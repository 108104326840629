import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

class MensajeError extends React.Component {
  constructor(props) {
    super(props);
    this.state = { mostrar: false, error: null };
  }

  componentDidUpdate(prevProps) {
    let error = null;
    Object.entries(this.props.state).forEach(([key, value]) => {
      var valuePrev = prevProps.state[key];
      if (
        value.create &&
        valuePrev &&
        valuePrev.create &&
        !value.create.isCreating &&
        valuePrev.create.isCreating &&
        value.create.error
      ) {
        error = value.create.error;
      }
      if (
        value.update &&
        valuePrev &&
        valuePrev.update &&
        !value.update.isUpdating &&
        valuePrev.update.isUpdating &&
        value.update.error
      ) {
        error = value.update.error;
      }
      if (
        value.delete &&
        valuePrev &&
        valuePrev.delete &&
        !value.delete.isDeleting &&
        valuePrev.delete.isDeleting &&
        value.delete.error
      ) {
        error = value.delete.error;
      }
      if (
        value.notify &&
        valuePrev &&
        valuePrev.notify &&
        !value.notify.isNotifying &&
        valuePrev.notify.isNotifying &&
        value.notify.error
      ) {
        error = value.notify.error;
      }
    });
    if (
      this.state.mostrar != (error != null) &&
      error != null &&
      Object.keys(error).length > 0
    ) {
      this.setState({ mostrar: true, error: error });
    }
  }

  render() {
    const { mostrar, error } = this.state;
    let Errores = '';
    if (
      error != null &&
      Object.keys(error).length > 0 &&
      typeof error == 'object'
    ) {
      Errores = Object.keys(error).map((nombre, index) => {
        let mensajeCompleto = '';
        if (error[nombre])
          error[nombre].forEach(mensaje => {
            mensajeCompleto += mensaje;
          });
        return (
          <p key={nombre} style={{ margin: '0px' }}>
            {mensajeCompleto}
          </p>
        );
      });
    } else
      Errores = (
        <p key={0} style={{ margin: '0px' }}>
          {error}
        </p>
      );

    return (
      <Snackbar
        open={mostrar}
        onClose={() => {
          this.setState({ mostrar: false });
        }}
        //TransitionComponent={<Slide direction="up" />}
        //autoHideDuration={6000}
        // message={Errores}
      >
        <Alert
          severity="error"
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => {
                  this.setState({ mostrar: false });
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        >
          {Errores}
        </Alert>
      </Snackbar>
    );
  }
}

function mapStateToProps(state) {
  return {
    state: state
  };
}

// Wrap the component to inject dispatch and state into it
export default withRouter(connect(mapStateToProps)(MensajeError));
