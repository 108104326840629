import React from 'react';
import Grid from '@material-ui/core/Grid';
import { MenuItem, TextField, Typography } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import PropertyOperation from './PropertyOperation';
import { useDispatch, useSelector } from 'react-redux';
import clone from 'lodash/clone';
import {
  createPropertyOperation,
  deletePropertyOperation,
  updatePropertyOperation
} from '../../actions/PropertyOperationActions';

function PropertyOperations(props) {
  const dispatch = useDispatch();
  const { property, handleChangeProperty, disabled, validador } = props;
  const idProperty = property ? property.id : null;

  //Store
  const properties = useSelector(state => state.properties);
  const operationTypes = useSelector(state => state.operationTypes);
  const propertyTypes = useSelector(state => state.propertyTypes);
  const periods = useSelector(state => state.periods);
  const propertyOperations = useSelector(state => state.propertyOperations);
  const currencies = useSelector(state => state.currencies);

  //Product language
  const handleChangePropertyOperation = (
    e,
    idPropertyOperation,
    idOperationType
  ) => {
    let id = idPropertyOperation
      ? idPropertyOperation
      : Date.now() + Math.random() + '-';
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    changePropertyOperation(cambio, id, idOperationType);
  };

  const changePropertyOperation = (
    cambio,
    idPropertyOperation,
    idOperationType
  ) => {
    //Verificar si ya esta siendo actualizado
    let propertyOperation =
      idPropertyOperation &&
      propertyOperations.update.activos.indexOf(idPropertyOperation) === -1 &&
      propertyOperations.create.nuevos.indexOf(idPropertyOperation) === -1
        ? propertyOperations.byId.propertyOperations[idPropertyOperation]
        : null;
    if (propertyOperation) {
      let actualizar = propertyOperation ? clone(propertyOperation) : null;
      if (actualizar) {
        let cambioPropertyOperation = {};
        cambioPropertyOperation[actualizar.id] = actualizar;
        if (idPropertyOperation.toString().indexOf('-') === -1)
          dispatch(updatePropertyOperation(cambioPropertyOperation));
        else dispatch(createPropertyOperation(cambioPropertyOperation));
      }
    }
    cambio.id = idPropertyOperation;
    cambio.idProperty = idProperty;
    cambio.idOperationType = idOperationType;
    let cambioPropertyOperation = {};
    cambioPropertyOperation[idPropertyOperation] = cambio;
    if (idPropertyOperation.toString().indexOf('-') === -1)
      dispatch(updatePropertyOperation(cambioPropertyOperation));
    else dispatch(createPropertyOperation(cambioPropertyOperation));
  };

  const handleDeletePropertyOperation = propertyOperation => {
    let cambioUbicacion = {};
    cambioUbicacion[propertyOperation.id] = clone(propertyOperation);
    dispatch(deletePropertyOperation(cambioUbicacion));
  };

  const handleAddChangePropertyOperation = (event, newPropertyOperation) => {
    //Create
    newPropertyOperation.forEach(idOperationType => {
      let found =
        property &&
        property.property_operation &&
        property.property_operation.some(idPropertyOperation => {
          let propertyOperation =
            propertyOperations.byId.propertyOperations[idPropertyOperation];
          if (
            propertyOperations.update.activos.indexOf(idPropertyOperation) !==
            -1
          )
            propertyOperation =
              propertyOperations.update.activo[idPropertyOperation];
          if (
            propertyOperations.create.nuevos.indexOf(idPropertyOperation) !== -1
          )
            propertyOperation =
              propertyOperations.create.nuevo[idPropertyOperation];
          if (
            propertyOperation &&
            propertyOperation.idOperationType === idOperationType &&
            (propertyOperation.status === undefined ||
              propertyOperation.status === 1)
          ) {
            return true;
          }
          return false;
        });
      if (!found) {
        let id = Date.now() + Math.random() + '-';
        changePropertyOperation({}, id, idOperationType);
      }
    });
    //Delete
    operationTypes.allIds.forEach(idOperationType => {
      if (newPropertyOperation.indexOf(idOperationType) === -1) {
        property &&
          property.property_operation &&
          property.property_operation.forEach(idPropertyOperation => {
            let propertyOperation =
              propertyOperations.byId.propertyOperations[idPropertyOperation];
            if (
              propertyOperations.update.activos.indexOf(idPropertyOperation) !==
              -1
            )
              propertyOperation =
                propertyOperations.update.activo[idPropertyOperation];
            if (
              propertyOperations.create.nuevos.indexOf(idPropertyOperation) !==
              -1
            )
              propertyOperation =
                propertyOperations.create.nuevo[idPropertyOperation];
            if (
              propertyOperation &&
              propertyOperation.idOperationType === idOperationType &&
              (propertyOperation.status === undefined ||
                propertyOperation.status === 1)
            ) {
              handleDeletePropertyOperation(propertyOperation);
            }
          });
      }
    });
  };
  return (
    <Grid container spacing={2}>
      <Grid item md={12} sm={12} xs={12}>
        <Typography gutterBottom variant="body1">
          ¿Cómo publicamos la propiedad?
        </Typography>
        <ToggleButtonGroup
          size={'large'}
          color="primary"
          onChange={handleAddChangePropertyOperation}
          aria-label="contained primary button group"
          value={
            property &&
            property.property_operation &&
            property.property_operation.map(idPropertyOperation => {
              let propertyOperation =
                propertyOperations.byId.propertyOperations[idPropertyOperation];
              if (
                propertyOperations.update.activos.indexOf(
                  idPropertyOperation
                ) !== -1
              )
                propertyOperation =
                  propertyOperations.update.activo[idPropertyOperation];
              if (
                propertyOperations.create.nuevos.indexOf(
                  idPropertyOperation
                ) !== -1
              )
                propertyOperation =
                  propertyOperations.create.nuevo[idPropertyOperation];
              return propertyOperation.status === undefined ||
                propertyOperation.status === 1
                ? propertyOperation.idOperationType
                : null;
            })
          }
        >
          {operationTypes.allIds.map(idOperationType => {
            let operationType =
              operationTypes.byId.operationTypes[idOperationType];
            return (
              <ToggleButton key={idOperationType} value={operationType.id}>
                {operationType.nameType}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
        {operationTypes.allIds.map((idOperationType, i) => {
          let operationType =
            operationTypes.byId.operationTypes[idOperationType];
          let property = idProperty
            ? properties.update.activo
            : properties.create.nuevo;
          let propertyOperationFound = [];
          if (property && property.property_operation)
            property.property_operation.forEach(idPropertyOperation => {
              let propertyOperation =
                propertyOperations.byId.propertyOperations[idPropertyOperation];
              if (
                propertyOperations.update.activos.indexOf(
                  idPropertyOperation
                ) !== -1
              )
                propertyOperation =
                  propertyOperations.update.activo[idPropertyOperation];
              if (
                propertyOperations.create.nuevos.indexOf(
                  idPropertyOperation
                ) !== -1
              )
                propertyOperation =
                  propertyOperations.create.nuevo[idPropertyOperation];
              if (
                propertyOperation &&
                propertyOperation.idOperationType === idOperationType &&
                (propertyOperation.status === undefined ||
                  propertyOperation.status === 1)
              ) {
                propertyOperationFound.push(propertyOperation);
              }
            });
          return (
            <PropertyOperation
              key={'po' + i}
              operationType={operationType}
              propertyOperations={propertyOperationFound}
              handleChangePropertyOperation={handleChangePropertyOperation}
              currencies={currencies}
              disabled={disabled}
              changePropertyOperation={changePropertyOperation}
              periods={periods}
              handleDeletePropertyOperation={handleDeletePropertyOperation}
            />
          );
        })}
      </Grid>
    </Grid>
  );
}

export default PropertyOperations;
