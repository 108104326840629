import { combineReducers } from 'redux';
import auth from './authReducer';
import assets from './assets';
import typesFiles from './typesFiles';
import properties from './properties';
import propertyTypes from './propertyTypes';
import attributes from './attributes';
import attributesGroups from './attributesGroups';
import attributesValues from './attributesValues';
import propertiesAttributesValues from './propertiesAttributesValues';
import posts from './posts';
import logs from './logs';
import users from './users';
import operationTypes from './operationTypes';
import currencies from './currencies';
import propertyOperations from './propertyOperations';
import periods from './periods';
import dependencies from './dependencies';
import tenants from './tenants';
import tenantUserAccesses from './tenantUserAccesses';
import externalSystems from './externalSystems';
import domains from './domains';
import analytics from './analytics';
import contacts from './contacts';
import propertyClosedOperations from './propertyClosedOperations';
import tickets from './tickets';
import ticketProperties from './ticketProperties';
import ticketActivities from './ticketActivities';
import ticketStatuses from './ticketStatuses';
import countries from './countries';
import states from './states';
import locations from './locations';
import neighborhoods from './neighborhoods';
import roles from './roles';

const appReducers = combineReducers({
  auth,
  assets,
  analytics,
  attributes,
  attributesGroups,
  attributesValues,
  contacts,
  currencies,
  propertyTypes,
  countries,
  dependencies,
  domains,
  externalSystems,
  locations,
  logs,
  neighborhoods,
  operationTypes,
  periods,
  posts,
  properties,
  propertiesAttributesValues,
  propertyOperations,
  propertyClosedOperations,
  roles,
  states,
  tenants,
  tenantUserAccesses,
  tickets,
  ticketActivities,
  ticketStatuses,
  ticketProperties,
  typesFiles,
  users
});

export default appReducers;
