//api
import analyticsApi from '../api/analyticsApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizeAnalytics';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//ANALYTICS
export const REQUEST_ANALYTICS = 'REQUEST_ANALYTICS';
export const RECEIVE_ANALYTICS = 'RECEIVE_ANALYTICS';
export const INVALIDATE_ANALYTICS = 'INVALIDATE_ANALYTICS';
export const ERROR_ANALYTICS = 'ERROR_ANALYTICS';
export const RESET_ANALYTICS = 'RESET_ANALYTICS';

export function invalidateAnalytics() {
  return {
    type: INVALIDATE_ANALYTICS
  };
}

function requestAnalytics() {
  return {
    type: REQUEST_ANALYTICS
  };
}

function receiveAnalytics(json) {
  return {
    type: RECEIVE_ANALYTICS,
    analytics: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorAnalytics(error) {
  return {
    type: ERROR_ANALYTICS,
    error: error
  };
}

export function resetAnalytics() {
  return {
    type: RESET_ANALYTICS
  };
}

export function fetchAnalytics(filtros) {
  return dispatch => {
    dispatch(requestAnalytics());
    return analyticsApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveAnalytics(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorAnalytics(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorAnalytics(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

function shouldFetchAnalytics(state) {
  const analytics = state.analytics.byId;
  if (!analytics) {
    return true;
  } else if (analytics.isFetching) {
    return false;
  } else {
    return analytics.didInvalidate;
  }
}

export function fetchAnalyticsIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchAnalytics(getState())) {
      return dispatch(fetchAnalytics(filtros));
    }
  };
}

//MODEL
export const REQUEST_ANALYTIC = 'REQUEST_ANALYTIC';
export const RECEIVE_ANALYTIC = 'RECEIVE_ANALYTIC';
export const INVALIDATE_ANALYTIC = 'INVALIDATE_ANALYTIC';
export const ERROR_ANALYTIC = 'ERROR_ANALYTIC';
export const RESET_ANALYTIC = 'RESET_ANALYTIC';

export function invalidateAnalytic() {
  return {
    type: INVALIDATE_ANALYTIC
  };
}

function requestAnalytic() {
  return {
    type: REQUEST_ANALYTIC
  };
}

export function receiveAnalytic(json) {
  return {
    type: RECEIVE_ANALYTIC,
    analytic: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorAnalytic(error) {
  return {
    type: ERROR_ANALYTIC,
    error: error
  };
}

export function fetchAnalytic(idAnalytic) {
  return dispatch => {
    dispatch(requestAnalytic());
    return analyticsApi
      .getOne(idAnalytic)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveAnalytic(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(errorAnalytic(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorAnalytic(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_ANALYTIC = 'RECEIVE_FILE_ANALYTIC';

function receiveFileAnalytic(file) {
  return {
    type: RECEIVE_FILE_ANALYTIC,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFileAnalytic(idAnalytic, filtros) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return analyticsApi
      .getFile(idAnalytic, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFileAnalytic(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorAnalytic(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorAnalytic(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_ANALYTIC = 'UPDATE_ANALYTIC';
export const REQUEST_UPDATE_ANALYTIC = 'REQUEST_UPDATE_ANALYTIC';
export const SUCCESS_UPDATE_ANALYTIC = 'SUCCESS_UPDATE_ANALYTIC';
export const ERROR_UPDATE_ANALYTIC = 'ERROR_UPDATE_ANALYTIC';
export const RESET_UPDATE_ANALYTIC = 'RESET_UPDATE_ANALYTIC';
export const DELETE_UPDATE_ANALYTIC = 'DELETE_UPDATE_ANALYTIC';

function requestUpdateAnalytic() {
  return {
    type: REQUEST_UPDATE_ANALYTIC
  };
}

function receiveUpdateAnalytic(analytic) {
  return {
    type: SUCCESS_UPDATE_ANALYTIC,
    receivedAt: Date.now(),
    analytic: normalizeDato(analytic)
  };
}

function errorUpdateAnalytic(error) {
  return {
    type: ERROR_UPDATE_ANALYTIC,
    error: error
  };
}

export function resetUpdateAnalytic() {
  return {
    type: RESET_UPDATE_ANALYTIC
  };
}

export function updateAnalytic(analytic) {
  return {
    type: UPDATE_ANALYTIC,
    analytic
  };
}

export function saveUpdateAnalytic() {
  return (dispatch, getState) => {
    dispatch(requestUpdateAnalytic());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let analytic = denormalizeDato(getState().analytics.update.activo, store);

    return analyticsApi
      .saveUpdate(analytic)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateAnalytic(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateAnalytic(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateAnalytics(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdateAnalytics(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateAnalytic(JSON.parse(error.message)));
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdateAnalytic(error.data));
                })
                .catch(() => {
                  dispatch(errorUpdateAnalytic(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

export function deleteUpdateAnalytic(analytic) {
  return {
    type: DELETE_UPDATE_ANALYTIC,
    analytic
  };
}

//UPDATE ANALYTICS
export const REQUEST_UPDATE_ANALYTICS = 'REQUEST_UPDATE_ANALYTICS';
export const SUCCESS_UPDATE_ANALYTICS = 'SUCCESS_UPDATE_ANALYTICS';
export const ERROR_UPDATE_ANALYTICS = 'ERROR_UPDATE_ANALYTICS';
export const RESET_UPDATE_ANALYTICS = 'RESET_UPDATE_ANALYTICS';

function requestUpdateAnalytics() {
  return {
    type: REQUEST_UPDATE_ANALYTICS
  };
}

function receiveUpdateAnalytics(analytics) {
  return {
    type: SUCCESS_UPDATE_ANALYTICS,
    receivedAt: Date.now(),
    analytics: normalizeDatos(analytics)
  };
}

function errorUpdateAnalytics(error) {
  return {
    type: ERROR_UPDATE_ANALYTICS,
    error: error
  };
}

export function resetUpdateAnalytics() {
  return {
    type: RESET_UPDATE_ANALYTICS
  };
}

export function saveUpdateAnalytics() {
  return (dispatch, getState) => {
    dispatch(requestUpdateAnalytics());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let analytics = getState().analytics.update.activos.map(idAnalytic => {
      return denormalizeDato(
        getState().analytics.update.activo[idAnalytic],
        store
      );
    });

    return analyticsApi
      .saveUpdateAnalytics(analytics)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateAnalytics(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateAnalytics(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateAnalytics(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdateAnalytics(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateAnalytics(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorUpdateAnalytics(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//ALTA ANALYTIC
export const CREATE_ANALYTIC = 'CREATE_ANALYTIC';
export const REQUEST_CREATE_ANALYTIC = 'REQUEST_CREATE_ANALYTIC';
export const SUCCESS_CREATE_ANALYTIC = 'SUCCESS_CREATE_ANALYTIC';
export const ERROR_CREATE_ANALYTIC = 'ERROR_CREATE_ANALYTIC';
export const RESET_CREATE_ANALYTIC = 'RESET_CREATE_ANALYTIC';
export const DELETE_CREATE_ANALYTIC = 'DELETE_CREATE_ANALYTIC';

//ALTA ANALYTIC
function requestCreateAnalytic() {
  return {
    type: REQUEST_CREATE_ANALYTIC
  };
}

function receiveCreateAnalytic(analytic) {
  return {
    type: SUCCESS_CREATE_ANALYTIC,
    receivedAt: Date.now(),
    analytic: normalizeDato(analytic)
  };
}

export function errorCreateAnalytic(error) {
  return {
    type: ERROR_CREATE_ANALYTIC,
    error: error
  };
}

export function resetCreateAnalytic() {
  return {
    type: RESET_CREATE_ANALYTIC
  };
}

export function createAnalytic(analytic) {
  return {
    type: CREATE_ANALYTIC,
    analytic
  };
}

export function deleteCreateAnalytic(analytic) {
  return {
    type: DELETE_CREATE_ANALYTIC,
    analytic
  };
}

export function saveCreateAnalytic() {
  return (dispatch, getState) => {
    dispatch(requestCreateAnalytic());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let analytic = denormalizeDato(getState().analytics.create.nuevo, store);

    return analyticsApi
      .saveCreate(analytic)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateAnalytic(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateAnalytic(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateAnalytics(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreateAnalytics(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateAnalytic(JSON.parse(error.message)));
                  if (error.data) dispatch(receiveCreateAnalytic(error.data));
                })
                .catch(() => {
                  dispatch(errorCreateAnalytic(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//CREATE ANALYTICS
export const REQUEST_CREATE_ANALYTICS = 'REQUEST_CREATE_ANALYTICS';
export const SUCCESS_CREATE_ANALYTICS = 'SUCCESS_CREATE_ANALYTICS';
export const ERROR_CREATE_ANALYTICS = 'ERROR_CREATE_ANALYTICS';
export const RESET_CREATE_ANALYTICS = 'RESET_CREATE_ANALYTICS';

function requestCreateAnalytics() {
  return {
    type: REQUEST_CREATE_ANALYTICS
  };
}

function receiveCreateAnalytics(analytics) {
  return {
    type: SUCCESS_CREATE_ANALYTICS,
    receivedAt: Date.now(),
    analytics: normalizeDatos(analytics)
  };
}

function errorCreateAnalytics(error) {
  return {
    type: ERROR_CREATE_ANALYTICS,
    error: error
  };
}

export function resetCreateAnalytics() {
  return {
    type: RESET_CREATE_ANALYTICS
  };
}

export function saveCreateAnalytics() {
  return (dispatch, getState) => {
    dispatch(requestCreateAnalytics());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let analytics = getState().analytics.create.nuevos.map(idAnalytic => {
      return denormalizeDato(
        getState().analytics.create.nuevo[idAnalytic],
        store
      );
    });

    return analyticsApi
      .saveCreateAnalytics(analytics)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateAnalytics(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateAnalytics(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateAnalytics(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreateAnalytics(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateAnalytics(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorCreateAnalytics(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//DELETE ANALYTIC
export const DELETE_ANALYTIC = 'DELETE_ANALYTIC';
export const REQUEST_DELETE_ANALYTIC = 'REQUEST_DELETE_ANALYTIC';
export const SUCCESS_DELETE_ANALYTIC = 'SUCCESS_DELETE_ANALYTIC';
export const ERROR_DELETE_ANALYTIC = 'ERROR_DELETE_ANALYTIC';
export const RESET_DELETE_ANALYTIC = 'RESET_DELETE_ANALYTIC';

function requestDeleteAnalytic() {
  return {
    type: REQUEST_DELETE_ANALYTIC
  };
}

function receiveDeleteAnalytic(analytic) {
  return {
    type: SUCCESS_DELETE_ANALYTIC,
    receivedAt: Date.now(),
    analytic: normalizeDato(analytic)
  };
}

function errorDeleteAnalytic(error) {
  return {
    type: ERROR_DELETE_ANALYTIC,
    error: error
  };
}

export function resetDeleteAnalytic(error) {
  return {
    type: RESET_DELETE_ANALYTIC,
    error: error
  };
}

export function deleteAnalytic(analytic) {
  return {
    type: DELETE_ANALYTIC,
    analytic
  };
}

export function saveDeleteAnalytic(analytic) {
  return dispatch => {
    dispatch(requestDeleteAnalytic());
    return analyticsApi
      .saveDelete(analytic)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeleteAnalytic());
        dispatch(receiveDeleteAnalytic(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorDeleteAnalytic(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeleteAnalytic(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorDeleteAnalytic(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorDeleteAnalytic(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorDeleteAnalytic(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT ANALYTIC
export const PRINT_ANALYTIC = 'PRINT_ANALYTIC';
export const REQUEST_PRINT_ANALYTIC = 'REQUEST_PRINT_ANALYTIC';
export const SUCCESS_PRINT_ANALYTIC = 'SUCCESS_PRINT_ANALYTIC';
export const ERROR_PRINT_ANALYTIC = 'ERROR_PRINT_ANALYTIC';
export const RESET_PRINT_ANALYTIC = 'RESET_PRINT_ANALYTIC';
export const DELETE_PRINT_ANALYTIC = 'DELETE_PRINT_ANALYTIC';

function requestPrintAnalytic() {
  return {
    type: REQUEST_PRINT_ANALYTIC
  };
}

function receivePrintAnalytic(turnos) {
  return {
    type: SUCCESS_PRINT_ANALYTIC,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintAnalytic(error) {
  return {
    type: ERROR_PRINT_ANALYTIC,
    error: error
  };
}

export function resetPrintAnalytic() {
  return {
    type: RESET_PRINT_ANALYTIC
  };
}

export function printAnalytic(analytic) {
  return {
    type: PRINT_ANALYTIC,
    analytic
  };
}

export function deletePrintAnalytic(analytic) {
  return {
    type: DELETE_PRINT_ANALYTIC,
    analytic
  };
}

export function savePrintAnalytic(idAnalytic) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintAnalytic());
    return analyticsApi
      .printAnalytic(idAnalytic)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintAnalytic(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintAnalytic(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintAnalytic(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintAnalytic(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintAnalytic(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT ANALYTICS
export const REQUEST_PRINT_ANALYTICS = 'REQUEST_PRINT_ANALYTICS';
export const SUCCESS_PRINT_ANALYTICS = 'SUCCESS_PRINT_ANALYTICS';
export const ERROR_PRINT_ANALYTICS = 'ERROR_PRINT_ANALYTICS';
export const RESET_PRINT_ANALYTICS = 'RESET_PRINT_ANALYTICS';

function requestPrintAnalytics() {
  return {
    type: REQUEST_PRINT_ANALYTICS
  };
}

function receivePrintAnalytics(analytics) {
  return {
    type: SUCCESS_PRINT_ANALYTICS,
    receivedAt: Date.now(),
    analytics: normalizeDatos(analytics)
  };
}

function errorPrintAnalytics(error) {
  return {
    type: ERROR_PRINT_ANALYTICS,
    error: error
  };
}

export function resetPrintAnalytics() {
  return {
    type: RESET_PRINT_ANALYTICS
  };
}

export function savePrintAnalytics() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintAnalytic());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let analytics = getState().analytics.print.printers.map(idAnalytic => {
      return denormalizeDato(
        getState().analytics.print.print[idAnalytic],
        store
      );
    });
    return analyticsApi
      .printAnalytics(analytics)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintAnalytics(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintAnalytics(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintAnalytics(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintAnalytics(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintAnalytics(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}
