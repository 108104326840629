import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
//Components
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import clsx from 'clsx';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
//Icons
import clone from 'lodash/clone';
import union from 'lodash/union';
import { useDispatch, useSelector } from 'react-redux';
import {
  createAsset,
  deleteAsset,
  updateAsset
} from '../../actions/AssetActions';
//Img
import c from '../../constants/Constants';
import PropertyAssetItem from './PropertyAssetItem';

const styles = makeStyles(theme => ({
  form: {
    // padding: theme.spacing(2, 0)
  },
  fileInput: {
    marginBottom: '12px',
    '& label': {
      width: '100%',
      height: '100%',
      display: 'flex',
      padding: '0px 8px',
      paddingBottom: '11px',
      paddingTop: '12px',
      borderRadius: '8px',
      // backgroundColor: "rgba(241, 10, 77, 0.1)",
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      '& *': {
        pointerEvents: 'none'
      }
    },
    '& input[type=file]': {
      width: '0.1px',
      height: '0.1px',
      opacity: 0,
      overflow: 'hidden',
      top: '50%',
      left: '50%',
      position: 'absolute',
      zIndex: '-1'
    }
  },
  mobile: {
    width: '100%',
    maxWidth: '120px',
    height: '120px'
    // paddingRight: "6px",
  },
  desktop: {
    // width: "100%",
    width: '200px',
    height: '200px'
    // paddingLeft: "6px",
  },
  buttonUploaded: {
    padding: 0,
    borderRadius: '8px',
    textTransform: 'none',
    width: '100%',
    height: '100%',
    '& .MuiButton-label': {
      width: '100%',
      height: '100%',
      border: '1px solid rgba(0, 0, 0, 0.4)',
      borderRadius: '8px'
      // padding: "3px"
    }
  },
  buttonUpload: {
    '& .MuiButton-label': {
      border: '1px dashed rgba(241, 10, 77, 0.8)'
    }
  },
  labelButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
    '& img': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
      backgroundColor: 'white',
      objectFit: 'cover'
    }
  },
  progress: {
    width: '80px !important',
    height: '80px !important',
    position: 'absolute',
    '& svg circle': {
      strokeWidth: '1.2'
    }
  },
  btnImg: {
    backgroundColor: 'white',
    width: '30px'
  }
}));

export default function PropertyAssets(props) {
  const classes = styles();
  const dispatch = useDispatch();
  let textFile = React.useRef();
  let inputfileprod = React.createRef();
  let img = React.useRef();
  const mounted = useRef();
  const { property, files, setFiles, type, changeProperty, disabled } = props;
  //Hooks
  const properties = useSelector(state => state.properties);
  const assets = useSelector(state => state.assets);
  //State
  const [loading, setLoading] = React.useState(false);
  const [previews, setPreviews] = React.useState(new Map());
  const ref = useRef(null);
  let arraySorted = [];
  if (property?.asset) {
    arraySorted = [...property.asset]
      .filter(aid => {
        let a = assets.byId.assets[aid];
        if (assets.update.activos.indexOf(aid) !== -1)
          a = assets.update.activo[aid];
        if (assets.create.nuevos.indexOf(aid) !== -1)
          a = assets.create.nuevo[aid];
        return a.type === type;
      })
      .sort(compareFn);
  }

  function compareFn(aid, bid) {
    let a = assets.byId.assets[aid];
    if (assets.update.activos.indexOf(aid) !== -1)
      a = assets.update.activo[aid];
    if (assets.create.nuevos.indexOf(aid) !== -1) a = assets.create.nuevo[aid];
    let b = assets.byId.assets[bid];
    if (assets.update.activos.indexOf(bid) !== -1)
      b = assets.update.activo[bid];
    if (assets.create.nuevos.indexOf(bid) !== -1) b = assets.create.nuevo[bid];
    if (a.order < b.order) {
      return -1;
    } else if (a.order > b.order) {
      return 1;
    }
    // a must be equal to b
    return 0;
  }

  const getLastOrder = () => {
    let order = -1;
    property?.asset?.forEach(aid => {
      let a = assets.byId.assets[aid];
      if (assets.update.activos.indexOf(aid) !== -1)
        a = assets.update.activo[aid];
      if (assets.create.nuevos.indexOf(aid) !== -1)
        a = assets.create.nuevo[aid];
      if (order < a.order && a.type === type) order = a.order;
    });
    return order;
  };

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    }
  }, [properties.create.isCreating]);

  const handleChangeFileAsset = e => {
    e.preventDefault();
    //Preview
    let fotos = [];
    let previewsImg = clone(previews);
    let cant = clone(previewsImg.length);
    setLoading(true);
    for (let i = 0; i < inputfileprod.current.files.length; i++) {
      let filename = inputfileprod.current.files[i].name.replace(
        /[^a-zA-Z0-9]/g,
        '_'
      );
      Object.defineProperty(inputfileprod.current.files[i], 'name', {
        writable: true,
        value: filename
      });
      fotos.push(inputfileprod.current.files[i]);
      setPreviews(prev => prev.set(filename, 'loading'));
    }
    let fotosClone = clone(files);
    setFiles(union(fotosClone, fotos));

    //Cargar foto
    for (let i = 0; i < inputfileprod.current.files.length; i++) {
      let fileSubido = inputfileprod.current.files[i];
      let vuelta = clone(i);
      let filename = fileSubido.name.replace(/[^a-zA-Z0-9]/g, '_');
      let reader = new FileReader();
      reader.onloadend = e => {
        // img.current.src = reader.result;
        setPreviews(prev => prev.set(filename, reader.result));
        setTimeout(() => setLoading(prev => !prev), 60);
      };

      if (fileSubido) {
        reader.readAsDataURL(fileSubido);
      }
      let id = Date.now() + Math.random() + '-';
      let cambio = {};
      cambio.originalFilename = filename;
      cambio.type = type;
      cambio.order = getLastOrder() + 1 + i;
      changeAsset(cambio, id);
    }
  };

  const handleChangeAsset = (e, idAsset) => {
    let id = idAsset ? idAsset : Date.now() + Math.random() + '-';
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    changeAsset(cambio, id);
  };

  const handleChangeOrder = (idAsset, dragIndex, hoverIndex) => {
    if (arraySorted[dragIndex] === idAsset) {
      let arr = [...arraySorted];
      // let id = orderArray[dragIndex];
      arr.splice(dragIndex, 1);
      arr.splice(hoverIndex, 0, idAsset);
      arr.forEach((id, i) => {
        let cambio = {};
        cambio.order = i;
        changeAsset(cambio, id);
      });
    }
  };

  const changeAsset = (cambio, idAsset) => {
    //Verificar si ya esta siendo actualizado
    let asset =
      idAsset &&
      assets.update.activos.indexOf(idAsset) === -1 &&
      assets.create.nuevos.indexOf(idAsset) === -1
        ? assets.byId.assets[idAsset]
        : null;
    if (asset) {
      let actualizar = asset ? clone(asset) : null;
      if (actualizar) {
        let cambioAsset = {};
        cambioAsset[actualizar.id] = actualizar;
        if (idAsset.toString().indexOf('-') === -1)
          dispatch(updateAsset(cambioAsset));
        else dispatch(createAsset(cambioAsset));
      }
    }
    cambio.id = idAsset;
    cambio.idProperty = property.id;
    let cambioAsset = {};
    cambioAsset[idAsset] = cambio;
    if (idAsset.toString().indexOf('-') === -1)
      dispatch(updateAsset(cambioAsset));
    else {
      cambio.type = type;
      dispatch(createAsset(cambioAsset));
    }
  };

  const deleteAssetFile = asset => {
    if (asset.mainImage === 0) {
      handleChangeOrder(
        asset.id,
        arraySorted.indexOf(asset.id),
        arraySorted.length
      );
      let cambioUbicacion = {};
      cambioUbicacion[asset.id] = clone(asset);
      dispatch(deleteAsset(cambioUbicacion));
    } else
      alert(
        'Esta intentando borrar la foto de portada. Primero seleccione otra foto de portada.'
      );
  };

  const downloadFileAsset = file => {
    let fileSubido = file;
    let reader = new FileReader();
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    reader.onloadend = function () {
      a.href = reader.result;
      a.download = file.name;
      a.click();
    };
    if (fileSubido) {
      reader.readAsDataURL(fileSubido);
    }
  };

  function b64toBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }

  const previewFileAsset = file => {
    if (file.path) {
      window.open(c.API_HOST + file.path);
    } else {
      const blob = b64toBlob(file, null);
      const blobUrl = URL.createObjectURL(blob);
    }
  };

  const handleChangeMainImageProductAsset = asset => {
    let _asset = clone(asset);
    _asset.mainImage = 1;
    changeAsset(_asset, asset.id);

    let _property = {};
    _property.main_product_asset = [asset.id];
    changeProperty(_property);

    property.asset.forEach(idAsset => {
      if (idAsset !== asset.id) {
        let _asset = assets.byId.assets[idAsset];
        if (assets.update.activos.indexOf(idAsset) !== -1)
          _asset = assets.update.activo[idAsset];
        if (assets.create.nuevos.indexOf(idAsset) !== -1)
          _asset = assets.create.nuevo[idAsset];
        if (_asset.mainImage === 1) {
          let cambioProductAsset = clone(_asset);
          cambioProductAsset.mainImage = 0;
          changeAsset(cambioProductAsset, idAsset);
        }
      }
    });
  };

  return (
    <Box
      component={'div'}
      className={classes.form}
      display={'flex'}
      justifyContent={'flex-start'}
      alignItems={'center'}
    >
      <Box
        display={'flex'}
        flexWrap={'wrap'}
        component={'div'}
        className={classes.containerFileInput}
        gridColumnGap={'6px'}
        ref={ref}
      >
        <Box
          component={'div'}
          className={clsx([classes.fileInput, classes.desktop])}
        >
          <Button
            className={clsx([classes.buttonUpload, classes.buttonUploaded])}
          >
            <label
              htmlFor={'mainFileProduct-' + type}
              className={classes.labelButton}
              // ref={instance => labelRef.current = instance}
            >
              <AddPhotoAlternateIcon color={'primary'} />
              <Typography variant={'caption'} color={'primary'}>
                Subir imagen
              </Typography>
              <CircularProgress
                className={classes.progress}
                style={{ display: !loading ? 'none' : 'inherit' }}
              />
            </label>
            <input
              ref={inputfileprod}
              type="file"
              name="mainFileProduct"
              id={'mainFileProduct-' + type}
              className="inputfile"
              multiple={true}
              accept="image/*"
              onChange={e => handleChangeFileAsset(e)}
            />
          </Button>
        </Box>
        {arraySorted &&
          arraySorted.map((idAsset, i) => {
            let asset = assets.byId.assets[idAsset];
            if (assets.update.activos.indexOf(idAsset) !== -1)
              asset = assets.update.activo[idAsset];
            if (assets.create.nuevos.indexOf(idAsset) !== -1)
              asset = assets.create.nuevo[idAsset];
            let src = previews.get(asset.originalFilename);
            if (asset.type === type)
              return (
                <PropertyAssetItem
                  asset={asset}
                  src={src}
                  deleteAssetFile={deleteAssetFile}
                  handleChangeMainImageProductAsset={
                    handleChangeMainImageProductAsset
                  }
                  disabled={disabled}
                  previewFileAsset={previewFileAsset}
                  handleChangeOrder={handleChangeOrder}
                />
              );
          })}
      </Box>
    </Box>
  );
}

PropertyAssets.propTypes = {
  legend: PropTypes.string
};
