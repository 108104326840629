import {
  INVALIDATE_LOCATIONS,
  ERROR_LOCATIONS,
  RECEIVE_LOCATIONS,
  REQUEST_LOCATIONS,
  RESET_LOCATIONS,
  ERROR_LOCATION,
  RECEIVE_LOCATION,
  REQUEST_LOCATION,
  UPDATE_LOCATION,
  REQUEST_UPDATE_LOCATION,
  SUCCESS_UPDATE_LOCATION,
  ERROR_UPDATE_LOCATION,
  RESET_UPDATE_LOCATION,
  REQUEST_UPDATE_LOCATIONS,
  SUCCESS_UPDATE_LOCATIONS,
  ERROR_UPDATE_LOCATIONS,
  RESET_UPDATE_LOCATIONS,
  CREATE_LOCATION,
  ERROR_CREATE_LOCATION,
  REQUEST_CREATE_LOCATION,
  RESET_CREATE_LOCATION,
  SUCCESS_CREATE_LOCATION,
  REQUEST_CREATE_LOCATIONS,
  SUCCESS_CREATE_LOCATIONS,
  ERROR_CREATE_LOCATIONS,
  RESET_CREATE_LOCATIONS,
  DELETE_LOCATION,
  DELETE_CREATE_LOCATION,
  DELETE_UPDATE_LOCATION,
  REQUEST_DELETE_LOCATION,
  SUCCESS_DELETE_LOCATION,
  ERROR_DELETE_LOCATION,
  RESET_DELETE_LOCATION,
  REQUEST_PRINT_LOCATION,
  SUCCESS_PRINT_LOCATION,
  ERROR_PRINT_LOCATION,
  RESET_PRINT_LOCATION,
  RECEIVE_FILE_LOCATION,
  REQUEST_PRINT_LOCATIONS,
  SUCCESS_PRINT_LOCATIONS,
  ERROR_PRINT_LOCATIONS,
  RESET_PRINT_LOCATIONS,
  RECEIVE_FILE_LOCATIONS,
  PRINT_LOCATION,
  DELETE_PRINT_LOCATION
} from '../actions/LocationActions';

import {
  RECEIVE_COUNTRY,
  RECEIVE_COUNTRIES,
  SUCCESS_DELETE_COUNTRY,
  SUCCESS_CREATE_COUNTRY,
  SUCCESS_UPDATE_COUNTRY,
  SUCCESS_UPDATE_COUNTRIES
} from '../actions/CountryActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    locations: {},
    files: {}
  };
}

function locationsById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_LOCATIONS:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_LOCATIONS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_LOCATIONS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_LOCATIONS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        locations: {}
      });
    case RECEIVE_LOCATIONS:
      let dato = action.locations.entities.locations;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        locations: merge({}, state.locations, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_LOCATION:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_LOCATION:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_LOCATION:
      let datoLocation = action.location.entities.locations;
      return Object.assign({}, state, {
        locations: merge({}, state.locations, datoLocation),
        isFetching: false
      });
    case RECEIVE_FILE_LOCATION:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_LOCATION:
      let datoLocationEliminado = action.location.entities.locations;
      return Object.assign({}, state, {
        locations: mergeWith(
          clone(datoLocationEliminado),
          state.locations,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_LOCATION:
      let datoLocationCreado = action.location.entities.locations;
      return Object.assign({}, state, {
        locations: mergeWith(
          clone(datoLocationCreado),
          state.locations,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_LOCATIONS:
      let datosLocationCreado = action.locations.entities.locations;
      return Object.assign({}, state, {
        locations: mergeWith(
          clone(datosLocationCreado),
          state.locations,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_LOCATION:
      let datoLocationActualizado = action.location.entities.locations;
      return Object.assign({}, state, {
        locations: mergeWith(
          clone(datoLocationActualizado),
          state.locations,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_LOCATIONS:
      let datosLocationActualizado = action.locations.entities.locations;
      return Object.assign({}, state, {
        locations: mergeWith(
          clone(datosLocationActualizado),
          state.locations,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    //COUNTRY
    case RECEIVE_COUNTRY:
      let country =
        action.country.entities && action.country.entities.locations
          ? action.country.entities.locations
          : {};
      return Object.assign({}, state, {
        locations: merge({}, state.locations, country)
      });
    case RECEIVE_COUNTRIES:
      let countries =
        action.countries.entities && action.countries.entities.locations
          ? action.countries.entities.locations
          : {};
      return Object.assign({}, state, {
        locations: merge({}, state.locations, countries)
      });
    case SUCCESS_DELETE_COUNTRY:
      let datocountryEliminado =
        action.country.entities && action.country.entities.locations
          ? action.country.entities.locations
          : {};
      return Object.assign({}, state, {
        locations: mergeWith(
          clone(datocountryEliminado),
          pickBy(state.locations, function (location) {
            return location.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_COUNTRY:
      let datocountryCreado =
        action.country.entities && action.country.entities.locations
          ? action.country.entities.locations
          : {};
      return Object.assign({}, state, {
        locations: mergeWith(
          clone(datocountryCreado),
          pickBy(state.locations, function (location) {
            return location.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_COUNTRY:
      let datocountryActualizado =
        action.country.entities && action.country.entities.locations
          ? action.country.entities.locations
          : {};
      return Object.assign({}, state, {
        locations: mergeWith(
          clone(datocountryActualizado),
          pickBy(state.locations, function (location) {
            return location.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_COUNTRIES:
      let datoscountryActualizado =
        action.countries.entities && action.countries.entities.locations
          ? action.countries.entities.locations
          : {};
      return Object.assign({}, state, {
        locations: mergeWith(
          clone(datoscountryActualizado),
          state.locations,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        locations: {}
      });
    default:
      return state;
  }
}

function allLocations(state = [], action) {
  switch (action.type) {
    case RECEIVE_LOCATIONS:
      return action.locations.result && action.locations.result.locations
        ? union(action.locations.result.locations, state)
        : action.locations.result
        ? action.locations.result
        : state;
    case RECEIVE_LOCATION:
      return action.location.result
        ? union([action.location.result], state)
        : state;

    case SUCCESS_CREATE_LOCATION:
      let datoLocationSCreate = action.location.entities.locations;
      let idNuevoSCreate = null;
      if (Object.values(datoLocationSCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoLocationSCreate)[0] &&
          Object.values(datoLocationSCreate)[0].id
            ? Object.values(datoLocationSCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_LOCATIONS:
      let locationsCreate =
        action.locations.entities && action.locations.entities.locations
          ? action.locations.entities.locations
          : null;
      return locationsCreate
        ? union(
            state,
            Object.values(locationsCreate).map(locations => {
              return locations.id;
            })
          )
        : state;
    case RESET_LOCATIONS:
      return [];

    case RECEIVE_COUNTRY:
      let country =
        action.country.entities && action.country.entities.locations
          ? action.country.entities.locations
          : null;
      return country
        ? union(
            state,
            Object.values(country).map(country => {
              return country.id;
            })
          )
        : state;
    case RECEIVE_COUNTRIES:
      let countries =
        action.countries.entities && action.countries.entities.locations
          ? action.countries.entities.locations
          : null;
      return countries
        ? union(
            state,
            Object.values(countries).map(countries => {
              return countries.id;
            })
          )
        : state;

    case SUCCESS_DELETE_COUNTRY:
      let countryDelete =
        action.country.entities && action.country.entities.locations
          ? action.country.entities.locations
          : null;
      return countryDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(countryDelete).map(country => {
              return country.id;
            })
          )
        : state;
    case SUCCESS_CREATE_COUNTRY:
      let countryCreate =
        action.country.entities && action.country.entities.locations
          ? action.country.entities.locations
          : null;
      return countryCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(countryCreate).map(country => {
              return country.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_COUNTRY:
      let countryUpdate =
        action.country.entities && action.country.entities.locations
          ? action.country.entities.locations
          : null;
      return countryUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(countryUpdate).map(country => {
              return country.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_COUNTRIES:
      let countriesUpdate =
        action.countries.entities && action.countries.entities.locations
          ? action.countries.entities.locations
          : null;
      return countriesUpdate
        ? union(
            state,
            Object.values(countriesUpdate).map(countries => {
              return countries.id;
            })
          )
        : state;

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalLocations(state = null, action) {
  switch (action.type) {
    case RECEIVE_LOCATIONS:
      return action.locations && action.locations.result.total
        ? action.locations.result.total
        : 0;
    case RESET_LOCATIONS:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_LOCATION:
      let dato = action.location.entities.locations;
      let location =
        dato && Object.keys(dato).length > 0
          ? dato[action.location.result]
          : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: location ? location : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_LOCATION:
      let idsUpdate = [];
      Object.values(action.location).map(locationUpdate => {
        if (locationUpdate && locationUpdate.id)
          idsUpdate.push(locationUpdate.id);
      });
      return merge({}, state, {
        activo: action.location,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_LOCATION:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_LOCATION:
      let datoLocationActualizado = action.location.entities.locations;
      let locationNuevo =
        datoLocationActualizado &&
        Object.keys(datoLocationActualizado).length > 0
          ? datoLocationActualizado[action.location.result]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: locationNuevo
      });
    case ERROR_UPDATE_LOCATION:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_LOCATIONS:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_LOCATIONS:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_LOCATIONS:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_LOCATION:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    //COUNTRY
    //TODO ver si esta bien
    case SUCCESS_CREATE_COUNTRY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_COUNTRY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_COUNTRY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_COUNTRIES:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });

    case DELETE_LOCATION:
      let datoLocationDelete = action.location;
      let idsDelete = [];
      Object.values(action.location).map(locationDelete => {
        if (locationDelete && locationDelete.id)
          idsDelete.push(locationDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(clone(state.activo), Object.keys(datoLocationDelete)),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_LOCATION:
      let datoLocationDeleteUpdate = action.location;
      let idsDeleteUpdate = [];
      Object.values(action.location).map(locationDelete => {
        if (locationDelete && locationDelete.id)
          idsDeleteUpdate.push(locationDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoLocationDeleteUpdate)
          ),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_LOCATION:
      let datoLocationDeleted = {};
      if (Object.values(action.location.entities.locations).length > 0)
        datoLocationDeleted = Object.values(
          action.location.entities.locations
        )[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoLocationDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_LOCATION:
      let idsCreate = [];
      Object.values(action.location).map(locationCreate => {
        if (locationCreate && locationCreate.id)
          idsCreate.push(locationCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.location,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_LOCATION:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_LOCATION:
      let datoLocationNuevo = action.location.entities.locations;
      let locationNuevo =
        datoLocationNuevo && Object.keys(datoLocationNuevo).length > 0
          ? datoLocationNuevo[action.location.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: locationNuevo,
        nuevos: []
      });
    case ERROR_CREATE_LOCATION:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_LOCATIONS:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_LOCATIONS:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_LOCATIONS:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_LOCATION:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    //COUNTRY
    case SUCCESS_CREATE_COUNTRY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_COUNTRY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_COUNTRY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_COUNTRIES:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });

    case DELETE_LOCATION:
      let datoLocationDelete = action.location;
      let idsDelete = [];
      Object.values(action.location).map(locationDelete => {
        if (locationDelete && locationDelete.id)
          idsDelete.push(locationDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoLocationDelete)),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_LOCATION:
      let datoLocationDeleteCreate = action.location;
      let idsDeleteCreate = [];
      Object.values(action.location).map(locationDelete => {
        if (locationDelete && locationDelete.id)
          idsDeleteCreate.push(locationDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoLocationDeleteCreate)
          ),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_LOCATION:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.location,
        error: null
      });
    case REQUEST_DELETE_LOCATION:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_LOCATION:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_LOCATION:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_LOCATION:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    //COUNTRY
    case SUCCESS_CREATE_COUNTRY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_COUNTRY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_COUNTRY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_COUNTRIES:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_LOCATION:
      let idsCreate = [];
      Object.values(action.location).map(locationCreate => {
        if (locationCreate && locationCreate.id)
          idsCreate.push(locationCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.location,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_LOCATION:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_LOCATION:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_LOCATION:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_LOCATIONS:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_LOCATIONS:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_LOCATIONS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_LOCATION:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_LOCATION:
      let datoLocationDeleteCreate = action.location;
      let idsDeleteCreate = [];
      Object.values(action.location).map(locationDelete => {
        if (locationDelete && locationDelete.id)
          idsDeleteCreate.push(locationDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(
            clone(state.print),
            Object.keys(datoLocationDeleteCreate)
          ),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const locations = combineReducers({
  byId: locationsById,
  allIds: allLocations,
  update: update,
  create: create,
  totalLocations: totalLocations,
  delete: deleter,
  print: print
});

export default locations;
