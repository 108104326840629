import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@material-ui/core';
import { useValidador } from '../../utils/validador';
import Button from '@material-ui/core/Button';

function ModalURLAsset(props) {
  const { open, setOpen, url, setUrl, handleAdd } = props;

  //Hooks
  let reglas = {};
  reglas = {
    url: ['required']
  };
  const [validador, validar] = useValidador(reglas);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickAdd = e => {
    if (validador.isValid()) {
      handleAdd(e, 'url');
      handleClose();
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Escriba la url</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Copie y pegue la URL del recurso que desea agregar.
        </DialogContentText>
        <TextField
          id="url"
          placeholder="URL"
          variant="outlined"
          label={'URL'}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={
            {
              // disabled: disabled
            }
          }
          fullWidth
          value={url ? url : ''}
          onChange={e => setUrl(e.target.value)}
          onBlur={validar}
          error={!validador.isValidById('url')}
          helperText={validador.getHelperTextById('url')}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={handleClickAdd} color="primary">
          Agregar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ModalURLAsset.propTypes = {};

export default ModalURLAsset;
