//api
import postsApi from '../api/postsApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizePosts';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//POSTS
export const REQUEST_POSTS = 'REQUEST_POSTS';
export const RECEIVE_POSTS = 'RECEIVE_POSTS';
export const INVALIDATE_POSTS = 'INVALIDATE_POSTS';
export const ERROR_POSTS = 'ERROR_POSTS';
export const RESET_POSTS = 'RESET_POSTS';

export function invalidatePosts() {
  return {
    type: INVALIDATE_POSTS
  };
}

function requestPosts() {
  return {
    type: REQUEST_POSTS
  };
}

function receivePosts(json) {
  return {
    type: RECEIVE_POSTS,
    posts: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorPosts(error) {
  return {
    type: ERROR_POSTS,
    error: error
  };
}

export function resetPosts() {
  return {
    type: RESET_POSTS
  };
}

export function fetchPosts(filtros) {
  return dispatch => {
    dispatch(requestPosts());
    return postsApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receivePosts(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorPosts(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorPosts(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

function shouldFetchPosts(state) {
  const posts = state.posts.byId;
  if (!posts) {
    return true;
  } else if (posts.isFetching) {
    return false;
  } else {
    return posts.didInvalidate;
  }
}

export function fetchPostsIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchPosts(getState())) {
      return dispatch(fetchPosts(filtros));
    }
  };
}

//MODEL
export const REQUEST_POST = 'REQUEST_POST';
export const RECEIVE_POST = 'RECEIVE_POST';
export const INVALIDATE_POST = 'INVALIDATE_POST';
export const ERROR_POST = 'ERROR_POST';
export const RESET_POST = 'RESET_POST';

export function invalidatePost() {
  return {
    type: INVALIDATE_POST
  };
}

function requestPost() {
  return {
    type: REQUEST_POST
  };
}

export function receivePost(json) {
  return {
    type: RECEIVE_POST,
    post: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorPost(error) {
  return {
    type: ERROR_POST,
    error: error
  };
}

export function fetchPost(idPost) {
  return dispatch => {
    dispatch(requestPost());
    return postsApi
      .getOne(idPost)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receivePost(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(errorPost(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorPost(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_POST = 'RECEIVE_FILE_POST';

function receiveFilePost(file) {
  return {
    type: RECEIVE_FILE_POST,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFilePost(idPost, filtros) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return postsApi
      .getFile(idPost, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFilePost(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorPost(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorPost(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_POST = 'UPDATE_POST';
export const REQUEST_UPDATE_POST = 'REQUEST_UPDATE_POST';
export const SUCCESS_UPDATE_POST = 'SUCCESS_UPDATE_POST';
export const ERROR_UPDATE_POST = 'ERROR_UPDATE_POST';
export const RESET_UPDATE_POST = 'RESET_UPDATE_POST';
export const DELETE_UPDATE_POST = 'DELETE_UPDATE_POST';

function requestUpdatePost() {
  return {
    type: REQUEST_UPDATE_POST
  };
}

function receiveUpdatePost(post) {
  return {
    type: SUCCESS_UPDATE_POST,
    receivedAt: Date.now(),
    post: normalizeDato(post)
  };
}

function errorUpdatePost(error) {
  return {
    type: ERROR_UPDATE_POST,
    error: error
  };
}

export function resetUpdatePost() {
  return {
    type: RESET_UPDATE_POST
  };
}

export function updatePost(post) {
  return {
    type: UPDATE_POST,
    post
  };
}

export function saveUpdatePost(files) {
  return (dispatch, getState) => {
    dispatch(requestUpdatePost());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let post = denormalizeDato(getState().posts.update.activo, store);

    return postsApi
      .saveUpdate(post, files)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdatePost(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdatePost(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdatePosts(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdatePosts(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdatePost(JSON.parse(error.message)));
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdatePost(error.data));
                })
                .catch(() => {
                  dispatch(errorUpdatePost(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

export function deleteUpdatePost(post) {
  return {
    type: DELETE_UPDATE_POST,
    post
  };
}

//UPDATE POSTS
export const REQUEST_UPDATE_POSTS = 'REQUEST_UPDATE_POSTS';
export const SUCCESS_UPDATE_POSTS = 'SUCCESS_UPDATE_POSTS';
export const ERROR_UPDATE_POSTS = 'ERROR_UPDATE_POSTS';
export const RESET_UPDATE_POSTS = 'RESET_UPDATE_POSTS';

function requestUpdatePosts() {
  return {
    type: REQUEST_UPDATE_POSTS
  };
}

function receiveUpdatePosts(posts) {
  return {
    type: SUCCESS_UPDATE_POSTS,
    receivedAt: Date.now(),
    posts: normalizeDatos(posts)
  };
}

function errorUpdatePosts(error) {
  return {
    type: ERROR_UPDATE_POSTS,
    error: error
  };
}

export function resetUpdatePosts() {
  return {
    type: RESET_UPDATE_POSTS
  };
}

export function saveUpdatePosts() {
  return (dispatch, getState) => {
    dispatch(requestUpdatePosts());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let posts = getState().posts.update.activos.map(idPost => {
      return denormalizeDato(getState().posts.update.activo[idPost], store);
    });

    return postsApi
      .saveUpdatePosts(posts)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdatePosts(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdatePosts(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdatePosts(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdatePosts(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdatePosts(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorUpdatePosts(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//ALTA POST
export const CREATE_POST = 'CREATE_POST';
export const REQUEST_CREATE_POST = 'REQUEST_CREATE_POST';
export const SUCCESS_CREATE_POST = 'SUCCESS_CREATE_POST';
export const ERROR_CREATE_POST = 'ERROR_CREATE_POST';
export const RESET_CREATE_POST = 'RESET_CREATE_POST';
export const DELETE_CREATE_POST = 'DELETE_CREATE_POST';

//ALTA POST
function requestCreatePost() {
  return {
    type: REQUEST_CREATE_POST
  };
}

function receiveCreatePost(post) {
  return {
    type: SUCCESS_CREATE_POST,
    receivedAt: Date.now(),
    post: normalizeDato(post)
  };
}

export function errorCreatePost(error) {
  return {
    type: ERROR_CREATE_POST,
    error: error
  };
}

export function resetCreatePost() {
  return {
    type: RESET_CREATE_POST
  };
}

export function createPost(post) {
  return {
    type: CREATE_POST,
    post
  };
}

export function deleteCreatePost(post) {
  return {
    type: DELETE_CREATE_POST,
    post
  };
}

export function saveCreatePost(files) {
  return (dispatch, getState) => {
    dispatch(requestCreatePost());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let post = denormalizeDato(getState().posts.create.nuevo, store);

    return postsApi
      .saveCreate(post, files)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreatePost(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreatePost(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreatePosts(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreatePosts(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreatePost(JSON.parse(error.message)));
                  if (error.data) dispatch(receiveCreatePost(error.data));
                })
                .catch(() => {
                  dispatch(errorCreatePost(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//CREATE POSTS
export const REQUEST_CREATE_POSTS = 'REQUEST_CREATE_POSTS';
export const SUCCESS_CREATE_POSTS = 'SUCCESS_CREATE_POSTS';
export const ERROR_CREATE_POSTS = 'ERROR_CREATE_POSTS';
export const RESET_CREATE_POSTS = 'RESET_CREATE_POSTS';

function requestCreatePosts() {
  return {
    type: REQUEST_CREATE_POSTS
  };
}

function receiveCreatePosts(posts) {
  return {
    type: SUCCESS_CREATE_POSTS,
    receivedAt: Date.now(),
    posts: normalizeDatos(posts)
  };
}

function errorCreatePosts(error) {
  return {
    type: ERROR_CREATE_POSTS,
    error: error
  };
}

export function resetCreatePosts() {
  return {
    type: RESET_CREATE_POSTS
  };
}

export function saveCreatePosts() {
  return (dispatch, getState) => {
    dispatch(requestCreatePosts());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let posts = getState().posts.create.nuevos.map(idPost => {
      return denormalizeDato(getState().posts.create.nuevo[idPost], store);
    });

    return postsApi
      .saveCreatePosts(posts)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreatePosts(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreatePosts(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreatePosts(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreatePosts(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreatePosts(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorCreatePosts(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//DELETE POST
export const DELETE_POST = 'DELETE_POST';
export const REQUEST_DELETE_POST = 'REQUEST_DELETE_POST';
export const SUCCESS_DELETE_POST = 'SUCCESS_DELETE_POST';
export const ERROR_DELETE_POST = 'ERROR_DELETE_POST';
export const RESET_DELETE_POST = 'RESET_DELETE_POST';

function requestDeletePost() {
  return {
    type: REQUEST_DELETE_POST
  };
}

function receiveDeletePost(post) {
  return {
    type: SUCCESS_DELETE_POST,
    receivedAt: Date.now(),
    post: normalizeDato(post)
  };
}

function errorDeletePost(error) {
  return {
    type: ERROR_DELETE_POST,
    error: error
  };
}

export function resetDeletePost(error) {
  return {
    type: RESET_DELETE_POST,
    error: error
  };
}

export function deletePost(post) {
  return {
    type: DELETE_POST,
    post
  };
}

export function saveDeletePost(post) {
  return dispatch => {
    dispatch(requestDeletePost());
    return postsApi
      .saveDelete(post)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeletePost());
        dispatch(receiveDeletePost(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorDeletePost(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeletePost(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorDeletePost(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorDeletePost(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorDeletePost(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT POST
export const PRINT_POST = 'PRINT_POST';
export const REQUEST_PRINT_POST = 'REQUEST_PRINT_POST';
export const SUCCESS_PRINT_POST = 'SUCCESS_PRINT_POST';
export const ERROR_PRINT_POST = 'ERROR_PRINT_POST';
export const RESET_PRINT_POST = 'RESET_PRINT_POST';
export const DELETE_PRINT_POST = 'DELETE_PRINT_POST';

function requestPrintPost() {
  return {
    type: REQUEST_PRINT_POST
  };
}

function receivePrintPost(turnos) {
  return {
    type: SUCCESS_PRINT_POST,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintPost(error) {
  return {
    type: ERROR_PRINT_POST,
    error: error
  };
}

export function resetPrintPost() {
  return {
    type: RESET_PRINT_POST
  };
}

export function printPost(post) {
  return {
    type: PRINT_POST,
    post
  };
}

export function deletePrintPost(post) {
  return {
    type: DELETE_PRINT_POST,
    post
  };
}

export function savePrintPost(idPost) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintPost());
    return postsApi
      .printPost(idPost)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintPost(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintPost(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(errorPrintPost(JSON.parse(error.responseJSON.message)));
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintPost(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintPost(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT POSTS
export const REQUEST_PRINT_POSTS = 'REQUEST_PRINT_POSTS';
export const SUCCESS_PRINT_POSTS = 'SUCCESS_PRINT_POSTS';
export const ERROR_PRINT_POSTS = 'ERROR_PRINT_POSTS';
export const RESET_PRINT_POSTS = 'RESET_PRINT_POSTS';

function requestPrintPosts() {
  return {
    type: REQUEST_PRINT_POSTS
  };
}

function receivePrintPosts(posts) {
  return {
    type: SUCCESS_PRINT_POSTS,
    receivedAt: Date.now(),
    posts: normalizeDatos(posts)
  };
}

function errorPrintPosts(error) {
  return {
    type: ERROR_PRINT_POSTS,
    error: error
  };
}

export function resetPrintPosts() {
  return {
    type: RESET_PRINT_POSTS
  };
}

export function savePrintPosts() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintPost());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let posts = getState().posts.print.printers.map(idPost => {
      return denormalizeDato(getState().posts.print.print[idPost], store);
    });
    return postsApi
      .printPosts(posts)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintPosts(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintPosts(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(errorPrintPosts(JSON.parse(error.responseJSON.message)));
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintPosts(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintPosts(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}
