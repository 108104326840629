import React, { useEffect, useRef } from 'react';
import {
  createPropertyClosedOperation,
  resetCreatePropertyClosedOperation,
  updatePropertyClosedOperation
} from '../../actions/PropertyClosedOperationActions';
import { useDispatch, useSelector } from 'react-redux';
import PropertyClosedOperationModalForm from './PropertyClosedOperationModalForm';
import {
  createProperty,
  resetUpdateProperty,
  saveUpdateProperty,
  updateProperty
} from '../../actions/PropertyActions';

export default function PropertyClosedOperationModal(props) {
  const { property_id, open, setOpen, idPropertyClosedOperation } = props;
  const dispatch = useDispatch();

  const properties = useSelector(state => state.properties);
  const propertyClosedOperations = useSelector(
    state => state.propertyClosedOperations
  );
  const propertyClosedOperation =
    idPropertyClosedOperation &&
    idPropertyClosedOperation.toString().indexOf('-') === -1
      ? propertyClosedOperations.update.activo[idPropertyClosedOperation]
      : propertyClosedOperations.create.nuevo[idPropertyClosedOperation];
  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (!properties.update.isUpdating && !properties.update.error && open) {
        handleClose();
        dispatch(resetCreatePropertyClosedOperation());
      }
    }
  }, [properties.update.isUpdating]);

  useEffect(() => {
    if (property_id !== properties.update.activo.id) {
      dispatch(resetUpdateProperty());
    }
  }, [open]);

  const handleChangePropertyClosedOperation = e => {
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    changePropertyClosedOperation(cambio);
  };

  const handleDateChangePropertyClosedOperation = (e, id) => {
    let cambio = {};
    cambio[id] = e.format('YYYY-MM-DD');
    changePropertyClosedOperation(cambio);
  };

  const handlePropertyTypeChangePropertyClosedOperation = (e, value) => {
    let cambio = {};
    cambio.codType = value;
    changePropertyClosedOperation(cambio);
  };

  const changePropertyClosedOperation = cambio => {
    cambio.id = idPropertyClosedOperation;
    let cambioPropertyClosedOperation = {};
    cambioPropertyClosedOperation[idPropertyClosedOperation] = cambio;
    if (idPropertyClosedOperation.toString().indexOf('-') === -1)
      dispatch(updatePropertyClosedOperation(cambioPropertyClosedOperation));
    else dispatch(createPropertyClosedOperation(cambioPropertyClosedOperation));
  };

  const changeProperty = cambio => {
    if (property_id) dispatch(updateProperty(cambio));
    else dispatch(createProperty(cambio));
  };

  const handleAccept = e => {
    let cambio = { status: 0, id: property_id };
    changeProperty(cambio);
    dispatch(saveUpdateProperty());
  };

  const handleClose = e => {
    setOpen(false);
    dispatch(resetCreatePropertyClosedOperation());
  };

  return (
    <>
      <PropertyClosedOperationModalForm
        handleClose={handleClose}
        handleAccept={handleAccept}
        handleChangePropertyClosedOperation={
          handleChangePropertyClosedOperation
        }
        handleDateChangePropertyClosedOperation={
          handleDateChangePropertyClosedOperation
        }
        handlePropertyTypeChangePropertyClosedOperation={
          handlePropertyTypeChangePropertyClosedOperation
        }
        propertyClosedOperation={propertyClosedOperation}
        open={open}
      />
    </>
  );
}
