import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

//Components
import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core';
import CustomizedNumberInput from './CustomizedNumberInput';
import Box from '@material-ui/core/Box';
import clone from 'lodash/clone';
//Actions
import {
  createPropertyAttributeValue,
  deletePropertyAttributeValue,
  updatePropertyAttributeValue
} from '../../actions/PropertyAttributeValueActions';

const useStyles = makeStyles(theme => ({
  tarjeta: {
    padding: '10px 20px 20px 20px',
    marginBottom: '17px'
  },
  rootLista: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
    // height: "300px",
    // overflowY: "auto",
  },
  listaOperadores: {
    '&.checked': {
      borderLeft: '3px solid',
      borderLeftColor: theme.palette.primary[theme.palette.type]
    }
  },
  containerAreaOp: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.2)
    }
  },
  mensaje: {
    display: 'none',
    color: theme.palette.error.main,
    '&.visible': {
      display: 'inherit'
    }
  },
  container: {
    display: 'flex',
    flexFlow: 'row wrap',
    gap: '0 30px',
    // backgroundColor: "pink",
    height: '100%', // set the height limit to your liking
    // overflow: "auto"
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  itemCheckBox: {
    width: 'auto',
    minWidth: 300,
    maxWidth: 300
  },
  item: {
    width: 'auto'
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 200
  },
  input: {
    marginLeft: theme.spacing(1)
  }
}));

export default function PropertyBasicAttributes(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { disabled, attributeGroup, property } = props;

  //State

  //Store
  const attributes = useSelector(state => state.attributes);
  const attributesValues = useSelector(state => state.attributesValues);
  const propertiesAttributesValues = useSelector(
    state => state.propertiesAttributesValues
  );

  const handleChangePropertyAttributeValue = (
    e,
    idPropertyAttributeValue,
    idAttribute
  ) => {
    let id = idPropertyAttributeValue
      ? idPropertyAttributeValue
      : Date.now() + Math.random() + '-';
    let cambio = {};
    if (!!e.target.value && e.target.value !== '') {
      cambio.value = e.target.value;
      changePropertyAttributeValue(cambio, id, idAttribute);
    } else {
      let _propertyAttributeValue =
        propertiesAttributesValues.byId.propertiesAttributesValues[
          idPropertyAttributeValue
        ];
      if (
        propertiesAttributesValues.update.activos.indexOf(
          idPropertyAttributeValue
        ) !== -1
      )
        _propertyAttributeValue =
          propertiesAttributesValues.update.activo[idPropertyAttributeValue];
      if (
        propertiesAttributesValues.create.nuevos.indexOf(
          idPropertyAttributeValue
        ) !== -1
      )
        _propertyAttributeValue =
          propertiesAttributesValues.create.nuevo[idPropertyAttributeValue];
      handleDeletePropertyAttributeValue(_propertyAttributeValue);
    }
  };

  const handleChangeCheckedPropertyAttributeValue = (
    e,
    idPropertyAttributeValue,
    idAttribute
  ) => {
    let cambio = {};
    if (!idPropertyAttributeValue) {
      cambio.value = true;
      let id = idPropertyAttributeValue
        ? idPropertyAttributeValue
        : Date.now() + Math.random() + '-';
      changePropertyAttributeValue(cambio, id, idAttribute);
    } else {
      let _propertyAttributeValue =
        propertiesAttributesValues.byId.propertiesAttributesValues[
          idPropertyAttributeValue
        ];
      if (
        propertiesAttributesValues.update.activos.indexOf(
          idPropertyAttributeValue
        ) !== -1
      )
        _propertyAttributeValue =
          propertiesAttributesValues.update.activo[idPropertyAttributeValue];
      if (
        propertiesAttributesValues.create.nuevos.indexOf(
          idPropertyAttributeValue
        ) !== -1
      )
        _propertyAttributeValue =
          propertiesAttributesValues.create.nuevo[idPropertyAttributeValue];
      handleDeletePropertyAttributeValue(_propertyAttributeValue);
    }
  };

  const isChecked = idAttribute => {
    let isChecked = false;
    if (property.property_attribute_value)
      isChecked = property.property_attribute_value.some(idAttributeValue => {
        let propertyAttributeValue =
          propertiesAttributesValues.byId.propertiesAttributesValues[
            idAttributeValue
          ];
        if (
          propertiesAttributesValues.update.activos.indexOf(
            idAttributeValue
          ) !== -1
        )
          propertyAttributeValue =
            propertiesAttributesValues.update.activo[idAttributeValue];
        if (
          propertiesAttributesValues.create.nuevos.indexOf(idAttributeValue) !==
          -1
        )
          propertyAttributeValue =
            propertiesAttributesValues.create.nuevo[idAttributeValue];
        if (propertyAttributeValue)
          return propertyAttributeValue.idAttribute === idAttribute;
        else return false;
      });
    return isChecked;
  };

  const changePropertyAttributeValue = (
    cambio,
    idPropertyAttributeValue,
    idAttribute
  ) => {
    //Verificar si ya esta siendo actualizado
    let propertyAttributeValue =
      idPropertyAttributeValue &&
      propertiesAttributesValues.update.activos.indexOf(
        idPropertyAttributeValue
      ) === -1 &&
      propertiesAttributesValues.create.nuevos.indexOf(
        idPropertyAttributeValue
      ) === -1
        ? propertiesAttributesValues.byId.propertiesAttributesValues[
            idPropertyAttributeValue
          ]
        : null;
    if (propertyAttributeValue) {
      let actualizar = propertyAttributeValue
        ? clone(propertyAttributeValue)
        : null;
      if (actualizar) {
        let cambioPropertyAttributeValue = {};
        cambioPropertyAttributeValue[actualizar.id] = actualizar;
        if (idPropertyAttributeValue.toString().indexOf('-') === -1)
          dispatch(updatePropertyAttributeValue(cambioPropertyAttributeValue));
        else
          dispatch(createPropertyAttributeValue(cambioPropertyAttributeValue));
      }
    }
    cambio.id = idPropertyAttributeValue;
    cambio.idProperty = property.id;
    cambio.idAttribute = idAttribute;
    let cambioPropertyAttributeValue = {};
    cambioPropertyAttributeValue[idPropertyAttributeValue] = cambio;
    if (idPropertyAttributeValue.toString().indexOf('-') === -1)
      dispatch(updatePropertyAttributeValue(cambioPropertyAttributeValue));
    else dispatch(createPropertyAttributeValue(cambioPropertyAttributeValue));
  };

  const handleDeletePropertyAttributeValue = propertyAttributeValue => {
    let cambioUbicacion = {};
    if (propertyAttributeValue) {
      cambioUbicacion[propertyAttributeValue.id] = clone(
        propertyAttributeValue
      );
      dispatch(deletePropertyAttributeValue(cambioUbicacion));
    }
  };

  const getComponent = (type, attribute, propertyAttributeValue) => {
    switch (type) {
      case 'boolean': {
        return (
          <ListItem
            key={attribute.id}
            button
            disabled={disabled}
            className={classes.itemCheckBox}
            onClick={e =>
              handleChangeCheckedPropertyAttributeValue(
                e,
                propertyAttributeValue ? propertyAttributeValue.id : null,
                attribute.id
              )
            }
          >
            <ListItemIcon>
              <Checkbox
                edge="end"
                checked={isChecked(attribute.id)}
                // onChange={(e) => handleChangeCheckedPropertyAttributeValue(e, propertyAttributeValue ? propertyAttributeValue.id : null, attribute.id)}
                inputProps={{ 'aria-labelledby': attribute.id }}
                color={'primary'}
                disabled={disabled}
              />
            </ListItemIcon>
            <ListItemText
              id={attribute.id}
              primary={`${attribute ? attribute.nameAttribute : ''}`}
            />
            <ListItemSecondaryAction></ListItemSecondaryAction>
          </ListItem>
        );
      }
      case 'string':
        return (
          <ListItem
            key={attribute.id}
            disabled={disabled}
            className={classes.item}
          >
            <ListItemText
              id={attribute.id}
              primary={`${attribute ? attribute.nameAttribute : ''}`}
              secondary={
                <Box className={classes.root}>
                  <TextField
                    className={classes.input}
                    id="externalId"
                    placeholder={attribute ? attribute.nameAttribute : ''}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      disabled: disabled,
                      type: 'text',
                      min: 1
                    }}
                    fullWidth
                    margin={'dense'}
                    value={
                      propertyAttributeValue && propertyAttributeValue.value
                        ? propertyAttributeValue.value
                        : ''
                    }
                    onChange={e =>
                      handleChangePropertyAttributeValue(
                        e,
                        propertyAttributeValue
                          ? propertyAttributeValue.id
                          : null,
                        attribute.id
                      )
                    }
                    // onKeyDown={(e) => handleKeyDown(e)}
                  />
                </Box>
              }
            />
          </ListItem>
        );
      case 'number':
        return (
          <ListItem
            key={attribute.id}
            disabled={disabled}
            className={classes.item}
          >
            <ListItemText
              id={attribute.id}
              primary={`${attribute ? attribute.nameAttribute : ''}`}
              secondary={
                <CustomizedNumberInput
                  id="externalId"
                  placeholder={0}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    disabled: disabled,
                    type: 'number',
                    min: 1,
                    step: 1
                  }}
                  margin={'dense'}
                  value={
                    propertyAttributeValue && propertyAttributeValue.value
                      ? propertyAttributeValue.value
                      : ''
                  }
                  onChange={e =>
                    handleChangePropertyAttributeValue(
                      e,
                      propertyAttributeValue ? propertyAttributeValue.id : null,
                      attribute.id
                    )
                  }
                />
              }
            />
          </ListItem>
        );
      case 'enum':
        return (
          <ListItem
            key={attribute.id}
            disabled={disabled}
            className={classes.item}
          >
            <ListItemText
              id={attribute.id}
              primary={`${attribute ? attribute.nameAttribute : ''}`}
              secondary={
                <Box className={classes.root}>
                  <TextField
                    id="externalId"
                    placeholder={'0'}
                    select
                    className={classes.input}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      disabled: disabled,
                      type: 'text',
                      min: 1
                    }}
                    // disabled={true}
                    fullWidth
                    margin={'dense'}
                    value={
                      propertyAttributeValue && propertyAttributeValue.value
                        ? propertyAttributeValue.value
                        : ''
                    }
                    onChange={e =>
                      handleChangePropertyAttributeValue(
                        e,
                        propertyAttributeValue
                          ? propertyAttributeValue.id
                          : null,
                        attribute.id
                      )
                    }
                  >
                    {attribute.attribute_value.map(idAttributeValue => {
                      let attributeValue =
                        attributesValues.byId.attributesValues[
                          idAttributeValue
                        ];
                      return (
                        <MenuItem
                          key={attributeValue.codAttributeValue}
                          value={attributeValue.codAttributeValue}
                        >
                          {attributeValue.nameAttributeValue}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Box>
              }
            />
          </ListItem>
        );
      case 'age':
        return (
          <ListItem key={attribute.id} disabled={disabled}>
            <ListItemText
              id={attribute.id}
              primary={`${attribute ? attribute.nameAttribute : ''}`}
              secondary={
                <>
                  <RadioGroup
                    aria-label="age"
                    name="age"
                    value={
                      propertyAttributeValue && propertyAttributeValue.value
                        ? propertyAttributeValue.value
                        : ''
                    }
                    onChange={e =>
                      handleChangePropertyAttributeValue(
                        e,
                        propertyAttributeValue
                          ? propertyAttributeValue.id
                          : null,
                        attribute.id
                      )
                    }
                  >
                    <FormControlLabel
                      value="-1"
                      control={<Radio />}
                      label="En construcción"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="A estrenar"
                    />
                    <FormControlLabel
                      value="1"
                      control={
                        <Radio
                          checked={
                            propertyAttributeValue &&
                            propertyAttributeValue.value >= 1
                          }
                        />
                      }
                      label="Antiguedad"
                    />
                  </RadioGroup>
                  {propertyAttributeValue &&
                    propertyAttributeValue.value >= 1 && (
                      <TextField
                        className={classes.input}
                        id={attribute.id}
                        placeholder={1}
                        variant="outlined"
                        label={'Años'}
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{
                          disabled: disabled,
                          type: 'number',
                          min: 1
                        }}
                        fullWidth
                        margin={'dense'}
                        value={
                          propertyAttributeValue && propertyAttributeValue.value
                            ? propertyAttributeValue.value
                            : ''
                        }
                        onChange={e =>
                          handleChangePropertyAttributeValue(
                            e,
                            propertyAttributeValue
                              ? propertyAttributeValue.id
                              : null,
                            attribute.id
                          )
                        }
                      />
                    )}
                </>
              }
            />
          </ListItem>
        );
      case 'currency':
        return (
          <ListItem
            key={attribute.id}
            disabled={disabled}
            className={classes.item}
          >
            <ListItemText
              id={attribute.id}
              primary={`${attribute ? attribute.nameAttribute : ''}`}
              secondary={
                <Box className={classes.root}>
                  <TextField
                    className={classes.input}
                    id="externalId"
                    placeholder={attribute ? attribute.nameAttribute : ''}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      disabled: disabled,
                      type: 'number',
                      min: 1,
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                    fullWidth
                    margin={'dense'}
                    value={
                      propertyAttributeValue && propertyAttributeValue.value
                        ? propertyAttributeValue.value
                        : ''
                    }
                    onChange={e =>
                      handleChangePropertyAttributeValue(
                        e,
                        propertyAttributeValue
                          ? propertyAttributeValue.id
                          : null,
                        attribute.id
                      )
                    }
                  />
                </Box>
              }
            />
          </ListItem>
        );
      case 'unit':
        return (
          <ListItem
            key={attribute.id}
            disabled={disabled}
            className={classes.item}
          >
            <ListItemText
              id={attribute.id}
              primary={`${attribute ? attribute.nameAttribute : ''}`}
              secondary={
                <Box className={classes.root}>
                  <TextField
                    className={classes.input}
                    id="externalId"
                    placeholder={'0'}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      disabled: disabled,
                      type: 'number',
                      min: 1,
                      endAdornment: (
                        <InputAdornment position="end">m2/ha</InputAdornment>
                      )
                    }}
                    fullWidth
                    margin={'dense'}
                    value={
                      propertyAttributeValue && propertyAttributeValue.value
                        ? propertyAttributeValue.value
                        : ''
                    }
                    onChange={e =>
                      handleChangePropertyAttributeValue(
                        e,
                        propertyAttributeValue
                          ? propertyAttributeValue.id
                          : null,
                        attribute.id
                      )
                    }
                  />
                </Box>
              }
            />
          </ListItem>
        );
    }
  };

  return (
    <List dense className={classes.rootLista} subheader={<li />}>
      <div className={classes.container}>
        {attributeGroup &&
          attributeGroup.attribute &&
          attributeGroup.attribute.map(idAttribute => {
            let attribute = attributes.byId.attributes[idAttribute];
            let propertyAttributeValue = null;
            property.property_attribute_value &&
              property.property_attribute_value.some(
                idPropertyAttributeValue => {
                  let _propertyAttributeValue =
                    propertiesAttributesValues.byId.propertiesAttributesValues[
                      idPropertyAttributeValue
                    ];
                  if (
                    propertiesAttributesValues.update.activos.indexOf(
                      idPropertyAttributeValue
                    ) !== -1
                  )
                    _propertyAttributeValue =
                      propertiesAttributesValues.update.activo[
                        idPropertyAttributeValue
                      ];
                  if (
                    propertiesAttributesValues.create.nuevos.indexOf(
                      idPropertyAttributeValue
                    ) !== -1
                  )
                    _propertyAttributeValue =
                      propertiesAttributesValues.create.nuevo[
                        idPropertyAttributeValue
                      ];
                  if (_propertyAttributeValue.idAttribute === idAttribute) {
                    propertyAttributeValue = _propertyAttributeValue;
                    return true;
                  }
                  return false;
                }
              );
            return getComponent(
              attribute.typeAttribute,
              attribute,
              propertyAttributeValue
            );
          })}
      </div>
    </List>
  );
}
