//api
import statesApi from '../api/statesApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizeStates';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//STATES
export const REQUEST_STATES = 'REQUEST_STATES';
export const RECEIVE_STATES = 'RECEIVE_STATES';
export const INVALIDATE_STATES = 'INVALIDATE_STATES';
export const ERROR_STATES = 'ERROR_STATES';
export const RESET_STATES = 'RESET_STATES';

export function invalidateStates() {
  return {
    type: INVALIDATE_STATES
  };
}

function requestStates() {
  return {
    type: REQUEST_STATES
  };
}

function receiveStates(json) {
  return {
    type: RECEIVE_STATES,
    states: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorStates(error) {
  return {
    type: ERROR_STATES,
    error: error
  };
}

export function resetStates() {
  return {
    type: RESET_STATES
  };
}

export function fetchStates(filtros) {
  return dispatch => {
    dispatch(requestStates());
    return statesApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveStates(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorStates(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorStates(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

function shouldFetchStates(state) {
  const states = state.states.byId;
  if (!states) {
    return true;
  } else if (states.isFetching) {
    return false;
  } else {
    return states.didInvalidate;
  }
}

export function fetchStatesIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchStates(getState())) {
      return dispatch(fetchStates(filtros));
    }
  };
}

//MODEL
export const REQUEST_STATE = 'REQUEST_STATE';
export const RECEIVE_STATE = 'RECEIVE_STATE';
export const INVALIDATE_STATE = 'INVALIDATE_STATE';
export const ERROR_STATE = 'ERROR_STATE';
export const RESET_STATE = 'RESET_STATE';

export function invalidateState() {
  return {
    type: INVALIDATE_STATE
  };
}

function requestState() {
  return {
    type: REQUEST_STATE
  };
}

export function receiveState(json) {
  return {
    type: RECEIVE_STATE,
    state: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorState(error) {
  return {
    type: ERROR_STATE,
    error: error
  };
}

export function fetchState(idState) {
  return dispatch => {
    dispatch(requestState());
    return statesApi
      .getOne(idState)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveState(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(errorState(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorState(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_STATE = 'RECEIVE_FILE_STATE';

function receiveFileState(file) {
  return {
    type: RECEIVE_FILE_STATE,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFileState(idState, filtros) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return statesApi
      .getFile(idState, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFileState(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorState(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorState(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_STATE = 'UPDATE_STATE';
export const REQUEST_UPDATE_STATE = 'REQUEST_UPDATE_STATE';
export const SUCCESS_UPDATE_STATE = 'SUCCESS_UPDATE_STATE';
export const ERROR_UPDATE_STATE = 'ERROR_UPDATE_STATE';
export const RESET_UPDATE_STATE = 'RESET_UPDATE_STATE';
export const DELETE_UPDATE_STATE = 'DELETE_UPDATE_STATE';

function requestUpdateState() {
  return {
    type: REQUEST_UPDATE_STATE
  };
}

function receiveUpdateState(state) {
  return {
    type: SUCCESS_UPDATE_STATE,
    receivedAt: Date.now(),
    state: normalizeDato(state)
  };
}

function errorUpdateState(error) {
  return {
    type: ERROR_UPDATE_STATE,
    error: error
  };
}

export function resetUpdateState() {
  return {
    type: RESET_UPDATE_STATE
  };
}

export function updateState(state) {
  return {
    type: UPDATE_STATE,
    state
  };
}

export function saveUpdateState() {
  return (dispatch, getState) => {
    dispatch(requestUpdateState());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let state = denormalizeDato(getState().states.update.activo, store);

    return statesApi
      .saveUpdate(state)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateState(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateState(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateStates(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdateStates(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateState(JSON.parse(error.message)));
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdateState(error.data));
                })
                .catch(() => {
                  dispatch(errorUpdateState(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

export function deleteUpdateState(state) {
  return {
    type: DELETE_UPDATE_STATE,
    state
  };
}

//UPDATE STATES
export const REQUEST_UPDATE_STATES = 'REQUEST_UPDATE_STATES';
export const SUCCESS_UPDATE_STATES = 'SUCCESS_UPDATE_STATES';
export const ERROR_UPDATE_STATES = 'ERROR_UPDATE_STATES';
export const RESET_UPDATE_STATES = 'RESET_UPDATE_STATES';

function requestUpdateStates() {
  return {
    type: REQUEST_UPDATE_STATES
  };
}

function receiveUpdateStates(states) {
  return {
    type: SUCCESS_UPDATE_STATES,
    receivedAt: Date.now(),
    states: normalizeDatos(states)
  };
}

function errorUpdateStates(error) {
  return {
    type: ERROR_UPDATE_STATES,
    error: error
  };
}

export function resetUpdateStates() {
  return {
    type: RESET_UPDATE_STATES
  };
}

export function saveUpdateStates() {
  return (dispatch, getState) => {
    dispatch(requestUpdateStates());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let states = getState().states.update.activos.map(idState => {
      return denormalizeDato(getState().states.update.activo[idState], store);
    });

    return statesApi
      .saveUpdateStates(states)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateStates(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateStates(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateStates(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdateStates(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateStates(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorUpdateStates(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//ALTA STATE
export const CREATE_STATE = 'CREATE_STATE';
export const REQUEST_CREATE_STATE = 'REQUEST_CREATE_STATE';
export const SUCCESS_CREATE_STATE = 'SUCCESS_CREATE_STATE';
export const ERROR_CREATE_STATE = 'ERROR_CREATE_STATE';
export const RESET_CREATE_STATE = 'RESET_CREATE_STATE';
export const DELETE_CREATE_STATE = 'DELETE_CREATE_STATE';

//ALTA STATE
function requestCreateState() {
  return {
    type: REQUEST_CREATE_STATE
  };
}

function receiveCreateState(state) {
  return {
    type: SUCCESS_CREATE_STATE,
    receivedAt: Date.now(),
    state: normalizeDato(state)
  };
}

export function errorCreateState(error) {
  return {
    type: ERROR_CREATE_STATE,
    error: error
  };
}

export function resetCreateState() {
  return {
    type: RESET_CREATE_STATE
  };
}

export function createState(state) {
  return {
    type: CREATE_STATE,
    state
  };
}

export function deleteCreateState(state) {
  return {
    type: DELETE_CREATE_STATE,
    state
  };
}

export function saveCreateState() {
  return (dispatch, getState) => {
    dispatch(requestCreateState());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let state = denormalizeDato(getState().states.create.nuevo, store);

    return statesApi
      .saveCreate(state)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateState(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateState(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateStates(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreateStates(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateState(JSON.parse(error.message)));
                  if (error.data) dispatch(receiveCreateState(error.data));
                })
                .catch(() => {
                  dispatch(errorCreateState(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//CREATE STATES
export const REQUEST_CREATE_STATES = 'REQUEST_CREATE_STATES';
export const SUCCESS_CREATE_STATES = 'SUCCESS_CREATE_STATES';
export const ERROR_CREATE_STATES = 'ERROR_CREATE_STATES';
export const RESET_CREATE_STATES = 'RESET_CREATE_STATES';

function requestCreateStates() {
  return {
    type: REQUEST_CREATE_STATES
  };
}

function receiveCreateStates(states) {
  return {
    type: SUCCESS_CREATE_STATES,
    receivedAt: Date.now(),
    states: normalizeDatos(states)
  };
}

function errorCreateStates(error) {
  return {
    type: ERROR_CREATE_STATES,
    error: error
  };
}

export function resetCreateStates() {
  return {
    type: RESET_CREATE_STATES
  };
}

export function saveCreateStates() {
  return (dispatch, getState) => {
    dispatch(requestCreateStates());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let states = getState().states.create.nuevos.map(idState => {
      return denormalizeDato(getState().states.create.nuevo[idState], store);
    });

    return statesApi
      .saveCreateStates(states)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateStates(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateStates(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateStates(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreateStates(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateStates(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorCreateStates(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//DELETE STATE
export const DELETE_STATE = 'DELETE_STATE';
export const REQUEST_DELETE_STATE = 'REQUEST_DELETE_STATE';
export const SUCCESS_DELETE_STATE = 'SUCCESS_DELETE_STATE';
export const ERROR_DELETE_STATE = 'ERROR_DELETE_STATE';
export const RESET_DELETE_STATE = 'RESET_DELETE_STATE';

function requestDeleteState() {
  return {
    type: REQUEST_DELETE_STATE
  };
}

function receiveDeleteState(state) {
  return {
    type: SUCCESS_DELETE_STATE,
    receivedAt: Date.now(),
    state: normalizeDato(state)
  };
}

function errorDeleteState(error) {
  return {
    type: ERROR_DELETE_STATE,
    error: error
  };
}

export function resetDeleteState(error) {
  return {
    type: RESET_DELETE_STATE,
    error: error
  };
}

export function deleteState(state) {
  return {
    type: DELETE_STATE,
    state
  };
}

export function saveDeleteState(state) {
  return dispatch => {
    dispatch(requestDeleteState());
    return statesApi
      .saveDelete(state)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeleteState());
        dispatch(receiveDeleteState(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorDeleteState(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeleteState(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorDeleteState(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorDeleteState(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorDeleteState(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT STATE
export const PRINT_STATE = 'PRINT_STATE';
export const REQUEST_PRINT_STATE = 'REQUEST_PRINT_STATE';
export const SUCCESS_PRINT_STATE = 'SUCCESS_PRINT_STATE';
export const ERROR_PRINT_STATE = 'ERROR_PRINT_STATE';
export const RESET_PRINT_STATE = 'RESET_PRINT_STATE';
export const DELETE_PRINT_STATE = 'DELETE_PRINT_STATE';

function requestPrintState() {
  return {
    type: REQUEST_PRINT_STATE
  };
}

function receivePrintState(turnos) {
  return {
    type: SUCCESS_PRINT_STATE,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintState(error) {
  return {
    type: ERROR_PRINT_STATE,
    error: error
  };
}

export function resetPrintState() {
  return {
    type: RESET_PRINT_STATE
  };
}

export function printState(state) {
  return {
    type: PRINT_STATE,
    state
  };
}

export function deletePrintState(state) {
  return {
    type: DELETE_PRINT_STATE,
    state
  };
}

export function savePrintState(idState) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintState());
    return statesApi
      .printState(idState)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintState(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintState(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(errorPrintState(JSON.parse(error.responseJSON.message)));
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintState(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintState(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT STATES
export const REQUEST_PRINT_STATES = 'REQUEST_PRINT_STATES';
export const SUCCESS_PRINT_STATES = 'SUCCESS_PRINT_STATES';
export const ERROR_PRINT_STATES = 'ERROR_PRINT_STATES';
export const RESET_PRINT_STATES = 'RESET_PRINT_STATES';

function requestPrintStates() {
  return {
    type: REQUEST_PRINT_STATES
  };
}

function receivePrintStates(states) {
  return {
    type: SUCCESS_PRINT_STATES,
    receivedAt: Date.now(),
    states: normalizeDatos(states)
  };
}

function errorPrintStates(error) {
  return {
    type: ERROR_PRINT_STATES,
    error: error
  };
}

export function resetPrintStates() {
  return {
    type: RESET_PRINT_STATES
  };
}

export function savePrintStates() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintState());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let states = getState().states.print.printers.map(idState => {
      return denormalizeDato(getState().states.print.print[idState], store);
    });
    return statesApi
      .printStates(states)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintStates(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintStates(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintStates(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintStates(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintStates(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}
