import { normalize, schema, denormalize } from 'normalizr';
import operationType from './normalizeOperationTypes';
import property from './normalizeProperties';

const propertyClosedOperation = new schema.Entity(
  'propertyClosedOperations',
  { operation_type: operationType, property: property },
  { idAttribute: 'id' }
);

export function normalizeDatos(myData) {
  const mySchema = { propertyClosedOperations: [propertyClosedOperation] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = propertyClosedOperation;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = propertyClosedOperation;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default propertyClosedOperation;
