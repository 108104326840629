//api
import dependenciesApi from '../api/dependenciesApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizeDependencies';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//DEPENDENCIES
export const REQUEST_DEPENDENCIES = 'REQUEST_DEPENDENCIES';
export const RECEIVE_DEPENDENCIES = 'RECEIVE_DEPENDENCIES';
export const INVALIDATE_DEPENDENCIES = 'INVALIDATE_DEPENDENCIES';
export const ERROR_DEPENDENCIES = 'ERROR_DEPENDENCIES';
export const RESET_DEPENDENCIES = 'RESET_DEPENDENCIES';

export function invalidateDependencies() {
  return {
    type: INVALIDATE_DEPENDENCIES
  };
}

function requestDependencies() {
  return {
    type: REQUEST_DEPENDENCIES
  };
}

function receiveDependencies(json) {
  return {
    type: RECEIVE_DEPENDENCIES,
    dependencies: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorDependencies(error) {
  return {
    type: ERROR_DEPENDENCIES,
    error: error
  };
}

export function resetDependencies() {
  return {
    type: RESET_DEPENDENCIES
  };
}

export function fetchDependencies(filtros) {
  return dispatch => {
    dispatch(requestDependencies());
    return dependenciesApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveDependencies(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorDependencies(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorDependencies(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

function shouldFetchDependencies(state) {
  const dependencies = state.dependencies.byId;
  if (!dependencies) {
    return true;
  } else if (dependencies.isFetching) {
    return false;
  } else {
    return dependencies.didInvalidate;
  }
}

export function fetchDependenciesIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchDependencies(getState())) {
      return dispatch(fetchDependencies(filtros));
    }
  };
}

//MODEL
export const REQUEST_DEPENDENCY = 'REQUEST_DEPENDENCY';
export const RECEIVE_DEPENDENCY = 'RECEIVE_DEPENDENCY';
export const INVALIDATE_DEPENDENCY = 'INVALIDATE_DEPENDENCY';
export const ERROR_DEPENDENCY = 'ERROR_DEPENDENCY';
export const RESET_DEPENDENCY = 'RESET_DEPENDENCY';

export function invalidateDependency() {
  return {
    type: INVALIDATE_DEPENDENCY
  };
}

function requestDependency() {
  return {
    type: REQUEST_DEPENDENCY
  };
}

export function receiveDependency(json) {
  return {
    type: RECEIVE_DEPENDENCY,
    dependency: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorDependency(error) {
  return {
    type: ERROR_DEPENDENCY,
    error: error
  };
}

export function fetchDependency(idDependency) {
  return dispatch => {
    dispatch(requestDependency());
    return dependenciesApi
      .getOne(idDependency)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveDependency(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(errorDependency(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorDependency(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_DEPENDENCY = 'RECEIVE_FILE_DEPENDENCY';

function receiveFileDependency(file) {
  return {
    type: RECEIVE_FILE_DEPENDENCY,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFileDependency(idDependency, filtros) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return dependenciesApi
      .getFile(idDependency, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFileDependency(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorDependency(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorDependency(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_DEPENDENCY = 'UPDATE_DEPENDENCY';
export const REQUEST_UPDATE_DEPENDENCY = 'REQUEST_UPDATE_DEPENDENCY';
export const SUCCESS_UPDATE_DEPENDENCY = 'SUCCESS_UPDATE_DEPENDENCY';
export const ERROR_UPDATE_DEPENDENCY = 'ERROR_UPDATE_DEPENDENCY';
export const RESET_UPDATE_DEPENDENCY = 'RESET_UPDATE_DEPENDENCY';
export const DELETE_UPDATE_DEPENDENCY = 'DELETE_UPDATE_DEPENDENCY';

function requestUpdateDependency() {
  return {
    type: REQUEST_UPDATE_DEPENDENCY
  };
}

function receiveUpdateDependency(dependency) {
  return {
    type: SUCCESS_UPDATE_DEPENDENCY,
    receivedAt: Date.now(),
    dependency: normalizeDato(dependency)
  };
}

function errorUpdateDependency(error) {
  return {
    type: ERROR_UPDATE_DEPENDENCY,
    error: error
  };
}

export function resetUpdateDependency() {
  return {
    type: RESET_UPDATE_DEPENDENCY
  };
}

export function updateDependency(dependency) {
  return {
    type: UPDATE_DEPENDENCY,
    dependency
  };
}

export function saveUpdateDependency() {
  return (dispatch, getState) => {
    dispatch(requestUpdateDependency());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let dependency = denormalizeDato(
      getState().dependencies.update.activo,
      store
    );

    return dependenciesApi
      .saveUpdate(dependency)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateDependency(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateDependency(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateDependencies(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorUpdateDependencies(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateDependency(JSON.parse(error.message)));
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdateDependency(error.data));
                })
                .catch(() => {
                  dispatch(errorUpdateDependency(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

export function deleteUpdateDependency(dependency) {
  return {
    type: DELETE_UPDATE_DEPENDENCY,
    dependency
  };
}

//UPDATE DEPENDENCIES
export const REQUEST_UPDATE_DEPENDENCIES = 'REQUEST_UPDATE_DEPENDENCIES';
export const SUCCESS_UPDATE_DEPENDENCIES = 'SUCCESS_UPDATE_DEPENDENCIES';
export const ERROR_UPDATE_DEPENDENCIES = 'ERROR_UPDATE_DEPENDENCIES';
export const RESET_UPDATE_DEPENDENCIES = 'RESET_UPDATE_DEPENDENCIES';

function requestUpdateDependencies() {
  return {
    type: REQUEST_UPDATE_DEPENDENCIES
  };
}

function receiveUpdateDependencies(dependencies) {
  return {
    type: SUCCESS_UPDATE_DEPENDENCIES,
    receivedAt: Date.now(),
    dependencies: normalizeDatos(dependencies)
  };
}

function errorUpdateDependencies(error) {
  return {
    type: ERROR_UPDATE_DEPENDENCIES,
    error: error
  };
}

export function resetUpdateDependencies() {
  return {
    type: RESET_UPDATE_DEPENDENCIES
  };
}

export function saveUpdateDependencies() {
  return (dispatch, getState) => {
    dispatch(requestUpdateDependencies());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let dependencies = getState().dependencies.update.activos.map(
      idDependency => {
        return denormalizeDato(
          getState().dependencies.update.activo[idDependency],
          store
        );
      }
    );

    return dependenciesApi
      .saveUpdateDependencies(dependencies)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateDependencies(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateDependencies(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateDependencies(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorUpdateDependencies(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateDependencies(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(
                    errorUpdateDependencies(errorMessages.GENERAL_ERROR)
                  );
                });

            return;
        }
      });
  };
}

//ALTA DEPENDENCY
export const CREATE_DEPENDENCY = 'CREATE_DEPENDENCY';
export const REQUEST_CREATE_DEPENDENCY = 'REQUEST_CREATE_DEPENDENCY';
export const SUCCESS_CREATE_DEPENDENCY = 'SUCCESS_CREATE_DEPENDENCY';
export const ERROR_CREATE_DEPENDENCY = 'ERROR_CREATE_DEPENDENCY';
export const RESET_CREATE_DEPENDENCY = 'RESET_CREATE_DEPENDENCY';
export const DELETE_CREATE_DEPENDENCY = 'DELETE_CREATE_DEPENDENCY';

//ALTA DEPENDENCY
function requestCreateDependency() {
  return {
    type: REQUEST_CREATE_DEPENDENCY
  };
}

function receiveCreateDependency(dependency) {
  return {
    type: SUCCESS_CREATE_DEPENDENCY,
    receivedAt: Date.now(),
    dependency: normalizeDato(dependency)
  };
}

export function errorCreateDependency(error) {
  return {
    type: ERROR_CREATE_DEPENDENCY,
    error: error
  };
}

export function resetCreateDependency() {
  return {
    type: RESET_CREATE_DEPENDENCY
  };
}

export function createDependency(dependency) {
  return {
    type: CREATE_DEPENDENCY,
    dependency
  };
}

export function deleteCreateDependency(dependency) {
  return {
    type: DELETE_CREATE_DEPENDENCY,
    dependency
  };
}

export function saveCreateDependency() {
  return (dispatch, getState) => {
    dispatch(requestCreateDependency());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let dependency = denormalizeDato(
      getState().dependencies.create.nuevo,
      store
    );

    return dependenciesApi
      .saveCreate(dependency)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateDependency(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateDependency(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateDependencies(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorCreateDependencies(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateDependency(JSON.parse(error.message)));
                  if (error.data) dispatch(receiveCreateDependency(error.data));
                })
                .catch(() => {
                  dispatch(errorCreateDependency(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//CREATE DEPENDENCIES
export const REQUEST_CREATE_DEPENDENCIES = 'REQUEST_CREATE_DEPENDENCIES';
export const SUCCESS_CREATE_DEPENDENCIES = 'SUCCESS_CREATE_DEPENDENCIES';
export const ERROR_CREATE_DEPENDENCIES = 'ERROR_CREATE_DEPENDENCIES';
export const RESET_CREATE_DEPENDENCIES = 'RESET_CREATE_DEPENDENCIES';

function requestCreateDependencies() {
  return {
    type: REQUEST_CREATE_DEPENDENCIES
  };
}

function receiveCreateDependencies(dependencies) {
  return {
    type: SUCCESS_CREATE_DEPENDENCIES,
    receivedAt: Date.now(),
    dependencies: normalizeDatos(dependencies)
  };
}

function errorCreateDependencies(error) {
  return {
    type: ERROR_CREATE_DEPENDENCIES,
    error: error
  };
}

export function resetCreateDependencies() {
  return {
    type: RESET_CREATE_DEPENDENCIES
  };
}

export function saveCreateDependencies() {
  return (dispatch, getState) => {
    dispatch(requestCreateDependencies());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let dependencies = getState().dependencies.create.nuevos.map(
      idDependency => {
        return denormalizeDato(
          getState().dependencies.create.nuevo[idDependency],
          store
        );
      }
    );

    return dependenciesApi
      .saveCreateDependencies(dependencies)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateDependencies(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateDependencies(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateDependencies(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorCreateDependencies(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateDependencies(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(
                    errorCreateDependencies(errorMessages.GENERAL_ERROR)
                  );
                });

            return;
        }
      });
  };
}

//DELETE DEPENDENCY
export const DELETE_DEPENDENCY = 'DELETE_DEPENDENCY';
export const REQUEST_DELETE_DEPENDENCY = 'REQUEST_DELETE_DEPENDENCY';
export const SUCCESS_DELETE_DEPENDENCY = 'SUCCESS_DELETE_DEPENDENCY';
export const ERROR_DELETE_DEPENDENCY = 'ERROR_DELETE_DEPENDENCY';
export const RESET_DELETE_DEPENDENCY = 'RESET_DELETE_DEPENDENCY';

function requestDeleteDependency() {
  return {
    type: REQUEST_DELETE_DEPENDENCY
  };
}

function receiveDeleteDependency(dependency) {
  return {
    type: SUCCESS_DELETE_DEPENDENCY,
    receivedAt: Date.now(),
    dependency: normalizeDato(dependency)
  };
}

function errorDeleteDependency(error) {
  return {
    type: ERROR_DELETE_DEPENDENCY,
    error: error
  };
}

export function resetDeleteDependency(error) {
  return {
    type: RESET_DELETE_DEPENDENCY,
    error: error
  };
}

export function deleteDependency(dependency) {
  return {
    type: DELETE_DEPENDENCY,
    dependency
  };
}

export function saveDeleteDependency(dependency) {
  return dispatch => {
    dispatch(requestDeleteDependency());
    return dependenciesApi
      .saveDelete(dependency)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeleteDependency());
        dispatch(receiveDeleteDependency(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorDeleteDependency(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeleteDependency(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorDeleteDependency(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorDeleteDependency(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorDeleteDependency(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT DEPENDENCY
export const PRINT_DEPENDENCY = 'PRINT_DEPENDENCY';
export const REQUEST_PRINT_DEPENDENCY = 'REQUEST_PRINT_DEPENDENCY';
export const SUCCESS_PRINT_DEPENDENCY = 'SUCCESS_PRINT_DEPENDENCY';
export const ERROR_PRINT_DEPENDENCY = 'ERROR_PRINT_DEPENDENCY';
export const RESET_PRINT_DEPENDENCY = 'RESET_PRINT_DEPENDENCY';
export const DELETE_PRINT_DEPENDENCY = 'DELETE_PRINT_DEPENDENCY';

function requestPrintDependency() {
  return {
    type: REQUEST_PRINT_DEPENDENCY
  };
}

function receivePrintDependency(turnos) {
  return {
    type: SUCCESS_PRINT_DEPENDENCY,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintDependency(error) {
  return {
    type: ERROR_PRINT_DEPENDENCY,
    error: error
  };
}

export function resetPrintDependency() {
  return {
    type: RESET_PRINT_DEPENDENCY
  };
}

export function printDependency(dependency) {
  return {
    type: PRINT_DEPENDENCY,
    dependency
  };
}

export function deletePrintDependency(dependency) {
  return {
    type: DELETE_PRINT_DEPENDENCY,
    dependency
  };
}

export function savePrintDependency(idDependency) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintDependency());
    return dependenciesApi
      .printDependency(idDependency)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintDependency(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintDependency(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintDependency(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintDependency(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintDependency(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT DEPENDENCIES
export const REQUEST_PRINT_DEPENDENCIES = 'REQUEST_PRINT_DEPENDENCIES';
export const SUCCESS_PRINT_DEPENDENCIES = 'SUCCESS_PRINT_DEPENDENCIES';
export const ERROR_PRINT_DEPENDENCIES = 'ERROR_PRINT_DEPENDENCIES';
export const RESET_PRINT_DEPENDENCIES = 'RESET_PRINT_DEPENDENCIES';

function requestPrintDependencies() {
  return {
    type: REQUEST_PRINT_DEPENDENCIES
  };
}

function receivePrintDependencies(dependencies) {
  return {
    type: SUCCESS_PRINT_DEPENDENCIES,
    receivedAt: Date.now(),
    dependencies: normalizeDatos(dependencies)
  };
}

function errorPrintDependencies(error) {
  return {
    type: ERROR_PRINT_DEPENDENCIES,
    error: error
  };
}

export function resetPrintDependencies() {
  return {
    type: RESET_PRINT_DEPENDENCIES
  };
}

export function savePrintDependencies() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintDependency());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let dependencies = getState().dependencies.print.printers.map(
      idDependency => {
        return denormalizeDato(
          getState().dependencies.print.print[idDependency],
          store
        );
      }
    );
    return dependenciesApi
      .printDependencies(dependencies)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintDependencies(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintDependencies(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintDependencies(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintDependencies(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintDependencies(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}
