import {
  INVALIDATE_DOMAINS,
  ERROR_DOMAINS,
  RECEIVE_DOMAINS,
  REQUEST_DOMAINS,
  RESET_DOMAINS,
  ERROR_DOMAIN,
  RECEIVE_DOMAIN,
  REQUEST_DOMAIN,
  UPDATE_DOMAIN,
  REQUEST_UPDATE_DOMAIN,
  SUCCESS_UPDATE_DOMAIN,
  ERROR_UPDATE_DOMAIN,
  RESET_UPDATE_DOMAIN,
  REQUEST_UPDATE_DOMAINS,
  SUCCESS_UPDATE_DOMAINS,
  ERROR_UPDATE_DOMAINS,
  RESET_UPDATE_DOMAINS,
  CREATE_DOMAIN,
  ERROR_CREATE_DOMAIN,
  REQUEST_CREATE_DOMAIN,
  RESET_CREATE_DOMAIN,
  SUCCESS_CREATE_DOMAIN,
  REQUEST_CREATE_DOMAINS,
  SUCCESS_CREATE_DOMAINS,
  ERROR_CREATE_DOMAINS,
  RESET_CREATE_DOMAINS,
  DELETE_DOMAIN,
  DELETE_CREATE_DOMAIN,
  DELETE_UPDATE_DOMAIN,
  REQUEST_DELETE_DOMAIN,
  SUCCESS_DELETE_DOMAIN,
  ERROR_DELETE_DOMAIN,
  RESET_DELETE_DOMAIN,
  REQUEST_PRINT_DOMAIN,
  SUCCESS_PRINT_DOMAIN,
  ERROR_PRINT_DOMAIN,
  RESET_PRINT_DOMAIN,
  RECEIVE_FILE_DOMAIN,
  REQUEST_PRINT_DOMAINS,
  SUCCESS_PRINT_DOMAINS,
  ERROR_PRINT_DOMAINS,
  RESET_PRINT_DOMAINS,
  RECEIVE_FILE_DOMAINS,
  PRINT_DOMAIN,
  DELETE_PRINT_DOMAIN
} from '../actions/DomainActions';

import {
  RECEIVE_TENANT,
  RECEIVE_TENANTS,
  SUCCESS_DELETE_TENANT,
  SUCCESS_CREATE_TENANT,
  SUCCESS_UPDATE_TENANT,
  SUCCESS_UPDATE_TENANTS
} from '../actions/TenantActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    domains: {},
    files: {}
  };
}

function domainsById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_DOMAINS:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_DOMAINS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_DOMAINS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_DOMAINS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        domains: {}
      });
    case RECEIVE_DOMAINS:
      let dato = action.domains.entities.domains;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        domains: merge({}, state.domains, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_DOMAIN:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_DOMAIN:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_DOMAIN:
      let datoDomain = action.domain.entities.domains;
      return Object.assign({}, state, {
        domains: merge({}, state.domains, datoDomain),
        isFetching: false
      });
    case RECEIVE_FILE_DOMAIN:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_DOMAIN:
      let datoDomainEliminado = action.domain.entities.domains;
      return Object.assign({}, state, {
        domains: mergeWith(
          clone(datoDomainEliminado),
          state.domains,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_DOMAIN:
      let datoDomainCreado = action.domain.entities.domains;
      return Object.assign({}, state, {
        domains: mergeWith(
          clone(datoDomainCreado),
          state.domains,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_DOMAINS:
      let datosDomainCreado = action.domains.entities.domains;
      return Object.assign({}, state, {
        domains: mergeWith(
          clone(datosDomainCreado),
          state.domains,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_DOMAIN:
      let datoDomainActualizado = action.domain.entities.domains;
      return Object.assign({}, state, {
        domains: mergeWith(
          clone(datoDomainActualizado),
          state.domains,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_DOMAINS:
      let datosDomainActualizado = action.domains.entities.domains;
      return Object.assign({}, state, {
        domains: mergeWith(
          clone(datosDomainActualizado),
          state.domains,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    //TENANT
    case RECEIVE_TENANT:
      let tenant =
        action.tenant.entities && action.tenant.entities.domains
          ? action.tenant.entities.domains
          : {};
      return Object.assign({}, state, {
        domains: merge({}, state.domains, tenant)
      });
    case RECEIVE_TENANTS:
      let tenants =
        action.tenants.entities && action.tenants.entities.domains
          ? action.tenants.entities.domains
          : {};
      return Object.assign({}, state, {
        domains: merge({}, state.domains, tenants)
      });
    case SUCCESS_DELETE_TENANT:
      let datotenantEliminado =
        action.tenant.entities && action.tenant.entities.domains
          ? action.tenant.entities.domains
          : {};
      return Object.assign({}, state, {
        domains: mergeWith(
          clone(datotenantEliminado),
          pickBy(state.domains, function (domain) {
            return domain.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_TENANT:
      let datotenantCreado =
        action.tenant.entities && action.tenant.entities.domains
          ? action.tenant.entities.domains
          : {};
      return Object.assign({}, state, {
        domains: mergeWith(
          clone(datotenantCreado),
          pickBy(state.domains, function (domain) {
            return domain.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TENANT:
      let datotenantActualizado =
        action.tenant.entities && action.tenant.entities.domains
          ? action.tenant.entities.domains
          : {};
      return Object.assign({}, state, {
        domains: mergeWith(
          clone(datotenantActualizado),
          pickBy(state.domains, function (domain) {
            return domain.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TENANTS:
      let datostenantActualizado =
        action.tenants.entities && action.tenants.entities.domains
          ? action.tenants.entities.domains
          : {};
      return Object.assign({}, state, {
        domains: mergeWith(
          clone(datostenantActualizado),
          state.domains,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        domains: {}
      });
    default:
      return state;
  }
}

function allDomains(state = [], action) {
  switch (action.type) {
    case RECEIVE_DOMAINS:
      return action.domains.result && action.domains.result.domains
        ? union(action.domains.result.domains, state)
        : action.domains.result
        ? action.domains.result
        : state;
    case RECEIVE_DOMAIN:
      return action.domain.result
        ? union([action.domain.result], state)
        : state;

    case SUCCESS_CREATE_DOMAIN:
      let datoDomainSCreate = action.domain.entities.domains;
      let idNuevoSCreate = null;
      if (Object.values(datoDomainSCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoDomainSCreate)[0] &&
          Object.values(datoDomainSCreate)[0].id
            ? Object.values(datoDomainSCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_DOMAINS:
      let domainsCreate =
        action.domains.entities && action.domains.entities.domains
          ? action.domains.entities.domains
          : null;
      return domainsCreate
        ? union(
            state,
            Object.values(domainsCreate).map(domains => {
              return domains.id;
            })
          )
        : state;
    case RESET_DOMAINS:
      return [];

    case RECEIVE_TENANT:
      let tenant =
        action.tenant.entities && action.tenant.entities.domains
          ? action.tenant.entities.domains
          : null;
      return tenant
        ? union(
            state,
            Object.values(tenant).map(tenant => {
              return tenant.id;
            })
          )
        : state;
    case RECEIVE_TENANTS:
      let tenants =
        action.tenants.entities && action.tenants.entities.domains
          ? action.tenants.entities.domains
          : null;
      return tenants
        ? union(
            state,
            Object.values(tenants).map(tenants => {
              return tenants.id;
            })
          )
        : state;

    case SUCCESS_DELETE_TENANT:
      let tenantDelete =
        action.tenant.entities && action.tenant.entities.domains
          ? action.tenant.entities.domains
          : null;
      return tenantDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(tenantDelete).map(tenant => {
              return tenant.id;
            })
          )
        : state;
    case SUCCESS_CREATE_TENANT:
      let tenantCreate =
        action.tenant.entities && action.tenant.entities.domains
          ? action.tenant.entities.domains
          : null;
      return tenantCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(tenantCreate).map(tenant => {
              return tenant.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_TENANT:
      let tenantUpdate =
        action.tenant.entities && action.tenant.entities.domains
          ? action.tenant.entities.domains
          : null;
      return tenantUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(tenantUpdate).map(tenant => {
              return tenant.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_TENANTS:
      let tenantsUpdate =
        action.tenants.entities && action.tenants.entities.domains
          ? action.tenants.entities.domains
          : null;
      return tenantsUpdate
        ? union(
            state,
            Object.values(tenantsUpdate).map(tenants => {
              return tenants.id;
            })
          )
        : state;

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalDomains(state = null, action) {
  switch (action.type) {
    case RECEIVE_DOMAINS:
      return action.domains && action.domains.result.total
        ? action.domains.result.total
        : 0;
    case RESET_DOMAINS:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_DOMAIN:
      let dato = action.domain.entities.domains;
      let domain =
        dato && Object.keys(dato).length > 0 ? dato[action.domain.result] : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: domain ? domain : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_DOMAIN:
      let idsUpdate = [];
      Object.values(action.domain).map(domainUpdate => {
        if (domainUpdate && domainUpdate.id) idsUpdate.push(domainUpdate.id);
      });
      return merge({}, state, {
        activo: action.domain,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_DOMAIN:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_DOMAIN:
      let datoDomainActualizado = action.domain.entities.domains;
      let domainNuevo =
        datoDomainActualizado && Object.keys(datoDomainActualizado).length > 0
          ? datoDomainActualizado[action.domain.result]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: domainNuevo
      });
    case ERROR_UPDATE_DOMAIN:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_DOMAINS:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_DOMAINS:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_DOMAINS:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_DOMAIN:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    //TENANT
    //TODO ver si esta bien
    case SUCCESS_CREATE_TENANT:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_TENANT:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_TENANT:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_TENANTS:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });

    case DELETE_DOMAIN:
      let datoDomainDelete = action.domain;
      let idsDelete = [];
      Object.values(action.domain).map(domainDelete => {
        if (domainDelete && domainDelete.id) idsDelete.push(domainDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(clone(state.activo), Object.keys(datoDomainDelete)),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_DOMAIN:
      let datoDomainDeleteUpdate = action.domain;
      let idsDeleteUpdate = [];
      Object.values(action.domain).map(domainDelete => {
        if (domainDelete && domainDelete.id)
          idsDeleteUpdate.push(domainDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoDomainDeleteUpdate)
          ),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_DOMAIN:
      let datoDomainDeleted = {};
      if (Object.values(action.domain.entities.domains).length > 0)
        datoDomainDeleted = Object.values(action.domain.entities.domains)[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoDomainDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_DOMAIN:
      let idsCreate = [];
      Object.values(action.domain).map(domainCreate => {
        if (domainCreate && domainCreate.id) idsCreate.push(domainCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.domain,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_DOMAIN:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_DOMAIN:
      let datoDomainNuevo = action.domain.entities.domains;
      let domainNuevo =
        datoDomainNuevo && Object.keys(datoDomainNuevo).length > 0
          ? datoDomainNuevo[action.domain.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: domainNuevo,
        nuevos: []
      });
    case ERROR_CREATE_DOMAIN:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_DOMAINS:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_DOMAINS:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_DOMAINS:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_DOMAIN:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    //TENANT
    case SUCCESS_CREATE_TENANT:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_TENANT:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_TENANT:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_TENANTS:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });

    case DELETE_DOMAIN:
      let datoDomainDelete = action.domain;
      let idsDelete = [];
      Object.values(action.domain).map(domainDelete => {
        if (domainDelete && domainDelete.id) idsDelete.push(domainDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoDomainDelete)),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_DOMAIN:
      let datoDomainDeleteCreate = action.domain;
      let idsDeleteCreate = [];
      Object.values(action.domain).map(domainDelete => {
        if (domainDelete && domainDelete.id)
          idsDeleteCreate.push(domainDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoDomainDeleteCreate)),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_DOMAIN:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.domain,
        error: null
      });
    case REQUEST_DELETE_DOMAIN:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_DOMAIN:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_DOMAIN:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_DOMAIN:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    //TENANT
    case SUCCESS_CREATE_TENANT:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_TENANT:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_TENANT:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_TENANTS:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_DOMAIN:
      let idsCreate = [];
      Object.values(action.domain).map(domainCreate => {
        if (domainCreate && domainCreate.id) idsCreate.push(domainCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.domain,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_DOMAIN:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_DOMAIN:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_DOMAIN:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_DOMAINS:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_DOMAINS:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_DOMAINS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_DOMAIN:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_DOMAIN:
      let datoDomainDeleteCreate = action.domain;
      let idsDeleteCreate = [];
      Object.values(action.domain).map(domainDelete => {
        if (domainDelete && domainDelete.id)
          idsDeleteCreate.push(domainDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(clone(state.print), Object.keys(datoDomainDeleteCreate)),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const domains = combineReducers({
  byId: domainsById,
  allIds: allDomains,
  update: update,
  create: create,
  totalDomains: totalDomains,
  delete: deleter,
  print: print
});

export default domains;
