import CustomPaper from '../../library/CustomPaper/CustomPaper';
import PaperHeader from '../../library/CustomPaper/PaperHeader';
import {
  Box,
  Chip,
  Container,
  InputAdornment,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CropFreeIcon from '@material-ui/icons/Search';
import Grow from '../../library/Grow';
import PaperFooter from '../../library/CustomPaper/PaperFooter';
import Pagination from '@material-ui/lab/Pagination';
import ContainerBtn from '../../library/Buttons/ContainerBtn';
import BtnAdd from '../../library/Buttons/BtnAdd';
import React, { useRef, useState } from 'react';
import { Skeleton } from '@material-ui/lab';
import { Link, useParams } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import c from '../../constants/Constants';
import SVGimg from '../../library/SVGimg';
import PropertyAttributeChips from './PropertyAttributeChips';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router';
import propertyReset from '../../reset/propertyReset';

const useStyles = makeStyles(theme => ({
  form: {
    padding: theme.spacing(2, 0)
  },
  root: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: 151,
    backgroundSize: 'contain'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  avatar: {
    borderRadius: '100%',
    backgroundColor: theme.palette.primary.main,
    '& svg': {
      fill: 'white'
    }
  },
  divAvatar: {
    width: '50px',
    height: '50px',
    padding: '8px'
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

export default function DevelopmentPropertiesListEmbededd(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  let { idProperty } = useParams();
  const mounted = useRef();
  const timer = useRef();
  const history = useHistory();
  const { property, disabled } = props;

  const [search, setSearch] = useState('');
  const [attributeRoom, setAttributeRoom] = useState(null);

  const attributes = useSelector(state => state.attributes);
  const propertyOperations = useSelector(state => state.propertyOperations);
  const attributesValues = useSelector(state => state.attributesValues);
  const propertiesAttributesValues = useSelector(
    state => state.propertiesAttributesValues
  );

  const changeSearch = e => {
    clearTimeout(timer.current);
    let valor = e.target.value;
    setSearch(e.target.value);

    timer.current = setTimeout(() => triggerChange(valor), 200);
  };

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      clearTimeout(timer.current);
      triggerChange();
    }
  };

  const triggerChange = searchPhase => {
    // if ((!searchPhase && !search) || (searchPhase === "")) {
    //     params.delete("sp");
    //     props.history.push("/users?" + params.toString());
    // } else {
    //     params.delete("page");
    //     params.set("sp", searchPhase ? searchPhase : search);
    //     props.history.push("/users?" + params.toString());
    // }
  };

  //Store
  const properties = useSelector(state => state.properties);

  let Properties = [];
  let PropertiesPerRooms = [];

  const addProperty = () => {
    propertyReset.resetAll(dispatch);
    history.push('/properties/new/' + property.id);
  };

  if (properties.byId.isFetching) {
    Properties = [...Array(5).keys()].map(i => {
      return (
        <TableRow key={'inv' + i}>
          {[...Array(5).keys()].map(i => (
            <TableCell>
              <Skeleton variant="text" />
            </TableCell>
          ))}
        </TableRow>
      );
    });
  }

  if (!properties.byId.isFetching && property.development_property)
    property.development_property.forEach(idProperty => {
      let developmentProperty = properties.byId.properties[idProperty];
      let surface_meaurement = null;
      let semiroofed_surface = null;
      let total_surface = null;
      let roofed_surface = null;
      let unroofed_surface = null;
      let price = null;
      let rooms = 0;
      developmentProperty &&
        developmentProperty.property_attribute_value &&
        developmentProperty.property_attribute_value.forEach(
          idPropertyAttributeValue => {
            let propertyAttributeValue =
              propertiesAttributesValues.byId.propertiesAttributesValues[
                idPropertyAttributeValue
              ];
            if (
              propertiesAttributesValues.update.activos.indexOf(
                idPropertyAttributeValue
              ) !== -1
            )
              propertyAttributeValue =
                propertiesAttributesValues.update.activo[
                  idPropertyAttributeValue
                ];
            if (
              propertiesAttributesValues.create.nuevos.indexOf(
                idPropertyAttributeValue
              ) !== -1
            )
              propertyAttributeValue =
                propertiesAttributesValues.create.nuevo[
                  idPropertyAttributeValue
                ];
            let attribute = null;
            if (propertyAttributeValue) {
              attribute =
                attributes.byId.attributes[propertyAttributeValue.idAttribute];
              if (attribute && attribute.codAttribute === 'surface_measurement')
                surface_meaurement = propertyAttributeValue.value.toLowerCase();
              if (attribute && attribute.codAttribute === 'semiroofed_surface')
                semiroofed_surface = propertyAttributeValue.value;
              if (attribute && attribute.codAttribute === 'total_surface')
                total_surface = propertyAttributeValue.value;
              if (attribute && attribute.codAttribute === 'roofed_surface')
                roofed_surface = propertyAttributeValue.value;
              if (attribute && attribute.codAttribute === 'unroofed_surface')
                unroofed_surface = propertyAttributeValue.value;
              if (attribute && attribute.codAttribute === 'suite_amount') {
                rooms = parseInt(propertyAttributeValue.value);
                if (!attributeRoom) setAttributeRoom(attribute);
              }
            }
          }
        );
      //Price
      developmentProperty &&
        developmentProperty.property_operation &&
        developmentProperty.property_operation.forEach(idPropertyOperation => {
          let propertyOperation =
            propertyOperations.byId.propertyOperations[idPropertyOperation];
          if (
            propertyOperations.update.activos.indexOf(idPropertyOperation) !==
            -1
          )
            propertyOperation =
              propertyOperations.update.activo[idPropertyOperation];
          if (
            propertyOperations.create.nuevos.indexOf(idPropertyOperation) !== -1
          )
            propertyOperation =
              propertyOperations.create.nuevo[idPropertyOperation];
          if (
            propertyOperation &&
            propertyOperation.codType &&
            propertyOperation.codCurrency &&
            propertyOperation.price
          )
            price =
              propertyOperation.codCurrency + ' ' + propertyOperation.price;
        });

      if (!PropertiesPerRooms[rooms]) PropertiesPerRooms[rooms] = [];

      PropertiesPerRooms[rooms].push(
        <TableRow key={developmentProperty.id}>
          <TableCell>
            {developmentProperty && developmentProperty.floor
              ? developmentProperty.floor +
                (developmentProperty.apartment
                  ? '-' + developmentProperty.apartment
                  : '')
              : developmentProperty.address
              ? developmentProperty.address
              : ''}
          </TableCell>
          <TableCell>
            {roofed_surface ? roofed_surface + surface_meaurement : ''}
          </TableCell>
          <TableCell>
            {semiroofed_surface
              ? unroofed_surface
                ? semiroofed_surface +
                  surface_meaurement +
                  '-' +
                  unroofed_surface +
                  surface_meaurement
                : semiroofed_surface + surface_meaurement
              : ''}
          </TableCell>
          <TableCell>
            {total_surface ? total_surface + surface_meaurement : ''}
          </TableCell>
          <TableCell>
            <PropertyAttributeChips
              property={developmentProperty}
              listAttributesGroups={['basic']}
            />
          </TableCell>
          <TableCell>
            <Typography variant={'subtitle1'} color={'primary'}>
              {price ? price : ''}
            </Typography>
          </TableCell>
          <TableCell>
            <Switch
              disabled={disabled}
              checked={!!developmentProperty.status}
              // onChange={() => handleChangeEstado(user.id)}
              name={'estado'}
              color={'primary'}
              size={'small'}
            />
          </TableCell>
          <TableCell>
            <Link to={'/properties/' + developmentProperty.id}>
              <Tooltip title="Editar">
                <IconButton
                  aria-label="editar"
                  size={'small'}
                  disabled={disabled}
                >
                  <EditIcon color="secondary" />
                </IconButton>
              </Tooltip>
            </Link>
          </TableCell>
        </TableRow>
      );
    });

  if (Properties.length === 0)
    Properties.push(
      <TableRow key={0}>
        <TableCell align="center" colSpan={11}>
          No se encontraron propiedades relacionadas al emprendimiento
        </TableCell>
      </TableRow>
    );

  return (
    <CustomPaper>
      <PaperHeader style={{ justifyContent: 'flex-end' }}>
        <Button color={'primary'} variant={'outlined'} onClick={addProperty}>
          Agregar propiedad
        </Button>
      </PaperHeader>
      {PropertiesPerRooms.map((Properties, i) => {
        console.log(Properties);
        if (Properties.length > 0)
          return (
            <>
              <Box
                display={'flex'}
                flexDirection={'row'}
                alignItems={'center'}
                gridColumnGap={'20px'}
                marginLeft={'20px'}
                marginBottom={'20px'}
              >
                {attributeRoom && (
                  <Box className={classes.avatar}>
                    <SVGimg
                      src={c.API_HOST + attributeRoom.path}
                      className={classes.divAvatar}
                    />
                  </Box>
                )}
                <Box display={'flex'} flexDirection={'column'}>
                  <Typography
                    variant={'subtitle1'}
                    component={'span'}
                    color={'primary'}
                  >
                    {i + ' dormitorios'}
                  </Typography>
                  <Typography variant={'body2'} component={'span'}>
                    {Properties.length + ' disponibles'}
                  </Typography>
                </Box>
              </Box>
              <TableContainer style={{ marginBottom: '50px' }}>
                <Table aria-label="Tabla de contenido">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableSortLabel>
                          <b>Unidad</b>
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel>
                          <b>Superficie cubierta</b>
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel>
                          <b>Superficie semi/descubierta</b>
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel>
                          <b>Superficie total</b>
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <b>Características</b>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel>
                          <b>Precio</b>
                        </TableSortLabel>
                      </TableCell>

                      <TableCell width={'10px'} />
                      <TableCell width={'10px'} />
                    </TableRow>
                  </TableHead>
                  <TableBody>{Properties}</TableBody>
                </Table>
              </TableContainer>
            </>
          );
      })}

      <PaperFooter style={{ justifyContent: 'center' }}></PaperFooter>
    </CustomPaper>
    // <ContainerBtn>
    //     <BtnAdd onClick={() => props.history.push("/properties/new")} fetching={disabled}/>
    // </ContainerBtn>
  );
}
