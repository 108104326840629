import {
  INVALIDATE_TICKETPROPERTIES,
  ERROR_TICKETPROPERTIES,
  RECEIVE_TICKETPROPERTIES,
  REQUEST_TICKETPROPERTIES,
  RESET_TICKETPROPERTIES,
  ERROR_TICKETPROPERTY,
  RECEIVE_TICKETPROPERTY,
  REQUEST_TICKETPROPERTY,
  UPDATE_TICKETPROPERTY,
  REQUEST_UPDATE_TICKETPROPERTY,
  SUCCESS_UPDATE_TICKETPROPERTY,
  ERROR_UPDATE_TICKETPROPERTY,
  RESET_UPDATE_TICKETPROPERTY,
  REQUEST_UPDATE_TICKETPROPERTIES,
  SUCCESS_UPDATE_TICKETPROPERTIES,
  ERROR_UPDATE_TICKETPROPERTIES,
  RESET_UPDATE_TICKETPROPERTIES,
  CREATE_TICKETPROPERTY,
  ERROR_CREATE_TICKETPROPERTY,
  REQUEST_CREATE_TICKETPROPERTY,
  RESET_CREATE_TICKETPROPERTY,
  SUCCESS_CREATE_TICKETPROPERTY,
  REQUEST_CREATE_TICKETPROPERTIES,
  SUCCESS_CREATE_TICKETPROPERTIES,
  ERROR_CREATE_TICKETPROPERTIES,
  RESET_CREATE_TICKETPROPERTIES,
  DELETE_TICKETPROPERTY,
  DELETE_CREATE_TICKETPROPERTY,
  DELETE_UPDATE_TICKETPROPERTY,
  REQUEST_DELETE_TICKETPROPERTY,
  SUCCESS_DELETE_TICKETPROPERTY,
  ERROR_DELETE_TICKETPROPERTY,
  RESET_DELETE_TICKETPROPERTY,
  REQUEST_PRINT_TICKETPROPERTY,
  SUCCESS_PRINT_TICKETPROPERTY,
  ERROR_PRINT_TICKETPROPERTY,
  RESET_PRINT_TICKETPROPERTY,
  RECEIVE_FILE_TICKETPROPERTY,
  REQUEST_PRINT_TICKETPROPERTIES,
  SUCCESS_PRINT_TICKETPROPERTIES,
  ERROR_PRINT_TICKETPROPERTIES,
  RESET_PRINT_TICKETPROPERTIES,
  RECEIVE_FILE_TICKETPROPERTIES,
  PRINT_TICKETPROPERTY,
  DELETE_PRINT_TICKETPROPERTY
} from '../actions/TicketPropertyActions';

import {
  RECEIVE_TICKET,
  RECEIVE_TICKETS,
  SUCCESS_DELETE_TICKET,
  SUCCESS_CREATE_TICKET,
  SUCCESS_UPDATE_TICKET,
  SUCCESS_UPDATE_TICKETS
} from '../actions/TicketActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    ticketProperties: {},
    files: {}
  };
}

function ticketPropertiesById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_TICKETPROPERTIES:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_TICKETPROPERTIES:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_TICKETPROPERTIES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_TICKETPROPERTIES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        ticketProperties: {}
      });
    case RECEIVE_TICKETPROPERTIES:
      let dato = action.ticketProperties.entities.ticketProperties;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        ticketProperties: merge({}, state.ticketProperties, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_TICKETPROPERTY:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_TICKETPROPERTY:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_TICKETPROPERTY:
      let datoTicketProperty = action.ticketProperty.entities.ticketProperties;
      return Object.assign({}, state, {
        ticketProperties: merge({}, state.ticketProperties, datoTicketProperty),
        isFetching: false
      });
    case RECEIVE_FILE_TICKETPROPERTY:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_TICKETPROPERTY:
      let datoTicketPropertyEliminado =
        action.ticketProperty.entities.ticketProperties;
      return Object.assign({}, state, {
        ticketProperties: mergeWith(
          clone(datoTicketPropertyEliminado),
          state.ticketProperties,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_TICKETPROPERTY:
      let datoTicketPropertyCreado =
        action.ticketProperty.entities.ticketProperties;
      return Object.assign({}, state, {
        ticketProperties: mergeWith(
          clone(datoTicketPropertyCreado),
          state.ticketProperties,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_TICKETPROPERTIES:
      let datosTicketPropertyCreado =
        action.ticketProperties.entities.ticketProperties;
      return Object.assign({}, state, {
        ticketProperties: mergeWith(
          clone(datosTicketPropertyCreado),
          state.ticketProperties,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TICKETPROPERTY:
      let datoTicketPropertyActualizado =
        action.ticketProperty.entities.ticketProperties;
      return Object.assign({}, state, {
        ticketProperties: mergeWith(
          clone(datoTicketPropertyActualizado),
          state.ticketProperties,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TICKETPROPERTIES:
      let datosTicketPropertyActualizado =
        action.ticketProperties.entities.ticketProperties;
      return Object.assign({}, state, {
        ticketProperties: mergeWith(
          clone(datosTicketPropertyActualizado),
          state.ticketProperties,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    //TICKET
    case RECEIVE_TICKET:
      let ticket =
        action.ticket.entities && action.ticket.entities.ticketProperties
          ? action.ticket.entities.ticketProperties
          : {};
      return Object.assign({}, state, {
        ticketProperties: merge({}, state.ticketProperties, ticket)
      });
    case RECEIVE_TICKETS:
      let tickets =
        action.tickets.entities && action.tickets.entities.ticketProperties
          ? action.tickets.entities.ticketProperties
          : {};
      return Object.assign({}, state, {
        ticketProperties: merge({}, state.ticketProperties, tickets)
      });
    case SUCCESS_DELETE_TICKET:
      let datoticketEliminado =
        action.ticket.entities && action.ticket.entities.ticketProperties
          ? action.ticket.entities.ticketProperties
          : {};
      return Object.assign({}, state, {
        ticketProperties: mergeWith(
          clone(datoticketEliminado),
          pickBy(state.ticketProperties, function (ticketProperty) {
            return ticketProperty.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_TICKET:
      let datoticketCreado =
        action.ticket.entities && action.ticket.entities.ticketProperties
          ? action.ticket.entities.ticketProperties
          : {};
      return Object.assign({}, state, {
        ticketProperties: mergeWith(
          clone(datoticketCreado),
          pickBy(state.ticketProperties, function (ticketProperty) {
            return ticketProperty.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TICKET:
      let datoticketActualizado =
        action.ticket.entities && action.ticket.entities.ticketProperties
          ? action.ticket.entities.ticketProperties
          : {};
      return Object.assign({}, state, {
        ticketProperties: mergeWith(
          clone(datoticketActualizado),
          pickBy(state.ticketProperties, function (ticketProperty) {
            return ticketProperty.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TICKETS:
      let datosticketActualizado =
        action.tickets.entities && action.tickets.entities.ticketProperties
          ? action.tickets.entities.ticketProperties
          : {};
      return Object.assign({}, state, {
        ticketProperties: mergeWith(
          clone(datosticketActualizado),
          state.ticketProperties,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        ticketProperties: {}
      });
    default:
      return state;
  }
}

function allTicketProperties(state = [], action) {
  switch (action.type) {
    case RECEIVE_TICKETPROPERTIES:
      return action.ticketProperties.result &&
        action.ticketProperties.result.ticketProperties
        ? union(action.ticketProperties.result.ticketProperties, state)
        : action.ticketProperties.result
        ? action.ticketProperties.result
        : state;
    case RECEIVE_TICKETPROPERTY:
      return action.ticketProperty.result
        ? union([action.ticketProperty.result], state)
        : state;

    case SUCCESS_CREATE_TICKETPROPERTY:
      let datoTicketPropertySCreate =
        action.ticketProperty.entities.ticketProperties;
      let idNuevoSCreate = null;
      if (Object.values(datoTicketPropertySCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoTicketPropertySCreate)[0] &&
          Object.values(datoTicketPropertySCreate)[0].id
            ? Object.values(datoTicketPropertySCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_TICKETPROPERTIES:
      let ticketPropertiesCreate =
        action.ticketProperties.entities &&
        action.ticketProperties.entities.ticketProperties
          ? action.ticketProperties.entities.ticketProperties
          : null;
      return ticketPropertiesCreate
        ? union(
            state,
            Object.values(ticketPropertiesCreate).map(ticketProperties => {
              return ticketProperties.id;
            })
          )
        : state;
    case RESET_TICKETPROPERTIES:
      return [];

    case RECEIVE_TICKET:
      let ticket =
        action.ticket.entities && action.ticket.entities.ticketProperties
          ? action.ticket.entities.ticketProperties
          : null;
      return ticket
        ? union(
            state,
            Object.values(ticket).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case RECEIVE_TICKETS:
      let tickets =
        action.tickets.entities && action.tickets.entities.ticketProperties
          ? action.tickets.entities.ticketProperties
          : null;
      return tickets
        ? union(
            state,
            Object.values(tickets).map(tickets => {
              return tickets.id;
            })
          )
        : state;

    case SUCCESS_DELETE_TICKET:
      let ticketDelete =
        action.ticket.entities && action.ticket.entities.ticketProperties
          ? action.ticket.entities.ticketProperties
          : null;
      return ticketDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(ticketDelete).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case SUCCESS_CREATE_TICKET:
      let ticketCreate =
        action.ticket.entities && action.ticket.entities.ticketProperties
          ? action.ticket.entities.ticketProperties
          : null;
      return ticketCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(ticketCreate).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_TICKET:
      let ticketUpdate =
        action.ticket.entities && action.ticket.entities.ticketProperties
          ? action.ticket.entities.ticketProperties
          : null;
      return ticketUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(ticketUpdate).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_TICKETS:
      let ticketsUpdate =
        action.tickets.entities && action.tickets.entities.ticketProperties
          ? action.tickets.entities.ticketProperties
          : null;
      return ticketsUpdate
        ? union(
            state,
            Object.values(ticketsUpdate).map(tickets => {
              return tickets.id;
            })
          )
        : state;

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalTicketProperties(state = null, action) {
  switch (action.type) {
    case RECEIVE_TICKETPROPERTIES:
      return action.ticketProperties && action.ticketProperties.result.total
        ? action.ticketProperties.result.total
        : 0;
    case RESET_TICKETPROPERTIES:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_TICKETPROPERTY:
      let dato = action.ticketProperty.entities.ticketProperties;
      let ticketProperty =
        dato && Object.keys(dato).length > 0
          ? dato[action.ticketProperty.result]
          : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: ticketProperty ? ticketProperty : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_TICKETPROPERTY:
      let idsUpdate = [];
      Object.values(action.ticketProperty).map(ticketPropertyUpdate => {
        if (ticketPropertyUpdate && ticketPropertyUpdate.id)
          idsUpdate.push(ticketPropertyUpdate.id);
      });
      return merge({}, state, {
        activo: action.ticketProperty,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_TICKETPROPERTY:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_TICKETPROPERTY:
      let datoTicketPropertyActualizado =
        action.ticketProperty.entities.ticketProperties;
      let ticketPropertyNuevo =
        datoTicketPropertyActualizado &&
        Object.keys(datoTicketPropertyActualizado).length > 0
          ? datoTicketPropertyActualizado[action.ticketProperty.result]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: ticketPropertyNuevo
      });
    case ERROR_UPDATE_TICKETPROPERTY:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_TICKETPROPERTIES:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_TICKETPROPERTIES:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_TICKETPROPERTIES:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_TICKETPROPERTY:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    //TICKET
    //TODO ver si esta bien
    case SUCCESS_CREATE_TICKET:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_TICKET:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_TICKET:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_TICKETS:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });

    case DELETE_TICKETPROPERTY:
      let datoTicketPropertyDelete = action.ticketProperty;
      let idsDelete = [];
      Object.values(action.ticketProperty).map(ticketPropertyDelete => {
        if (ticketPropertyDelete && ticketPropertyDelete.id)
          idsDelete.push(ticketPropertyDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoTicketPropertyDelete)
          ),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_TICKETPROPERTY:
      let datoTicketPropertyDeleteUpdate = action.ticketProperty;
      let idsDeleteUpdate = [];
      Object.values(action.ticketProperty).map(ticketPropertyDelete => {
        if (ticketPropertyDelete && ticketPropertyDelete.id)
          idsDeleteUpdate.push(ticketPropertyDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoTicketPropertyDeleteUpdate)
          ),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_TICKETPROPERTY:
      let datoTicketPropertyDeleted = {};
      if (
        Object.values(action.ticketProperty.entities.ticketProperties).length >
        0
      )
        datoTicketPropertyDeleted = Object.values(
          action.ticketProperty.entities.ticketProperties
        )[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoTicketPropertyDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_TICKETPROPERTY:
      let idsCreate = [];
      Object.values(action.ticketProperty).map(ticketPropertyCreate => {
        if (ticketPropertyCreate && ticketPropertyCreate.id)
          idsCreate.push(ticketPropertyCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.ticketProperty,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_TICKETPROPERTY:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_TICKETPROPERTY:
      let datoTicketPropertyNuevo =
        action.ticketProperty.entities.ticketProperties;
      let ticketPropertyNuevo =
        datoTicketPropertyNuevo &&
        Object.keys(datoTicketPropertyNuevo).length > 0
          ? datoTicketPropertyNuevo[action.ticketProperty.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: ticketPropertyNuevo,
        nuevos: []
      });
    case ERROR_CREATE_TICKETPROPERTY:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_TICKETPROPERTIES:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_TICKETPROPERTIES:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_TICKETPROPERTIES:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_TICKETPROPERTY:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    //TICKET
    case SUCCESS_CREATE_TICKET:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_TICKET:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_TICKET:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_TICKETS:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });

    case DELETE_TICKETPROPERTY:
      let datoTicketPropertyDelete = action.ticketProperty;
      let idsDelete = [];
      Object.values(action.ticketProperty).map(ticketPropertyDelete => {
        if (ticketPropertyDelete && ticketPropertyDelete.id)
          idsDelete.push(ticketPropertyDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoTicketPropertyDelete)
          ),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_TICKETPROPERTY:
      let datoTicketPropertyDeleteCreate = action.ticketProperty;
      let idsDeleteCreate = [];
      Object.values(action.ticketProperty).map(ticketPropertyDelete => {
        if (ticketPropertyDelete && ticketPropertyDelete.id)
          idsDeleteCreate.push(ticketPropertyDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoTicketPropertyDeleteCreate)
          ),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_TICKETPROPERTY:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.ticketProperty,
        error: null
      });
    case REQUEST_DELETE_TICKETPROPERTY:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_TICKETPROPERTY:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_TICKETPROPERTY:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_TICKETPROPERTY:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    //TICKET
    case SUCCESS_CREATE_TICKET:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_TICKET:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_TICKET:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_TICKETS:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_TICKETPROPERTY:
      let idsCreate = [];
      Object.values(action.ticketProperty).map(ticketPropertyCreate => {
        if (ticketPropertyCreate && ticketPropertyCreate.id)
          idsCreate.push(ticketPropertyCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.ticketProperty,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_TICKETPROPERTY:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_TICKETPROPERTY:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_TICKETPROPERTY:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_TICKETPROPERTIES:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_TICKETPROPERTIES:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_TICKETPROPERTIES:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_TICKETPROPERTY:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_TICKETPROPERTY:
      let datoTicketPropertyDeleteCreate = action.ticketProperty;
      let idsDeleteCreate = [];
      Object.values(action.ticketProperty).map(ticketPropertyDelete => {
        if (ticketPropertyDelete && ticketPropertyDelete.id)
          idsDeleteCreate.push(ticketPropertyDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(
            clone(state.print),
            Object.keys(datoTicketPropertyDeleteCreate)
          ),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const ticketProperties = combineReducers({
  byId: ticketPropertiesById,
  allIds: allTicketProperties,
  update: update,
  create: create,
  totalTicketProperties: totalTicketProperties,
  delete: deleter,
  print: print
});

export default ticketProperties;
