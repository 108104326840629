import {
  resetPosts,
  resetCreatePost,
  resetUpdatePost,
  resetDeletePost
} from '../actions/PostActions';
import {
  resetAssets,
  resetCreateAsset,
  resetUpdateAsset,
  resetDeleteAsset
} from '../actions/AssetActions';

var resetPost = {
  resetAll(dispatch) {
    dispatch(resetPosts());
    dispatch(resetCreatePost());
    dispatch(resetUpdatePost());
    dispatch(resetDeletePost());
    dispatch(resetAssets());
    dispatch(resetCreateAsset());
    dispatch(resetUpdateAsset());
    dispatch(resetDeleteAsset());
  }
};

export default resetPost;
