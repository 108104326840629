import React from 'react';
import { ListItem, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CustomPaper from '../../../library/CustomPaper/CustomPaper';
import PaperBody from '../../../library/CustomPaper/PaperBody';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import moment from 'moment';
import List from '@material-ui/core/List';
import DraftsIcon from '@material-ui/icons/Drafts';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import 'moment/locale/es';

function ListCard(props) {
  const { listKey, titleKey, textKey, nameKey } = props;
  const list = useSelector(state => state[listKey]);

  return (
    <Grid item md={4} sm={12} xs={12}>
      <CustomPaper style={{ height: '100%', marginTop: 0 }}>
        <PaperBody>
          <Box display={'flex'} flexDirection={'row'}>
            <Typography variant={'button'} component={'h7'}>
              {props.title ? props.title : 'PROPIEDADES MÁS VISTAS'}
            </Typography>
          </Box>
          <Box
            width={'100%'}
            height={'100px'}
            minHeight={'250px'}
            marginTop={'10px'}
            overflow={'auto'}
          >
            <List component="nav" dense alignItems="flex-start">
              {list.allIds.map(id => {
                let item = list.byId[listKey][id];
                return (
                  <>
                    <ListItem key={'card' + id}>
                      <ListItemIcon>
                        <DraftsIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            component="span"
                            variant="subtitle2"
                            color="textPrimary"
                            style={{ fontWeight: 600 }}
                          >
                            {item[titleKey]}
                          </Typography>
                        }
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              color="textPrimary"
                            >
                              {item[nameKey]}
                            </Typography>
                            {' — ' + item[textKey]}
                            {' — ' +
                              (moment(item.created_at).isSame(moment(), 'day')
                                ? moment().diff(
                                    moment(item.created_at),
                                    'minutes'
                                  ) < 60
                                  ? 'hace ' +
                                    moment().diff(
                                      moment(item.created_at),
                                      'minutes'
                                    ) +
                                    'minutos'
                                  : 'hace ' +
                                    moment().diff(
                                      moment(item.created_at),
                                      'hours'
                                    ) +
                                    'horas'
                                : moment(item.created_at).format(
                                    'DD MMM HH:mm'
                                  ))}
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                    <Divider variant="inset" component="li" />
                  </>
                );
              })}
              {list.allIds.length === 0 && (
                <ListItem>
                  <ListItemIcon>
                    <DraftsIcon />
                  </ListItemIcon>
                  <ListItemText primary={'No hay registros'} />
                </ListItem>
              )}
            </List>
          </Box>
        </PaperBody>
      </CustomPaper>
    </Grid>
  );
}

export default ListCard;
