//api
import ticketsApi from '../api/ticketsApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizeTickets';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//TICKETS
export const REQUEST_TICKETS = 'REQUEST_TICKETS';
export const RECEIVE_TICKETS = 'RECEIVE_TICKETS';
export const INVALIDATE_TICKETS = 'INVALIDATE_TICKETS';
export const ERROR_TICKETS = 'ERROR_TICKETS';
export const RESET_TICKETS = 'RESET_TICKETS';

export function invalidateTickets() {
  return {
    type: INVALIDATE_TICKETS
  };
}

function requestTickets() {
  return {
    type: REQUEST_TICKETS
  };
}

function receiveTickets(json) {
  return {
    type: RECEIVE_TICKETS,
    tickets: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorTickets(error) {
  return {
    type: ERROR_TICKETS,
    error: error
  };
}

export function resetTickets() {
  return {
    type: RESET_TICKETS
  };
}

export function fetchTickets(filtros) {
  return dispatch => {
    dispatch(requestTickets());
    return ticketsApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveTickets(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorTickets(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorTickets(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

function shouldFetchTickets(state) {
  const tickets = state.tickets.byId;
  if (!tickets) {
    return true;
  } else if (tickets.isFetching) {
    return false;
  } else {
    return tickets.didInvalidate;
  }
}

export function fetchTicketsIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchTickets(getState())) {
      return dispatch(fetchTickets(filtros));
    }
  };
}

//MODEL
export const REQUEST_TICKET = 'REQUEST_TICKET';
export const RECEIVE_TICKET = 'RECEIVE_TICKET';
export const INVALIDATE_TICKET = 'INVALIDATE_TICKET';
export const ERROR_TICKET = 'ERROR_TICKET';
export const RESET_TICKET = 'RESET_TICKET';

export function invalidateTicket() {
  return {
    type: INVALIDATE_TICKET
  };
}

function requestTicket() {
  return {
    type: REQUEST_TICKET
  };
}

export function receiveTicket(json) {
  return {
    type: RECEIVE_TICKET,
    ticket: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorTicket(error) {
  return {
    type: ERROR_TICKET,
    error: error
  };
}

export function fetchTicket(idTicket) {
  return dispatch => {
    dispatch(requestTicket());
    return ticketsApi
      .getOne(idTicket)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveTicket(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(errorTicket(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorTicket(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_TICKET = 'RECEIVE_FILE_TICKET';

function receiveFileTicket(file) {
  return {
    type: RECEIVE_FILE_TICKET,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFileTicket(idTicket, filtros) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return ticketsApi
      .getFile(idTicket, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFileTicket(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorTicket(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorTicket(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_TICKET = 'UPDATE_TICKET';
export const REQUEST_UPDATE_TICKET = 'REQUEST_UPDATE_TICKET';
export const SUCCESS_UPDATE_TICKET = 'SUCCESS_UPDATE_TICKET';
export const ERROR_UPDATE_TICKET = 'ERROR_UPDATE_TICKET';
export const RESET_UPDATE_TICKET = 'RESET_UPDATE_TICKET';
export const DELETE_UPDATE_TICKET = 'DELETE_UPDATE_TICKET';

function requestUpdateTicket() {
  return {
    type: REQUEST_UPDATE_TICKET
  };
}

function receiveUpdateTicket(ticket) {
  return {
    type: SUCCESS_UPDATE_TICKET,
    receivedAt: Date.now(),
    ticket: normalizeDato(ticket)
  };
}

function errorUpdateTicket(error) {
  return {
    type: ERROR_UPDATE_TICKET,
    error: error
  };
}

export function resetUpdateTicket() {
  return {
    type: RESET_UPDATE_TICKET
  };
}

export function updateTicket(ticket) {
  return {
    type: UPDATE_TICKET,
    ticket
  };
}

export function saveUpdateTicket() {
  return (dispatch, getState) => {
    dispatch(requestUpdateTicket());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let ticket = denormalizeDato(getState().tickets.update.activo, store);

    return ticketsApi
      .saveUpdate(ticket)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateTicket(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateTicket(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateTickets(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdateTickets(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateTicket(JSON.parse(error.message)));
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdateTicket(error.data));
                })
                .catch(() => {
                  dispatch(errorUpdateTicket(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

export function deleteUpdateTicket(ticket) {
  return {
    type: DELETE_UPDATE_TICKET,
    ticket
  };
}

//UPDATE TICKETS
export const REQUEST_UPDATE_TICKETS = 'REQUEST_UPDATE_TICKETS';
export const SUCCESS_UPDATE_TICKETS = 'SUCCESS_UPDATE_TICKETS';
export const ERROR_UPDATE_TICKETS = 'ERROR_UPDATE_TICKETS';
export const RESET_UPDATE_TICKETS = 'RESET_UPDATE_TICKETS';

function requestUpdateTickets() {
  return {
    type: REQUEST_UPDATE_TICKETS
  };
}

function receiveUpdateTickets(tickets) {
  return {
    type: SUCCESS_UPDATE_TICKETS,
    receivedAt: Date.now(),
    tickets: normalizeDatos(tickets)
  };
}

function errorUpdateTickets(error) {
  return {
    type: ERROR_UPDATE_TICKETS,
    error: error
  };
}

export function resetUpdateTickets() {
  return {
    type: RESET_UPDATE_TICKETS
  };
}

export function saveUpdateTickets() {
  return (dispatch, getState) => {
    dispatch(requestUpdateTickets());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let tickets = getState().tickets.update.activos.map(idTicket => {
      return denormalizeDato(getState().tickets.update.activo[idTicket], store);
    });

    return ticketsApi
      .saveUpdateTickets(tickets)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateTickets(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateTickets(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateTickets(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdateTickets(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateTickets(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorUpdateTickets(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//ALTA TICKET
export const CREATE_TICKET = 'CREATE_TICKET';
export const REQUEST_CREATE_TICKET = 'REQUEST_CREATE_TICKET';
export const SUCCESS_CREATE_TICKET = 'SUCCESS_CREATE_TICKET';
export const ERROR_CREATE_TICKET = 'ERROR_CREATE_TICKET';
export const RESET_CREATE_TICKET = 'RESET_CREATE_TICKET';
export const DELETE_CREATE_TICKET = 'DELETE_CREATE_TICKET';

//ALTA TICKET
function requestCreateTicket() {
  return {
    type: REQUEST_CREATE_TICKET
  };
}

function receiveCreateTicket(ticket) {
  return {
    type: SUCCESS_CREATE_TICKET,
    receivedAt: Date.now(),
    ticket: normalizeDato(ticket)
  };
}

export function errorCreateTicket(error) {
  return {
    type: ERROR_CREATE_TICKET,
    error: error
  };
}

export function resetCreateTicket() {
  return {
    type: RESET_CREATE_TICKET
  };
}

export function createTicket(ticket) {
  return {
    type: CREATE_TICKET,
    ticket
  };
}

export function deleteCreateTicket(ticket) {
  return {
    type: DELETE_CREATE_TICKET,
    ticket
  };
}

export function saveCreateTicket() {
  return (dispatch, getState) => {
    dispatch(requestCreateTicket());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let ticket = denormalizeDato(getState().tickets.create.nuevo, store);

    return ticketsApi
      .saveCreate(ticket)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateTicket(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateTicket(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateTickets(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreateTickets(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateTicket(JSON.parse(error.message)));
                  if (error.data) dispatch(receiveCreateTicket(error.data));
                })
                .catch(() => {
                  dispatch(errorCreateTicket(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//CREATE TICKETS
export const REQUEST_CREATE_TICKETS = 'REQUEST_CREATE_TICKETS';
export const SUCCESS_CREATE_TICKETS = 'SUCCESS_CREATE_TICKETS';
export const ERROR_CREATE_TICKETS = 'ERROR_CREATE_TICKETS';
export const RESET_CREATE_TICKETS = 'RESET_CREATE_TICKETS';

function requestCreateTickets() {
  return {
    type: REQUEST_CREATE_TICKETS
  };
}

function receiveCreateTickets(tickets) {
  return {
    type: SUCCESS_CREATE_TICKETS,
    receivedAt: Date.now(),
    tickets: normalizeDatos(tickets)
  };
}

function errorCreateTickets(error) {
  return {
    type: ERROR_CREATE_TICKETS,
    error: error
  };
}

export function resetCreateTickets() {
  return {
    type: RESET_CREATE_TICKETS
  };
}

export function saveCreateTickets() {
  return (dispatch, getState) => {
    dispatch(requestCreateTickets());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let tickets = getState().tickets.create.nuevos.map(idTicket => {
      return denormalizeDato(getState().tickets.create.nuevo[idTicket], store);
    });

    return ticketsApi
      .saveCreateTickets(tickets)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateTickets(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateTickets(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateTickets(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreateTickets(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateTickets(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorCreateTickets(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//DELETE TICKET
export const DELETE_TICKET = 'DELETE_TICKET';
export const REQUEST_DELETE_TICKET = 'REQUEST_DELETE_TICKET';
export const SUCCESS_DELETE_TICKET = 'SUCCESS_DELETE_TICKET';
export const ERROR_DELETE_TICKET = 'ERROR_DELETE_TICKET';
export const RESET_DELETE_TICKET = 'RESET_DELETE_TICKET';

function requestDeleteTicket() {
  return {
    type: REQUEST_DELETE_TICKET
  };
}

function receiveDeleteTicket(ticket) {
  return {
    type: SUCCESS_DELETE_TICKET,
    receivedAt: Date.now(),
    ticket: normalizeDato(ticket)
  };
}

function errorDeleteTicket(error) {
  return {
    type: ERROR_DELETE_TICKET,
    error: error
  };
}

export function resetDeleteTicket(error) {
  return {
    type: RESET_DELETE_TICKET,
    error: error
  };
}

export function deleteTicket(ticket) {
  return {
    type: DELETE_TICKET,
    ticket
  };
}

export function saveDeleteTicket(ticket) {
  return dispatch => {
    dispatch(requestDeleteTicket());
    return ticketsApi
      .saveDelete(ticket)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeleteTicket());
        dispatch(receiveDeleteTicket(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorDeleteTicket(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeleteTicket(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorDeleteTicket(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorDeleteTicket(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorDeleteTicket(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT TICKET
export const PRINT_TICKET = 'PRINT_TICKET';
export const REQUEST_PRINT_TICKET = 'REQUEST_PRINT_TICKET';
export const SUCCESS_PRINT_TICKET = 'SUCCESS_PRINT_TICKET';
export const ERROR_PRINT_TICKET = 'ERROR_PRINT_TICKET';
export const RESET_PRINT_TICKET = 'RESET_PRINT_TICKET';
export const DELETE_PRINT_TICKET = 'DELETE_PRINT_TICKET';

function requestPrintTicket() {
  return {
    type: REQUEST_PRINT_TICKET
  };
}

function receivePrintTicket(turnos) {
  return {
    type: SUCCESS_PRINT_TICKET,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintTicket(error) {
  return {
    type: ERROR_PRINT_TICKET,
    error: error
  };
}

export function resetPrintTicket() {
  return {
    type: RESET_PRINT_TICKET
  };
}

export function printTicket(ticket) {
  return {
    type: PRINT_TICKET,
    ticket
  };
}

export function deletePrintTicket(ticket) {
  return {
    type: DELETE_PRINT_TICKET,
    ticket
  };
}

export function savePrintTicket(idTicket) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintTicket());
    return ticketsApi
      .printTicket(idTicket)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintTicket(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintTicket(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintTicket(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintTicket(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintTicket(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT TICKETS
export const REQUEST_PRINT_TICKETS = 'REQUEST_PRINT_TICKETS';
export const SUCCESS_PRINT_TICKETS = 'SUCCESS_PRINT_TICKETS';
export const ERROR_PRINT_TICKETS = 'ERROR_PRINT_TICKETS';
export const RESET_PRINT_TICKETS = 'RESET_PRINT_TICKETS';

function requestPrintTickets() {
  return {
    type: REQUEST_PRINT_TICKETS
  };
}

function receivePrintTickets(tickets) {
  return {
    type: SUCCESS_PRINT_TICKETS,
    receivedAt: Date.now(),
    tickets: normalizeDatos(tickets)
  };
}

function errorPrintTickets(error) {
  return {
    type: ERROR_PRINT_TICKETS,
    error: error
  };
}

export function resetPrintTickets() {
  return {
    type: RESET_PRINT_TICKETS
  };
}

export function savePrintTickets() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintTicket());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let tickets = getState().tickets.print.printers.map(idTicket => {
      return denormalizeDato(getState().tickets.print.print[idTicket], store);
    });
    return ticketsApi
      .printTickets(tickets)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintTickets(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintTickets(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintTickets(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintTickets(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintTickets(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}
