import React from 'react';
import c from '../../../../constants/Constants';
import { useSelector } from 'react-redux';
import { Chip, FormControl, FormGroup, FormLabel } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import SVGimg from '../../../../library/SVGimg';
import Avatar from '@material-ui/core/Avatar';

export default function PropertyTypesFilter(props) {
  const propertyTypes = useSelector(state => state.propertyTypes);
  const { query, setQuery } = props;
  const propertyTypesSelected = query.idsPropertyType;

  const handleChange = value => {
    let _query = Object.assign({}, query);
    if (!_query.idsPropertyType) _query.idsPropertyType = [];
    if (typeof _query.idsPropertyType === 'string')
      _query.idsPropertyType = [_query.idsPropertyType];
    if (_query.idsPropertyType.indexOf(value.toString()) === -1)
      _query.idsPropertyType.push(value.toString());
    else
      _query.idsPropertyType.splice(
        _query.idsPropertyType.indexOf(value.toString()),
        1
      );
    setQuery(_query);
  };

  return (
    <FormControl component="fieldset" variant="standard">
      <FormLabel component="legend" className={'formLabel'}>
        Tipología
      </FormLabel>
      <FormGroup
        style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}
      >
        <Box
          display={'flex'}
          gridRowGap={'10px'}
          gridColumnGap={'10px'}
          maxWidth={'460px'}
          flexWrap={'wrap'}
        >
          {propertyTypes &&
            propertyTypes.allIds &&
            propertyTypes.allIds.map(idPropertyType => {
              let propertyType =
                propertyTypes.byId.propertyTypes[idPropertyType];
              return (
                <Chip
                  key={'pt' + propertyType.id}
                  label={
                    propertyType.publicNameType
                      ? propertyType.publicNameType
                      : propertyType.nameType
                  }
                  size={'medium'}
                  style={{
                    width: '146px'
                  }}
                  onClick={() => handleChange(propertyType.id)}
                  color={
                    propertyTypesSelected &&
                    (typeof propertyTypesSelected === 'string'
                      ? propertyTypesSelected === propertyType.id.toString()
                      : propertyTypesSelected.indexOf(
                          propertyType.id.toString()
                        ) !== -1)
                      ? 'primary'
                      : 'default'
                  }
                  // color={query[value.query] && query[value.query] == 1 ? 'primary' : 'default'}
                  icon={
                    propertyType.path ? (
                      <SVGimg
                        style={{ width: '23px', height: '23px' }}
                        width={'23px'}
                        height={'23px'}
                        fill={
                          propertyTypesSelected &&
                          propertyTypesSelected.indexOf(
                            propertyType.id.toString()
                          ) !== -1
                            ? 'white'
                            : 'inherit'
                        }
                        src={`${c.API_HOST}${propertyType.path}`}
                      />
                    ) : (
                      <Avatar>{propertyType.nameType[0]}</Avatar>
                    )
                  }
                />
              );
            })}
        </Box>
      </FormGroup>
    </FormControl>
  );
}
