import { normalize, schema, denormalize } from 'normalizr';

const propertyType = new schema.Entity(
  'propertyTypes',
  {},
  { idAttribute: 'id' }
);

propertyType.define({
  children_type: [propertyType]
});

export function normalizeDatos(myData) {
  const mySchema = { propertyTypes: [propertyType] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = propertyType;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = propertyType;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default propertyType;
