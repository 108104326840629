import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
//Components
import PostsList from './PostsList';
import CRUDPost from './CRUDPost';

export default function Posts() {
  const dispatch = useDispatch();

  return (
    <Switch>
      <Route path="/posts/new" component={CRUDPost} />
      <Route path="/posts/:idPost" component={CRUDPost} />
      <Route path="/posts" component={PostsList} />
      <Route path="/jobs/new" component={CRUDPost} />
      <Route path="/jobs/:idPost" component={CRUDPost} />
      <Route path="/jobs" component={PostsList} />
    </Switch>
  );
}
