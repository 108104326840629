import React from 'react';
import c from '../../../../constants/Constants';
import { Chip, FormControl, FormGroup, FormLabel } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import SVGimg from '../../../../library/SVGimg';
import Avatar from '@material-ui/core/Avatar';

export default function AmenitiesFilter(props) {
  const { query, setQuery } = props;
  const _amenities = query.amenities;
  const filters = [
    { query: 'pool', name: 'Pileta', img: '/storage/icons/pool.svg' },
    { query: 'grill', name: 'Parrilla', img: '/storage/icons/fire.svg' },
    { query: 'parking', name: 'Cochera', img: '/storage/icons/parking.svg' },
    { query: 'yard', name: 'Patio', img: '/storage/icons/yard.svg' },
    { query: 'barbecue', name: 'Quincho', img: '/storage/icons/hut.svg' },
    {
      query: 'garden',
      name: 'Jardín',
      img: '/storage/icons/gardenflowers.svg'
    },
    { query: 'terrace', name: 'Terraza', img: '/storage/icons/terrace.svg' }
  ];

  const handleChange = value => {
    let _query = Object.assign({}, query);
    if (!_query.amenities) _query.amenities = [];
    if (typeof _query.amenities === 'string')
      _query.amenities = [_query.amenities];
    if (_query.amenities.indexOf(value.toString()) === -1)
      _query.amenities.push(value.toString());
    else _query.amenities.splice(_query.amenities.indexOf(value.toString()), 1);
    setQuery(_query);
  };

  return (
    <FormControl component="fieldset" variant="standard">
      <FormLabel component="legend" className={'formLabel'}>
        Amenities
      </FormLabel>
      <FormGroup
        style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}
      >
        <Box
          display={'flex'}
          gridRowGap={'10px'}
          gridColumnGap={'10px'}
          maxWidth={'500px'}
          flexWrap={'wrap'}
        >
          {filters.map((value, i) => {
            return (
              <Chip
                key={'beds' + i}
                label={value.name}
                size={'large'}
                onClick={() => handleChange(value.query)}
                color={
                  _amenities &&
                  _amenities.indexOf(value.query.toString()) !== -1
                    ? 'primary'
                    : 'default'
                }
                // color={query[value.query] && query[value.query] == 1 ? 'primary' : 'default'}
                icon={
                  value && value.img ? (
                    <SVGimg
                      style={{ width: '23px', height: '23px' }}
                      width={'23px'}
                      height={'23px'}
                      fill={
                        _amenities &&
                        _amenities.indexOf(value.query.toString()) !== -1
                          ? 'white'
                          : 'inherit'
                      }
                      src={`${c.API_HOST}${value.img}`}
                    />
                  ) : (
                    <Avatar>{value.name[0]}</Avatar>
                  )
                }
              />
            );
          })}
        </Box>
      </FormGroup>
    </FormControl>
  );
}
