import { normalize, schema, denormalize } from 'normalizr';
import asset from './normalizeAssets';
import propertyAttributeValue from './normalizePropertiesAttributesValues';
import propertyType from './normalizePropertyTypes';
import propertyOperation from './normalizePropertyOperations';
import user from './normalizeUsers';

const propertyClosedOperation = new schema.Entity(
  'propertyClosedOperations',
  {},
  { idAttribute: 'id' }
);

const property = new schema.Entity('properties', {}, { idAttribute: 'id' });

property.define({
  asset: [asset],
  main_asset: [asset],
  property_attribute_value: [propertyAttributeValue],
  property_type: propertyType,
  property_operation: [propertyOperation],
  development_property: [property],
  development: property,
  property_closed_operation: [propertyClosedOperation],
  owner: user
});

export function normalizeDatos(myData) {
  const mySchema = { properties: [property] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = property;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = property;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default property;
