import {
  INVALIDATE_ASSETS,
  ERROR_ASSETS,
  RECEIVE_ASSETS,
  REQUEST_ASSETS,
  RESET_ASSETS,
  ERROR_ASSET,
  RECEIVE_ASSET,
  REQUEST_ASSET,
  UPDATE_ASSET,
  REQUEST_UPDATE_ASSET,
  SUCCESS_UPDATE_ASSET,
  ERROR_UPDATE_ASSET,
  RESET_UPDATE_ASSET,
  REQUEST_UPDATE_ASSETS,
  SUCCESS_UPDATE_ASSETS,
  ERROR_UPDATE_ASSETS,
  RESET_UPDATE_ASSETS,
  CREATE_ASSET,
  ERROR_CREATE_ASSET,
  REQUEST_CREATE_ASSET,
  RESET_CREATE_ASSET,
  SUCCESS_CREATE_ASSET,
  REQUEST_CREATE_ASSETS,
  SUCCESS_CREATE_ASSETS,
  ERROR_CREATE_ASSETS,
  RESET_CREATE_ASSETS,
  DELETE_ASSET,
  DELETE_CREATE_ASSET,
  DELETE_UPDATE_ASSET,
  REQUEST_DELETE_ASSET,
  SUCCESS_DELETE_ASSET,
  ERROR_DELETE_ASSET,
  RESET_DELETE_ASSET,
  REQUEST_PRINT_ASSET,
  SUCCESS_PRINT_ASSET,
  ERROR_PRINT_ASSET,
  RESET_PRINT_ASSET,
  RECEIVE_FILE_ASSET,
  REQUEST_PRINT_ASSETS,
  SUCCESS_PRINT_ASSETS,
  ERROR_PRINT_ASSETS,
  RESET_PRINT_ASSETS,
  RECEIVE_FILE_ASSETS,
  PRINT_ASSET,
  DELETE_PRINT_ASSET
} from '../actions/AssetActions';

import {
  RECEIVE_PROPERTY,
  RECEIVE_PROPERTIES,
  SUCCESS_DELETE_PROPERTY,
  SUCCESS_CREATE_PROPERTY,
  SUCCESS_UPDATE_PROPERTY,
  SUCCESS_UPDATE_PROPERTIES
} from '../actions/PropertyActions';
import {
  RECEIVE_POST,
  RECEIVE_POSTS,
  SUCCESS_DELETE_POST,
  SUCCESS_CREATE_POST,
  SUCCESS_UPDATE_POST,
  SUCCESS_UPDATE_POSTS
} from '../actions/PostActions';
import {
  RECEIVE_TICKET,
  RECEIVE_TICKETS,
  SUCCESS_DELETE_TICKET,
  SUCCESS_CREATE_TICKET,
  SUCCESS_UPDATE_TICKET,
  SUCCESS_UPDATE_TICKETS
} from '../actions/TicketActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    assets: {},
    files: {}
  };
}

function assetsById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_ASSETS:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_ASSETS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_ASSETS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_ASSETS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        assets: {}
      });
    case RECEIVE_ASSETS:
      let dato = action.assets.entities.assets;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        assets: merge({}, state.assets, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_ASSET:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_ASSET:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_ASSET:
      let datoAsset = action.asset.entities.assets;
      return Object.assign({}, state, {
        assets: merge({}, state.assets, datoAsset),
        isFetching: false
      });
    case RECEIVE_FILE_ASSET:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_ASSET:
      let datoAssetEliminado = action.asset.entities.assets;
      return Object.assign({}, state, {
        assets: mergeWith(
          clone(datoAssetEliminado),
          state.assets,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_ASSET:
      let datoAssetCreado = action.asset.entities.assets;
      return Object.assign({}, state, {
        assets: mergeWith(
          clone(datoAssetCreado),
          state.assets,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_ASSETS:
      let datosAssetCreado = action.assets.entities.assets;
      return Object.assign({}, state, {
        assets: mergeWith(
          clone(datosAssetCreado),
          state.assets,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_ASSET:
      let datoAssetActualizado = action.asset.entities.assets;
      return Object.assign({}, state, {
        assets: mergeWith(
          clone(datoAssetActualizado),
          state.assets,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_ASSETS:
      let datosAssetActualizado = action.assets.entities.assets;
      return Object.assign({}, state, {
        assets: mergeWith(
          clone(datosAssetActualizado),
          state.assets,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    //PROPERTY
    case RECEIVE_PROPERTY:
      let property =
        action.property.entities && action.property.entities.assets
          ? action.property.entities.assets
          : {};
      return Object.assign({}, state, {
        assets: merge({}, state.assets, property)
      });
    case RECEIVE_PROPERTIES:
      let properties =
        action.properties.entities && action.properties.entities.assets
          ? action.properties.entities.assets
          : {};
      return Object.assign({}, state, {
        assets: merge({}, state.assets, properties)
      });
    case SUCCESS_DELETE_PROPERTY:
      let datopropertyEliminado =
        action.property.entities && action.property.entities.assets
          ? action.property.entities.assets
          : {};
      return Object.assign({}, state, {
        assets: mergeWith(
          clone(datopropertyEliminado),
          pickBy(state.assets, function (asset) {
            return asset.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_PROPERTY:
      let datopropertyCreado =
        action.property.entities && action.property.entities.assets
          ? action.property.entities.assets
          : {};
      return Object.assign({}, state, {
        assets: mergeWith(
          clone(datopropertyCreado),
          pickBy(state.assets, function (asset) {
            return asset.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTY:
      let datopropertyActualizado =
        action.property.entities && action.property.entities.assets
          ? action.property.entities.assets
          : {};
      return Object.assign({}, state, {
        assets: mergeWith(
          clone(datopropertyActualizado),
          pickBy(state.assets, function (asset) {
            return asset.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTIES:
      let datospropertyActualizado =
        action.properties.entities && action.properties.entities.assets
          ? action.properties.entities.assets
          : {};
      return Object.assign({}, state, {
        assets: mergeWith(
          clone(datospropertyActualizado),
          state.assets,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    //POST
    case RECEIVE_POST:
      let post =
        action.post.entities && action.post.entities.assets
          ? action.post.entities.assets
          : {};
      return Object.assign({}, state, {
        assets: merge({}, state.assets, post)
      });
    case RECEIVE_POSTS:
      let posts =
        action.posts.entities && action.posts.entities.assets
          ? action.posts.entities.assets
          : {};
      return Object.assign({}, state, {
        assets: merge({}, state.assets, posts)
      });
    case SUCCESS_DELETE_POST:
      let datopostEliminado =
        action.post.entities && action.post.entities.assets
          ? action.post.entities.assets
          : {};
      return Object.assign({}, state, {
        assets: mergeWith(
          clone(datopostEliminado),
          pickBy(state.assets, function (asset) {
            return asset.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_POST:
      let datopostCreado =
        action.post.entities && action.post.entities.assets
          ? action.post.entities.assets
          : {};
      return Object.assign({}, state, {
        assets: mergeWith(
          clone(datopostCreado),
          pickBy(state.assets, function (asset) {
            return asset.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_POST:
      let datopostActualizado =
        action.post.entities && action.post.entities.assets
          ? action.post.entities.assets
          : {};
      return Object.assign({}, state, {
        assets: mergeWith(
          clone(datopostActualizado),
          pickBy(state.assets, function (asset) {
            return asset.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_POSTS:
      let datospostActualizado =
        action.posts.entities && action.posts.entities.assets
          ? action.posts.entities.assets
          : {};
      return Object.assign({}, state, {
        assets: mergeWith(
          clone(datospostActualizado),
          state.assets,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    //TICKET
    case RECEIVE_TICKET:
      let ticket =
        action.ticket.entities && action.ticket.entities.assets
          ? action.ticket.entities.assets
          : {};
      return Object.assign({}, state, {
        assets: merge({}, state.assets, ticket)
      });
    case RECEIVE_TICKETS:
      let tickets =
        action.tickets.entities && action.tickets.entities.assets
          ? action.tickets.entities.assets
          : {};
      return Object.assign({}, state, {
        assets: merge({}, state.assets, tickets)
      });
    case SUCCESS_DELETE_TICKET:
      let datoticketEliminado =
        action.ticket.entities && action.ticket.entities.assets
          ? action.ticket.entities.assets
          : {};
      return Object.assign({}, state, {
        assets: mergeWith(
          clone(datoticketEliminado),
          pickBy(state.assets, function (asset) {
            return asset.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_TICKET:
      let datoticketCreado =
        action.ticket.entities && action.ticket.entities.assets
          ? action.ticket.entities.assets
          : {};
      return Object.assign({}, state, {
        assets: mergeWith(
          clone(datoticketCreado),
          pickBy(state.assets, function (asset) {
            return asset.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TICKET:
      let datoticketActualizado =
        action.ticket.entities && action.ticket.entities.assets
          ? action.ticket.entities.assets
          : {};
      return Object.assign({}, state, {
        assets: mergeWith(
          clone(datoticketActualizado),
          pickBy(state.assets, function (asset) {
            return asset.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TICKETS:
      let datosticketActualizado =
        action.tickets.entities && action.tickets.entities.assets
          ? action.tickets.entities.assets
          : {};
      return Object.assign({}, state, {
        assets: mergeWith(
          clone(datosticketActualizado),
          state.assets,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        assets: {}
      });
    default:
      return state;
  }
}

function allAssets(state = [], action) {
  switch (action.type) {
    case RECEIVE_ASSETS:
      return action.assets.result && action.assets.result.assets
        ? union(action.assets.result.assets, state)
        : action.assets.result
        ? action.assets.result
        : state;
    case RECEIVE_ASSET:
      return action.asset.result ? union([action.asset.result], state) : state;

    case SUCCESS_CREATE_ASSET:
      let datoAssetSCreate = action.asset.entities.assets;
      let idNuevoSCreate = null;
      if (Object.values(datoAssetSCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoAssetSCreate)[0] &&
          Object.values(datoAssetSCreate)[0].id
            ? Object.values(datoAssetSCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_ASSETS:
      let assetsCreate =
        action.assets.entities && action.assets.entities.assets
          ? action.assets.entities.assets
          : null;
      return assetsCreate
        ? union(
            state,
            Object.values(assetsCreate).map(assets => {
              return assets.id;
            })
          )
        : state;
    case RESET_ASSETS:
      return [];

    case RECEIVE_PROPERTY:
      let property =
        action.property.entities && action.property.entities.assets
          ? action.property.entities.assets
          : null;
      return property
        ? union(
            state,
            Object.values(property).map(property => {
              return property.id;
            })
          )
        : state;
    case RECEIVE_PROPERTIES:
      let properties =
        action.properties.entities && action.properties.entities.assets
          ? action.properties.entities.assets
          : null;
      return properties
        ? union(
            state,
            Object.values(properties).map(properties => {
              return properties.id;
            })
          )
        : state;

    case SUCCESS_DELETE_PROPERTY:
      let propertyDelete =
        action.property.entities && action.property.entities.assets
          ? action.property.entities.assets
          : null;
      return propertyDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyDelete).map(property => {
              return property.id;
            })
          )
        : state;
    case SUCCESS_CREATE_PROPERTY:
      let propertyCreate =
        action.property.entities && action.property.entities.assets
          ? action.property.entities.assets
          : null;
      return propertyCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyCreate).map(property => {
              return property.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_PROPERTY:
      let propertyUpdate =
        action.property.entities && action.property.entities.assets
          ? action.property.entities.assets
          : null;
      return propertyUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyUpdate).map(property => {
              return property.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_PROPERTIES:
      let propertiesUpdate =
        action.properties.entities && action.properties.entities.assets
          ? action.properties.entities.assets
          : null;
      return propertiesUpdate
        ? union(
            state,
            Object.values(propertiesUpdate).map(properties => {
              return properties.id;
            })
          )
        : state;
    case RECEIVE_POST:
      let post =
        action.post.entities && action.post.entities.assets
          ? action.post.entities.assets
          : null;
      return post
        ? union(
            state,
            Object.values(post).map(post => {
              return post.id;
            })
          )
        : state;
    case RECEIVE_POSTS:
      let posts =
        action.posts.entities && action.posts.entities.assets
          ? action.posts.entities.assets
          : null;
      return posts
        ? union(
            state,
            Object.values(posts).map(posts => {
              return posts.id;
            })
          )
        : state;

    case SUCCESS_DELETE_POST:
      let postDelete =
        action.post.entities && action.post.entities.assets
          ? action.post.entities.assets
          : null;
      return postDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(postDelete).map(post => {
              return post.id;
            })
          )
        : state;
    case SUCCESS_CREATE_POST:
      let postCreate =
        action.post.entities && action.post.entities.assets
          ? action.post.entities.assets
          : null;
      return postCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(postCreate).map(post => {
              return post.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_POST:
      let postUpdate =
        action.post.entities && action.post.entities.assets
          ? action.post.entities.assets
          : null;
      return postUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(postUpdate).map(post => {
              return post.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_POSTS:
      let postsUpdate =
        action.posts.entities && action.posts.entities.assets
          ? action.posts.entities.assets
          : null;
      return postsUpdate
        ? union(
            state,
            Object.values(postsUpdate).map(posts => {
              return posts.id;
            })
          )
        : state;
    case RECEIVE_TICKET:
      let ticket =
        action.ticket.entities && action.ticket.entities.assets
          ? action.ticket.entities.assets
          : null;
      return ticket
        ? union(
            state,
            Object.values(ticket).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case RECEIVE_TICKETS:
      let tickets =
        action.tickets.entities && action.tickets.entities.assets
          ? action.tickets.entities.assets
          : null;
      return tickets
        ? union(
            state,
            Object.values(tickets).map(tickets => {
              return tickets.id;
            })
          )
        : state;

    case SUCCESS_DELETE_TICKET:
      let ticketDelete =
        action.ticket.entities && action.ticket.entities.assets
          ? action.ticket.entities.assets
          : null;
      return ticketDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(ticketDelete).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case SUCCESS_CREATE_TICKET:
      let ticketCreate =
        action.ticket.entities && action.ticket.entities.assets
          ? action.ticket.entities.assets
          : null;
      return ticketCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(ticketCreate).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_TICKET:
      let ticketUpdate =
        action.ticket.entities && action.ticket.entities.assets
          ? action.ticket.entities.assets
          : null;
      return ticketUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(ticketUpdate).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_TICKETS:
      let ticketsUpdate =
        action.tickets.entities && action.tickets.entities.assets
          ? action.tickets.entities.assets
          : null;
      return ticketsUpdate
        ? union(
            state,
            Object.values(ticketsUpdate).map(tickets => {
              return tickets.id;
            })
          )
        : state;

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalAssets(state = null, action) {
  switch (action.type) {
    case RECEIVE_ASSETS:
      return action.assets && action.assets.result.total
        ? action.assets.result.total
        : 0;
    case RESET_ASSETS:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_ASSET:
      let dato = action.asset.entities.assets;
      let asset =
        dato && Object.keys(dato).length > 0 ? dato[action.asset.result] : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: asset ? asset : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_ASSET:
      let idsUpdate = [];
      Object.values(action.asset).map(assetUpdate => {
        if (assetUpdate && assetUpdate.id) idsUpdate.push(assetUpdate.id);
      });
      return merge({}, state, {
        activo: action.asset,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_ASSET:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_ASSET:
      let datoAssetActualizado = action.asset.entities.assets;
      let assetNuevo =
        datoAssetActualizado && Object.keys(datoAssetActualizado).length > 0
          ? datoAssetActualizado[action.asset.result]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: assetNuevo
      });
    case ERROR_UPDATE_ASSET:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_ASSETS:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_ASSETS:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_ASSETS:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_ASSET:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    //PROPERTY
    //TODO ver si esta bien
    case SUCCESS_CREATE_PROPERTY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_PROPERTY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      }); //POST
    //TODO ver si esta bien
    case SUCCESS_CREATE_POST:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_POST:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_POST:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_POSTS:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      }); //TICKET
    //TODO ver si esta bien
    case SUCCESS_CREATE_TICKET:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_TICKET:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_TICKET:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_TICKETS:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });

    case DELETE_ASSET:
      let datoAssetDelete = action.asset;
      let idsDelete = [];
      Object.values(action.asset).map(assetDelete => {
        if (assetDelete && assetDelete.id) idsDelete.push(assetDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(clone(state.activo), Object.keys(datoAssetDelete)),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_ASSET:
      let datoAssetDeleteUpdate = action.asset;
      let idsDeleteUpdate = [];
      Object.values(action.asset).map(assetDelete => {
        if (assetDelete && assetDelete.id) idsDeleteUpdate.push(assetDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(clone(state.activo), Object.keys(datoAssetDeleteUpdate)),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_ASSET:
      let datoAssetDeleted = {};
      if (Object.values(action.asset.entities.assets).length > 0)
        datoAssetDeleted = Object.values(action.asset.entities.assets)[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoAssetDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_ASSET:
      let idsCreate = [];
      Object.values(action.asset).map(assetCreate => {
        if (assetCreate && assetCreate.id) idsCreate.push(assetCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.asset,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_ASSET:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_ASSET:
      let datoAssetNuevo = action.asset.entities.assets;
      let assetNuevo =
        datoAssetNuevo && Object.keys(datoAssetNuevo).length > 0
          ? datoAssetNuevo[action.asset.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: assetNuevo,
        nuevos: []
      });
    case ERROR_CREATE_ASSET:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_ASSETS:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_ASSETS:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_ASSETS:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_ASSET:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    //PROPERTY
    case SUCCESS_CREATE_PROPERTY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_PROPERTY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    //POST
    case SUCCESS_CREATE_POST:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_POST:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_POST:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_POSTS:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    //TICKET
    case SUCCESS_CREATE_TICKET:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_TICKET:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_TICKET:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_TICKETS:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });

    case DELETE_ASSET:
      let datoAssetDelete = action.asset;
      let idsDelete = [];
      Object.values(action.asset).map(assetDelete => {
        if (assetDelete && assetDelete.id) idsDelete.push(assetDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoAssetDelete)),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_ASSET:
      let datoAssetDeleteCreate = action.asset;
      let idsDeleteCreate = [];
      Object.values(action.asset).map(assetDelete => {
        if (assetDelete && assetDelete.id) idsDeleteCreate.push(assetDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoAssetDeleteCreate)),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_ASSET:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.asset,
        error: null
      });
    case REQUEST_DELETE_ASSET:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_ASSET:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_ASSET:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_ASSET:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    //PROPERTY
    case SUCCESS_CREATE_PROPERTY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_PROPERTY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        eliminado: {}
      }); //POST
    case SUCCESS_CREATE_POST:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_POST:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_POST:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_POSTS:
      return Object.assign({}, state, {
        eliminado: {}
      }); //TICKET
    case SUCCESS_CREATE_TICKET:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_TICKET:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_TICKET:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_TICKETS:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_ASSET:
      let idsCreate = [];
      Object.values(action.asset).map(assetCreate => {
        if (assetCreate && assetCreate.id) idsCreate.push(assetCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.asset,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_ASSET:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_ASSET:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_ASSET:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_ASSETS:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_ASSETS:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_ASSETS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_ASSET:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_ASSET:
      let datoAssetDeleteCreate = action.asset;
      let idsDeleteCreate = [];
      Object.values(action.asset).map(assetDelete => {
        if (assetDelete && assetDelete.id) idsDeleteCreate.push(assetDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(clone(state.print), Object.keys(datoAssetDeleteCreate)),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const assets = combineReducers({
  byId: assetsById,
  allIds: allAssets,
  update: update,
  create: create,
  totalAssets: totalAssets,
  delete: deleter,
  print: print
});

export default assets;
