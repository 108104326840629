import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
//Components
import {
  Breadcrumbs,
  Container,
  InputAdornment,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import postReset from '../../reset/postReset';
import {
  fetchPosts,
  invalidatePosts,
  resetPosts,
  saveDeletePost
} from '../../actions/PostActions';
import { Skeleton } from '@material-ui/lab';
import CustomPaper from '../../library/CustomPaper/CustomPaper';
import PaperHeader from '../../library/CustomPaper/PaperHeader';
import CropFreeIcon from '@material-ui/icons/Search';
import Grow from '../../library/Grow';
import PaperFooter from '../../library/CustomPaper/PaperFooter';
import Pagination from '@material-ui/lab/Pagination';
import ContainerBtn from '../../library/Buttons/ContainerBtn';
import BtnAdd from '../../library/Buttons/BtnAdd';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import { useLocation } from 'react-router';
//Icons
//Img

const useStyles = makeStyles(theme => ({
  form: {
    padding: theme.spacing(2, 0)
  },
  root: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: 151,
    backgroundSize: 'contain'
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

function PostsList(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { idPost, changeAsset } = props;
  let location = useLocation();
  let path = location.pathname.indexOf('jobs') === -1 ? '/posts' + '' : '/jobs';
  //Store
  const posts = useSelector(state => state.posts);

  //State
  const [search, setSearch] = useState('');

  const params = new URLSearchParams(props.location.search);
  const order = params.get('order') ? params.get('order') : 'id';
  const direction = params.get('direction') ? params.get('direction') : 'desc';
  const page = params.get('page') ? params.get('page') : 1;
  const searchPhase = params.get('sp') ? params.get('sp') : '';

  const disabled = posts.byId.isFetching || posts.delete.isDeleting;

  //Hooks
  const timer = useRef();

  //Effects
  useEffect(() => {
    window.scrollTo(0, 0);

    if (searchPhase != search) {
      setSearch(searchPhase);
    }

    return function cleanup() {
      postReset.resetAll(dispatch);
    };
  }, []);

  useEffect(() => {
    let filtros = {
      searchPhase: search ? search : '',
      order: order,
      direction: direction,
      page: page,
      excludeType: location.pathname.indexOf('jobs') !== -1 ? '' : 'Empleo',
      type: location.pathname.indexOf('jobs') !== -1 ? 'Empleo' : '',
      with: [],
      responseMode: 'brief'
    };

    dispatch(resetPosts());
    dispatch(invalidatePosts());
    dispatch(fetchPosts(filtros));
  }, [props.location.search, props.location.pathname]);

  const changeOrder = or => {
    params.set('order', or ? or : '');
    let direction = params.get('direction');
    params.set('direction', direction === 'asc' ? 'desc' : 'asc');
    props.history.push(path + '?' + params.toString());
  };
  const WAIT_INTERVAL = 500;

  const changeSearch = e => {
    clearTimeout(timer.current);
    let valor = e.target.value;
    setSearch(e.target.value);

    timer.current = setTimeout(() => triggerChange(valor), WAIT_INTERVAL);
  };

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      clearTimeout(timer.current);
      triggerChange();
    }
  };

  const triggerChange = searchPhase => {
    if ((!searchPhase && !search) || searchPhase === '') {
      params.delete('sp');
      props.history.push(path + '?' + params.toString());
    } else {
      params.delete('page');
      params.set('sp', searchPhase ? searchPhase : search);
      props.history.push(path + '?' + params.toString());
    }
  };

  const handleChangePage = (e, page) => {
    let pagina = page;
    let paramsPagina = new URLSearchParams(props.location.search);
    paramsPagina.set('page', pagina ? pagina : '1');
    props.history.push(path + '?' + paramsPagina.toString());
  };

  const handleChangeEstado = idPost => {
    let asset = posts.byId.posts[idPost];
    dispatch(saveDeletePost(asset));
  };

  let Posts = [];

  if (posts.byId.isFetching) {
    Posts = [...Array(5).keys()].map(i => {
      return (
        <TableRow key={'inv' + i}>
          {[...Array(5).keys()].map(i => (
            <TableCell>
              <Skeleton variant="text" />
            </TableCell>
          ))}
        </TableRow>
      );
    });
  }

  if (!posts.byId.isFetching)
    Posts = posts.allIds.map(idPost => {
      let post = posts.byId.posts[idPost];
      return (
        <TableRow key={post.id}>
          <TableCell>{post ? post.id : ''}</TableCell>
          <TableCell>{post ? post.type : ''}</TableCell>
          <TableCell>{post ? post.titlePost : ''}</TableCell>

          <TableCell>
            <Switch
              disabled={disabled}
              checked={!!post.status}
              onChange={() => handleChangeEstado(post.id)}
              name={'estado'}
              color={'primary'}
              size={'small'}
            />
          </TableCell>
          <TableCell>
            <Tooltip title="Editar">
              <IconButton
                aria-label="editar"
                size={'small'}
                disabled={disabled}
              >
                <Link to={path + '/' + post.id}>
                  <EditIcon color="secondary" />
                </Link>
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      );
    });

  if (Posts.length === 0)
    Posts.push(
      <TableRow key={0}>
        <TableCell align="center" colSpan={11}>
          No se encontraron posteos
        </TableCell>
      </TableRow>
    );

  const totalPaginas = Math.ceil(posts.totalPosts ? posts.totalPosts / 50 : 0);

  return (
    <Container maxWidth={'xg'}>
      <Breadcrumbs aria-label="breadcrumb" separator={'‣'}>
        <Link color="inherit" to="/">
          Home
        </Link>
      </Breadcrumbs>
      <Typography component={'h1'} variant={'h1'}>
        {location.pathname.indexOf('jobs') === -1 ? 'Posteos' : 'Empleos'}
      </Typography>
      <CustomPaper>
        <PaperHeader>
          <TextField
            id="search"
            placeholder="Buscar ..."
            variant="outlined"
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <CropFreeIcon />
                  </IconButton>
                </InputAdornment>
              )
              // disabled: disabled
            }}
            value={search ? search : ''}
            onChange={e => changeSearch(e)}
            onKeyDown={e => handleKeyDown(e)}
          />
          <Grow />
        </PaperHeader>
        <TableContainer>
          <Table aria-label="Tabla de contenido">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={order === 'id'}
                    direction={direction}
                    onClick={() => changeOrder('id')}
                  >
                    <b>ID</b>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={order === 'type'}
                    direction={direction}
                    onClick={() => changeOrder('type')}
                  >
                    <b>Tipo</b>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={order === 'genericName'}
                    direction={direction}
                    onClick={() => changeOrder('genericName')}
                  >
                    <b>Título</b>
                  </TableSortLabel>
                </TableCell>
                <TableCell width={'10px'} />
                <TableCell width={'10px'} />
              </TableRow>
            </TableHead>
            <TableBody>{Posts}</TableBody>
          </Table>
        </TableContainer>
        <PaperFooter style={{ justifyContent: 'center' }}>
          {!!totalPaginas && totalPaginas > 1 && (
            <Pagination
              count={totalPaginas}
              page={page ? parseInt(page) : 1}
              color="primary"
              onChange={handleChangePage}
              size={'small'}
            />
          )}
        </PaperFooter>
      </CustomPaper>
      <ContainerBtn>
        <BtnAdd
          onClick={() => props.history.push(path + '/new')}
          fetching={disabled}
        />
      </ContainerBtn>
    </Container>
  );
}

PostsList.propTypes = {};

export default PostsList;
