import { normalize, schema, denormalize } from 'normalizr';

const asset = new schema.Entity('assets', {}, { idAttribute: 'id' });

export function normalizeDatos(myData) {
  const mySchema = { assets: [asset] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = asset;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = asset;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default asset;
