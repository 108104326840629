//api
import tenantUserAccessesApi from '../api/tenantUserAccessesApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizeTenantUserAccesses';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//TENANTUSERACCESSES
export const REQUEST_TENANTUSERACCESSES = 'REQUEST_TENANTUSERACCESSES';
export const RECEIVE_TENANTUSERACCESSES = 'RECEIVE_TENANTUSERACCESSES';
export const INVALIDATE_TENANTUSERACCESSES = 'INVALIDATE_TENANTUSERACCESSES';
export const ERROR_TENANTUSERACCESSES = 'ERROR_TENANTUSERACCESSES';
export const RESET_TENANTUSERACCESSES = 'RESET_TENANTUSERACCESSES';

export function invalidateTenantUserAccesses() {
  return {
    type: INVALIDATE_TENANTUSERACCESSES
  };
}

function requestTenantUserAccesses() {
  return {
    type: REQUEST_TENANTUSERACCESSES
  };
}

function receiveTenantUserAccesses(json) {
  return {
    type: RECEIVE_TENANTUSERACCESSES,
    tenantUserAccesses: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorTenantUserAccesses(error) {
  return {
    type: ERROR_TENANTUSERACCESSES,
    error: error
  };
}

export function resetTenantUserAccesses() {
  return {
    type: RESET_TENANTUSERACCESSES
  };
}

export function fetchTenantUserAccesses(filtros) {
  return dispatch => {
    dispatch(requestTenantUserAccesses());
    return tenantUserAccessesApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveTenantUserAccesses(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorTenantUserAccesses(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorTenantUserAccesses(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

function shouldFetchTenantUserAccesses(state) {
  const tenantUserAccesses = state.tenantUserAccesses.byId;
  if (!tenantUserAccesses) {
    return true;
  } else if (tenantUserAccesses.isFetching) {
    return false;
  } else {
    return tenantUserAccesses.didInvalidate;
  }
}

export function fetchTenantUserAccessesIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchTenantUserAccesses(getState())) {
      return dispatch(fetchTenantUserAccesses(filtros));
    }
  };
}

//MODEL
export const REQUEST_TENANTUSERACCESS = 'REQUEST_TENANTUSERACCESS';
export const RECEIVE_TENANTUSERACCESS = 'RECEIVE_TENANTUSERACCESS';
export const INVALIDATE_TENANTUSERACCESS = 'INVALIDATE_TENANTUSERACCESS';
export const ERROR_TENANTUSERACCESS = 'ERROR_TENANTUSERACCESS';
export const RESET_TENANTUSERACCESS = 'RESET_TENANTUSERACCESS';

export function invalidateTenantUserAccess() {
  return {
    type: INVALIDATE_TENANTUSERACCESS
  };
}

function requestTenantUserAccess() {
  return {
    type: REQUEST_TENANTUSERACCESS
  };
}

export function receiveTenantUserAccess(json) {
  return {
    type: RECEIVE_TENANTUSERACCESS,
    tenantUserAccess: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorTenantUserAccess(error) {
  return {
    type: ERROR_TENANTUSERACCESS,
    error: error
  };
}

export function fetchTenantUserAccess(idTenantUserAccess) {
  return dispatch => {
    dispatch(requestTenantUserAccess());
    return tenantUserAccessesApi
      .getOne(idTenantUserAccess)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveTenantUserAccess(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(errorTenantUserAccess(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorTenantUserAccess(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_TENANTUSERACCESS = 'RECEIVE_FILE_TENANTUSERACCESS';

function receiveFileTenantUserAccess(file) {
  return {
    type: RECEIVE_FILE_TENANTUSERACCESS,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFileTenantUserAccess(idTenantUserAccess, filtros) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return tenantUserAccessesApi
      .getFile(idTenantUserAccess, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFileTenantUserAccess(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorTenantUserAccess(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorTenantUserAccess(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_TENANTUSERACCESS = 'UPDATE_TENANTUSERACCESS';
export const REQUEST_UPDATE_TENANTUSERACCESS =
  'REQUEST_UPDATE_TENANTUSERACCESS';
export const SUCCESS_UPDATE_TENANTUSERACCESS =
  'SUCCESS_UPDATE_TENANTUSERACCESS';
export const ERROR_UPDATE_TENANTUSERACCESS = 'ERROR_UPDATE_TENANTUSERACCESS';
export const RESET_UPDATE_TENANTUSERACCESS = 'RESET_UPDATE_TENANTUSERACCESS';
export const DELETE_UPDATE_TENANTUSERACCESS = 'DELETE_UPDATE_TENANTUSERACCESS';

function requestUpdateTenantUserAccess() {
  return {
    type: REQUEST_UPDATE_TENANTUSERACCESS
  };
}

function receiveUpdateTenantUserAccess(tenantUserAccess) {
  return {
    type: SUCCESS_UPDATE_TENANTUSERACCESS,
    receivedAt: Date.now(),
    tenantUserAccess: normalizeDato(tenantUserAccess)
  };
}

function errorUpdateTenantUserAccess(error) {
  return {
    type: ERROR_UPDATE_TENANTUSERACCESS,
    error: error
  };
}

export function resetUpdateTenantUserAccess() {
  return {
    type: RESET_UPDATE_TENANTUSERACCESS
  };
}

export function updateTenantUserAccess(tenantUserAccess) {
  return {
    type: UPDATE_TENANTUSERACCESS,
    tenantUserAccess
  };
}

export function saveUpdateTenantUserAccess() {
  return (dispatch, getState) => {
    dispatch(requestUpdateTenantUserAccess());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let tenantUserAccess = denormalizeDato(
      getState().tenantUserAccesses.update.activo,
      store
    );

    return tenantUserAccessesApi
      .saveUpdate(tenantUserAccess)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateTenantUserAccess(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorUpdateTenantUserAccess(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateTenantUserAccesses(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorUpdateTenantUserAccesses(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorUpdateTenantUserAccess(JSON.parse(error.message))
                  );
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdateTenantUserAccess(error.data));
                })
                .catch(() => {
                  dispatch(
                    errorUpdateTenantUserAccess(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

export function deleteUpdateTenantUserAccess(tenantUserAccess) {
  return {
    type: DELETE_UPDATE_TENANTUSERACCESS,
    tenantUserAccess
  };
}

//UPDATE TENANTUSERACCESSES
export const REQUEST_UPDATE_TENANTUSERACCESSES =
  'REQUEST_UPDATE_TENANTUSERACCESSES';
export const SUCCESS_UPDATE_TENANTUSERACCESSES =
  'SUCCESS_UPDATE_TENANTUSERACCESSES';
export const ERROR_UPDATE_TENANTUSERACCESSES =
  'ERROR_UPDATE_TENANTUSERACCESSES';
export const RESET_UPDATE_TENANTUSERACCESSES =
  'RESET_UPDATE_TENANTUSERACCESSES';

function requestUpdateTenantUserAccesses() {
  return {
    type: REQUEST_UPDATE_TENANTUSERACCESSES
  };
}

function receiveUpdateTenantUserAccesses(tenantUserAccesses) {
  return {
    type: SUCCESS_UPDATE_TENANTUSERACCESSES,
    receivedAt: Date.now(),
    tenantUserAccesses: normalizeDatos(tenantUserAccesses)
  };
}

function errorUpdateTenantUserAccesses(error) {
  return {
    type: ERROR_UPDATE_TENANTUSERACCESSES,
    error: error
  };
}

export function resetUpdateTenantUserAccesses() {
  return {
    type: RESET_UPDATE_TENANTUSERACCESSES
  };
}

export function saveUpdateTenantUserAccesses() {
  return (dispatch, getState) => {
    dispatch(requestUpdateTenantUserAccesses());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let tenantUserAccesses = getState().tenantUserAccesses.update.activos.map(
      idTenantUserAccess => {
        return denormalizeDato(
          getState().tenantUserAccesses.update.activo[idTenantUserAccess],
          store
        );
      }
    );

    return tenantUserAccessesApi
      .saveUpdateTenantUserAccesses(tenantUserAccesses)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateTenantUserAccesses(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorUpdateTenantUserAccesses(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateTenantUserAccesses(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorUpdateTenantUserAccesses(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorUpdateTenantUserAccesses(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorUpdateTenantUserAccesses(errorMessages.GENERAL_ERROR)
                  );
                });

            return;
        }
      });
  };
}

//ALTA TENANTUSERACCESS
export const CREATE_TENANTUSERACCESS = 'CREATE_TENANTUSERACCESS';
export const REQUEST_CREATE_TENANTUSERACCESS =
  'REQUEST_CREATE_TENANTUSERACCESS';
export const SUCCESS_CREATE_TENANTUSERACCESS =
  'SUCCESS_CREATE_TENANTUSERACCESS';
export const ERROR_CREATE_TENANTUSERACCESS = 'ERROR_CREATE_TENANTUSERACCESS';
export const RESET_CREATE_TENANTUSERACCESS = 'RESET_CREATE_TENANTUSERACCESS';
export const DELETE_CREATE_TENANTUSERACCESS = 'DELETE_CREATE_TENANTUSERACCESS';

//ALTA TENANTUSERACCESS
function requestCreateTenantUserAccess() {
  return {
    type: REQUEST_CREATE_TENANTUSERACCESS
  };
}

function receiveCreateTenantUserAccess(tenantUserAccess) {
  return {
    type: SUCCESS_CREATE_TENANTUSERACCESS,
    receivedAt: Date.now(),
    tenantUserAccess: normalizeDato(tenantUserAccess)
  };
}

export function errorCreateTenantUserAccess(error) {
  return {
    type: ERROR_CREATE_TENANTUSERACCESS,
    error: error
  };
}

export function resetCreateTenantUserAccess() {
  return {
    type: RESET_CREATE_TENANTUSERACCESS
  };
}

export function createTenantUserAccess(tenantUserAccess) {
  return {
    type: CREATE_TENANTUSERACCESS,
    tenantUserAccess
  };
}

export function deleteCreateTenantUserAccess(tenantUserAccess) {
  return {
    type: DELETE_CREATE_TENANTUSERACCESS,
    tenantUserAccess
  };
}

export function saveCreateTenantUserAccess() {
  return (dispatch, getState) => {
    dispatch(requestCreateTenantUserAccess());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let tenantUserAccess = denormalizeDato(
      getState().tenantUserAccesses.create.nuevo,
      store
    );

    return tenantUserAccessesApi
      .saveCreate(tenantUserAccess)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateTenantUserAccess(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorCreateTenantUserAccess(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateTenantUserAccesses(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorCreateTenantUserAccesses(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorCreateTenantUserAccess(JSON.parse(error.message))
                  );
                  if (error.data)
                    dispatch(receiveCreateTenantUserAccess(error.data));
                })
                .catch(() => {
                  dispatch(
                    errorCreateTenantUserAccess(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//CREATE TENANTUSERACCESSES
export const REQUEST_CREATE_TENANTUSERACCESSES =
  'REQUEST_CREATE_TENANTUSERACCESSES';
export const SUCCESS_CREATE_TENANTUSERACCESSES =
  'SUCCESS_CREATE_TENANTUSERACCESSES';
export const ERROR_CREATE_TENANTUSERACCESSES =
  'ERROR_CREATE_TENANTUSERACCESSES';
export const RESET_CREATE_TENANTUSERACCESSES =
  'RESET_CREATE_TENANTUSERACCESSES';

function requestCreateTenantUserAccesses() {
  return {
    type: REQUEST_CREATE_TENANTUSERACCESSES
  };
}

function receiveCreateTenantUserAccesses(tenantUserAccesses) {
  return {
    type: SUCCESS_CREATE_TENANTUSERACCESSES,
    receivedAt: Date.now(),
    tenantUserAccesses: normalizeDatos(tenantUserAccesses)
  };
}

function errorCreateTenantUserAccesses(error) {
  return {
    type: ERROR_CREATE_TENANTUSERACCESSES,
    error: error
  };
}

export function resetCreateTenantUserAccesses() {
  return {
    type: RESET_CREATE_TENANTUSERACCESSES
  };
}

export function saveCreateTenantUserAccesses() {
  return (dispatch, getState) => {
    dispatch(requestCreateTenantUserAccesses());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let tenantUserAccesses = getState().tenantUserAccesses.create.nuevos.map(
      idTenantUserAccess => {
        return denormalizeDato(
          getState().tenantUserAccesses.create.nuevo[idTenantUserAccess],
          store
        );
      }
    );

    return tenantUserAccessesApi
      .saveCreateTenantUserAccesses(tenantUserAccesses)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateTenantUserAccesses(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorCreateTenantUserAccesses(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateTenantUserAccesses(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorCreateTenantUserAccesses(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorCreateTenantUserAccesses(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorCreateTenantUserAccesses(errorMessages.GENERAL_ERROR)
                  );
                });

            return;
        }
      });
  };
}

//DELETE TENANTUSERACCESS
export const DELETE_TENANTUSERACCESS = 'DELETE_TENANTUSERACCESS';
export const REQUEST_DELETE_TENANTUSERACCESS =
  'REQUEST_DELETE_TENANTUSERACCESS';
export const SUCCESS_DELETE_TENANTUSERACCESS =
  'SUCCESS_DELETE_TENANTUSERACCESS';
export const ERROR_DELETE_TENANTUSERACCESS = 'ERROR_DELETE_TENANTUSERACCESS';
export const RESET_DELETE_TENANTUSERACCESS = 'RESET_DELETE_TENANTUSERACCESS';

function requestDeleteTenantUserAccess() {
  return {
    type: REQUEST_DELETE_TENANTUSERACCESS
  };
}

function receiveDeleteTenantUserAccess(tenantUserAccess) {
  return {
    type: SUCCESS_DELETE_TENANTUSERACCESS,
    receivedAt: Date.now(),
    tenantUserAccess: normalizeDato(tenantUserAccess)
  };
}

function errorDeleteTenantUserAccess(error) {
  return {
    type: ERROR_DELETE_TENANTUSERACCESS,
    error: error
  };
}

export function resetDeleteTenantUserAccess(error) {
  return {
    type: RESET_DELETE_TENANTUSERACCESS,
    error: error
  };
}

export function deleteTenantUserAccess(tenantUserAccess) {
  return {
    type: DELETE_TENANTUSERACCESS,
    tenantUserAccess
  };
}

export function saveDeleteTenantUserAccess(tenantUserAccess) {
  return dispatch => {
    dispatch(requestDeleteTenantUserAccess());
    return tenantUserAccessesApi
      .saveDelete(tenantUserAccess)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeleteTenantUserAccess());
        dispatch(receiveDeleteTenantUserAccess(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorDeleteTenantUserAccess(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeleteTenantUserAccess(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorDeleteTenantUserAccess(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorDeleteTenantUserAccess(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorDeleteTenantUserAccess(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//PRINT TENANTUSERACCESS
export const PRINT_TENANTUSERACCESS = 'PRINT_TENANTUSERACCESS';
export const REQUEST_PRINT_TENANTUSERACCESS = 'REQUEST_PRINT_TENANTUSERACCESS';
export const SUCCESS_PRINT_TENANTUSERACCESS = 'SUCCESS_PRINT_TENANTUSERACCESS';
export const ERROR_PRINT_TENANTUSERACCESS = 'ERROR_PRINT_TENANTUSERACCESS';
export const RESET_PRINT_TENANTUSERACCESS = 'RESET_PRINT_TENANTUSERACCESS';
export const DELETE_PRINT_TENANTUSERACCESS = 'DELETE_PRINT_TENANTUSERACCESS';

function requestPrintTenantUserAccess() {
  return {
    type: REQUEST_PRINT_TENANTUSERACCESS
  };
}

function receivePrintTenantUserAccess(turnos) {
  return {
    type: SUCCESS_PRINT_TENANTUSERACCESS,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintTenantUserAccess(error) {
  return {
    type: ERROR_PRINT_TENANTUSERACCESS,
    error: error
  };
}

export function resetPrintTenantUserAccess() {
  return {
    type: RESET_PRINT_TENANTUSERACCESS
  };
}

export function printTenantUserAccess(tenantUserAccess) {
  return {
    type: PRINT_TENANTUSERACCESS,
    tenantUserAccess
  };
}

export function deletePrintTenantUserAccess(tenantUserAccess) {
  return {
    type: DELETE_PRINT_TENANTUSERACCESS,
    tenantUserAccess
  };
}

export function savePrintTenantUserAccess(idTenantUserAccess) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintTenantUserAccess());
    return tenantUserAccessesApi
      .printTenantUserAccess(idTenantUserAccess)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintTenantUserAccess(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorPrintTenantUserAccess(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintTenantUserAccess(
                  JSON.parse(error.responseJSON.message)
                )
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorPrintTenantUserAccess(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorPrintTenantUserAccess(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//PRINT TENANTUSERACCESSES
export const REQUEST_PRINT_TENANTUSERACCESSES =
  'REQUEST_PRINT_TENANTUSERACCESSES';
export const SUCCESS_PRINT_TENANTUSERACCESSES =
  'SUCCESS_PRINT_TENANTUSERACCESSES';
export const ERROR_PRINT_TENANTUSERACCESSES = 'ERROR_PRINT_TENANTUSERACCESSES';
export const RESET_PRINT_TENANTUSERACCESSES = 'RESET_PRINT_TENANTUSERACCESSES';

function requestPrintTenantUserAccesses() {
  return {
    type: REQUEST_PRINT_TENANTUSERACCESSES
  };
}

function receivePrintTenantUserAccesses(tenantUserAccesses) {
  return {
    type: SUCCESS_PRINT_TENANTUSERACCESSES,
    receivedAt: Date.now(),
    tenantUserAccesses: normalizeDatos(tenantUserAccesses)
  };
}

function errorPrintTenantUserAccesses(error) {
  return {
    type: ERROR_PRINT_TENANTUSERACCESSES,
    error: error
  };
}

export function resetPrintTenantUserAccesses() {
  return {
    type: RESET_PRINT_TENANTUSERACCESSES
  };
}

export function savePrintTenantUserAccesses() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintTenantUserAccess());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let tenantUserAccesses = getState().tenantUserAccesses.print.printers.map(
      idTenantUserAccess => {
        return denormalizeDato(
          getState().tenantUserAccesses.print.print[idTenantUserAccess],
          store
        );
      }
    );
    return tenantUserAccessesApi
      .printTenantUserAccesses(tenantUserAccesses)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintTenantUserAccesses(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorPrintTenantUserAccesses(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintTenantUserAccesses(
                  JSON.parse(error.responseJSON.message)
                )
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorPrintTenantUserAccesses(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorPrintTenantUserAccesses(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}
