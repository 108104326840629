import { normalize, schema, denormalize } from 'normalizr';

const neighborhood = new schema.Entity(
  'neighborhoods',
  {},
  { idAttribute: 'id' }
);

export function normalizeDatos(myData) {
  const mySchema = { neighborhoods: [neighborhood] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = neighborhood;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = neighborhood;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default neighborhood;
