import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { fetchTenant } from '../../actions/TenantActions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import tenantUtil from '../../utils/tenantUtil';
import CustomPaper from '../../library/CustomPaper/CustomPaper';
import PaperBody from '../../library/CustomPaper/PaperBody';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Custom from '../../assets/img/icon/web-development.png';
import ExternalSystem from '../../assets/img/icon/externalSystem.svg';
import Website from '../../assets/img/icon/website.png';
import * as PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  item: {
    '&.MuiListItem-secondaryAction': {
      paddingRight: '90px'
    }
  },
  paper: {
    backgroundColor: theme.palette.secondary.light
  }
}));

const externalSystemsIcons = {
  Custom: Custom,
  ExternalSystem: ExternalSystem,
  Website: Website
};

const steps = [
  {
    description:
      'Configura los datos de tu inmobiliaria, sucursal principal, logos y colores.',
    icon: 'Custom',
    url: '/configuration/account'
  },
  {
    description: 'Conecta tu CRM con tu sitio web.',
    icon: 'ExternalSystem',
    url: '/configuration/synchronization'
  },
  {
    description:
      'Configura un dominio personalizado para ingresar a tu sitio web.',
    icon: 'Website',
    url: '/configuration/website'
  }
];

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = { value: PropTypes.any };

function FirstStepsCard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  //State
  const [values, setValues] = useState([0, 0, 0]);
  const [open, setOpen] = useState(true);

  //Store
  const tenants = useSelector(state => state.tenants);
  const tenant =
    tenantUtil.getTenant() && tenants.byId.tenants[tenantUtil.getTenant()]
      ? tenants.byId.tenants[tenantUtil.getTenant()]
      : tenants.update.activo;
  const dependencies = useSelector(state => state.dependencies);
  const dependency =
    tenant && tenant.dependency?.[0]
      ? dependencies.byId.dependencies[tenant.dependency?.[0]]
      : dependencies.update.activo;

  useEffect(() => {
    dispatch(fetchTenant(tenantUtil.getTenant()));
  }, []);

  useEffect(() => {
    let value1 = [
      'primaryColor',
      'secondaryColor',
      'filename',
      'filename1',
      'filename2'
    ].reduce((accumulator, currentValue) => {
      if (tenant[currentValue]) return accumulator + 10;
      else return accumulator;
    }, 0);
    value1 = [
      'nameDependency',
      'address',
      'phoneNumber1',
      'phoneNumber1',
      'email'
    ].reduce((accumulator, currentValue) => {
      if (dependency?.[currentValue]) return accumulator + 10;
      else return accumulator;
    }, value1);
    let value2 = ['tenant.external_system_name', 'external_system_key'].reduce(
      (accumulator, currentValue) => {
        if (tenant[currentValue]) return accumulator + 50;
        else return accumulator;
      },
      0
    );
    let value3 = tenant?.domains?.length > 1 ? 100 : 0;
    setValues([value1, value2, value3]);
  }, [tenant.id]);

  const onClickNoVolverAMostrar = () => {
    localStorage.setItem('no_volver_a_mostrar_ayuda', true);
    setOpen(false);
  };

  return (
    <>
      {!localStorage.no_volver_a_mostrar_ayuda && (
        <Grid item md={5} sm={12} xs={12} spacing={1}>
          <CustomPaper style={{ marginTop: 0 }} className={classes.paper}>
            <PaperBody>
              <Grid
                container
                spacing={1}
                style={{ marginTop: '10px', marginBottom: '10px' }}
              >
                <Grid item md={12} sm={12} xs={12}>
                  <Typography
                    variant={'overline'}
                    component={'span'}
                    display={'block'}
                  >
                    {`Primeros pasos`}
                  </Typography>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <List className={classes.root}>
                    {steps.map((step, i) => {
                      return (
                        <ListItem
                          key={'step' + i}
                          className={classes.item}
                          button
                          onClick={() => history.push(step.url)}
                        >
                          <ListItemAvatar>
                            <Avatar
                              src={externalSystemsIcons[step.icon]}
                            ></Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={'Paso ' + (i + 1)}
                            secondary={step.description}
                          />
                          <ListItemSecondaryAction>
                            <CircularProgressWithLabel value={values[i]} />
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                </Grid>
              </Grid>
              <Box display={'flex'} justifyContent={'flex-end'} width={'100%'}>
                <Button size="small" onClick={onClickNoVolverAMostrar}>
                  No volver a mostrar
                </Button>
              </Box>
            </PaperBody>
          </CustomPaper>
        </Grid>
      )}
    </>
  );
}

export default FirstStepsCard;
