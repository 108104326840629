import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as PropertyStatusC from '../constants/PropertyStatus';
import clx from 'clsx';
import DoneIcon from '@material-ui/icons/Done';
import PropertyClosedOperationModal from '../component/PropertyClosedOperation/PropertyClosedOperationModal';
import clone from 'lodash/clone';
import { updatePropertyClosedOperation } from '../actions/PropertyClosedOperationActions';
import { useDispatch, useSelector } from 'react-redux';
import { deleteProperty, saveDeleteProperty } from '../actions/PropertyActions';

const useStyles = makeStyles(theme => ({
  danger: {
    backgroundColor: theme.palette.error.main,
    color: 'white'
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: 'white'
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
    color: 'white'
  },
  columnaEstado: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    '& p': {
      color: 'rgba(0,0,0,0.6)',
      fontWeight: 300,
      fontSize: 'smaller',
      marginBottom: 0,
      marginTop: '3px',
      padding: 0
    }
  },
  chipsRoot: {
    height: '30px',
    minWidth: '100px',
    [theme.breakpoints.down('sm')]: {
      minWidth: '85px'
    }
  },
  chipsRootXS: {
    height: '22px',
    minWidth: '70px',
    fontSize: '11px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      minWidth: '65px'
    }
  }
}));
export default function PropertyStatus(props) {
  const { property_id, disabled = false } = props;
  const dispatch = useDispatch();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(null);
  const [idPropertyClosedOperation, setIdPropertyClosedOperation] =
    React.useState(null);

  const propertyClosedOperations = useSelector(
    state => state.propertyClosedOperations
  );

  const size = props.size !== undefined ? props.size : 'medium';

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenu = (type, idPropertyClosedOperation_) => {
    setAnchorEl(null);
    if (type === PropertyStatusC.NO_DISPONIBLE) {
      if (!idPropertyClosedOperation_) {
        setIdPropertyClosedOperation(Date.now() + Math.random() + '-');
      } else {
        setIdPropertyClosedOperation(idPropertyClosedOperation_);
        if (
          propertyClosedOperations.update.activos.indexOf(
            idPropertyClosedOperation_
          ) === -1
        ) {
          let attributeUpdate = clone(
            propertyClosedOperations.byId.propertyClosedOperations[
              idPropertyClosedOperation_
            ]
          );
          let cambio = {};
          cambio[idPropertyClosedOperation_] = attributeUpdate;
          dispatch(updatePropertyClosedOperation(cambio));
        }
      }
      setOpen(true);
    } else handleChangeStatusProperty();
  };

  const handleChangeStatusProperty = () => {
    dispatch(deleteProperty({ id: property_id }));
    dispatch(saveDeleteProperty({ id: property_id }));
  };

  const getChip = () => {
    switch (props.status) {
      case 1:
        return (
          <Chip
            size={size}
            className={clx({
              [classes.chipsRoot]: true,
              [classes.chipsRootXS]: size === 'xs'
            })}
            id={props.id + 'chip'}
            color={'primary'}
            icon={<DoneIcon />}
            variant="outlined"
            label={PropertyStatusC.DISPONIBLE}
            onClick={handleClick}
            disabled={disabled}
          />
        );
      case 0:
        return (
          <Chip
            size={size}
            id={props.id + 'chip'}
            className={clx({
              [classes.chipsRoot]: size === 'small',
              [classes.chipsRootXS]: size === 'xs'
            })}
            variant="outlined"
            label={PropertyStatusC.NO_DISPONIBLE}
            onClick={handleClick}
            disabled={disabled}
          />
        );
      default:
        return (
          <Chip
            size={size}
            id={props.id + 'chip'}
            label={props.estadoTurno}
            className={clx({
              [classes.chipsRoot]: size === 'small',
              [classes.chipsRootXS]: size === 'xs'
            })}
          />
        );
    }
  };

  const getMenuItems = () => {
    switch (props.status) {
      case 1:
        return [PropertyStatusC.NO_DISPONIBLE];
      case 0:
        return [PropertyStatusC.DISPONIBLE];
      default:
        return [];
    }
  };

  return (
    <>
      <div>
        <div className={classes.columnaEstado}>{getChip()}</div>
        {!disabled && (
          <Menu
            id={props.id}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {getMenuItems().map(desEstadoTurno => {
              return (
                <MenuItem
                  key={props.id}
                  onClick={() => handleClickMenu(desEstadoTurno)}
                >
                  {desEstadoTurno}
                </MenuItem>
              );
            })}
          </Menu>
        )}
      </div>
      {!disabled && (
        <PropertyClosedOperationModal
          open={open}
          setOpen={setOpen}
          idPropertyClosedOperation={idPropertyClosedOperation}
          property_id={property_id}
        />
      )}
    </>
  );
}
