import CustomPaper from '../../library/CustomPaper/CustomPaper';
import PropertiesList from '../Property/PropertiesList';
import React, { useEffect, useState } from 'react';
import {
  deletePrintProperty,
  fetchProperties,
  invalidateProperties,
  printProperty,
  resetProperties
} from '../../actions/PropertyActions';
import { useDispatch, useSelector } from 'react-redux';
import PropertyCard from '../Property/PropertyCard';
import Grid from '@material-ui/core/Grid';
import PaperBody from '../../library/CustomPaper/PaperBody';
import DesktopFilterNavBar from '../Property/FilterBar/DesktopFilterNavBar';
import PropertiesPaginationList from '../Property/PropertiesPaginationList';
import Box from '@material-ui/core/Box';
import { MenuItem, useTheme } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { useStyles } from '@material-ui/pickers/views/Year/Year';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import { useHistory } from 'react-router';
import { createTicketProperty } from '../../actions/TicketPropertyActions';
import { saveUpdateTicket } from '../../actions/TicketActions';

function PropertyCardComponent(props) {
  const history = useHistory();
  const { property, disabled = false } = props;
  const dispatch = useDispatch();
  const properties = useSelector(state => state.properties);
  const tickets = useSelector(state => state.tickets);
  const ticket = tickets.update.activo;
  const ticketProperties = useSelector(state => state.ticketProperties);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  function handleAdd() {
    let property_ = {};
    property_[property.id] = { ...property };
    if (properties.print.printers?.indexOf(property.id) === -1) {
      dispatch(printProperty(property_));
    } else {
      dispatch(deletePrintProperty(property_));
    }
  }

  function handleAddTicketProperty() {
    handleClose();
    let isAdded = isTicketProperty(property.id);
    if (!isAdded) {
      let ticketProperty = {
        id: Date.now() + Math.random() + '-',
        property_id: property.id
      };
      let ticketProperty_ = {};
      ticketProperty_[ticketProperty.id] = ticketProperty;
      dispatch(createTicketProperty(ticketProperty_));
      dispatch(saveUpdateTicket());
    }
  }

  const isTicketProperty = id => {
    return ticket?.ticket_property?.some((idTicketProperty, i) => {
      let ticketProperty =
        ticketProperties.byId.ticketProperties[idTicketProperty];
      if (ticketProperty?.property_id === id) return true;
      return false;
    });
  };

  return (
    // <CustomPaper>
    //   <PaperBody>
    <Grid container spacing={3} style={{ marginTop: '10px' }}>
      <PropertyCard property={property} disabled={true} />
      <Grid item md={12} sm={12} xs={12}>
        <Box
          width={'100%'}
          display={'flex'}
          justifyContent={'flex-end'}
          marginBottom={'10px'}
        >
          <Button
            color={
              properties.print.printers?.indexOf(property.id) === -1
                ? 'default'
                : 'primary'
            }
            onClick={handleAdd}
            variant={
              properties.print.printers?.indexOf(property.id) === -1
                ? 'default'
                : 'contained'
            }
          >
            {properties.print.printers?.indexOf(property.id) === -1
              ? 'Seleccionar'
              : 'Seleccionado'}
          </Button>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={handleAddTicketProperty}
              disabled={isTicketProperty(property.id)}
            >
              Agregar propiedad de interés
            </MenuItem>
            <MenuItem
              onClick={() => history.push('/properties/' + property.id)}
            >
              Editar
            </MenuItem>
            <MenuItem
              onClick={() => history.push('/properties/' + property.id)}
            >
              Ver
            </MenuItem>
          </Menu>
        </Box>
        <Divider />
      </Grid>
    </Grid>
    //   </PaperBody>
    // </CustomPaper>
  );
}

export default function SuggestedTicketPropertyList(props) {
  const dispatch = useDispatch(); //Store
  const properties = useSelector(state => state.properties);
  const tickets = useSelector(state => state.tickets);
  const ticket = tickets.update.activo;

  //State
  const [search, setSearch] = useState('');
  const [page, setPage] = useState('');
  const [queryRouter, setQueryRouter] = React.useState(ticket.query);

  useEffect(() => {
    let filtros = {
      ...{
        searchPhase: search ? search : '',
        order: 'id',
        direction: 'asc',
        page: page,
        with: ['mainAsset', 'propertyAttributeValue', 'propertyOperation']
      },
      ...queryRouter
    };
    dispatch(resetProperties());
    dispatch(invalidateProperties());
    dispatch(fetchProperties(filtros));
  }, [queryRouter, page]);

  const handleChangePage = (e, page) => {
    setPage(page ? page : '1');
    window.scrollTo(0, 0);
  };

  return (
    <CustomPaper>
      <DesktopFilterNavBar
        queryRouter={queryRouter}
        setQueryRouter={setQueryRouter}
      />
      <PaperBody>
        <Grid container spacing={3}>
          <PropertiesList
            component={PropertyCardComponent}
            properties={properties}
          />
        </Grid>
        <PropertiesPaginationList
          totalProperties={properties?.totalProperties}
          handleChangePage={handleChangePage}
          page={page}
        />
      </PaperBody>
    </CustomPaper>
  );
}
