//api
import ticketActivitiesApi from '../api/ticketActivitiesApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizeTicketActivities';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//TICKETACTIVITIES
export const REQUEST_TICKETACTIVITIES = 'REQUEST_TICKETACTIVITIES';
export const RECEIVE_TICKETACTIVITIES = 'RECEIVE_TICKETACTIVITIES';
export const INVALIDATE_TICKETACTIVITIES = 'INVALIDATE_TICKETACTIVITIES';
export const ERROR_TICKETACTIVITIES = 'ERROR_TICKETACTIVITIES';
export const RESET_TICKETACTIVITIES = 'RESET_TICKETACTIVITIES';

export function invalidateTicketActivities() {
  return {
    type: INVALIDATE_TICKETACTIVITIES
  };
}

function requestTicketActivities() {
  return {
    type: REQUEST_TICKETACTIVITIES
  };
}

function receiveTicketActivities(json) {
  return {
    type: RECEIVE_TICKETACTIVITIES,
    ticketActivities: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorTicketActivities(error) {
  return {
    type: ERROR_TICKETACTIVITIES,
    error: error
  };
}

export function resetTicketActivities() {
  return {
    type: RESET_TICKETACTIVITIES
  };
}

export function fetchTicketActivities(filtros) {
  return dispatch => {
    dispatch(requestTicketActivities());
    return ticketActivitiesApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveTicketActivities(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorTicketActivities(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorTicketActivities(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

function shouldFetchTicketActivities(state) {
  const ticketActivities = state.ticketActivities.byId;
  if (!ticketActivities) {
    return true;
  } else if (ticketActivities.isFetching) {
    return false;
  } else {
    return ticketActivities.didInvalidate;
  }
}

export function fetchTicketActivitiesIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchTicketActivities(getState())) {
      return dispatch(fetchTicketActivities(filtros));
    }
  };
}

//MODEL
export const REQUEST_TICKETACTIVITY = 'REQUEST_TICKETACTIVITY';
export const RECEIVE_TICKETACTIVITY = 'RECEIVE_TICKETACTIVITY';
export const INVALIDATE_TICKETACTIVITY = 'INVALIDATE_TICKETACTIVITY';
export const ERROR_TICKETACTIVITY = 'ERROR_TICKETACTIVITY';
export const RESET_TICKETACTIVITY = 'RESET_TICKETACTIVITY';

export function invalidateTicketActivity() {
  return {
    type: INVALIDATE_TICKETACTIVITY
  };
}

function requestTicketActivity() {
  return {
    type: REQUEST_TICKETACTIVITY
  };
}

export function receiveTicketActivity(json) {
  return {
    type: RECEIVE_TICKETACTIVITY,
    ticketActivity: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorTicketActivity(error) {
  return {
    type: ERROR_TICKETACTIVITY,
    error: error
  };
}

export function fetchTicketActivity(idTicketActivity) {
  return dispatch => {
    dispatch(requestTicketActivity());
    return ticketActivitiesApi
      .getOne(idTicketActivity)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveTicketActivity(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(errorTicketActivity(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorTicketActivity(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_TICKETACTIVITY = 'RECEIVE_FILE_TICKETACTIVITY';

function receiveFileTicketActivity(file) {
  return {
    type: RECEIVE_FILE_TICKETACTIVITY,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFileTicketActivity(idTicketActivity, filtros) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return ticketActivitiesApi
      .getFile(idTicketActivity, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFileTicketActivity(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorTicketActivity(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorTicketActivity(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_TICKETACTIVITY = 'UPDATE_TICKETACTIVITY';
export const REQUEST_UPDATE_TICKETACTIVITY = 'REQUEST_UPDATE_TICKETACTIVITY';
export const SUCCESS_UPDATE_TICKETACTIVITY = 'SUCCESS_UPDATE_TICKETACTIVITY';
export const ERROR_UPDATE_TICKETACTIVITY = 'ERROR_UPDATE_TICKETACTIVITY';
export const RESET_UPDATE_TICKETACTIVITY = 'RESET_UPDATE_TICKETACTIVITY';
export const DELETE_UPDATE_TICKETACTIVITY = 'DELETE_UPDATE_TICKETACTIVITY';

function requestUpdateTicketActivity() {
  return {
    type: REQUEST_UPDATE_TICKETACTIVITY
  };
}

function receiveUpdateTicketActivity(ticketActivity) {
  return {
    type: SUCCESS_UPDATE_TICKETACTIVITY,
    receivedAt: Date.now(),
    ticketActivity: normalizeDato(ticketActivity)
  };
}

function errorUpdateTicketActivity(error) {
  return {
    type: ERROR_UPDATE_TICKETACTIVITY,
    error: error
  };
}

export function resetUpdateTicketActivity() {
  return {
    type: RESET_UPDATE_TICKETACTIVITY
  };
}

export function updateTicketActivity(ticketActivity) {
  return {
    type: UPDATE_TICKETACTIVITY,
    ticketActivity
  };
}

export function saveUpdateTicketActivity() {
  return (dispatch, getState) => {
    dispatch(requestUpdateTicketActivity());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let ticketActivity = denormalizeDato(
      getState().ticketActivities.update.activo,
      store
    );

    return ticketActivitiesApi
      .saveUpdate(ticketActivity)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateTicketActivity(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorUpdateTicketActivity(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateTicketActivities(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorUpdateTicketActivities(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorUpdateTicketActivity(JSON.parse(error.message))
                  );
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdateTicketActivity(error.data));
                })
                .catch(() => {
                  dispatch(
                    errorUpdateTicketActivity(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

export function deleteUpdateTicketActivity(ticketActivity) {
  return {
    type: DELETE_UPDATE_TICKETACTIVITY,
    ticketActivity
  };
}

//UPDATE TICKETACTIVITIES
export const REQUEST_UPDATE_TICKETACTIVITIES =
  'REQUEST_UPDATE_TICKETACTIVITIES';
export const SUCCESS_UPDATE_TICKETACTIVITIES =
  'SUCCESS_UPDATE_TICKETACTIVITIES';
export const ERROR_UPDATE_TICKETACTIVITIES = 'ERROR_UPDATE_TICKETACTIVITIES';
export const RESET_UPDATE_TICKETACTIVITIES = 'RESET_UPDATE_TICKETACTIVITIES';

function requestUpdateTicketActivities() {
  return {
    type: REQUEST_UPDATE_TICKETACTIVITIES
  };
}

function receiveUpdateTicketActivities(ticketActivities) {
  return {
    type: SUCCESS_UPDATE_TICKETACTIVITIES,
    receivedAt: Date.now(),
    ticketActivities: normalizeDatos(ticketActivities)
  };
}

function errorUpdateTicketActivities(error) {
  return {
    type: ERROR_UPDATE_TICKETACTIVITIES,
    error: error
  };
}

export function resetUpdateTicketActivities() {
  return {
    type: RESET_UPDATE_TICKETACTIVITIES
  };
}

export function saveUpdateTicketActivities() {
  return (dispatch, getState) => {
    dispatch(requestUpdateTicketActivities());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let ticketActivities = getState().ticketActivities.update.activos.map(
      idTicketActivity => {
        return denormalizeDato(
          getState().ticketActivities.update.activo[idTicketActivity],
          store
        );
      }
    );

    return ticketActivitiesApi
      .saveUpdateTicketActivities(ticketActivities)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateTicketActivities(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorUpdateTicketActivities(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateTicketActivities(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorUpdateTicketActivities(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorUpdateTicketActivities(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorUpdateTicketActivities(errorMessages.GENERAL_ERROR)
                  );
                });

            return;
        }
      });
  };
}

//ALTA TICKETACTIVITY
export const CREATE_TICKETACTIVITY = 'CREATE_TICKETACTIVITY';
export const REQUEST_CREATE_TICKETACTIVITY = 'REQUEST_CREATE_TICKETACTIVITY';
export const SUCCESS_CREATE_TICKETACTIVITY = 'SUCCESS_CREATE_TICKETACTIVITY';
export const ERROR_CREATE_TICKETACTIVITY = 'ERROR_CREATE_TICKETACTIVITY';
export const RESET_CREATE_TICKETACTIVITY = 'RESET_CREATE_TICKETACTIVITY';
export const DELETE_CREATE_TICKETACTIVITY = 'DELETE_CREATE_TICKETACTIVITY';

//ALTA TICKETACTIVITY
function requestCreateTicketActivity() {
  return {
    type: REQUEST_CREATE_TICKETACTIVITY
  };
}

function receiveCreateTicketActivity(ticketActivity) {
  return {
    type: SUCCESS_CREATE_TICKETACTIVITY,
    receivedAt: Date.now(),
    ticketActivity: normalizeDato(ticketActivity)
  };
}

export function errorCreateTicketActivity(error) {
  return {
    type: ERROR_CREATE_TICKETACTIVITY,
    error: error
  };
}

export function resetCreateTicketActivity() {
  return {
    type: RESET_CREATE_TICKETACTIVITY
  };
}

export function createTicketActivity(ticketActivity) {
  return {
    type: CREATE_TICKETACTIVITY,
    ticketActivity
  };
}

export function deleteCreateTicketActivity(ticketActivity) {
  return {
    type: DELETE_CREATE_TICKETACTIVITY,
    ticketActivity
  };
}

export function saveCreateTicketActivity() {
  return (dispatch, getState) => {
    dispatch(requestCreateTicketActivity());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let ticketActivity = denormalizeDato(
      getState().ticketActivities.create.nuevo,
      store
    );

    return ticketActivitiesApi
      .saveCreate(ticketActivity)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateTicketActivity(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorCreateTicketActivity(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateTicketActivities(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorCreateTicketActivities(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorCreateTicketActivity(JSON.parse(error.message))
                  );
                  if (error.data)
                    dispatch(receiveCreateTicketActivity(error.data));
                })
                .catch(() => {
                  dispatch(
                    errorCreateTicketActivity(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//CREATE TICKETACTIVITIES
export const REQUEST_CREATE_TICKETACTIVITIES =
  'REQUEST_CREATE_TICKETACTIVITIES';
export const SUCCESS_CREATE_TICKETACTIVITIES =
  'SUCCESS_CREATE_TICKETACTIVITIES';
export const ERROR_CREATE_TICKETACTIVITIES = 'ERROR_CREATE_TICKETACTIVITIES';
export const RESET_CREATE_TICKETACTIVITIES = 'RESET_CREATE_TICKETACTIVITIES';

function requestCreateTicketActivities() {
  return {
    type: REQUEST_CREATE_TICKETACTIVITIES
  };
}

function receiveCreateTicketActivities(ticketActivities) {
  return {
    type: SUCCESS_CREATE_TICKETACTIVITIES,
    receivedAt: Date.now(),
    ticketActivities: normalizeDatos(ticketActivities)
  };
}

function errorCreateTicketActivities(error) {
  return {
    type: ERROR_CREATE_TICKETACTIVITIES,
    error: error
  };
}

export function resetCreateTicketActivities() {
  return {
    type: RESET_CREATE_TICKETACTIVITIES
  };
}

export function saveCreateTicketActivities() {
  return (dispatch, getState) => {
    dispatch(requestCreateTicketActivities());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let ticketActivities = getState().ticketActivities.create.nuevos.map(
      idTicketActivity => {
        return denormalizeDato(
          getState().ticketActivities.create.nuevo[idTicketActivity],
          store
        );
      }
    );

    return ticketActivitiesApi
      .saveCreateTicketActivities(ticketActivities)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateTicketActivities(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorCreateTicketActivities(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateTicketActivities(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorCreateTicketActivities(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorCreateTicketActivities(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorCreateTicketActivities(errorMessages.GENERAL_ERROR)
                  );
                });

            return;
        }
      });
  };
}

//DELETE TICKETACTIVITY
export const DELETE_TICKETACTIVITY = 'DELETE_TICKETACTIVITY';
export const REQUEST_DELETE_TICKETACTIVITY = 'REQUEST_DELETE_TICKETACTIVITY';
export const SUCCESS_DELETE_TICKETACTIVITY = 'SUCCESS_DELETE_TICKETACTIVITY';
export const ERROR_DELETE_TICKETACTIVITY = 'ERROR_DELETE_TICKETACTIVITY';
export const RESET_DELETE_TICKETACTIVITY = 'RESET_DELETE_TICKETACTIVITY';

function requestDeleteTicketActivity() {
  return {
    type: REQUEST_DELETE_TICKETACTIVITY
  };
}

function receiveDeleteTicketActivity(ticketActivity) {
  return {
    type: SUCCESS_DELETE_TICKETACTIVITY,
    receivedAt: Date.now(),
    ticketActivity: normalizeDato(ticketActivity)
  };
}

function errorDeleteTicketActivity(error) {
  return {
    type: ERROR_DELETE_TICKETACTIVITY,
    error: error
  };
}

export function resetDeleteTicketActivity(error) {
  return {
    type: RESET_DELETE_TICKETACTIVITY,
    error: error
  };
}

export function deleteTicketActivity(ticketActivity) {
  return {
    type: DELETE_TICKETACTIVITY,
    ticketActivity
  };
}

export function saveDeleteTicketActivity(ticketActivity) {
  return dispatch => {
    dispatch(requestDeleteTicketActivity());
    return ticketActivitiesApi
      .saveDelete(ticketActivity)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeleteTicketActivity());
        dispatch(receiveDeleteTicketActivity(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorDeleteTicketActivity(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeleteTicketActivity(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorDeleteTicketActivity(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorDeleteTicketActivity(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorDeleteTicketActivity(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//PRINT TICKETACTIVITY
export const PRINT_TICKETACTIVITY = 'PRINT_TICKETACTIVITY';
export const REQUEST_PRINT_TICKETACTIVITY = 'REQUEST_PRINT_TICKETACTIVITY';
export const SUCCESS_PRINT_TICKETACTIVITY = 'SUCCESS_PRINT_TICKETACTIVITY';
export const ERROR_PRINT_TICKETACTIVITY = 'ERROR_PRINT_TICKETACTIVITY';
export const RESET_PRINT_TICKETACTIVITY = 'RESET_PRINT_TICKETACTIVITY';
export const DELETE_PRINT_TICKETACTIVITY = 'DELETE_PRINT_TICKETACTIVITY';

function requestPrintTicketActivity() {
  return {
    type: REQUEST_PRINT_TICKETACTIVITY
  };
}

function receivePrintTicketActivity(turnos) {
  return {
    type: SUCCESS_PRINT_TICKETACTIVITY,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintTicketActivity(error) {
  return {
    type: ERROR_PRINT_TICKETACTIVITY,
    error: error
  };
}

export function resetPrintTicketActivity() {
  return {
    type: RESET_PRINT_TICKETACTIVITY
  };
}

export function printTicketActivity(ticketActivity) {
  return {
    type: PRINT_TICKETACTIVITY,
    ticketActivity
  };
}

export function deletePrintTicketActivity(ticketActivity) {
  return {
    type: DELETE_PRINT_TICKETACTIVITY,
    ticketActivity
  };
}

export function savePrintTicketActivity(idTicketActivity) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintTicketActivity());
    return ticketActivitiesApi
      .printTicketActivity(idTicketActivity)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintTicketActivity(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorPrintTicketActivity(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintTicketActivity(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintTicketActivity(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(
                    errorPrintTicketActivity(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//PRINT TICKETACTIVITIES
export const REQUEST_PRINT_TICKETACTIVITIES = 'REQUEST_PRINT_TICKETACTIVITIES';
export const SUCCESS_PRINT_TICKETACTIVITIES = 'SUCCESS_PRINT_TICKETACTIVITIES';
export const ERROR_PRINT_TICKETACTIVITIES = 'ERROR_PRINT_TICKETACTIVITIES';
export const RESET_PRINT_TICKETACTIVITIES = 'RESET_PRINT_TICKETACTIVITIES';

function requestPrintTicketActivities() {
  return {
    type: REQUEST_PRINT_TICKETACTIVITIES
  };
}

function receivePrintTicketActivities(ticketActivities) {
  return {
    type: SUCCESS_PRINT_TICKETACTIVITIES,
    receivedAt: Date.now(),
    ticketActivities: normalizeDatos(ticketActivities)
  };
}

function errorPrintTicketActivities(error) {
  return {
    type: ERROR_PRINT_TICKETACTIVITIES,
    error: error
  };
}

export function resetPrintTicketActivities() {
  return {
    type: RESET_PRINT_TICKETACTIVITIES
  };
}

export function savePrintTicketActivities() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintTicketActivity());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let ticketActivities = getState().ticketActivities.print.printers.map(
      idTicketActivity => {
        return denormalizeDato(
          getState().ticketActivities.print.print[idTicketActivity],
          store
        );
      }
    );
    return ticketActivitiesApi
      .printTicketActivities(ticketActivities)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintTicketActivities(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorPrintTicketActivities(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintTicketActivities(
                  JSON.parse(error.responseJSON.message)
                )
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorPrintTicketActivities(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorPrintTicketActivities(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}
