import {
  INVALIDATE_COUNTRIES,
  ERROR_COUNTRIES,
  RECEIVE_COUNTRIES,
  REQUEST_COUNTRIES,
  RESET_COUNTRIES,
  ERROR_COUNTRY,
  RECEIVE_COUNTRY,
  REQUEST_COUNTRY,
  UPDATE_COUNTRY,
  REQUEST_UPDATE_COUNTRY,
  SUCCESS_UPDATE_COUNTRY,
  ERROR_UPDATE_COUNTRY,
  RESET_UPDATE_COUNTRY,
  REQUEST_UPDATE_COUNTRIES,
  SUCCESS_UPDATE_COUNTRIES,
  ERROR_UPDATE_COUNTRIES,
  RESET_UPDATE_COUNTRIES,
  CREATE_COUNTRY,
  ERROR_CREATE_COUNTRY,
  REQUEST_CREATE_COUNTRY,
  RESET_CREATE_COUNTRY,
  SUCCESS_CREATE_COUNTRY,
  REQUEST_CREATE_COUNTRIES,
  SUCCESS_CREATE_COUNTRIES,
  ERROR_CREATE_COUNTRIES,
  RESET_CREATE_COUNTRIES,
  DELETE_COUNTRY,
  DELETE_CREATE_COUNTRY,
  DELETE_UPDATE_COUNTRY,
  REQUEST_DELETE_COUNTRY,
  SUCCESS_DELETE_COUNTRY,
  ERROR_DELETE_COUNTRY,
  RESET_DELETE_COUNTRY,
  REQUEST_PRINT_COUNTRY,
  SUCCESS_PRINT_COUNTRY,
  ERROR_PRINT_COUNTRY,
  RESET_PRINT_COUNTRY,
  RECEIVE_FILE_COUNTRY,
  REQUEST_PRINT_COUNTRIES,
  SUCCESS_PRINT_COUNTRIES,
  ERROR_PRINT_COUNTRIES,
  RESET_PRINT_COUNTRIES,
  RECEIVE_FILE_COUNTRIES,
  PRINT_COUNTRY,
  DELETE_PRINT_COUNTRY
} from '../actions/CountryActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    countries: {},
    files: {}
  };
}

function countriesById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_COUNTRIES:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_COUNTRIES:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_COUNTRIES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_COUNTRIES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        countries: {}
      });
    case RECEIVE_COUNTRIES:
      let dato = action.countries.entities.countries;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        countries: merge({}, state.countries, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_COUNTRY:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_COUNTRY:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_COUNTRY:
      let datoCountry = action.country.entities.countries;
      return Object.assign({}, state, {
        countries: merge({}, state.countries, datoCountry),
        isFetching: false
      });
    case RECEIVE_FILE_COUNTRY:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_COUNTRY:
      let datoCountryEliminado = action.country.entities.countries;
      return Object.assign({}, state, {
        countries: mergeWith(
          clone(datoCountryEliminado),
          state.countries,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_COUNTRY:
      let datoCountryCreado = action.country.entities.countries;
      return Object.assign({}, state, {
        countries: mergeWith(
          clone(datoCountryCreado),
          state.countries,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_COUNTRIES:
      let datosCountryCreado = action.countries.entities.countries;
      return Object.assign({}, state, {
        countries: mergeWith(
          clone(datosCountryCreado),
          state.countries,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_COUNTRY:
      let datoCountryActualizado = action.country.entities.countries;
      return Object.assign({}, state, {
        countries: mergeWith(
          clone(datoCountryActualizado),
          state.countries,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_COUNTRIES:
      let datosCountryActualizado = action.countries.entities.countries;
      return Object.assign({}, state, {
        countries: mergeWith(
          clone(datosCountryActualizado),
          state.countries,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        countries: {}
      });
    default:
      return state;
  }
}

function allCountries(state = [], action) {
  switch (action.type) {
    case RECEIVE_COUNTRIES:
      return action.countries.result && action.countries.result.countries
        ? union(action.countries.result.countries, state)
        : action.countries.result
        ? action.countries.result
        : state;
    case RECEIVE_COUNTRY:
      return action.country.result
        ? union([action.country.result], state)
        : state;

    case SUCCESS_CREATE_COUNTRY:
      let datoCountrySCreate = action.country.entities.countries;
      let idNuevoSCreate = null;
      if (Object.values(datoCountrySCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoCountrySCreate)[0] &&
          Object.values(datoCountrySCreate)[0].id
            ? Object.values(datoCountrySCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_COUNTRIES:
      let countriesCreate =
        action.countries.entities && action.countries.entities.countries
          ? action.countries.entities.countries
          : null;
      return countriesCreate
        ? union(
            state,
            Object.values(countriesCreate).map(countries => {
              return countries.id;
            })
          )
        : state;
    case RESET_COUNTRIES:
      return [];

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalCountries(state = null, action) {
  switch (action.type) {
    case RECEIVE_COUNTRIES:
      return action.countries && action.countries.result.total
        ? action.countries.result.total
        : 0;
    case RESET_COUNTRIES:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_COUNTRY:
      let dato = action.country.entities.countries;
      let country =
        dato && Object.keys(dato).length > 0 ? dato[action.country.result] : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: country ? country : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_COUNTRY:
      let idsUpdate = [];
      Object.values(action.country).map(countryUpdate => {
        if (countryUpdate && countryUpdate.id) idsUpdate.push(countryUpdate.id);
      });
      return merge({}, state, {
        activo: action.country,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_COUNTRY:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_COUNTRY:
      let datoCountryActualizado = action.country.entities.countries;
      let countryNuevo =
        datoCountryActualizado && Object.keys(datoCountryActualizado).length > 0
          ? datoCountryActualizado[action.country.result]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: countryNuevo
      });
    case ERROR_UPDATE_COUNTRY:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_COUNTRIES:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_COUNTRIES:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_COUNTRIES:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_COUNTRY:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    case DELETE_COUNTRY:
      let datoCountryDelete = action.country;
      let idsDelete = [];
      Object.values(action.country).map(countryDelete => {
        if (countryDelete && countryDelete.id) idsDelete.push(countryDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(clone(state.activo), Object.keys(datoCountryDelete)),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_COUNTRY:
      let datoCountryDeleteUpdate = action.country;
      let idsDeleteUpdate = [];
      Object.values(action.country).map(countryDelete => {
        if (countryDelete && countryDelete.id)
          idsDeleteUpdate.push(countryDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoCountryDeleteUpdate)
          ),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_COUNTRY:
      let datoCountryDeleted = {};
      if (Object.values(action.country.entities.countries).length > 0)
        datoCountryDeleted = Object.values(
          action.country.entities.countries
        )[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoCountryDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_COUNTRY:
      let idsCreate = [];
      Object.values(action.country).map(countryCreate => {
        if (countryCreate && countryCreate.id) idsCreate.push(countryCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.country,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_COUNTRY:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_COUNTRY:
      let datoCountryNuevo = action.country.entities.countries;
      let countryNuevo =
        datoCountryNuevo && Object.keys(datoCountryNuevo).length > 0
          ? datoCountryNuevo[action.country.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: countryNuevo,
        nuevos: []
      });
    case ERROR_CREATE_COUNTRY:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_COUNTRIES:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_COUNTRIES:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_COUNTRIES:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_COUNTRY:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    case DELETE_COUNTRY:
      let datoCountryDelete = action.country;
      let idsDelete = [];
      Object.values(action.country).map(countryDelete => {
        if (countryDelete && countryDelete.id) idsDelete.push(countryDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoCountryDelete)),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_COUNTRY:
      let datoCountryDeleteCreate = action.country;
      let idsDeleteCreate = [];
      Object.values(action.country).map(countryDelete => {
        if (countryDelete && countryDelete.id)
          idsDeleteCreate.push(countryDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoCountryDeleteCreate)),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_COUNTRY:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.country,
        error: null
      });
    case REQUEST_DELETE_COUNTRY:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_COUNTRY:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_COUNTRY:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_COUNTRY:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_COUNTRY:
      let idsCreate = [];
      Object.values(action.country).map(countryCreate => {
        if (countryCreate && countryCreate.id) idsCreate.push(countryCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.country,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_COUNTRY:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_COUNTRY:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_COUNTRY:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_COUNTRIES:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_COUNTRIES:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_COUNTRIES:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_COUNTRY:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_COUNTRY:
      let datoCountryDeleteCreate = action.country;
      let idsDeleteCreate = [];
      Object.values(action.country).map(countryDelete => {
        if (countryDelete && countryDelete.id)
          idsDeleteCreate.push(countryDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(clone(state.print), Object.keys(datoCountryDeleteCreate)),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const countries = combineReducers({
  byId: countriesById,
  allIds: allCountries,
  update: update,
  create: create,
  totalCountries: totalCountries,
  delete: deleter,
  print: print
});

export default countries;
