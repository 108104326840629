import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useValidador } from '../../../utils/validador';
import TicketPropertySelector from '../../TicketProperty/TicketPropertySelector';

export default function WhatsAppActivityModal(props) {
  const {
    ticketActivity,
    handleClose,
    handleChangeTicketActivity,
    cellphone,
    open,
    ticket_property,
    disabled
  } = props;
  //Hooks
  let reglas = {};
  reglas = {
    text: ['required']
  };
  const [validador, validar] = useValidador(reglas);

  function handleAccept() {
    if (validador.isValid()) {
      props.handleAccept();
      if (cellphone && ticketActivity.text)
        window.open(
          `https://api.whatsapp.com/send?phone=${cellphone.replaceAll(
            '+',
            ''
          )}&text=${ticketActivity.text}`
        );
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Enviar WhatsApp</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Iniciaremos una conversación con WhatsApp
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="text"
            label="Texto del mensaje"
            type="text"
            multiline
            rows={3}
            fullWidth
            value={
              ticketActivity && ticketActivity.text ? ticketActivity.text : ''
            }
            disabled={disabled}
            onChange={handleChangeTicketActivity}
            onBlur={validar}
            error={!validador.isValidById('text')}
            helperText={validador.getHelperTextById('text')}
          />
          <TicketPropertySelector
            model={ticketActivity}
            handleChangeModel={handleChangeTicketActivity}
            disabled={disabled}
            id={'property_id'}
            margin="dense"
            fullwidth={true}
            idsTicketProperties={ticket_property}
            description={'Propiedad'}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={disabled}>
            Cancelar
          </Button>
          <Button onClick={handleAccept} color="primary" disabled={disabled}>
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
