import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
//Components
import {
  Chip,
  InputAdornment,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
//Icons
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import Box from '@material-ui/core/Box';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
//Img

const styles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper
  },
  chip: {
    margin: theme.spacing(0.5)
  },
  section1: {
    margin: theme.spacing(3, 0)
  },
  section2: {
    margin: theme.spacing(2)
  },
  section3: {
    margin: theme.spacing(2)
  }
}));

export default function PropertyOperation(props) {
  const classes = styles();
  const {
    operationType,
    propertyOperations,
    handleChangePropertyOperation,
    currencies,
    disabled,
    changePropertyOperation,
    periods,
    handleDeletePropertyOperation
  } = props;
  //Hooks
  //State
  let propertyOperation =
    propertyOperations && propertyOperations.length >= 1
      ? propertyOperations[0]
      : null;
  const currency =
    propertyOperation && propertyOperation.codCurrency
      ? currencies.byId.currencies[propertyOperation.codCurrency]
      : null;

  const handleChangeChip = (idCurrency, propertyOperation) => {
    let cambio = {};
    let id =
      propertyOperation && propertyOperation.id
        ? propertyOperation.id
        : Date.now() + Math.random() + '-';
    cambio.codCurrency = idCurrency;
    changePropertyOperation(cambio, id, operationType.id);
  };

  const handleAddOperation = () => {
    let cambio = {};
    let id = Date.now() + Math.random() + '-';
    cambio.idOperationType = operationType.id;
    changePropertyOperation(cambio, id, operationType.id);
  };

  return (
    propertyOperation && (
      <div className={classes.root}>
        <div className={classes.section1}>
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography gutterBottom variant="h5">
                {operationType.nameType}
              </Typography>
            </Grid>
            <Grid item>
              {operationType && operationType.id !== 3 && (
                <Typography gutterBottom variant="h6">
                  {(currency ? currency.simbol + ' ' : '') +
                    (propertyOperation && propertyOperation.price
                      ? propertyOperation.price
                      : '')}
                </Typography>
              )}
              {operationType && operationType.id === 3 && (
                <IconButton
                  aria-label="delete"
                  disabled={disabled}
                  color="primary"
                  onClick={handleAddOperation}
                >
                  <AddCircleOutlineRoundedIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
          <Typography color="textSecondary" variant="body2">
            {`Configure los valores para ${operationType.nameType}`}
          </Typography>
        </div>
        {operationType && operationType.id !== 3 && (
          <div className={classes.section2}>
            <Typography gutterBottom variant="body1">
              Moneda
            </Typography>
            <div>
              {currencies.allIds.map(idCurrency => {
                let currency = currencies.byId.currencies[idCurrency];
                return (
                  <Chip
                    key={'curr' + idCurrency}
                    className={classes.chip}
                    // color={propertyOperation && propertyOperation.codCurrency === idCurrency ? "primary" : "default"}
                    color={
                      propertyOperations &&
                      propertyOperations.some(propertyOperation => {
                        return (
                          (propertyOperation.status === undefined ||
                            propertyOperation.status === 1) &&
                          idCurrency === propertyOperation.codCurrency
                        );
                      })
                        ? 'primary'
                        : 'default'
                    }
                    label={currency.name}
                    onClick={() =>
                      handleChangeChip(idCurrency, propertyOperation)
                    }
                  />
                );
              })}
            </div>
          </div>
        )}
        {operationType && operationType.id !== 3 && (
          <div className={classes.section3}>
            <Typography gutterBottom variant="body1">
              Precio
            </Typography>
            {propertyOperations &&
              propertyOperations.map(propertyOperation => {
                return (
                  <TextField
                    key={'price' + propertyOperation.id}
                    id="price"
                    // placeholder="Precio"
                    variant="outlined"
                    // label={"Precio"}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      // disabled: disabled
                      type: 'number',
                      min: 1,
                      startAdornment: (
                        <InputAdornment position="start">
                          {currency ? currency.simbol : ''}
                        </InputAdornment>
                      )
                    }}
                    margin={'dense'}
                    disabled={disabled}
                    fullWidth
                    value={propertyOperation ? propertyOperation.price : ''}
                    onChange={e =>
                      handleChangePropertyOperation(
                        e,
                        propertyOperation ? propertyOperation.id : null,
                        operationType ? operationType.id : null
                      )
                    }
                    // onKeyDown={(e) => handleKeyDown(e)}
                  />
                );
              })}
          </div>
        )}
        {operationType &&
          operationType.id === 3 &&
          propertyOperations &&
          propertyOperations.map(_propertyoperation => {
            return (
              <React.Fragment key={'period' + _propertyoperation.id}>
                <div className={classes.section2}>
                  <Typography gutterBottom variant="body1">
                    Periodo
                  </Typography>
                  <Box display={'flex'}>
                    <TextField
                      id={'idPeriod'}
                      select
                      fullWidth
                      // label={"Período"}
                      value={
                        _propertyoperation && _propertyoperation.idPeriod
                          ? _propertyoperation.idPeriod
                          : ''
                      }
                      onChange={e =>
                        handleChangePropertyOperation(
                          e,
                          _propertyoperation ? _propertyoperation.id : null,
                          operationType ? operationType.id : null
                        )
                      }
                      InputProps={{
                        id: 'idPeriod',
                        name: 'idPeriod'
                      }}
                      disabled={disabled}
                      variant="outlined"
                      margin={'dense'}
                      // onBlur={validador.validar}
                      // error={!validador.isValidById("idPropertyType")}
                      // helperText={validador.getHelperTextById("idTypeFile")}
                    >
                      {periods.allIds.map(idPeriod => {
                        let period = periods.byId.periods[idPeriod];
                        return (
                          <MenuItem key={idPeriod} value={idPeriod}>
                            {period.desPeriod}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                    <IconButton
                      aria-label="delete"
                      disabled={disabled}
                      onClick={() =>
                        handleDeletePropertyOperation(_propertyoperation)
                      }
                    >
                      <DeleteOutlineRoundedIcon />
                    </IconButton>
                  </Box>
                </div>
                <div className={classes.section2}>
                  <Typography gutterBottom variant="body1">
                    Moneda
                  </Typography>
                  <div>
                    {currencies.allIds.map(idCurrency => {
                      let currency = currencies.byId.currencies[idCurrency];
                      return (
                        <Chip
                          key={'currencyperiod' + idCurrency}
                          className={classes.chip}
                          color={
                            _propertyoperation &&
                            _propertyoperation.codCurrency === idCurrency
                              ? 'primary'
                              : 'default'
                          }
                          label={currency.name}
                          onClick={() =>
                            handleChangeChip(idCurrency, _propertyoperation)
                          }
                        />
                      );
                    })}
                  </div>
                </div>
                <div className={classes.section2}>
                  <Typography gutterBottom variant="body1">
                    Precio
                  </Typography>
                  <TextField
                    id="price"
                    // placeholder="Precio"
                    variant="outlined"
                    // label={"Precio"}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      // disabled: disabled
                      type: 'number',
                      min: 1,
                      startAdornment: (
                        <InputAdornment position="start">
                          {currency ? currency.simbol : ''}
                        </InputAdornment>
                      )
                    }}
                    margin={'dense'}
                    disabled={disabled}
                    fullWidth
                    value={_propertyoperation ? _propertyoperation.price : ''}
                    onChange={e =>
                      handleChangePropertyOperation(
                        e,
                        _propertyoperation ? _propertyoperation.id : null,
                        operationType ? operationType.id : null
                      )
                    }
                    // onKeyDown={(e) => handleKeyDown(e)}
                  />
                </div>
                <Divider variant={'middle'} />
              </React.Fragment>
            );
          })}
        <Divider />
      </div>
    )
  );
}

PropertyOperation.propTypes = {};
