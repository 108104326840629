import React, { useEffect, useRef, useState } from 'react';
import {
  Breadcrumbs,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useValidador } from '../../utils/validador';
import {
  fetchTenant,
  saveCreateTenant,
  saveUpdateTenant
} from '../../actions/TenantActions';
import { useDispatch, useSelector } from 'react-redux';
import BtnCancel from '../../library/Buttons/BtnCancel';
import BtnSave from '../../library/Buttons/BtnSave';
import ContainerBtn from '../../library/Buttons/ContainerBtn';
import { useHistory } from 'react-router';
import tenantUtil from '../../utils/tenantUtil';
import CustomPaper from '../../library/CustomPaper/CustomPaper';
import PaperBody from '../../library/CustomPaper/PaperBody';
import HomeIcon from '../../assets/img/icon/home.svg';
import PadlockIcon from '../../assets/img/icon/padlock.svg';
import KeysIcon from '../../assets/img/icon/keys.svg';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { fetchExternalSystems } from '../../actions/ExternalSystemActions';
import IconButton from '@material-ui/core/IconButton';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import LanguageIcon from '@material-ui/icons/Language';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  createDomain,
  deleteDomain,
  updateDomain
} from '../../actions/DomainActions';
import clone from 'lodash/clone';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CheckIcon from '@material-ui/icons/Check';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: '15px',
    [theme.breakpoints.down('sm')]: {
      padding: '5px 5px'
    }
  },
  table: {
    marginTop: '20px',
    '& .MuiTableCell-head': {
      paddingTop: '10px !important',
      paddingBottom: '10px !important'
    },
    '& .MuiTableRow-root:hover': {
      backgroundColor: 'transparent'
    }
  },
  pendent: {
    backgroundColor: theme.palette.warning.main
  },
  verified: {
    backgroundColor: theme.palette.success.main
  }
}));

const externalSystemsIcons = {
  HomeIcon: HomeIcon,
  PadlockIcon: PadlockIcon,
  KeysIcon: KeysIcon
};

function WebsiteConfiguration() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [domain, setDomain] = React.useState({});
  const [id, setId] = useState(null);
  const link = useRef();
  const mounted = useRef();

  //Store
  const tenants = useSelector(state => state.tenants);
  const tenant = tenantUtil.getTenant()
    ? tenants.update.activo
    : tenants.create.nuevo;
  const domains = useSelector(state => state.domains);
  const mainDomain = domains.byId.domains[tenant?.domains?.[0]];

  const guardandoLoading = tenantUtil.getTenant()
    ? tenants.update.isUpdating
    : tenants.create.isCreating;
  const error = tenantUtil.getTenant()
    ? tenants.update.error
    : tenants.create.error;
  const disabled =
    tenants.update.isUpdating ||
    tenants.create.isCreating ||
    tenants.byId.isFetching;

  //Hooks
  let reglas = {
    domain: ['required']
  };
  const [validador, validar] = useValidador(reglas);

  useEffect(() => {
    dispatch(fetchTenant(tenantUtil.getTenant()));
    dispatch(fetchExternalSystems({ status: 1 }));
  }, []);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (!tenants.update.isUpdating && !tenants.update.error) {
        handleClose();
        setId(null);
        setDomain({});
      }
    }
  }, [tenants.update.isUpdating]);

  //Domain
  const handleChangeDomain = e => {
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    cambio.tenant_id = tenant.id;
    cambio.id = id;
    setDomain(prev => {
      return { ...prev, ...cambio };
    });
    validar(e);
  };

  const handleChangeSubDomain = e => {
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] =
      e.target.value + '.glassprop.com';
    cambio.tenant_id = tenant.id;
    cambio.id = id;
    setDomain(prev => {
      return { ...prev, ...cambio };
    });
    validar(e);
  };

  const changeDomain = cambio => {
    let id = cambio.id;
    let _cambio = {};
    _cambio[id] = cambio;
    if (id.indexOf('-') === -1) dispatch(updateDomain(_cambio));
    else dispatch(createDomain(_cambio));
  };

  const onSubmit = e => {
    if (e) e.preventDefault();
    if (tenantUtil.getTenant()) dispatch(saveUpdateTenant([]));
    else dispatch(saveCreateTenant([]));
  };

  // Modal
  const handleClickOpen = (type, idDomain) => {
    if (!idDomain) {
      setId(Date.now() + Math.random() + '-');
    } else {
      setId(idDomain);
      if (
        domains.update.activos.indexOf(idDomain) === -1 &&
        domains.create.nuevos.indexOf(idDomain) === -1
      ) {
        let domainUpdate = clone(domains.byId.domains[idDomain]);
        let cambio = {};
        cambio[idDomain] = domainUpdate;
        dispatch(updateDomain(cambio));
      }
    }
    setOpen(type);
  };

  const handleClose = () => {
    if (id?.toString().indexOf('-') === -1) {
      let domain = clone(domains.byId.domains[id]);
      changeDomain(domain);
    }
    setOpen(false);
    setId(null);
    setDomain({});
  };

  const handleAccept = () => {
    if (validador.isValid()) {
      changeDomain(domain);
      onSubmit();
    }
  };

  const handleDeleteDomain = idDomain => {
    let domain = idDomain ? domains.byId.domains[idDomain] : null;
    if (domains.update.activos.indexOf(idDomain) !== -1)
      domain = domains.update.activo[idDomain];
    if (domains.create.nuevos.indexOf(idDomain) !== -1)
      domain = domains.create.nuevo[idDomain];
    let cambio = {};
    cambio[idDomain] = domain;
    dispatch(deleteDomain(cambio));
    onSubmit();
  };

  var isSubdomain = function (url) {
    // IF THERE, REMOVE WHITE SPACE FROM BOTH ENDS
    url = url.replace(new RegExp(/^\s+/), ''); // START
    url = url.replace(new RegExp(/\s+$/), ''); // END

    // IF FOUND, CONVERT BACK SLASHES TO FORWARD SLASHES
    url = url.replace(new RegExp(/\\/g), '/');

    // IF THERE, REMOVES 'http://', 'https://' or 'ftp://' FROM THE START
    url = url.replace(new RegExp(/^http\:\/\/|^https\:\/\/|^ftp\:\/\//i), '');

    // IF THERE, REMOVES 'www.' FROM THE START OF THE STRING
    url = url.replace(new RegExp(/^www\./i), '');

    // REMOVE COMPLETE STRING FROM FIRST FORWARD SLASH ON
    url = url.replace(new RegExp(/\/(.*)/), '');

    // REMOVES '.??.??' OR '.???.??' FROM END - e.g. '.CO.UK', '.COM.AU'
    if (url.match(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i))) {
      url = url.replace(new RegExp(/\.[a-z]{2,3}\.[a-z]{2}$/i), '');

      // REMOVES '.??' or '.???' or '.????' FROM END - e.g. '.US', '.COM', '.INFO'
    } else if (url.match(new RegExp(/\.[a-z]{2,4}$/i))) {
      url = url.replace(new RegExp(/\.[a-z]{2,4}$/i), '');
    }

    // CHECK TO SEE IF THERE IS A DOT '.' LEFT IN THE STRING
    var subDomain = url.match(new RegExp(/\./g)) ? true : false;

    return subDomain;
  };

  const copyLink = text => {
    let href = window.location.href;
    link.current.value = href.replace(text);
    link.current.select();
    link.current.setSelectionRange(0, 99999999999); /*For mobile devices*/
    document.execCommand('copy');
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Breadcrumbs aria-label="breadcrumb" separator={'‣'}>
        <Link color="inherit" to="/">
          Home
        </Link>
        <Link color="inherit" to="/configuration">
          Configuración
        </Link>
        <Link color="inherit">Mi sitio web</Link>
      </Breadcrumbs>
      <Typography component={'h1'} variant={'h1'}>
        {'Mi sitio web'}
      </Typography>
      <form onSubmit={onSubmit}>
        <Grid
          container
          spacing={1}
          style={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <Grid item md={6} sm={12} xs={12}>
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item md={12} sm={12} xs={12} spacing={1}>
                <CustomPaper>
                  <PaperBody>
                    <Grid
                      container
                      spacing={1}
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                    >
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography
                          variant={'overline'}
                          component={'span'}
                          display={'block'}
                        >
                          Tu sitio web
                        </Typography>
                        <Typography variant={'caption'} component={'span'}>
                          Podrás acceder a tu sitio web ingresando a la
                          siguiente URL.
                        </Typography>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <TextField
                          id="defaultURL"
                          fullWidth={true}
                          InputLabelProps={{
                            shrink: true
                          }}
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <a
                                  href={mainDomain?.domain}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <IconButton aria-label="toggle password visibility">
                                    <OpenInNewIcon />
                                  </IconButton>
                                </a>
                              </InputAdornment>
                            )
                          }}
                          value={
                            mainDomain && mainDomain.domain
                              ? mainDomain.domain
                              : ''
                          }
                          onBlur={validar}
                          error={!validador.isValidById('external_system_key')}
                          helperText={validador.getHelperTextById(
                            'external_system_key'
                          )}
                          disabled={true}
                        />
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <Box
                          display={'flex'}
                          marginTop={'25px'}
                          marginBottom={'10px'}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                        >
                          <Typography
                            variant={'overline'}
                            component={'span'}
                            display={'block'}
                          >
                            Subdominios personalizados
                          </Typography>
                          <Button
                            color={'primary'}
                            variant={'contained'}
                            onClick={() => handleClickOpen('subdomain')}
                          >
                            Agregar
                          </Button>
                        </Box>
                        <Typography variant={'caption'} component={'span'}>
                          Crea un subdominio personalizado para tu inmobiliaria
                          por ejemplo: miinmobiliaria.glassprop.com
                        </Typography>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <List className={classes.root}>
                          {tenant?.domains?.map((idDomain, i) => {
                            let domain = domains.byId.domains[idDomain];
                            if (domains.update.activos.indexOf(idDomain) !== -1)
                              domain = domains.update.activo[idDomain];
                            if (domains.create.nuevos.indexOf(idDomain) !== -1)
                              domain = domains.create.nuevo[idDomain];
                            if (
                              i !== 0 &&
                              domain &&
                              domain?.domain?.indexOf('glassprop.com') !== -1
                            )
                              return (
                                <ListItem>
                                  <ListItemAvatar>
                                    <Avatar>
                                      <LanguageIcon />
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText primary={domain.domain} />
                                  <ListItemSecondaryAction>
                                    <IconButton
                                      edge="end"
                                      aria-label="delete"
                                      onClick={() =>
                                        handleDeleteDomain(idDomain)
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              );
                          })}
                        </List>
                      </Grid>
                    </Grid>
                  </PaperBody>
                </CustomPaper>
              </Grid>
            </Grid>
          </Grid>
          {tenant && (
            <Grid item md={6} sm={12} xs={12} spacing={1}>
              <CustomPaper>
                <PaperBody>
                  <Grid
                    container
                    spacing={1}
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  >
                    <Grid item md={12} sm={12} xs={12}>
                      <Box
                        display={'flex'}
                        marginBottom={'10px'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                      >
                        <Typography
                          variant={'overline'}
                          component={'span'}
                          display={'block'}
                        >
                          Dominios personalizados
                        </Typography>
                        <Button
                          color={'primary'}
                          variant={'contained'}
                          onClick={() => handleClickOpen('domain')}
                        >
                          Agregar
                        </Button>
                      </Box>
                      <Typography variant={'caption'} component={'span'}>
                        Agrega un dominio personalizado para tu inmobiliaria por
                        ejemplo: miinmobiliaria.com
                      </Typography>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Box display="flex" flexDirection="column" gridGap="12px">
                        {tenant?.domains?.map((idDomain, i) => {
                          let domain = domains.byId.domains[idDomain];
                          if (domains.update.activos.indexOf(idDomain) !== -1)
                            domain = domains.update.activo[idDomain];
                          if (domains.create.nuevos.indexOf(idDomain) !== -1)
                            domain = domains.create.nuevo[idDomain];
                          if (
                            i !== 0 &&
                            domain?.domain.indexOf('glassprop.com') === -1
                          )
                            return (
                              <Card className={classes.root}>
                                <CardHeader
                                  avatar={
                                    <Avatar
                                      aria-label="recipe"
                                      className={classes.avatar}
                                    >
                                      <LanguageIcon />
                                    </Avatar>
                                  }
                                  action={
                                    <IconButton
                                      aria-label="add to favorites"
                                      style={{ marginTop: '8px' }}
                                      onClick={() =>
                                        handleDeleteDomain(idDomain)
                                      }
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  }
                                  title={
                                    <Box
                                      display={'flex'}
                                      justifyContent={'space-between'}
                                      alignItems={'center'}
                                    >
                                      <Box
                                        display={'flex'}
                                        alignItems={'center'}
                                      >
                                        <Typography
                                          variant={'h2'}
                                          component={'h2'}
                                        >
                                          {domain.domain}
                                        </Typography>
                                        <a
                                          href={'http://' + domain?.domain}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <IconButton aria-label="settings">
                                            <OpenInNewIcon />
                                          </IconButton>
                                        </a>
                                      </Box>
                                      {domain.status === 1 && (
                                        <Chip
                                          size="small"
                                          icon={<CheckIcon />}
                                          label="Verificado"
                                          color={'primary'}
                                          className={classes.verified}
                                        />
                                      )}
                                      {domain.status === 0 && (
                                        <Chip
                                          size="small"
                                          icon={<HourglassEmptyIcon />}
                                          label="Pendiente"
                                          color={'primary'}
                                          className={classes.pendent}
                                        />
                                      )}
                                    </Box>
                                  }
                                />
                                <CardContent>
                                  <Typography
                                    variant="body1"
                                    color="textSecondary"
                                    component="p"
                                  >
                                    En tu proveedor DNS agregar el siguiente
                                    registro a tu zona DNS
                                  </Typography>
                                  {!isSubdomain(domain.domain) && (
                                    <TableContainer
                                      component={Box}
                                      display={'flex'}
                                      justifyContent={'center'}
                                    >
                                      <Table
                                        className={classes.table}
                                        size="small"
                                        aria-label="simple table"
                                      >
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Value</TableCell>
                                            <TableCell width={'20px'} />
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          <TableRow>
                                            <TableCell>A</TableCell>
                                            <TableCell>@</TableCell>
                                            <TableCell>76.76.21.21</TableCell>
                                            <TableCell>
                                              <IconButton
                                                onClick={() =>
                                                  copyLink('76.76.21.21')
                                                }
                                                aria-label="settings"
                                              >
                                                <FileCopyIcon />
                                              </IconButton>
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  )}
                                  {isSubdomain(domain.domain) && (
                                    <TableContainer
                                      component={Box}
                                      display={'flex'}
                                      justifyContent={'center'}
                                    >
                                      <Table
                                        className={classes.table}
                                        size="small"
                                        aria-label="simple table"
                                      >
                                        <TableHead>
                                          <TableRow>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Value</TableCell>
                                            <TableCell width={'20px'} />
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          <TableRow>
                                            <TableCell>CNAME</TableCell>
                                            <TableCell>
                                              {domain.domain}
                                            </TableCell>
                                            <TableCell>76.76.21.21</TableCell>
                                            <TableCell>
                                              <IconButton
                                                onClick={() =>
                                                  copyLink('76.76.21.21')
                                                }
                                                aria-label="settings"
                                              >
                                                <FileCopyIcon />
                                              </IconButton>
                                            </TableCell>
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  )}
                                </CardContent>
                              </Card>
                            );
                        })}
                      </Box>
                    </Grid>
                  </Grid>
                </PaperBody>
              </CustomPaper>
            </Grid>
          )}
        </Grid>
        <ContainerBtn>
          <BtnCancel onClick={() => history.push('/configuration')} />
          <BtnSave
            loading={guardandoLoading}
            disabled={disabled}
            error={error}
          />
        </ContainerBtn>
      </form>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {open === 'domain'
            ? 'Agregar dominio personalizado'
            : 'Agregar subdominio personalizado'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {(open === 'domain'
              ? ' Agrega un dominio personalizado para tu inmobiliaria por ejemplo: miinmobiliaria.com. '
              : 'Crea un subdominio personalizado para tu inmobiliaria por ejemplo: miinmobiliaria.glassprop.com. ') +
              'No incluyas los prefijos http, https ni www.'}
          </DialogContentText>
          {open === 'domain' && (
            <TextField
              id="domain"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              variant="outlined"
              InputProps={{}}
              value={domain && domain.domain ? domain.domain : ''}
              onChange={handleChangeDomain}
              onBlur={validar}
              error={!validador.isValidById('external_system_key')}
              helperText={validador.getHelperTextById('external_system_key')}
            />
          )}
          {open === 'subdomain' && (
            <TextField
              id="domain"
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">.glassprop.com</InputAdornment>
                )
              }}
              value={
                domain && domain.domain
                  ? domain.domain.replace('.glassprop.com', '')
                  : ''
              }
              onChange={handleChangeSubDomain}
              onBlur={validar}
              error={!validador.isValidById('external_system_key')}
              helperText={validador.getHelperTextById('external_system_key')}
              disabled={disabled}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={disabled}>
            Cancelar
          </Button>
          <Button
            onClick={handleAccept}
            color="primary"
            variant={'contained'}
            disabled={disabled}
          >
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <input
        ref={link}
        style={{ position: 'absolute', opacity: '0', top: 0, left: 0 }}
      />
    </Container>
  );
}

export default WebsiteConfiguration;
