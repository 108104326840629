import React, { useEffect } from 'react';
// import style from '../../styles/navbar/DesktopFilterNavBar.module.css';
import { alpha, useMediaQuery, useTheme } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import BedOutlinedIcon from '@material-ui/icons/KingBed';
import PoolOutlinedIcon from '@material-ui/icons/PoolOutlined';
import FilterAltOutlinedIcon from '@material-ui/icons/Filter1Outlined';
import HomeIcon from '@material-ui/icons/Home';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import DeleteOutlineIcon from '@material-ui/icons/DeleteForeverOutlined';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import OperationTypesFilter from './Filters/OperationTypesFilter';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import PropertyTypesFilter from './Filters/PropertyTypesFilter';
import PriceFilter from './Filters/PriceFilter';
import BedsFilter from './Filters/BedsFilter';
import AmenitiesFilter from './Filters/AmenitiesFilter';
import LocationFilter from './Filters/LocationFilter';

const StyledMenu = withStyles(theme => {
  return {
    containerBtn: {
      backgroundColor: 'rgb(245,245,245)',
      paddingTop: '20px',
      paddingBottom: '18px',
      // borderTopLeftRadius: '16px',
      // borderTopRightRadius: '16px',
      paddingLeft: '18px',
      paddingRight: '18px'
    },
    paper: {
      '& .MuiFormControl-root': {
        width: '100%',
        '& button': {
          width: '50%'
        },
        '& .formLabel': {
          fontWeight: 900,
          fontSize: '1rem',
          marginBottom: '20px',
          paddingTop: '30px'
        },
        '&.operationType': {
          '& .MuiButtonBase-root:not(:last-of-type)': {
            borderTopLeftRadius: '12px',
            borderBottomLeftRadius: '12px'
          },
          '& .MuiButtonBase-root:last-of-type': {
            borderTopRightRadius: '12px',
            borderBottomRightRadius: '12px'
          }
        }
      },
      borderRadius: '20px',
      marginTop: theme.spacing(1),
      minWidth: 340,
      color:
        theme.palette.mode === 'light'
          ? 'rgb(55, 65, 81)'
          : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: 0
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5)
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          )
        }
      }
    }
  };
})(props => {
  const { classes } = props;
  return (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      {...props}
    >
      <Box display={'flex'} justifyContent={'flex-end'} padding={'5px'}>
        <IconButton aria-label="delete" onClick={props.onClose} size="small">
          <Close fontSize="small" />
        </IconButton>
      </Box>
      <Box padding={'0 30px 20px 30px'}>{props.children}</Box>
      <Box className={classes.containerBtn}>
        <StyleFooterMenu
          onClose={props.onClose}
          onReset={props.onReset}
          onApply={props.onApply}
        />
      </Box>
    </Menu>
  );
});

const StyleFooterMenu = withStyles({})(props => (
  <Box
    display={'flex'}
    justifyContent={'space-between'}
    sx={{
      '& button': {
        textTransform: 'none',
        borderRadius: '10px'
      }
    }}
  >
    <Button
      variant={'text'}
      color={'primary'}
      size={'small'}
      startIcon={<DeleteOutlineIcon />}
      onClick={props.onReset}
    >
      Borrar
    </Button>
    <Button variant={'outlined'} size={'large'} onClick={props.onApply}>
      Aplicar
    </Button>
  </Box>
));

const menus = [
  {
    name: 'Ubicación',
    query: ['city', 'state', 'country', 'neighborhood'],
    icon: <LocationOnIcon />
  },
  {
    name: 'Operación',
    query: ['codOperationType'],
    icon: <LocalOfferIcon />
  },
  { name: 'Tipo', query: ['idsPropertyType'], icon: <HomeIcon /> },
  {
    name: 'Precio',
    query: ['codCurrency', 'priceFrom', 'priceTo'],
    icon: <MonetizationOnIcon />
  },
  { name: 'Dormitorios', query: ['beds'], icon: <BedOutlinedIcon /> },
  { name: 'Amenities', query: ['amenities'], icon: <PoolOutlinedIcon /> }
];
export default function FilterBar(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width:990px)');

  let { setQueryRouter, queryRouter } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuOpen, setMenuOpen] = React.useState(null);
  const [query, setQuery] = React.useState({});

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (queryRouter && Object.keys(queryRouter).length > 0) {
      setQuery(queryRouter);
    }
  }, []);

  const handleClick = (event, openMenu) => {
    setAnchorEl(event.currentTarget);
    setMenuOpen(openMenu);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(null);
  };

  const handleApply = () => {
    let _query = Object.assign({}, query);
    delete _query.page;
    handleClose();
    if (setQueryRouter) setQueryRouter(_query);
  };

  const handleReset = () => {
    handleClose();
    if (!isMobile) {
      let query_ = Object.assign({}, query);
      let menu = null;
      menus.some(_menu => {
        if (_menu.name === menuOpen) {
          menu = _menu;
          return true;
        }
        return false;
      });
      if (menu) {
        menu.query.forEach(_query => {
          delete query_[_query];
        });
      }
      setQuery(query_);
      if (setQueryRouter) setQueryRouter(query_);
    } else {
      setQuery({});
      if (setQueryRouter) setQueryRouter({});
    }
  };

  return (
    <>
      {!isMobile &&
        menus.map(menu => {
          return (
            <Button
              key={menu.name}
              // size={'small'}
              id="demo-customized-button"
              aria-controls={open ? 'demo-customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              variant={
                menu.query.some(value => query[value])
                  ? 'contained'
                  : 'outlined'
              }
              color={
                menu.query.some(value => query[value]) ? 'primary' : 'inherit'
              }
              disableElevation
              onClick={e => handleClick(e, menu.name)}
              endIcon={menu.icon ? menu.icon : null}
              sx={{
                zIndex:
                  open && menuOpen === menu.name
                    ? theme.zIndex.drawer + 3
                    : 'inherit'
              }}
            >
              {menu.name}
            </Button>
          );
        })}
      {!!isMobile && (
        <IconButton
          size={'small'}
          id="demo-customized-button"
          aria-controls={open ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          variant={'outlined'}
          color={'primary'}
          disableElevation
          onClick={handleClick}
        >
          <FilterAltOutlinedIcon />
        </IconButton>
      )}
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onReset={handleReset}
        onApply={handleApply}
        style={{ padding: '10px' }}
      >
        {/*TIPO DE OPERACION*/}
        {(menuOpen === 'Operación' || isMobile) && (
          <OperationTypesFilter setQuery={setQuery} query={query} />
        )}
        {(menuOpen === 'Tipo' || isMobile) && (
          <PropertyTypesFilter setQuery={setQuery} query={query} />
        )}

        {(menuOpen === 'Precio' || isMobile) && (
          <PriceFilter setQuery={setQuery} query={query} />
        )}

        {(menuOpen === 'Ubicación' || isMobile) && (
          <LocationFilter setQuery={setQuery} query={query} />
        )}

        {(menuOpen === 'Dormitorios' || isMobile) && (
          <BedsFilter setQuery={setQuery} query={query} />
        )}

        {(menuOpen === 'Amenities' || isMobile) && (
          <AmenitiesFilter setQuery={setQuery} query={query} />
        )}
      </StyledMenu>
    </>
  );
}
