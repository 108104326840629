import {
  INVALIDATE_PROPERTIES,
  ERROR_PROPERTIES,
  RECEIVE_PROPERTIES,
  REQUEST_PROPERTIES,
  RESET_PROPERTIES,
  ERROR_PROPERTY,
  RECEIVE_PROPERTY,
  REQUEST_PROPERTY,
  UPDATE_PROPERTY,
  REQUEST_UPDATE_PROPERTY,
  SUCCESS_UPDATE_PROPERTY,
  ERROR_UPDATE_PROPERTY,
  RESET_UPDATE_PROPERTY,
  REQUEST_UPDATE_PROPERTIES,
  SUCCESS_UPDATE_PROPERTIES,
  ERROR_UPDATE_PROPERTIES,
  RESET_UPDATE_PROPERTIES,
  CREATE_PROPERTY,
  ERROR_CREATE_PROPERTY,
  REQUEST_CREATE_PROPERTY,
  RESET_CREATE_PROPERTY,
  SUCCESS_CREATE_PROPERTY,
  REQUEST_CREATE_PROPERTIES,
  SUCCESS_CREATE_PROPERTIES,
  ERROR_CREATE_PROPERTIES,
  RESET_CREATE_PROPERTIES,
  DELETE_PROPERTY,
  DELETE_CREATE_PROPERTY,
  DELETE_UPDATE_PROPERTY,
  REQUEST_DELETE_PROPERTY,
  SUCCESS_DELETE_PROPERTY,
  ERROR_DELETE_PROPERTY,
  RESET_DELETE_PROPERTY,
  REQUEST_PRINT_PROPERTY,
  SUCCESS_PRINT_PROPERTY,
  ERROR_PRINT_PROPERTY,
  RESET_PRINT_PROPERTY,
  RECEIVE_FILE_PROPERTY,
  REQUEST_PRINT_PROPERTIES,
  SUCCESS_PRINT_PROPERTIES,
  ERROR_PRINT_PROPERTIES,
  RESET_PRINT_PROPERTIES,
  RECEIVE_FILE_PROPERTIES,
  PRINT_PROPERTY,
  DELETE_PRINT_PROPERTY
} from '../actions/PropertyActions';
import {
  CREATE_PROPERTYOPERATION,
  UPDATE_PROPERTYOPERATION,
  DELETE_PROPERTYOPERATION
} from '../actions/PropertyOperationActions';
import {
  CREATE_PROPERTYATTRIBUTEVALUE,
  UPDATE_PROPERTYATTRIBUTEVALUE,
  DELETE_PROPERTYATTRIBUTEVALUE
} from '../actions/PropertyAttributeValueActions';
import {
  CREATE_ASSET,
  UPDATE_ASSET,
  DELETE_ASSET
} from '../actions/AssetActions';
import {
  CREATE_PROPERTYCLOSEDOPERATION,
  UPDATE_PROPERTYCLOSEDOPERATION,
  DELETE_PROPERTYCLOSEDOPERATION
} from '../actions/PropertyClosedOperationActions';

import {
  RECEIVE_ASSET,
  RECEIVE_ASSETS,
  SUCCESS_DELETE_ASSET,
  SUCCESS_CREATE_ASSET,
  SUCCESS_UPDATE_ASSET,
  SUCCESS_UPDATE_ASSETS
} from '../actions/AssetActions';
import {
  RECEIVE_PROPERTYCLOSEDOPERATION,
  RECEIVE_PROPERTYCLOSEDOPERATIONS,
  SUCCESS_DELETE_PROPERTYCLOSEDOPERATION,
  SUCCESS_CREATE_PROPERTYCLOSEDOPERATION,
  SUCCESS_UPDATE_PROPERTYCLOSEDOPERATION,
  SUCCESS_UPDATE_PROPERTYCLOSEDOPERATIONS
} from '../actions/PropertyClosedOperationActions';
import {
  RECEIVE_TICKET,
  RECEIVE_TICKETS,
  SUCCESS_DELETE_TICKET,
  SUCCESS_CREATE_TICKET,
  SUCCESS_UPDATE_TICKET,
  SUCCESS_UPDATE_TICKETS
} from '../actions/TicketActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    properties: {},
    files: {}
  };
}

function propertiesById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_PROPERTIES:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_PROPERTIES:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_PROPERTIES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_PROPERTIES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        properties: {}
      });
    case RECEIVE_PROPERTIES:
      let dato = action.properties.entities.properties;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        properties: merge({}, state.properties, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_PROPERTY:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_PROPERTY:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_PROPERTY:
      let datoProperty = action.property.entities.properties;
      return Object.assign({}, state, {
        properties: merge({}, state.properties, datoProperty),
        isFetching: false
      });
    case RECEIVE_FILE_PROPERTY:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_PROPERTY:
      let datoPropertyEliminado = action.property.entities.properties;
      return Object.assign({}, state, {
        properties: mergeWith(
          clone(datoPropertyEliminado),
          state.properties,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_PROPERTY:
      let datoPropertyCreado = action.property.entities.properties;
      return Object.assign({}, state, {
        properties: mergeWith(
          clone(datoPropertyCreado),
          state.properties,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_PROPERTIES:
      let datosPropertyCreado = action.properties.entities.properties;
      return Object.assign({}, state, {
        properties: mergeWith(
          clone(datosPropertyCreado),
          state.properties,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTY:
      let datoPropertyActualizado = action.property.entities.properties;
      return Object.assign({}, state, {
        properties: mergeWith(
          clone(datoPropertyActualizado),
          state.properties,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTIES:
      let datosPropertyActualizado = action.properties.entities.properties;
      return Object.assign({}, state, {
        properties: mergeWith(
          clone(datosPropertyActualizado),
          state.properties,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    //ASSET
    case RECEIVE_ASSET:
      let asset =
        action.asset.entities && action.asset.entities.properties
          ? action.asset.entities.properties
          : {};
      return Object.assign({}, state, {
        properties: merge({}, state.properties, asset)
      });
    case RECEIVE_ASSETS:
      let assets =
        action.assets.entities && action.assets.entities.properties
          ? action.assets.entities.properties
          : {};
      return Object.assign({}, state, {
        properties: merge({}, state.properties, assets)
      });
    case SUCCESS_DELETE_ASSET:
      let datoassetEliminado =
        action.asset.entities && action.asset.entities.properties
          ? action.asset.entities.properties
          : {};
      return Object.assign({}, state, {
        properties: mergeWith(
          clone(datoassetEliminado),
          pickBy(state.properties, function (property) {
            return property.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_ASSET:
      let datoassetCreado =
        action.asset.entities && action.asset.entities.properties
          ? action.asset.entities.properties
          : {};
      return Object.assign({}, state, {
        properties: mergeWith(
          clone(datoassetCreado),
          pickBy(state.properties, function (property) {
            return property.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_ASSET:
      let datoassetActualizado =
        action.asset.entities && action.asset.entities.properties
          ? action.asset.entities.properties
          : {};
      return Object.assign({}, state, {
        properties: mergeWith(
          clone(datoassetActualizado),
          pickBy(state.properties, function (property) {
            return property.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_ASSETS:
      let datosassetActualizado =
        action.assets.entities && action.assets.entities.properties
          ? action.assets.entities.properties
          : {};
      return Object.assign({}, state, {
        properties: mergeWith(
          clone(datosassetActualizado),
          state.properties,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    //PROPERTYCLOSEDOPERATION
    case RECEIVE_PROPERTYCLOSEDOPERATION:
      let propertyClosedOperation =
        action.propertyClosedOperation.entities &&
        action.propertyClosedOperation.entities.properties
          ? action.propertyClosedOperation.entities.properties
          : {};
      return Object.assign({}, state, {
        properties: merge({}, state.properties, propertyClosedOperation)
      });
    case RECEIVE_PROPERTYCLOSEDOPERATIONS:
      let propertyClosedOperations =
        action.propertyClosedOperations.entities &&
        action.propertyClosedOperations.entities.properties
          ? action.propertyClosedOperations.entities.properties
          : {};
      return Object.assign({}, state, {
        properties: merge({}, state.properties, propertyClosedOperations)
      });
    case SUCCESS_DELETE_PROPERTYCLOSEDOPERATION:
      let datopropertyClosedOperationEliminado =
        action.propertyClosedOperation.entities &&
        action.propertyClosedOperation.entities.properties
          ? action.propertyClosedOperation.entities.properties
          : {};
      return Object.assign({}, state, {
        properties: mergeWith(
          clone(datopropertyClosedOperationEliminado),
          pickBy(state.properties, function (property) {
            return property.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_PROPERTYCLOSEDOPERATION:
      let datopropertyClosedOperationCreado =
        action.propertyClosedOperation.entities &&
        action.propertyClosedOperation.entities.properties
          ? action.propertyClosedOperation.entities.properties
          : {};
      return Object.assign({}, state, {
        properties: mergeWith(
          clone(datopropertyClosedOperationCreado),
          pickBy(state.properties, function (property) {
            return property.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTYCLOSEDOPERATION:
      let datopropertyClosedOperationActualizado =
        action.propertyClosedOperation.entities &&
        action.propertyClosedOperation.entities.properties
          ? action.propertyClosedOperation.entities.properties
          : {};
      return Object.assign({}, state, {
        properties: mergeWith(
          clone(datopropertyClosedOperationActualizado),
          pickBy(state.properties, function (property) {
            return property.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTYCLOSEDOPERATIONS:
      let datospropertyClosedOperationActualizado =
        action.propertyClosedOperations.entities &&
        action.propertyClosedOperations.entities.properties
          ? action.propertyClosedOperations.entities.properties
          : {};
      return Object.assign({}, state, {
        properties: mergeWith(
          clone(datospropertyClosedOperationActualizado),
          state.properties,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    //TICKET
    case RECEIVE_TICKET:
      let ticket =
        action.ticket.entities && action.ticket.entities.properties
          ? action.ticket.entities.properties
          : {};
      return Object.assign({}, state, {
        properties: merge({}, state.properties, ticket)
      });
    case RECEIVE_TICKETS:
      let tickets =
        action.tickets.entities && action.tickets.entities.properties
          ? action.tickets.entities.properties
          : {};
      return Object.assign({}, state, {
        properties: merge({}, state.properties, tickets)
      });
    case SUCCESS_DELETE_TICKET:
      let datoticketEliminado =
        action.ticket.entities && action.ticket.entities.properties
          ? action.ticket.entities.properties
          : {};
      return Object.assign({}, state, {
        properties: mergeWith(
          clone(datoticketEliminado),
          pickBy(state.properties, function (property) {
            return property.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_TICKET:
      let datoticketCreado =
        action.ticket.entities && action.ticket.entities.properties
          ? action.ticket.entities.properties
          : {};
      return Object.assign({}, state, {
        properties: mergeWith(
          clone(datoticketCreado),
          pickBy(state.properties, function (property) {
            return property.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TICKET:
      let datoticketActualizado =
        action.ticket.entities && action.ticket.entities.properties
          ? action.ticket.entities.properties
          : {};
      return Object.assign({}, state, {
        properties: mergeWith(
          clone(datoticketActualizado),
          pickBy(state.properties, function (property) {
            return property.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TICKETS:
      let datosticketActualizado =
        action.tickets.entities && action.tickets.entities.properties
          ? action.tickets.entities.properties
          : {};
      return Object.assign({}, state, {
        properties: mergeWith(
          clone(datosticketActualizado),
          state.properties,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        properties: {}
      });
    default:
      return state;
  }
}

function allProperties(state = [], action) {
  switch (action.type) {
    case RECEIVE_PROPERTIES:
      return action.properties.result && action.properties.result.properties
        ? union(action.properties.result.properties, state)
        : action.properties.result
        ? action.properties.result
        : state;
    case RECEIVE_PROPERTY:
      return action.property.result
        ? union([action.property.result], state)
        : state;

    case SUCCESS_CREATE_PROPERTY:
      let datoPropertySCreate = action.property.entities.properties;
      let idNuevoSCreate = null;
      if (Object.values(datoPropertySCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoPropertySCreate)[0] &&
          Object.values(datoPropertySCreate)[0].id
            ? Object.values(datoPropertySCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_PROPERTIES:
      let propertiesCreate =
        action.properties.entities && action.properties.entities.properties
          ? action.properties.entities.properties
          : null;
      return propertiesCreate
        ? union(
            state,
            Object.values(propertiesCreate).map(properties => {
              return properties.id;
            })
          )
        : state;
    case RESET_PROPERTIES:
      return [];

    case RECEIVE_ASSET:
      let asset =
        action.asset.entities && action.asset.entities.properties
          ? action.asset.entities.properties
          : null;
      return asset
        ? union(
            state,
            Object.values(asset).map(asset => {
              return asset.id;
            })
          )
        : state;
    case RECEIVE_ASSETS:
      let assets =
        action.assets.entities && action.assets.entities.properties
          ? action.assets.entities.properties
          : null;
      return assets
        ? union(
            state,
            Object.values(assets).map(assets => {
              return assets.id;
            })
          )
        : state;

    case SUCCESS_DELETE_ASSET:
      let assetDelete =
        action.asset.entities && action.asset.entities.properties
          ? action.asset.entities.properties
          : null;
      return assetDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(assetDelete).map(asset => {
              return asset.id;
            })
          )
        : state;
    case SUCCESS_CREATE_ASSET:
      let assetCreate =
        action.asset.entities && action.asset.entities.properties
          ? action.asset.entities.properties
          : null;
      return assetCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(assetCreate).map(asset => {
              return asset.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_ASSET:
      let assetUpdate =
        action.asset.entities && action.asset.entities.properties
          ? action.asset.entities.properties
          : null;
      return assetUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(assetUpdate).map(asset => {
              return asset.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_ASSETS:
      let assetsUpdate =
        action.assets.entities && action.assets.entities.properties
          ? action.assets.entities.properties
          : null;
      return assetsUpdate
        ? union(
            state,
            Object.values(assetsUpdate).map(assets => {
              return assets.id;
            })
          )
        : state;
    case RECEIVE_PROPERTYCLOSEDOPERATION:
      let propertyClosedOperation =
        action.propertyClosedOperation.entities &&
        action.propertyClosedOperation.entities.properties
          ? action.propertyClosedOperation.entities.properties
          : null;
      return propertyClosedOperation
        ? union(
            state,
            Object.values(propertyClosedOperation).map(
              propertyClosedOperation => {
                return propertyClosedOperation.id;
              }
            )
          )
        : state;
    case RECEIVE_PROPERTYCLOSEDOPERATIONS:
      let propertyClosedOperations =
        action.propertyClosedOperations.entities &&
        action.propertyClosedOperations.entities.properties
          ? action.propertyClosedOperations.entities.properties
          : null;
      return propertyClosedOperations
        ? union(
            state,
            Object.values(propertyClosedOperations).map(
              propertyClosedOperations => {
                return propertyClosedOperations.id;
              }
            )
          )
        : state;

    case SUCCESS_DELETE_PROPERTYCLOSEDOPERATION:
      let propertyClosedOperationDelete =
        action.propertyClosedOperation.entities &&
        action.propertyClosedOperation.entities.properties
          ? action.propertyClosedOperation.entities.properties
          : null;
      return propertyClosedOperationDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyClosedOperationDelete).map(
              propertyClosedOperation => {
                return propertyClosedOperation.id;
              }
            )
          )
        : state;
    case SUCCESS_CREATE_PROPERTYCLOSEDOPERATION:
      let propertyClosedOperationCreate =
        action.propertyClosedOperation.entities &&
        action.propertyClosedOperation.entities.properties
          ? action.propertyClosedOperation.entities.properties
          : null;
      return propertyClosedOperationCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyClosedOperationCreate).map(
              propertyClosedOperation => {
                return propertyClosedOperation.id;
              }
            )
          )
        : state;
    case SUCCESS_UPDATE_PROPERTYCLOSEDOPERATION:
      let propertyClosedOperationUpdate =
        action.propertyClosedOperation.entities &&
        action.propertyClosedOperation.entities.properties
          ? action.propertyClosedOperation.entities.properties
          : null;
      return propertyClosedOperationUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyClosedOperationUpdate).map(
              propertyClosedOperation => {
                return propertyClosedOperation.id;
              }
            )
          )
        : state;
    case SUCCESS_UPDATE_PROPERTYCLOSEDOPERATIONS:
      let propertyClosedOperationsUpdate =
        action.propertyClosedOperations.entities &&
        action.propertyClosedOperations.entities.properties
          ? action.propertyClosedOperations.entities.properties
          : null;
      return propertyClosedOperationsUpdate
        ? union(
            state,
            Object.values(propertyClosedOperationsUpdate).map(
              propertyClosedOperations => {
                return propertyClosedOperations.id;
              }
            )
          )
        : state;
    case RECEIVE_TICKET:
      let ticket =
        action.ticket.entities && action.ticket.entities.properties
          ? action.ticket.entities.properties
          : null;
      return ticket
        ? union(
            state,
            Object.values(ticket).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case RECEIVE_TICKETS:
      let tickets =
        action.tickets.entities && action.tickets.entities.properties
          ? action.tickets.entities.properties
          : null;
      return tickets
        ? union(
            state,
            Object.values(tickets).map(tickets => {
              return tickets.id;
            })
          )
        : state;

    case SUCCESS_DELETE_TICKET:
      let ticketDelete =
        action.ticket.entities && action.ticket.entities.properties
          ? action.ticket.entities.properties
          : null;
      return ticketDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(ticketDelete).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case SUCCESS_CREATE_TICKET:
      let ticketCreate =
        action.ticket.entities && action.ticket.entities.properties
          ? action.ticket.entities.properties
          : null;
      return ticketCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(ticketCreate).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_TICKET:
      let ticketUpdate =
        action.ticket.entities && action.ticket.entities.properties
          ? action.ticket.entities.properties
          : null;
      return ticketUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(ticketUpdate).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_TICKETS:
      let ticketsUpdate =
        action.tickets.entities && action.tickets.entities.properties
          ? action.tickets.entities.properties
          : null;
      return ticketsUpdate
        ? union(
            state,
            Object.values(ticketsUpdate).map(tickets => {
              return tickets.id;
            })
          )
        : state;

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalProperties(state = null, action) {
  switch (action.type) {
    case RECEIVE_PROPERTIES:
      return action.properties && action.properties.result.total
        ? action.properties.result.total
        : 0;
    case RESET_PROPERTIES:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_PROPERTY:
      let dato = action.property.entities.properties;
      let property =
        dato && Object.keys(dato).length > 0
          ? dato[action.property.result]
          : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: property ? property : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_PROPERTY:
      let idsUpdate = [];
      Object.values(action.property).map(propertyUpdate => {
        if (propertyUpdate && propertyUpdate.id)
          idsUpdate.push(propertyUpdate.id);
      });
      return merge({}, state, {
        activo: action.property,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_PROPERTY:
      let datoPropertyActualizado = action.property.entities.properties;
      let propertyNuevo =
        datoPropertyActualizado &&
        Object.keys(datoPropertyActualizado).length > 0
          ? datoPropertyActualizado[action.property.result]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: propertyNuevo
      });
    case ERROR_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    case CREATE_PROPERTYOPERATION:
      let propertypropertyOperationCreateActivo = clone(state.activo);
      let propertypropertyOperationCreateActivos = clone(state.activos);
      Object.values(action.propertyOperation).map(propertyOperationCreate => {
        if (
          propertyOperationCreate &&
          propertyOperationCreate.idProperty &&
          propertypropertyOperationCreateActivo[
            propertyOperationCreate.idProperty
          ]
        ) {
          if (propertyOperationCreate.idProperty.toString().indexOf('-') === -1)
            propertypropertyOperationCreateActivo[
              propertyOperationCreate.idProperty
            ].property_operation = union(
              propertypropertyOperationCreateActivo.property_operation,
              [propertyOperationCreate.id]
            );
        } else if (propertyOperationCreate) {
          propertypropertyOperationCreateActivo.property_operation = union(
            propertypropertyOperationCreateActivo.property_operation
              ? propertypropertyOperationCreateActivo.property_operation
              : [],
            [propertyOperationCreate.id]
          );
        }
        if (
          propertyOperationCreate &&
          propertyOperationCreate.idProperty &&
          propertyOperationCreate.idProperty.toString().indexOf('-') === -1
        )
          propertypropertyOperationCreateActivos = union(
            propertypropertyOperationCreateActivos,
            [propertyOperationCreate.idProperty]
          );
      });
      return Object.assign({}, state, {
        activo: propertypropertyOperationCreateActivo,
        activos: propertypropertyOperationCreateActivos
      });
    case UPDATE_PROPERTYOPERATION:
      let propertypropertyOperationUpdateActivo = clone(state.activo);
      let propertypropertyOperationUpdateActivos = clone(state.activos);
      Object.values(action.propertyOperation).map(propertyOperationUpdate => {
        if (
          propertyOperationUpdate &&
          propertyOperationUpdate.idProperty &&
          propertypropertyOperationUpdateActivo[
            propertyOperationUpdate.idProperty
          ]
        ) {
          if (propertyOperationUpdate.idProperty.toString().indexOf('-') === -1)
            propertypropertyOperationUpdateActivo[
              propertyOperationUpdate.idProperty
            ].property_operation = union(
              propertypropertyOperationUpdateActivo.property_operation,
              [propertyOperationUpdate.id]
            );
        } else if (propertyOperationUpdate) {
          propertypropertyOperationUpdateActivo.property_operation = union(
            propertypropertyOperationUpdateActivo.property_operation
              ? propertypropertyOperationUpdateActivo.property_operation
              : [],
            [propertyOperationUpdate.id]
          );
        }
        if (
          propertyOperationUpdate &&
          propertyOperationUpdate.idProperty &&
          propertyOperationUpdate.idProperty.toString().indexOf('-') === -1
        )
          propertypropertyOperationUpdateActivos = union(
            propertypropertyOperationUpdateActivos,
            [propertyOperationUpdate.idProperty]
          );
      });
      return Object.assign({}, state, {
        activo: propertypropertyOperationUpdateActivo,
        activos: propertypropertyOperationUpdateActivos
      });
    case DELETE_PROPERTYOPERATION:
      let propertypropertyOperationDeleteActivo = clone(state.activo);
      let propertypropertyOperationDeleteActivos = clone(state.activos);
      Object.values(action.propertyOperation).map(propertyOperationDelete => {
        if (
          propertyOperationDelete &&
          propertyOperationDelete.idProperty &&
          propertypropertyOperationDeleteActivo[
            propertyOperationDelete.idProperty
          ]
        ) {
          if (propertyOperationDelete.idProperty.toString().indexOf('-') === -1)
            propertypropertyOperationDeleteActivo[
              propertyOperationDelete.idProperty
            ].property_operation = difference(
              propertypropertyOperationDeleteActivo.property_operation,
              [propertyOperationDelete.id]
            );
        } else if (propertyOperationDelete) {
          propertypropertyOperationDeleteActivo.property_operation = difference(
            propertypropertyOperationDeleteActivo.property_operation
              ? propertypropertyOperationDeleteActivo.property_operation
              : [],
            [propertyOperationDelete.id]
          );
        }
        if (
          propertyOperationDelete &&
          propertyOperationDelete.idProperty &&
          propertyOperationDelete.idProperty.toString().indexOf('-') === -1
        )
          propertypropertyOperationDeleteActivos = union(
            propertypropertyOperationDeleteActivos,
            [propertyOperationDelete.idProperty]
          );
      });
      return Object.assign({}, state, {
        activo: propertypropertyOperationDeleteActivo,
        activos: propertypropertyOperationDeleteActivos
      });
    case CREATE_PROPERTYATTRIBUTEVALUE:
      let propertypropertyAttributeValueCreateActivo = clone(state.activo);
      let propertypropertyAttributeValueCreateActivos = clone(state.activos);
      Object.values(action.propertyAttributeValue).map(
        propertyAttributeValueCreate => {
          if (
            propertyAttributeValueCreate &&
            propertyAttributeValueCreate.idProperty &&
            propertypropertyAttributeValueCreateActivo[
              propertyAttributeValueCreate.idProperty
            ]
          ) {
            if (
              propertyAttributeValueCreate.idProperty
                .toString()
                .indexOf('-') === -1
            )
              propertypropertyAttributeValueCreateActivo[
                propertyAttributeValueCreate.idProperty
              ].property_attribute_value = union(
                propertypropertyAttributeValueCreateActivo.property_attribute_value,
                [propertyAttributeValueCreate.id]
              );
          } else if (propertyAttributeValueCreate) {
            propertypropertyAttributeValueCreateActivo.property_attribute_value =
              union(
                propertypropertyAttributeValueCreateActivo.property_attribute_value
                  ? propertypropertyAttributeValueCreateActivo.property_attribute_value
                  : [],
                [propertyAttributeValueCreate.id]
              );
          }
          if (
            propertyAttributeValueCreate &&
            propertyAttributeValueCreate.idProperty &&
            propertyAttributeValueCreate.idProperty.toString().indexOf('-') ===
              -1
          )
            propertypropertyAttributeValueCreateActivos = union(
              propertypropertyAttributeValueCreateActivos,
              [propertyAttributeValueCreate.idProperty]
            );
        }
      );
      return Object.assign({}, state, {
        activo: propertypropertyAttributeValueCreateActivo,
        activos: propertypropertyAttributeValueCreateActivos
      });
    case UPDATE_PROPERTYATTRIBUTEVALUE:
      let propertypropertyAttributeValueUpdateActivo = clone(state.activo);
      let propertypropertyAttributeValueUpdateActivos = clone(state.activos);
      Object.values(action.propertyAttributeValue).map(
        propertyAttributeValueUpdate => {
          if (
            propertyAttributeValueUpdate &&
            propertyAttributeValueUpdate.idProperty &&
            propertypropertyAttributeValueUpdateActivo[
              propertyAttributeValueUpdate.idProperty
            ]
          ) {
            if (
              propertyAttributeValueUpdate.idProperty
                .toString()
                .indexOf('-') === -1
            )
              propertypropertyAttributeValueUpdateActivo[
                propertyAttributeValueUpdate.idProperty
              ].property_attribute_value = union(
                propertypropertyAttributeValueUpdateActivo.property_attribute_value,
                [propertyAttributeValueUpdate.id]
              );
          } else if (propertyAttributeValueUpdate) {
            propertypropertyAttributeValueUpdateActivo.property_attribute_value =
              union(
                propertypropertyAttributeValueUpdateActivo.property_attribute_value
                  ? propertypropertyAttributeValueUpdateActivo.property_attribute_value
                  : [],
                [propertyAttributeValueUpdate.id]
              );
          }
          if (
            propertyAttributeValueUpdate &&
            propertyAttributeValueUpdate.idProperty &&
            propertyAttributeValueUpdate.idProperty.toString().indexOf('-') ===
              -1
          )
            propertypropertyAttributeValueUpdateActivos = union(
              propertypropertyAttributeValueUpdateActivos,
              [propertyAttributeValueUpdate.idProperty]
            );
        }
      );
      return Object.assign({}, state, {
        activo: propertypropertyAttributeValueUpdateActivo,
        activos: propertypropertyAttributeValueUpdateActivos
      });
    case DELETE_PROPERTYATTRIBUTEVALUE:
      let propertypropertyAttributeValueDeleteActivo = clone(state.activo);
      let propertypropertyAttributeValueDeleteActivos = clone(state.activos);
      Object.values(action.propertyAttributeValue).map(
        propertyAttributeValueDelete => {
          if (
            propertyAttributeValueDelete &&
            propertyAttributeValueDelete.idProperty &&
            propertypropertyAttributeValueDeleteActivo[
              propertyAttributeValueDelete.idProperty
            ]
          ) {
            if (
              propertyAttributeValueDelete.idProperty
                .toString()
                .indexOf('-') === -1
            )
              propertypropertyAttributeValueDeleteActivo[
                propertyAttributeValueDelete.idProperty
              ].property_attribute_value = difference(
                propertypropertyAttributeValueDeleteActivo.property_attribute_value,
                [propertyAttributeValueDelete.id]
              );
          } else if (propertyAttributeValueDelete) {
            propertypropertyAttributeValueDeleteActivo.property_attribute_value =
              difference(
                propertypropertyAttributeValueDeleteActivo.property_attribute_value
                  ? propertypropertyAttributeValueDeleteActivo.property_attribute_value
                  : [],
                [propertyAttributeValueDelete.id]
              );
          }
          if (
            propertyAttributeValueDelete &&
            propertyAttributeValueDelete.idProperty &&
            propertyAttributeValueDelete.idProperty.toString().indexOf('-') ===
              -1
          )
            propertypropertyAttributeValueDeleteActivos = union(
              propertypropertyAttributeValueDeleteActivos,
              [propertyAttributeValueDelete.idProperty]
            );
        }
      );
      return Object.assign({}, state, {
        activo: propertypropertyAttributeValueDeleteActivo,
        activos: propertypropertyAttributeValueDeleteActivos
      });
    case CREATE_ASSET:
      let propertyassetCreateActivo = clone(state.activo);
      let propertyassetCreateActivos = clone(state.activos);
      Object.values(action.asset).map(assetCreate => {
        if (
          assetCreate &&
          assetCreate.idProperty &&
          propertyassetCreateActivo[assetCreate.idProperty]
        ) {
          if (assetCreate.idProperty.toString().indexOf('-') === -1)
            propertyassetCreateActivo[assetCreate.idProperty].asset = union(
              propertyassetCreateActivo.asset,
              [assetCreate.id]
            );
        } else if (assetCreate) {
          propertyassetCreateActivo.asset = union(
            propertyassetCreateActivo.asset
              ? propertyassetCreateActivo.asset
              : [],
            [assetCreate.id]
          );
        }
        if (
          assetCreate &&
          assetCreate.idProperty &&
          assetCreate.idProperty.toString().indexOf('-') === -1
        )
          propertyassetCreateActivos = union(propertyassetCreateActivos, [
            assetCreate.idProperty
          ]);
      });
      return Object.assign({}, state, {
        activo: propertyassetCreateActivo,
        activos: propertyassetCreateActivos
      });
    case UPDATE_ASSET:
      let propertyassetUpdateActivo = clone(state.activo);
      let propertyassetUpdateActivos = clone(state.activos);
      Object.values(action.asset).map(assetUpdate => {
        if (
          assetUpdate &&
          assetUpdate.idProperty &&
          propertyassetUpdateActivo[assetUpdate.idProperty]
        ) {
          if (assetUpdate.idProperty.toString().indexOf('-') === -1)
            propertyassetUpdateActivo[assetUpdate.idProperty].asset = union(
              propertyassetUpdateActivo.asset,
              [assetUpdate.id]
            );
        } else if (assetUpdate) {
          propertyassetUpdateActivo.asset = union(
            propertyassetUpdateActivo.asset
              ? propertyassetUpdateActivo.asset
              : [],
            [assetUpdate.id]
          );
        }
        if (
          assetUpdate &&
          assetUpdate.idProperty &&
          assetUpdate.idProperty.toString().indexOf('-') === -1
        )
          propertyassetUpdateActivos = union(propertyassetUpdateActivos, [
            assetUpdate.idProperty
          ]);
      });
      return Object.assign({}, state, {
        activo: propertyassetUpdateActivo,
        activos: propertyassetUpdateActivos
      });
    case DELETE_ASSET:
      let propertyassetDeleteActivo = clone(state.activo);
      let propertyassetDeleteActivos = clone(state.activos);
      Object.values(action.asset).map(assetDelete => {
        if (
          assetDelete &&
          assetDelete.idProperty &&
          propertyassetDeleteActivo[assetDelete.idProperty]
        ) {
          if (assetDelete.idProperty.toString().indexOf('-') === -1)
            propertyassetDeleteActivo[assetDelete.idProperty].asset =
              difference(propertyassetDeleteActivo.asset, [assetDelete.id]);
        } else if (assetDelete) {
          propertyassetDeleteActivo.asset = difference(
            propertyassetDeleteActivo.asset
              ? propertyassetDeleteActivo.asset
              : [],
            [assetDelete.id]
          );
        }
        if (
          assetDelete &&
          assetDelete.idProperty &&
          assetDelete.idProperty.toString().indexOf('-') === -1
        )
          propertyassetDeleteActivos = union(propertyassetDeleteActivos, [
            assetDelete.idProperty
          ]);
      });
      return Object.assign({}, state, {
        activo: propertyassetDeleteActivo,
        activos: propertyassetDeleteActivos
      });
    case CREATE_PROPERTYCLOSEDOPERATION:
      let propertypropertyClosedOperationCreateActivo = clone(state.activo);
      let propertypropertyClosedOperationCreateActivos = clone(state.activos);
      Object.values(action.propertyClosedOperation).map(
        propertyClosedOperationCreate => {
          if (
            propertyClosedOperationCreate &&
            propertyClosedOperationCreate.property_id &&
            propertypropertyClosedOperationCreateActivo[
              propertyClosedOperationCreate.property_id
            ]
          ) {
            if (
              propertyClosedOperationCreate.property_id
                .toString()
                .indexOf('-') === -1
            )
              propertypropertyClosedOperationCreateActivo[
                propertyClosedOperationCreate.property_id
              ].property_closed_operation = union(
                propertypropertyClosedOperationCreateActivo.property_closed_operation,
                [propertyClosedOperationCreate.id]
              );
          } else if (propertyClosedOperationCreate) {
            propertypropertyClosedOperationCreateActivo.property_closed_operation =
              union(
                propertypropertyClosedOperationCreateActivo.property_closed_operation
                  ? propertypropertyClosedOperationCreateActivo.property_closed_operation
                  : [],
                [propertyClosedOperationCreate.id]
              );
          }
          if (
            propertyClosedOperationCreate &&
            propertyClosedOperationCreate.property_id &&
            propertyClosedOperationCreate.property_id
              .toString()
              .indexOf('-') === -1
          )
            propertypropertyClosedOperationCreateActivos = union(
              propertypropertyClosedOperationCreateActivos,
              [propertyClosedOperationCreate.property_id]
            );
        }
      );
      return Object.assign({}, state, {
        activo: propertypropertyClosedOperationCreateActivo,
        activos: propertypropertyClosedOperationCreateActivos
      });
    case UPDATE_PROPERTYCLOSEDOPERATION:
      let propertypropertyClosedOperationUpdateActivo = clone(state.activo);
      let propertypropertyClosedOperationUpdateActivos = clone(state.activos);
      Object.values(action.propertyClosedOperation).map(
        propertyClosedOperationUpdate => {
          if (
            propertyClosedOperationUpdate &&
            propertyClosedOperationUpdate.property_id &&
            propertypropertyClosedOperationUpdateActivo[
              propertyClosedOperationUpdate.property_id
            ]
          ) {
            if (
              propertyClosedOperationUpdate.property_id
                .toString()
                .indexOf('-') === -1
            )
              propertypropertyClosedOperationUpdateActivo[
                propertyClosedOperationUpdate.property_id
              ].property_closed_operation = union(
                propertypropertyClosedOperationUpdateActivo.property_closed_operation,
                [propertyClosedOperationUpdate.id]
              );
          } else if (propertyClosedOperationUpdate) {
            propertypropertyClosedOperationUpdateActivo.property_closed_operation =
              union(
                propertypropertyClosedOperationUpdateActivo.property_closed_operation
                  ? propertypropertyClosedOperationUpdateActivo.property_closed_operation
                  : [],
                [propertyClosedOperationUpdate.id]
              );
          }
          if (
            propertyClosedOperationUpdate &&
            propertyClosedOperationUpdate.property_id &&
            propertyClosedOperationUpdate.property_id
              .toString()
              .indexOf('-') === -1
          )
            propertypropertyClosedOperationUpdateActivos = union(
              propertypropertyClosedOperationUpdateActivos,
              [propertyClosedOperationUpdate.property_id]
            );
        }
      );
      return Object.assign({}, state, {
        activo: propertypropertyClosedOperationUpdateActivo,
        activos: propertypropertyClosedOperationUpdateActivos
      });
    case DELETE_PROPERTYCLOSEDOPERATION:
      let propertypropertyClosedOperationDeleteActivo = clone(state.activo);
      let propertypropertyClosedOperationDeleteActivos = clone(state.activos);
      Object.values(action.propertyClosedOperation).map(
        propertyClosedOperationDelete => {
          if (
            propertyClosedOperationDelete &&
            propertyClosedOperationDelete.property_id &&
            propertypropertyClosedOperationDeleteActivo[
              propertyClosedOperationDelete.property_id
            ]
          ) {
            if (
              propertyClosedOperationDelete.property_id
                .toString()
                .indexOf('-') === -1
            )
              propertypropertyClosedOperationDeleteActivo[
                propertyClosedOperationDelete.property_id
              ].property_closed_operation = difference(
                propertypropertyClosedOperationDeleteActivo.property_closed_operation,
                [propertyClosedOperationDelete.id]
              );
          } else if (propertyClosedOperationDelete) {
            propertypropertyClosedOperationDeleteActivo.property_closed_operation =
              difference(
                propertypropertyClosedOperationDeleteActivo.property_closed_operation
                  ? propertypropertyClosedOperationDeleteActivo.property_closed_operation
                  : [],
                [propertyClosedOperationDelete.id]
              );
          }
          if (
            propertyClosedOperationDelete &&
            propertyClosedOperationDelete.property_id &&
            propertyClosedOperationDelete.property_id
              .toString()
              .indexOf('-') === -1
          )
            propertypropertyClosedOperationDeleteActivos = union(
              propertypropertyClosedOperationDeleteActivos,
              [propertyClosedOperationDelete.property_id]
            );
        }
      );
      return Object.assign({}, state, {
        activo: propertypropertyClosedOperationDeleteActivo,
        activos: propertypropertyClosedOperationDeleteActivos
      });

    //ASSET
    //TODO ver si esta bien
    case SUCCESS_CREATE_ASSET:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_ASSET:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_ASSET:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_ASSETS:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      }); //PROPERTYCLOSEDOPERATION
    //TODO ver si esta bien
    case SUCCESS_CREATE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_PROPERTYCLOSEDOPERATIONS:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      }); //TICKET
    //TODO ver si esta bien
    case SUCCESS_CREATE_TICKET:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_TICKET:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_TICKET:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_TICKETS:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });

    case DELETE_PROPERTY:
      let datoPropertyDelete = action.property;
      let idsDelete = [];
      Object.values(action.property).map(propertyDelete => {
        if (propertyDelete && propertyDelete.id)
          idsDelete.push(propertyDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(clone(state.activo), Object.keys(datoPropertyDelete)),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_PROPERTY:
      let datoPropertyDeleteUpdate = action.property;
      let idsDeleteUpdate = [];
      Object.values(action.property).map(propertyDelete => {
        if (propertyDelete && propertyDelete.id)
          idsDeleteUpdate.push(propertyDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoPropertyDeleteUpdate)
          ),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_PROPERTY:
      let datoPropertyDeleted = {};
      if (Object.values(action.property.entities.properties).length > 0)
        datoPropertyDeleted = Object.values(
          action.property.entities.properties
        )[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoPropertyDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_PROPERTY:
      let idsCreate = [];
      Object.values(action.property).map(propertyCreate => {
        if (propertyCreate && propertyCreate.id)
          idsCreate.push(propertyCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.property,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_PROPERTY:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_PROPERTY:
      let datoPropertyNuevo = action.property.entities.properties;
      let propertyNuevo =
        datoPropertyNuevo && Object.keys(datoPropertyNuevo).length > 0
          ? datoPropertyNuevo[action.property.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: propertyNuevo,
        nuevos: []
      });
    case ERROR_CREATE_PROPERTY:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_PROPERTIES:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_PROPERTIES:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_PROPERTIES:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_PROPERTY:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    //CREATE PROPERTYOPERATION
    case CREATE_PROPERTYOPERATION:
      let propertypropertyOperationCreateActivo = clone(state.nuevo);
      Object.values(action.propertyOperation).map(propertyOperationCreate => {
        if (
          propertyOperationCreate &&
          propertyOperationCreate.idProperty &&
          propertypropertyOperationCreateActivo[
            propertyOperationCreate.idProperty
          ]
        ) {
          if (propertyOperationCreate.idProperty.toString().indexOf('-') !== -1)
            propertypropertyOperationCreateActivo[
              propertyOperationCreate.idProperty
            ].property_operation = union(
              propertypropertyOperationCreateActivo.property_operation,
              [propertyOperationCreate.id]
            );
        } else if (propertyOperationCreate) {
          propertypropertyOperationCreateActivo.property_operation = union(
            propertypropertyOperationCreateActivo.property_operation
              ? propertypropertyOperationCreateActivo.property_operation
              : [],
            [propertyOperationCreate.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: propertypropertyOperationCreateActivo
        //nuevos: propertyOperationCreate && propertyOperationCreate.idProperty ? union(state.nuevos, [propertyOperationCreate.idProperty]) : state.nuevos,
      });
    case UPDATE_PROPERTYOPERATION:
      let propertypropertyOperationUpdateActivo = clone(state.nuevo);
      Object.values(action.propertyOperation).map(propertyOperationUpdate => {
        if (
          propertyOperationUpdate &&
          propertyOperationUpdate.idProperty &&
          propertypropertyOperationUpdateActivo[
            propertyOperationUpdate.idProperty
          ]
        ) {
          if (propertyOperationUpdate.idProperty.toString().indexOf('-') !== -1)
            propertypropertyOperationUpdateActivo[
              propertyOperationUpdate.idProperty
            ].property_operation = union(
              propertypropertyOperationUpdateActivo.property_operation,
              [propertyOperationUpdate.id]
            );
        } else if (propertyOperationUpdate) {
          propertypropertyOperationUpdateActivo.property_operation = union(
            propertypropertyOperationUpdateActivo.property_operation
              ? propertypropertyOperationUpdateActivo.property_operation
              : [],
            [propertyOperationUpdate.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: propertypropertyOperationUpdateActivo
        //nuevos: propertyOperationUpdate && propertyOperationUpdate.idProperty ? union(state.nuevos, [propertyOperationUpdate.idProperty]) : state.nuevos,
      });
    case DELETE_PROPERTYOPERATION:
      let propertypropertyOperationDeleteActivo = clone(state.nuevo);
      Object.values(action.propertyOperation).map(propertyOperationDelete => {
        if (
          propertyOperationDelete &&
          propertyOperationDelete.idProperty &&
          propertyOperationDelete.idProperty &&
          propertypropertyOperationDeleteActivo[
            propertyOperationDelete.idProperty
          ]
        ) {
          if (propertyOperationDelete.idProperty.toString().indexOf('-') !== -1)
            propertypropertyOperationDeleteActivo[
              propertyOperationDelete.idProperty
            ].property_operation = difference(
              propertypropertyOperationDeleteActivo.property_operation,
              [propertyOperationDelete.id]
            );
        } else if (propertyOperationDelete) {
          propertypropertyOperationDeleteActivo.property_operation = difference(
            propertypropertyOperationDeleteActivo.property_operation
              ? propertypropertyOperationDeleteActivo.property_operation
              : [],
            [propertyOperationDelete.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: propertypropertyOperationDeleteActivo
        //nuevos: propertyOperationDelete && propertyOperationDelete.idProperty ? union(state.nuevos, [propertyOperationDelete.idProperty]) : state.nuevos,
      }); //CREATE PROPERTYATTRIBUTEVALUE
    case CREATE_PROPERTYATTRIBUTEVALUE:
      let propertypropertyAttributeValueCreateActivo = clone(state.nuevo);
      Object.values(action.propertyAttributeValue).map(
        propertyAttributeValueCreate => {
          if (
            propertyAttributeValueCreate &&
            propertyAttributeValueCreate.idProperty &&
            propertypropertyAttributeValueCreateActivo[
              propertyAttributeValueCreate.idProperty
            ]
          ) {
            if (
              propertyAttributeValueCreate.idProperty
                .toString()
                .indexOf('-') !== -1
            )
              propertypropertyAttributeValueCreateActivo[
                propertyAttributeValueCreate.idProperty
              ].property_attribute_value = union(
                propertypropertyAttributeValueCreateActivo.property_attribute_value,
                [propertyAttributeValueCreate.id]
              );
          } else if (propertyAttributeValueCreate) {
            propertypropertyAttributeValueCreateActivo.property_attribute_value =
              union(
                propertypropertyAttributeValueCreateActivo.property_attribute_value
                  ? propertypropertyAttributeValueCreateActivo.property_attribute_value
                  : [],
                [propertyAttributeValueCreate.id]
              );
          }
        }
      );
      return Object.assign({}, state, {
        nuevo: propertypropertyAttributeValueCreateActivo
        //nuevos: propertyAttributeValueCreate && propertyAttributeValueCreate.idProperty ? union(state.nuevos, [propertyAttributeValueCreate.idProperty]) : state.nuevos,
      });
    case UPDATE_PROPERTYATTRIBUTEVALUE:
      let propertypropertyAttributeValueUpdateActivo = clone(state.nuevo);
      Object.values(action.propertyAttributeValue).map(
        propertyAttributeValueUpdate => {
          if (
            propertyAttributeValueUpdate &&
            propertyAttributeValueUpdate.idProperty &&
            propertypropertyAttributeValueUpdateActivo[
              propertyAttributeValueUpdate.idProperty
            ]
          ) {
            if (
              propertyAttributeValueUpdate.idProperty
                .toString()
                .indexOf('-') !== -1
            )
              propertypropertyAttributeValueUpdateActivo[
                propertyAttributeValueUpdate.idProperty
              ].property_attribute_value = union(
                propertypropertyAttributeValueUpdateActivo.property_attribute_value,
                [propertyAttributeValueUpdate.id]
              );
          } else if (propertyAttributeValueUpdate) {
            propertypropertyAttributeValueUpdateActivo.property_attribute_value =
              union(
                propertypropertyAttributeValueUpdateActivo.property_attribute_value
                  ? propertypropertyAttributeValueUpdateActivo.property_attribute_value
                  : [],
                [propertyAttributeValueUpdate.id]
              );
          }
        }
      );
      return Object.assign({}, state, {
        nuevo: propertypropertyAttributeValueUpdateActivo
        //nuevos: propertyAttributeValueUpdate && propertyAttributeValueUpdate.idProperty ? union(state.nuevos, [propertyAttributeValueUpdate.idProperty]) : state.nuevos,
      });
    case DELETE_PROPERTYATTRIBUTEVALUE:
      let propertypropertyAttributeValueDeleteActivo = clone(state.nuevo);
      Object.values(action.propertyAttributeValue).map(
        propertyAttributeValueDelete => {
          if (
            propertyAttributeValueDelete &&
            propertyAttributeValueDelete.idProperty &&
            propertyAttributeValueDelete.idProperty &&
            propertypropertyAttributeValueDeleteActivo[
              propertyAttributeValueDelete.idProperty
            ]
          ) {
            if (
              propertyAttributeValueDelete.idProperty
                .toString()
                .indexOf('-') !== -1
            )
              propertypropertyAttributeValueDeleteActivo[
                propertyAttributeValueDelete.idProperty
              ].property_attribute_value = difference(
                propertypropertyAttributeValueDeleteActivo.property_attribute_value,
                [propertyAttributeValueDelete.id]
              );
          } else if (propertyAttributeValueDelete) {
            propertypropertyAttributeValueDeleteActivo.property_attribute_value =
              difference(
                propertypropertyAttributeValueDeleteActivo.property_attribute_value
                  ? propertypropertyAttributeValueDeleteActivo.property_attribute_value
                  : [],
                [propertyAttributeValueDelete.id]
              );
          }
        }
      );
      return Object.assign({}, state, {
        nuevo: propertypropertyAttributeValueDeleteActivo
        //nuevos: propertyAttributeValueDelete && propertyAttributeValueDelete.idProperty ? union(state.nuevos, [propertyAttributeValueDelete.idProperty]) : state.nuevos,
      }); //CREATE ASSET
    case CREATE_ASSET:
      let propertyassetCreateActivo = clone(state.nuevo);
      Object.values(action.asset).map(assetCreate => {
        if (
          assetCreate &&
          assetCreate.idProperty &&
          propertyassetCreateActivo[assetCreate.idProperty]
        ) {
          if (assetCreate.idProperty.toString().indexOf('-') !== -1)
            propertyassetCreateActivo[assetCreate.idProperty].asset = union(
              propertyassetCreateActivo.asset,
              [assetCreate.id]
            );
        } else if (assetCreate) {
          propertyassetCreateActivo.asset = union(
            propertyassetCreateActivo.asset
              ? propertyassetCreateActivo.asset
              : [],
            [assetCreate.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: propertyassetCreateActivo
        //nuevos: assetCreate && assetCreate.idProperty ? union(state.nuevos, [assetCreate.idProperty]) : state.nuevos,
      });
    case UPDATE_ASSET:
      let propertyassetUpdateActivo = clone(state.nuevo);
      Object.values(action.asset).map(assetUpdate => {
        if (
          assetUpdate &&
          assetUpdate.idProperty &&
          propertyassetUpdateActivo[assetUpdate.idProperty]
        ) {
          if (assetUpdate.idProperty.toString().indexOf('-') !== -1)
            propertyassetUpdateActivo[assetUpdate.idProperty].asset = union(
              propertyassetUpdateActivo.asset,
              [assetUpdate.id]
            );
        } else if (assetUpdate) {
          propertyassetUpdateActivo.asset = union(
            propertyassetUpdateActivo.asset
              ? propertyassetUpdateActivo.asset
              : [],
            [assetUpdate.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: propertyassetUpdateActivo
        //nuevos: assetUpdate && assetUpdate.idProperty ? union(state.nuevos, [assetUpdate.idProperty]) : state.nuevos,
      });
    case DELETE_ASSET:
      let propertyassetDeleteActivo = clone(state.nuevo);
      Object.values(action.asset).map(assetDelete => {
        if (
          assetDelete &&
          assetDelete.idProperty &&
          assetDelete.idProperty &&
          propertyassetDeleteActivo[assetDelete.idProperty]
        ) {
          if (assetDelete.idProperty.toString().indexOf('-') !== -1)
            propertyassetDeleteActivo[assetDelete.idProperty].asset =
              difference(propertyassetDeleteActivo.asset, [assetDelete.id]);
        } else if (assetDelete) {
          propertyassetDeleteActivo.asset = difference(
            propertyassetDeleteActivo.asset
              ? propertyassetDeleteActivo.asset
              : [],
            [assetDelete.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: propertyassetDeleteActivo
        //nuevos: assetDelete && assetDelete.idProperty ? union(state.nuevos, [assetDelete.idProperty]) : state.nuevos,
      }); //CREATE PROPERTYCLOSEDOPERATION
    case CREATE_PROPERTYCLOSEDOPERATION:
      let propertypropertyClosedOperationCreateActivo = clone(state.nuevo);
      Object.values(action.propertyClosedOperation).map(
        propertyClosedOperationCreate => {
          if (
            propertyClosedOperationCreate &&
            propertyClosedOperationCreate.property_id &&
            propertypropertyClosedOperationCreateActivo[
              propertyClosedOperationCreate.property_id
            ]
          ) {
            if (
              propertyClosedOperationCreate.property_id
                .toString()
                .indexOf('-') !== -1
            )
              propertypropertyClosedOperationCreateActivo[
                propertyClosedOperationCreate.property_id
              ].property_closed_operation = union(
                propertypropertyClosedOperationCreateActivo.property_closed_operation,
                [propertyClosedOperationCreate.id]
              );
          } else if (propertyClosedOperationCreate) {
            propertypropertyClosedOperationCreateActivo.property_closed_operation =
              union(
                propertypropertyClosedOperationCreateActivo.property_closed_operation
                  ? propertypropertyClosedOperationCreateActivo.property_closed_operation
                  : [],
                [propertyClosedOperationCreate.id]
              );
          }
        }
      );
      return Object.assign({}, state, {
        nuevo: propertypropertyClosedOperationCreateActivo
        //nuevos: propertyClosedOperationCreate && propertyClosedOperationCreate.property_id ? union(state.nuevos, [propertyClosedOperationCreate.property_id]) : state.nuevos,
      });
    case UPDATE_PROPERTYCLOSEDOPERATION:
      let propertypropertyClosedOperationUpdateActivo = clone(state.nuevo);
      Object.values(action.propertyClosedOperation).map(
        propertyClosedOperationUpdate => {
          if (
            propertyClosedOperationUpdate &&
            propertyClosedOperationUpdate.property_id &&
            propertypropertyClosedOperationUpdateActivo[
              propertyClosedOperationUpdate.property_id
            ]
          ) {
            if (
              propertyClosedOperationUpdate.property_id
                .toString()
                .indexOf('-') !== -1
            )
              propertypropertyClosedOperationUpdateActivo[
                propertyClosedOperationUpdate.property_id
              ].property_closed_operation = union(
                propertypropertyClosedOperationUpdateActivo.property_closed_operation,
                [propertyClosedOperationUpdate.id]
              );
          } else if (propertyClosedOperationUpdate) {
            propertypropertyClosedOperationUpdateActivo.property_closed_operation =
              union(
                propertypropertyClosedOperationUpdateActivo.property_closed_operation
                  ? propertypropertyClosedOperationUpdateActivo.property_closed_operation
                  : [],
                [propertyClosedOperationUpdate.id]
              );
          }
        }
      );
      return Object.assign({}, state, {
        nuevo: propertypropertyClosedOperationUpdateActivo
        //nuevos: propertyClosedOperationUpdate && propertyClosedOperationUpdate.property_id ? union(state.nuevos, [propertyClosedOperationUpdate.property_id]) : state.nuevos,
      });
    case DELETE_PROPERTYCLOSEDOPERATION:
      let propertypropertyClosedOperationDeleteActivo = clone(state.nuevo);
      Object.values(action.propertyClosedOperation).map(
        propertyClosedOperationDelete => {
          if (
            propertyClosedOperationDelete &&
            propertyClosedOperationDelete.property_id &&
            propertyClosedOperationDelete.property_id &&
            propertypropertyClosedOperationDeleteActivo[
              propertyClosedOperationDelete.property_id
            ]
          ) {
            if (
              propertyClosedOperationDelete.property_id
                .toString()
                .indexOf('-') !== -1
            )
              propertypropertyClosedOperationDeleteActivo[
                propertyClosedOperationDelete.property_id
              ].property_closed_operation = difference(
                propertypropertyClosedOperationDeleteActivo.property_closed_operation,
                [propertyClosedOperationDelete.id]
              );
          } else if (propertyClosedOperationDelete) {
            propertypropertyClosedOperationDeleteActivo.property_closed_operation =
              difference(
                propertypropertyClosedOperationDeleteActivo.property_closed_operation
                  ? propertypropertyClosedOperationDeleteActivo.property_closed_operation
                  : [],
                [propertyClosedOperationDelete.id]
              );
          }
        }
      );
      return Object.assign({}, state, {
        nuevo: propertypropertyClosedOperationDeleteActivo
        //nuevos: propertyClosedOperationDelete && propertyClosedOperationDelete.property_id ? union(state.nuevos, [propertyClosedOperationDelete.property_id]) : state.nuevos,
      });

    //ASSET
    case SUCCESS_CREATE_ASSET:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_ASSET:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_ASSET:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_ASSETS:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    //PROPERTYCLOSEDOPERATION
    case SUCCESS_CREATE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_PROPERTYCLOSEDOPERATIONS:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    //TICKET
    case SUCCESS_CREATE_TICKET:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_TICKET:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_TICKET:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_TICKETS:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });

    case DELETE_PROPERTY:
      let datoPropertyDelete = action.property;
      let idsDelete = [];
      Object.values(action.property).map(propertyDelete => {
        if (propertyDelete && propertyDelete.id)
          idsDelete.push(propertyDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoPropertyDelete)),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_PROPERTY:
      let datoPropertyDeleteCreate = action.property;
      let idsDeleteCreate = [];
      Object.values(action.property).map(propertyDelete => {
        if (propertyDelete && propertyDelete.id)
          idsDeleteCreate.push(propertyDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoPropertyDeleteCreate)
          ),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_PROPERTY:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.property,
        error: null
      });
    case REQUEST_DELETE_PROPERTY:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_PROPERTY:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_PROPERTY:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_PROPERTY:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    //ASSET
    case SUCCESS_CREATE_ASSET:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_ASSET:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_ASSET:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_ASSETS:
      return Object.assign({}, state, {
        eliminado: {}
      }); //PROPERTYCLOSEDOPERATION
    case SUCCESS_CREATE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_PROPERTYCLOSEDOPERATIONS:
      return Object.assign({}, state, {
        eliminado: {}
      }); //TICKET
    case SUCCESS_CREATE_TICKET:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_TICKET:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_TICKET:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_TICKETS:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_PROPERTY:
      let idsCreate = [];
      Object.values(action.property).map(propertyCreate => {
        if (propertyCreate && propertyCreate.id)
          idsCreate.push(propertyCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.property,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_PROPERTY:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_PROPERTY:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_PROPERTY:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_PROPERTIES:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_PROPERTIES:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_PROPERTIES:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_PROPERTY:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_PROPERTY:
      let datoPropertyDeleteCreate = action.property;
      let idsDeleteCreate = [];
      Object.values(action.property).map(propertyDelete => {
        if (propertyDelete && propertyDelete.id)
          idsDeleteCreate.push(propertyDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(
            clone(state.print),
            Object.keys(datoPropertyDeleteCreate)
          ),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const properties = combineReducers({
  byId: propertiesById,
  allIds: allProperties,
  update: update,
  create: create,
  totalProperties: totalProperties,
  delete: deleter,
  print: print
});

export default properties;
