import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import TicketActivityItem from './TicketActivityItem';
import React from 'react';

export default function TicketActitivitiesList(props) {
  const { ticket } = props;
  const properties = useSelector(state => state.properties);
  const ticketActivities = useSelector(state => state.ticketActivities);

  return (
    <Box
      display="flex"
      flexDirection={'column'}
      alignItems="flex-start"
      gridRowGap={'15px'}
      width={'100%'}
    >
      {ticket?.ticket_activity?.map(id => {
        let activity = ticketActivities.byId.ticketActivities[id];
        let property = activity?.property_id
          ? properties.byId.properties[activity.property_id]
          : null;
        if (properties.update.activos.indexOf(activity?.property_id) !== -1)
          property = properties.update.activo[activity?.property_id];
        return (
          <TicketActivityItem
            activity={activity}
            property={property}
            position={0 !== activity?.idUserLastMdf ? 'right' : 'left'}
          />
        );
      })}
    </Box>
  );
}
