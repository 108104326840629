import { normalize, schema, denormalize } from 'normalizr';

const log = new schema.Entity('logs', {}, { idAttribute: 'id' });

export function normalizeDatos(myData) {
  const mySchema = { logs: [log] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = log;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = log;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default log;
