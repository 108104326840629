import {
  Breadcrumbs,
  ListItem,
  ListItemAvatar,
  Typography
} from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import PropertyAttributeChips from './PropertyAttributeChips';
import { makeStyles } from '@material-ui/core/styles';
import c from '../../constants/Constants';
//Img
import PropertyStatus from '../../utils/PropertyStatus';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
  desProperty: {
    display: '-webkit-box',
    lineClamp: 2,
    boxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: '20px',
    marginTop: '10px'
  },
  iconsContainer: {
    display: 'flex',
    gap: '5px 5px',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  avatarPropertyType: {
    width: theme.spacing(2),
    height: theme.spacing(2)
  }
}));

export default function PropertyItemList(props) {
  const { property, style, attributes = true } = props;
  const classes = useStyles();

  //Store
  const propertyTypes = useSelector(state => state.propertyTypes);
  const operationTypes = useSelector(state => state.operationTypes);
  const propertyOperations = useSelector(state => state.propertyOperations);
  const assets = useSelector(state => state.assets);

  const propertyType =
    property &&
    property.idPropertyType &&
    propertyTypes.byId.propertyTypes[property.idPropertyType]
      ? propertyTypes.byId.propertyTypes[property.idPropertyType]
      : null;
  const propertySubType =
    property &&
    property.idPropertySubType &&
    propertyTypes.byId.propertyTypes[property.idPropertySubType]
      ? propertyTypes.byId.propertyTypes[property.idPropertySubType]
      : null;

  return (
    <>
      <ListItem alignItems="flex-start" style={{ ...style }}>
        <ListItemAvatar>
          <img
            width={'120px'}
            height={'120px'}
            style={{ marginRight: '10px', borderRadius: '15px' }}
            alt="property image"
            src={
              property && property.main_asset && property.main_asset.length > 0
                ? c.API_HOST + assets.byId.assets[property.main_asset[0]].path
                : null
            }
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Box
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              flexWrap={'wrap'}
            >
              <Typography
                variant={'h6'}
                component={'span'}
                style={{ fontWeight: 600, fontSize: '1.15 rem' }}
              >
                {`${property?.address}${
                  property?.floor ? ' ' + property?.floor : ''
                }${property?.apartment ? ' ' + property?.apartment : ''}`}
              </Typography>
              <Box
                display={'flex'}
                justifyContent={'flex-start'}
                alignItems={'baseline'}
                gridColumnGap={'5px'}
              >
                <img
                  alt={'icono tipo propiedad'}
                  src={
                    propertyType?.filename
                      ? `${c.API_HOST}/storage/propertyTypeIcons/${propertyType.filename}`
                      : ''
                  }
                  className={classes.avatarPropertyType}
                />
                <Typography
                  variant={'p'}
                  component={'caption'}
                  display={'inline'}
                >
                  {propertyType?.nameType ? propertyType.nameType : ''}
                  {propertySubType?.nameType
                    ? ' - ' + propertySubType.nameType
                    : ''}
                </Typography>
              </Box>
            </Box>
          }
          secondary={
            <React.Fragment>
              <Breadcrumbs
                aria-label="breadcrumb"
                separator={'‣'}
                style={{ marginBottom: '10px' }}
              >
                <Typography variant={'p'} component={'body2'}>
                  {property?.neighborhood ? property?.neighborhood : ''}
                </Typography>
                <Typography variant={'p'} component={'body2'}>
                  {property?.city ? property?.city : ''}
                </Typography>
                <Typography variant={'p'} component={'body2'}>
                  {property?.state ? property?.state : ''}
                </Typography>
                <Typography variant={'p'} component={'body2'}>
                  {property?.country ? property?.country : ''}
                </Typography>
              </Breadcrumbs>
              {attributes && (
                <PropertyAttributeChips
                  size={'small'}
                  property={property}
                  listAttributesGroups={[
                    'basic',
                    'surfaces',
                    'antiquity',
                    'expenses',
                    'main'
                  ]}
                />
              )}
              <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}
                gridColumnGap={'15px'}
                gridRowGap={'10px'}
                flexWrap={'wrap'}
              >
                <Box
                  display={'flex'}
                  justifyContent={'flex-start'}
                  alignItems={'self-end'}
                >
                  <PropertyStatus status={property?.status} disabled={true} />
                </Box>
                {property &&
                  property.property_operation &&
                  property.property_operation.map(idPropertyOperation => {
                    let propertyOperation =
                      propertyOperations.byId.propertyOperations[
                        idPropertyOperation
                      ];
                    if (
                      propertyOperations.update.activos.indexOf(
                        idPropertyOperation
                      ) !== -1
                    )
                      propertyOperation =
                        propertyOperations.update.activo[idPropertyOperation];
                    if (
                      propertyOperations.create.nuevos.indexOf(
                        idPropertyOperation
                      ) !== -1
                    )
                      propertyOperation =
                        propertyOperations.create.nuevo[idPropertyOperation];
                    let operationType = null;
                    if (propertyOperation)
                      operationType =
                        operationTypes.byId.operationTypes[
                          propertyOperation.idOperationType
                        ];
                    if (
                      propertyOperation &&
                      operationType &&
                      propertyOperation.codCurrency &&
                      propertyOperation.price
                    )
                      return (
                        <Box
                          key={'pop' + idPropertyOperation}
                          display={'flex'}
                          flexDirection={'column'}
                          justifyContent={'flex-end'}
                          alignItems={'self-end'}
                        >
                          <Typography variant={'caption'} component={'span'}>
                            {operationType.nameType}
                          </Typography>
                          <Typography
                            variant={'h6'}
                            component={'span'}
                            style={{ fontWeight: 600, color: 'black' }}
                          >
                            {propertyOperation.codCurrency +
                              ' ' +
                              propertyOperation.price}
                          </Typography>
                        </Box>
                      );
                  })}
              </Box>
            </React.Fragment>
          }
        />
        {props.children}
      </ListItem>
      <Divider />
    </>
  );
}
