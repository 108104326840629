import {
  INVALIDATE_ANALYTICS,
  ERROR_ANALYTICS,
  RECEIVE_ANALYTICS,
  REQUEST_ANALYTICS,
  RESET_ANALYTICS,
  ERROR_ANALYTIC,
  RECEIVE_ANALYTIC,
  REQUEST_ANALYTIC,
  UPDATE_ANALYTIC,
  REQUEST_UPDATE_ANALYTIC,
  SUCCESS_UPDATE_ANALYTIC,
  ERROR_UPDATE_ANALYTIC,
  RESET_UPDATE_ANALYTIC,
  REQUEST_UPDATE_ANALYTICS,
  SUCCESS_UPDATE_ANALYTICS,
  ERROR_UPDATE_ANALYTICS,
  RESET_UPDATE_ANALYTICS,
  CREATE_ANALYTIC,
  ERROR_CREATE_ANALYTIC,
  REQUEST_CREATE_ANALYTIC,
  RESET_CREATE_ANALYTIC,
  SUCCESS_CREATE_ANALYTIC,
  REQUEST_CREATE_ANALYTICS,
  SUCCESS_CREATE_ANALYTICS,
  ERROR_CREATE_ANALYTICS,
  RESET_CREATE_ANALYTICS,
  DELETE_ANALYTIC,
  DELETE_CREATE_ANALYTIC,
  DELETE_UPDATE_ANALYTIC,
  REQUEST_DELETE_ANALYTIC,
  SUCCESS_DELETE_ANALYTIC,
  ERROR_DELETE_ANALYTIC,
  RESET_DELETE_ANALYTIC,
  REQUEST_PRINT_ANALYTIC,
  SUCCESS_PRINT_ANALYTIC,
  ERROR_PRINT_ANALYTIC,
  RESET_PRINT_ANALYTIC,
  RECEIVE_FILE_ANALYTIC,
  REQUEST_PRINT_ANALYTICS,
  SUCCESS_PRINT_ANALYTICS,
  ERROR_PRINT_ANALYTICS,
  RESET_PRINT_ANALYTICS,
  RECEIVE_FILE_ANALYTICS,
  PRINT_ANALYTIC,
  DELETE_PRINT_ANALYTIC
} from '../actions/AnalyticActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    analytics: {},
    files: {}
  };
}

function analyticsById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_ANALYTICS:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_ANALYTICS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_ANALYTICS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_ANALYTICS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        analytics: {}
      });
    case RECEIVE_ANALYTICS:
      let dato = action.analytics.entities.analytics;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        analytics: merge({}, state.analytics, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_ANALYTIC:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_ANALYTIC:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_ANALYTIC:
      let datoAnalytic = action.analytic.entities.analytics;
      return Object.assign({}, state, {
        analytics: merge({}, state.analytics, datoAnalytic),
        isFetching: false
      });
    case RECEIVE_FILE_ANALYTIC:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_ANALYTIC:
      let datoAnalyticEliminado = action.analytic.entities.analytics;
      return Object.assign({}, state, {
        analytics: mergeWith(
          clone(datoAnalyticEliminado),
          state.analytics,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_ANALYTIC:
      let datoAnalyticCreado = action.analytic.entities.analytics;
      return Object.assign({}, state, {
        analytics: mergeWith(
          clone(datoAnalyticCreado),
          state.analytics,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_ANALYTICS:
      let datosAnalyticCreado = action.analytics.entities.analytics;
      return Object.assign({}, state, {
        analytics: mergeWith(
          clone(datosAnalyticCreado),
          state.analytics,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_ANALYTIC:
      let datoAnalyticActualizado = action.analytic.entities.analytics;
      return Object.assign({}, state, {
        analytics: mergeWith(
          clone(datoAnalyticActualizado),
          state.analytics,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_ANALYTICS:
      let datosAnalyticActualizado = action.analytics.entities.analytics;
      return Object.assign({}, state, {
        analytics: mergeWith(
          clone(datosAnalyticActualizado),
          state.analytics,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        analytics: {}
      });
    default:
      return state;
  }
}

function allAnalytics(state = [], action) {
  switch (action.type) {
    case RECEIVE_ANALYTICS:
      return action.analytics.result && action.analytics.result.analytics
        ? union(action.analytics.result.analytics, state)
        : action.analytics.result
        ? action.analytics.result
        : state;
    case RECEIVE_ANALYTIC:
      return action.analytic.result
        ? union([action.analytic.result], state)
        : state;

    case SUCCESS_CREATE_ANALYTIC:
      let datoAnalyticSCreate = action.analytic.entities.analytics;
      let idNuevoSCreate = null;
      if (Object.values(datoAnalyticSCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoAnalyticSCreate)[0] &&
          Object.values(datoAnalyticSCreate)[0].id
            ? Object.values(datoAnalyticSCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_ANALYTICS:
      let analyticsCreate =
        action.analytics.entities && action.analytics.entities.analytics
          ? action.analytics.entities.analytics
          : null;
      return analyticsCreate
        ? union(
            state,
            Object.values(analyticsCreate).map(analytics => {
              return analytics.id;
            })
          )
        : state;
    case RESET_ANALYTICS:
      return [];

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalAnalytics(state = null, action) {
  switch (action.type) {
    case RECEIVE_ANALYTICS:
      return action.analytics && action.analytics.result.total
        ? action.analytics.result.total
        : 0;
    case RESET_ANALYTICS:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_ANALYTIC:
      let dato = action.analytic.entities.analytics;
      let analytic =
        dato && Object.keys(dato).length > 0
          ? dato[action.analytic.result]
          : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: analytic ? analytic : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_ANALYTIC:
      let idsUpdate = [];
      Object.values(action.analytic).map(analyticUpdate => {
        if (analyticUpdate && analyticUpdate.id)
          idsUpdate.push(analyticUpdate.id);
      });
      return merge({}, state, {
        activo: action.analytic,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_ANALYTIC:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_ANALYTIC:
      let datoAnalyticActualizado = action.analytic.entities.analytics;
      let analyticNuevo =
        datoAnalyticActualizado &&
        Object.keys(datoAnalyticActualizado).length > 0
          ? datoAnalyticActualizado[action.analytic.result]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: analyticNuevo
      });
    case ERROR_UPDATE_ANALYTIC:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_ANALYTICS:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_ANALYTICS:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_ANALYTICS:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_ANALYTIC:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    case DELETE_ANALYTIC:
      let datoAnalyticDelete = action.analytic;
      let idsDelete = [];
      Object.values(action.analytic).map(analyticDelete => {
        if (analyticDelete && analyticDelete.id)
          idsDelete.push(analyticDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(clone(state.activo), Object.keys(datoAnalyticDelete)),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_ANALYTIC:
      let datoAnalyticDeleteUpdate = action.analytic;
      let idsDeleteUpdate = [];
      Object.values(action.analytic).map(analyticDelete => {
        if (analyticDelete && analyticDelete.id)
          idsDeleteUpdate.push(analyticDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoAnalyticDeleteUpdate)
          ),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_ANALYTIC:
      let datoAnalyticDeleted = {};
      if (Object.values(action.analytic.entities.analytics).length > 0)
        datoAnalyticDeleted = Object.values(
          action.analytic.entities.analytics
        )[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoAnalyticDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_ANALYTIC:
      let idsCreate = [];
      Object.values(action.analytic).map(analyticCreate => {
        if (analyticCreate && analyticCreate.id)
          idsCreate.push(analyticCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.analytic,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_ANALYTIC:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_ANALYTIC:
      let datoAnalyticNuevo = action.analytic.entities.analytics;
      let analyticNuevo =
        datoAnalyticNuevo && Object.keys(datoAnalyticNuevo).length > 0
          ? datoAnalyticNuevo[action.analytic.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: analyticNuevo,
        nuevos: []
      });
    case ERROR_CREATE_ANALYTIC:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_ANALYTICS:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_ANALYTICS:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_ANALYTICS:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_ANALYTIC:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    case DELETE_ANALYTIC:
      let datoAnalyticDelete = action.analytic;
      let idsDelete = [];
      Object.values(action.analytic).map(analyticDelete => {
        if (analyticDelete && analyticDelete.id)
          idsDelete.push(analyticDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoAnalyticDelete)),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_ANALYTIC:
      let datoAnalyticDeleteCreate = action.analytic;
      let idsDeleteCreate = [];
      Object.values(action.analytic).map(analyticDelete => {
        if (analyticDelete && analyticDelete.id)
          idsDeleteCreate.push(analyticDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoAnalyticDeleteCreate)
          ),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_ANALYTIC:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.analytic,
        error: null
      });
    case REQUEST_DELETE_ANALYTIC:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_ANALYTIC:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_ANALYTIC:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_ANALYTIC:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_ANALYTIC:
      let idsCreate = [];
      Object.values(action.analytic).map(analyticCreate => {
        if (analyticCreate && analyticCreate.id)
          idsCreate.push(analyticCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.analytic,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_ANALYTIC:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_ANALYTIC:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_ANALYTIC:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_ANALYTICS:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_ANALYTICS:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_ANALYTICS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_ANALYTIC:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_ANALYTIC:
      let datoAnalyticDeleteCreate = action.analytic;
      let idsDeleteCreate = [];
      Object.values(action.analytic).map(analyticDelete => {
        if (analyticDelete && analyticDelete.id)
          idsDeleteCreate.push(analyticDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(
            clone(state.print),
            Object.keys(datoAnalyticDeleteCreate)
          ),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const analytics = combineReducers({
  byId: analyticsById,
  allIds: allAnalytics,
  update: update,
  create: create,
  totalAnalytics: totalAnalytics,
  delete: deleter,
  print: print
});

export default analytics;
