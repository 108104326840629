import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

//Components
import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import { ColorPicker } from 'material-ui-color';
//Icons
import PublishIcon from '@material-ui/icons/Publish';
//Actions
//constants
//Hooks
import Button from '@material-ui/core/Button';
import { createTenant, updateTenant } from '../../actions/TenantActions';
import FormHelperText from '@material-ui/core/FormHelperText';
import CustomPaper from '../../library/CustomPaper/CustomPaper';
import PaperBody from '../../library/CustomPaper/PaperBody';
import tenantUtil from '../../utils/tenantUtil';
import c from '../../constants/Constants';
//Reset

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: '15px',
    [theme.breakpoints.down('sm')]: {
      padding: '5px 5px'
    },
    '& input': {
      width: '500px'
    }
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  tarjeta: {
    padding: '10px 20px 20px 20px',
    marginBottom: '17px'
  },
  mensaje: {
    display: 'none',
    color: theme.palette.error.main,
    '&.visible': {
      display: 'inherit'
    }
  },
  colorPicker: {
    '& button': {
      borderRadius: '100%',
      width: '40px',
      height: '40px',
      margin: '0px',
      marginRight: '10px'
    },
    '& div': {
      borderRadius: '100%',
      height: '40px',
      width: '50px'
    }
  },
  instructions: {
    marginTop: theme.spacing(6)
  }
}));

export default function CRUDTenant(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  let file = React.createRef();
  let file1 = React.createRef();
  let file2 = React.createRef();
  const { setFiles, validador, validar, msjImagen, disabled } = props;

  //State
  const [previews, setPreviews] = useState([]);

  //Store
  const tenants = useSelector(state => state.tenants);
  const tenant = tenantUtil.getTenant()
    ? tenants.update.activo
    : tenants.create.nuevo;

  useEffect(() => {
    if (tenant?.id && !tenant.primaryColor) {
      let cambio = {
        primaryColor: '#865DFF',
        secondaryColor: '#E384FF'
      };
      changeTenant(cambio);
    }
    if (tenant.id) {
      setPreviews([
        c.API_HOST + tenant.path,
        c.API_HOST + tenant.path1,
        c.API_HOST + tenant.path2
      ]);
    }
  }, [tenant.id]);

  //Tenant
  const handleChangeTenant = e => {
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    changeTenant(cambio);
    validar(e);
  };

  const handleChangeTenantColorPrincipal = e => {
    let cambio = {};
    cambio['primaryColor'] = '#' + e.hex;
    changeTenant(cambio);
  };

  const handleChangeTenantColorSecundario = e => {
    let cambio = {};
    cambio['secondaryColor'] = '#' + e.hex;
    changeTenant(cambio);
  };

  const changeTenant = cambio => {
    if (tenantUtil.getTenant()) dispatch(updateTenant(cambio));
    else dispatch(createTenant(cambio));
  };

  const onClickBotonPrimario = () => {
    let boton = document.querySelectorAll('[data-testid="colorpicker-button"]');
    if (boton && boton.length == 2) boton[0].click();
  };

  const onClickBotonSecundario = () => {
    let boton = document.querySelectorAll('[data-testid="colorpicker-button"]');
    if (boton && boton.length == 2) boton[1].click();
  };

  const verImagen = (e, indexFile) => {
    e.preventDefault();
    let file = e.target;
    //Preview
    for (let i = 0; i < file.files.length; i++) {
      let filename = file.files[i].name.replace(/[^a-zA-Z0-9]/g, '_');
      Object.defineProperty(file.files[i], 'name', {
        writable: true,
        value: filename
      });
      setFiles(files => {
        let _files = [...files];
        _files[indexFile] = file.files[i];
        return _files;
      });
      setPreviews(previews => {
        let _previews = [...previews];
        _previews[indexFile] = 'loading';
        return _previews;
      });
    }

    //Cargar foto
    for (let i = 0; i < file.files.length; i++) {
      let fileSubido = file.files[i];
      let filename = fileSubido.name.replace(/[^a-zA-Z0-9]/g, '_');
      let cambio = {};
      if (indexFile === 0) cambio['originalFilename'] = filename;
      else cambio['originalFilename' + indexFile] = filename;
      changeTenant(cambio);
      let reader = new FileReader();
      reader.onloadend = function () {
        setPreviews(previews => {
          let _previews = [...previews];
          _previews[indexFile] = reader.result;
          return _previews;
        });
      };
      if (fileSubido) {
        reader.readAsDataURL(fileSubido);
      }
    }
  };

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item md={12} sm={12} xs={12} spacing={1}>
        <CustomPaper>
          <PaperBody>
            <Grid
              container
              spacing={1}
              style={{ marginTop: '10px', marginBottom: '10px' }}
            >
              <Grid item md={12} sm={12} xs={12}>
                <Typography variant={'overline'} component={'span'}>
                  ¿Cómo se llama tu inmobiliaria?
                </Typography>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  id="name"
                  label="Nombre"
                  placeholder="Nombre"
                  fullWidth={true}
                  margin="dense"
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                  InputProps={{}}
                  value={tenant && tenant.name ? tenant.name : ''}
                  onChange={handleChangeTenant}
                  onBlur={validar}
                  error={!validador.isValidById('name')}
                  helperText={validador.getHelperTextById('name')}
                  disabled={disabled}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Typography
                  variant={'overline'}
                  component={'span'}
                  style={{ marginTop: '15px' }}
                >
                  Logo de la empresa
                </Typography>
                <FormHelperText
                  ref={msjImagen}
                  style={{ display: 'none', color: 'red' }}
                >
                  Debe completar la imagen de la empresa.
                </FormHelperText>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Box display={'flex'} marginTop={'10px'}>
                  {previews[0] === null && (
                    <Avatar style={{ marginRight: '10px' }}>
                      <PublishIcon />
                    </Avatar>
                  )}
                  {previews[0] !== null && previews[0] !== 'loading' && (
                    <Avatar style={{ marginRight: '10px' }} src={previews[0]} />
                  )}
                  {previews[0] === 'loading' && <CircularProgress />}
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={disabled}
                    onClick={() => file.current.click()}
                  >
                    Subir logo
                  </Button>
                </Box>
                <FormHelperText>Logo para fondos claros.</FormHelperText>
                <input
                  type="file"
                  ref={file}
                  style={{ display: 'none' }}
                  accept="image/png, image/jpeg"
                  multiple={false}
                  disabled={props.disabled}
                  className="agregarFoto"
                  onChange={e => verImagen(e, 0)}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Box display={'flex'} marginTop={'10px'}>
                  {previews[1] === null && (
                    <Avatar style={{ marginRight: '10px' }}>
                      <PublishIcon />
                    </Avatar>
                  )}
                  {previews[1] !== null && previews[1] !== 'loading' && (
                    <Avatar
                      style={{ marginRight: '10px', backgroundColor: 'black' }}
                      src={previews[1]}
                    />
                  )}
                  {previews[1] === 'loading' && <CircularProgress />}
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={disabled}
                    onClick={() => file1.current.click()}
                  >
                    Subir logo negativo
                  </Button>
                </Box>
                <FormHelperText>Logo para fondos oscuros.</FormHelperText>
                <input
                  type="file"
                  ref={file1}
                  style={{ display: 'none' }}
                  accept="image/png, image/jpeg"
                  multiple={false}
                  disabled={props.disabled}
                  className="agregarFoto"
                  onChange={e => verImagen(e, 1)}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Box display={'flex'} marginTop={'10px'}>
                  {previews[2] === null && (
                    <Avatar style={{ marginRight: '10px' }}>
                      <PublishIcon />
                    </Avatar>
                  )}
                  {previews[2] !== null && previews[2] !== 'loading' && (
                    <Avatar style={{ marginRight: '10px' }} src={previews[2]} />
                  )}
                  {previews[2] === 'loading' && <CircularProgress />}
                  <Button
                    variant="outlined"
                    color="primary"
                    disabled={disabled}
                    onClick={() => file2.current.click()}
                  >
                    Subir favicon
                  </Button>
                </Box>
                <FormHelperText>
                  Icono para la pestaña de los navegadores.
                </FormHelperText>
                <input
                  type="file"
                  ref={file2}
                  style={{ display: 'none' }}
                  accept="image/png"
                  multiple={false}
                  disabled={props.disabled}
                  className="agregarFoto"
                  onChange={e => verImagen(e, 2)}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Box display={'flex'} marginTop={'10px'}>
                  <div className={classes.colorPicker}>
                    <ColorPicker
                      hideTextfield
                      id={'primaryColor'}
                      onChange={handleChangeTenantColorPrincipal}
                      value={
                        tenant && tenant.primaryColor ? tenant.primaryColor : ''
                      }
                      disabled={disabled}
                    />
                  </div>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onClickBotonPrimario}
                    disabled={disabled}
                  >
                    Elegir color primario
                  </Button>
                </Box>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Box display={'flex'} marginTop={'10px'}>
                  <div className={classes.colorPicker}>
                    <ColorPicker
                      hideTextfield
                      onChange={handleChangeTenantColorSecundario}
                      value={
                        tenant && tenant.secondaryColor
                          ? tenant.secondaryColor
                          : ''
                      }
                      disabled={disabled}
                    />
                  </div>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onClickBotonSecundario}
                    disabled={disabled}
                  >
                    Elegir color secundario
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </PaperBody>
        </CustomPaper>
      </Grid>
    </Grid>
  );
}
