import {
  resetCreateProperty,
  resetDeleteProperty,
  resetProperties,
  resetUpdateProperty
} from '../actions/PropertyActions';
import {
  resetAssets,
  resetCreateAsset,
  resetDeleteAsset,
  resetUpdateAsset
} from '../actions/AssetActions';
import {
  resetCreatePropertyClosedOperation,
  resetDeletePropertyClosedOperation,
  resetPropertyClosedOperations,
  resetUpdatePropertyClosedOperation
} from '../actions/PropertyClosedOperationActions';
import {
  resetCreateTicket,
  resetDeleteTicket,
  resetTickets,
  resetUpdateTicket
} from '../actions/TicketActions';
import {
  resetCreatePropertyOperation,
  resetDeletePropertyOperation,
  resetPropertyOperations,
  resetUpdatePropertyOperation
} from '../actions/PropertyOperationActions';
import {
  resetCreatePropertyAttributeValue,
  resetDeletePropertyAttributeValue,
  resetPropertiesAttributesValues,
  resetUpdatePropertyAttributeValue
} from '../actions/PropertyAttributeValueActions';

var resetProperty = {
  resetAll(dispatch) {
    dispatch(resetProperties());
    dispatch(resetCreateProperty());
    dispatch(resetUpdateProperty());
    dispatch(resetDeleteProperty());
    dispatch(resetAssets());
    dispatch(resetCreateAsset());
    dispatch(resetUpdateAsset());
    dispatch(resetDeleteAsset());
    dispatch(resetPropertyClosedOperations());
    dispatch(resetCreatePropertyClosedOperation());
    dispatch(resetUpdatePropertyClosedOperation());
    dispatch(resetDeletePropertyClosedOperation());
    dispatch(resetTickets());
    dispatch(resetCreateTicket());
    dispatch(resetUpdateTicket());
    dispatch(resetDeleteTicket());
    dispatch(resetPropertyOperations());
    dispatch(resetCreatePropertyOperation());
    dispatch(resetUpdatePropertyOperation());
    dispatch(resetDeletePropertyOperation());
    dispatch(resetPropertiesAttributesValues());
    dispatch(resetCreatePropertyAttributeValue());
    dispatch(resetUpdatePropertyAttributeValue());
    dispatch(resetDeletePropertyAttributeValue());
    dispatch(resetAssets());
    dispatch(resetCreateAsset());
    dispatch(resetUpdateAsset());
    dispatch(resetDeleteAsset());
    dispatch(resetPropertyClosedOperations());
    dispatch(resetCreatePropertyClosedOperation());
    dispatch(resetUpdatePropertyClosedOperation());
    dispatch(resetDeletePropertyClosedOperation());
  }
};

export default resetProperty;
