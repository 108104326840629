import {
  INVALIDATE_TENANTS,
  ERROR_TENANTS,
  RECEIVE_TENANTS,
  REQUEST_TENANTS,
  RESET_TENANTS,
  ERROR_TENANT,
  RECEIVE_TENANT,
  REQUEST_TENANT,
  UPDATE_TENANT,
  REQUEST_UPDATE_TENANT,
  SUCCESS_UPDATE_TENANT,
  ERROR_UPDATE_TENANT,
  RESET_UPDATE_TENANT,
  REQUEST_UPDATE_TENANTS,
  SUCCESS_UPDATE_TENANTS,
  ERROR_UPDATE_TENANTS,
  RESET_UPDATE_TENANTS,
  CREATE_TENANT,
  ERROR_CREATE_TENANT,
  REQUEST_CREATE_TENANT,
  RESET_CREATE_TENANT,
  SUCCESS_CREATE_TENANT,
  REQUEST_CREATE_TENANTS,
  SUCCESS_CREATE_TENANTS,
  ERROR_CREATE_TENANTS,
  RESET_CREATE_TENANTS,
  DELETE_TENANT,
  DELETE_CREATE_TENANT,
  DELETE_UPDATE_TENANT,
  REQUEST_DELETE_TENANT,
  SUCCESS_DELETE_TENANT,
  ERROR_DELETE_TENANT,
  RESET_DELETE_TENANT,
  REQUEST_PRINT_TENANT,
  SUCCESS_PRINT_TENANT,
  ERROR_PRINT_TENANT,
  RESET_PRINT_TENANT,
  RECEIVE_FILE_TENANT,
  REQUEST_PRINT_TENANTS,
  SUCCESS_PRINT_TENANTS,
  ERROR_PRINT_TENANTS,
  RESET_PRINT_TENANTS,
  RECEIVE_FILE_TENANTS,
  PRINT_TENANT,
  DELETE_PRINT_TENANT
} from '../actions/TenantActions';
import { CREATE_USER, UPDATE_USER, DELETE_USER } from '../actions/UserActions';
import {
  CREATE_DEPENDENCY,
  UPDATE_DEPENDENCY,
  DELETE_DEPENDENCY
} from '../actions/DependencyActions';
import {
  CREATE_DOMAIN,
  UPDATE_DOMAIN,
  DELETE_DOMAIN
} from '../actions/DomainActions';

import {
  RECEIVE_TENANTUSERACCESS,
  RECEIVE_TENANTUSERACCESSES,
  SUCCESS_DELETE_TENANTUSERACCESS,
  SUCCESS_CREATE_TENANTUSERACCESS,
  SUCCESS_UPDATE_TENANTUSERACCESS,
  SUCCESS_UPDATE_TENANTUSERACCESSES
} from '../actions/TenantUserAccessActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    tenants: {},
    files: {}
  };
}

function tenantsById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_TENANTS:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_TENANTS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_TENANTS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_TENANTS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        tenants: {}
      });
    case RECEIVE_TENANTS:
      let dato = action.tenants.entities.tenants;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        tenants: merge({}, state.tenants, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_TENANT:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_TENANT:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_TENANT:
      let datoTenant = action.tenant.entities.tenants;
      return Object.assign({}, state, {
        tenants: merge({}, state.tenants, datoTenant),
        isFetching: false
      });
    case RECEIVE_FILE_TENANT:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_TENANT:
      let datoTenantEliminado = action.tenant.entities.tenants;
      return Object.assign({}, state, {
        tenants: mergeWith(
          clone(datoTenantEliminado),
          state.tenants,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_TENANT:
      let datoTenantCreado = action.tenant.entities.tenants;
      return Object.assign({}, state, {
        tenants: mergeWith(
          clone(datoTenantCreado),
          state.tenants,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_TENANTS:
      let datosTenantCreado = action.tenants.entities.tenants;
      return Object.assign({}, state, {
        tenants: mergeWith(
          clone(datosTenantCreado),
          state.tenants,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TENANT:
      let datoTenantActualizado = action.tenant.entities.tenants;
      return Object.assign({}, state, {
        tenants: mergeWith(
          clone(datoTenantActualizado),
          state.tenants,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TENANTS:
      let datosTenantActualizado = action.tenants.entities.tenants;
      return Object.assign({}, state, {
        tenants: mergeWith(
          clone(datosTenantActualizado),
          state.tenants,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    //TENANTUSERACCESS
    case RECEIVE_TENANTUSERACCESS:
      let tenantUserAccess =
        action.tenantUserAccess.entities &&
        action.tenantUserAccess.entities.tenants
          ? action.tenantUserAccess.entities.tenants
          : {};
      return Object.assign({}, state, {
        tenants: merge({}, state.tenants, tenantUserAccess)
      });
    case RECEIVE_TENANTUSERACCESSES:
      let tenantUserAccesses =
        action.tenantUserAccesses.entities &&
        action.tenantUserAccesses.entities.tenants
          ? action.tenantUserAccesses.entities.tenants
          : {};
      return Object.assign({}, state, {
        tenants: merge({}, state.tenants, tenantUserAccesses)
      });
    case SUCCESS_DELETE_TENANTUSERACCESS:
      let datotenantUserAccessEliminado =
        action.tenantUserAccess.entities &&
        action.tenantUserAccess.entities.tenants
          ? action.tenantUserAccess.entities.tenants
          : {};
      return Object.assign({}, state, {
        tenants: mergeWith(
          clone(datotenantUserAccessEliminado),
          pickBy(state.tenants, function (tenant) {
            return tenant.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_TENANTUSERACCESS:
      let datotenantUserAccessCreado =
        action.tenantUserAccess.entities &&
        action.tenantUserAccess.entities.tenants
          ? action.tenantUserAccess.entities.tenants
          : {};
      return Object.assign({}, state, {
        tenants: mergeWith(
          clone(datotenantUserAccessCreado),
          pickBy(state.tenants, function (tenant) {
            return tenant.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TENANTUSERACCESS:
      let datotenantUserAccessActualizado =
        action.tenantUserAccess.entities &&
        action.tenantUserAccess.entities.tenants
          ? action.tenantUserAccess.entities.tenants
          : {};
      return Object.assign({}, state, {
        tenants: mergeWith(
          clone(datotenantUserAccessActualizado),
          pickBy(state.tenants, function (tenant) {
            return tenant.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TENANTUSERACCESSES:
      let datostenantUserAccessActualizado =
        action.tenantUserAccesses.entities &&
        action.tenantUserAccesses.entities.tenants
          ? action.tenantUserAccesses.entities.tenants
          : {};
      return Object.assign({}, state, {
        tenants: mergeWith(
          clone(datostenantUserAccessActualizado),
          state.tenants,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        tenants: {}
      });
    default:
      return state;
  }
}

function allTenants(state = [], action) {
  switch (action.type) {
    case RECEIVE_TENANTS:
      return action.tenants.result && action.tenants.result.tenants
        ? union(action.tenants.result.tenants, state)
        : action.tenants.result
        ? action.tenants.result
        : state;
    case RECEIVE_TENANT:
      return action.tenant.result
        ? union([action.tenant.result], state)
        : state;

    case SUCCESS_CREATE_TENANT:
      let datoTenantSCreate = action.tenant.entities.tenants;
      let idNuevoSCreate = null;
      if (Object.values(datoTenantSCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoTenantSCreate)[0] &&
          Object.values(datoTenantSCreate)[0].id
            ? Object.values(datoTenantSCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_TENANTS:
      let tenantsCreate =
        action.tenants.entities && action.tenants.entities.tenants
          ? action.tenants.entities.tenants
          : null;
      return tenantsCreate
        ? union(
            state,
            Object.values(tenantsCreate).map(tenants => {
              return tenants.id;
            })
          )
        : state;
    case RESET_TENANTS:
      return [];

    case RECEIVE_TENANTUSERACCESS:
      let tenantUserAccess =
        action.tenantUserAccess.entities &&
        action.tenantUserAccess.entities.tenants
          ? action.tenantUserAccess.entities.tenants
          : null;
      return tenantUserAccess
        ? union(
            state,
            Object.values(tenantUserAccess).map(tenantUserAccess => {
              return tenantUserAccess.id;
            })
          )
        : state;
    case RECEIVE_TENANTUSERACCESSES:
      let tenantUserAccesses =
        action.tenantUserAccesses.entities &&
        action.tenantUserAccesses.entities.tenants
          ? action.tenantUserAccesses.entities.tenants
          : null;
      return tenantUserAccesses
        ? union(
            state,
            Object.values(tenantUserAccesses).map(tenantUserAccesses => {
              return tenantUserAccesses.id;
            })
          )
        : state;

    case SUCCESS_DELETE_TENANTUSERACCESS:
      let tenantUserAccessDelete =
        action.tenantUserAccess.entities &&
        action.tenantUserAccess.entities.tenants
          ? action.tenantUserAccess.entities.tenants
          : null;
      return tenantUserAccessDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(tenantUserAccessDelete).map(tenantUserAccess => {
              return tenantUserAccess.id;
            })
          )
        : state;
    case SUCCESS_CREATE_TENANTUSERACCESS:
      let tenantUserAccessCreate =
        action.tenantUserAccess.entities &&
        action.tenantUserAccess.entities.tenants
          ? action.tenantUserAccess.entities.tenants
          : null;
      return tenantUserAccessCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(tenantUserAccessCreate).map(tenantUserAccess => {
              return tenantUserAccess.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_TENANTUSERACCESS:
      let tenantUserAccessUpdate =
        action.tenantUserAccess.entities &&
        action.tenantUserAccess.entities.tenants
          ? action.tenantUserAccess.entities.tenants
          : null;
      return tenantUserAccessUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(tenantUserAccessUpdate).map(tenantUserAccess => {
              return tenantUserAccess.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_TENANTUSERACCESSES:
      let tenantUserAccessesUpdate =
        action.tenantUserAccesses.entities &&
        action.tenantUserAccesses.entities.tenants
          ? action.tenantUserAccesses.entities.tenants
          : null;
      return tenantUserAccessesUpdate
        ? union(
            state,
            Object.values(tenantUserAccessesUpdate).map(tenantUserAccesses => {
              return tenantUserAccesses.id;
            })
          )
        : state;

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalTenants(state = null, action) {
  switch (action.type) {
    case RECEIVE_TENANTS:
      return action.tenants && action.tenants.result.total
        ? action.tenants.result.total
        : 0;
    case RESET_TENANTS:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_TENANT:
      let dato = action.tenant.entities.tenants;
      let tenant =
        dato && Object.keys(dato).length > 0 ? dato[action.tenant.result] : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: tenant ? tenant : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_TENANT:
      let idsUpdate = [];
      Object.values(action.tenant).map(tenantUpdate => {
        if (tenantUpdate && tenantUpdate.id) idsUpdate.push(tenantUpdate.id);
      });
      return merge({}, state, {
        activo: action.tenant,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_TENANT:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_TENANT:
      let datoTenantActualizado = action.tenant.entities.tenants;
      let tenantNuevo =
        datoTenantActualizado && Object.keys(datoTenantActualizado).length > 0
          ? datoTenantActualizado[action.tenant.result]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: tenantNuevo
      });
    case ERROR_UPDATE_TENANT:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_TENANTS:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_TENANTS:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_TENANTS:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_TENANT:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    case CREATE_USER:
      let tenantuserCreateActivo = clone(state.activo);
      let tenantuserCreateActivos = clone(state.activos);
      Object.values(action.user).map(userCreate => {
        if (
          userCreate &&
          userCreate.tenant_id &&
          tenantuserCreateActivo[userCreate.tenant_id]
        ) {
          if (userCreate.tenant_id.toString().indexOf('-') === -1)
            tenantuserCreateActivo[userCreate.tenant_id].user = union(
              tenantuserCreateActivo.user,
              [userCreate.id]
            );
        } else if (userCreate) {
          tenantuserCreateActivo.user = union(
            tenantuserCreateActivo.user ? tenantuserCreateActivo.user : [],
            [userCreate.id]
          );
        }
        if (
          userCreate &&
          userCreate.tenant_id &&
          userCreate.tenant_id.toString().indexOf('-') === -1
        )
          tenantuserCreateActivos = union(tenantuserCreateActivos, [
            userCreate.tenant_id
          ]);
      });
      return Object.assign({}, state, {
        activo: tenantuserCreateActivo,
        activos: tenantuserCreateActivos
      });
    case UPDATE_USER:
      let tenantuserUpdateActivo = clone(state.activo);
      let tenantuserUpdateActivos = clone(state.activos);
      Object.values(action.user).map(userUpdate => {
        if (
          userUpdate &&
          userUpdate.tenant_id &&
          tenantuserUpdateActivo[userUpdate.tenant_id]
        ) {
          if (userUpdate.tenant_id.toString().indexOf('-') === -1)
            tenantuserUpdateActivo[userUpdate.tenant_id].user = union(
              tenantuserUpdateActivo.user,
              [userUpdate.id]
            );
        } else if (userUpdate) {
          tenantuserUpdateActivo.user = union(
            tenantuserUpdateActivo.user ? tenantuserUpdateActivo.user : [],
            [userUpdate.id]
          );
        }
        if (
          userUpdate &&
          userUpdate.tenant_id &&
          userUpdate.tenant_id.toString().indexOf('-') === -1
        )
          tenantuserUpdateActivos = union(tenantuserUpdateActivos, [
            userUpdate.tenant_id
          ]);
      });
      return Object.assign({}, state, {
        activo: tenantuserUpdateActivo,
        activos: tenantuserUpdateActivos
      });
    case DELETE_USER:
      let tenantuserDeleteActivo = clone(state.activo);
      let tenantuserDeleteActivos = clone(state.activos);
      Object.values(action.user).map(userDelete => {
        if (
          userDelete &&
          userDelete.tenant_id &&
          tenantuserDeleteActivo[userDelete.tenant_id]
        ) {
          if (userDelete.tenant_id.toString().indexOf('-') === -1)
            tenantuserDeleteActivo[userDelete.tenant_id].user = difference(
              tenantuserDeleteActivo.user,
              [userDelete.id]
            );
        } else if (userDelete) {
          tenantuserDeleteActivo.user = difference(
            tenantuserDeleteActivo.user ? tenantuserDeleteActivo.user : [],
            [userDelete.id]
          );
        }
        if (
          userDelete &&
          userDelete.tenant_id &&
          userDelete.tenant_id.toString().indexOf('-') === -1
        )
          tenantuserDeleteActivos = union(tenantuserDeleteActivos, [
            userDelete.tenant_id
          ]);
      });
      return Object.assign({}, state, {
        activo: tenantuserDeleteActivo,
        activos: tenantuserDeleteActivos
      });
    case CREATE_DEPENDENCY:
      let tenantdependencyCreateActivo = clone(state.activo);
      let tenantdependencyCreateActivos = clone(state.activos);
      Object.values(action.dependency).map(dependencyCreate => {
        if (
          dependencyCreate &&
          dependencyCreate.tenant_id &&
          tenantdependencyCreateActivo[dependencyCreate.tenant_id]
        ) {
          if (dependencyCreate.tenant_id.toString().indexOf('-') === -1)
            tenantdependencyCreateActivo[
              dependencyCreate.tenant_id
            ].dependency = union(tenantdependencyCreateActivo.dependency, [
              dependencyCreate.id
            ]);
        } else if (dependencyCreate) {
          tenantdependencyCreateActivo.dependency = union(
            tenantdependencyCreateActivo.dependency
              ? tenantdependencyCreateActivo.dependency
              : [],
            [dependencyCreate.id]
          );
        }
        if (
          dependencyCreate &&
          dependencyCreate.tenant_id &&
          dependencyCreate.tenant_id.toString().indexOf('-') === -1
        )
          tenantdependencyCreateActivos = union(tenantdependencyCreateActivos, [
            dependencyCreate.tenant_id
          ]);
      });
      return Object.assign({}, state, {
        activo: tenantdependencyCreateActivo,
        activos: tenantdependencyCreateActivos
      });
    case UPDATE_DEPENDENCY:
      let tenantdependencyUpdateActivo = clone(state.activo);
      let tenantdependencyUpdateActivos = clone(state.activos);
      Object.values(action.dependency).map(dependencyUpdate => {
        if (
          dependencyUpdate &&
          dependencyUpdate.tenant_id &&
          tenantdependencyUpdateActivo[dependencyUpdate.tenant_id]
        ) {
          if (dependencyUpdate.tenant_id.toString().indexOf('-') === -1)
            tenantdependencyUpdateActivo[
              dependencyUpdate.tenant_id
            ].dependency = union(tenantdependencyUpdateActivo.dependency, [
              dependencyUpdate.id
            ]);
        } else if (dependencyUpdate) {
          tenantdependencyUpdateActivo.dependency = union(
            tenantdependencyUpdateActivo.dependency
              ? tenantdependencyUpdateActivo.dependency
              : [],
            [dependencyUpdate.id]
          );
        }
        if (
          dependencyUpdate &&
          dependencyUpdate.tenant_id &&
          dependencyUpdate.tenant_id.toString().indexOf('-') === -1
        )
          tenantdependencyUpdateActivos = union(tenantdependencyUpdateActivos, [
            dependencyUpdate.tenant_id
          ]);
      });
      return Object.assign({}, state, {
        activo: tenantdependencyUpdateActivo,
        activos: tenantdependencyUpdateActivos
      });
    case DELETE_DEPENDENCY:
      let tenantdependencyDeleteActivo = clone(state.activo);
      let tenantdependencyDeleteActivos = clone(state.activos);
      Object.values(action.dependency).map(dependencyDelete => {
        if (
          dependencyDelete &&
          dependencyDelete.tenant_id &&
          tenantdependencyDeleteActivo[dependencyDelete.tenant_id]
        ) {
          if (dependencyDelete.tenant_id.toString().indexOf('-') === -1)
            tenantdependencyDeleteActivo[
              dependencyDelete.tenant_id
            ].dependency = difference(tenantdependencyDeleteActivo.dependency, [
              dependencyDelete.id
            ]);
        } else if (dependencyDelete) {
          tenantdependencyDeleteActivo.dependency = difference(
            tenantdependencyDeleteActivo.dependency
              ? tenantdependencyDeleteActivo.dependency
              : [],
            [dependencyDelete.id]
          );
        }
        if (
          dependencyDelete &&
          dependencyDelete.tenant_id &&
          dependencyDelete.tenant_id.toString().indexOf('-') === -1
        )
          tenantdependencyDeleteActivos = union(tenantdependencyDeleteActivos, [
            dependencyDelete.tenant_id
          ]);
      });
      return Object.assign({}, state, {
        activo: tenantdependencyDeleteActivo,
        activos: tenantdependencyDeleteActivos
      });
    case CREATE_DOMAIN:
      let tenantdomainCreateActivo = clone(state.activo);
      let tenantdomainCreateActivos = clone(state.activos);
      Object.values(action.domain).map(domainCreate => {
        if (
          domainCreate &&
          domainCreate.tenant_id &&
          tenantdomainCreateActivo[domainCreate.tenant_id]
        ) {
          if (domainCreate.tenant_id.toString().indexOf('-') === -1)
            tenantdomainCreateActivo[domainCreate.tenant_id].domains = union(
              tenantdomainCreateActivo.domains,
              [domainCreate.id]
            );
        } else if (domainCreate) {
          tenantdomainCreateActivo.domains = union(
            tenantdomainCreateActivo.domains
              ? tenantdomainCreateActivo.domains
              : [],
            [domainCreate.id]
          );
        }
        if (
          domainCreate &&
          domainCreate.tenant_id &&
          domainCreate.tenant_id.toString().indexOf('-') === -1
        )
          tenantdomainCreateActivos = union(tenantdomainCreateActivos, [
            domainCreate.tenant_id
          ]);
      });
      return Object.assign({}, state, {
        activo: tenantdomainCreateActivo,
        activos: tenantdomainCreateActivos
      });
    case UPDATE_DOMAIN:
      let tenantdomainUpdateActivo = clone(state.activo);
      let tenantdomainUpdateActivos = clone(state.activos);
      Object.values(action.domain).map(domainUpdate => {
        if (
          domainUpdate &&
          domainUpdate.tenant_id &&
          tenantdomainUpdateActivo[domainUpdate.tenant_id]
        ) {
          if (domainUpdate.tenant_id.toString().indexOf('-') === -1)
            tenantdomainUpdateActivo[domainUpdate.tenant_id].domains = union(
              tenantdomainUpdateActivo.domains,
              [domainUpdate.id]
            );
        } else if (domainUpdate) {
          tenantdomainUpdateActivo.domains = union(
            tenantdomainUpdateActivo.domains
              ? tenantdomainUpdateActivo.domains
              : [],
            [domainUpdate.id]
          );
        }
        if (
          domainUpdate &&
          domainUpdate.tenant_id &&
          domainUpdate.tenant_id.toString().indexOf('-') === -1
        )
          tenantdomainUpdateActivos = union(tenantdomainUpdateActivos, [
            domainUpdate.tenant_id
          ]);
      });
      return Object.assign({}, state, {
        activo: tenantdomainUpdateActivo,
        activos: tenantdomainUpdateActivos
      });
    case DELETE_DOMAIN:
      let tenantdomainDeleteActivo = clone(state.activo);
      let tenantdomainDeleteActivos = clone(state.activos);
      Object.values(action.domain).map(domainDelete => {
        if (
          domainDelete &&
          domainDelete.tenant_id &&
          tenantdomainDeleteActivo[domainDelete.tenant_id]
        ) {
          if (domainDelete.tenant_id.toString().indexOf('-') === -1)
            tenantdomainDeleteActivo[domainDelete.tenant_id].domains =
              difference(tenantdomainDeleteActivo.domains, [domainDelete.id]);
        } else if (domainDelete) {
          tenantdomainDeleteActivo.domains = difference(
            tenantdomainDeleteActivo.domains
              ? tenantdomainDeleteActivo.domains
              : [],
            [domainDelete.id]
          );
        }
        if (
          domainDelete &&
          domainDelete.tenant_id &&
          domainDelete.tenant_id.toString().indexOf('-') === -1
        )
          tenantdomainDeleteActivos = union(tenantdomainDeleteActivos, [
            domainDelete.tenant_id
          ]);
      });
      return Object.assign({}, state, {
        activo: tenantdomainDeleteActivo,
        activos: tenantdomainDeleteActivos
      });

    //TENANTUSERACCESS
    //TODO ver si esta bien
    case SUCCESS_CREATE_TENANTUSERACCESS:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_TENANTUSERACCESS:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_TENANTUSERACCESS:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_TENANTUSERACCESSES:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });

    case DELETE_TENANT:
      let datoTenantDelete = action.tenant;
      let idsDelete = [];
      Object.values(action.tenant).map(tenantDelete => {
        if (tenantDelete && tenantDelete.id) idsDelete.push(tenantDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(clone(state.activo), Object.keys(datoTenantDelete)),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_TENANT:
      let datoTenantDeleteUpdate = action.tenant;
      let idsDeleteUpdate = [];
      Object.values(action.tenant).map(tenantDelete => {
        if (tenantDelete && tenantDelete.id)
          idsDeleteUpdate.push(tenantDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoTenantDeleteUpdate)
          ),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_TENANT:
      let datoTenantDeleted = {};
      if (Object.values(action.tenant.entities.tenants).length > 0)
        datoTenantDeleted = Object.values(action.tenant.entities.tenants)[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoTenantDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_TENANT:
      let idsCreate = [];
      Object.values(action.tenant).map(tenantCreate => {
        if (tenantCreate && tenantCreate.id) idsCreate.push(tenantCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.tenant,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_TENANT:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_TENANT:
      let datoTenantNuevo = action.tenant.entities.tenants;
      let tenantNuevo =
        datoTenantNuevo && Object.keys(datoTenantNuevo).length > 0
          ? datoTenantNuevo[action.tenant.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: tenantNuevo,
        nuevos: []
      });
    case ERROR_CREATE_TENANT:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_TENANTS:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_TENANTS:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_TENANTS:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_TENANT:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    //CREATE USER
    case CREATE_USER:
      let tenantuserCreateActivo = clone(state.nuevo);
      Object.values(action.user).map(userCreate => {
        if (
          userCreate &&
          userCreate.tenant_id &&
          tenantuserCreateActivo[userCreate.tenant_id]
        ) {
          if (userCreate.tenant_id.toString().indexOf('-') !== -1)
            tenantuserCreateActivo[userCreate.tenant_id].user = union(
              tenantuserCreateActivo.user,
              [userCreate.id]
            );
        } else if (userCreate) {
          tenantuserCreateActivo.user = union(
            tenantuserCreateActivo.user ? tenantuserCreateActivo.user : [],
            [userCreate.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: tenantuserCreateActivo
        //nuevos: userCreate && userCreate.tenant_id ? union(state.nuevos, [userCreate.tenant_id]) : state.nuevos,
      });
    case UPDATE_USER:
      let tenantuserUpdateActivo = clone(state.nuevo);
      Object.values(action.user).map(userUpdate => {
        if (
          userUpdate &&
          userUpdate.tenant_id &&
          tenantuserUpdateActivo[userUpdate.tenant_id]
        ) {
          if (userUpdate.tenant_id.toString().indexOf('-') !== -1)
            tenantuserUpdateActivo[userUpdate.tenant_id].user = union(
              tenantuserUpdateActivo.user,
              [userUpdate.id]
            );
        } else if (userUpdate) {
          tenantuserUpdateActivo.user = union(
            tenantuserUpdateActivo.user ? tenantuserUpdateActivo.user : [],
            [userUpdate.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: tenantuserUpdateActivo
        //nuevos: userUpdate && userUpdate.tenant_id ? union(state.nuevos, [userUpdate.tenant_id]) : state.nuevos,
      });
    case DELETE_USER:
      let tenantuserDeleteActivo = clone(state.nuevo);
      Object.values(action.user).map(userDelete => {
        if (
          userDelete &&
          userDelete.tenant_id &&
          userDelete.tenant_id &&
          tenantuserDeleteActivo[userDelete.tenant_id]
        ) {
          if (userDelete.tenant_id.toString().indexOf('-') !== -1)
            tenantuserDeleteActivo[userDelete.tenant_id].user = difference(
              tenantuserDeleteActivo.user,
              [userDelete.id]
            );
        } else if (userDelete) {
          tenantuserDeleteActivo.user = difference(
            tenantuserDeleteActivo.user ? tenantuserDeleteActivo.user : [],
            [userDelete.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: tenantuserDeleteActivo
        //nuevos: userDelete && userDelete.tenant_id ? union(state.nuevos, [userDelete.tenant_id]) : state.nuevos,
      }); //CREATE DEPENDENCY
    case CREATE_DEPENDENCY:
      let tenantdependencyCreateActivo = clone(state.nuevo);
      Object.values(action.dependency).map(dependencyCreate => {
        if (
          dependencyCreate &&
          dependencyCreate.tenant_id &&
          tenantdependencyCreateActivo[dependencyCreate.tenant_id]
        ) {
          if (dependencyCreate.tenant_id.toString().indexOf('-') !== -1)
            tenantdependencyCreateActivo[
              dependencyCreate.tenant_id
            ].dependency = union(tenantdependencyCreateActivo.dependency, [
              dependencyCreate.id
            ]);
        } else if (dependencyCreate) {
          tenantdependencyCreateActivo.dependency = union(
            tenantdependencyCreateActivo.dependency
              ? tenantdependencyCreateActivo.dependency
              : [],
            [dependencyCreate.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: tenantdependencyCreateActivo
        //nuevos: dependencyCreate && dependencyCreate.tenant_id ? union(state.nuevos, [dependencyCreate.tenant_id]) : state.nuevos,
      });
    case UPDATE_DEPENDENCY:
      let tenantdependencyUpdateActivo = clone(state.nuevo);
      Object.values(action.dependency).map(dependencyUpdate => {
        if (
          dependencyUpdate &&
          dependencyUpdate.tenant_id &&
          tenantdependencyUpdateActivo[dependencyUpdate.tenant_id]
        ) {
          if (dependencyUpdate.tenant_id.toString().indexOf('-') !== -1)
            tenantdependencyUpdateActivo[
              dependencyUpdate.tenant_id
            ].dependency = union(tenantdependencyUpdateActivo.dependency, [
              dependencyUpdate.id
            ]);
        } else if (dependencyUpdate) {
          tenantdependencyUpdateActivo.dependency = union(
            tenantdependencyUpdateActivo.dependency
              ? tenantdependencyUpdateActivo.dependency
              : [],
            [dependencyUpdate.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: tenantdependencyUpdateActivo
        //nuevos: dependencyUpdate && dependencyUpdate.tenant_id ? union(state.nuevos, [dependencyUpdate.tenant_id]) : state.nuevos,
      });
    case DELETE_DEPENDENCY:
      let tenantdependencyDeleteActivo = clone(state.nuevo);
      Object.values(action.dependency).map(dependencyDelete => {
        if (
          dependencyDelete &&
          dependencyDelete.tenant_id &&
          dependencyDelete.tenant_id &&
          tenantdependencyDeleteActivo[dependencyDelete.tenant_id]
        ) {
          if (dependencyDelete.tenant_id.toString().indexOf('-') !== -1)
            tenantdependencyDeleteActivo[
              dependencyDelete.tenant_id
            ].dependency = difference(tenantdependencyDeleteActivo.dependency, [
              dependencyDelete.id
            ]);
        } else if (dependencyDelete) {
          tenantdependencyDeleteActivo.dependency = difference(
            tenantdependencyDeleteActivo.dependency
              ? tenantdependencyDeleteActivo.dependency
              : [],
            [dependencyDelete.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: tenantdependencyDeleteActivo
        //nuevos: dependencyDelete && dependencyDelete.tenant_id ? union(state.nuevos, [dependencyDelete.tenant_id]) : state.nuevos,
      }); //CREATE DOMAIN
    case CREATE_DOMAIN:
      let tenantdomainCreateActivo = clone(state.nuevo);
      Object.values(action.domain).map(domainCreate => {
        if (
          domainCreate &&
          domainCreate.tenant_id &&
          tenantdomainCreateActivo[domainCreate.tenant_id]
        ) {
          if (domainCreate.tenant_id.toString().indexOf('-') !== -1)
            tenantdomainCreateActivo[domainCreate.tenant_id].domains = union(
              tenantdomainCreateActivo.domains,
              [domainCreate.id]
            );
        } else if (domainCreate) {
          tenantdomainCreateActivo.domains = union(
            tenantdomainCreateActivo.domains
              ? tenantdomainCreateActivo.domains
              : [],
            [domainCreate.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: tenantdomainCreateActivo
        //nuevos: domainCreate && domainCreate.tenant_id ? union(state.nuevos, [domainCreate.tenant_id]) : state.nuevos,
      });
    case UPDATE_DOMAIN:
      let tenantdomainUpdateActivo = clone(state.nuevo);
      Object.values(action.domain).map(domainUpdate => {
        if (
          domainUpdate &&
          domainUpdate.tenant_id &&
          tenantdomainUpdateActivo[domainUpdate.tenant_id]
        ) {
          if (domainUpdate.tenant_id.toString().indexOf('-') !== -1)
            tenantdomainUpdateActivo[domainUpdate.tenant_id].domains = union(
              tenantdomainUpdateActivo.domains,
              [domainUpdate.id]
            );
        } else if (domainUpdate) {
          tenantdomainUpdateActivo.domains = union(
            tenantdomainUpdateActivo.domains
              ? tenantdomainUpdateActivo.domains
              : [],
            [domainUpdate.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: tenantdomainUpdateActivo
        //nuevos: domainUpdate && domainUpdate.tenant_id ? union(state.nuevos, [domainUpdate.tenant_id]) : state.nuevos,
      });
    case DELETE_DOMAIN:
      let tenantdomainDeleteActivo = clone(state.nuevo);
      Object.values(action.domain).map(domainDelete => {
        if (
          domainDelete &&
          domainDelete.tenant_id &&
          domainDelete.tenant_id &&
          tenantdomainDeleteActivo[domainDelete.tenant_id]
        ) {
          if (domainDelete.tenant_id.toString().indexOf('-') !== -1)
            tenantdomainDeleteActivo[domainDelete.tenant_id].domains =
              difference(tenantdomainDeleteActivo.domains, [domainDelete.id]);
        } else if (domainDelete) {
          tenantdomainDeleteActivo.domains = difference(
            tenantdomainDeleteActivo.domains
              ? tenantdomainDeleteActivo.domains
              : [],
            [domainDelete.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: tenantdomainDeleteActivo
        //nuevos: domainDelete && domainDelete.tenant_id ? union(state.nuevos, [domainDelete.tenant_id]) : state.nuevos,
      });

    //TENANTUSERACCESS
    case SUCCESS_CREATE_TENANTUSERACCESS:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_TENANTUSERACCESS:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_TENANTUSERACCESS:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_TENANTUSERACCESSES:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });

    case DELETE_TENANT:
      let datoTenantDelete = action.tenant;
      let idsDelete = [];
      Object.values(action.tenant).map(tenantDelete => {
        if (tenantDelete && tenantDelete.id) idsDelete.push(tenantDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoTenantDelete)),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_TENANT:
      let datoTenantDeleteCreate = action.tenant;
      let idsDeleteCreate = [];
      Object.values(action.tenant).map(tenantDelete => {
        if (tenantDelete && tenantDelete.id)
          idsDeleteCreate.push(tenantDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoTenantDeleteCreate)),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_TENANT:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.tenant,
        error: null
      });
    case REQUEST_DELETE_TENANT:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_TENANT:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_TENANT:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_TENANT:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    //TENANTUSERACCESS
    case SUCCESS_CREATE_TENANTUSERACCESS:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_TENANTUSERACCESS:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_TENANTUSERACCESS:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_TENANTUSERACCESSES:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_TENANT:
      let idsCreate = [];
      Object.values(action.tenant).map(tenantCreate => {
        if (tenantCreate && tenantCreate.id) idsCreate.push(tenantCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.tenant,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_TENANT:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_TENANT:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_TENANT:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_TENANTS:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_TENANTS:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_TENANTS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_TENANT:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_TENANT:
      let datoTenantDeleteCreate = action.tenant;
      let idsDeleteCreate = [];
      Object.values(action.tenant).map(tenantDelete => {
        if (tenantDelete && tenantDelete.id)
          idsDeleteCreate.push(tenantDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(clone(state.print), Object.keys(datoTenantDeleteCreate)),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const tenants = combineReducers({
  byId: tenantsById,
  allIds: allTenants,
  update: update,
  create: create,
  totalTenants: totalTenants,
  delete: deleter,
  print: print
});

export default tenants;
