import { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { Box, useTheme } from '@material-ui/core';

function TicketsPath({ status, changeTicketStatus, children }) {
  const theme = useTheme();
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: 'card',
    drop(item) {
      console.log('item', item);
      changeTicketStatus(item.id, status);
    }
  });
  drop(ref);
  return (
    <Box
      ref={ref}
      style={{
        backgroundColor: theme.palette.background.paper,
        boxShadow: '1px 1px 10px rgba(0,0,0,.1)',
        flex: '1',
        maxWidth: 'calc(100% / 4)',
        borderRadius: '16px',
        padding: '8px 8px 3px 8px',
        height: '100%'
      }}
    >
      {children}
    </Box>
  );
}

TicketsPath.propTypes = {};
export default TicketsPath;
