import React, { useMemo } from 'react';
import { LinearProgress, Paper, Typography, useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CustomPaper from '../../../library/CustomPaper/CustomPaper';
import PaperBody from '../../../library/CustomPaper/PaperBody';
import Box from '@material-ui/core/Box';
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Skeleton } from '@material-ui/lab';
import { min } from 'lodash';

const CustomTooltip = ({ active, payload, label }) => {
  return (
    <Paper style={{ padding: '10px' }}>
      <Box display={'flex'} flexDirection={'column'}>
        <Typography variant={'caption'}>
          {`Hace ${30 - parseInt(label)} minutos`}
        </Typography>
        <Typography variant={'body2'}>
          {`Usuarios: ${payload?.[0]?.payload.value}`}
        </Typography>
      </Box>
    </Paper>
  );
};

function RealTimeUsersCard() {
  const theme = useTheme();
  const analytics = useSelector(state => state.analytics);
  const totalSessions_dateTime =
    analytics.byId.analytics['totalSessions_dateTime'];
  const totalSessions_country = analytics.byId.analytics['totalUsers_country'];
  const format_totalSessions_dateTime = useMemo(() => {
    let total = 0;
    return [...Array(30).keys()].map(i => {
      let value = 0;
      totalSessions_dateTime?.metric.some(metric => {
        if (30 + moment(metric.dateTime).diff(moment(), 'minutes') === i) {
          value = metric.value;
          return true;
        } else return false;
      });
      total += parseInt(value);
      return {
        dateTime: i,
        value: parseInt(value),
        total: total
      };
    });
  }, totalSessions_dateTime);

  return (
    <Grid item md={5} sm={12} xs={12}>
      {analytics.byId.isFetching ? (
        <CustomPaper
          style={{
            height: '100%',
            marginTop: '0',
            overflow: 'hidden',
            minHeight: '330px'
          }}
        >
          <Skeleton variant="rectangular" width="100%" height="100%" />
        </CustomPaper>
      ) : (
        <CustomPaper style={{ height: '100%', marginTop: 0 }}>
          <PaperBody>
            <Box display={'flex'} flexDirection={'column'}>
              <Typography variant={'caption'} component={'h7'}>
                NÚMERO DE VISITANTES DURANTE LOS ÚLTIMOS 30 MINUTOS
              </Typography>
              <Typography variant={'h1'} component={'h1'}>
                {format_totalSessions_dateTime?.[
                  format_totalSessions_dateTime.length - 1
                ].total
                  ? format_totalSessions_dateTime?.[
                      format_totalSessions_dateTime.length - 1
                    ].total
                  : 0}
              </Typography>
            </Box>
            <Box
              width={'100%'}
              height={'100px'}
              minHeight={'100%'}
              marginTop={'10px'}
            >
              <Typography variant={'caption'} component={'h7'}>
                Línea de tiempo
              </Typography>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={150}
                  height={40}
                  data={format_totalSessions_dateTime}
                >
                  <Bar
                    dataKey="value"
                    fill={theme.palette.primary.main}
                    barSize={10}
                  />
                  <Tooltip content={<CustomTooltip />} cursor={true} />
                  <XAxis
                    dataKey={'dateTime'}
                    tick={false}
                    stroke={theme.palette.primary.main}
                    domain={[0, 30]}
                    strokeDasharray="5 0 5"
                    interval={'preserveStart'}
                    // domain={['auto', 'auto']}
                    type={'number'}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Box>
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              width={'100%'}
              marginTop={'15px'}
              borderBottom={'1px solid rgba(122,120,120,0.2)'}
            >
              <Typography variant={'caption'} component={'h7'}>
                Países
              </Typography>
              <Typography variant={'caption'} component={'h7'}>
                Visitantes
              </Typography>
            </Box>
            <Box
              width={'100%'}
              height={'100px'}
              minHeight={'100%'}
              marginTop={'10px'}
            >
              {totalSessions_country?.metric.map(metric => {
                return (
                  <Box marginTop={'10px'} key={metric?.country}>
                    <Box
                      display={'flex'}
                      flexDirection={'row'}
                      justifyContent={'space-between'}
                      width={'100%'}
                    >
                      <Typography variant={'caption'} component={'h6'}>
                        {metric.country}
                      </Typography>
                      <Typography variant={'caption'} component={'h7'}>
                        {metric.value}
                      </Typography>
                    </Box>
                    <Box width={'100%'}>
                      <LinearProgress
                        variant={'determinate'}
                        color={'primary'}
                        value={
                          (metric.value * 100) /
                          format_totalSessions_dateTime?.[
                            format_totalSessions_dateTime.length - 1
                          ].total
                        }
                      />
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </PaperBody>
        </CustomPaper>
      )}
    </Grid>
  );
}

export default RealTimeUsersCard;
