import {
  INVALIDATE_NEIGHBORHOODS,
  ERROR_NEIGHBORHOODS,
  RECEIVE_NEIGHBORHOODS,
  REQUEST_NEIGHBORHOODS,
  RESET_NEIGHBORHOODS,
  ERROR_NEIGHBORHOOD,
  RECEIVE_NEIGHBORHOOD,
  REQUEST_NEIGHBORHOOD,
  UPDATE_NEIGHBORHOOD,
  REQUEST_UPDATE_NEIGHBORHOOD,
  SUCCESS_UPDATE_NEIGHBORHOOD,
  ERROR_UPDATE_NEIGHBORHOOD,
  RESET_UPDATE_NEIGHBORHOOD,
  REQUEST_UPDATE_NEIGHBORHOODS,
  SUCCESS_UPDATE_NEIGHBORHOODS,
  ERROR_UPDATE_NEIGHBORHOODS,
  RESET_UPDATE_NEIGHBORHOODS,
  CREATE_NEIGHBORHOOD,
  ERROR_CREATE_NEIGHBORHOOD,
  REQUEST_CREATE_NEIGHBORHOOD,
  RESET_CREATE_NEIGHBORHOOD,
  SUCCESS_CREATE_NEIGHBORHOOD,
  REQUEST_CREATE_NEIGHBORHOODS,
  SUCCESS_CREATE_NEIGHBORHOODS,
  ERROR_CREATE_NEIGHBORHOODS,
  RESET_CREATE_NEIGHBORHOODS,
  DELETE_NEIGHBORHOOD,
  DELETE_CREATE_NEIGHBORHOOD,
  DELETE_UPDATE_NEIGHBORHOOD,
  REQUEST_DELETE_NEIGHBORHOOD,
  SUCCESS_DELETE_NEIGHBORHOOD,
  ERROR_DELETE_NEIGHBORHOOD,
  RESET_DELETE_NEIGHBORHOOD,
  REQUEST_PRINT_NEIGHBORHOOD,
  SUCCESS_PRINT_NEIGHBORHOOD,
  ERROR_PRINT_NEIGHBORHOOD,
  RESET_PRINT_NEIGHBORHOOD,
  RECEIVE_FILE_NEIGHBORHOOD,
  REQUEST_PRINT_NEIGHBORHOODS,
  SUCCESS_PRINT_NEIGHBORHOODS,
  ERROR_PRINT_NEIGHBORHOODS,
  RESET_PRINT_NEIGHBORHOODS,
  RECEIVE_FILE_NEIGHBORHOODS,
  PRINT_NEIGHBORHOOD,
  DELETE_PRINT_NEIGHBORHOOD
} from '../actions/NeighborhoodActions';

import {
  RECEIVE_COUNTRY,
  RECEIVE_COUNTRIES,
  SUCCESS_DELETE_COUNTRY,
  SUCCESS_CREATE_COUNTRY,
  SUCCESS_UPDATE_COUNTRY,
  SUCCESS_UPDATE_COUNTRIES
} from '../actions/CountryActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    neighborhoods: {},
    files: {}
  };
}

function neighborhoodsById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_NEIGHBORHOODS:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_NEIGHBORHOODS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_NEIGHBORHOODS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_NEIGHBORHOODS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        neighborhoods: {}
      });
    case RECEIVE_NEIGHBORHOODS:
      let dato = action.neighborhoods.entities.neighborhoods;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        neighborhoods: merge({}, state.neighborhoods, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_NEIGHBORHOOD:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_NEIGHBORHOOD:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_NEIGHBORHOOD:
      let datoNeighborhood = action.neighborhood.entities.neighborhoods;
      return Object.assign({}, state, {
        neighborhoods: merge({}, state.neighborhoods, datoNeighborhood),
        isFetching: false
      });
    case RECEIVE_FILE_NEIGHBORHOOD:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_NEIGHBORHOOD:
      let datoNeighborhoodEliminado =
        action.neighborhood.entities.neighborhoods;
      return Object.assign({}, state, {
        neighborhoods: mergeWith(
          clone(datoNeighborhoodEliminado),
          state.neighborhoods,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_NEIGHBORHOOD:
      let datoNeighborhoodCreado = action.neighborhood.entities.neighborhoods;
      return Object.assign({}, state, {
        neighborhoods: mergeWith(
          clone(datoNeighborhoodCreado),
          state.neighborhoods,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_NEIGHBORHOODS:
      let datosNeighborhoodCreado = action.neighborhoods.entities.neighborhoods;
      return Object.assign({}, state, {
        neighborhoods: mergeWith(
          clone(datosNeighborhoodCreado),
          state.neighborhoods,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_NEIGHBORHOOD:
      let datoNeighborhoodActualizado =
        action.neighborhood.entities.neighborhoods;
      return Object.assign({}, state, {
        neighborhoods: mergeWith(
          clone(datoNeighborhoodActualizado),
          state.neighborhoods,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_NEIGHBORHOODS:
      let datosNeighborhoodActualizado =
        action.neighborhoods.entities.neighborhoods;
      return Object.assign({}, state, {
        neighborhoods: mergeWith(
          clone(datosNeighborhoodActualizado),
          state.neighborhoods,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    //COUNTRY
    case RECEIVE_COUNTRY:
      let country =
        action.country.entities && action.country.entities.neighborhoods
          ? action.country.entities.neighborhoods
          : {};
      return Object.assign({}, state, {
        neighborhoods: merge({}, state.neighborhoods, country)
      });
    case RECEIVE_COUNTRIES:
      let countries =
        action.countries.entities && action.countries.entities.neighborhoods
          ? action.countries.entities.neighborhoods
          : {};
      return Object.assign({}, state, {
        neighborhoods: merge({}, state.neighborhoods, countries)
      });
    case SUCCESS_DELETE_COUNTRY:
      let datocountryEliminado =
        action.country.entities && action.country.entities.neighborhoods
          ? action.country.entities.neighborhoods
          : {};
      return Object.assign({}, state, {
        neighborhoods: mergeWith(
          clone(datocountryEliminado),
          pickBy(state.neighborhoods, function (neighborhood) {
            return neighborhood.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_COUNTRY:
      let datocountryCreado =
        action.country.entities && action.country.entities.neighborhoods
          ? action.country.entities.neighborhoods
          : {};
      return Object.assign({}, state, {
        neighborhoods: mergeWith(
          clone(datocountryCreado),
          pickBy(state.neighborhoods, function (neighborhood) {
            return neighborhood.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_COUNTRY:
      let datocountryActualizado =
        action.country.entities && action.country.entities.neighborhoods
          ? action.country.entities.neighborhoods
          : {};
      return Object.assign({}, state, {
        neighborhoods: mergeWith(
          clone(datocountryActualizado),
          pickBy(state.neighborhoods, function (neighborhood) {
            return neighborhood.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_COUNTRIES:
      let datoscountryActualizado =
        action.countries.entities && action.countries.entities.neighborhoods
          ? action.countries.entities.neighborhoods
          : {};
      return Object.assign({}, state, {
        neighborhoods: mergeWith(
          clone(datoscountryActualizado),
          state.neighborhoods,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        neighborhoods: {}
      });
    default:
      return state;
  }
}

function allNeighborhoods(state = [], action) {
  switch (action.type) {
    case RECEIVE_NEIGHBORHOODS:
      return action.neighborhoods.result &&
        action.neighborhoods.result.neighborhoods
        ? union(action.neighborhoods.result.neighborhoods, state)
        : action.neighborhoods.result
        ? action.neighborhoods.result
        : state;
    case RECEIVE_NEIGHBORHOOD:
      return action.neighborhood.result
        ? union([action.neighborhood.result], state)
        : state;

    case SUCCESS_CREATE_NEIGHBORHOOD:
      let datoNeighborhoodSCreate = action.neighborhood.entities.neighborhoods;
      let idNuevoSCreate = null;
      if (Object.values(datoNeighborhoodSCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoNeighborhoodSCreate)[0] &&
          Object.values(datoNeighborhoodSCreate)[0].id
            ? Object.values(datoNeighborhoodSCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_NEIGHBORHOODS:
      let neighborhoodsCreate =
        action.neighborhoods.entities &&
        action.neighborhoods.entities.neighborhoods
          ? action.neighborhoods.entities.neighborhoods
          : null;
      return neighborhoodsCreate
        ? union(
            state,
            Object.values(neighborhoodsCreate).map(neighborhoods => {
              return neighborhoods.id;
            })
          )
        : state;
    case RESET_NEIGHBORHOODS:
      return [];

    case RECEIVE_COUNTRY:
      let country =
        action.country.entities && action.country.entities.neighborhoods
          ? action.country.entities.neighborhoods
          : null;
      return country
        ? union(
            state,
            Object.values(country).map(country => {
              return country.id;
            })
          )
        : state;
    case RECEIVE_COUNTRIES:
      let countries =
        action.countries.entities && action.countries.entities.neighborhoods
          ? action.countries.entities.neighborhoods
          : null;
      return countries
        ? union(
            state,
            Object.values(countries).map(countries => {
              return countries.id;
            })
          )
        : state;

    case SUCCESS_DELETE_COUNTRY:
      let countryDelete =
        action.country.entities && action.country.entities.neighborhoods
          ? action.country.entities.neighborhoods
          : null;
      return countryDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(countryDelete).map(country => {
              return country.id;
            })
          )
        : state;
    case SUCCESS_CREATE_COUNTRY:
      let countryCreate =
        action.country.entities && action.country.entities.neighborhoods
          ? action.country.entities.neighborhoods
          : null;
      return countryCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(countryCreate).map(country => {
              return country.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_COUNTRY:
      let countryUpdate =
        action.country.entities && action.country.entities.neighborhoods
          ? action.country.entities.neighborhoods
          : null;
      return countryUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(countryUpdate).map(country => {
              return country.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_COUNTRIES:
      let countriesUpdate =
        action.countries.entities && action.countries.entities.neighborhoods
          ? action.countries.entities.neighborhoods
          : null;
      return countriesUpdate
        ? union(
            state,
            Object.values(countriesUpdate).map(countries => {
              return countries.id;
            })
          )
        : state;

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalNeighborhoods(state = null, action) {
  switch (action.type) {
    case RECEIVE_NEIGHBORHOODS:
      return action.neighborhoods && action.neighborhoods.result.total
        ? action.neighborhoods.result.total
        : 0;
    case RESET_NEIGHBORHOODS:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_NEIGHBORHOOD:
      let dato = action.neighborhood.entities.neighborhoods;
      let neighborhood =
        dato && Object.keys(dato).length > 0
          ? dato[action.neighborhood.result]
          : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: neighborhood ? neighborhood : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_NEIGHBORHOOD:
      let idsUpdate = [];
      Object.values(action.neighborhood).map(neighborhoodUpdate => {
        if (neighborhoodUpdate && neighborhoodUpdate.id)
          idsUpdate.push(neighborhoodUpdate.id);
      });
      return merge({}, state, {
        activo: action.neighborhood,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_NEIGHBORHOOD:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_NEIGHBORHOOD:
      let datoNeighborhoodActualizado =
        action.neighborhood.entities.neighborhoods;
      let neighborhoodNuevo =
        datoNeighborhoodActualizado &&
        Object.keys(datoNeighborhoodActualizado).length > 0
          ? datoNeighborhoodActualizado[action.neighborhood.result]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: neighborhoodNuevo
      });
    case ERROR_UPDATE_NEIGHBORHOOD:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_NEIGHBORHOODS:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_NEIGHBORHOODS:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_NEIGHBORHOODS:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_NEIGHBORHOOD:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    //COUNTRY
    //TODO ver si esta bien
    case SUCCESS_CREATE_COUNTRY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_COUNTRY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_COUNTRY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_COUNTRIES:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });

    case DELETE_NEIGHBORHOOD:
      let datoNeighborhoodDelete = action.neighborhood;
      let idsDelete = [];
      Object.values(action.neighborhood).map(neighborhoodDelete => {
        if (neighborhoodDelete && neighborhoodDelete.id)
          idsDelete.push(neighborhoodDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoNeighborhoodDelete)
          ),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_NEIGHBORHOOD:
      let datoNeighborhoodDeleteUpdate = action.neighborhood;
      let idsDeleteUpdate = [];
      Object.values(action.neighborhood).map(neighborhoodDelete => {
        if (neighborhoodDelete && neighborhoodDelete.id)
          idsDeleteUpdate.push(neighborhoodDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoNeighborhoodDeleteUpdate)
          ),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_NEIGHBORHOOD:
      let datoNeighborhoodDeleted = {};
      if (Object.values(action.neighborhood.entities.neighborhoods).length > 0)
        datoNeighborhoodDeleted = Object.values(
          action.neighborhood.entities.neighborhoods
        )[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoNeighborhoodDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_NEIGHBORHOOD:
      let idsCreate = [];
      Object.values(action.neighborhood).map(neighborhoodCreate => {
        if (neighborhoodCreate && neighborhoodCreate.id)
          idsCreate.push(neighborhoodCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.neighborhood,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_NEIGHBORHOOD:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_NEIGHBORHOOD:
      let datoNeighborhoodNuevo = action.neighborhood.entities.neighborhoods;
      let neighborhoodNuevo =
        datoNeighborhoodNuevo && Object.keys(datoNeighborhoodNuevo).length > 0
          ? datoNeighborhoodNuevo[action.neighborhood.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: neighborhoodNuevo,
        nuevos: []
      });
    case ERROR_CREATE_NEIGHBORHOOD:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_NEIGHBORHOODS:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_NEIGHBORHOODS:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_NEIGHBORHOODS:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_NEIGHBORHOOD:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    //COUNTRY
    case SUCCESS_CREATE_COUNTRY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_COUNTRY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_COUNTRY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_COUNTRIES:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });

    case DELETE_NEIGHBORHOOD:
      let datoNeighborhoodDelete = action.neighborhood;
      let idsDelete = [];
      Object.values(action.neighborhood).map(neighborhoodDelete => {
        if (neighborhoodDelete && neighborhoodDelete.id)
          idsDelete.push(neighborhoodDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoNeighborhoodDelete)),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_NEIGHBORHOOD:
      let datoNeighborhoodDeleteCreate = action.neighborhood;
      let idsDeleteCreate = [];
      Object.values(action.neighborhood).map(neighborhoodDelete => {
        if (neighborhoodDelete && neighborhoodDelete.id)
          idsDeleteCreate.push(neighborhoodDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoNeighborhoodDeleteCreate)
          ),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_NEIGHBORHOOD:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.neighborhood,
        error: null
      });
    case REQUEST_DELETE_NEIGHBORHOOD:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_NEIGHBORHOOD:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_NEIGHBORHOOD:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_NEIGHBORHOOD:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    //COUNTRY
    case SUCCESS_CREATE_COUNTRY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_COUNTRY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_COUNTRY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_COUNTRIES:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_NEIGHBORHOOD:
      let idsCreate = [];
      Object.values(action.neighborhood).map(neighborhoodCreate => {
        if (neighborhoodCreate && neighborhoodCreate.id)
          idsCreate.push(neighborhoodCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.neighborhood,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_NEIGHBORHOOD:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_NEIGHBORHOOD:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_NEIGHBORHOOD:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_NEIGHBORHOODS:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_NEIGHBORHOODS:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_NEIGHBORHOODS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_NEIGHBORHOOD:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_NEIGHBORHOOD:
      let datoNeighborhoodDeleteCreate = action.neighborhood;
      let idsDeleteCreate = [];
      Object.values(action.neighborhood).map(neighborhoodDelete => {
        if (neighborhoodDelete && neighborhoodDelete.id)
          idsDeleteCreate.push(neighborhoodDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(
            clone(state.print),
            Object.keys(datoNeighborhoodDeleteCreate)
          ),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const neighborhoods = combineReducers({
  byId: neighborhoodsById,
  allIds: allNeighborhoods,
  update: update,
  create: create,
  totalNeighborhoods: totalNeighborhoods,
  delete: deleter,
  print: print
});

export default neighborhoods;
