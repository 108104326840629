import { normalize, schema, denormalize } from 'normalizr';

const domain = new schema.Entity('domains', {}, { idAttribute: 'id' });

export function normalizeDatos(myData) {
  const mySchema = { domains: [domain] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = domain;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = domain;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default domain;
