import c from '../constants/Constants';

var propertyClosedOperations = {
  getAll(filtros) {
    var esc = encodeURIComponent;
    var query = '';
    if (filtros)
      query = Object.keys(filtros)
        .map(k => esc(k) + '=' + esc(filtros[k]))
        .join('&');

    let defaultOptions = {
      url: '',
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json'
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(
      c.BASE_URL + '/propertyClosedOperations/?' + query,
      defaultOptions
    );
  },
  getOne(idPropertyClosedOperation) {
    let defaultOptions = {
      url: '',
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json'
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(
      c.BASE_URL + '/propertyClosedOperations/' + idPropertyClosedOperation,
      defaultOptions
    );
  },
  getFile(idPropertyClosedOperation, filtros) {
    var esc = encodeURIComponent;
    var query = '';
    if (filtros)
      query = Object.keys(filtros)
        .map(k => esc(k) + '=' + esc(filtros[k]))
        .join('&');
    let defaultOptions = {
      url: '',
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        pragma: 'no-cache',
        'cache-control': 'no-cache',
        'X-Tenant': localStorage.tenant_id
      }
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(
      c.BASE_URL +
        '/propertyClosedOperations/' +
        idPropertyClosedOperation +
        '/file/?' +
        query,
      defaultOptions
    );
  },
  saveCreate(propertyClosedOperation) {
    let defaultOptions = {
      url: '',
      method: 'POST',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json',
      body: JSON.stringify(propertyClosedOperation)
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }

    return fetch(c.BASE_URL + '/propertyClosedOperations/', defaultOptions);
  },
  saveUpdate(propertyClosedOperation) {
    let defaultOptions = {
      url: '',
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json',
      body: JSON.stringify(propertyClosedOperation)
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }

    return fetch(
      c.BASE_URL + '/propertyClosedOperations/' + propertyClosedOperation.id,
      defaultOptions
    );
  },
  saveUpdatePropertyClosedOperations(activos) {
    let defaultOptions = {
      url: '',
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: 'Bearer ' + localStorage.token,
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json',
      body: JSON.stringify(activos)
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(c.BASE_URL + '/propertyClosedOperations/all', defaultOptions);
  },
  saveDelete(propertyClosedOperation) {
    let defaultOptions = {
      url: '',
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: 'Bearer ' + localStorage.token,
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json'
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(
      c.BASE_URL + '/propertyClosedOperations/' + propertyClosedOperation.id,
      defaultOptions
    );
  },
  saveCreatePropertyClosedOperations(nuevos) {
    let defaultOptions = {
      url: '',
      method: 'POST',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: 'Bearer ' + localStorage.token,
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json',
      body: JSON.stringify(nuevos)
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(c.BASE_URL + '/propertyClosedOperations/all', defaultOptions);
  },
  printPropertyClosedOperation(idPropertyClosedOperation) {
    let defaultOptions = {
      url: '',
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.token,
        'X-Tenant': localStorage.tenant_id
      },
      cache: 'no-cache'
    };
    return fetch(
      c.BASE_URL +
        '/propertyClosedOperations/print/' +
        idPropertyClosedOperation,
      defaultOptions
    );
  },
  printPropertyClosedOperations(print) {
    let defaultOptions = {
      url: '',
      method: 'POST',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.token,
        'X-Tenant': localStorage.tenant_id
      },
      cache: 'no-cache',
      body: JSON.stringify(print)
    };
    return fetch(
      c.BASE_URL + '/propertyClosedOperations/' + 'print/all',
      defaultOptions
    );
  }
};

export default propertyClosedOperations;
