import {
  resetAttributes,
  resetCreateAttribute,
  resetUpdateAttribute,
  resetDeleteAttribute
} from '../actions/AttributeActions';
import {
  resetProperties,
  resetCreateProperty,
  resetUpdateProperty,
  resetDeleteProperty
} from '../actions/PropertyActions';
import {
  resetAttributesGroups,
  resetCreateAttributeGroup,
  resetUpdateAttributeGroup,
  resetDeleteAttributeGroup
} from '../actions/AttributeGroupActions';

var resetAttribute = {
  resetAll(dispatch) {
    dispatch(resetAttributes());
    dispatch(resetCreateAttribute());
    dispatch(resetUpdateAttribute());
    dispatch(resetDeleteAttribute());
    dispatch(resetProperties());
    dispatch(resetCreateProperty());
    dispatch(resetUpdateProperty());
    dispatch(resetDeleteProperty());
    dispatch(resetAttributesGroups());
    dispatch(resetCreateAttributeGroup());
    dispatch(resetUpdateAttributeGroup());
    dispatch(resetDeleteAttributeGroup());
  }
};

export default resetAttribute;
