import Grid from '@material-ui/core/Grid';
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  makeStyles,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchProperties,
  invalidateProperties,
  resetProperties,
  updateProperty
} from '../../actions/PropertyActions';
import { Autocomplete } from '@material-ui/lab';
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/Add';
import c from '../../constants/Constants';
import PropertyCard from './PropertyCard';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({}));

export default function PropertyBelongsToDevelopment(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { property, changeProperty, disabled, validador } = props;
  const history = useHistory();

  //Store
  const properties = useSelector(state => state.properties);
  const propertyTypes = useSelector(state => state.propertyTypes);
  const assets = useSelector(state => state.assets);

  const development =
    property && property.idDevelopment
      ? properties.byId.properties[property.idDevelopment]
      : null;

  const [idDevelopment, setIdDevelopment] = useState(null);
  const [desDevelopment, setDevelopment] = useState('');

  //User
  const timer = useRef();

  function handleChangeDevelopmentInput(e, value, reason) {
    if (value && reason === 'input' && value.length > 2) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => triggerChangeDevelopment(value), 300);
    }
    setDevelopment(value);
  }

  const triggerChangeDevelopment = searchPhase => {
    dispatch(resetProperties);
    dispatch(invalidateProperties);
    dispatch(
      fetchProperties({
        searchPhase: searchPhase,
        codPropertyType: 'EM',
        with: ['mainAsset', 'propertyAttributeValue']
      })
    );
  };

  const handleSelectUser = (e, value, reason) => {
    let idDevelopmentSelect = null;
    let solicitanteSelect = '';
    if (value && value.id) {
      idDevelopmentSelect = value.id;
      solicitanteSelect = value.address;
    } else if (value && value.name && value.name.indexOf('Agregar') !== -1) {
      idDevelopmentSelect = -1;
      solicitanteSelect = 'Nuevo invitado';
    } else if (reason === 'clear') {
      idDevelopmentSelect = 0;
      solicitanteSelect = '';
    }
    let cambio = {};
    cambio.idDevelopment = idDevelopmentSelect;
    cambio.development = idDevelopmentSelect;
    changeProperty(cambio);
    setIdDevelopment(idDevelopmentSelect);
    setDevelopment(solicitanteSelect);
  };

  return (
    <Grid container spacing={2}>
      <Grid item md={12} sm={12} xs={12}>
        <Typography gutterBottom variant="body1">
          Vincular el empredimiento con la propiedad
        </Typography>
        <Autocomplete
          classes={{
            option: classes.option
          }}
          onChange={(event, newValue, reason) => {
            handleSelectUser(null, newValue, reason);
          }}
          filterOptions={(options, params) => {
            const filtered = options;
            // Suggest the creation of a new value

            if (params && params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                name: `Agregar nuevo emprendimiento "${params.inputValue}"`,
                lastname: '',
                email: ''
              });
            }

            return filtered;
          }}
          noOptionsText={'Escriba para buscar'}
          loadingText={'Buscando...'}
          selectOnFocus
          // clearOnBlur
          margin="normal"
          handleHomeEndKeys
          id="idDevelopment"
          options={properties.allIds.map(option => {
            let development = properties.byId.properties[option];
            return development ? development : '';
          })}
          getOptionLabel={option => {
            // Value selected with enter, right from the input
            if (typeof option === 'string') {
              return option;
            }
            // Add "xxx" option created dynamically
            if (option.inputValue) {
              return option.inputValue;
            }
            // Regular option
            return option.address;
          }}
          // renderOption={(option) => option.nombre + ' ' + option.apellido + ' ' + option.email}
          disabled={false}
          renderOption={option => {
            let facePhoto =
              option && option.main_asset && option.main_asset.length > 0
                ? assets.byId.assets[option.main_asset[0]]
                : null;
            return (
              <Card variant="outlined" style={{ width: '100%' }}>
                <CardHeader
                  avatar={
                    <Avatar
                      aria-label="recipe"
                      className={classes.avatar}
                      src={facePhoto ? c.API_HOST + facePhoto.path : ''}
                    >
                      {option.address ? option.address.charAt(0) : <AddIcon />}
                    </Avatar>
                  }
                  title={option.address}
                  subheader={
                    option &&
                    option.idPropertyType &&
                    propertyTypes.byId.propertyTypes[option.idPropertyType]
                      ? propertyTypes.byId.propertyTypes[option.idPropertyType]
                          .nameType
                      : ''
                  }
                />
              </Card>
            );
          }}
          fullWidth={false}
          style={{ width: '100%' }}
          // freeSolo
          loading={properties.byId.isFetching}
          onInputChange={handleChangeDevelopmentInput}
          inputValue={desDevelopment ? desDevelopment : ''}
          renderInput={params => (
            <TextField
              {...params}
              label="Buscar por dirección o nombre del emprendimiento"
              variant="outlined"
              margin="normal"
              error={!validador.isValidById('idDevelopment')}
              helperText={validador.getHelperTextById('idDevelopment')}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {properties.byId.isFetching ? (
                      <CircularProgress color="primary" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
                autoComplete: 'off'
              }}
            />
          )}
        />
      </Grid>
      {property && property.idDevelopment && development && (
        <PropertyCard
          property={development}
          changeProperty={changeProperty}
          disabled={true}
        />
      )}
      {property && property.idDevelopment && development && (
        <Grid item md={12} sm={12} xs={12}>
          <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
            <Button
              variant={'outlined'}
              color={'primary'}
              onClick={() =>
                history.push('/properties/' + property.idDevelopment)
              }
            >
              Ver emprendimiento
            </Button>
          </Box>
        </Grid>
      )}
    </Grid>
  );
}
