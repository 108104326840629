import Button from '@material-ui/core/Button';
import { ButtonGroup } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';

export default function TicketStatusBar(props) {
  const { ticketStatus_id, handleChangeTicketStatus, disabled } = props;
  const ticketStatuses = useSelector(state => state.ticketStatuses);

  return (
    <ButtonGroup color="default" aria-label="outlined primary button group">
      {ticketStatuses?.allIds?.ticketStatus?.map(id => {
        let ticketStatus = ticketStatuses.byId.ticketStatuses[id];
        return (
          <Button
            key={'status' + id}
            onClick={() => handleChangeTicketStatus(id)}
            size={'small'}
            color={ticketStatus_id === id ? 'primary' : 'default'}
            disabled={disabled}
            style={{
              minWidth: '130px',
              backgroundColor: ticketStatus_id === id ? '#D9C0EC' : 'inherit',
              color: ticketStatus_id === id ? 'white' : 'inherit',
              borderColor:
                ticketStatus_id === id ? 'white' : 'rgba(0, 0, 0, 0.23)'
            }}
          >
            {ticketStatus?.desStatus}
          </Button>
        );
      })}
    </ButtonGroup>
  );
}
