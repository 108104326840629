import {
  INVALIDATE_PROPERTYTYPES,
  ERROR_PROPERTYTYPES,
  RECEIVE_PROPERTYTYPES,
  REQUEST_PROPERTYTYPES,
  RESET_PROPERTYTYPES,
  ERROR_PROPERTYTYPE,
  RECEIVE_PROPERTYTYPE,
  REQUEST_PROPERTYTYPE,
  UPDATE_PROPERTYTYPE,
  REQUEST_UPDATE_PROPERTYTYPE,
  SUCCESS_UPDATE_PROPERTYTYPE,
  ERROR_UPDATE_PROPERTYTYPE,
  RESET_UPDATE_PROPERTYTYPE,
  REQUEST_UPDATE_PROPERTYTYPES,
  SUCCESS_UPDATE_PROPERTYTYPES,
  ERROR_UPDATE_PROPERTYTYPES,
  RESET_UPDATE_PROPERTYTYPES,
  CREATE_PROPERTYTYPE,
  ERROR_CREATE_PROPERTYTYPE,
  REQUEST_CREATE_PROPERTYTYPE,
  RESET_CREATE_PROPERTYTYPE,
  SUCCESS_CREATE_PROPERTYTYPE,
  REQUEST_CREATE_PROPERTYTYPES,
  SUCCESS_CREATE_PROPERTYTYPES,
  ERROR_CREATE_PROPERTYTYPES,
  RESET_CREATE_PROPERTYTYPES,
  DELETE_PROPERTYTYPE,
  DELETE_CREATE_PROPERTYTYPE,
  DELETE_UPDATE_PROPERTYTYPE,
  REQUEST_DELETE_PROPERTYTYPE,
  SUCCESS_DELETE_PROPERTYTYPE,
  ERROR_DELETE_PROPERTYTYPE,
  RESET_DELETE_PROPERTYTYPE,
  REQUEST_PRINT_PROPERTYTYPE,
  SUCCESS_PRINT_PROPERTYTYPE,
  ERROR_PRINT_PROPERTYTYPE,
  RESET_PRINT_PROPERTYTYPE,
  RECEIVE_FILE_PROPERTYTYPE,
  REQUEST_PRINT_PROPERTYTYPES,
  SUCCESS_PRINT_PROPERTYTYPES,
  ERROR_PRINT_PROPERTYTYPES,
  RESET_PRINT_PROPERTYTYPES,
  RECEIVE_FILE_PROPERTYTYPES,
  PRINT_PROPERTYTYPE,
  DELETE_PRINT_PROPERTYTYPE
} from '../actions/PropertyTypeActions';

import {
  RECEIVE_PROPERTY,
  RECEIVE_PROPERTIES,
  SUCCESS_DELETE_PROPERTY,
  SUCCESS_CREATE_PROPERTY,
  SUCCESS_UPDATE_PROPERTY,
  SUCCESS_UPDATE_PROPERTIES
} from '../actions/PropertyActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    propertyTypes: {},
    files: {}
  };
}

function propertyTypesById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_PROPERTYTYPES:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_PROPERTYTYPES:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_PROPERTYTYPES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_PROPERTYTYPES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        propertyTypes: {}
      });
    case RECEIVE_PROPERTYTYPES:
      let dato = action.propertyTypes.entities.propertyTypes;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        propertyTypes: merge({}, state.propertyTypes, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_PROPERTYTYPE:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_PROPERTYTYPE:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_PROPERTYTYPE:
      let datoPropertyType = action.propertyType.entities.propertyTypes;
      return Object.assign({}, state, {
        propertyTypes: merge({}, state.propertyTypes, datoPropertyType),
        isFetching: false
      });
    case RECEIVE_FILE_PROPERTYTYPE:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_PROPERTYTYPE:
      let datoPropertyTypeEliminado =
        action.propertyType.entities.propertyTypes;
      return Object.assign({}, state, {
        propertyTypes: mergeWith(
          clone(datoPropertyTypeEliminado),
          state.propertyTypes,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_PROPERTYTYPE:
      let datoPropertyTypeCreado = action.propertyType.entities.propertyTypes;
      return Object.assign({}, state, {
        propertyTypes: mergeWith(
          clone(datoPropertyTypeCreado),
          state.propertyTypes,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_PROPERTYTYPES:
      let datosPropertyTypeCreado = action.propertyTypes.entities.propertyTypes;
      return Object.assign({}, state, {
        propertyTypes: mergeWith(
          clone(datosPropertyTypeCreado),
          state.propertyTypes,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTYTYPE:
      let datoPropertyTypeActualizado =
        action.propertyType.entities.propertyTypes;
      return Object.assign({}, state, {
        propertyTypes: mergeWith(
          clone(datoPropertyTypeActualizado),
          state.propertyTypes,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTYTYPES:
      let datosPropertyTypeActualizado =
        action.propertyTypes.entities.propertyTypes;
      return Object.assign({}, state, {
        propertyTypes: mergeWith(
          clone(datosPropertyTypeActualizado),
          state.propertyTypes,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    //PROPERTY
    case RECEIVE_PROPERTY:
      let property =
        action.property.entities && action.property.entities.propertyTypes
          ? action.property.entities.propertyTypes
          : {};
      return Object.assign({}, state, {
        propertyTypes: merge({}, state.propertyTypes, property)
      });
    case RECEIVE_PROPERTIES:
      let properties =
        action.properties.entities && action.properties.entities.propertyTypes
          ? action.properties.entities.propertyTypes
          : {};
      return Object.assign({}, state, {
        propertyTypes: merge({}, state.propertyTypes, properties)
      });
    case SUCCESS_DELETE_PROPERTY:
      let datopropertyEliminado =
        action.property.entities && action.property.entities.propertyTypes
          ? action.property.entities.propertyTypes
          : {};
      return Object.assign({}, state, {
        propertyTypes: mergeWith(
          clone(datopropertyEliminado),
          pickBy(state.propertyTypes, function (propertyType) {
            return propertyType.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_PROPERTY:
      let datopropertyCreado =
        action.property.entities && action.property.entities.propertyTypes
          ? action.property.entities.propertyTypes
          : {};
      return Object.assign({}, state, {
        propertyTypes: mergeWith(
          clone(datopropertyCreado),
          pickBy(state.propertyTypes, function (propertyType) {
            return propertyType.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTY:
      let datopropertyActualizado =
        action.property.entities && action.property.entities.propertyTypes
          ? action.property.entities.propertyTypes
          : {};
      return Object.assign({}, state, {
        propertyTypes: mergeWith(
          clone(datopropertyActualizado),
          pickBy(state.propertyTypes, function (propertyType) {
            return propertyType.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTIES:
      let datospropertyActualizado =
        action.properties.entities && action.properties.entities.propertyTypes
          ? action.properties.entities.propertyTypes
          : {};
      return Object.assign({}, state, {
        propertyTypes: mergeWith(
          clone(datospropertyActualizado),
          state.propertyTypes,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        propertyTypes: {}
      });
    default:
      return state;
  }
}

function allPropertyTypes(state = [], action) {
  switch (action.type) {
    case RECEIVE_PROPERTYTYPES:
      return action.propertyTypes.result &&
        action.propertyTypes.result.propertyTypes
        ? union(action.propertyTypes.result.propertyTypes, state)
        : action.propertyTypes.result
        ? action.propertyTypes.result
        : state;
    case RECEIVE_PROPERTYTYPE:
      return action.propertyType.result
        ? union([action.propertyType.result], state)
        : state;

    case SUCCESS_CREATE_PROPERTYTYPE:
      let datoPropertyTypeSCreate = action.propertyType.entities.propertyTypes;
      let idNuevoSCreate = null;
      if (Object.values(datoPropertyTypeSCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoPropertyTypeSCreate)[0] &&
          Object.values(datoPropertyTypeSCreate)[0].id
            ? Object.values(datoPropertyTypeSCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_PROPERTYTYPES:
      let propertyTypesCreate =
        action.propertyTypes.entities &&
        action.propertyTypes.entities.propertyTypes
          ? action.propertyTypes.entities.propertyTypes
          : null;
      return propertyTypesCreate
        ? union(
            state,
            Object.values(propertyTypesCreate).map(propertyTypes => {
              return propertyTypes.id;
            })
          )
        : state;
    case RESET_PROPERTYTYPES:
      return [];

    case RECEIVE_PROPERTY:
      let property =
        action.property.entities && action.property.entities.propertyTypes
          ? action.property.entities.propertyTypes
          : null;
      return property
        ? union(
            state,
            Object.values(property).map(property => {
              return property.id;
            })
          )
        : state;
    case RECEIVE_PROPERTIES:
      let properties =
        action.properties.entities && action.properties.entities.propertyTypes
          ? action.properties.entities.propertyTypes
          : null;
      return properties
        ? union(
            state,
            Object.values(properties).map(properties => {
              return properties.id;
            })
          )
        : state;

    case SUCCESS_DELETE_PROPERTY:
      let propertyDelete =
        action.property.entities && action.property.entities.propertyTypes
          ? action.property.entities.propertyTypes
          : null;
      return propertyDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyDelete).map(property => {
              return property.id;
            })
          )
        : state;
    case SUCCESS_CREATE_PROPERTY:
      let propertyCreate =
        action.property.entities && action.property.entities.propertyTypes
          ? action.property.entities.propertyTypes
          : null;
      return propertyCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyCreate).map(property => {
              return property.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_PROPERTY:
      let propertyUpdate =
        action.property.entities && action.property.entities.propertyTypes
          ? action.property.entities.propertyTypes
          : null;
      return propertyUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyUpdate).map(property => {
              return property.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_PROPERTIES:
      let propertiesUpdate =
        action.properties.entities && action.properties.entities.propertyTypes
          ? action.properties.entities.propertyTypes
          : null;
      return propertiesUpdate
        ? union(
            state,
            Object.values(propertiesUpdate).map(properties => {
              return properties.id;
            })
          )
        : state;

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalPropertyTypes(state = null, action) {
  switch (action.type) {
    case RECEIVE_PROPERTYTYPES:
      return action.propertyTypes && action.propertyTypes.result.total
        ? action.propertyTypes.result.total
        : 0;
    case RESET_PROPERTYTYPES:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_PROPERTYTYPE:
      let dato = action.propertyType.entities.propertyTypes;
      let propertyType =
        dato && Object.keys(dato).length > 0
          ? dato[action.propertyType.result]
          : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: propertyType ? propertyType : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_PROPERTYTYPE:
      let idsUpdate = [];
      Object.values(action.propertyType).map(propertyTypeUpdate => {
        if (propertyTypeUpdate && propertyTypeUpdate.id)
          idsUpdate.push(propertyTypeUpdate.id);
      });
      return merge({}, state, {
        activo: action.propertyType,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_PROPERTYTYPE:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_PROPERTYTYPE:
      let datoPropertyTypeActualizado =
        action.propertyType.entities.propertyTypes;
      let propertyTypeNuevo =
        datoPropertyTypeActualizado &&
        Object.keys(datoPropertyTypeActualizado).length > 0
          ? datoPropertyTypeActualizado[action.propertyType.result]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: propertyTypeNuevo
      });
    case ERROR_UPDATE_PROPERTYTYPE:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_PROPERTYTYPES:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_PROPERTYTYPES:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_PROPERTYTYPES:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_PROPERTYTYPE:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    //PROPERTY
    //TODO ver si esta bien
    case SUCCESS_CREATE_PROPERTY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_PROPERTY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });

    case DELETE_PROPERTYTYPE:
      let datoPropertyTypeDelete = action.propertyType;
      let idsDelete = [];
      Object.values(action.propertyType).map(propertyTypeDelete => {
        if (propertyTypeDelete && propertyTypeDelete.id)
          idsDelete.push(propertyTypeDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoPropertyTypeDelete)
          ),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_PROPERTYTYPE:
      let datoPropertyTypeDeleteUpdate = action.propertyType;
      let idsDeleteUpdate = [];
      Object.values(action.propertyType).map(propertyTypeDelete => {
        if (propertyTypeDelete && propertyTypeDelete.id)
          idsDeleteUpdate.push(propertyTypeDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoPropertyTypeDeleteUpdate)
          ),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_PROPERTYTYPE:
      let datoPropertyTypeDeleted = {};
      if (Object.values(action.propertyType.entities.propertyTypes).length > 0)
        datoPropertyTypeDeleted = Object.values(
          action.propertyType.entities.propertyTypes
        )[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoPropertyTypeDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_PROPERTYTYPE:
      let idsCreate = [];
      Object.values(action.propertyType).map(propertyTypeCreate => {
        if (propertyTypeCreate && propertyTypeCreate.id)
          idsCreate.push(propertyTypeCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.propertyType,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_PROPERTYTYPE:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_PROPERTYTYPE:
      let datoPropertyTypeNuevo = action.propertyType.entities.propertyTypes;
      let propertyTypeNuevo =
        datoPropertyTypeNuevo && Object.keys(datoPropertyTypeNuevo).length > 0
          ? datoPropertyTypeNuevo[action.propertyType.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: propertyTypeNuevo,
        nuevos: []
      });
    case ERROR_CREATE_PROPERTYTYPE:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_PROPERTYTYPES:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_PROPERTYTYPES:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_PROPERTYTYPES:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_PROPERTYTYPE:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    //PROPERTY
    case SUCCESS_CREATE_PROPERTY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_PROPERTY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });

    case DELETE_PROPERTYTYPE:
      let datoPropertyTypeDelete = action.propertyType;
      let idsDelete = [];
      Object.values(action.propertyType).map(propertyTypeDelete => {
        if (propertyTypeDelete && propertyTypeDelete.id)
          idsDelete.push(propertyTypeDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoPropertyTypeDelete)),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_PROPERTYTYPE:
      let datoPropertyTypeDeleteCreate = action.propertyType;
      let idsDeleteCreate = [];
      Object.values(action.propertyType).map(propertyTypeDelete => {
        if (propertyTypeDelete && propertyTypeDelete.id)
          idsDeleteCreate.push(propertyTypeDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoPropertyTypeDeleteCreate)
          ),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_PROPERTYTYPE:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.propertyType,
        error: null
      });
    case REQUEST_DELETE_PROPERTYTYPE:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_PROPERTYTYPE:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_PROPERTYTYPE:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_PROPERTYTYPE:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    //PROPERTY
    case SUCCESS_CREATE_PROPERTY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_PROPERTY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_PROPERTYTYPE:
      let idsCreate = [];
      Object.values(action.propertyType).map(propertyTypeCreate => {
        if (propertyTypeCreate && propertyTypeCreate.id)
          idsCreate.push(propertyTypeCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.propertyType,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_PROPERTYTYPE:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_PROPERTYTYPE:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_PROPERTYTYPE:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_PROPERTYTYPES:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_PROPERTYTYPES:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_PROPERTYTYPES:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_PROPERTYTYPE:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_PROPERTYTYPE:
      let datoPropertyTypeDeleteCreate = action.propertyType;
      let idsDeleteCreate = [];
      Object.values(action.propertyType).map(propertyTypeDelete => {
        if (propertyTypeDelete && propertyTypeDelete.id)
          idsDeleteCreate.push(propertyTypeDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(
            clone(state.print),
            Object.keys(datoPropertyTypeDeleteCreate)
          ),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const propertyTypes = combineReducers({
  byId: propertyTypesById,
  allIds: allPropertyTypes,
  update: update,
  create: create,
  totalPropertyTypes: totalPropertyTypes,
  delete: deleter,
  print: print
});

export default propertyTypes;
