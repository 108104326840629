import c from '../constants/Constants';

// require('es6-promise').polyfill();
// require('isomorphic-fetch');

var authApi = {
  login(email, password) {
    let defaultOptions = {
      url: '',
      method: 'POST',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: localStorage.token
      },
      dataType: 'json',
      body: JSON.stringify({ email, password })
    };
    return fetch(c.BASE_URL + '/auth/login', defaultOptions);
  },

  olvideMiPassword(usuario) {
    let defaultOptions = {
      url: '',
      method: 'POST',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify({ email: usuario }),
      // cache: false,
      dataType: 'json'
    };
    return fetch(c.BASE_URL + '/auth/forgotPassword', defaultOptions);
  },
  activarCuenta(token) {
    let defaultOptions = {
      url: '',
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      // cache: false,
      dataType: 'json'
    };
    return fetch(c.BASE_URL + '/auth/signup/activate/' + token, defaultOptions);
  },

  resetPassword(usuario) {
    let defaultOptions = {
      url: '',
      method: 'POST',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify(usuario),
      // cache: false,
      dataType: 'json'
    };
    return fetch(c.BASE_URL + '/auth/resetPassword', defaultOptions);
  },
  validarToken(token) {
    let defaultOptions = {
      url: '',
      method: 'POST',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8'
      },
      body: JSON.stringify({ tokenReset: token }),
      // cache: false,
      dataType: 'json'
    };
    return fetch(c.BASE_URL + '/auth/validateToken', defaultOptions);
  },

  logout() {
    let defaultOptions = {
      url: '',
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: 'Bearer ' + localStorage.token
      },
      // cache: false,
      dataType: 'json'
    };
    return fetch(c.BASE_URL + '/auth/logout', defaultOptions);
  }
};

export default authApi;
