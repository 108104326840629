/**
 * Created by joelg on 6/9/17.
 */

let BASE_URL = '';
let API_HOST = '';
let HOST = '';
//localhost
if (process.env.NODE_ENV === 'development' && !process.env.REACT_APP_ENVI) {
  BASE_URL = 'http://localhost:8001/api';
  API_HOST = 'https://glassprop-test.nyc3.cdn.digitaloceanspaces.com';
  HOST = 'http://localhost:8001';
}

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_ENVI === 'EXTERNAL_API'
) {
  BASE_URL = 'https://api.glassprop.com/api';
  API_HOST = 'https://glassprop-test.nyc3.cdn.digitaloceanspaces.com';
  HOST = 'https://api.glassprop.com';
}

if (process.env.NODE_ENV === 'production') {
  BASE_URL = 'https://api.glassprop.com/api';
  API_HOST = 'https://glassprop.nyc3.cdn.digitaloceanspaces.com';
  HOST = 'https://api.glassprop.com';
}

export default {
  BASE_URL: BASE_URL,
  API_HOST: API_HOST,
  HOST: HOST
};
