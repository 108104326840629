import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
//Components
import {
  Box,
  Breadcrumbs,
  Container,
  InputAdornment,
  TextField
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CropFreeIcon from '@material-ui/icons/Search';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import CustomPaper from '../../library/CustomPaper/CustomPaper';
import PaperHeader from '../../library/CustomPaper/PaperHeader';
import Grow from '../../library/Grow';

import TicketItem from './TicketItem';
import TicketsPath from './TicketsPath';
import {
  fetchTickets,
  saveUpdateTickets,
  updateTicket
} from '../../actions/TicketActions';
import { fetchTicketStatuses } from '../../actions/TicketStatusActions';
import Button from '@material-ui/core/Button';
import CreateTicketModal from './CreateTicketModal';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'flex',
    gap: '12px',
    justifyContent: 'space-between',
    userSelect: 'none',
    marginTop: '12px',
    height: '100%'
  }
}));

function TicketsBoard(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { title } = props;

  //Store
  const tickets = useSelector(state => state.tickets);
  const ticketStatuses = useSelector(state => state.ticketStatuses);
  const users = useSelector(state => state.users);
  const properties = useSelector(state => state.properties);
  const ticketProperties = useSelector(state => state.ticketProperties);

  //State
  const [search, setSearch] = useState('');
  const [open, setOpen] = React.useState(false);

  const changeTicketStatus = (id, status) => {
    let ticket = tickets.byId.tickets[id];
    let idticketStatus = ticketStatuses.allIds.ticketStatus.find(id_ => {
      let ticketStatus = ticketStatuses.byId.ticketStatuses[id_];
      return status === ticketStatus.desStatus;
    });
    let cambio = { ...ticket };
    cambio.ticketStatus_id = idticketStatus;
    let cambio_ = {};
    cambio_[ticket.id] = cambio;
    dispatch(updateTicket(cambio_));
    dispatch(saveUpdateTickets());
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchTicketStatuses({}));
    dispatch(
      fetchTickets({
        with: ['user', 'ticketProperty.property'],
        order: 'created_at',
        direction: 'desc',
        status: 1
      })
    );

    return function cleanup() {
      //Todo
    };
  }, []);

  const handleAddTicket = () => {
    setOpen(true);
  };

  return (
    <Container
      maxWidth={'lg'}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      {title !== false && (
        <>
          <Breadcrumbs aria-label="breadcrumb" separator={'‣'}>
            <Link color="inherit" to="/">
              Home
            </Link>
          </Breadcrumbs>
          <Typography component={'h1'} variant={'h1'}>
            Oportunidades
          </Typography>
        </>
      )}
      <CustomPaper>
        <PaperHeader>
          <TextField
            id="search"
            placeholder="Buscar ..."
            variant="outlined"
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <CropFreeIcon />
                  </IconButton>
                </InputAdornment>
              )
              // disabled: disabled
            }}
            value={search ? search : ''}
            // onChange={(e) => changeSearch(e)}
            // onKeyDown={(e) => handleKeyDown(e)}
          />
          <Grow />
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={handleAddTicket}
          >
            Nueva oportunidad
          </Button>
        </PaperHeader>
      </CustomPaper>
      <Box className={classes.root}>
        {/* {Contacts} */}
        {ticketStatuses?.allIds?.ticketStatus?.map(id => {
          let ticketStatus = ticketStatuses.byId.ticketStatuses[id];
          return (
            <TicketsPath
              key={ticketStatus?.desStatus}
              status={ticketStatus?.desStatus}
              changeTicketStatus={changeTicketStatus}
            >
              <Typography
                component={'h6'}
                variant={'h6'}
                style={{ paddingLeft: '12px', paddingBottom: '8px' }}
              >
                {ticketStatus?.desStatus}
              </Typography>
              <Box>
                {tickets.allIds
                  ?.filter(idTicket => {
                    let ticket = tickets.byId.tickets[idTicket];
                    if (tickets.update.activos.indexOf(idTicket) !== -1)
                      ticket = tickets.update.activo[idTicket];
                    if (tickets.create.nuevos.indexOf(idTicket) !== -1)
                      ticket = tickets.create.nuevo[idTicket];
                    return ticket.ticketStatus_id === ticketStatus?.id;
                  })
                  .map(idTicket => {
                    let ticket = tickets.byId.tickets[idTicket];
                    const user =
                      ticket && ticket.user_id
                        ? users.byId.users[ticket.user_id]
                        : null;
                    const ticketProperty =
                      ticket?.ticket_property &&
                      ticket?.ticket_property.length > 0
                        ? ticketProperties.byId.ticketProperties[
                            ticket.ticket_property[0]
                          ]
                        : null;
                    const property = ticketProperty?.property_id
                      ? properties.byId.properties[ticketProperty.property_id]
                      : null;
                    return (
                      <TicketItem
                        key={ticket.id}
                        id={ticket.id}
                        lead={ticket}
                        user={user}
                        property={property}
                      />
                    );
                  })}
                {/*{tickets_*/}
                {/*  ?.filter(lead => lead.status === ticketStatus?.id)*/}
                {/*  .map(lead => (*/}
                {/*    <TicketItem key={lead._id} id={lead._id} lead={lead} />*/}
                {/*  ))}*/}
              </Box>
            </TicketsPath>
          );
        })}
      </Box>
      <CreateTicketModal open={open} setOpen={setOpen} />
    </Container>
  );
}

TicketsBoard.propTypes = {};

export default TicketsBoard;
