//api
import ticketStatusesApi from '../api/ticketStatusesApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizeTicketStatuses';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//TICKETSTATUSES
export const REQUEST_TICKETSTATUSES = 'REQUEST_TICKETSTATUSES';
export const RECEIVE_TICKETSTATUSES = 'RECEIVE_TICKETSTATUSES';
export const INVALIDATE_TICKETSTATUSES = 'INVALIDATE_TICKETSTATUSES';
export const ERROR_TICKETSTATUSES = 'ERROR_TICKETSTATUSES';
export const RESET_TICKETSTATUSES = 'RESET_TICKETSTATUSES';

export function invalidateTicketStatuses() {
  return {
    type: INVALIDATE_TICKETSTATUSES
  };
}

function requestTicketStatuses() {
  return {
    type: REQUEST_TICKETSTATUSES
  };
}

function receiveTicketStatuses(json) {
  return {
    type: RECEIVE_TICKETSTATUSES,
    ticketStatuses: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorTicketStatuses(error) {
  return {
    type: ERROR_TICKETSTATUSES,
    error: error
  };
}

export function resetTicketStatuses() {
  return {
    type: RESET_TICKETSTATUSES
  };
}

export function fetchTicketStatuses(filtros) {
  return dispatch => {
    dispatch(requestTicketStatuses());
    return ticketStatusesApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveTicketStatuses(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorTicketStatuses(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorTicketStatuses(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

function shouldFetchTicketStatuses(state) {
  const ticketStatuses = state.ticketStatuses.byId;
  if (!ticketStatuses) {
    return true;
  } else if (ticketStatuses.isFetching) {
    return false;
  } else {
    return ticketStatuses.didInvalidate;
  }
}

export function fetchTicketStatusesIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchTicketStatuses(getState())) {
      return dispatch(fetchTicketStatuses(filtros));
    }
  };
}

//MODEL
export const REQUEST_TICKETSTATUS = 'REQUEST_TICKETSTATUS';
export const RECEIVE_TICKETSTATUS = 'RECEIVE_TICKETSTATUS';
export const INVALIDATE_TICKETSTATUS = 'INVALIDATE_TICKETSTATUS';
export const ERROR_TICKETSTATUS = 'ERROR_TICKETSTATUS';
export const RESET_TICKETSTATUS = 'RESET_TICKETSTATUS';

export function invalidateTicketStatus() {
  return {
    type: INVALIDATE_TICKETSTATUS
  };
}

function requestTicketStatus() {
  return {
    type: REQUEST_TICKETSTATUS
  };
}

export function receiveTicketStatus(json) {
  return {
    type: RECEIVE_TICKETSTATUS,
    ticketStatus: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorTicketStatus(error) {
  return {
    type: ERROR_TICKETSTATUS,
    error: error
  };
}

export function fetchTicketStatus(idTicketStatus) {
  return dispatch => {
    dispatch(requestTicketStatus());
    return ticketStatusesApi
      .getOne(idTicketStatus)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveTicketStatus(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(errorTicketStatus(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorTicketStatus(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_TICKETSTATUS = 'RECEIVE_FILE_TICKETSTATUS';

function receiveFileTicketStatus(file) {
  return {
    type: RECEIVE_FILE_TICKETSTATUS,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFileTicketStatus(idTicketStatus, filtros) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return ticketStatusesApi
      .getFile(idTicketStatus, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFileTicketStatus(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorTicketStatus(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorTicketStatus(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_TICKETSTATUS = 'UPDATE_TICKETSTATUS';
export const REQUEST_UPDATE_TICKETSTATUS = 'REQUEST_UPDATE_TICKETSTATUS';
export const SUCCESS_UPDATE_TICKETSTATUS = 'SUCCESS_UPDATE_TICKETSTATUS';
export const ERROR_UPDATE_TICKETSTATUS = 'ERROR_UPDATE_TICKETSTATUS';
export const RESET_UPDATE_TICKETSTATUS = 'RESET_UPDATE_TICKETSTATUS';
export const DELETE_UPDATE_TICKETSTATUS = 'DELETE_UPDATE_TICKETSTATUS';

function requestUpdateTicketStatus() {
  return {
    type: REQUEST_UPDATE_TICKETSTATUS
  };
}

function receiveUpdateTicketStatus(ticketStatus) {
  return {
    type: SUCCESS_UPDATE_TICKETSTATUS,
    receivedAt: Date.now(),
    ticketStatus: normalizeDato(ticketStatus)
  };
}

function errorUpdateTicketStatus(error) {
  return {
    type: ERROR_UPDATE_TICKETSTATUS,
    error: error
  };
}

export function resetUpdateTicketStatus() {
  return {
    type: RESET_UPDATE_TICKETSTATUS
  };
}

export function updateTicketStatus(ticketStatus) {
  return {
    type: UPDATE_TICKETSTATUS,
    ticketStatus
  };
}

export function saveUpdateTicketStatus() {
  return (dispatch, getState) => {
    dispatch(requestUpdateTicketStatus());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let ticketStatus = denormalizeDato(
      getState().ticketStatuses.update.activo,
      store
    );

    return ticketStatusesApi
      .saveUpdate(ticketStatus)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateTicketStatus(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateTicketStatus(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateTicketStatuses(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorUpdateTicketStatuses(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateTicketStatus(JSON.parse(error.message)));
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdateTicketStatus(error.data));
                })
                .catch(() => {
                  dispatch(
                    errorUpdateTicketStatus(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

export function deleteUpdateTicketStatus(ticketStatus) {
  return {
    type: DELETE_UPDATE_TICKETSTATUS,
    ticketStatus
  };
}

//UPDATE TICKETSTATUSES
export const REQUEST_UPDATE_TICKETSTATUSES = 'REQUEST_UPDATE_TICKETSTATUSES';
export const SUCCESS_UPDATE_TICKETSTATUSES = 'SUCCESS_UPDATE_TICKETSTATUSES';
export const ERROR_UPDATE_TICKETSTATUSES = 'ERROR_UPDATE_TICKETSTATUSES';
export const RESET_UPDATE_TICKETSTATUSES = 'RESET_UPDATE_TICKETSTATUSES';

function requestUpdateTicketStatuses() {
  return {
    type: REQUEST_UPDATE_TICKETSTATUSES
  };
}

function receiveUpdateTicketStatuses(ticketStatuses) {
  return {
    type: SUCCESS_UPDATE_TICKETSTATUSES,
    receivedAt: Date.now(),
    ticketStatuses: normalizeDatos(ticketStatuses)
  };
}

function errorUpdateTicketStatuses(error) {
  return {
    type: ERROR_UPDATE_TICKETSTATUSES,
    error: error
  };
}

export function resetUpdateTicketStatuses() {
  return {
    type: RESET_UPDATE_TICKETSTATUSES
  };
}

export function saveUpdateTicketStatuses() {
  return (dispatch, getState) => {
    dispatch(requestUpdateTicketStatuses());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let ticketStatuses = getState().ticketStatuses.update.activos.map(
      idTicketStatus => {
        return denormalizeDato(
          getState().ticketStatuses.update.activo[idTicketStatus],
          store
        );
      }
    );

    return ticketStatusesApi
      .saveUpdateTicketStatuses(ticketStatuses)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateTicketStatuses(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorUpdateTicketStatuses(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateTicketStatuses(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorUpdateTicketStatuses(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorUpdateTicketStatuses(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorUpdateTicketStatuses(errorMessages.GENERAL_ERROR)
                  );
                });

            return;
        }
      });
  };
}

//ALTA TICKETSTATUS
export const CREATE_TICKETSTATUS = 'CREATE_TICKETSTATUS';
export const REQUEST_CREATE_TICKETSTATUS = 'REQUEST_CREATE_TICKETSTATUS';
export const SUCCESS_CREATE_TICKETSTATUS = 'SUCCESS_CREATE_TICKETSTATUS';
export const ERROR_CREATE_TICKETSTATUS = 'ERROR_CREATE_TICKETSTATUS';
export const RESET_CREATE_TICKETSTATUS = 'RESET_CREATE_TICKETSTATUS';
export const DELETE_CREATE_TICKETSTATUS = 'DELETE_CREATE_TICKETSTATUS';

//ALTA TICKETSTATUS
function requestCreateTicketStatus() {
  return {
    type: REQUEST_CREATE_TICKETSTATUS
  };
}

function receiveCreateTicketStatus(ticketStatus) {
  return {
    type: SUCCESS_CREATE_TICKETSTATUS,
    receivedAt: Date.now(),
    ticketStatus: normalizeDato(ticketStatus)
  };
}

export function errorCreateTicketStatus(error) {
  return {
    type: ERROR_CREATE_TICKETSTATUS,
    error: error
  };
}

export function resetCreateTicketStatus() {
  return {
    type: RESET_CREATE_TICKETSTATUS
  };
}

export function createTicketStatus(ticketStatus) {
  return {
    type: CREATE_TICKETSTATUS,
    ticketStatus
  };
}

export function deleteCreateTicketStatus(ticketStatus) {
  return {
    type: DELETE_CREATE_TICKETSTATUS,
    ticketStatus
  };
}

export function saveCreateTicketStatus() {
  return (dispatch, getState) => {
    dispatch(requestCreateTicketStatus());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let ticketStatus = denormalizeDato(
      getState().ticketStatuses.create.nuevo,
      store
    );

    return ticketStatusesApi
      .saveCreate(ticketStatus)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateTicketStatus(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateTicketStatus(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateTicketStatuses(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorCreateTicketStatuses(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateTicketStatus(JSON.parse(error.message)));
                  if (error.data)
                    dispatch(receiveCreateTicketStatus(error.data));
                })
                .catch(() => {
                  dispatch(
                    errorCreateTicketStatus(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//CREATE TICKETSTATUSES
export const REQUEST_CREATE_TICKETSTATUSES = 'REQUEST_CREATE_TICKETSTATUSES';
export const SUCCESS_CREATE_TICKETSTATUSES = 'SUCCESS_CREATE_TICKETSTATUSES';
export const ERROR_CREATE_TICKETSTATUSES = 'ERROR_CREATE_TICKETSTATUSES';
export const RESET_CREATE_TICKETSTATUSES = 'RESET_CREATE_TICKETSTATUSES';

function requestCreateTicketStatuses() {
  return {
    type: REQUEST_CREATE_TICKETSTATUSES
  };
}

function receiveCreateTicketStatuses(ticketStatuses) {
  return {
    type: SUCCESS_CREATE_TICKETSTATUSES,
    receivedAt: Date.now(),
    ticketStatuses: normalizeDatos(ticketStatuses)
  };
}

function errorCreateTicketStatuses(error) {
  return {
    type: ERROR_CREATE_TICKETSTATUSES,
    error: error
  };
}

export function resetCreateTicketStatuses() {
  return {
    type: RESET_CREATE_TICKETSTATUSES
  };
}

export function saveCreateTicketStatuses() {
  return (dispatch, getState) => {
    dispatch(requestCreateTicketStatuses());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let ticketStatuses = getState().ticketStatuses.create.nuevos.map(
      idTicketStatus => {
        return denormalizeDato(
          getState().ticketStatuses.create.nuevo[idTicketStatus],
          store
        );
      }
    );

    return ticketStatusesApi
      .saveCreateTicketStatuses(ticketStatuses)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateTicketStatuses(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorCreateTicketStatuses(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateTicketStatuses(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorCreateTicketStatuses(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorCreateTicketStatuses(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorCreateTicketStatuses(errorMessages.GENERAL_ERROR)
                  );
                });

            return;
        }
      });
  };
}

//DELETE TICKETSTATUS
export const DELETE_TICKETSTATUS = 'DELETE_TICKETSTATUS';
export const REQUEST_DELETE_TICKETSTATUS = 'REQUEST_DELETE_TICKETSTATUS';
export const SUCCESS_DELETE_TICKETSTATUS = 'SUCCESS_DELETE_TICKETSTATUS';
export const ERROR_DELETE_TICKETSTATUS = 'ERROR_DELETE_TICKETSTATUS';
export const RESET_DELETE_TICKETSTATUS = 'RESET_DELETE_TICKETSTATUS';

function requestDeleteTicketStatus() {
  return {
    type: REQUEST_DELETE_TICKETSTATUS
  };
}

function receiveDeleteTicketStatus(ticketStatus) {
  return {
    type: SUCCESS_DELETE_TICKETSTATUS,
    receivedAt: Date.now(),
    ticketStatus: normalizeDato(ticketStatus)
  };
}

function errorDeleteTicketStatus(error) {
  return {
    type: ERROR_DELETE_TICKETSTATUS,
    error: error
  };
}

export function resetDeleteTicketStatus(error) {
  return {
    type: RESET_DELETE_TICKETSTATUS,
    error: error
  };
}

export function deleteTicketStatus(ticketStatus) {
  return {
    type: DELETE_TICKETSTATUS,
    ticketStatus
  };
}

export function saveDeleteTicketStatus(ticketStatus) {
  return dispatch => {
    dispatch(requestDeleteTicketStatus());
    return ticketStatusesApi
      .saveDelete(ticketStatus)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeleteTicketStatus());
        dispatch(receiveDeleteTicketStatus(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorDeleteTicketStatus(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeleteTicketStatus(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorDeleteTicketStatus(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorDeleteTicketStatus(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(
                    errorDeleteTicketStatus(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//PRINT TICKETSTATUS
export const PRINT_TICKETSTATUS = 'PRINT_TICKETSTATUS';
export const REQUEST_PRINT_TICKETSTATUS = 'REQUEST_PRINT_TICKETSTATUS';
export const SUCCESS_PRINT_TICKETSTATUS = 'SUCCESS_PRINT_TICKETSTATUS';
export const ERROR_PRINT_TICKETSTATUS = 'ERROR_PRINT_TICKETSTATUS';
export const RESET_PRINT_TICKETSTATUS = 'RESET_PRINT_TICKETSTATUS';
export const DELETE_PRINT_TICKETSTATUS = 'DELETE_PRINT_TICKETSTATUS';

function requestPrintTicketStatus() {
  return {
    type: REQUEST_PRINT_TICKETSTATUS
  };
}

function receivePrintTicketStatus(turnos) {
  return {
    type: SUCCESS_PRINT_TICKETSTATUS,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintTicketStatus(error) {
  return {
    type: ERROR_PRINT_TICKETSTATUS,
    error: error
  };
}

export function resetPrintTicketStatus() {
  return {
    type: RESET_PRINT_TICKETSTATUS
  };
}

export function printTicketStatus(ticketStatus) {
  return {
    type: PRINT_TICKETSTATUS,
    ticketStatus
  };
}

export function deletePrintTicketStatus(ticketStatus) {
  return {
    type: DELETE_PRINT_TICKETSTATUS,
    ticketStatus
  };
}

export function savePrintTicketStatus(idTicketStatus) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintTicketStatus());
    return ticketStatusesApi
      .printTicketStatus(idTicketStatus)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintTicketStatus(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintTicketStatus(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintTicketStatus(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintTicketStatus(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintTicketStatus(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT TICKETSTATUSES
export const REQUEST_PRINT_TICKETSTATUSES = 'REQUEST_PRINT_TICKETSTATUSES';
export const SUCCESS_PRINT_TICKETSTATUSES = 'SUCCESS_PRINT_TICKETSTATUSES';
export const ERROR_PRINT_TICKETSTATUSES = 'ERROR_PRINT_TICKETSTATUSES';
export const RESET_PRINT_TICKETSTATUSES = 'RESET_PRINT_TICKETSTATUSES';

function requestPrintTicketStatuses() {
  return {
    type: REQUEST_PRINT_TICKETSTATUSES
  };
}

function receivePrintTicketStatuses(ticketStatuses) {
  return {
    type: SUCCESS_PRINT_TICKETSTATUSES,
    receivedAt: Date.now(),
    ticketStatuses: normalizeDatos(ticketStatuses)
  };
}

function errorPrintTicketStatuses(error) {
  return {
    type: ERROR_PRINT_TICKETSTATUSES,
    error: error
  };
}

export function resetPrintTicketStatuses() {
  return {
    type: RESET_PRINT_TICKETSTATUSES
  };
}

export function savePrintTicketStatuses() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintTicketStatus());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let ticketStatuses = getState().ticketStatuses.print.printers.map(
      idTicketStatus => {
        return denormalizeDato(
          getState().ticketStatuses.print.print[idTicketStatus],
          store
        );
      }
    );
    return ticketStatusesApi
      .printTicketStatuses(ticketStatuses)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintTicketStatuses(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorPrintTicketStatuses(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintTicketStatuses(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintTicketStatuses(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(
                    errorPrintTicketStatuses(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}
