import { makeStyles } from '@material-ui/core/styles';
import React, { forwardRef } from 'react';
import { TextField } from '@material-ui/core';
import PhoneInput from 'react-phone-number-input';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles(theme => ({
  mensaje: {
    display: 'none',
    color: theme.palette.error.main,
    '&.visible': {
      display: 'inherit'
    }
  }
}));

const RenderHeader = forwardRef((props, ref) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      fullWidth={true}
      label="Celular"
      variant="outlined"
      name="phone"
    />
  );
});
export default function CellphoneInput(props) {
  const classes = useStyles();
  const ref = React.createRef();
  const {
    cellphone,
    changeUser,
    msjError,
    margin = 'normal',
    size = 'small',
    disabled
  } = props;
  const handleChangeCelularUser = value => {
    msjError.current.classList.remove('visible');
    if (value) {
      let cambio = {};
      cambio.cellphone = value;
      changeUser(cambio);
    }
  };
  return (
    <>
      <PhoneInput
        id="cellphone"
        label="Celular"
        placeholder="Celular"
        defaultCountry={'AR'}
        international={true}
        countryCallingCodeEditable={false}
        inputComponentRef={ref}
        value={cellphone ? cellphone : ''}
        onChange={handleChangeCelularUser}
        inputComponent={RenderHeader}
        margin={margin}
        size={size}
        fullWidth
        style={{ width: '100%' }}
        disabled={disabled}
      />
      <FormHelperText ref={msjError} className={classes.mensaje}>
        El celular no es válido
      </FormHelperText>
    </>
  );
}
