import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import 'quill/dist/quill.snow.css';
import './assets/css/IndexPanel.css';

//Router

import { Redirect, Route, Router, Switch } from 'react-router-dom';
import history from './history';
//Redux
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import appReducers from './reducers/index';

//Components
import Layout from './component/Layout/Layout.js';
// import '@fontsource/roboto';
// import "@fontsource/public-sans";
import './assets/css/fonts.css';
import GenericLayout from './component/GenericLayout/GenericLayout.js';
// Creates the Redux reducer with the redux-thunk middleware, which allows us
// to do asynchronous things in the actions
var createStoreWithMiddleware = null;
if (process.env.NODE_ENV !== 'production') {
  const loggerMiddleware = createLogger();
  createStoreWithMiddleware = applyMiddleware(
    thunk,
    loggerMiddleware
  )(createStore);
  console.log('Mode: Debug 💀');
} else {
  console.log('Mode: Production 🚀');
  createStoreWithMiddleware = applyMiddleware(thunk)(createStore);

  //delete console.log
  (function () {
    var method;
    var noop = function noop() {};
    var methods = [
      'assert',
      'clear',
      'count',
      'debug',
      'dir',
      'dirxml',
      'error',
      'exception',
      'group',
      'groupCollapsed',
      'groupEnd',
      'info',
      'log',
      'markTimeline',
      'profile',
      'profileEnd',
      'table',
      'time',
      'timeEnd',
      'timeStamp',
      'trace',
      'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
      method = methods[length];
      console[method] = noop;
    }
  })();
}

const store = createStoreWithMiddleware(appReducers);
//For auth
const PrivateRoute = (
  { component, ...rest } // eslint-disable-line
) => (
  <Route
    {...rest}
    render={props =>
      store.getState().auth.loggedIn &&
      store.getState().auth.usuarioLogueado ? (
        //true
        React.createElement(component, props)
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location } // eslint-disable-line
          }}
        />
      )
    }
  />
);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Router history={history}>
        <Switch>
          <Route path="/olvido/:token" component={GenericLayout} />
          <Route path="/olvido" component={GenericLayout} />
          <Route path="/login" component={GenericLayout} />
          <Route path="/signup" component={GenericLayout} />
          <PrivateRoute path="/" component={Layout} />
        </Switch>
      </Router>
    </MuiPickersUtilsProvider>
  </Provider>
);
