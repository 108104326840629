import { normalize, schema, denormalize } from 'normalizr';
import ticketActivity from './normalizeTicketActivities';
import user from './normalizeUsers';
import ticketProperty from './normalizeTicketProperties';
import propertyClosedOperation from './normalizePropertyClosedOperations';

const ticket = new schema.Entity(
  'tickets',
  {
    ticket_activity: [ticketActivity],
    user: user,
    ticket_property: [ticketProperty],
    property_closed_operation: [propertyClosedOperation]
  },
  { idAttribute: 'id' }
);

export function normalizeDatos(myData) {
  const mySchema = { tickets: [ticket] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = ticket;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = ticket;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default ticket;
