//api
import propertiesApi from '../api/propertiesApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizeProperties';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//PROPERTIES
export const REQUEST_PROPERTIES = 'REQUEST_PROPERTIES';
export const RECEIVE_PROPERTIES = 'RECEIVE_PROPERTIES';
export const INVALIDATE_PROPERTIES = 'INVALIDATE_PROPERTIES';
export const ERROR_PROPERTIES = 'ERROR_PROPERTIES';
export const RESET_PROPERTIES = 'RESET_PROPERTIES';

export function invalidateProperties() {
  return {
    type: INVALIDATE_PROPERTIES
  };
}

function requestProperties() {
  return {
    type: REQUEST_PROPERTIES
  };
}

function receiveProperties(json) {
  return {
    type: RECEIVE_PROPERTIES,
    properties: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorProperties(error) {
  return {
    type: ERROR_PROPERTIES,
    error: error
  };
}

export function resetProperties() {
  return {
    type: RESET_PROPERTIES
  };
}

export function fetchProperties(filtros) {
  return dispatch => {
    dispatch(requestProperties());
    return propertiesApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveProperties(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorProperties(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorProperties(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

function shouldFetchProperties(state) {
  const properties = state.properties.byId;
  if (!properties) {
    return true;
  } else if (properties.isFetching) {
    return false;
  } else {
    return properties.didInvalidate;
  }
}

export function fetchPropertiesIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchProperties(getState())) {
      return dispatch(fetchProperties(filtros));
    }
  };
}

//MODEL
export const REQUEST_PROPERTY = 'REQUEST_PROPERTY';
export const RECEIVE_PROPERTY = 'RECEIVE_PROPERTY';
export const INVALIDATE_PROPERTY = 'INVALIDATE_PROPERTY';
export const ERROR_PROPERTY = 'ERROR_PROPERTY';
export const RESET_PROPERTY = 'RESET_PROPERTY';

export function invalidateProperty() {
  return {
    type: INVALIDATE_PROPERTY
  };
}

function requestProperty() {
  return {
    type: REQUEST_PROPERTY
  };
}

export function receiveProperty(json) {
  return {
    type: RECEIVE_PROPERTY,
    property: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorProperty(error) {
  return {
    type: ERROR_PROPERTY,
    error: error
  };
}

export function fetchProperty(idProperty) {
  return dispatch => {
    dispatch(requestProperty());
    return propertiesApi
      .getOne(idProperty)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveProperty(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(errorProperty(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorProperty(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_PROPERTY = 'RECEIVE_FILE_PROPERTY';

function receiveFileProperty(file) {
  return {
    type: RECEIVE_FILE_PROPERTY,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFileProperty(idProperty, filtros) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return propertiesApi
      .getFile(idProperty, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFileProperty(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorProperty(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorProperty(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_PROPERTY = 'UPDATE_PROPERTY';
export const REQUEST_UPDATE_PROPERTY = 'REQUEST_UPDATE_PROPERTY';
export const SUCCESS_UPDATE_PROPERTY = 'SUCCESS_UPDATE_PROPERTY';
export const ERROR_UPDATE_PROPERTY = 'ERROR_UPDATE_PROPERTY';
export const RESET_UPDATE_PROPERTY = 'RESET_UPDATE_PROPERTY';
export const DELETE_UPDATE_PROPERTY = 'DELETE_UPDATE_PROPERTY';

function requestUpdateProperty() {
  return {
    type: REQUEST_UPDATE_PROPERTY
  };
}

function receiveUpdateProperty(property) {
  return {
    type: SUCCESS_UPDATE_PROPERTY,
    receivedAt: Date.now(),
    property: normalizeDato(property)
  };
}

function errorUpdateProperty(error) {
  return {
    type: ERROR_UPDATE_PROPERTY,
    error: error
  };
}

export function resetUpdateProperty() {
  return {
    type: RESET_UPDATE_PROPERTY
  };
}

export function updateProperty(property) {
  return {
    type: UPDATE_PROPERTY,
    property
  };
}

export function saveUpdateProperty(files) {
  return (dispatch, getState) => {
    dispatch(requestUpdateProperty());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let property = denormalizeDato(getState().properties.update.activo, store);

    return propertiesApi
      .saveUpdate(property, files)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateProperty(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateProperty(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateProperties(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdateProperties(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateProperty(JSON.parse(error.message)));
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdateProperty(error.data));
                })
                .catch(() => {
                  dispatch(errorUpdateProperty(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

export function deleteUpdateProperty(property) {
  return {
    type: DELETE_UPDATE_PROPERTY,
    property
  };
}

//UPDATE PROPERTIES
export const REQUEST_UPDATE_PROPERTIES = 'REQUEST_UPDATE_PROPERTIES';
export const SUCCESS_UPDATE_PROPERTIES = 'SUCCESS_UPDATE_PROPERTIES';
export const ERROR_UPDATE_PROPERTIES = 'ERROR_UPDATE_PROPERTIES';
export const RESET_UPDATE_PROPERTIES = 'RESET_UPDATE_PROPERTIES';

function requestUpdateProperties() {
  return {
    type: REQUEST_UPDATE_PROPERTIES
  };
}

function receiveUpdateProperties(properties) {
  return {
    type: SUCCESS_UPDATE_PROPERTIES,
    receivedAt: Date.now(),
    properties: normalizeDatos(properties)
  };
}

function errorUpdateProperties(error) {
  return {
    type: ERROR_UPDATE_PROPERTIES,
    error: error
  };
}

export function resetUpdateProperties() {
  return {
    type: RESET_UPDATE_PROPERTIES
  };
}

export function saveUpdateProperties() {
  return (dispatch, getState) => {
    dispatch(requestUpdateProperties());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let properties = getState().properties.update.activos.map(idProperty => {
      return denormalizeDato(
        getState().properties.update.activo[idProperty],
        store
      );
    });

    return propertiesApi
      .saveUpdateProperties(properties)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateProperties(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateProperties(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateProperties(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdateProperties(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateProperties(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorUpdateProperties(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//ALTA PROPERTY
export const CREATE_PROPERTY = 'CREATE_PROPERTY';
export const REQUEST_CREATE_PROPERTY = 'REQUEST_CREATE_PROPERTY';
export const SUCCESS_CREATE_PROPERTY = 'SUCCESS_CREATE_PROPERTY';
export const ERROR_CREATE_PROPERTY = 'ERROR_CREATE_PROPERTY';
export const RESET_CREATE_PROPERTY = 'RESET_CREATE_PROPERTY';
export const DELETE_CREATE_PROPERTY = 'DELETE_CREATE_PROPERTY';

//ALTA PROPERTY
function requestCreateProperty() {
  return {
    type: REQUEST_CREATE_PROPERTY
  };
}

function receiveCreateProperty(property) {
  return {
    type: SUCCESS_CREATE_PROPERTY,
    receivedAt: Date.now(),
    property: normalizeDato(property)
  };
}

export function errorCreateProperty(error) {
  return {
    type: ERROR_CREATE_PROPERTY,
    error: error
  };
}

export function resetCreateProperty() {
  return {
    type: RESET_CREATE_PROPERTY
  };
}

export function createProperty(property) {
  return {
    type: CREATE_PROPERTY,
    property
  };
}

export function deleteCreateProperty(property) {
  return {
    type: DELETE_CREATE_PROPERTY,
    property
  };
}

export function saveCreateProperty(files) {
  return (dispatch, getState) => {
    dispatch(requestCreateProperty());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let property = denormalizeDato(getState().properties.create.nuevo, store);

    return propertiesApi
      .saveCreate(property, files)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateProperty(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateProperty(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateProperties(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreateProperties(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateProperty(JSON.parse(error.message)));
                  if (error.data) dispatch(receiveCreateProperty(error.data));
                })
                .catch(() => {
                  dispatch(errorCreateProperty(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//CREATE PROPERTIES
export const REQUEST_CREATE_PROPERTIES = 'REQUEST_CREATE_PROPERTIES';
export const SUCCESS_CREATE_PROPERTIES = 'SUCCESS_CREATE_PROPERTIES';
export const ERROR_CREATE_PROPERTIES = 'ERROR_CREATE_PROPERTIES';
export const RESET_CREATE_PROPERTIES = 'RESET_CREATE_PROPERTIES';

function requestCreateProperties() {
  return {
    type: REQUEST_CREATE_PROPERTIES
  };
}

function receiveCreateProperties(properties) {
  return {
    type: SUCCESS_CREATE_PROPERTIES,
    receivedAt: Date.now(),
    properties: normalizeDatos(properties)
  };
}

function errorCreateProperties(error) {
  return {
    type: ERROR_CREATE_PROPERTIES,
    error: error
  };
}

export function resetCreateProperties() {
  return {
    type: RESET_CREATE_PROPERTIES
  };
}

export function saveCreateProperties() {
  return (dispatch, getState) => {
    dispatch(requestCreateProperties());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let properties = getState().properties.create.nuevos.map(idProperty => {
      return denormalizeDato(
        getState().properties.create.nuevo[idProperty],
        store
      );
    });

    return propertiesApi
      .saveCreateProperties(properties)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateProperties(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateProperties(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateProperties(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreateProperties(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateProperties(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorCreateProperties(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//DELETE PROPERTY
export const DELETE_PROPERTY = 'DELETE_PROPERTY';
export const REQUEST_DELETE_PROPERTY = 'REQUEST_DELETE_PROPERTY';
export const SUCCESS_DELETE_PROPERTY = 'SUCCESS_DELETE_PROPERTY';
export const ERROR_DELETE_PROPERTY = 'ERROR_DELETE_PROPERTY';
export const RESET_DELETE_PROPERTY = 'RESET_DELETE_PROPERTY';

function requestDeleteProperty() {
  return {
    type: REQUEST_DELETE_PROPERTY
  };
}

function receiveDeleteProperty(property) {
  return {
    type: SUCCESS_DELETE_PROPERTY,
    receivedAt: Date.now(),
    property: normalizeDato(property)
  };
}

function errorDeleteProperty(error) {
  return {
    type: ERROR_DELETE_PROPERTY,
    error: error
  };
}

export function resetDeleteProperty(error) {
  return {
    type: RESET_DELETE_PROPERTY,
    error: error
  };
}

export function deleteProperty(property) {
  return {
    type: DELETE_PROPERTY,
    property
  };
}

export function saveDeleteProperty(property) {
  return dispatch => {
    dispatch(requestDeleteProperty());
    return propertiesApi
      .saveDelete(property)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeleteProperty());
        dispatch(receiveDeleteProperty(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorDeleteProperty(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeleteProperty(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorDeleteProperty(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorDeleteProperty(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorDeleteProperty(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT PROPERTY
export const PRINT_PROPERTY = 'PRINT_PROPERTY';
export const REQUEST_PRINT_PROPERTY = 'REQUEST_PRINT_PROPERTY';
export const SUCCESS_PRINT_PROPERTY = 'SUCCESS_PRINT_PROPERTY';
export const ERROR_PRINT_PROPERTY = 'ERROR_PRINT_PROPERTY';
export const RESET_PRINT_PROPERTY = 'RESET_PRINT_PROPERTY';
export const DELETE_PRINT_PROPERTY = 'DELETE_PRINT_PROPERTY';

function requestPrintProperty() {
  return {
    type: REQUEST_PRINT_PROPERTY
  };
}

function receivePrintProperty(turnos) {
  return {
    type: SUCCESS_PRINT_PROPERTY,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintProperty(error) {
  return {
    type: ERROR_PRINT_PROPERTY,
    error: error
  };
}

export function resetPrintProperty() {
  return {
    type: RESET_PRINT_PROPERTY
  };
}

export function printProperty(property) {
  return {
    type: PRINT_PROPERTY,
    property
  };
}

export function deletePrintProperty(property) {
  return {
    type: DELETE_PRINT_PROPERTY,
    property
  };
}

export function savePrintProperty(idProperty) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintProperty());
    return propertiesApi
      .printProperty(idProperty)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintProperty(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintProperty(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintProperty(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintProperty(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintProperty(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT PROPERTIES
export const REQUEST_PRINT_PROPERTIES = 'REQUEST_PRINT_PROPERTIES';
export const SUCCESS_PRINT_PROPERTIES = 'SUCCESS_PRINT_PROPERTIES';
export const ERROR_PRINT_PROPERTIES = 'ERROR_PRINT_PROPERTIES';
export const RESET_PRINT_PROPERTIES = 'RESET_PRINT_PROPERTIES';

function requestPrintProperties() {
  return {
    type: REQUEST_PRINT_PROPERTIES
  };
}

function receivePrintProperties(properties) {
  return {
    type: SUCCESS_PRINT_PROPERTIES,
    receivedAt: Date.now(),
    properties: normalizeDatos(properties)
  };
}

function errorPrintProperties(error) {
  return {
    type: ERROR_PRINT_PROPERTIES,
    error: error
  };
}

export function resetPrintProperties() {
  return {
    type: RESET_PRINT_PROPERTIES
  };
}

export function savePrintProperties() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintProperty());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let properties = getState().properties.print.printers.map(idProperty => {
      return denormalizeDato(
        getState().properties.print.print[idProperty],
        store
      );
    });
    return propertiesApi
      .printProperties(properties)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintProperties(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintProperties(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintProperties(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintProperties(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintProperties(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}
