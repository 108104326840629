import React, { useEffect, useRef } from 'react';
import clone from 'lodash/clone';
import {
  createTicketActivity,
  deleteTicketActivity,
  updateTicketActivity
} from '../../../actions/TicketActivityActions';
import { useDispatch, useSelector } from 'react-redux';
import { saveUpdateTicket } from '../../../actions/TicketActions';
import WhatsAppActivityModal from './WhatsAppActivityModal';
import NoteActivityModal from './NoteActivityModal';
import EmailActivityModal from './EmailActivityModal';
import AppointmentActivityModal from './AppointmentActivityModal';

export default function ActivityModal(props) {
  const dispatch = useDispatch();
  const { open, setOpen, type = 'WhatsApp', idTicketActivity, user } = props;
  const tickets = useSelector(state => state.tickets);
  const ticketActivities = useSelector(state => state.ticketActivities);
  const ticketActivity =
    idTicketActivity && idTicketActivity.toString().indexOf('-') === -1
      ? ticketActivities.update.activo[idTicketActivity]
      : ticketActivities.create.nuevo[idTicketActivity];
  const mounted = useRef(false);
  const disabled =
    tickets.update.isUpdating ||
    tickets.create.isCreating ||
    tickets.byId.isFetching;

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (!tickets.update.isUpdating && !tickets.update.error && open) {
        handleClose();
      }
    }
  }, [tickets.update.isUpdating]);

  const handleChangeTicketActivity = e => {
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    cambio.type = type;
    changeTicketActivity(cambio);
  };

  const handleDateChangeTicketActivity = e => {
    let cambio = {};
    cambio.visit_date = e.format('YYYY-MM-DD');
    changeTicketActivity(cambio);
  };

  const handleTimeChangeTicketActivity = e => {
    let cambio = {};
    cambio.visit_time = e.format('HH:mm');
    changeTicketActivity(cambio);
  };

  const changeTicketActivity = cambio => {
    cambio.id = idTicketActivity;
    let cambioTicketActivity = {};
    cambioTicketActivity[idTicketActivity] = cambio;
    if (idTicketActivity.toString().indexOf('-') === -1)
      dispatch(updateTicketActivity(cambioTicketActivity));
    else dispatch(createTicketActivity(cambioTicketActivity));
  };

  const deleteTicketActivityNueva = () => {
    let cambio = ticketActivities.create.nuevo[idTicketActivity]
      ? clone(ticketActivities.create.nuevo[idTicketActivity])
      : { id: idTicketActivity };
    let cambioTicketActivity = {};
    cambioTicketActivity[idTicketActivity] = cambio;
    dispatch(deleteTicketActivity(cambioTicketActivity));
  };

  const handleAccept = e => {
    dispatch(saveUpdateTicket());
  };

  const handleClose = e => {
    if (idTicketActivity.toString().indexOf('-') === -1) {
      let attribute = clone(
        ticketActivities.byId.ticketActivities[idTicketActivity]
      );
      changeTicketActivity(attribute);
    } else {
      deleteTicketActivityNueva({});
    }
    setOpen(false);
  };

  return (
    <>
      {type === 'WhatsApp' && (
        <WhatsAppActivityModal
          handleClose={handleClose}
          handleAccept={handleAccept}
          handleChangeTicketActivity={handleChangeTicketActivity}
          ticketActivity={ticketActivity}
          open={open}
          cellphone={user?.cellphone}
          ticket_property={tickets.update.activo.ticket_property}
          disabled={disabled}
        />
      )}
      {type === 'Nota' && (
        <NoteActivityModal
          handleClose={handleClose}
          handleAccept={handleAccept}
          handleChangeTicketActivity={handleChangeTicketActivity}
          ticketActivity={ticketActivity}
          open={open}
          cellphone={user?.cellphone}
          ticket_property={tickets.update.activo.ticket_property}
          disabled={disabled}
        />
      )}
      {type === 'Email' && (
        <EmailActivityModal
          handleClose={handleClose}
          handleAccept={handleAccept}
          handleChangeTicketActivity={handleChangeTicketActivity}
          ticketActivity={ticketActivity}
          open={open}
          email={user?.email}
          ticket_property={tickets.update.activo.ticket_property}
          disabled={disabled}
        />
      )}
      {type === 'Cita' && (
        <AppointmentActivityModal
          handleClose={handleClose}
          handleAccept={handleAccept}
          handleChangeTicketActivity={handleChangeTicketActivity}
          ticketActivity={ticketActivity}
          open={open}
          email={user?.email}
          handleTimeChangeTicketActivity={handleTimeChangeTicketActivity}
          handleDateChangeTicketActivity={handleDateChangeTicketActivity}
          ticket_property={tickets.update.activo.ticket_property}
          disabled={disabled}
        />
      )}
    </>
  );
}
