import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useValidador } from '../../../utils/validador';
import moment from 'moment/moment';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import TicketPropertySelector from '../../TicketProperty/TicketPropertySelector';

export default function AppointmentActivityModal(props) {
  const {
    ticketActivity,
    handleClose,
    handleChangeTicketActivity,
    handleDateChangeTicketActivity,
    handleTimeChangeTicketActivity,
    cellphone,
    open,
    ticket_property,
    disabled
  } = props;
  //Hooks
  let reglas = {};
  reglas = {
    text: ['required']
  };
  const [validador, validar] = useValidador(reglas);

  function handleAccept() {
    if (validador.isValid()) {
      props.handleAccept();
      if (cellphone && ticketActivity.message)
        window.open(
          `https://api.whatsapp.com/send?phone=${cellphone.replaceAll(
            '+',
            ''
          )}&text=${ticketActivity.message}`
        );
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Programar cita</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Programaremos una visitar o un encuentro
          </DialogContentText>
          <DatePicker
            autoFocus
            autoOk
            clearable
            label="Fecha de visita"
            margin={'dense'}
            variant="outlined"
            disablePast
            value={
              ticketActivity && ticketActivity.visit_date
                ? moment(ticketActivity.visit_date)
                : null
            }
            fullWidth
            onChange={handleDateChangeTicketActivity}
            format="DD/MM/yyyy"
            views={['year', 'month', 'date']}
            disabled={disabled}
          />
          <TimePicker
            autoOk
            label="Hora de visita"
            clearable
            margin={'dense'}
            variant="outlined"
            disablePast
            value={
              ticketActivity && ticketActivity.visit_time
                ? moment(ticketActivity.visit_time, 'HH:mm')
                : null
            }
            fullWidth
            onChange={handleTimeChangeTicketActivity}
            format="HH:mm"
            disabled={disabled}
          />
          <TextField
            margin="dense"
            id="text"
            label="Texto del mensaje"
            type="text"
            multiline
            rows={3}
            fullWidth
            value={
              ticketActivity && ticketActivity.text ? ticketActivity.text : ''
            }
            onChange={handleChangeTicketActivity}
            onBlur={validar}
            error={!validador.isValidById('text')}
            helperText={validador.getHelperTextById('text')}
            disabled={disabled}
          />
          <TicketPropertySelector
            model={ticketActivity}
            handleChangeModel={handleChangeTicketActivity}
            id={'property_id'}
            margin="dense"
            fullwidth={true}
            idsTicketProperties={ticket_property}
            description={'Propiedad'}
            disabled={disabled}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={disabled}>
            Cancelar
          </Button>
          <Button onClick={handleAccept} color="primary" disabled={disabled}>
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
