import {
  INVALIDATE_ATTRIBUTESVALUES,
  ERROR_ATTRIBUTESVALUES,
  RECEIVE_ATTRIBUTESVALUES,
  REQUEST_ATTRIBUTESVALUES,
  RESET_ATTRIBUTESVALUES,
  ERROR_ATTRIBUTEVALUE,
  RECEIVE_ATTRIBUTEVALUE,
  REQUEST_ATTRIBUTEVALUE,
  UPDATE_ATTRIBUTEVALUE,
  REQUEST_UPDATE_ATTRIBUTEVALUE,
  SUCCESS_UPDATE_ATTRIBUTEVALUE,
  ERROR_UPDATE_ATTRIBUTEVALUE,
  RESET_UPDATE_ATTRIBUTEVALUE,
  REQUEST_UPDATE_ATTRIBUTESVALUES,
  SUCCESS_UPDATE_ATTRIBUTESVALUES,
  ERROR_UPDATE_ATTRIBUTESVALUES,
  RESET_UPDATE_ATTRIBUTESVALUES,
  CREATE_ATTRIBUTEVALUE,
  ERROR_CREATE_ATTRIBUTEVALUE,
  REQUEST_CREATE_ATTRIBUTEVALUE,
  RESET_CREATE_ATTRIBUTEVALUE,
  SUCCESS_CREATE_ATTRIBUTEVALUE,
  REQUEST_CREATE_ATTRIBUTESVALUES,
  SUCCESS_CREATE_ATTRIBUTESVALUES,
  ERROR_CREATE_ATTRIBUTESVALUES,
  RESET_CREATE_ATTRIBUTESVALUES,
  DELETE_ATTRIBUTEVALUE,
  DELETE_CREATE_ATTRIBUTEVALUE,
  DELETE_UPDATE_ATTRIBUTEVALUE,
  REQUEST_DELETE_ATTRIBUTEVALUE,
  SUCCESS_DELETE_ATTRIBUTEVALUE,
  ERROR_DELETE_ATTRIBUTEVALUE,
  RESET_DELETE_ATTRIBUTEVALUE,
  REQUEST_PRINT_ATTRIBUTEVALUE,
  SUCCESS_PRINT_ATTRIBUTEVALUE,
  ERROR_PRINT_ATTRIBUTEVALUE,
  RESET_PRINT_ATTRIBUTEVALUE,
  RECEIVE_FILE_ATTRIBUTEVALUE,
  REQUEST_PRINT_ATTRIBUTESVALUES,
  SUCCESS_PRINT_ATTRIBUTESVALUES,
  ERROR_PRINT_ATTRIBUTESVALUES,
  RESET_PRINT_ATTRIBUTESVALUES,
  RECEIVE_FILE_ATTRIBUTESVALUES,
  PRINT_ATTRIBUTEVALUE,
  DELETE_PRINT_ATTRIBUTEVALUE
} from '../actions/AttributeValueActions';

import {
  RECEIVE_ATTRIBUTEGROUP,
  RECEIVE_ATTRIBUTESGROUPS,
  SUCCESS_DELETE_ATTRIBUTEGROUP,
  SUCCESS_CREATE_ATTRIBUTEGROUP,
  SUCCESS_UPDATE_ATTRIBUTEGROUP,
  SUCCESS_UPDATE_ATTRIBUTESGROUPS
} from '../actions/AttributeGroupActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    attributesValues: {},
    files: {}
  };
}

function attributesValuesById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_ATTRIBUTESVALUES:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_ATTRIBUTESVALUES:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_ATTRIBUTESVALUES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_ATTRIBUTESVALUES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        attributesValues: {}
      });
    case RECEIVE_ATTRIBUTESVALUES:
      let dato = action.attributesValues.entities.attributesValues;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        attributesValues: merge({}, state.attributesValues, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_ATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_ATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_ATTRIBUTEVALUE:
      let datoAttributeValue = action.attributeValue.entities.attributesValues;
      return Object.assign({}, state, {
        attributesValues: merge({}, state.attributesValues, datoAttributeValue),
        isFetching: false
      });
    case RECEIVE_FILE_ATTRIBUTEVALUE:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_ATTRIBUTEVALUE:
      let datoAttributeValueEliminado =
        action.attributeValue.entities.attributesValues;
      return Object.assign({}, state, {
        attributesValues: mergeWith(
          clone(datoAttributeValueEliminado),
          state.attributesValues,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_ATTRIBUTEVALUE:
      let datoAttributeValueCreado =
        action.attributeValue.entities.attributesValues;
      return Object.assign({}, state, {
        attributesValues: mergeWith(
          clone(datoAttributeValueCreado),
          state.attributesValues,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_ATTRIBUTESVALUES:
      let datosAttributeValueCreado =
        action.attributesValues.entities.attributesValues;
      return Object.assign({}, state, {
        attributesValues: mergeWith(
          clone(datosAttributeValueCreado),
          state.attributesValues,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_ATTRIBUTEVALUE:
      let datoAttributeValueActualizado =
        action.attributeValue.entities.attributesValues;
      return Object.assign({}, state, {
        attributesValues: mergeWith(
          clone(datoAttributeValueActualizado),
          state.attributesValues,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_ATTRIBUTESVALUES:
      let datosAttributeValueActualizado =
        action.attributesValues.entities.attributesValues;
      return Object.assign({}, state, {
        attributesValues: mergeWith(
          clone(datosAttributeValueActualizado),
          state.attributesValues,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    //ATTRIBUTEGROUP
    case RECEIVE_ATTRIBUTEGROUP:
      let attributeGroup =
        action.attributeGroup.entities &&
        action.attributeGroup.entities.attributesValues
          ? action.attributeGroup.entities.attributesValues
          : {};
      return Object.assign({}, state, {
        attributesValues: merge({}, state.attributesValues, attributeGroup)
      });
    case RECEIVE_ATTRIBUTESGROUPS:
      let attributesGroups =
        action.attributesGroups.entities &&
        action.attributesGroups.entities.attributesValues
          ? action.attributesGroups.entities.attributesValues
          : {};
      return Object.assign({}, state, {
        attributesValues: merge({}, state.attributesValues, attributesGroups)
      });
    case SUCCESS_DELETE_ATTRIBUTEGROUP:
      let datoattributeGroupEliminado =
        action.attributeGroup.entities &&
        action.attributeGroup.entities.attributesValues
          ? action.attributeGroup.entities.attributesValues
          : {};
      return Object.assign({}, state, {
        attributesValues: mergeWith(
          clone(datoattributeGroupEliminado),
          pickBy(state.attributesValues, function (attributeValue) {
            return attributeValue.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_ATTRIBUTEGROUP:
      let datoattributeGroupCreado =
        action.attributeGroup.entities &&
        action.attributeGroup.entities.attributesValues
          ? action.attributeGroup.entities.attributesValues
          : {};
      return Object.assign({}, state, {
        attributesValues: mergeWith(
          clone(datoattributeGroupCreado),
          pickBy(state.attributesValues, function (attributeValue) {
            return attributeValue.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_ATTRIBUTEGROUP:
      let datoattributeGroupActualizado =
        action.attributeGroup.entities &&
        action.attributeGroup.entities.attributesValues
          ? action.attributeGroup.entities.attributesValues
          : {};
      return Object.assign({}, state, {
        attributesValues: mergeWith(
          clone(datoattributeGroupActualizado),
          pickBy(state.attributesValues, function (attributeValue) {
            return attributeValue.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_ATTRIBUTESGROUPS:
      let datosattributeGroupActualizado =
        action.attributesGroups.entities &&
        action.attributesGroups.entities.attributesValues
          ? action.attributesGroups.entities.attributesValues
          : {};
      return Object.assign({}, state, {
        attributesValues: mergeWith(
          clone(datosattributeGroupActualizado),
          state.attributesValues,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        attributesValues: {}
      });
    default:
      return state;
  }
}

function allAttributesValues(state = [], action) {
  switch (action.type) {
    case RECEIVE_ATTRIBUTESVALUES:
      return action.attributesValues.result &&
        action.attributesValues.result.attributesValues
        ? union(action.attributesValues.result.attributesValues, state)
        : action.attributesValues.result
        ? action.attributesValues.result
        : state;
    case RECEIVE_ATTRIBUTEVALUE:
      return action.attributeValue.result
        ? union([action.attributeValue.result], state)
        : state;

    case SUCCESS_CREATE_ATTRIBUTEVALUE:
      let datoAttributeValueSCreate =
        action.attributeValue.entities.attributesValues;
      let idNuevoSCreate = null;
      if (Object.values(datoAttributeValueSCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoAttributeValueSCreate)[0] &&
          Object.values(datoAttributeValueSCreate)[0].id
            ? Object.values(datoAttributeValueSCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_ATTRIBUTESVALUES:
      let attributesValuesCreate =
        action.attributesValues.entities &&
        action.attributesValues.entities.attributesValues
          ? action.attributesValues.entities.attributesValues
          : null;
      return attributesValuesCreate
        ? union(
            state,
            Object.values(attributesValuesCreate).map(attributesValues => {
              return attributesValues.id;
            })
          )
        : state;
    case RESET_ATTRIBUTESVALUES:
      return [];

    case RECEIVE_ATTRIBUTEGROUP:
      let attributeGroup =
        action.attributeGroup.entities &&
        action.attributeGroup.entities.attributesValues
          ? action.attributeGroup.entities.attributesValues
          : null;
      return attributeGroup
        ? union(
            state,
            Object.values(attributeGroup).map(attributeGroup => {
              return attributeGroup.id;
            })
          )
        : state;
    case RECEIVE_ATTRIBUTESGROUPS:
      let attributesGroups =
        action.attributesGroups.entities &&
        action.attributesGroups.entities.attributesValues
          ? action.attributesGroups.entities.attributesValues
          : null;
      return attributesGroups
        ? union(
            state,
            Object.values(attributesGroups).map(attributesGroups => {
              return attributesGroups.id;
            })
          )
        : state;

    case SUCCESS_DELETE_ATTRIBUTEGROUP:
      let attributeGroupDelete =
        action.attributeGroup.entities &&
        action.attributeGroup.entities.attributesValues
          ? action.attributeGroup.entities.attributesValues
          : null;
      return attributeGroupDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(attributeGroupDelete).map(attributeGroup => {
              return attributeGroup.id;
            })
          )
        : state;
    case SUCCESS_CREATE_ATTRIBUTEGROUP:
      let attributeGroupCreate =
        action.attributeGroup.entities &&
        action.attributeGroup.entities.attributesValues
          ? action.attributeGroup.entities.attributesValues
          : null;
      return attributeGroupCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(attributeGroupCreate).map(attributeGroup => {
              return attributeGroup.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_ATTRIBUTEGROUP:
      let attributeGroupUpdate =
        action.attributeGroup.entities &&
        action.attributeGroup.entities.attributesValues
          ? action.attributeGroup.entities.attributesValues
          : null;
      return attributeGroupUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(attributeGroupUpdate).map(attributeGroup => {
              return attributeGroup.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_ATTRIBUTESGROUPS:
      let attributesGroupsUpdate =
        action.attributesGroups.entities &&
        action.attributesGroups.entities.attributesValues
          ? action.attributesGroups.entities.attributesValues
          : null;
      return attributesGroupsUpdate
        ? union(
            state,
            Object.values(attributesGroupsUpdate).map(attributesGroups => {
              return attributesGroups.id;
            })
          )
        : state;

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalAttributesValues(state = null, action) {
  switch (action.type) {
    case RECEIVE_ATTRIBUTESVALUES:
      return action.attributesValues && action.attributesValues.result.total
        ? action.attributesValues.result.total
        : 0;
    case RESET_ATTRIBUTESVALUES:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_ATTRIBUTEVALUE:
      let dato = action.attributeValue.entities.attributesValues;
      let attributeValue =
        dato && Object.keys(dato).length > 0
          ? dato[action.attributeValue.result]
          : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: attributeValue ? attributeValue : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_ATTRIBUTEVALUE:
      let idsUpdate = [];
      Object.values(action.attributeValue).map(attributeValueUpdate => {
        if (attributeValueUpdate && attributeValueUpdate.id)
          idsUpdate.push(attributeValueUpdate.id);
      });
      return merge({}, state, {
        activo: action.attributeValue,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_ATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_ATTRIBUTEVALUE:
      let datoAttributeValueActualizado =
        action.attributeValue.entities.attributesValues;
      let attributeValueNuevo =
        datoAttributeValueActualizado &&
        Object.keys(datoAttributeValueActualizado).length > 0
          ? datoAttributeValueActualizado[action.attributeValue.result]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: attributeValueNuevo
      });
    case ERROR_UPDATE_ATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_ATTRIBUTESVALUES:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_ATTRIBUTESVALUES:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_ATTRIBUTESVALUES:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_ATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    //ATTRIBUTEGROUP
    //TODO ver si esta bien
    case SUCCESS_CREATE_ATTRIBUTEGROUP:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_ATTRIBUTEGROUP:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_ATTRIBUTEGROUP:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_ATTRIBUTESGROUPS:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });

    case DELETE_ATTRIBUTEVALUE:
      let datoAttributeValueDelete = action.attributeValue;
      let idsDelete = [];
      Object.values(action.attributeValue).map(attributeValueDelete => {
        if (attributeValueDelete && attributeValueDelete.id)
          idsDelete.push(attributeValueDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoAttributeValueDelete)
          ),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_ATTRIBUTEVALUE:
      let datoAttributeValueDeleteUpdate = action.attributeValue;
      let idsDeleteUpdate = [];
      Object.values(action.attributeValue).map(attributeValueDelete => {
        if (attributeValueDelete && attributeValueDelete.id)
          idsDeleteUpdate.push(attributeValueDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoAttributeValueDeleteUpdate)
          ),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_ATTRIBUTEVALUE:
      let datoAttributeValueDeleted = {};
      if (
        Object.values(action.attributeValue.entities.attributesValues).length >
        0
      )
        datoAttributeValueDeleted = Object.values(
          action.attributeValue.entities.attributesValues
        )[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoAttributeValueDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_ATTRIBUTEVALUE:
      let idsCreate = [];
      Object.values(action.attributeValue).map(attributeValueCreate => {
        if (attributeValueCreate && attributeValueCreate.id)
          idsCreate.push(attributeValueCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.attributeValue,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_ATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_ATTRIBUTEVALUE:
      let datoAttributeValueNuevo =
        action.attributeValue.entities.attributesValues;
      let attributeValueNuevo =
        datoAttributeValueNuevo &&
        Object.keys(datoAttributeValueNuevo).length > 0
          ? datoAttributeValueNuevo[action.attributeValue.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: attributeValueNuevo,
        nuevos: []
      });
    case ERROR_CREATE_ATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_ATTRIBUTESVALUES:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_ATTRIBUTESVALUES:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_ATTRIBUTESVALUES:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_ATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    //ATTRIBUTEGROUP
    case SUCCESS_CREATE_ATTRIBUTEGROUP:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_ATTRIBUTEGROUP:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_ATTRIBUTEGROUP:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_ATTRIBUTESGROUPS:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });

    case DELETE_ATTRIBUTEVALUE:
      let datoAttributeValueDelete = action.attributeValue;
      let idsDelete = [];
      Object.values(action.attributeValue).map(attributeValueDelete => {
        if (attributeValueDelete && attributeValueDelete.id)
          idsDelete.push(attributeValueDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoAttributeValueDelete)
          ),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_ATTRIBUTEVALUE:
      let datoAttributeValueDeleteCreate = action.attributeValue;
      let idsDeleteCreate = [];
      Object.values(action.attributeValue).map(attributeValueDelete => {
        if (attributeValueDelete && attributeValueDelete.id)
          idsDeleteCreate.push(attributeValueDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoAttributeValueDeleteCreate)
          ),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_ATTRIBUTEVALUE:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.attributeValue,
        error: null
      });
    case REQUEST_DELETE_ATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_ATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_ATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_ATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    //ATTRIBUTEGROUP
    case SUCCESS_CREATE_ATTRIBUTEGROUP:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_ATTRIBUTEGROUP:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_ATTRIBUTEGROUP:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_ATTRIBUTESGROUPS:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_ATTRIBUTEVALUE:
      let idsCreate = [];
      Object.values(action.attributeValue).map(attributeValueCreate => {
        if (attributeValueCreate && attributeValueCreate.id)
          idsCreate.push(attributeValueCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.attributeValue,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_ATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_ATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_ATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_ATTRIBUTESVALUES:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_ATTRIBUTESVALUES:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_ATTRIBUTESVALUES:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_ATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_ATTRIBUTEVALUE:
      let datoAttributeValueDeleteCreate = action.attributeValue;
      let idsDeleteCreate = [];
      Object.values(action.attributeValue).map(attributeValueDelete => {
        if (attributeValueDelete && attributeValueDelete.id)
          idsDeleteCreate.push(attributeValueDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(
            clone(state.print),
            Object.keys(datoAttributeValueDeleteCreate)
          ),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const attributesValues = combineReducers({
  byId: attributesValuesById,
  allIds: allAttributesValues,
  update: update,
  create: create,
  totalAttributesValues: totalAttributesValues,
  delete: deleter,
  print: print
});

export default attributesValues;
