//api
import attributesValuesApi from '../api/attributesValuesApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizeAttributesValues';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//ATTRIBUTESVALUES
export const REQUEST_ATTRIBUTESVALUES = 'REQUEST_ATTRIBUTESVALUES';
export const RECEIVE_ATTRIBUTESVALUES = 'RECEIVE_ATTRIBUTESVALUES';
export const INVALIDATE_ATTRIBUTESVALUES = 'INVALIDATE_ATTRIBUTESVALUES';
export const ERROR_ATTRIBUTESVALUES = 'ERROR_ATTRIBUTESVALUES';
export const RESET_ATTRIBUTESVALUES = 'RESET_ATTRIBUTESVALUES';

export function invalidateAttributesValues() {
  return {
    type: INVALIDATE_ATTRIBUTESVALUES
  };
}

function requestAttributesValues() {
  return {
    type: REQUEST_ATTRIBUTESVALUES
  };
}

function receiveAttributesValues(json) {
  return {
    type: RECEIVE_ATTRIBUTESVALUES,
    attributesValues: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorAttributesValues(error) {
  return {
    type: ERROR_ATTRIBUTESVALUES,
    error: error
  };
}

export function resetAttributesValues() {
  return {
    type: RESET_ATTRIBUTESVALUES
  };
}

export function fetchAttributesValues(filtros) {
  return dispatch => {
    dispatch(requestAttributesValues());
    return attributesValuesApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveAttributesValues(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorAttributesValues(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorAttributesValues(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

function shouldFetchAttributesValues(state) {
  const attributesValues = state.attributesValues.byId;
  if (!attributesValues) {
    return true;
  } else if (attributesValues.isFetching) {
    return false;
  } else {
    return attributesValues.didInvalidate;
  }
}

export function fetchAttributesValuesIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchAttributesValues(getState())) {
      return dispatch(fetchAttributesValues(filtros));
    }
  };
}

//MODEL
export const REQUEST_ATTRIBUTEVALUE = 'REQUEST_ATTRIBUTEVALUE';
export const RECEIVE_ATTRIBUTEVALUE = 'RECEIVE_ATTRIBUTEVALUE';
export const INVALIDATE_ATTRIBUTEVALUE = 'INVALIDATE_ATTRIBUTEVALUE';
export const ERROR_ATTRIBUTEVALUE = 'ERROR_ATTRIBUTEVALUE';
export const RESET_ATTRIBUTEVALUE = 'RESET_ATTRIBUTEVALUE';

export function invalidateAttributeValue() {
  return {
    type: INVALIDATE_ATTRIBUTEVALUE
  };
}

function requestAttributeValue() {
  return {
    type: REQUEST_ATTRIBUTEVALUE
  };
}

export function receiveAttributeValue(json) {
  return {
    type: RECEIVE_ATTRIBUTEVALUE,
    attributeValue: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorAttributeValue(error) {
  return {
    type: ERROR_ATTRIBUTEVALUE,
    error: error
  };
}

export function fetchAttributeValue(idAttributeValue) {
  return dispatch => {
    dispatch(requestAttributeValue());
    return attributesValuesApi
      .getOne(idAttributeValue)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveAttributeValue(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(errorAttributeValue(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorAttributeValue(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_ATTRIBUTEVALUE = 'RECEIVE_FILE_ATTRIBUTEVALUE';

function receiveFileAttributeValue(file) {
  return {
    type: RECEIVE_FILE_ATTRIBUTEVALUE,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFileAttributeValue(idAttributeValue, filtros) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return attributesValuesApi
      .getFile(idAttributeValue, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFileAttributeValue(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorAttributeValue(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorAttributeValue(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_ATTRIBUTEVALUE = 'UPDATE_ATTRIBUTEVALUE';
export const REQUEST_UPDATE_ATTRIBUTEVALUE = 'REQUEST_UPDATE_ATTRIBUTEVALUE';
export const SUCCESS_UPDATE_ATTRIBUTEVALUE = 'SUCCESS_UPDATE_ATTRIBUTEVALUE';
export const ERROR_UPDATE_ATTRIBUTEVALUE = 'ERROR_UPDATE_ATTRIBUTEVALUE';
export const RESET_UPDATE_ATTRIBUTEVALUE = 'RESET_UPDATE_ATTRIBUTEVALUE';
export const DELETE_UPDATE_ATTRIBUTEVALUE = 'DELETE_UPDATE_ATTRIBUTEVALUE';

function requestUpdateAttributeValue() {
  return {
    type: REQUEST_UPDATE_ATTRIBUTEVALUE
  };
}

function receiveUpdateAttributeValue(attributeValue) {
  return {
    type: SUCCESS_UPDATE_ATTRIBUTEVALUE,
    receivedAt: Date.now(),
    attributeValue: normalizeDato(attributeValue)
  };
}

function errorUpdateAttributeValue(error) {
  return {
    type: ERROR_UPDATE_ATTRIBUTEVALUE,
    error: error
  };
}

export function resetUpdateAttributeValue() {
  return {
    type: RESET_UPDATE_ATTRIBUTEVALUE
  };
}

export function updateAttributeValue(attributeValue) {
  return {
    type: UPDATE_ATTRIBUTEVALUE,
    attributeValue
  };
}

export function saveUpdateAttributeValue() {
  return (dispatch, getState) => {
    dispatch(requestUpdateAttributeValue());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let attributeValue = denormalizeDato(
      getState().attributesValues.update.activo,
      store
    );

    return attributesValuesApi
      .saveUpdate(attributeValue)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateAttributeValue(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorUpdateAttributeValue(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateAttributesValues(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorUpdateAttributesValues(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorUpdateAttributeValue(JSON.parse(error.message))
                  );
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdateAttributeValue(error.data));
                })
                .catch(() => {
                  dispatch(
                    errorUpdateAttributeValue(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

export function deleteUpdateAttributeValue(attributeValue) {
  return {
    type: DELETE_UPDATE_ATTRIBUTEVALUE,
    attributeValue
  };
}

//UPDATE ATTRIBUTESVALUES
export const REQUEST_UPDATE_ATTRIBUTESVALUES =
  'REQUEST_UPDATE_ATTRIBUTESVALUES';
export const SUCCESS_UPDATE_ATTRIBUTESVALUES =
  'SUCCESS_UPDATE_ATTRIBUTESVALUES';
export const ERROR_UPDATE_ATTRIBUTESVALUES = 'ERROR_UPDATE_ATTRIBUTESVALUES';
export const RESET_UPDATE_ATTRIBUTESVALUES = 'RESET_UPDATE_ATTRIBUTESVALUES';

function requestUpdateAttributesValues() {
  return {
    type: REQUEST_UPDATE_ATTRIBUTESVALUES
  };
}

function receiveUpdateAttributesValues(attributesValues) {
  return {
    type: SUCCESS_UPDATE_ATTRIBUTESVALUES,
    receivedAt: Date.now(),
    attributesValues: normalizeDatos(attributesValues)
  };
}

function errorUpdateAttributesValues(error) {
  return {
    type: ERROR_UPDATE_ATTRIBUTESVALUES,
    error: error
  };
}

export function resetUpdateAttributesValues() {
  return {
    type: RESET_UPDATE_ATTRIBUTESVALUES
  };
}

export function saveUpdateAttributesValues() {
  return (dispatch, getState) => {
    dispatch(requestUpdateAttributesValues());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let attributesValues = getState().attributesValues.update.activos.map(
      idAttributeValue => {
        return denormalizeDato(
          getState().attributesValues.update.activo[idAttributeValue],
          store
        );
      }
    );

    return attributesValuesApi
      .saveUpdateAttributesValues(attributesValues)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateAttributesValues(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorUpdateAttributesValues(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateAttributesValues(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorUpdateAttributesValues(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorUpdateAttributesValues(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorUpdateAttributesValues(errorMessages.GENERAL_ERROR)
                  );
                });

            return;
        }
      });
  };
}

//ALTA ATTRIBUTEVALUE
export const CREATE_ATTRIBUTEVALUE = 'CREATE_ATTRIBUTEVALUE';
export const REQUEST_CREATE_ATTRIBUTEVALUE = 'REQUEST_CREATE_ATTRIBUTEVALUE';
export const SUCCESS_CREATE_ATTRIBUTEVALUE = 'SUCCESS_CREATE_ATTRIBUTEVALUE';
export const ERROR_CREATE_ATTRIBUTEVALUE = 'ERROR_CREATE_ATTRIBUTEVALUE';
export const RESET_CREATE_ATTRIBUTEVALUE = 'RESET_CREATE_ATTRIBUTEVALUE';
export const DELETE_CREATE_ATTRIBUTEVALUE = 'DELETE_CREATE_ATTRIBUTEVALUE';

//ALTA ATTRIBUTEVALUE
function requestCreateAttributeValue() {
  return {
    type: REQUEST_CREATE_ATTRIBUTEVALUE
  };
}

function receiveCreateAttributeValue(attributeValue) {
  return {
    type: SUCCESS_CREATE_ATTRIBUTEVALUE,
    receivedAt: Date.now(),
    attributeValue: normalizeDato(attributeValue)
  };
}

export function errorCreateAttributeValue(error) {
  return {
    type: ERROR_CREATE_ATTRIBUTEVALUE,
    error: error
  };
}

export function resetCreateAttributeValue() {
  return {
    type: RESET_CREATE_ATTRIBUTEVALUE
  };
}

export function createAttributeValue(attributeValue) {
  return {
    type: CREATE_ATTRIBUTEVALUE,
    attributeValue
  };
}

export function deleteCreateAttributeValue(attributeValue) {
  return {
    type: DELETE_CREATE_ATTRIBUTEVALUE,
    attributeValue
  };
}

export function saveCreateAttributeValue() {
  return (dispatch, getState) => {
    dispatch(requestCreateAttributeValue());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let attributeValue = denormalizeDato(
      getState().attributesValues.create.nuevo,
      store
    );

    return attributesValuesApi
      .saveCreate(attributeValue)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateAttributeValue(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorCreateAttributeValue(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateAttributesValues(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorCreateAttributesValues(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorCreateAttributeValue(JSON.parse(error.message))
                  );
                  if (error.data)
                    dispatch(receiveCreateAttributeValue(error.data));
                })
                .catch(() => {
                  dispatch(
                    errorCreateAttributeValue(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//CREATE ATTRIBUTESVALUES
export const REQUEST_CREATE_ATTRIBUTESVALUES =
  'REQUEST_CREATE_ATTRIBUTESVALUES';
export const SUCCESS_CREATE_ATTRIBUTESVALUES =
  'SUCCESS_CREATE_ATTRIBUTESVALUES';
export const ERROR_CREATE_ATTRIBUTESVALUES = 'ERROR_CREATE_ATTRIBUTESVALUES';
export const RESET_CREATE_ATTRIBUTESVALUES = 'RESET_CREATE_ATTRIBUTESVALUES';

function requestCreateAttributesValues() {
  return {
    type: REQUEST_CREATE_ATTRIBUTESVALUES
  };
}

function receiveCreateAttributesValues(attributesValues) {
  return {
    type: SUCCESS_CREATE_ATTRIBUTESVALUES,
    receivedAt: Date.now(),
    attributesValues: normalizeDatos(attributesValues)
  };
}

function errorCreateAttributesValues(error) {
  return {
    type: ERROR_CREATE_ATTRIBUTESVALUES,
    error: error
  };
}

export function resetCreateAttributesValues() {
  return {
    type: RESET_CREATE_ATTRIBUTESVALUES
  };
}

export function saveCreateAttributesValues() {
  return (dispatch, getState) => {
    dispatch(requestCreateAttributesValues());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let attributesValues = getState().attributesValues.create.nuevos.map(
      idAttributeValue => {
        return denormalizeDato(
          getState().attributesValues.create.nuevo[idAttributeValue],
          store
        );
      }
    );

    return attributesValuesApi
      .saveCreateAttributesValues(attributesValues)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateAttributesValues(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorCreateAttributesValues(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateAttributesValues(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorCreateAttributesValues(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorCreateAttributesValues(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorCreateAttributesValues(errorMessages.GENERAL_ERROR)
                  );
                });

            return;
        }
      });
  };
}

//DELETE ATTRIBUTEVALUE
export const DELETE_ATTRIBUTEVALUE = 'DELETE_ATTRIBUTEVALUE';
export const REQUEST_DELETE_ATTRIBUTEVALUE = 'REQUEST_DELETE_ATTRIBUTEVALUE';
export const SUCCESS_DELETE_ATTRIBUTEVALUE = 'SUCCESS_DELETE_ATTRIBUTEVALUE';
export const ERROR_DELETE_ATTRIBUTEVALUE = 'ERROR_DELETE_ATTRIBUTEVALUE';
export const RESET_DELETE_ATTRIBUTEVALUE = 'RESET_DELETE_ATTRIBUTEVALUE';

function requestDeleteAttributeValue() {
  return {
    type: REQUEST_DELETE_ATTRIBUTEVALUE
  };
}

function receiveDeleteAttributeValue(attributeValue) {
  return {
    type: SUCCESS_DELETE_ATTRIBUTEVALUE,
    receivedAt: Date.now(),
    attributeValue: normalizeDato(attributeValue)
  };
}

function errorDeleteAttributeValue(error) {
  return {
    type: ERROR_DELETE_ATTRIBUTEVALUE,
    error: error
  };
}

export function resetDeleteAttributeValue(error) {
  return {
    type: RESET_DELETE_ATTRIBUTEVALUE,
    error: error
  };
}

export function deleteAttributeValue(attributeValue) {
  return {
    type: DELETE_ATTRIBUTEVALUE,
    attributeValue
  };
}

export function saveDeleteAttributeValue(attributeValue) {
  return dispatch => {
    dispatch(requestDeleteAttributeValue());
    return attributesValuesApi
      .saveDelete(attributeValue)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeleteAttributeValue());
        dispatch(receiveDeleteAttributeValue(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorDeleteAttributeValue(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeleteAttributeValue(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorDeleteAttributeValue(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorDeleteAttributeValue(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorDeleteAttributeValue(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//PRINT ATTRIBUTEVALUE
export const PRINT_ATTRIBUTEVALUE = 'PRINT_ATTRIBUTEVALUE';
export const REQUEST_PRINT_ATTRIBUTEVALUE = 'REQUEST_PRINT_ATTRIBUTEVALUE';
export const SUCCESS_PRINT_ATTRIBUTEVALUE = 'SUCCESS_PRINT_ATTRIBUTEVALUE';
export const ERROR_PRINT_ATTRIBUTEVALUE = 'ERROR_PRINT_ATTRIBUTEVALUE';
export const RESET_PRINT_ATTRIBUTEVALUE = 'RESET_PRINT_ATTRIBUTEVALUE';
export const DELETE_PRINT_ATTRIBUTEVALUE = 'DELETE_PRINT_ATTRIBUTEVALUE';

function requestPrintAttributeValue() {
  return {
    type: REQUEST_PRINT_ATTRIBUTEVALUE
  };
}

function receivePrintAttributeValue(turnos) {
  return {
    type: SUCCESS_PRINT_ATTRIBUTEVALUE,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintAttributeValue(error) {
  return {
    type: ERROR_PRINT_ATTRIBUTEVALUE,
    error: error
  };
}

export function resetPrintAttributeValue() {
  return {
    type: RESET_PRINT_ATTRIBUTEVALUE
  };
}

export function printAttributeValue(attributeValue) {
  return {
    type: PRINT_ATTRIBUTEVALUE,
    attributeValue
  };
}

export function deletePrintAttributeValue(attributeValue) {
  return {
    type: DELETE_PRINT_ATTRIBUTEVALUE,
    attributeValue
  };
}

export function savePrintAttributeValue(idAttributeValue) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintAttributeValue());
    return attributesValuesApi
      .printAttributeValue(idAttributeValue)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintAttributeValue(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorPrintAttributeValue(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintAttributeValue(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintAttributeValue(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(
                    errorPrintAttributeValue(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//PRINT ATTRIBUTESVALUES
export const REQUEST_PRINT_ATTRIBUTESVALUES = 'REQUEST_PRINT_ATTRIBUTESVALUES';
export const SUCCESS_PRINT_ATTRIBUTESVALUES = 'SUCCESS_PRINT_ATTRIBUTESVALUES';
export const ERROR_PRINT_ATTRIBUTESVALUES = 'ERROR_PRINT_ATTRIBUTESVALUES';
export const RESET_PRINT_ATTRIBUTESVALUES = 'RESET_PRINT_ATTRIBUTESVALUES';

function requestPrintAttributesValues() {
  return {
    type: REQUEST_PRINT_ATTRIBUTESVALUES
  };
}

function receivePrintAttributesValues(attributesValues) {
  return {
    type: SUCCESS_PRINT_ATTRIBUTESVALUES,
    receivedAt: Date.now(),
    attributesValues: normalizeDatos(attributesValues)
  };
}

function errorPrintAttributesValues(error) {
  return {
    type: ERROR_PRINT_ATTRIBUTESVALUES,
    error: error
  };
}

export function resetPrintAttributesValues() {
  return {
    type: RESET_PRINT_ATTRIBUTESVALUES
  };
}

export function savePrintAttributesValues() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintAttributeValue());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let attributesValues = getState().attributesValues.print.printers.map(
      idAttributeValue => {
        return denormalizeDato(
          getState().attributesValues.print.print[idAttributeValue],
          store
        );
      }
    );
    return attributesValuesApi
      .printAttributesValues(attributesValues)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintAttributesValues(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorPrintAttributesValues(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintAttributesValues(
                  JSON.parse(error.responseJSON.message)
                )
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorPrintAttributesValues(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorPrintAttributesValues(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}
