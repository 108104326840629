import { normalize, schema, denormalize } from 'normalizr';
import user from './normalizeUsers';
import dependency from './normalizeDependencies';
import tenantUserAccess from './normalizeTenantUserAccesses';
import domain from './normalizeDomains';

const tenant = new schema.Entity(
  'tenants',
  {
    user: [user],
    dependency: [dependency],
    tenant_user_access: [tenantUserAccess],
    domains: [domain],
    main_domain: [domain]
  },
  {
    idAttribute: 'id'
  }
);
export function normalizeDatos(myData) {
  const mySchema = { tenants: [tenant] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = tenant;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = tenant;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default tenant;
