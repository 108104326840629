import {
  INVALIDATE_PERIODS,
  ERROR_PERIODS,
  RECEIVE_PERIODS,
  REQUEST_PERIODS,
  RESET_PERIODS,
  ERROR_PERIOD,
  RECEIVE_PERIOD,
  REQUEST_PERIOD,
  UPDATE_PERIOD,
  REQUEST_UPDATE_PERIOD,
  SUCCESS_UPDATE_PERIOD,
  ERROR_UPDATE_PERIOD,
  RESET_UPDATE_PERIOD,
  REQUEST_UPDATE_PERIODS,
  SUCCESS_UPDATE_PERIODS,
  ERROR_UPDATE_PERIODS,
  RESET_UPDATE_PERIODS,
  CREATE_PERIOD,
  ERROR_CREATE_PERIOD,
  REQUEST_CREATE_PERIOD,
  RESET_CREATE_PERIOD,
  SUCCESS_CREATE_PERIOD,
  REQUEST_CREATE_PERIODS,
  SUCCESS_CREATE_PERIODS,
  ERROR_CREATE_PERIODS,
  RESET_CREATE_PERIODS,
  DELETE_PERIOD,
  DELETE_CREATE_PERIOD,
  DELETE_UPDATE_PERIOD,
  REQUEST_DELETE_PERIOD,
  SUCCESS_DELETE_PERIOD,
  ERROR_DELETE_PERIOD,
  RESET_DELETE_PERIOD,
  REQUEST_PRINT_PERIOD,
  SUCCESS_PRINT_PERIOD,
  ERROR_PRINT_PERIOD,
  RESET_PRINT_PERIOD,
  RECEIVE_FILE_PERIOD,
  REQUEST_PRINT_PERIODS,
  SUCCESS_PRINT_PERIODS,
  ERROR_PRINT_PERIODS,
  RESET_PRINT_PERIODS,
  RECEIVE_FILE_PERIODS,
  PRINT_PERIOD,
  DELETE_PRINT_PERIOD
} from '../actions/PeriodActions';

import {
  RECEIVE_PROPERTY,
  RECEIVE_PROPERTIES,
  SUCCESS_DELETE_PROPERTY,
  SUCCESS_CREATE_PROPERTY,
  SUCCESS_UPDATE_PROPERTY,
  SUCCESS_UPDATE_PROPERTIES
} from '../actions/PropertyActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    periods: {},
    files: {}
  };
}

function periodsById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_PERIODS:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_PERIODS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_PERIODS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_PERIODS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        periods: {}
      });
    case RECEIVE_PERIODS:
      let dato = action.periods.entities.periods;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        periods: merge({}, state.periods, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_PERIOD:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_PERIOD:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_PERIOD:
      let datoPeriod = action.period.entities.periods;
      return Object.assign({}, state, {
        periods: merge({}, state.periods, datoPeriod),
        isFetching: false
      });
    case RECEIVE_FILE_PERIOD:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_PERIOD:
      let datoPeriodEliminado = action.period.entities.periods;
      return Object.assign({}, state, {
        periods: mergeWith(
          clone(datoPeriodEliminado),
          state.periods,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_PERIOD:
      let datoPeriodCreado = action.period.entities.periods;
      return Object.assign({}, state, {
        periods: mergeWith(
          clone(datoPeriodCreado),
          state.periods,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_PERIODS:
      let datosPeriodCreado = action.periods.entities.periods;
      return Object.assign({}, state, {
        periods: mergeWith(
          clone(datosPeriodCreado),
          state.periods,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PERIOD:
      let datoPeriodActualizado = action.period.entities.periods;
      return Object.assign({}, state, {
        periods: mergeWith(
          clone(datoPeriodActualizado),
          state.periods,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PERIODS:
      let datosPeriodActualizado = action.periods.entities.periods;
      return Object.assign({}, state, {
        periods: mergeWith(
          clone(datosPeriodActualizado),
          state.periods,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    //PROPERTY
    case RECEIVE_PROPERTY:
      let property =
        action.property.entities && action.property.entities.periods
          ? action.property.entities.periods
          : {};
      return Object.assign({}, state, {
        periods: merge({}, state.periods, property)
      });
    case RECEIVE_PROPERTIES:
      let properties =
        action.properties.entities && action.properties.entities.periods
          ? action.properties.entities.periods
          : {};
      return Object.assign({}, state, {
        periods: merge({}, state.periods, properties)
      });
    case SUCCESS_DELETE_PROPERTY:
      let datopropertyEliminado =
        action.property.entities && action.property.entities.periods
          ? action.property.entities.periods
          : {};
      return Object.assign({}, state, {
        periods: mergeWith(
          clone(datopropertyEliminado),
          pickBy(state.periods, function (period) {
            return period.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_PROPERTY:
      let datopropertyCreado =
        action.property.entities && action.property.entities.periods
          ? action.property.entities.periods
          : {};
      return Object.assign({}, state, {
        periods: mergeWith(
          clone(datopropertyCreado),
          pickBy(state.periods, function (period) {
            return period.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTY:
      let datopropertyActualizado =
        action.property.entities && action.property.entities.periods
          ? action.property.entities.periods
          : {};
      return Object.assign({}, state, {
        periods: mergeWith(
          clone(datopropertyActualizado),
          pickBy(state.periods, function (period) {
            return period.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTIES:
      let datospropertyActualizado =
        action.properties.entities && action.properties.entities.periods
          ? action.properties.entities.periods
          : {};
      return Object.assign({}, state, {
        periods: mergeWith(
          clone(datospropertyActualizado),
          state.periods,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        periods: {}
      });
    default:
      return state;
  }
}

function allPeriods(state = [], action) {
  switch (action.type) {
    case RECEIVE_PERIODS:
      return action.periods.result && action.periods.result.periods
        ? union(action.periods.result.periods, state)
        : action.periods.result
        ? action.periods.result
        : state;
    case RECEIVE_PERIOD:
      return action.period.result
        ? union([action.period.result], state)
        : state;

    case SUCCESS_CREATE_PERIOD:
      let datoPeriodSCreate = action.period.entities.periods;
      let idNuevoSCreate = null;
      if (Object.values(datoPeriodSCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoPeriodSCreate)[0] &&
          Object.values(datoPeriodSCreate)[0].id
            ? Object.values(datoPeriodSCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_PERIODS:
      let periodsCreate =
        action.periods.entities && action.periods.entities.periods
          ? action.periods.entities.periods
          : null;
      return periodsCreate
        ? union(
            state,
            Object.values(periodsCreate).map(periods => {
              return periods.id;
            })
          )
        : state;
    case RESET_PERIODS:
      return [];

    case RECEIVE_PROPERTY:
      let property =
        action.property.entities && action.property.entities.periods
          ? action.property.entities.periods
          : null;
      return property
        ? union(
            state,
            Object.values(property).map(property => {
              return property.id;
            })
          )
        : state;
    case RECEIVE_PROPERTIES:
      let properties =
        action.properties.entities && action.properties.entities.periods
          ? action.properties.entities.periods
          : null;
      return properties
        ? union(
            state,
            Object.values(properties).map(properties => {
              return properties.id;
            })
          )
        : state;

    case SUCCESS_DELETE_PROPERTY:
      let propertyDelete =
        action.property.entities && action.property.entities.periods
          ? action.property.entities.periods
          : null;
      return propertyDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyDelete).map(property => {
              return property.id;
            })
          )
        : state;
    case SUCCESS_CREATE_PROPERTY:
      let propertyCreate =
        action.property.entities && action.property.entities.periods
          ? action.property.entities.periods
          : null;
      return propertyCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyCreate).map(property => {
              return property.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_PROPERTY:
      let propertyUpdate =
        action.property.entities && action.property.entities.periods
          ? action.property.entities.periods
          : null;
      return propertyUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyUpdate).map(property => {
              return property.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_PROPERTIES:
      let propertiesUpdate =
        action.properties.entities && action.properties.entities.periods
          ? action.properties.entities.periods
          : null;
      return propertiesUpdate
        ? union(
            state,
            Object.values(propertiesUpdate).map(properties => {
              return properties.id;
            })
          )
        : state;

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalPeriods(state = null, action) {
  switch (action.type) {
    case RECEIVE_PERIODS:
      return action.periods && action.periods.result.total
        ? action.periods.result.total
        : 0;
    case RESET_PERIODS:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_PERIOD:
      let dato = action.period.entities.periods;
      let period =
        dato && Object.keys(dato).length > 0 ? dato[action.period.result] : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: period ? period : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_PERIOD:
      let idsUpdate = [];
      Object.values(action.period).map(periodUpdate => {
        if (periodUpdate && periodUpdate.id) idsUpdate.push(periodUpdate.id);
      });
      return merge({}, state, {
        activo: action.period,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_PERIOD:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_PERIOD:
      let datoPeriodActualizado = action.period.entities.periods;
      let periodNuevo =
        datoPeriodActualizado && Object.keys(datoPeriodActualizado).length > 0
          ? datoPeriodActualizado[action.period.result]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: periodNuevo
      });
    case ERROR_UPDATE_PERIOD:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_PERIODS:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_PERIODS:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_PERIODS:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_PERIOD:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    //PROPERTY
    //TODO ver si esta bien
    case SUCCESS_CREATE_PROPERTY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_PROPERTY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });

    case DELETE_PERIOD:
      let datoPeriodDelete = action.period;
      let idsDelete = [];
      Object.values(action.period).map(periodDelete => {
        if (periodDelete && periodDelete.id) idsDelete.push(periodDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(clone(state.activo), Object.keys(datoPeriodDelete)),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_PERIOD:
      let datoPeriodDeleteUpdate = action.period;
      let idsDeleteUpdate = [];
      Object.values(action.period).map(periodDelete => {
        if (periodDelete && periodDelete.id)
          idsDeleteUpdate.push(periodDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoPeriodDeleteUpdate)
          ),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_PERIOD:
      let datoPeriodDeleted = {};
      if (Object.values(action.period.entities.periods).length > 0)
        datoPeriodDeleted = Object.values(action.period.entities.periods)[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoPeriodDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_PERIOD:
      let idsCreate = [];
      Object.values(action.period).map(periodCreate => {
        if (periodCreate && periodCreate.id) idsCreate.push(periodCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.period,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_PERIOD:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_PERIOD:
      let datoPeriodNuevo = action.period.entities.periods;
      let periodNuevo =
        datoPeriodNuevo && Object.keys(datoPeriodNuevo).length > 0
          ? datoPeriodNuevo[action.period.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: periodNuevo,
        nuevos: []
      });
    case ERROR_CREATE_PERIOD:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_PERIODS:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_PERIODS:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_PERIODS:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_PERIOD:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    //PROPERTY
    case SUCCESS_CREATE_PROPERTY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_PROPERTY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });

    case DELETE_PERIOD:
      let datoPeriodDelete = action.period;
      let idsDelete = [];
      Object.values(action.period).map(periodDelete => {
        if (periodDelete && periodDelete.id) idsDelete.push(periodDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoPeriodDelete)),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_PERIOD:
      let datoPeriodDeleteCreate = action.period;
      let idsDeleteCreate = [];
      Object.values(action.period).map(periodDelete => {
        if (periodDelete && periodDelete.id)
          idsDeleteCreate.push(periodDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoPeriodDeleteCreate)),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_PERIOD:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.period,
        error: null
      });
    case REQUEST_DELETE_PERIOD:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_PERIOD:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_PERIOD:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_PERIOD:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    //PROPERTY
    case SUCCESS_CREATE_PROPERTY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_PROPERTY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_PERIOD:
      let idsCreate = [];
      Object.values(action.period).map(periodCreate => {
        if (periodCreate && periodCreate.id) idsCreate.push(periodCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.period,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_PERIOD:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_PERIOD:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_PERIOD:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_PERIODS:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_PERIODS:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_PERIODS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_PERIOD:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_PERIOD:
      let datoPeriodDeleteCreate = action.period;
      let idsDeleteCreate = [];
      Object.values(action.period).map(periodDelete => {
        if (periodDelete && periodDelete.id)
          idsDeleteCreate.push(periodDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(clone(state.print), Object.keys(datoPeriodDeleteCreate)),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const periods = combineReducers({
  byId: periodsById,
  allIds: allPeriods,
  update: update,
  create: create,
  totalPeriods: totalPeriods,
  delete: deleter,
  print: print
});

export default periods;
