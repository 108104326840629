import {
  INVALIDATE_LOGS,
  ERROR_LOGS,
  RECEIVE_LOGS,
  REQUEST_LOGS,
  RESET_LOGS,
  ERROR_LOG,
  RECEIVE_LOG,
  REQUEST_LOG,
  UPDATE_LOG,
  REQUEST_UPDATE_LOG,
  SUCCESS_UPDATE_LOG,
  ERROR_UPDATE_LOG,
  RESET_UPDATE_LOG,
  REQUEST_UPDATE_LOGS,
  SUCCESS_UPDATE_LOGS,
  ERROR_UPDATE_LOGS,
  RESET_UPDATE_LOGS,
  CREATE_LOG,
  ERROR_CREATE_LOG,
  REQUEST_CREATE_LOG,
  RESET_CREATE_LOG,
  SUCCESS_CREATE_LOG,
  REQUEST_CREATE_LOGS,
  SUCCESS_CREATE_LOGS,
  ERROR_CREATE_LOGS,
  RESET_CREATE_LOGS,
  DELETE_LOG,
  DELETE_CREATE_LOG,
  DELETE_UPDATE_LOG,
  REQUEST_DELETE_LOG,
  SUCCESS_DELETE_LOG,
  ERROR_DELETE_LOG,
  RESET_DELETE_LOG,
  REQUEST_PRINT_LOG,
  SUCCESS_PRINT_LOG,
  ERROR_PRINT_LOG,
  RESET_PRINT_LOG,
  RECEIVE_FILE_LOG,
  REQUEST_PRINT_LOGS,
  SUCCESS_PRINT_LOGS,
  ERROR_PRINT_LOGS,
  RESET_PRINT_LOGS,
  RECEIVE_FILE_LOGS,
  PRINT_LOG,
  DELETE_PRINT_LOG
} from '../actions/LogActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    logs: {},
    files: {}
  };
}

function logsById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_LOGS:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_LOGS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_LOGS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_LOGS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        logs: {}
      });
    case RECEIVE_LOGS:
      let dato = action.logs.entities.logs;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        logs: merge({}, state.logs, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_LOG:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_LOG:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_LOG:
      let datoLog = action.log.entities.logs;
      return Object.assign({}, state, {
        logs: merge({}, state.logs, datoLog),
        isFetching: false
      });
    case RECEIVE_FILE_LOG:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_LOG:
      let datoLogEliminado = action.log.entities.logs;
      return Object.assign({}, state, {
        logs: mergeWith(
          clone(datoLogEliminado),
          state.logs,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_LOG:
      let datoLogCreado = action.log.entities.logs;
      return Object.assign({}, state, {
        logs: mergeWith(
          clone(datoLogCreado),
          state.logs,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_LOGS:
      let datosLogCreado = action.logs.entities.logs;
      return Object.assign({}, state, {
        logs: mergeWith(
          clone(datosLogCreado),
          state.logs,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_LOG:
      let datoLogActualizado = action.log.entities.logs;
      return Object.assign({}, state, {
        logs: mergeWith(
          clone(datoLogActualizado),
          state.logs,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_LOGS:
      let datosLogActualizado = action.logs.entities.logs;
      return Object.assign({}, state, {
        logs: mergeWith(
          clone(datosLogActualizado),
          state.logs,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        logs: {}
      });
    default:
      return state;
  }
}

function allLogs(state = [], action) {
  switch (action.type) {
    case RECEIVE_LOGS:
      return action.logs.result && action.logs.result.logs
        ? union(action.logs.result.logs, state)
        : action.logs.result
        ? action.logs.result
        : state;
    case RECEIVE_LOG:
      return action.log.result ? union([action.log.result], state) : state;

    case SUCCESS_CREATE_LOG:
      let datoLogSCreate = action.log.entities.logs;
      let idNuevoSCreate = null;
      if (Object.values(datoLogSCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoLogSCreate)[0] &&
          Object.values(datoLogSCreate)[0].id
            ? Object.values(datoLogSCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_LOGS:
      let logsCreate =
        action.logs.entities && action.logs.entities.logs
          ? action.logs.entities.logs
          : null;
      return logsCreate
        ? union(
            state,
            Object.values(logsCreate).map(logs => {
              return logs.id;
            })
          )
        : state;
    case RESET_LOGS:
      return [];

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalLogs(state = null, action) {
  switch (action.type) {
    case RECEIVE_LOGS:
      return action.logs && action.logs.result.total
        ? action.logs.result.total
        : 0;
    case RESET_LOGS:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_LOG:
      let dato = action.log.entities.logs;
      let log =
        dato && Object.keys(dato).length > 0 ? dato[action.log.result] : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: log ? log : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_LOG:
      let idsUpdate = [];
      Object.values(action.log).map(logUpdate => {
        if (logUpdate && logUpdate.id) idsUpdate.push(logUpdate.id);
      });
      return merge({}, state, {
        activo: action.log,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_LOG:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_LOG:
      let datoLogActualizado = action.log.entities.logs;
      let logNuevo =
        datoLogActualizado && Object.keys(datoLogActualizado).length > 0
          ? datoLogActualizado[action.log.result]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: logNuevo
      });
    case ERROR_UPDATE_LOG:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_LOGS:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_LOGS:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_LOGS:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_LOG:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    case DELETE_LOG:
      let datoLogDelete = action.log;
      let idsDelete = [];
      Object.values(action.log).map(logDelete => {
        if (logDelete && logDelete.id) idsDelete.push(logDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(clone(state.activo), Object.keys(datoLogDelete)),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_LOG:
      let datoLogDeleteUpdate = action.log;
      let idsDeleteUpdate = [];
      Object.values(action.log).map(logDelete => {
        if (logDelete && logDelete.id) idsDeleteUpdate.push(logDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(clone(state.activo), Object.keys(datoLogDeleteUpdate)),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_LOG:
      let datoLogDeleted = {};
      if (Object.values(action.log.entities.logs).length > 0)
        datoLogDeleted = Object.values(action.log.entities.logs)[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoLogDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_LOG:
      let idsCreate = [];
      Object.values(action.log).map(logCreate => {
        if (logCreate && logCreate.id) idsCreate.push(logCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.log,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_LOG:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_LOG:
      let datoLogNuevo = action.log.entities.logs;
      let logNuevo =
        datoLogNuevo && Object.keys(datoLogNuevo).length > 0
          ? datoLogNuevo[action.log.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: logNuevo,
        nuevos: []
      });
    case ERROR_CREATE_LOG:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_LOGS:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_LOGS:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_LOGS:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_LOG:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    case DELETE_LOG:
      let datoLogDelete = action.log;
      let idsDelete = [];
      Object.values(action.log).map(logDelete => {
        if (logDelete && logDelete.id) idsDelete.push(logDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoLogDelete)),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_LOG:
      let datoLogDeleteCreate = action.log;
      let idsDeleteCreate = [];
      Object.values(action.log).map(logDelete => {
        if (logDelete && logDelete.id) idsDeleteCreate.push(logDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoLogDeleteCreate)),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_LOG:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.log,
        error: null
      });
    case REQUEST_DELETE_LOG:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_LOG:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_LOG:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_LOG:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_LOG:
      let idsCreate = [];
      Object.values(action.log).map(logCreate => {
        if (logCreate && logCreate.id) idsCreate.push(logCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.log,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_LOG:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_LOG:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_LOG:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_LOGS:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_LOGS:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_LOGS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_LOG:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_LOG:
      let datoLogDeleteCreate = action.log;
      let idsDeleteCreate = [];
      Object.values(action.log).map(logDelete => {
        if (logDelete && logDelete.id) idsDeleteCreate.push(logDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(clone(state.print), Object.keys(datoLogDeleteCreate)),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const logs = combineReducers({
  byId: logsById,
  allIds: allLogs,
  update: update,
  create: create,
  totalLogs: totalLogs,
  delete: deleter,
  print: print
});

export default logs;
