import {
  INVALIDATE_USERS,
  ERROR_USERS,
  RECEIVE_USERS,
  REQUEST_USERS,
  RESET_USERS,
  ERROR_USER,
  RECEIVE_USER,
  REQUEST_USER,
  UPDATE_USER,
  REQUEST_UPDATE_USER,
  SUCCESS_UPDATE_USER,
  ERROR_UPDATE_USER,
  RESET_UPDATE_USER,
  REQUEST_UPDATE_USERS,
  SUCCESS_UPDATE_USERS,
  ERROR_UPDATE_USERS,
  RESET_UPDATE_USERS,
  CREATE_USER,
  ERROR_CREATE_USER,
  REQUEST_CREATE_USER,
  RESET_CREATE_USER,
  SUCCESS_CREATE_USER,
  REQUEST_CREATE_USERS,
  SUCCESS_CREATE_USERS,
  ERROR_CREATE_USERS,
  RESET_CREATE_USERS,
  DELETE_USER,
  DELETE_CREATE_USER,
  DELETE_UPDATE_USER,
  REQUEST_DELETE_USER,
  SUCCESS_DELETE_USER,
  ERROR_DELETE_USER,
  RESET_DELETE_USER,
  REQUEST_PRINT_USER,
  SUCCESS_PRINT_USER,
  ERROR_PRINT_USER,
  RESET_PRINT_USER,
  RECEIVE_FILE_USER,
  REQUEST_PRINT_USERS,
  SUCCESS_PRINT_USERS,
  ERROR_PRINT_USERS,
  RESET_PRINT_USERS,
  RECEIVE_FILE_USERS,
  PRINT_USER,
  DELETE_PRINT_USER
} from '../actions/UserActions';
import {
  CREATE_TENANTUSERACCESS,
  UPDATE_TENANTUSERACCESS,
  DELETE_TENANTUSERACCESS
} from '../actions/TenantUserAccessActions';

import {
  RECEIVE_TICKET,
  RECEIVE_TICKETS,
  SUCCESS_DELETE_TICKET,
  SUCCESS_CREATE_TICKET,
  SUCCESS_UPDATE_TICKET,
  SUCCESS_UPDATE_TICKETS
} from '../actions/TicketActions';
import {
  RECEIVE_PROPERTY,
  RECEIVE_PROPERTIES,
  SUCCESS_DELETE_PROPERTY,
  SUCCESS_CREATE_PROPERTY,
  SUCCESS_UPDATE_PROPERTY,
  SUCCESS_UPDATE_PROPERTIES
} from '../actions/PropertyActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    users: {},
    files: {}
  };
}

function usersById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_USERS:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_USERS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_USERS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_USERS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        users: {}
      });
    case RECEIVE_USERS:
      let dato = action.users.entities.users;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        users: merge({}, state.users, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_USER:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_USER:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_USER:
      let datoUser = action.user.entities.users;
      return Object.assign({}, state, {
        users: merge({}, state.users, datoUser),
        isFetching: false
      });
    case RECEIVE_FILE_USER:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_USER:
      let datoUserEliminado = action.user.entities.users;
      return Object.assign({}, state, {
        users: mergeWith(
          clone(datoUserEliminado),
          state.users,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_USER:
      let datoUserCreado = action.user.entities.users;
      return Object.assign({}, state, {
        users: mergeWith(
          clone(datoUserCreado),
          state.users,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_USERS:
      let datosUserCreado = action.users.entities.users;
      return Object.assign({}, state, {
        users: mergeWith(
          clone(datosUserCreado),
          state.users,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_USER:
      let datoUserActualizado = action.user.entities.users;
      return Object.assign({}, state, {
        users: mergeWith(
          clone(datoUserActualizado),
          state.users,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_USERS:
      let datosUserActualizado = action.users.entities.users;
      return Object.assign({}, state, {
        users: mergeWith(
          clone(datosUserActualizado),
          state.users,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    //TICKET
    case RECEIVE_TICKET:
      let ticket =
        action.ticket.entities && action.ticket.entities.users
          ? action.ticket.entities.users
          : {};
      return Object.assign({}, state, {
        users: merge({}, state.users, ticket)
      });
    case RECEIVE_TICKETS:
      let tickets =
        action.tickets.entities && action.tickets.entities.users
          ? action.tickets.entities.users
          : {};
      return Object.assign({}, state, {
        users: merge({}, state.users, tickets)
      });
    case SUCCESS_DELETE_TICKET:
      let datoticketEliminado =
        action.ticket.entities && action.ticket.entities.users
          ? action.ticket.entities.users
          : {};
      return Object.assign({}, state, {
        users: mergeWith(
          clone(datoticketEliminado),
          pickBy(state.users, function (user) {
            return user.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_TICKET:
      let datoticketCreado =
        action.ticket.entities && action.ticket.entities.users
          ? action.ticket.entities.users
          : {};
      return Object.assign({}, state, {
        users: mergeWith(
          clone(datoticketCreado),
          pickBy(state.users, function (user) {
            return user.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TICKET:
      let datoticketActualizado =
        action.ticket.entities && action.ticket.entities.users
          ? action.ticket.entities.users
          : {};
      return Object.assign({}, state, {
        users: mergeWith(
          clone(datoticketActualizado),
          pickBy(state.users, function (user) {
            return user.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TICKETS:
      let datosticketActualizado =
        action.tickets.entities && action.tickets.entities.users
          ? action.tickets.entities.users
          : {};
      return Object.assign({}, state, {
        users: mergeWith(
          clone(datosticketActualizado),
          state.users,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    //PROPERTY
    case RECEIVE_PROPERTY:
      let property =
        action.property.entities && action.property.entities.users
          ? action.property.entities.users
          : {};
      return Object.assign({}, state, {
        users: merge({}, state.users, property)
      });
    case RECEIVE_PROPERTIES:
      let properties =
        action.properties.entities && action.properties.entities.users
          ? action.properties.entities.users
          : {};
      return Object.assign({}, state, {
        users: merge({}, state.users, properties)
      });
    case SUCCESS_DELETE_PROPERTY:
      let datopropertyEliminado =
        action.property.entities && action.property.entities.users
          ? action.property.entities.users
          : {};
      return Object.assign({}, state, {
        users: mergeWith(
          clone(datopropertyEliminado),
          pickBy(state.users, function (user) {
            return user.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_PROPERTY:
      let datopropertyCreado =
        action.property.entities && action.property.entities.users
          ? action.property.entities.users
          : {};
      return Object.assign({}, state, {
        users: mergeWith(
          clone(datopropertyCreado),
          pickBy(state.users, function (user) {
            return user.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTY:
      let datopropertyActualizado =
        action.property.entities && action.property.entities.users
          ? action.property.entities.users
          : {};
      return Object.assign({}, state, {
        users: mergeWith(
          clone(datopropertyActualizado),
          pickBy(state.users, function (user) {
            return user.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTIES:
      let datospropertyActualizado =
        action.properties.entities && action.properties.entities.users
          ? action.properties.entities.users
          : {};
      return Object.assign({}, state, {
        users: mergeWith(
          clone(datospropertyActualizado),
          state.users,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        users: {}
      });
    default:
      return state;
  }
}

function allUsers(state = [], action) {
  switch (action.type) {
    case RECEIVE_USERS:
      return action.users.result && action.users.result.users
        ? union(action.users.result.users, state)
        : action.users.result
        ? action.users.result
        : state;
    case RECEIVE_USER:
      return action.user.result ? union([action.user.result], state) : state;

    case SUCCESS_CREATE_USER:
      let datoUserSCreate = action.user.entities.users;
      let idNuevoSCreate = null;
      if (Object.values(datoUserSCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoUserSCreate)[0] &&
          Object.values(datoUserSCreate)[0].id
            ? Object.values(datoUserSCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_USERS:
      let usersCreate =
        action.users.entities && action.users.entities.users
          ? action.users.entities.users
          : null;
      return usersCreate
        ? union(
            state,
            Object.values(usersCreate).map(users => {
              return users.id;
            })
          )
        : state;
    case RESET_USERS:
      return [];

    case RECEIVE_TICKET:
      let ticket =
        action.ticket.entities && action.ticket.entities.users
          ? action.ticket.entities.users
          : null;
      return ticket
        ? union(
            state,
            Object.values(ticket).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case RECEIVE_TICKETS:
      let tickets =
        action.tickets.entities && action.tickets.entities.users
          ? action.tickets.entities.users
          : null;
      return tickets
        ? union(
            state,
            Object.values(tickets).map(tickets => {
              return tickets.id;
            })
          )
        : state;

    case SUCCESS_DELETE_TICKET:
      let ticketDelete =
        action.ticket.entities && action.ticket.entities.users
          ? action.ticket.entities.users
          : null;
      return ticketDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(ticketDelete).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case SUCCESS_CREATE_TICKET:
      let ticketCreate =
        action.ticket.entities && action.ticket.entities.users
          ? action.ticket.entities.users
          : null;
      return ticketCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(ticketCreate).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_TICKET:
      let ticketUpdate =
        action.ticket.entities && action.ticket.entities.users
          ? action.ticket.entities.users
          : null;
      return ticketUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(ticketUpdate).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_TICKETS:
      let ticketsUpdate =
        action.tickets.entities && action.tickets.entities.users
          ? action.tickets.entities.users
          : null;
      return ticketsUpdate
        ? union(
            state,
            Object.values(ticketsUpdate).map(tickets => {
              return tickets.id;
            })
          )
        : state;
    case RECEIVE_PROPERTY:
      let property =
        action.property.entities && action.property.entities.users
          ? action.property.entities.users
          : null;
      return property
        ? union(
            state,
            Object.values(property).map(property => {
              return property.id;
            })
          )
        : state;
    case RECEIVE_PROPERTIES:
      let properties =
        action.properties.entities && action.properties.entities.users
          ? action.properties.entities.users
          : null;
      return properties
        ? union(
            state,
            Object.values(properties).map(properties => {
              return properties.id;
            })
          )
        : state;

    case SUCCESS_DELETE_PROPERTY:
      let propertyDelete =
        action.property.entities && action.property.entities.users
          ? action.property.entities.users
          : null;
      return propertyDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyDelete).map(property => {
              return property.id;
            })
          )
        : state;
    case SUCCESS_CREATE_PROPERTY:
      let propertyCreate =
        action.property.entities && action.property.entities.users
          ? action.property.entities.users
          : null;
      return propertyCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyCreate).map(property => {
              return property.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_PROPERTY:
      let propertyUpdate =
        action.property.entities && action.property.entities.users
          ? action.property.entities.users
          : null;
      return propertyUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyUpdate).map(property => {
              return property.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_PROPERTIES:
      let propertiesUpdate =
        action.properties.entities && action.properties.entities.users
          ? action.properties.entities.users
          : null;
      return propertiesUpdate
        ? union(
            state,
            Object.values(propertiesUpdate).map(properties => {
              return properties.id;
            })
          )
        : state;

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalUsers(state = null, action) {
  switch (action.type) {
    case RECEIVE_USERS:
      return action.users && action.users.result.total
        ? action.users.result.total
        : 0;
    case RESET_USERS:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_USER:
      let dato = action.user.entities.users;
      let user =
        dato && Object.keys(dato).length > 0 ? dato[action.user.result] : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: user ? user : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_USER:
      let idsUpdate = [];
      Object.values(action.user).map(userUpdate => {
        if (userUpdate && userUpdate.id) idsUpdate.push(userUpdate.id);
      });
      return merge({}, state, {
        activo: action.user,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_USER:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_USER:
      let datoUserActualizado = action.user.entities.users;
      let userNuevo =
        datoUserActualizado && Object.keys(datoUserActualizado).length > 0
          ? datoUserActualizado[action.user.result]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: userNuevo
      });
    case ERROR_UPDATE_USER:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_USERS:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_USERS:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_USERS:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_USER:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    case CREATE_TENANTUSERACCESS:
      let usertenantUserAccessCreateActivo = clone(state.activo);
      let usertenantUserAccessCreateActivos = clone(state.activos);
      Object.values(action.tenantUserAccess).map(tenantUserAccessCreate => {
        if (
          tenantUserAccessCreate &&
          tenantUserAccessCreate.user_id &&
          usertenantUserAccessCreateActivo[tenantUserAccessCreate.user_id]
        ) {
          if (tenantUserAccessCreate.user_id.toString().indexOf('-') === -1)
            usertenantUserAccessCreateActivo[
              tenantUserAccessCreate.user_id
            ].tenant_user_access = union(
              usertenantUserAccessCreateActivo.tenant_user_access,
              [tenantUserAccessCreate.id]
            );
        } else if (tenantUserAccessCreate) {
          usertenantUserAccessCreateActivo.tenant_user_access = union(
            usertenantUserAccessCreateActivo.tenant_user_access
              ? usertenantUserAccessCreateActivo.tenant_user_access
              : [],
            [tenantUserAccessCreate.id]
          );
        }
        if (
          tenantUserAccessCreate &&
          tenantUserAccessCreate.user_id &&
          tenantUserAccessCreate.user_id.toString().indexOf('-') === -1
        )
          usertenantUserAccessCreateActivos = union(
            usertenantUserAccessCreateActivos,
            [tenantUserAccessCreate.user_id]
          );
      });
      return Object.assign({}, state, {
        activo: usertenantUserAccessCreateActivo,
        activos: usertenantUserAccessCreateActivos
      });
    case UPDATE_TENANTUSERACCESS:
      let usertenantUserAccessUpdateActivo = clone(state.activo);
      let usertenantUserAccessUpdateActivos = clone(state.activos);
      Object.values(action.tenantUserAccess).map(tenantUserAccessUpdate => {
        if (
          tenantUserAccessUpdate &&
          tenantUserAccessUpdate.user_id &&
          usertenantUserAccessUpdateActivo[tenantUserAccessUpdate.user_id]
        ) {
          if (tenantUserAccessUpdate.user_id.toString().indexOf('-') === -1)
            usertenantUserAccessUpdateActivo[
              tenantUserAccessUpdate.user_id
            ].tenant_user_access = union(
              usertenantUserAccessUpdateActivo.tenant_user_access,
              [tenantUserAccessUpdate.id]
            );
        } else if (tenantUserAccessUpdate) {
          usertenantUserAccessUpdateActivo.tenant_user_access = union(
            usertenantUserAccessUpdateActivo.tenant_user_access
              ? usertenantUserAccessUpdateActivo.tenant_user_access
              : [],
            [tenantUserAccessUpdate.id]
          );
        }
        if (
          tenantUserAccessUpdate &&
          tenantUserAccessUpdate.user_id &&
          tenantUserAccessUpdate.user_id.toString().indexOf('-') === -1
        )
          usertenantUserAccessUpdateActivos = union(
            usertenantUserAccessUpdateActivos,
            [tenantUserAccessUpdate.user_id]
          );
      });
      return Object.assign({}, state, {
        activo: usertenantUserAccessUpdateActivo,
        activos: usertenantUserAccessUpdateActivos
      });
    case DELETE_TENANTUSERACCESS:
      let usertenantUserAccessDeleteActivo = clone(state.activo);
      let usertenantUserAccessDeleteActivos = clone(state.activos);
      Object.values(action.tenantUserAccess).map(tenantUserAccessDelete => {
        if (
          tenantUserAccessDelete &&
          tenantUserAccessDelete.user_id &&
          usertenantUserAccessDeleteActivo[tenantUserAccessDelete.user_id]
        ) {
          if (tenantUserAccessDelete.user_id.toString().indexOf('-') === -1)
            usertenantUserAccessDeleteActivo[
              tenantUserAccessDelete.user_id
            ].tenant_user_access = difference(
              usertenantUserAccessDeleteActivo.tenant_user_access,
              [tenantUserAccessDelete.id]
            );
        } else if (tenantUserAccessDelete) {
          usertenantUserAccessDeleteActivo.tenant_user_access = difference(
            usertenantUserAccessDeleteActivo.tenant_user_access
              ? usertenantUserAccessDeleteActivo.tenant_user_access
              : [],
            [tenantUserAccessDelete.id]
          );
        }
        if (
          tenantUserAccessDelete &&
          tenantUserAccessDelete.user_id &&
          tenantUserAccessDelete.user_id.toString().indexOf('-') === -1
        )
          usertenantUserAccessDeleteActivos = union(
            usertenantUserAccessDeleteActivos,
            [tenantUserAccessDelete.user_id]
          );
      });
      return Object.assign({}, state, {
        activo: usertenantUserAccessDeleteActivo,
        activos: usertenantUserAccessDeleteActivos
      });

    //TICKET
    //TODO ver si esta bien
    case SUCCESS_CREATE_TICKET:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_TICKET:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_TICKET:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_TICKETS:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      }); //PROPERTY
    //TODO ver si esta bien
    case SUCCESS_CREATE_PROPERTY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_PROPERTY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });

    case DELETE_USER:
      let datoUserDelete = action.user;
      let idsDelete = [];
      Object.values(action.user).map(userDelete => {
        if (userDelete && userDelete.id) idsDelete.push(userDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(clone(state.activo), Object.keys(datoUserDelete)),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_USER:
      let datoUserDeleteUpdate = action.user;
      let idsDeleteUpdate = [];
      Object.values(action.user).map(userDelete => {
        if (userDelete && userDelete.id) idsDeleteUpdate.push(userDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(clone(state.activo), Object.keys(datoUserDeleteUpdate)),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_USER:
      let datoUserDeleted = {};
      if (Object.values(action.user.entities.users).length > 0)
        datoUserDeleted = Object.values(action.user.entities.users)[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoUserDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_USER:
      let idsCreate = [];
      Object.values(action.user).map(userCreate => {
        if (userCreate && userCreate.id) idsCreate.push(userCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.user,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_USER:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_USER:
      let datoUserNuevo = action.user.entities.users;
      let userNuevo =
        datoUserNuevo && Object.keys(datoUserNuevo).length > 0
          ? datoUserNuevo[action.user.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: userNuevo,
        nuevos: []
      });
    case ERROR_CREATE_USER:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_USERS:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_USERS:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_USERS:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_USER:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    //CREATE TENANTUSERACCESS
    case CREATE_TENANTUSERACCESS:
      let usertenantUserAccessCreateActivo = clone(state.nuevo);
      Object.values(action.tenantUserAccess).map(tenantUserAccessCreate => {
        if (
          tenantUserAccessCreate &&
          tenantUserAccessCreate.user_id &&
          usertenantUserAccessCreateActivo[tenantUserAccessCreate.user_id]
        ) {
          if (tenantUserAccessCreate.user_id.toString().indexOf('-') !== -1)
            usertenantUserAccessCreateActivo[
              tenantUserAccessCreate.user_id
            ].tenant_user_access = union(
              usertenantUserAccessCreateActivo.tenant_user_access,
              [tenantUserAccessCreate.id]
            );
        } else if (tenantUserAccessCreate) {
          usertenantUserAccessCreateActivo.tenant_user_access = union(
            usertenantUserAccessCreateActivo.tenant_user_access
              ? usertenantUserAccessCreateActivo.tenant_user_access
              : [],
            [tenantUserAccessCreate.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: usertenantUserAccessCreateActivo
        //nuevos: tenantUserAccessCreate && tenantUserAccessCreate.user_id ? union(state.nuevos, [tenantUserAccessCreate.user_id]) : state.nuevos,
      });
    case UPDATE_TENANTUSERACCESS:
      let usertenantUserAccessUpdateActivo = clone(state.nuevo);
      Object.values(action.tenantUserAccess).map(tenantUserAccessUpdate => {
        if (
          tenantUserAccessUpdate &&
          tenantUserAccessUpdate.user_id &&
          usertenantUserAccessUpdateActivo[tenantUserAccessUpdate.user_id]
        ) {
          if (tenantUserAccessUpdate.user_id.toString().indexOf('-') !== -1)
            usertenantUserAccessUpdateActivo[
              tenantUserAccessUpdate.user_id
            ].tenant_user_access = union(
              usertenantUserAccessUpdateActivo.tenant_user_access,
              [tenantUserAccessUpdate.id]
            );
        } else if (tenantUserAccessUpdate) {
          usertenantUserAccessUpdateActivo.tenant_user_access = union(
            usertenantUserAccessUpdateActivo.tenant_user_access
              ? usertenantUserAccessUpdateActivo.tenant_user_access
              : [],
            [tenantUserAccessUpdate.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: usertenantUserAccessUpdateActivo
        //nuevos: tenantUserAccessUpdate && tenantUserAccessUpdate.user_id ? union(state.nuevos, [tenantUserAccessUpdate.user_id]) : state.nuevos,
      });
    case DELETE_TENANTUSERACCESS:
      let usertenantUserAccessDeleteActivo = clone(state.nuevo);
      Object.values(action.tenantUserAccess).map(tenantUserAccessDelete => {
        if (
          tenantUserAccessDelete &&
          tenantUserAccessDelete.user_id &&
          tenantUserAccessDelete.user_id &&
          usertenantUserAccessDeleteActivo[tenantUserAccessDelete.user_id]
        ) {
          if (tenantUserAccessDelete.user_id.toString().indexOf('-') !== -1)
            usertenantUserAccessDeleteActivo[
              tenantUserAccessDelete.user_id
            ].tenant_user_access = difference(
              usertenantUserAccessDeleteActivo.tenant_user_access,
              [tenantUserAccessDelete.id]
            );
        } else if (tenantUserAccessDelete) {
          usertenantUserAccessDeleteActivo.tenant_user_access = difference(
            usertenantUserAccessDeleteActivo.tenant_user_access
              ? usertenantUserAccessDeleteActivo.tenant_user_access
              : [],
            [tenantUserAccessDelete.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: usertenantUserAccessDeleteActivo
        //nuevos: tenantUserAccessDelete && tenantUserAccessDelete.user_id ? union(state.nuevos, [tenantUserAccessDelete.user_id]) : state.nuevos,
      });

    //TICKET
    case SUCCESS_CREATE_TICKET:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_TICKET:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_TICKET:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_TICKETS:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    //PROPERTY
    case SUCCESS_CREATE_PROPERTY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_PROPERTY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });

    case DELETE_USER:
      let datoUserDelete = action.user;
      let idsDelete = [];
      Object.values(action.user).map(userDelete => {
        if (userDelete && userDelete.id) idsDelete.push(userDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoUserDelete)),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_USER:
      let datoUserDeleteCreate = action.user;
      let idsDeleteCreate = [];
      Object.values(action.user).map(userDelete => {
        if (userDelete && userDelete.id) idsDeleteCreate.push(userDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoUserDeleteCreate)),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_USER:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.user,
        error: null
      });
    case REQUEST_DELETE_USER:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_USER:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_USER:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_USER:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    //TICKET
    case SUCCESS_CREATE_TICKET:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_TICKET:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_TICKET:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_TICKETS:
      return Object.assign({}, state, {
        eliminado: {}
      }); //PROPERTY
    case SUCCESS_CREATE_PROPERTY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_PROPERTY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_USER:
      let idsCreate = [];
      Object.values(action.user).map(userCreate => {
        if (userCreate && userCreate.id) idsCreate.push(userCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.user,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_USER:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_USER:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_USER:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_USERS:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_USERS:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_USERS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_USER:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_USER:
      let datoUserDeleteCreate = action.user;
      let idsDeleteCreate = [];
      Object.values(action.user).map(userDelete => {
        if (userDelete && userDelete.id) idsDeleteCreate.push(userDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(clone(state.print), Object.keys(datoUserDeleteCreate)),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const users = combineReducers({
  byId: usersById,
  allIds: allUsers,
  update: update,
  create: create,
  totalUsers: totalUsers,
  delete: deleter,
  print: print
});

export default users;
