import {
  INVALIDATE_DEPENDENCIES,
  ERROR_DEPENDENCIES,
  RECEIVE_DEPENDENCIES,
  REQUEST_DEPENDENCIES,
  RESET_DEPENDENCIES,
  ERROR_DEPENDENCY,
  RECEIVE_DEPENDENCY,
  REQUEST_DEPENDENCY,
  UPDATE_DEPENDENCY,
  REQUEST_UPDATE_DEPENDENCY,
  SUCCESS_UPDATE_DEPENDENCY,
  ERROR_UPDATE_DEPENDENCY,
  RESET_UPDATE_DEPENDENCY,
  REQUEST_UPDATE_DEPENDENCIES,
  SUCCESS_UPDATE_DEPENDENCIES,
  ERROR_UPDATE_DEPENDENCIES,
  RESET_UPDATE_DEPENDENCIES,
  CREATE_DEPENDENCY,
  ERROR_CREATE_DEPENDENCY,
  REQUEST_CREATE_DEPENDENCY,
  RESET_CREATE_DEPENDENCY,
  SUCCESS_CREATE_DEPENDENCY,
  REQUEST_CREATE_DEPENDENCIES,
  SUCCESS_CREATE_DEPENDENCIES,
  ERROR_CREATE_DEPENDENCIES,
  RESET_CREATE_DEPENDENCIES,
  DELETE_DEPENDENCY,
  DELETE_CREATE_DEPENDENCY,
  DELETE_UPDATE_DEPENDENCY,
  REQUEST_DELETE_DEPENDENCY,
  SUCCESS_DELETE_DEPENDENCY,
  ERROR_DELETE_DEPENDENCY,
  RESET_DELETE_DEPENDENCY,
  REQUEST_PRINT_DEPENDENCY,
  SUCCESS_PRINT_DEPENDENCY,
  ERROR_PRINT_DEPENDENCY,
  RESET_PRINT_DEPENDENCY,
  RECEIVE_FILE_DEPENDENCY,
  REQUEST_PRINT_DEPENDENCIES,
  SUCCESS_PRINT_DEPENDENCIES,
  ERROR_PRINT_DEPENDENCIES,
  RESET_PRINT_DEPENDENCIES,
  RECEIVE_FILE_DEPENDENCIES,
  PRINT_DEPENDENCY,
  DELETE_PRINT_DEPENDENCY
} from '../actions/DependencyActions';

import {
  RECEIVE_TENANT,
  RECEIVE_TENANTS,
  SUCCESS_DELETE_TENANT,
  SUCCESS_CREATE_TENANT,
  SUCCESS_UPDATE_TENANT,
  SUCCESS_UPDATE_TENANTS
} from '../actions/TenantActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    dependencies: {},
    files: {}
  };
}

function dependenciesById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_DEPENDENCIES:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_DEPENDENCIES:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_DEPENDENCIES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_DEPENDENCIES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        dependencies: {}
      });
    case RECEIVE_DEPENDENCIES:
      let dato = action.dependencies.entities.dependencies;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        dependencies: merge({}, state.dependencies, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_DEPENDENCY:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_DEPENDENCY:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_DEPENDENCY:
      let datoDependency = action.dependency.entities.dependencies;
      return Object.assign({}, state, {
        dependencies: merge({}, state.dependencies, datoDependency),
        isFetching: false
      });
    case RECEIVE_FILE_DEPENDENCY:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_DEPENDENCY:
      let datoDependencyEliminado = action.dependency.entities.dependencies;
      return Object.assign({}, state, {
        dependencies: mergeWith(
          clone(datoDependencyEliminado),
          state.dependencies,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_DEPENDENCY:
      let datoDependencyCreado = action.dependency.entities.dependencies;
      return Object.assign({}, state, {
        dependencies: mergeWith(
          clone(datoDependencyCreado),
          state.dependencies,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_DEPENDENCIES:
      let datosDependencyCreado = action.dependencies.entities.dependencies;
      return Object.assign({}, state, {
        dependencies: mergeWith(
          clone(datosDependencyCreado),
          state.dependencies,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_DEPENDENCY:
      let datoDependencyActualizado = action.dependency.entities.dependencies;
      return Object.assign({}, state, {
        dependencies: mergeWith(
          clone(datoDependencyActualizado),
          state.dependencies,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_DEPENDENCIES:
      let datosDependencyActualizado =
        action.dependencies.entities.dependencies;
      return Object.assign({}, state, {
        dependencies: mergeWith(
          clone(datosDependencyActualizado),
          state.dependencies,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    //TENANT
    case RECEIVE_TENANT:
      let tenant =
        action.tenant.entities && action.tenant.entities.dependencies
          ? action.tenant.entities.dependencies
          : {};
      return Object.assign({}, state, {
        dependencies: merge({}, state.dependencies, tenant)
      });
    case RECEIVE_TENANTS:
      let tenants =
        action.tenants.entities && action.tenants.entities.dependencies
          ? action.tenants.entities.dependencies
          : {};
      return Object.assign({}, state, {
        dependencies: merge({}, state.dependencies, tenants)
      });
    case SUCCESS_DELETE_TENANT:
      let datotenantEliminado =
        action.tenant.entities && action.tenant.entities.dependencies
          ? action.tenant.entities.dependencies
          : {};
      return Object.assign({}, state, {
        dependencies: mergeWith(
          clone(datotenantEliminado),
          pickBy(state.dependencies, function (dependency) {
            return dependency.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_TENANT:
      let datotenantCreado =
        action.tenant.entities && action.tenant.entities.dependencies
          ? action.tenant.entities.dependencies
          : {};
      return Object.assign({}, state, {
        dependencies: mergeWith(
          clone(datotenantCreado),
          pickBy(state.dependencies, function (dependency) {
            return dependency.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TENANT:
      let datotenantActualizado =
        action.tenant.entities && action.tenant.entities.dependencies
          ? action.tenant.entities.dependencies
          : {};
      return Object.assign({}, state, {
        dependencies: mergeWith(
          clone(datotenantActualizado),
          pickBy(state.dependencies, function (dependency) {
            return dependency.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TENANTS:
      let datostenantActualizado =
        action.tenants.entities && action.tenants.entities.dependencies
          ? action.tenants.entities.dependencies
          : {};
      return Object.assign({}, state, {
        dependencies: mergeWith(
          clone(datostenantActualizado),
          state.dependencies,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        dependencies: {}
      });
    default:
      return state;
  }
}

function allDependencies(state = [], action) {
  switch (action.type) {
    case RECEIVE_DEPENDENCIES:
      return action.dependencies.result &&
        action.dependencies.result.dependencies
        ? union(action.dependencies.result.dependencies, state)
        : action.dependencies.result
        ? action.dependencies.result
        : state;
    case RECEIVE_DEPENDENCY:
      return action.dependency.result
        ? union([action.dependency.result], state)
        : state;

    case SUCCESS_CREATE_DEPENDENCY:
      let datoDependencySCreate = action.dependency.entities.dependencies;
      let idNuevoSCreate = null;
      if (Object.values(datoDependencySCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoDependencySCreate)[0] &&
          Object.values(datoDependencySCreate)[0].id
            ? Object.values(datoDependencySCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_DEPENDENCIES:
      let dependenciesCreate =
        action.dependencies.entities &&
        action.dependencies.entities.dependencies
          ? action.dependencies.entities.dependencies
          : null;
      return dependenciesCreate
        ? union(
            state,
            Object.values(dependenciesCreate).map(dependencies => {
              return dependencies.id;
            })
          )
        : state;
    case RESET_DEPENDENCIES:
      return [];

    case RECEIVE_TENANT:
      let tenant =
        action.tenant.entities && action.tenant.entities.dependencies
          ? action.tenant.entities.dependencies
          : null;
      return tenant
        ? union(
            state,
            Object.values(tenant).map(tenant => {
              return tenant.id;
            })
          )
        : state;
    case RECEIVE_TENANTS:
      let tenants =
        action.tenants.entities && action.tenants.entities.dependencies
          ? action.tenants.entities.dependencies
          : null;
      return tenants
        ? union(
            state,
            Object.values(tenants).map(tenants => {
              return tenants.id;
            })
          )
        : state;

    case SUCCESS_DELETE_TENANT:
      let tenantDelete =
        action.tenant.entities && action.tenant.entities.dependencies
          ? action.tenant.entities.dependencies
          : null;
      return tenantDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(tenantDelete).map(tenant => {
              return tenant.id;
            })
          )
        : state;
    case SUCCESS_CREATE_TENANT:
      let tenantCreate =
        action.tenant.entities && action.tenant.entities.dependencies
          ? action.tenant.entities.dependencies
          : null;
      return tenantCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(tenantCreate).map(tenant => {
              return tenant.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_TENANT:
      let tenantUpdate =
        action.tenant.entities && action.tenant.entities.dependencies
          ? action.tenant.entities.dependencies
          : null;
      return tenantUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(tenantUpdate).map(tenant => {
              return tenant.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_TENANTS:
      let tenantsUpdate =
        action.tenants.entities && action.tenants.entities.dependencies
          ? action.tenants.entities.dependencies
          : null;
      return tenantsUpdate
        ? union(
            state,
            Object.values(tenantsUpdate).map(tenants => {
              return tenants.id;
            })
          )
        : state;

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalDependencies(state = null, action) {
  switch (action.type) {
    case RECEIVE_DEPENDENCIES:
      return action.dependencies && action.dependencies.result.total
        ? action.dependencies.result.total
        : 0;
    case RESET_DEPENDENCIES:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_DEPENDENCY:
      let dato = action.dependency.entities.dependencies;
      let dependency =
        dato && Object.keys(dato).length > 0
          ? dato[action.dependency.result]
          : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: dependency ? dependency : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_DEPENDENCY:
      let idsUpdate = [];
      Object.values(action.dependency).map(dependencyUpdate => {
        if (dependencyUpdate && dependencyUpdate.id)
          idsUpdate.push(dependencyUpdate.id);
      });
      return merge({}, state, {
        activo: action.dependency,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_DEPENDENCY:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_DEPENDENCY:
      let datoDependencyActualizado = action.dependency.entities.dependencies;
      let dependencyNuevo =
        datoDependencyActualizado &&
        Object.keys(datoDependencyActualizado).length > 0
          ? datoDependencyActualizado[action.dependency.result]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: dependencyNuevo
      });
    case ERROR_UPDATE_DEPENDENCY:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_DEPENDENCIES:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_DEPENDENCIES:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_DEPENDENCIES:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_DEPENDENCY:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    //TENANT
    //TODO ver si esta bien
    case SUCCESS_CREATE_TENANT:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_TENANT:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_TENANT:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_TENANTS:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });

    case DELETE_DEPENDENCY:
      let datoDependencyDelete = action.dependency;
      let idsDelete = [];
      Object.values(action.dependency).map(dependencyDelete => {
        if (dependencyDelete && dependencyDelete.id)
          idsDelete.push(dependencyDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(clone(state.activo), Object.keys(datoDependencyDelete)),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_DEPENDENCY:
      let datoDependencyDeleteUpdate = action.dependency;
      let idsDeleteUpdate = [];
      Object.values(action.dependency).map(dependencyDelete => {
        if (dependencyDelete && dependencyDelete.id)
          idsDeleteUpdate.push(dependencyDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoDependencyDeleteUpdate)
          ),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_DEPENDENCY:
      let datoDependencyDeleted = {};
      if (Object.values(action.dependency.entities.dependencies).length > 0)
        datoDependencyDeleted = Object.values(
          action.dependency.entities.dependencies
        )[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoDependencyDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_DEPENDENCY:
      let idsCreate = [];
      Object.values(action.dependency).map(dependencyCreate => {
        if (dependencyCreate && dependencyCreate.id)
          idsCreate.push(dependencyCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.dependency,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_DEPENDENCY:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_DEPENDENCY:
      let datoDependencyNuevo = action.dependency.entities.dependencies;
      let dependencyNuevo =
        datoDependencyNuevo && Object.keys(datoDependencyNuevo).length > 0
          ? datoDependencyNuevo[action.dependency.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: dependencyNuevo,
        nuevos: []
      });
    case ERROR_CREATE_DEPENDENCY:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_DEPENDENCIES:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_DEPENDENCIES:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_DEPENDENCIES:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_DEPENDENCY:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    //TENANT
    case SUCCESS_CREATE_TENANT:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_TENANT:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_TENANT:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_TENANTS:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });

    case DELETE_DEPENDENCY:
      let datoDependencyDelete = action.dependency;
      let idsDelete = [];
      Object.values(action.dependency).map(dependencyDelete => {
        if (dependencyDelete && dependencyDelete.id)
          idsDelete.push(dependencyDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoDependencyDelete)),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_DEPENDENCY:
      let datoDependencyDeleteCreate = action.dependency;
      let idsDeleteCreate = [];
      Object.values(action.dependency).map(dependencyDelete => {
        if (dependencyDelete && dependencyDelete.id)
          idsDeleteCreate.push(dependencyDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoDependencyDeleteCreate)
          ),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_DEPENDENCY:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.dependency,
        error: null
      });
    case REQUEST_DELETE_DEPENDENCY:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_DEPENDENCY:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_DEPENDENCY:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_DEPENDENCY:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    //TENANT
    case SUCCESS_CREATE_TENANT:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_TENANT:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_TENANT:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_TENANTS:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_DEPENDENCY:
      let idsCreate = [];
      Object.values(action.dependency).map(dependencyCreate => {
        if (dependencyCreate && dependencyCreate.id)
          idsCreate.push(dependencyCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.dependency,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_DEPENDENCY:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_DEPENDENCY:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_DEPENDENCY:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_DEPENDENCIES:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_DEPENDENCIES:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_DEPENDENCIES:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_DEPENDENCY:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_DEPENDENCY:
      let datoDependencyDeleteCreate = action.dependency;
      let idsDeleteCreate = [];
      Object.values(action.dependency).map(dependencyDelete => {
        if (dependencyDelete && dependencyDelete.id)
          idsDeleteCreate.push(dependencyDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(
            clone(state.print),
            Object.keys(datoDependencyDeleteCreate)
          ),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const dependencies = combineReducers({
  byId: dependenciesById,
  allIds: allDependencies,
  update: update,
  create: create,
  totalDependencies: totalDependencies,
  delete: deleter,
  print: print
});

export default dependencies;
