import React from 'react';
import { useSelector } from 'react-redux';
import {
  FormControl,
  FormGroup,
  FormLabel,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';
import Box from '@material-ui/core/Box';

export default function PriceFilter(props) {
  const { query, setQuery } = props;
  const { codCurrency, priceFrom, priceTo } = query;
  const currencies = useSelector(state => state.currencies);

  const handleChange = e => {
    let value = e.target.value;
    let id = e.target.id ? e.target.id : e.target.name;
    let _query = Object.assign({}, query);
    if (value) _query[id] = value;
    else delete _query[id];
    setQuery(_query);
  };

  return (
    <FormControl component="fieldset" variant="standard">
      <FormLabel component="legend" className={'formLabel'}>
        Precio
      </FormLabel>
      <FormGroup style={{ display: 'flex' }}>
        <TextField
          id="codCurrency"
          select
          label="Moneda"
          value={codCurrency ? codCurrency : ''}
          onChange={handleChange}
          inputProps={{ id: 'codCurrency', name: 'codCurrency' }}
          // helperText="Please select your currency"
        >
          {currencies &&
            currencies.allIds &&
            currencies.allIds.map(id => {
              let currency = currencies.byId.currencies[id];
              return (
                <MenuItem key={currency.simbol} value={currency.cod}>
                  {currency.simbol}
                </MenuItem>
              );
            })}
        </TextField>
        <Box display={'flex'} gap={'5px'} alignItems={'center'}>
          <TextField
            id="priceFrom"
            label="Desde"
            margin={'normal'}
            value={priceFrom ? priceFrom : ''}
            onChange={handleChange}
            // helperText="Please select your currency"
          />
          <Typography variant={'subtitle2'}>-</Typography>
          <TextField
            id="priceTo"
            label="Hasta"
            margin={'normal'}
            value={priceTo ? priceTo : ''}
            onChange={handleChange}
            // helperText="Please select your currency"
          />
        </Box>
        {/*<Slider*/}
        {/*    getAriaLabel={() => 'Rango Precio'}*/}
        {/*    value={currencySelected}*/}
        {/*    onChange={handleChange}*/}
        {/*    valueLabelDisplay="auto"*/}
        {/*    getAriaValueText={valuetext}*/}
        {/*    min={0}*/}
        {/*    max={999999}*/}
        {/*    sx={{mb: 2}}*/}
        {/*/>*/}
        {/*<FormControlLabel*/}
        {/*    control={*/}
        {/*        <Checkbox name="ph"/>*/}
        {/*    }*/}
        {/*    label="Financiación"*/}
        {/*/>*/}

        {/*<FormControlLabel*/}
        {/*    control={*/}
        {/*        <Checkbox name="Apto crédito Hipotecario"/>*/}
        {/*    }*/}
        {/*    label="Apto crédito Hipotecario"*/}
        {/*/>*/}

        {/*<FormControlLabel*/}
        {/*    control={*/}
        {/*        <Checkbox name="Permuta"/>*/}
        {/*    }*/}
        {/*    label="Acepta Permuta"*/}
        {/*/>*/}
        {/*<FormHelperText>Be careful</FormHelperText>*/}
      </FormGroup>
    </FormControl>
  );
}
