import {
  INVALIDATE_CURRENCIES,
  ERROR_CURRENCIES,
  RECEIVE_CURRENCIES,
  REQUEST_CURRENCIES,
  RESET_CURRENCIES,
  ERROR_CURRENCY,
  RECEIVE_CURRENCY,
  REQUEST_CURRENCY,
  UPDATE_CURRENCY,
  REQUEST_UPDATE_CURRENCY,
  SUCCESS_UPDATE_CURRENCY,
  ERROR_UPDATE_CURRENCY,
  RESET_UPDATE_CURRENCY,
  REQUEST_UPDATE_CURRENCIES,
  SUCCESS_UPDATE_CURRENCIES,
  ERROR_UPDATE_CURRENCIES,
  RESET_UPDATE_CURRENCIES,
  CREATE_CURRENCY,
  ERROR_CREATE_CURRENCY,
  REQUEST_CREATE_CURRENCY,
  RESET_CREATE_CURRENCY,
  SUCCESS_CREATE_CURRENCY,
  REQUEST_CREATE_CURRENCIES,
  SUCCESS_CREATE_CURRENCIES,
  ERROR_CREATE_CURRENCIES,
  RESET_CREATE_CURRENCIES,
  DELETE_CURRENCY,
  DELETE_CREATE_CURRENCY,
  DELETE_UPDATE_CURRENCY,
  REQUEST_DELETE_CURRENCY,
  SUCCESS_DELETE_CURRENCY,
  ERROR_DELETE_CURRENCY,
  RESET_DELETE_CURRENCY,
  REQUEST_PRINT_CURRENCY,
  SUCCESS_PRINT_CURRENCY,
  ERROR_PRINT_CURRENCY,
  RESET_PRINT_CURRENCY,
  RECEIVE_FILE_CURRENCY,
  REQUEST_PRINT_CURRENCIES,
  SUCCESS_PRINT_CURRENCIES,
  ERROR_PRINT_CURRENCIES,
  RESET_PRINT_CURRENCIES,
  RECEIVE_FILE_CURRENCIES,
  PRINT_CURRENCY,
  DELETE_PRINT_CURRENCY
} from '../actions/CurrencyActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    currencies: {},
    files: {}
  };
}

function currenciesById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_CURRENCIES:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_CURRENCIES:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_CURRENCIES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_CURRENCIES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        currencies: {}
      });
    case RECEIVE_CURRENCIES:
      let dato = action.currencies.entities.currencies;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        currencies: merge({}, state.currencies, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_CURRENCY:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_CURRENCY:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_CURRENCY:
      let datoCurrency = action.currency.entities.currencies;
      return Object.assign({}, state, {
        currencies: merge({}, state.currencies, datoCurrency),
        isFetching: false
      });
    case RECEIVE_FILE_CURRENCY:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_CURRENCY:
      let datoCurrencyEliminado = action.currency.entities.currencies;
      return Object.assign({}, state, {
        currencies: mergeWith(
          clone(datoCurrencyEliminado),
          state.currencies,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_CURRENCY:
      let datoCurrencyCreado = action.currency.entities.currencies;
      return Object.assign({}, state, {
        currencies: mergeWith(
          clone(datoCurrencyCreado),
          state.currencies,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_CURRENCIES:
      let datosCurrencyCreado = action.currencies.entities.currencies;
      return Object.assign({}, state, {
        currencies: mergeWith(
          clone(datosCurrencyCreado),
          state.currencies,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_CURRENCY:
      let datoCurrencyActualizado = action.currency.entities.currencies;
      return Object.assign({}, state, {
        currencies: mergeWith(
          clone(datoCurrencyActualizado),
          state.currencies,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_CURRENCIES:
      let datosCurrencyActualizado = action.currencies.entities.currencies;
      return Object.assign({}, state, {
        currencies: mergeWith(
          clone(datosCurrencyActualizado),
          state.currencies,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        currencies: {}
      });
    default:
      return state;
  }
}

function allCurrencies(state = [], action) {
  switch (action.type) {
    case RECEIVE_CURRENCIES:
      return action.currencies.result && action.currencies.result.currencies
        ? union(action.currencies.result.currencies, state)
        : action.currencies.result
        ? action.currencies.result
        : state;
    case RECEIVE_CURRENCY:
      return action.currency.result
        ? union([action.currency.result], state)
        : state;

    case SUCCESS_CREATE_CURRENCY:
      let datoCurrencySCreate = action.currency.entities.currencies;
      let idNuevoSCreate = null;
      if (Object.values(datoCurrencySCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoCurrencySCreate)[0] &&
          Object.values(datoCurrencySCreate)[0].id
            ? Object.values(datoCurrencySCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_CURRENCIES:
      let currenciesCreate =
        action.currencies.entities && action.currencies.entities.currencies
          ? action.currencies.entities.currencies
          : null;
      return currenciesCreate
        ? union(
            state,
            Object.values(currenciesCreate).map(currencies => {
              return currencies.id;
            })
          )
        : state;
    case RESET_CURRENCIES:
      return [];

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalCurrencies(state = null, action) {
  switch (action.type) {
    case RECEIVE_CURRENCIES:
      return action.currencies && action.currencies.result.total
        ? action.currencies.result.total
        : 0;
    case RESET_CURRENCIES:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_CURRENCY:
      let dato = action.currency.entities.currencies;
      let currency =
        dato && Object.keys(dato).length > 0
          ? dato[action.currency.result]
          : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: currency ? currency : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_CURRENCY:
      let idsUpdate = [];
      Object.values(action.currency).map(currencyUpdate => {
        if (currencyUpdate && currencyUpdate.id)
          idsUpdate.push(currencyUpdate.id);
      });
      return merge({}, state, {
        activo: action.currency,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_CURRENCY:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_CURRENCY:
      let datoCurrencyActualizado = action.currency.entities.currencies;
      let currencyNuevo =
        datoCurrencyActualizado &&
        Object.keys(datoCurrencyActualizado).length > 0
          ? datoCurrencyActualizado[action.currency.result]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: currencyNuevo
      });
    case ERROR_UPDATE_CURRENCY:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_CURRENCIES:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_CURRENCIES:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_CURRENCIES:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_CURRENCY:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    case DELETE_CURRENCY:
      let datoCurrencyDelete = action.currency;
      let idsDelete = [];
      Object.values(action.currency).map(currencyDelete => {
        if (currencyDelete && currencyDelete.id)
          idsDelete.push(currencyDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(clone(state.activo), Object.keys(datoCurrencyDelete)),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_CURRENCY:
      let datoCurrencyDeleteUpdate = action.currency;
      let idsDeleteUpdate = [];
      Object.values(action.currency).map(currencyDelete => {
        if (currencyDelete && currencyDelete.id)
          idsDeleteUpdate.push(currencyDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoCurrencyDeleteUpdate)
          ),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_CURRENCY:
      let datoCurrencyDeleted = {};
      if (Object.values(action.currency.entities.currencies).length > 0)
        datoCurrencyDeleted = Object.values(
          action.currency.entities.currencies
        )[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoCurrencyDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_CURRENCY:
      let idsCreate = [];
      Object.values(action.currency).map(currencyCreate => {
        if (currencyCreate && currencyCreate.id)
          idsCreate.push(currencyCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.currency,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_CURRENCY:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_CURRENCY:
      let datoCurrencyNuevo = action.currency.entities.currencies;
      let currencyNuevo =
        datoCurrencyNuevo && Object.keys(datoCurrencyNuevo).length > 0
          ? datoCurrencyNuevo[action.currency.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: currencyNuevo,
        nuevos: []
      });
    case ERROR_CREATE_CURRENCY:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_CURRENCIES:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_CURRENCIES:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_CURRENCIES:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_CURRENCY:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    case DELETE_CURRENCY:
      let datoCurrencyDelete = action.currency;
      let idsDelete = [];
      Object.values(action.currency).map(currencyDelete => {
        if (currencyDelete && currencyDelete.id)
          idsDelete.push(currencyDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoCurrencyDelete)),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_CURRENCY:
      let datoCurrencyDeleteCreate = action.currency;
      let idsDeleteCreate = [];
      Object.values(action.currency).map(currencyDelete => {
        if (currencyDelete && currencyDelete.id)
          idsDeleteCreate.push(currencyDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoCurrencyDeleteCreate)
          ),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_CURRENCY:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.currency,
        error: null
      });
    case REQUEST_DELETE_CURRENCY:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_CURRENCY:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_CURRENCY:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_CURRENCY:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_CURRENCY:
      let idsCreate = [];
      Object.values(action.currency).map(currencyCreate => {
        if (currencyCreate && currencyCreate.id)
          idsCreate.push(currencyCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.currency,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_CURRENCY:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_CURRENCY:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_CURRENCY:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_CURRENCIES:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_CURRENCIES:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_CURRENCIES:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_CURRENCY:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_CURRENCY:
      let datoCurrencyDeleteCreate = action.currency;
      let idsDeleteCreate = [];
      Object.values(action.currency).map(currencyDelete => {
        if (currencyDelete && currencyDelete.id)
          idsDeleteCreate.push(currencyDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(
            clone(state.print),
            Object.keys(datoCurrencyDeleteCreate)
          ),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const currencies = combineReducers({
  byId: currenciesById,
  allIds: allCurrencies,
  update: update,
  create: create,
  totalCurrencies: totalCurrencies,
  delete: deleter,
  print: print
});

export default currencies;
