import {
  resetCountries,
  resetCreateCountry,
  resetUpdateCountry,
  resetDeleteCountry
} from '../actions/CountryActions';

var resetCountry = {
  resetAll(dispatch) {
    dispatch(resetCountries());
    dispatch(resetCreateCountry());
    dispatch(resetUpdateCountry());
    dispatch(resetDeleteCountry());
  }
};

export default resetCountry;
