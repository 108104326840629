import React, { useMemo } from 'react';
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import CustomPaper from '../../../library/CustomPaper/CustomPaper';
import PaperBody from '../../../library/CustomPaper/PaperBody';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import SimpleAnalyticMeasure from './SimpleAnalyticMeasure';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Paper, Typography, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

moment.locale('es');

const CustomTooltip = ({ active, payload, label }) => {
  return (
    <Paper style={{ padding: '10px' }}>
      <Box display={'flex'} flexDirection={'column'}>
        <Typography variant={'caption'}>
          {`${moment(payload?.[0]?.payload.date).format('DD-MM-YYYY')}`}
        </Typography>
        <Typography variant={'body2'}>
          {`Usuarios: ${payload?.[0]?.payload.value}`}
        </Typography>
      </Box>
    </Paper>
  );
};

function UsersCard() {
  const theme = useTheme();
  const analytics = useSelector(state => state.analytics);
  const totalSessions_dateTime = analytics.byId.analytics['totalSessions_date'];
  const totalNewUsers = analytics.byId.analytics['totalUsers'];
  const prevMetric = useMemo(() => {
    return totalSessions_dateTime?.prevMetric.reduce(
      (accumulator, currentValue) => accumulator + currentValue.value,
      0
    );
  }, totalSessions_dateTime);

  const format_totalSessions_date = useMemo(() => {
    let total = 0;
    return [...Array(31).keys()].map(i => {
      let value = 0;
      let date = moment().subtract(30 - i, 'days');
      totalSessions_dateTime?.metric.some(metric => {
        if (moment(metric.date).isSame(date, 'day')) {
          value = metric.value;
          return true;
        } else return false;
      });
      total += value;
      return {
        date: date.valueOf(),
        value: parseInt(value),
        total: parseInt(total)
      };
    });
  }, totalSessions_dateTime);

  return (
    <>
      {analytics.byId.isFetching ? (
        <Grid item md={7} sm={12} xs={12} style={{ minHeight: '330px' }}>
          <CustomPaper
            style={{ height: '100%', marginTop: '0', overflow: 'hidden' }}
          >
            <Skeleton variant="rectangular" width="100%" height="100%" />
          </CustomPaper>
        </Grid>
      ) : (
        <Grid item md={7} sm={12} xs={12}>
          <CustomPaper style={{ height: '100%', marginTop: 0 }}>
            <PaperBody>
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                width={'100%'}
                gridColumnGap={'70px'}
              >
                <SimpleAnalyticMeasure
                  title={'Visitantes'}
                  percentage={
                    ((format_totalSessions_date?.[
                      format_totalSessions_date.length - 1
                    ].total -
                      prevMetric) *
                      100) /
                    prevMetric
                  }
                  value={
                    format_totalSessions_date?.[
                      format_totalSessions_date.length - 1
                    ].total
                      ? format_totalSessions_date?.[
                          format_totalSessions_date.length - 1
                        ].total
                      : 0
                  }
                />
                <SimpleAnalyticMeasure
                  title={'Visitantes nuevos'}
                  value={totalNewUsers?.metric}
                  percentage={
                    ((totalNewUsers?.metric - totalNewUsers?.prevMetric) *
                      100) /
                    totalNewUsers?.prevMetric
                  }
                />
              </Box>
              <Box
                width={'100%'}
                height={'200px'}
                minHeight={'100%'}
                marginTop={'60px'}
              >
                <ResponsiveContainer width={'100%'} height={'100%'}>
                  <LineChart
                    width={500}
                    height={300}
                    data={format_totalSessions_date}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5
                    }}
                  >
                    <XAxis
                      dataKey="date"
                      type={'number'}
                      scale="time"
                      interval={'preserveStartEnd'}
                      domain={[
                        moment().subtract(30, 'days').valueOf(),
                        moment().add(1, 'days').valueOf()
                      ]}
                      tick={true}
                      tickFormatter={value => moment(value).format('DD MMM')}
                    />
                    <YAxis />
                    <Tooltip content={<CustomTooltip />} />
                    <Line
                      type="linear"
                      dataKey="value"
                      stroke={theme.palette.primary.main}
                      strokeWidth={1.5}
                      dot={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            </PaperBody>
          </CustomPaper>
        </Grid>
      )}
    </>
  );
}

export default UsersCard;
