import clsx from 'clsx';
import { Box, Button, CircularProgress, Tooltip } from '@material-ui/core';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import c from '../../constants/Constants';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDrag, useDrop } from 'react-dnd';

const styles = makeStyles(theme => ({
  fileInput: {
    marginBottom: '12px',
    '& label': {
      width: '100%',
      height: '100%',
      display: 'flex',
      padding: '0px 8px',
      paddingBottom: '11px',
      paddingTop: '12px',
      borderRadius: '8px',
      // backgroundColor: "rgba(241, 10, 77, 0.1)",
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      '& *': {
        pointerEvents: 'none'
      }
    },
    '& input[type=file]': {
      width: '0.1px',
      height: '0.1px',
      opacity: 0,
      overflow: 'hidden',
      top: '50%',
      left: '50%',
      position: 'absolute',
      zIndex: '-1'
    }
  },
  desktop: {
    // width: "100%",
    width: '200px',
    height: '200px'
    // paddingLeft: "6px",
  },
  buttonUploaded: {
    padding: 0,
    borderRadius: '8px',
    textTransform: 'none',
    width: '100%',
    height: '100%',
    '& .MuiButton-label': {
      width: '100%',
      height: '100%',
      border: '1px solid rgba(0, 0, 0, 0.4)',
      borderRadius: '8px'
      // padding: "3px"
    }
  },
  buttonUpload: {
    '& .MuiButton-label': {
      border: '1px dashed rgba(241, 10, 77, 0.8)'
    }
  },
  labelButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
    '& img': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
      backgroundColor: 'white',
      objectFit: 'cover'
    }
  },
  progress: {
    width: '80px !important',
    height: '80px !important',
    position: 'absolute',
    '& svg circle': {
      strokeWidth: '1.2'
    }
  },
  btnImg: {
    backgroundColor: 'white',
    width: '30px'
  }
}));
export default function PropertyAssetItem(props) {
  const classes = styles();
  const {
    deleteAssetFile,
    asset,
    src,
    handleChangeMainImageProductAsset,
    disabled,
    previewFileAsset,
    handleChangeOrder
  } = props;
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      };
    },
    drop(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = asset.order;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      handleChangeOrder(item.id, dragIndex, hoverIndex);
    }
  });
  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => ({ id: asset.id, index: asset.order }),
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <Box
      component={'div'}
      position={'relative'}
      className={clsx([classes.fileInput, classes.desktop])}
      ref={ref}
      style={{
        opacity,
        ...{
          transition: 'all 0.3s ease-in-out',
          padding: '2px 2px',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Button className={classes.buttonUploaded}>
        <label className={classes.labelButton}>
          <AddPhotoAlternateIcon color={'primary'} />
          {!src && asset && asset.pathThumbnail && (
            <img src={c.API_HOST + asset.pathThumbnail} alt="" />
          )}
          {!src && asset && !asset.pathThumbnail && asset.temppath && (
            <img src={c.HOST + asset.temppath} alt="" />
          )}
          {src && src !== 'loading' && <img src={src} alt="" />}
          {src && src === 'loading' && (
            <CircularProgress className={classes.progress} />
          )}
        </label>
      </Button>
      <Box
        position={'absolute'}
        right={'10px'}
        bottom={'10px'}
        display={'flex'}
        gridColumnGap={'10px'}
      >
        <IconButton
          size="small"
          aria-label="close"
          onClick={() => deleteAssetFile(asset)}
          color="primary"
          className={classes.btnImg}
        >
          <DeleteIcon fontSize="small" />
        </IconButton>
        <IconButton
          size="small"
          aria-label="close"
          onClick={() => previewFileAsset(asset.path ? asset : src)}
          color="primary"
          className={classes.btnImg}
        >
          <VisibilityIcon fontSize="small" />
        </IconButton>
        {asset && asset.mainImage === 1 && (
          <Tooltip title="Foto principal">
            <IconButton
              size="small"
              aria-label="editar"
              edge="end"
              disabled={disabled}
              color="primary"
              className={classes.btnImg}
            >
              <FavoriteIcon />
            </IconButton>
          </Tooltip>
        )}
        {asset && (asset.mainImage === 0 || !asset.mainImage) && (
          <Tooltip title="Poner como foto principal">
            <IconButton
              size="small"
              aria-label="editar"
              edge="end"
              disabled={disabled}
              onClick={() => handleChangeMainImageProductAsset(asset)}
              color="primary"
              className={classes.btnImg}
            >
              <FavoriteBorderIcon />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  );
}
