import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import c from '../../../constants/Constants';
import Avatar from '@material-ui/core/Avatar';
import { Typography, useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';

const useStyles = makeStyles(theme => ({
  colorBox: {
    height: '120px',
    width: '100%',
    background:
      'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(182,162,242,1) 0%, rgba(225,197,233,1) 0%, rgba(115,109,255,0.5564819677871149) 100%)',
    borderRadius: '10px'
  },
  whiteBox: {
    width: '100%',
    borderRadius: '10px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column'
  },
  avatar: {
    position: 'relative',
    top: '70px',
    marginLeft: '70px',
    width: '100px',
    height: '100px',
    backgroundColor: '#dad6e7e8'
  }
}));

function UserPresentationCard(props) {
  const theme = useTheme();
  const classes = useStyles();
  const user = props.user ? props.user : {};
  return (
    <Grid item xs={12}>
      <Box>
        <Box className={classes.colorBox}>
          <Avatar
            className={classes.avatar}
            src={user?.path ? `${c.API_HOST}${user.path}` : ''}
          />
        </Box>
        <Box className={classes.whiteBox}>
          <Box
            height={'100%'}
            display={'flex'}
            flexDirection={'column'}
            marginLeft={'180px'}
            paddingTop={'10px'}
          >
            <Typography variant={'h2'} component={'h7'}>
              {(user?.name ? user.name + ' ' : '') +
                (user.lastname ? user.lastname : '')}
            </Typography>
            <Box display={'flex'} gridColumnGap={'30px'} marginTop={'10px'}>
              <Typography
                variant={'caption'}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <MailOutlineIcon style={{ marginRight: '5px ' }} />
                {`${user?.email ?? ''}`}
              </Typography>

              <Typography
                variant={'caption'}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <PhoneAndroidIcon style={{ marginRight: '5px ' }} />
                {`${user?.cellphone ?? ''}`}
              </Typography>
            </Box>
          </Box>
          {props.children}
        </Box>
      </Box>
    </Grid>
  );
}

export default UserPresentationCard;
