import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import HTMLEditor from '../../library/HTMLEditor';
import PaperBody from '../../library/CustomPaper/PaperBody';
import React from 'react';

export default function Publication(props) {
  const { property, handleChangeProperty, changeProperty } = props;

  const handleChangeTextEditorProperty = (content, delta, source) => {
    console.log(content, delta, source);
    if (source === 'user') {
      let cambio = {};
      cambio.rich_desProperty = content;
      changeProperty(cambio);
    }
  };
  const handleChangeCheckBox = e => {
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.checked;
    changeProperty(cambio);
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            id={'isStarredOnWeb'}
            checked={property && !!property.isStarredOnWeb}
            onChange={handleChangeCheckBox}
          />
        }
        label="Destacado en la web"
      />
      <TextField
        id="publication_title"
        placeholder={'Título'}
        variant="outlined"
        label={'Título'}
        InputLabelProps={{
          shrink: true
        }}
        InputProps={{
          // disabled: disabled
          type: 'text'
        }}
        // disabled={true}
        fullWidth
        margin={'dense'}
        value={
          property && property.publication_title
            ? property.publication_title
            : ''
        }
        onChange={handleChangeProperty}
        // onKeyDown={(e) => handleKeyDown(e)}
      />
      <HTMLEditor
        textLabel={'Descripción'}
        rows={'130px'}
        onChange={(content, delta, source, editor) =>
          handleChangeTextEditorProperty(content, delta, source, editor)
        }
        value={property ? property.rich_desProperty : ''}
      />
    </div>
  );
}
