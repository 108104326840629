import React from 'react';
import { ListItem, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CustomPaper from '../../../library/CustomPaper/CustomPaper';
import PaperBody from '../../../library/CustomPaper/PaperBody';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import moment from 'moment';
import List from '@material-ui/core/List';
import DraftsIcon from '@material-ui/icons/Drafts';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import 'moment/locale/es';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const motives = {
  retired: 'El cliente ya no quiere vender/alquilar',
  other_sold: 'Alguien más la vendió/alquiló',
  sold: 'Nosotros vendimos/alquilamos la propiedad',
  sold_with_cl:
    'Vendimos/alquilamos la propiedad en colaboración con un colega',
  blocked: 'Bloqueado por el desarrollador'
};

function ClosedOperationsListCard(props) {
  const { type } = props;
  const propertyClosedOperations = useSelector(
    state => state['propertyClosedOperations']
  );
  const operationTypes = useSelector(state => state['operationTypes']);
  const properties = useSelector(state => state['properties']);

  return (
    <Grid item md={4} sm={12} xs={12}>
      <CustomPaper style={{ height: '100%', marginTop: 0 }}>
        <PaperBody>
          <Box display={'flex'} flexDirection={'row'}>
            <Typography variant={'button'} component={'h7'}>
              {props.title ? props.title : 'PROPIEDADES MÁS VISTAS'}
            </Typography>
          </Box>
          <Box
            width={'100%'}
            height={'100px'}
            minHeight={'250px'}
            marginTop={'10px'}
            overflow={'auto'}
          >
            <List component="nav" dense alignItems="flex-start">
              {propertyClosedOperations.allIds.map(id => {
                let item =
                  propertyClosedOperations.byId['propertyClosedOperations'][id];
                let operationType =
                  operationTypes.byId['operationTypes'][item.idOperationType];
                let property = properties.byId['properties'][item.property_id];
                if (
                  (type === 'sold' && item.motive === 'sold') ||
                  (type !== 'sold' && item.motive !== 'sold')
                )
                  return (
                    <>
                      <ListItem key={'card' + id}>
                        <ListItemIcon>
                          {item.motive === 'sold' ? (
                            <CheckIcon />
                          ) : (
                            <ClearIcon />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              component="span"
                              variant="subtitle2"
                              color="textPrimary"
                              style={{ fontWeight: 600 }}
                            >
                              {(operationType
                                ? operationType?.nameType
                                : motives[item.motive]) +
                                ' - ' +
                                property?.address}
                            </Typography>
                          }
                          secondary={
                            <React.Fragment>
                              <Typography
                                component="span"
                                variant="body2"
                                color="textPrimary"
                              >
                                {item.price
                                  ? item.codCurrency + ' ' + item.price
                                  : ''}
                              </Typography>
                              {item.name ? ' — ' + item.name : ''}
                              {' — ' +
                                (moment(item.created_at).isSame(moment(), 'day')
                                  ? moment(item.created_at).diff(
                                      moment(),
                                      'minutes'
                                    ) < 60
                                    ? 'hace ' +
                                      moment(item.created_at).diff(
                                        moment(),
                                        'minutes'
                                      ) +
                                      'minutos'
                                    : 'hace ' +
                                      moment(item.created_at).diff(
                                        moment(),
                                        'hours'
                                      ) +
                                      'horas'
                                  : moment(item.created_at).format(
                                      'DD MMM HH:mm'
                                    ))}
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" component="li" />
                    </>
                  );
              })}
              {propertyClosedOperations.allIds.length === 0 && (
                <ListItem>
                  <ListItemIcon>
                    <DraftsIcon />
                  </ListItemIcon>
                  <ListItemText primary={'No hay registros'} />
                </ListItem>
              )}
            </List>
          </Box>
        </PaperBody>
      </CustomPaper>
    </Grid>
  );
}

export default ClosedOperationsListCard;
