import React, { useEffect, useState } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import tenantUtil from '../../../utils/tenantUtil';
import Avatar from '@material-ui/core/Avatar';
import House from '../../../assets/img/icon/house.png';
import Shelter from '../../../assets/img/icon/megaphone.png';
import Question from '../../../assets/img/icon/choice.png';
import Man from '../../../assets/img/icon/group.png';
import * as PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    '& h7': {
      color: 'rgba(70,69,69,0.87)'
    },
    '& span': {
      color: 'rgba(70,69,69,0.87)',
      fontSize: '10px'
    }
  },
  trendingUp: {
    color: theme.palette.success.main
  },
  trendingDown: {
    color: theme.palette.error.main
  }
}));

const externalSystemsIcons = {
  House: House,
  Man: Man,
  Shelter: Shelter,
  Question: Question
};

function SimpleAnalyticMeasure(props) {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.card}>
        <Box>
          <Box
            display={'flex'}
            gridColumnGap={'10px'}
            justifyContent={'flex-start'}
            alignItems={'center'}
          >
            {props.icon && (
              <Avatar src={externalSystemsIcons[props.icon]}></Avatar>
            )}
            <Typography variant={'h7'} component={'h7'}>
              {props.title}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography variant={'h1'}>{props.value}</Typography>
            {!!props.percentage && (
              <Box
                display={'flex'}
                justifyContent={'flex-end'}
                alignItems={'center'}
                flexDirection={'column'}
              >
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  className={
                    props.percentage > 0
                      ? classes.trendingUp
                      : classes.trendingDown
                  }
                >
                  {props.percentage > 0 ? (
                    <TrendingUpIcon />
                  ) : (
                    <TrendingDownIcon />
                  )}
                  <Typography variant={'h6'} component={'h6'}>
                    {`${
                      !Number.isNaN(parseInt(props.percentage))
                        ? parseInt(props.percentage)
                        : '100'
                    }%`}
                  </Typography>
                </Box>
                <Typography variant={'caption'} component={'span'}>
                  vs período anterior
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SimpleAnalyticMeasure;
