import React, { useEffect, useState } from 'react';
import { Box, Container, Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router';
import SimpleAnalyticsUserPanel from './panels/SimpleAnalyticsUserPanel';
import Grid from '@material-ui/core/Grid';
import { fetchAnalytics, resetAnalytics } from '../../actions/AnalyticActions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PropertiesCard from './cards/PropertiesCard';
import { fetchUser } from '../../actions/UserActions';
import UserPresentationCard from './cards/UserPresentationCard';
import PropertiesStatusCard from './cards/PropertiesStatusCard';
import { fetchContacts, resetContacts } from '../../actions/ContactActions';
import ListCard from './cards/ListCard';
import {
  fetchPropertyClosedOperations,
  resetPropertyClosedOperations
} from '../../actions/PropertyClosedOperationActions';
import ClosedOperationsListCard from './cards/ClosedOperationsListCard';
import SwipeableViews from 'react-swipeable-views';
import PropertiesListPage from '../Property/PropertiesListPage';
import ContactsList from '../Contacts/ContactsList';
import 'moment/locale/es';
import DataRangeButton from './components/DataRangeButton';
import PropertiesWithoutAuthorizationCard from './cards/PropertiesWithoutAuthorizationCard';
import PropertiesAuthorizationGraphicCard from './cards/PropertiesAuthorizationGraphicCard';

const useStyles = makeStyles(theme => ({}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function HomeUser(props) {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const usuarioLogueado = auth.usuarioLogueado;
  let { idUser } = useParams();
  idUser = idUser ? idUser : usuarioLogueado.idUser;
  const users = useSelector(state => state.users);
  const user = users.update.activo;
  const [valueInt, setValueInt] = useState(0);
  const [value, setValue] = useState('basic');
  const [dateRange, setDateRange] = React.useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    endDate: new Date()
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(resetAnalytics());

    if (idUser) dispatch(fetchUser(idUser));

    dispatch(
      fetchAnalytics({
        metric: 'totalProperties',
        idUser: idUser
      })
    );
    dispatch(
      fetchAnalytics({
        metric: 'statusProperties',
        idUser: idUser
      })
    );
    dispatch(
      fetchAnalytics({
        metric: 'totalNewProperties',
        dateFrom: moment(dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        dateTo: moment(dateRange.endDate).format('YYYY-MM-DD HH:mm'),
        idUser: idUser
      })
    );
    dispatch(
      fetchAnalytics({
        metric: 'totalNewContacts',
        dateFrom: moment(dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        dateTo: moment(dateRange.endDate).format('YYYY-MM-DD HH:mm'),
        idUser: idUser
      })
    );
    dispatch(
      fetchAnalytics({
        metric: 'totalClosedOperations',
        dateFrom: moment(dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        dateTo: moment(dateRange.endDate).format('YYYY-MM-DD HH:mm'),
        idUser: idUser
      })
    );
    dispatch(
      fetchAnalytics({
        metric: 'totalRetireOperations',
        dateFrom: moment(dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        dateTo: moment(dateRange.endDate).format('YYYY-MM-DD HH:mm'),
        idUser: idUser
      })
    );

    dispatch(
      fetchAnalytics({
        metric: 'totalSessions',
        dimension: 'property',
        idUser: idUser,
        dateFrom: moment(dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        dateTo: moment(dateRange.endDate).format('YYYY-MM-DD HH:mm')
      })
    );
    dispatch(
      fetchAnalytics({
        metric: 'totalSessions',
        dimension: 'lessViewProperties',
        idUser: idUser,
        dateFrom: moment(dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        dateTo: moment(dateRange.endDate).format('YYYY-MM-DD HH:mm')
      })
    );
    dispatch(resetContacts());
    dispatch(
      fetchContacts({
        status: 1,
        limit: 10,
        order: 'created_at',
        direction: 'desc',
        idConsultant: idUser,
        dateFrom: moment(dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        dateTo: moment(dateRange.endDate).format('YYYY-MM-DD HH:mm')
      })
    );
    dispatch(resetPropertyClosedOperations());
    dispatch(
      fetchPropertyClosedOperations({
        status: 1,
        limit: 10,
        order: 'created_at',
        direction: 'desc',
        idUser: idUser,
        with: ['property', 'operationType'],
        dateFrom: moment(dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        dateTo: moment(dateRange.endDate).format('YYYY-MM-DD HH:mm')
      })
    );
    dispatch(
      fetchAnalytics({
        metric: 'propertiesWithoutAuth',
        idUser: idUser
      })
    );
  }, [dateRange]);

  useEffect(() => {
    if (valueInt === 0) {
      dispatch(resetPropertyClosedOperations());
      dispatch(
        fetchPropertyClosedOperations({
          status: 1,
          limit: 10,
          order: 'created_at',
          direction: 'desc',
          idUser: idUser,
          with: ['property', 'operationType'],
          dateFrom: moment(dateRange.startDate).format('YYYY-MM-DD HH:mm'),
          dateTo: moment(dateRange.endDate).format('YYYY-MM-DD HH:mm')
        })
      );
    }
  }, [valueInt]);

  const handleChangeTab = (event, newValue) => {
    const tabs = ['basic', 'properties', 'contacts'];
    setValue(newValue);
    setValueInt(tabs.indexOf(newValue));
  };

  const handleChangeIndexTab = index => {
    const tabs = ['basic', 'properties', 'contacts'];
    setValue(tabs[index]);
    setValueInt(index);
  };

  return (
    <Container maxWidth={'lg'}>
      {/*<Breadcrumbs aria-label="breadcrumb" separator={"‣"}>*/}
      {/*    <Link color="inherit" to="/">*/}
      {/*        Home*/}
      {/*    </Link>*/}
      {/*</Breadcrumbs>*/}
      {/*<Typography component={"h1"} variant={"h1"}>*/}
      {/*    {"Estadísticas"}*/}
      {/*</Typography>*/}
      <Grid container style={{ marginTop: '25px', width: '100%' }} spacing={0}>
        <UserPresentationCard user={user}>
          <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
            <Tabs
              value={value}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Estadísticas" value={'basic'} />
              <Tab label="Propiedades" value={'properties'} />
              <Tab label="Contactos" value={'contacts'} />
            </Tabs>
          </Box>
        </UserPresentationCard>
        <SwipeableViews
          axis={'x'}
          index={valueInt}
          onChangeIndex={handleChangeIndexTab}
          style={{ width: '100%' }}
        >
          <TabPanel value={valueInt} index={0}>
            <DataRangeButton
              setDateRange={setDateRange}
              dateRange={dateRange}
            />
            <Grid container spacing={2}>
              <SimpleAnalyticsUserPanel />
              <PropertiesCard key1={'totalSessions_property'} />
              <PropertiesCard
                key1={'totalSessions_lessViewProperties'}
                title={'PROPIEDADES MENOS VISTAS'}
              />
              <PropertiesStatusCard />
              <ListCard
                listKey={'contacts'}
                titleKey={'contact_topic'}
                textKey={'message'}
                nameKey={'name'}
                title={'Últimos contactos'}
              />
              <ClosedOperationsListCard
                title={'Operaciones concretadas'}
                type={'sold'}
              />
              <ClosedOperationsListCard
                title={'Operaciones canceladas'}
                type={'retired'}
              />
              <PropertiesWithoutAuthorizationCard
                key1={'propertiesWithoutAuth'}
              />
              <PropertiesAuthorizationGraphicCard />
            </Grid>
          </TabPanel>
          <TabPanel value={valueInt} index={1}>
            <Grid container spacing={0}>
              <PropertiesListPage {...props} idUser={idUser} title={false} />
            </Grid>
          </TabPanel>
          <TabPanel value={valueInt} index={2}>
            <Grid container spacing={0}>
              <ContactsList {...props} idConsultant={idUser} title={false} />
            </Grid>
          </TabPanel>
        </SwipeableViews>
      </Grid>
    </Container>
  );
}

export default HomeUser;
