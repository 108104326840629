import React, { useMemo } from 'react';
import { Paper, Typography, useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import CustomPaper from '../../../library/CustomPaper/CustomPaper';
import PaperBody from '../../../library/CustomPaper/PaperBody';
import Box from '@material-ui/core/Box';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { useSelector } from 'react-redux';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Skeleton } from '@material-ui/lab';

const TooltipDeviceCategory = ({ active, payload, label }) => {
  return (
    <Paper style={{ padding: '10px' }}>
      <Box display={'flex'} flexDirection={'column'}>
        <Typography variant={'caption'}>
          {`${payload?.[0]?.payload.device}`}
        </Typography>
        <Typography variant={'body2'}>
          {`Usuarios: ${payload?.[0]?.payload.value}`}
        </Typography>
      </Box>
    </Paper>
  );
};

function DeviceCategoryCard() {
  const analytics = useSelector(state => state.analytics);
  const theme = useTheme();
  const totalSessions_deviceCategory =
    analytics.byId.analytics['totalSessions_deviceCategory'];
  const total = useMemo(() => {
    return totalSessions_deviceCategory?.metric.reduce(
      (accumulator, currentValue) => accumulator + currentValue.value,
      0
    );
  }, totalSessions_deviceCategory);

  const COLORS = [
    theme.palette.primary.main,
    theme.palette.primary.light,
    theme.palette.primary.dark
  ];

  return (
    <Grid item md={4} sm={12} xs={12}>
      {analytics.byId.isFetching ? (
        <CustomPaper
          style={{
            height: '100%',
            marginTop: '0',
            overflow: 'hidden',
            minHeight: '330px'
          }}
        >
          <Skeleton variant="rectangular" width="100%" height="100%" />
        </CustomPaper>
      ) : (
        <CustomPaper style={{ height: '100%', marginTop: 0 }}>
          <PaperBody>
            <Box display={'flex'} flexDirection={'row'}>
              <Typography variant={'button'} component={'h7'}>
                USUARIOS POR CATEGORÍA DE DISPOSITIVO
              </Typography>
            </Box>
            <Box
              width={'100%'}
              height={'100px'}
              minHeight={'190px'}
              marginTop={'10px'}
            >
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={totalSessions_deviceCategory?.metric}
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                  >
                    {totalSessions_deviceCategory?.metric.map(
                      (entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      )
                    )}
                  </Pie>
                  <Tooltip content={<TooltipDeviceCategory />} cursor={false} />
                </PieChart>
              </ResponsiveContainer>
            </Box>
            <Box
              display={'flex'}
              width={'100%'}
              flexDirection={'row'}
              justifyContent={'center'}
              gridColumnGap={'20px'}
              marginTop={'20px'}
            >
              {totalSessions_deviceCategory?.metric.map((metric, i) => {
                return (
                  <Box
                    key={metric.id}
                    display={'flex'}
                    width={'33%'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Box
                      width={'100%'}
                      display={'flex'}
                      justifyContent={'flex-start'}
                      flexDirection={'row'}
                    >
                      <FiberManualRecordIcon
                        size={'small'}
                        style={{
                          fill: COLORS[i],
                          fontSize: '12px',
                          marginTop: '7px'
                        }}
                      />
                      <Typography variant={'body1'} component={'h7'}>
                        {metric.device}
                      </Typography>
                    </Box>
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      flexDirection={'row'}
                    >
                      <Typography
                        variant={'h5'}
                        component={'h2'}
                        style={{ fontWeigth: '300' }}
                      >
                        {parseInt((metric.value * 100) / total) + '%'}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </PaperBody>
        </CustomPaper>
      )}
    </Grid>
  );
}

export default DeviceCategoryCard;
