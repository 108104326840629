import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useValidador } from '../../utils/validador';
import { MenuItem } from '@material-ui/core';

export default function UnsuccessfulTicketClosedOperationModal(props) {
  const { handleChangeTicket, ticket, open, handleClose, disabled } = props;
  //Hooks
  let reglas = {};
  reglas = {
    motive: ['required']
  };
  const [validador, validar] = useValidador(reglas);

  function handleAccept() {
    if (validador.isValid()) {
      props.handleAccept();
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Registrar operación cerrada sin resultados
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Registraremos los datos de la operación cerrada
          </DialogContentText>
          <TextField
            id="motive"
            select
            label="Motivo"
            fullWidth
            value={ticket?.motive ? ticket.motive : ''}
            onChange={handleChangeTicket}
            inputProps={{ id: 'motive', name: 'motive' }}
            onBlur={validar}
            error={!validador.isValidById('motive')}
            helperText={validador.getHelperTextById('motive')}
            disabled={disabled}
          >
            <MenuItem key={'other_sold'} value={'other_sold'}>
              Realizó la operación con otro
            </MenuItem>
            <MenuItem key={'ghost'} value={'ghost'}>
              Desapareció
            </MenuItem>
            <MenuItem key={'drop_off'} value={'drop_off'}>
              Búsqueda suspendida
            </MenuItem>
            <MenuItem key={'appraisal_unsuccess'} value={'appraisal_unsuccess'}>
              Tasación suspendida (no ingresó la propiedad)
            </MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={disabled}>
            Cancelar
          </Button>
          <Button onClick={handleAccept} color="primary" disabled={disabled}>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
