import * as React from 'react';
import { Skeleton } from '@material-ui/lab';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import PaperBody from '../../library/CustomPaper/PaperBody';
import CustomPaper from '../../library/CustomPaper/CustomPaper';

export default function PropertyCardSkeleton(props) {
  const { loading = false } = props;

  return (
    <Grid item md={12} sm={12} xs={12}>
      <CustomPaper>
        <PaperBody spacing={2}>
          <Grid container spacing={3}>
            <Grid item md={4} sm={12} xs={12}>
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={'100%'}
                height={200}
              />
            </Grid>
            <Grid item md={8} sm={12} xs={12}>
              <Skeleton
                animation="wave"
                height={10}
                width="40%"
                style={{ marginBottom: 6 }}
              />
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                marginBottom={'20px'}
                alignItems={'center'}
              >
                <Skeleton
                  animation="wave"
                  height={10}
                  width="60%"
                  style={{ marginBottom: 6 }}
                />
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={60}
                  height={20}
                  style={{ borderRadius: '40px' }}
                />
              </Box>
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={'100%'}
                height={20}
                style={{ marginBottom: 6 }}
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={'100%'}
                height={20}
                style={{ marginBottom: 20 }}
              />
              <Box
                display={'flex'}
                justifyContent={'flex-start'}
                gridColumnGap={'5px'}
                marginBottom={'10px'}
                alignItems={'center'}
              >
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={60}
                  height={20}
                  style={{ borderRadius: '40px' }}
                />
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={60}
                  height={20}
                  style={{ borderRadius: '40px' }}
                />
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={60}
                  height={20}
                  style={{ borderRadius: '40px' }}
                />
              </Box>
              <Box
                display={'flex'}
                justifyContent={'flex-end'}
                gridColumnGap={'5px'}
                marginBottom={'20px'}
                alignItems={'center'}
              >
                <Skeleton
                  animation="wave"
                  variant="rect"
                  width={90}
                  height={30}
                  style={{ borderRadius: '40px' }}
                />
              </Box>
            </Grid>
          </Grid>
        </PaperBody>
      </CustomPaper>
    </Grid>
  );
}
