//api
import propertiesAttributesValuesApi from '../api/propertiesAttributesValuesApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizePropertiesAttributesValues';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//PROPERTIESATTRIBUTESVALUES
export const REQUEST_PROPERTIESATTRIBUTESVALUES =
  'REQUEST_PROPERTIESATTRIBUTESVALUES';
export const RECEIVE_PROPERTIESATTRIBUTESVALUES =
  'RECEIVE_PROPERTIESATTRIBUTESVALUES';
export const INVALIDATE_PROPERTIESATTRIBUTESVALUES =
  'INVALIDATE_PROPERTIESATTRIBUTESVALUES';
export const ERROR_PROPERTIESATTRIBUTESVALUES =
  'ERROR_PROPERTIESATTRIBUTESVALUES';
export const RESET_PROPERTIESATTRIBUTESVALUES =
  'RESET_PROPERTIESATTRIBUTESVALUES';

export function invalidatePropertiesAttributesValues() {
  return {
    type: INVALIDATE_PROPERTIESATTRIBUTESVALUES
  };
}

function requestPropertiesAttributesValues() {
  return {
    type: REQUEST_PROPERTIESATTRIBUTESVALUES
  };
}

function receivePropertiesAttributesValues(json) {
  return {
    type: RECEIVE_PROPERTIESATTRIBUTESVALUES,
    propertiesAttributesValues: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorPropertiesAttributesValues(error) {
  return {
    type: ERROR_PROPERTIESATTRIBUTESVALUES,
    error: error
  };
}

export function resetPropertiesAttributesValues() {
  return {
    type: RESET_PROPERTIESATTRIBUTESVALUES
  };
}

export function fetchPropertiesAttributesValues(filtros) {
  return dispatch => {
    dispatch(requestPropertiesAttributesValues());
    return propertiesAttributesValuesApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receivePropertiesAttributesValues(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(
              errorPropertiesAttributesValues(errorMessages.UNAUTHORIZED_TOKEN)
            );
            dispatch(logout());
            return;
          default:
            dispatch(
              errorPropertiesAttributesValues(errorMessages.GENERAL_ERROR)
            );
            return;
        }
      });
  };
}

function shouldFetchPropertiesAttributesValues(state) {
  const propertiesAttributesValues = state.propertiesAttributesValues.byId;
  if (!propertiesAttributesValues) {
    return true;
  } else if (propertiesAttributesValues.isFetching) {
    return false;
  } else {
    return propertiesAttributesValues.didInvalidate;
  }
}

export function fetchPropertiesAttributesValuesIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchPropertiesAttributesValues(getState())) {
      return dispatch(fetchPropertiesAttributesValues(filtros));
    }
  };
}

//MODEL
export const REQUEST_PROPERTYATTRIBUTEVALUE = 'REQUEST_PROPERTYATTRIBUTEVALUE';
export const RECEIVE_PROPERTYATTRIBUTEVALUE = 'RECEIVE_PROPERTYATTRIBUTEVALUE';
export const INVALIDATE_PROPERTYATTRIBUTEVALUE =
  'INVALIDATE_PROPERTYATTRIBUTEVALUE';
export const ERROR_PROPERTYATTRIBUTEVALUE = 'ERROR_PROPERTYATTRIBUTEVALUE';
export const RESET_PROPERTYATTRIBUTEVALUE = 'RESET_PROPERTYATTRIBUTEVALUE';

export function invalidatePropertyAttributeValue() {
  return {
    type: INVALIDATE_PROPERTYATTRIBUTEVALUE
  };
}

function requestPropertyAttributeValue() {
  return {
    type: REQUEST_PROPERTYATTRIBUTEVALUE
  };
}

export function receivePropertyAttributeValue(json) {
  return {
    type: RECEIVE_PROPERTYATTRIBUTEVALUE,
    propertyAttributeValue: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorPropertyAttributeValue(error) {
  return {
    type: ERROR_PROPERTYATTRIBUTEVALUE,
    error: error
  };
}

export function fetchPropertyAttributeValue(idPropertyAttributeValue) {
  return dispatch => {
    dispatch(requestPropertyAttributeValue());
    return propertiesAttributesValuesApi
      .getOne(idPropertyAttributeValue)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receivePropertyAttributeValue(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(
              errorPropertyAttributeValue(errorMessages.UNAUTHORIZED_TOKEN)
            );
            dispatch(logout());
            return;
          default:
            dispatch(errorPropertyAttributeValue(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_PROPERTYATTRIBUTEVALUE =
  'RECEIVE_FILE_PROPERTYATTRIBUTEVALUE';

function receiveFilePropertyAttributeValue(file) {
  return {
    type: RECEIVE_FILE_PROPERTYATTRIBUTEVALUE,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFilePropertyAttributeValue(
  idPropertyAttributeValue,
  filtros
) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return propertiesAttributesValuesApi
      .getFile(idPropertyAttributeValue, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFilePropertyAttributeValue(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(
              errorPropertyAttributeValue(errorMessages.UNAUTHORIZED_TOKEN)
            );
            dispatch(logout());
            return;
          default:
            dispatch(errorPropertyAttributeValue(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_PROPERTYATTRIBUTEVALUE = 'UPDATE_PROPERTYATTRIBUTEVALUE';
export const REQUEST_UPDATE_PROPERTYATTRIBUTEVALUE =
  'REQUEST_UPDATE_PROPERTYATTRIBUTEVALUE';
export const SUCCESS_UPDATE_PROPERTYATTRIBUTEVALUE =
  'SUCCESS_UPDATE_PROPERTYATTRIBUTEVALUE';
export const ERROR_UPDATE_PROPERTYATTRIBUTEVALUE =
  'ERROR_UPDATE_PROPERTYATTRIBUTEVALUE';
export const RESET_UPDATE_PROPERTYATTRIBUTEVALUE =
  'RESET_UPDATE_PROPERTYATTRIBUTEVALUE';
export const DELETE_UPDATE_PROPERTYATTRIBUTEVALUE =
  'DELETE_UPDATE_PROPERTYATTRIBUTEVALUE';

function requestUpdatePropertyAttributeValue() {
  return {
    type: REQUEST_UPDATE_PROPERTYATTRIBUTEVALUE
  };
}

function receiveUpdatePropertyAttributeValue(propertyAttributeValue) {
  return {
    type: SUCCESS_UPDATE_PROPERTYATTRIBUTEVALUE,
    receivedAt: Date.now(),
    propertyAttributeValue: normalizeDato(propertyAttributeValue)
  };
}

function errorUpdatePropertyAttributeValue(error) {
  return {
    type: ERROR_UPDATE_PROPERTYATTRIBUTEVALUE,
    error: error
  };
}

export function resetUpdatePropertyAttributeValue() {
  return {
    type: RESET_UPDATE_PROPERTYATTRIBUTEVALUE
  };
}

export function updatePropertyAttributeValue(propertyAttributeValue) {
  return {
    type: UPDATE_PROPERTYATTRIBUTEVALUE,
    propertyAttributeValue
  };
}

export function saveUpdatePropertyAttributeValue() {
  return (dispatch, getState) => {
    dispatch(requestUpdatePropertyAttributeValue());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let propertyAttributeValue = denormalizeDato(
      getState().propertiesAttributesValues.update.activo,
      store
    );

    return propertiesAttributesValuesApi
      .saveUpdate(propertyAttributeValue)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdatePropertyAttributeValue(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorUpdatePropertyAttributeValue(
                errorMessages.UNAUTHORIZED_TOKEN
              )
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdatePropertiesAttributesValues(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorUpdatePropertiesAttributesValues(
                    error.responseJSON.message
                  )
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorUpdatePropertyAttributeValue(JSON.parse(error.message))
                  );
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdatePropertyAttributeValue(error.data));
                })
                .catch(() => {
                  dispatch(
                    errorUpdatePropertyAttributeValue(
                      errorMessages.GENERAL_ERROR
                    )
                  );
                });
            return;
        }
      });
  };
}

export function deleteUpdatePropertyAttributeValue(propertyAttributeValue) {
  return {
    type: DELETE_UPDATE_PROPERTYATTRIBUTEVALUE,
    propertyAttributeValue
  };
}

//UPDATE PROPERTIESATTRIBUTESVALUES
export const REQUEST_UPDATE_PROPERTIESATTRIBUTESVALUES =
  'REQUEST_UPDATE_PROPERTIESATTRIBUTESVALUES';
export const SUCCESS_UPDATE_PROPERTIESATTRIBUTESVALUES =
  'SUCCESS_UPDATE_PROPERTIESATTRIBUTESVALUES';
export const ERROR_UPDATE_PROPERTIESATTRIBUTESVALUES =
  'ERROR_UPDATE_PROPERTIESATTRIBUTESVALUES';
export const RESET_UPDATE_PROPERTIESATTRIBUTESVALUES =
  'RESET_UPDATE_PROPERTIESATTRIBUTESVALUES';

function requestUpdatePropertiesAttributesValues() {
  return {
    type: REQUEST_UPDATE_PROPERTIESATTRIBUTESVALUES
  };
}

function receiveUpdatePropertiesAttributesValues(propertiesAttributesValues) {
  return {
    type: SUCCESS_UPDATE_PROPERTIESATTRIBUTESVALUES,
    receivedAt: Date.now(),
    propertiesAttributesValues: normalizeDatos(propertiesAttributesValues)
  };
}

function errorUpdatePropertiesAttributesValues(error) {
  return {
    type: ERROR_UPDATE_PROPERTIESATTRIBUTESVALUES,
    error: error
  };
}

export function resetUpdatePropertiesAttributesValues() {
  return {
    type: RESET_UPDATE_PROPERTIESATTRIBUTESVALUES
  };
}

export function saveUpdatePropertiesAttributesValues() {
  return (dispatch, getState) => {
    dispatch(requestUpdatePropertiesAttributesValues());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let propertiesAttributesValues =
      getState().propertiesAttributesValues.update.activos.map(
        idPropertyAttributeValue => {
          return denormalizeDato(
            getState().propertiesAttributesValues.update.activo[
              idPropertyAttributeValue
            ],
            store
          );
        }
      );

    return propertiesAttributesValuesApi
      .saveUpdatePropertiesAttributesValues(propertiesAttributesValues)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdatePropertiesAttributesValues(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorUpdatePropertiesAttributesValues(
                errorMessages.UNAUTHORIZED_TOKEN
              )
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdatePropertiesAttributesValues(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorUpdatePropertiesAttributesValues(
                    error.responseJSON.message
                  )
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorUpdatePropertiesAttributesValues(
                      JSON.parse(error.message)
                    )
                  );
                })
                .catch(() => {
                  dispatch(
                    errorUpdatePropertiesAttributesValues(
                      errorMessages.GENERAL_ERROR
                    )
                  );
                });

            return;
        }
      });
  };
}

//ALTA PROPERTYATTRIBUTEVALUE
export const CREATE_PROPERTYATTRIBUTEVALUE = 'CREATE_PROPERTYATTRIBUTEVALUE';
export const REQUEST_CREATE_PROPERTYATTRIBUTEVALUE =
  'REQUEST_CREATE_PROPERTYATTRIBUTEVALUE';
export const SUCCESS_CREATE_PROPERTYATTRIBUTEVALUE =
  'SUCCESS_CREATE_PROPERTYATTRIBUTEVALUE';
export const ERROR_CREATE_PROPERTYATTRIBUTEVALUE =
  'ERROR_CREATE_PROPERTYATTRIBUTEVALUE';
export const RESET_CREATE_PROPERTYATTRIBUTEVALUE =
  'RESET_CREATE_PROPERTYATTRIBUTEVALUE';
export const DELETE_CREATE_PROPERTYATTRIBUTEVALUE =
  'DELETE_CREATE_PROPERTYATTRIBUTEVALUE';

//ALTA PROPERTYATTRIBUTEVALUE
function requestCreatePropertyAttributeValue() {
  return {
    type: REQUEST_CREATE_PROPERTYATTRIBUTEVALUE
  };
}

function receiveCreatePropertyAttributeValue(propertyAttributeValue) {
  return {
    type: SUCCESS_CREATE_PROPERTYATTRIBUTEVALUE,
    receivedAt: Date.now(),
    propertyAttributeValue: normalizeDato(propertyAttributeValue)
  };
}

export function errorCreatePropertyAttributeValue(error) {
  return {
    type: ERROR_CREATE_PROPERTYATTRIBUTEVALUE,
    error: error
  };
}

export function resetCreatePropertyAttributeValue() {
  return {
    type: RESET_CREATE_PROPERTYATTRIBUTEVALUE
  };
}

export function createPropertyAttributeValue(propertyAttributeValue) {
  return {
    type: CREATE_PROPERTYATTRIBUTEVALUE,
    propertyAttributeValue
  };
}

export function deleteCreatePropertyAttributeValue(propertyAttributeValue) {
  return {
    type: DELETE_CREATE_PROPERTYATTRIBUTEVALUE,
    propertyAttributeValue
  };
}

export function saveCreatePropertyAttributeValue() {
  return (dispatch, getState) => {
    dispatch(requestCreatePropertyAttributeValue());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let propertyAttributeValue = denormalizeDato(
      getState().propertiesAttributesValues.create.nuevo,
      store
    );

    return propertiesAttributesValuesApi
      .saveCreate(propertyAttributeValue)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreatePropertyAttributeValue(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorCreatePropertyAttributeValue(
                errorMessages.UNAUTHORIZED_TOKEN
              )
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreatePropertiesAttributesValues(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorCreatePropertiesAttributesValues(
                    error.responseJSON.message
                  )
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorCreatePropertyAttributeValue(JSON.parse(error.message))
                  );
                  if (error.data)
                    dispatch(receiveCreatePropertyAttributeValue(error.data));
                })
                .catch(() => {
                  dispatch(
                    errorCreatePropertyAttributeValue(
                      errorMessages.GENERAL_ERROR
                    )
                  );
                });
            return;
        }
      });
  };
}

//CREATE PROPERTIESATTRIBUTESVALUES
export const REQUEST_CREATE_PROPERTIESATTRIBUTESVALUES =
  'REQUEST_CREATE_PROPERTIESATTRIBUTESVALUES';
export const SUCCESS_CREATE_PROPERTIESATTRIBUTESVALUES =
  'SUCCESS_CREATE_PROPERTIESATTRIBUTESVALUES';
export const ERROR_CREATE_PROPERTIESATTRIBUTESVALUES =
  'ERROR_CREATE_PROPERTIESATTRIBUTESVALUES';
export const RESET_CREATE_PROPERTIESATTRIBUTESVALUES =
  'RESET_CREATE_PROPERTIESATTRIBUTESVALUES';

function requestCreatePropertiesAttributesValues() {
  return {
    type: REQUEST_CREATE_PROPERTIESATTRIBUTESVALUES
  };
}

function receiveCreatePropertiesAttributesValues(propertiesAttributesValues) {
  return {
    type: SUCCESS_CREATE_PROPERTIESATTRIBUTESVALUES,
    receivedAt: Date.now(),
    propertiesAttributesValues: normalizeDatos(propertiesAttributesValues)
  };
}

function errorCreatePropertiesAttributesValues(error) {
  return {
    type: ERROR_CREATE_PROPERTIESATTRIBUTESVALUES,
    error: error
  };
}

export function resetCreatePropertiesAttributesValues() {
  return {
    type: RESET_CREATE_PROPERTIESATTRIBUTESVALUES
  };
}

export function saveCreatePropertiesAttributesValues() {
  return (dispatch, getState) => {
    dispatch(requestCreatePropertiesAttributesValues());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let propertiesAttributesValues =
      getState().propertiesAttributesValues.create.nuevos.map(
        idPropertyAttributeValue => {
          return denormalizeDato(
            getState().propertiesAttributesValues.create.nuevo[
              idPropertyAttributeValue
            ],
            store
          );
        }
      );

    return propertiesAttributesValuesApi
      .saveCreatePropertiesAttributesValues(propertiesAttributesValues)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreatePropertiesAttributesValues(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorCreatePropertiesAttributesValues(
                errorMessages.UNAUTHORIZED_TOKEN
              )
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreatePropertiesAttributesValues(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorCreatePropertiesAttributesValues(
                    error.responseJSON.message
                  )
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorCreatePropertiesAttributesValues(
                      JSON.parse(error.message)
                    )
                  );
                })
                .catch(() => {
                  dispatch(
                    errorCreatePropertiesAttributesValues(
                      errorMessages.GENERAL_ERROR
                    )
                  );
                });

            return;
        }
      });
  };
}

//DELETE PROPERTYATTRIBUTEVALUE
export const DELETE_PROPERTYATTRIBUTEVALUE = 'DELETE_PROPERTYATTRIBUTEVALUE';
export const REQUEST_DELETE_PROPERTYATTRIBUTEVALUE =
  'REQUEST_DELETE_PROPERTYATTRIBUTEVALUE';
export const SUCCESS_DELETE_PROPERTYATTRIBUTEVALUE =
  'SUCCESS_DELETE_PROPERTYATTRIBUTEVALUE';
export const ERROR_DELETE_PROPERTYATTRIBUTEVALUE =
  'ERROR_DELETE_PROPERTYATTRIBUTEVALUE';
export const RESET_DELETE_PROPERTYATTRIBUTEVALUE =
  'RESET_DELETE_PROPERTYATTRIBUTEVALUE';

function requestDeletePropertyAttributeValue() {
  return {
    type: REQUEST_DELETE_PROPERTYATTRIBUTEVALUE
  };
}

function receiveDeletePropertyAttributeValue(propertyAttributeValue) {
  return {
    type: SUCCESS_DELETE_PROPERTYATTRIBUTEVALUE,
    receivedAt: Date.now(),
    propertyAttributeValue: normalizeDato(propertyAttributeValue)
  };
}

function errorDeletePropertyAttributeValue(error) {
  return {
    type: ERROR_DELETE_PROPERTYATTRIBUTEVALUE,
    error: error
  };
}

export function resetDeletePropertyAttributeValue(error) {
  return {
    type: RESET_DELETE_PROPERTYATTRIBUTEVALUE,
    error: error
  };
}

export function deletePropertyAttributeValue(propertyAttributeValue) {
  return {
    type: DELETE_PROPERTYATTRIBUTEVALUE,
    propertyAttributeValue
  };
}

export function saveDeletePropertyAttributeValue(propertyAttributeValue) {
  return dispatch => {
    dispatch(requestDeletePropertyAttributeValue());
    return propertiesAttributesValuesApi
      .saveDelete(propertyAttributeValue)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeletePropertyAttributeValue());
        dispatch(receiveDeletePropertyAttributeValue(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorDeletePropertyAttributeValue(
                errorMessages.UNAUTHORIZED_TOKEN
              )
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeletePropertyAttributeValue(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorDeletePropertyAttributeValue(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorDeletePropertyAttributeValue(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorDeletePropertyAttributeValue(
                      errorMessages.GENERAL_ERROR
                    )
                  );
                });
            return;
        }
      });
  };
}

//PRINT PROPERTYATTRIBUTEVALUE
export const PRINT_PROPERTYATTRIBUTEVALUE = 'PRINT_PROPERTYATTRIBUTEVALUE';
export const REQUEST_PRINT_PROPERTYATTRIBUTEVALUE =
  'REQUEST_PRINT_PROPERTYATTRIBUTEVALUE';
export const SUCCESS_PRINT_PROPERTYATTRIBUTEVALUE =
  'SUCCESS_PRINT_PROPERTYATTRIBUTEVALUE';
export const ERROR_PRINT_PROPERTYATTRIBUTEVALUE =
  'ERROR_PRINT_PROPERTYATTRIBUTEVALUE';
export const RESET_PRINT_PROPERTYATTRIBUTEVALUE =
  'RESET_PRINT_PROPERTYATTRIBUTEVALUE';
export const DELETE_PRINT_PROPERTYATTRIBUTEVALUE =
  'DELETE_PRINT_PROPERTYATTRIBUTEVALUE';

function requestPrintPropertyAttributeValue() {
  return {
    type: REQUEST_PRINT_PROPERTYATTRIBUTEVALUE
  };
}

function receivePrintPropertyAttributeValue(turnos) {
  return {
    type: SUCCESS_PRINT_PROPERTYATTRIBUTEVALUE,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintPropertyAttributeValue(error) {
  return {
    type: ERROR_PRINT_PROPERTYATTRIBUTEVALUE,
    error: error
  };
}

export function resetPrintPropertyAttributeValue() {
  return {
    type: RESET_PRINT_PROPERTYATTRIBUTEVALUE
  };
}

export function printPropertyAttributeValue(propertyAttributeValue) {
  return {
    type: PRINT_PROPERTYATTRIBUTEVALUE,
    propertyAttributeValue
  };
}

export function deletePrintPropertyAttributeValue(propertyAttributeValue) {
  return {
    type: DELETE_PRINT_PROPERTYATTRIBUTEVALUE,
    propertyAttributeValue
  };
}

export function savePrintPropertyAttributeValue(idPropertyAttributeValue) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintPropertyAttributeValue());
    return propertiesAttributesValuesApi
      .printPropertyAttributeValue(idPropertyAttributeValue)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintPropertyAttributeValue(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorPrintPropertyAttributeValue(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintPropertyAttributeValue(
                  JSON.parse(error.responseJSON.message)
                )
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorPrintPropertyAttributeValue(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorPrintPropertyAttributeValue(
                      errorMessages.GENERAL_ERROR
                    )
                  );
                });
            return;
        }
      });
  };
}

//PRINT PROPERTIESATTRIBUTESVALUES
export const REQUEST_PRINT_PROPERTIESATTRIBUTESVALUES =
  'REQUEST_PRINT_PROPERTIESATTRIBUTESVALUES';
export const SUCCESS_PRINT_PROPERTIESATTRIBUTESVALUES =
  'SUCCESS_PRINT_PROPERTIESATTRIBUTESVALUES';
export const ERROR_PRINT_PROPERTIESATTRIBUTESVALUES =
  'ERROR_PRINT_PROPERTIESATTRIBUTESVALUES';
export const RESET_PRINT_PROPERTIESATTRIBUTESVALUES =
  'RESET_PRINT_PROPERTIESATTRIBUTESVALUES';

function requestPrintPropertiesAttributesValues() {
  return {
    type: REQUEST_PRINT_PROPERTIESATTRIBUTESVALUES
  };
}

function receivePrintPropertiesAttributesValues(propertiesAttributesValues) {
  return {
    type: SUCCESS_PRINT_PROPERTIESATTRIBUTESVALUES,
    receivedAt: Date.now(),
    propertiesAttributesValues: normalizeDatos(propertiesAttributesValues)
  };
}

function errorPrintPropertiesAttributesValues(error) {
  return {
    type: ERROR_PRINT_PROPERTIESATTRIBUTESVALUES,
    error: error
  };
}

export function resetPrintPropertiesAttributesValues() {
  return {
    type: RESET_PRINT_PROPERTIESATTRIBUTESVALUES
  };
}

export function savePrintPropertiesAttributesValues() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintPropertyAttributeValue());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let propertiesAttributesValues =
      getState().propertiesAttributesValues.print.printers.map(
        idPropertyAttributeValue => {
          return denormalizeDato(
            getState().propertiesAttributesValues.print.print[
              idPropertyAttributeValue
            ],
            store
          );
        }
      );
    return propertiesAttributesValuesApi
      .printPropertiesAttributesValues(propertiesAttributesValues)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintPropertiesAttributesValues(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorPrintPropertiesAttributesValues(
                errorMessages.UNAUTHORIZED_TOKEN
              )
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintPropertiesAttributesValues(
                  JSON.parse(error.responseJSON.message)
                )
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorPrintPropertiesAttributesValues(
                      JSON.parse(error.message)
                    )
                  );
                })
                .catch(() => {
                  dispatch(
                    errorPrintPropertiesAttributesValues(
                      errorMessages.GENERAL_ERROR
                    )
                  );
                });
            return;
        }
      });
  };
}
