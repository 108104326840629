import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
//Components
import ConfigurationPanel from './ConfigurationPanel';
import CustomizationConfiguration from './CustomizationConfiguration';
import SynchronizationConfiguration from './SynchronizationConfiguration';
import WebsiteConfiguration from './WebsiteConfiguration';

export default function Configuration() {
  const dispatch = useDispatch();
  //Effects
  useEffect(() => {
    return function cleanup() {};
  }, []);

  return (
    <Switch>
      <Route
        path="/configuration/account"
        component={CustomizationConfiguration}
      />
      <Route
        path="/configuration/synchronization"
        component={SynchronizationConfiguration}
      />
      <Route path="/configuration/website" component={WebsiteConfiguration} />
      <Route path="/configuration" component={ConfigurationPanel} />
    </Switch>
  );
}
