import {
  INVALIDATE_PROPERTYOPERATIONS,
  ERROR_PROPERTYOPERATIONS,
  RECEIVE_PROPERTYOPERATIONS,
  REQUEST_PROPERTYOPERATIONS,
  RESET_PROPERTYOPERATIONS,
  ERROR_PROPERTYOPERATION,
  RECEIVE_PROPERTYOPERATION,
  REQUEST_PROPERTYOPERATION,
  UPDATE_PROPERTYOPERATION,
  REQUEST_UPDATE_PROPERTYOPERATION,
  SUCCESS_UPDATE_PROPERTYOPERATION,
  ERROR_UPDATE_PROPERTYOPERATION,
  RESET_UPDATE_PROPERTYOPERATION,
  REQUEST_UPDATE_PROPERTYOPERATIONS,
  SUCCESS_UPDATE_PROPERTYOPERATIONS,
  ERROR_UPDATE_PROPERTYOPERATIONS,
  RESET_UPDATE_PROPERTYOPERATIONS,
  CREATE_PROPERTYOPERATION,
  ERROR_CREATE_PROPERTYOPERATION,
  REQUEST_CREATE_PROPERTYOPERATION,
  RESET_CREATE_PROPERTYOPERATION,
  SUCCESS_CREATE_PROPERTYOPERATION,
  REQUEST_CREATE_PROPERTYOPERATIONS,
  SUCCESS_CREATE_PROPERTYOPERATIONS,
  ERROR_CREATE_PROPERTYOPERATIONS,
  RESET_CREATE_PROPERTYOPERATIONS,
  DELETE_PROPERTYOPERATION,
  DELETE_CREATE_PROPERTYOPERATION,
  DELETE_UPDATE_PROPERTYOPERATION,
  REQUEST_DELETE_PROPERTYOPERATION,
  SUCCESS_DELETE_PROPERTYOPERATION,
  ERROR_DELETE_PROPERTYOPERATION,
  RESET_DELETE_PROPERTYOPERATION,
  REQUEST_PRINT_PROPERTYOPERATION,
  SUCCESS_PRINT_PROPERTYOPERATION,
  ERROR_PRINT_PROPERTYOPERATION,
  RESET_PRINT_PROPERTYOPERATION,
  RECEIVE_FILE_PROPERTYOPERATION,
  REQUEST_PRINT_PROPERTYOPERATIONS,
  SUCCESS_PRINT_PROPERTYOPERATIONS,
  ERROR_PRINT_PROPERTYOPERATIONS,
  RESET_PRINT_PROPERTYOPERATIONS,
  RECEIVE_FILE_PROPERTYOPERATIONS,
  PRINT_PROPERTYOPERATION,
  DELETE_PRINT_PROPERTYOPERATION
} from '../actions/PropertyOperationActions';

import {
  RECEIVE_PROPERTY,
  RECEIVE_PROPERTIES,
  SUCCESS_DELETE_PROPERTY,
  SUCCESS_CREATE_PROPERTY,
  SUCCESS_UPDATE_PROPERTY,
  SUCCESS_UPDATE_PROPERTIES
} from '../actions/PropertyActions';
import {
  RECEIVE_TICKET,
  RECEIVE_TICKETS,
  SUCCESS_DELETE_TICKET,
  SUCCESS_CREATE_TICKET,
  SUCCESS_UPDATE_TICKET,
  SUCCESS_UPDATE_TICKETS
} from '../actions/TicketActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    propertyOperations: {},
    files: {}
  };
}

function propertyOperationsById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_PROPERTYOPERATIONS:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_PROPERTYOPERATIONS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_PROPERTYOPERATIONS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_PROPERTYOPERATIONS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        propertyOperations: {}
      });
    case RECEIVE_PROPERTYOPERATIONS:
      let dato = action.propertyOperations.entities.propertyOperations;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        propertyOperations: merge({}, state.propertyOperations, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_PROPERTYOPERATION:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_PROPERTYOPERATION:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_PROPERTYOPERATION:
      let datoPropertyOperation =
        action.propertyOperation.entities.propertyOperations;
      return Object.assign({}, state, {
        propertyOperations: merge(
          {},
          state.propertyOperations,
          datoPropertyOperation
        ),
        isFetching: false
      });
    case RECEIVE_FILE_PROPERTYOPERATION:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_PROPERTYOPERATION:
      let datoPropertyOperationEliminado =
        action.propertyOperation.entities.propertyOperations;
      return Object.assign({}, state, {
        propertyOperations: mergeWith(
          clone(datoPropertyOperationEliminado),
          state.propertyOperations,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_PROPERTYOPERATION:
      let datoPropertyOperationCreado =
        action.propertyOperation.entities.propertyOperations;
      return Object.assign({}, state, {
        propertyOperations: mergeWith(
          clone(datoPropertyOperationCreado),
          state.propertyOperations,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_PROPERTYOPERATIONS:
      let datosPropertyOperationCreado =
        action.propertyOperations.entities.propertyOperations;
      return Object.assign({}, state, {
        propertyOperations: mergeWith(
          clone(datosPropertyOperationCreado),
          state.propertyOperations,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTYOPERATION:
      let datoPropertyOperationActualizado =
        action.propertyOperation.entities.propertyOperations;
      return Object.assign({}, state, {
        propertyOperations: mergeWith(
          clone(datoPropertyOperationActualizado),
          state.propertyOperations,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTYOPERATIONS:
      let datosPropertyOperationActualizado =
        action.propertyOperations.entities.propertyOperations;
      return Object.assign({}, state, {
        propertyOperations: mergeWith(
          clone(datosPropertyOperationActualizado),
          state.propertyOperations,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    //PROPERTY
    case RECEIVE_PROPERTY:
      let property =
        action.property.entities && action.property.entities.propertyOperations
          ? action.property.entities.propertyOperations
          : {};
      return Object.assign({}, state, {
        propertyOperations: merge({}, state.propertyOperations, property)
      });
    case RECEIVE_PROPERTIES:
      let properties =
        action.properties.entities &&
        action.properties.entities.propertyOperations
          ? action.properties.entities.propertyOperations
          : {};
      return Object.assign({}, state, {
        propertyOperations: merge({}, state.propertyOperations, properties)
      });
    case SUCCESS_DELETE_PROPERTY:
      let datopropertyEliminado =
        action.property.entities && action.property.entities.propertyOperations
          ? action.property.entities.propertyOperations
          : {};
      return Object.assign({}, state, {
        propertyOperations: mergeWith(
          clone(datopropertyEliminado),
          pickBy(state.propertyOperations, function (propertyOperation) {
            return propertyOperation.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_PROPERTY:
      let datopropertyCreado =
        action.property.entities && action.property.entities.propertyOperations
          ? action.property.entities.propertyOperations
          : {};
      return Object.assign({}, state, {
        propertyOperations: mergeWith(
          clone(datopropertyCreado),
          pickBy(state.propertyOperations, function (propertyOperation) {
            return propertyOperation.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTY:
      let datopropertyActualizado =
        action.property.entities && action.property.entities.propertyOperations
          ? action.property.entities.propertyOperations
          : {};
      return Object.assign({}, state, {
        propertyOperations: mergeWith(
          clone(datopropertyActualizado),
          pickBy(state.propertyOperations, function (propertyOperation) {
            return propertyOperation.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTIES:
      let datospropertyActualizado =
        action.properties.entities &&
        action.properties.entities.propertyOperations
          ? action.properties.entities.propertyOperations
          : {};
      return Object.assign({}, state, {
        propertyOperations: mergeWith(
          clone(datospropertyActualizado),
          state.propertyOperations,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    //TICKET
    case RECEIVE_TICKET:
      let ticket =
        action.ticket.entities && action.ticket.entities.propertyOperations
          ? action.ticket.entities.propertyOperations
          : {};
      return Object.assign({}, state, {
        propertyOperations: merge({}, state.propertyOperations, ticket)
      });
    case RECEIVE_TICKETS:
      let tickets =
        action.tickets.entities && action.tickets.entities.propertyOperations
          ? action.tickets.entities.propertyOperations
          : {};
      return Object.assign({}, state, {
        propertyOperations: merge({}, state.propertyOperations, tickets)
      });
    case SUCCESS_DELETE_TICKET:
      let datoticketEliminado =
        action.ticket.entities && action.ticket.entities.propertyOperations
          ? action.ticket.entities.propertyOperations
          : {};
      return Object.assign({}, state, {
        propertyOperations: mergeWith(
          clone(datoticketEliminado),
          pickBy(state.propertyOperations, function (propertyOperation) {
            return propertyOperation.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_TICKET:
      let datoticketCreado =
        action.ticket.entities && action.ticket.entities.propertyOperations
          ? action.ticket.entities.propertyOperations
          : {};
      return Object.assign({}, state, {
        propertyOperations: mergeWith(
          clone(datoticketCreado),
          pickBy(state.propertyOperations, function (propertyOperation) {
            return propertyOperation.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TICKET:
      let datoticketActualizado =
        action.ticket.entities && action.ticket.entities.propertyOperations
          ? action.ticket.entities.propertyOperations
          : {};
      return Object.assign({}, state, {
        propertyOperations: mergeWith(
          clone(datoticketActualizado),
          pickBy(state.propertyOperations, function (propertyOperation) {
            return propertyOperation.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TICKETS:
      let datosticketActualizado =
        action.tickets.entities && action.tickets.entities.propertyOperations
          ? action.tickets.entities.propertyOperations
          : {};
      return Object.assign({}, state, {
        propertyOperations: mergeWith(
          clone(datosticketActualizado),
          state.propertyOperations,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        propertyOperations: {}
      });
    default:
      return state;
  }
}

function allPropertyOperations(state = [], action) {
  switch (action.type) {
    case RECEIVE_PROPERTYOPERATIONS:
      return action.propertyOperations.result &&
        action.propertyOperations.result.propertyOperations
        ? union(action.propertyOperations.result.propertyOperations, state)
        : action.propertyOperations.result
        ? action.propertyOperations.result
        : state;
    case RECEIVE_PROPERTYOPERATION:
      return action.propertyOperation.result
        ? union([action.propertyOperation.result], state)
        : state;

    case SUCCESS_CREATE_PROPERTYOPERATION:
      let datoPropertyOperationSCreate =
        action.propertyOperation.entities.propertyOperations;
      let idNuevoSCreate = null;
      if (Object.values(datoPropertyOperationSCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoPropertyOperationSCreate)[0] &&
          Object.values(datoPropertyOperationSCreate)[0].id
            ? Object.values(datoPropertyOperationSCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_PROPERTYOPERATIONS:
      let propertyOperationsCreate =
        action.propertyOperations.entities &&
        action.propertyOperations.entities.propertyOperations
          ? action.propertyOperations.entities.propertyOperations
          : null;
      return propertyOperationsCreate
        ? union(
            state,
            Object.values(propertyOperationsCreate).map(propertyOperations => {
              return propertyOperations.id;
            })
          )
        : state;
    case RESET_PROPERTYOPERATIONS:
      return [];

    case RECEIVE_PROPERTY:
      let property =
        action.property.entities && action.property.entities.propertyOperations
          ? action.property.entities.propertyOperations
          : null;
      return property
        ? union(
            state,
            Object.values(property).map(property => {
              return property.id;
            })
          )
        : state;
    case RECEIVE_PROPERTIES:
      let properties =
        action.properties.entities &&
        action.properties.entities.propertyOperations
          ? action.properties.entities.propertyOperations
          : null;
      return properties
        ? union(
            state,
            Object.values(properties).map(properties => {
              return properties.id;
            })
          )
        : state;

    case SUCCESS_DELETE_PROPERTY:
      let propertyDelete =
        action.property.entities && action.property.entities.propertyOperations
          ? action.property.entities.propertyOperations
          : null;
      return propertyDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyDelete).map(property => {
              return property.id;
            })
          )
        : state;
    case SUCCESS_CREATE_PROPERTY:
      let propertyCreate =
        action.property.entities && action.property.entities.propertyOperations
          ? action.property.entities.propertyOperations
          : null;
      return propertyCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyCreate).map(property => {
              return property.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_PROPERTY:
      let propertyUpdate =
        action.property.entities && action.property.entities.propertyOperations
          ? action.property.entities.propertyOperations
          : null;
      return propertyUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyUpdate).map(property => {
              return property.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_PROPERTIES:
      let propertiesUpdate =
        action.properties.entities &&
        action.properties.entities.propertyOperations
          ? action.properties.entities.propertyOperations
          : null;
      return propertiesUpdate
        ? union(
            state,
            Object.values(propertiesUpdate).map(properties => {
              return properties.id;
            })
          )
        : state;
    case RECEIVE_TICKET:
      let ticket =
        action.ticket.entities && action.ticket.entities.propertyOperations
          ? action.ticket.entities.propertyOperations
          : null;
      return ticket
        ? union(
            state,
            Object.values(ticket).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case RECEIVE_TICKETS:
      let tickets =
        action.tickets.entities && action.tickets.entities.propertyOperations
          ? action.tickets.entities.propertyOperations
          : null;
      return tickets
        ? union(
            state,
            Object.values(tickets).map(tickets => {
              return tickets.id;
            })
          )
        : state;

    case SUCCESS_DELETE_TICKET:
      let ticketDelete =
        action.ticket.entities && action.ticket.entities.propertyOperations
          ? action.ticket.entities.propertyOperations
          : null;
      return ticketDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(ticketDelete).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case SUCCESS_CREATE_TICKET:
      let ticketCreate =
        action.ticket.entities && action.ticket.entities.propertyOperations
          ? action.ticket.entities.propertyOperations
          : null;
      return ticketCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(ticketCreate).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_TICKET:
      let ticketUpdate =
        action.ticket.entities && action.ticket.entities.propertyOperations
          ? action.ticket.entities.propertyOperations
          : null;
      return ticketUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(ticketUpdate).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_TICKETS:
      let ticketsUpdate =
        action.tickets.entities && action.tickets.entities.propertyOperations
          ? action.tickets.entities.propertyOperations
          : null;
      return ticketsUpdate
        ? union(
            state,
            Object.values(ticketsUpdate).map(tickets => {
              return tickets.id;
            })
          )
        : state;

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalPropertyOperations(state = null, action) {
  switch (action.type) {
    case RECEIVE_PROPERTYOPERATIONS:
      return action.propertyOperations && action.propertyOperations.result.total
        ? action.propertyOperations.result.total
        : 0;
    case RESET_PROPERTYOPERATIONS:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_PROPERTYOPERATION:
      let dato = action.propertyOperation.entities.propertyOperations;
      let propertyOperation =
        dato && Object.keys(dato).length > 0
          ? dato[action.propertyOperation.result]
          : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: propertyOperation ? propertyOperation : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_PROPERTYOPERATION:
      let idsUpdate = [];
      Object.values(action.propertyOperation).map(propertyOperationUpdate => {
        if (propertyOperationUpdate && propertyOperationUpdate.id)
          idsUpdate.push(propertyOperationUpdate.id);
      });
      return merge({}, state, {
        activo: action.propertyOperation,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_PROPERTYOPERATION:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_PROPERTYOPERATION:
      let datoPropertyOperationActualizado =
        action.propertyOperation.entities.propertyOperations;
      let propertyOperationNuevo =
        datoPropertyOperationActualizado &&
        Object.keys(datoPropertyOperationActualizado).length > 0
          ? datoPropertyOperationActualizado[action.propertyOperation.result]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: propertyOperationNuevo
      });
    case ERROR_UPDATE_PROPERTYOPERATION:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_PROPERTYOPERATIONS:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_PROPERTYOPERATIONS:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_PROPERTYOPERATIONS:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_PROPERTYOPERATION:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    //PROPERTY
    //TODO ver si esta bien
    case SUCCESS_CREATE_PROPERTY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_PROPERTY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      }); //TICKET
    //TODO ver si esta bien
    case SUCCESS_CREATE_TICKET:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_TICKET:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_TICKET:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_TICKETS:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });

    case DELETE_PROPERTYOPERATION:
      let datoPropertyOperationDelete = action.propertyOperation;
      let idsDelete = [];
      Object.values(action.propertyOperation).map(propertyOperationDelete => {
        if (propertyOperationDelete && propertyOperationDelete.id)
          idsDelete.push(propertyOperationDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoPropertyOperationDelete)
          ),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_PROPERTYOPERATION:
      let datoPropertyOperationDeleteUpdate = action.propertyOperation;
      let idsDeleteUpdate = [];
      Object.values(action.propertyOperation).map(propertyOperationDelete => {
        if (propertyOperationDelete && propertyOperationDelete.id)
          idsDeleteUpdate.push(propertyOperationDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoPropertyOperationDeleteUpdate)
          ),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_PROPERTYOPERATION:
      let datoPropertyOperationDeleted = {};
      if (
        Object.values(action.propertyOperation.entities.propertyOperations)
          .length > 0
      )
        datoPropertyOperationDeleted = Object.values(
          action.propertyOperation.entities.propertyOperations
        )[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoPropertyOperationDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_PROPERTYOPERATION:
      let idsCreate = [];
      Object.values(action.propertyOperation).map(propertyOperationCreate => {
        if (propertyOperationCreate && propertyOperationCreate.id)
          idsCreate.push(propertyOperationCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.propertyOperation,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_PROPERTYOPERATION:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_PROPERTYOPERATION:
      let datoPropertyOperationNuevo =
        action.propertyOperation.entities.propertyOperations;
      let propertyOperationNuevo =
        datoPropertyOperationNuevo &&
        Object.keys(datoPropertyOperationNuevo).length > 0
          ? datoPropertyOperationNuevo[action.propertyOperation.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: propertyOperationNuevo,
        nuevos: []
      });
    case ERROR_CREATE_PROPERTYOPERATION:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_PROPERTYOPERATIONS:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_PROPERTYOPERATIONS:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_PROPERTYOPERATIONS:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_PROPERTYOPERATION:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    //PROPERTY
    case SUCCESS_CREATE_PROPERTY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_PROPERTY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    //TICKET
    case SUCCESS_CREATE_TICKET:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_TICKET:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_TICKET:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_TICKETS:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });

    case DELETE_PROPERTYOPERATION:
      let datoPropertyOperationDelete = action.propertyOperation;
      let idsDelete = [];
      Object.values(action.propertyOperation).map(propertyOperationDelete => {
        if (propertyOperationDelete && propertyOperationDelete.id)
          idsDelete.push(propertyOperationDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoPropertyOperationDelete)
          ),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_PROPERTYOPERATION:
      let datoPropertyOperationDeleteCreate = action.propertyOperation;
      let idsDeleteCreate = [];
      Object.values(action.propertyOperation).map(propertyOperationDelete => {
        if (propertyOperationDelete && propertyOperationDelete.id)
          idsDeleteCreate.push(propertyOperationDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoPropertyOperationDeleteCreate)
          ),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_PROPERTYOPERATION:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.propertyOperation,
        error: null
      });
    case REQUEST_DELETE_PROPERTYOPERATION:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_PROPERTYOPERATION:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_PROPERTYOPERATION:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_PROPERTYOPERATION:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    //PROPERTY
    case SUCCESS_CREATE_PROPERTY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_PROPERTY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        eliminado: {}
      }); //TICKET
    case SUCCESS_CREATE_TICKET:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_TICKET:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_TICKET:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_TICKETS:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_PROPERTYOPERATION:
      let idsCreate = [];
      Object.values(action.propertyOperation).map(propertyOperationCreate => {
        if (propertyOperationCreate && propertyOperationCreate.id)
          idsCreate.push(propertyOperationCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.propertyOperation,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_PROPERTYOPERATION:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_PROPERTYOPERATION:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_PROPERTYOPERATION:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_PROPERTYOPERATIONS:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_PROPERTYOPERATIONS:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_PROPERTYOPERATIONS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_PROPERTYOPERATION:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_PROPERTYOPERATION:
      let datoPropertyOperationDeleteCreate = action.propertyOperation;
      let idsDeleteCreate = [];
      Object.values(action.propertyOperation).map(propertyOperationDelete => {
        if (propertyOperationDelete && propertyOperationDelete.id)
          idsDeleteCreate.push(propertyOperationDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(
            clone(state.print),
            Object.keys(datoPropertyOperationDeleteCreate)
          ),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const propertyOperations = combineReducers({
  byId: propertyOperationsById,
  allIds: allPropertyOperations,
  update: update,
  create: create,
  totalPropertyOperations: totalPropertyOperations,
  delete: deleter,
  print: print
});

export default propertyOperations;
