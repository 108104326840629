import React from 'react';
import { ButtonGroup } from '@material-ui/core';
import clone from 'lodash/clone';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { updatePropertyClosedOperation } from '../../actions/PropertyClosedOperationActions';
import TicketClosedOperationModal from './TicketClosedOperationModal';

export default function AddPropertyClosedOperation(props) {
  const { disabled } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [idPropertyClosedOperation, setIdPropertyClosedOperation] =
    React.useState(null);
  const [type, setType] = React.useState(null);

  const propertyClosedOperations = useSelector(
    state => state.propertyClosedOperations
  );

  const handleClickOpenModalPropertyClosedOperation = (
    type,
    idPropertyClosedOperation_
  ) => {
    if (!idPropertyClosedOperation_) {
      setIdPropertyClosedOperation(Date.now() + Math.random() + '-');
      setType(type);
    } else {
      setIdPropertyClosedOperation(idPropertyClosedOperation_);
      if (
        propertyClosedOperations.update.activos.indexOf(
          idPropertyClosedOperation_
        ) === -1
      ) {
        let attributeUpdate = clone(
          propertyClosedOperations.byId.propertyClosedOperations[
            idPropertyClosedOperation_
          ]
        );
        let cambio = {};
        cambio[idPropertyClosedOperation_] = attributeUpdate;
        dispatch(updatePropertyClosedOperation(cambio));
      }
    }
    setOpen(true);
  };

  return (
    <>
      <ButtonGroup variant="contained">
        <Button
          disabled={disabled}
          onClick={() => handleClickOpenModalPropertyClosedOperation('sold')}
        >
          Operación exitosa
        </Button>
        <Button
          disabled={disabled}
          onClick={() =>
            handleClickOpenModalPropertyClosedOperation('not-sold')
          }
        >
          Cerrado
        </Button>
      </ButtonGroup>
      <TicketClosedOperationModal
        open={open}
        setOpen={setOpen}
        type={type}
        idPropertyClosedOperation={idPropertyClosedOperation}
      />
    </>
  );
}
