import React from 'react';
import {
  Box,
  Breadcrumbs,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Typography
} from '@material-ui/core';
import Custom from '../../assets/img/icon/web-development.png';
import ExternalSystem from '../../assets/img/icon/externalSystem.svg';
import Website from '../../assets/img/icon/website.png';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router';

const menus = [
  {
    title: 'Personalización',
    icon: Custom,
    description:
      'Configura tu logotipo, información de contacto, ubicación y preferencias generales.',
    url: '/configuration/account'
  },
  {
    title: 'Sincronización',
    icon: ExternalSystem,
    description: 'Configura los datos de sincronización para sistema externo.',
    url: '/configuration/synchronization'
  },
  {
    title: 'Mi sitio web',
    icon: Website,
    description: 'Configura los datos de tu sitio web.',
    url: '/configuration/website'
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiCardMedia-img': {
      objectFit: 'contain',
      padding: theme.spacing(2),
      paddingBottom: '0px'
    },
    marginTop: theme.spacing(4)
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    overflowX: 'auto',
    minHeight: '113px',
    paddingTop: '0px',
    width: '280px',
    cursor: 'pointer'
  }
}));

function ConfigurationPanel() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Container maxWidth={'lg'}>
      <Breadcrumbs aria-label="breadcrumb" separator={'‣'}>
        <Link color="inherit" to="/">
          Home
        </Link>
        <Link color="inherit" to="/properties">
          Configuración
        </Link>
      </Breadcrumbs>
      <Typography component={'h1'} variant={'h1'}>
        {'Configuración'}
      </Typography>
      <Box display={'flex'} gridColumnGap={'15px'}>
        {menus.map(menu => {
          return (
            <Card
              className={classes.root}
              onClick={() => history.push(menu.url)}
            >
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt={menu.title}
                  height="140"
                  image={menu.icon}
                  title={menu.title}
                />
                <CardContent className={classes.menuItem}>
                  <Typography component={'h2'} variant={'subtitle1'}>
                    {menu.title}
                  </Typography>
                  <Typography component={'h2'} variant={'body2'}>
                    {menu.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          );
        })}
      </Box>
    </Container>
  );
}

export default ConfigurationPanel;
