import concat from 'lodash/concat';
import {
  Chip,
  FormControl,
  FormGroup,
  FormLabel,
  InputAdornment,
  TextField,
  useTheme
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/Search';
import Box from '@material-ui/core/Box';

export default function LocationFilter(props) {
  const theme = useTheme();

  const { query, setQuery } = props;
  const countries = useSelector(state => state.countries);
  const neighborhoods = useSelector(state => state.neighborhoods);
  const states = useSelector(state => state.states);
  const locations = useSelector(state => state.locations);

  const { city, country, state, neighborhood } = query;
  let options = [];
  if (countries && countries.allIds) {
    let neig = Object.values(neighborhoods.byId.neighborhoods);
    neig.sort(function (a, b) {
      if (a.group > b.group) {
        return 1;
      }
      if (a.group < b.group) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    options = concat(
      options,
      Object.values(countries.byId.countries),
      Object.values(states.byId.states),
      Object.values(locations.byId.locations),
      neig
    );
  }

  const handleSelectLocation = (e, locations) => {
    locations.forEach(location => {
      if (location && location.id) {
        let { checked, _query } = isChecked(location.id, location.type);
        if (!checked) _query.push(location.id.toString());
        else _query.splice(_query.indexOf(location.id.toString()), 1);
        let newquery = Object.assign([], query);
        if (_query.length > 0) newquery[location.type] = _query;
        else delete newquery[location.type];
        setQuery(newquery);
      }
    });
  };

  const handleDeleteLocation = location => {
    if (location && location.id) {
      let { checked, _query } = isChecked(location.id, location.type);
      if (!checked) _query.push(location.id.toString());
      else _query.splice(_query.indexOf(location.id.toString()), 1);
      let newquery = Object.assign([], query);
      if (_query.length > 0) newquery[location.type] = _query;
      else delete newquery[location.type];
      setQuery(newquery);
    }
  };

  const isChecked = (idLocation, type) => {
    let varType = [];
    switch (type) {
      case 'city':
        varType = city ? city : [];
        break;
      case 'country':
        varType = country ? country : [];
        break;
      case 'neighborhood':
        varType = neighborhood ? neighborhood : [];
        break;
      case 'state':
        varType = state ? state : [];
        break;
    }
    if (!varType) varType = [];
    if (typeof varType === 'string') {
      varType = [varType];
    }
    return {
      checked: varType.indexOf(idLocation.toString()) !== -1,
      _query: Object.assign([], varType)
    };
  };

  const getChips = (value, getTagProps) => {
    let chips = [];
    let names = {
      locations: locations,
      countries: countries,
      neighborhoods: neighborhoods,
      states: states
    };
    [city, country, neighborhood, state].forEach((value, i) => {
      let arr = value;
      if (typeof value === 'string') arr = [value];
      arr &&
        arr.forEach((idLocation, index) => {
          let key = Object.keys(names)[i];
          console.log(key, Object.keys(names));
          let location = names[key].byId[key][idLocation];
          if (location)
            chips.push(
              <Chip
                key={'cit' + location.id}
                variant="filled"
                label={location.description}
                color={'primary'}
                size={'medium'}
                onDelete={() => handleDeleteLocation(location)}
              />
            );
        });
    });
    return chips;
  };
  let Selection = getChips();

  return (
    <FormControl component="fieldset" variant="standard">
      <FormLabel component="legend" className={'formLabel'}>
        Ubicación
      </FormLabel>
      <FormGroup>
        <Autocomplete
          multiple
          options={options}
          groupBy={option => option.group}
          getOptionLabel={option => {
            return option.description;
          }}
          noOptionsText={'No hay coincidencias'}
          disableClearable={true}
          value={Object.assign([], city, neighborhood, country, state)}
          onChange={handleSelectLocation}
          renderTags={getChips}
          sx={{ width: 300 }}
          renderInput={params => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
              sx={{
                maxWidth: '320px',
                '& .MuiInputAdornment-root': {
                  display: 'flex'
                },
                '& .MuiInputBase-root ': {
                  borderRadius: '13px',
                  // backgroundColor: theme.custom.inputSearch[theme.palette.mode],
                  minHeight: 'inherit',
                  '& fieldset': {
                    // border: "none"
                  },
                  '& .MuiInputBase-input': {
                    height: 'inherit',
                    padding: '12px'
                  }
                }
              }}
            />
          )}
        />
        {Selection && Selection.length > 0 && (
          <>
            <Box textAlign={'center'}>
              <FormLabel component="legend" className={'formLabel'}>
                Tu selección
              </FormLabel>
            </Box>
            <Box
              display={'flex'}
              flexWrap={'wrap'}
              columnGap={'10px'}
              rowGap={'10px'}
              maxWidth={'300px'}
            >
              {Selection}
            </Box>
          </>
        )}
      </FormGroup>
    </FormControl>
  );
}
