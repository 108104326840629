//api
import currenciesApi from '../api/currenciesApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizeCurrencies';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//CURRENCIES
export const REQUEST_CURRENCIES = 'REQUEST_CURRENCIES';
export const RECEIVE_CURRENCIES = 'RECEIVE_CURRENCIES';
export const INVALIDATE_CURRENCIES = 'INVALIDATE_CURRENCIES';
export const ERROR_CURRENCIES = 'ERROR_CURRENCIES';
export const RESET_CURRENCIES = 'RESET_CURRENCIES';

export function invalidateCurrencies() {
  return {
    type: INVALIDATE_CURRENCIES
  };
}

function requestCurrencies() {
  return {
    type: REQUEST_CURRENCIES
  };
}

function receiveCurrencies(json) {
  return {
    type: RECEIVE_CURRENCIES,
    currencies: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorCurrencies(error) {
  return {
    type: ERROR_CURRENCIES,
    error: error
  };
}

export function resetCurrencies() {
  return {
    type: RESET_CURRENCIES
  };
}

export function fetchCurrencies(filtros) {
  return dispatch => {
    dispatch(requestCurrencies());
    return currenciesApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveCurrencies(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorCurrencies(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorCurrencies(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

function shouldFetchCurrencies(state) {
  const currencies = state.currencies.byId;
  if (!currencies) {
    return true;
  } else if (currencies.isFetching) {
    return false;
  } else {
    return currencies.didInvalidate;
  }
}

export function fetchCurrenciesIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchCurrencies(getState())) {
      return dispatch(fetchCurrencies(filtros));
    }
  };
}

//MODEL
export const REQUEST_CURRENCY = 'REQUEST_CURRENCY';
export const RECEIVE_CURRENCY = 'RECEIVE_CURRENCY';
export const INVALIDATE_CURRENCY = 'INVALIDATE_CURRENCY';
export const ERROR_CURRENCY = 'ERROR_CURRENCY';
export const RESET_CURRENCY = 'RESET_CURRENCY';

export function invalidateCurrency() {
  return {
    type: INVALIDATE_CURRENCY
  };
}

function requestCurrency() {
  return {
    type: REQUEST_CURRENCY
  };
}

export function receiveCurrency(json) {
  return {
    type: RECEIVE_CURRENCY,
    currency: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorCurrency(error) {
  return {
    type: ERROR_CURRENCY,
    error: error
  };
}

export function fetchCurrency(idCurrency) {
  return dispatch => {
    dispatch(requestCurrency());
    return currenciesApi
      .getOne(idCurrency)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveCurrency(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(errorCurrency(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorCurrency(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_CURRENCY = 'RECEIVE_FILE_CURRENCY';

function receiveFileCurrency(file) {
  return {
    type: RECEIVE_FILE_CURRENCY,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFileCurrency(idCurrency, filtros) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return currenciesApi
      .getFile(idCurrency, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFileCurrency(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorCurrency(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorCurrency(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_CURRENCY = 'UPDATE_CURRENCY';
export const REQUEST_UPDATE_CURRENCY = 'REQUEST_UPDATE_CURRENCY';
export const SUCCESS_UPDATE_CURRENCY = 'SUCCESS_UPDATE_CURRENCY';
export const ERROR_UPDATE_CURRENCY = 'ERROR_UPDATE_CURRENCY';
export const RESET_UPDATE_CURRENCY = 'RESET_UPDATE_CURRENCY';
export const DELETE_UPDATE_CURRENCY = 'DELETE_UPDATE_CURRENCY';

function requestUpdateCurrency() {
  return {
    type: REQUEST_UPDATE_CURRENCY
  };
}

function receiveUpdateCurrency(currency) {
  return {
    type: SUCCESS_UPDATE_CURRENCY,
    receivedAt: Date.now(),
    currency: normalizeDato(currency)
  };
}

function errorUpdateCurrency(error) {
  return {
    type: ERROR_UPDATE_CURRENCY,
    error: error
  };
}

export function resetUpdateCurrency() {
  return {
    type: RESET_UPDATE_CURRENCY
  };
}

export function updateCurrency(currency) {
  return {
    type: UPDATE_CURRENCY,
    currency
  };
}

export function saveUpdateCurrency() {
  return (dispatch, getState) => {
    dispatch(requestUpdateCurrency());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let currency = denormalizeDato(getState().currencies.update.activo, store);

    return currenciesApi
      .saveUpdate(currency)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateCurrency(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateCurrency(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateCurrencies(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdateCurrencies(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateCurrency(JSON.parse(error.message)));
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdateCurrency(error.data));
                })
                .catch(() => {
                  dispatch(errorUpdateCurrency(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

export function deleteUpdateCurrency(currency) {
  return {
    type: DELETE_UPDATE_CURRENCY,
    currency
  };
}

//UPDATE CURRENCIES
export const REQUEST_UPDATE_CURRENCIES = 'REQUEST_UPDATE_CURRENCIES';
export const SUCCESS_UPDATE_CURRENCIES = 'SUCCESS_UPDATE_CURRENCIES';
export const ERROR_UPDATE_CURRENCIES = 'ERROR_UPDATE_CURRENCIES';
export const RESET_UPDATE_CURRENCIES = 'RESET_UPDATE_CURRENCIES';

function requestUpdateCurrencies() {
  return {
    type: REQUEST_UPDATE_CURRENCIES
  };
}

function receiveUpdateCurrencies(currencies) {
  return {
    type: SUCCESS_UPDATE_CURRENCIES,
    receivedAt: Date.now(),
    currencies: normalizeDatos(currencies)
  };
}

function errorUpdateCurrencies(error) {
  return {
    type: ERROR_UPDATE_CURRENCIES,
    error: error
  };
}

export function resetUpdateCurrencies() {
  return {
    type: RESET_UPDATE_CURRENCIES
  };
}

export function saveUpdateCurrencies() {
  return (dispatch, getState) => {
    dispatch(requestUpdateCurrencies());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let currencies = getState().currencies.update.activos.map(idCurrency => {
      return denormalizeDato(
        getState().currencies.update.activo[idCurrency],
        store
      );
    });

    return currenciesApi
      .saveUpdateCurrencies(currencies)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateCurrencies(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateCurrencies(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateCurrencies(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdateCurrencies(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateCurrencies(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorUpdateCurrencies(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//ALTA CURRENCY
export const CREATE_CURRENCY = 'CREATE_CURRENCY';
export const REQUEST_CREATE_CURRENCY = 'REQUEST_CREATE_CURRENCY';
export const SUCCESS_CREATE_CURRENCY = 'SUCCESS_CREATE_CURRENCY';
export const ERROR_CREATE_CURRENCY = 'ERROR_CREATE_CURRENCY';
export const RESET_CREATE_CURRENCY = 'RESET_CREATE_CURRENCY';
export const DELETE_CREATE_CURRENCY = 'DELETE_CREATE_CURRENCY';

//ALTA CURRENCY
function requestCreateCurrency() {
  return {
    type: REQUEST_CREATE_CURRENCY
  };
}

function receiveCreateCurrency(currency) {
  return {
    type: SUCCESS_CREATE_CURRENCY,
    receivedAt: Date.now(),
    currency: normalizeDato(currency)
  };
}

export function errorCreateCurrency(error) {
  return {
    type: ERROR_CREATE_CURRENCY,
    error: error
  };
}

export function resetCreateCurrency() {
  return {
    type: RESET_CREATE_CURRENCY
  };
}

export function createCurrency(currency) {
  return {
    type: CREATE_CURRENCY,
    currency
  };
}

export function deleteCreateCurrency(currency) {
  return {
    type: DELETE_CREATE_CURRENCY,
    currency
  };
}

export function saveCreateCurrency() {
  return (dispatch, getState) => {
    dispatch(requestCreateCurrency());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let currency = denormalizeDato(getState().currencies.create.nuevo, store);

    return currenciesApi
      .saveCreate(currency)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateCurrency(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateCurrency(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateCurrencies(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreateCurrencies(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateCurrency(JSON.parse(error.message)));
                  if (error.data) dispatch(receiveCreateCurrency(error.data));
                })
                .catch(() => {
                  dispatch(errorCreateCurrency(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//CREATE CURRENCIES
export const REQUEST_CREATE_CURRENCIES = 'REQUEST_CREATE_CURRENCIES';
export const SUCCESS_CREATE_CURRENCIES = 'SUCCESS_CREATE_CURRENCIES';
export const ERROR_CREATE_CURRENCIES = 'ERROR_CREATE_CURRENCIES';
export const RESET_CREATE_CURRENCIES = 'RESET_CREATE_CURRENCIES';

function requestCreateCurrencies() {
  return {
    type: REQUEST_CREATE_CURRENCIES
  };
}

function receiveCreateCurrencies(currencies) {
  return {
    type: SUCCESS_CREATE_CURRENCIES,
    receivedAt: Date.now(),
    currencies: normalizeDatos(currencies)
  };
}

function errorCreateCurrencies(error) {
  return {
    type: ERROR_CREATE_CURRENCIES,
    error: error
  };
}

export function resetCreateCurrencies() {
  return {
    type: RESET_CREATE_CURRENCIES
  };
}

export function saveCreateCurrencies() {
  return (dispatch, getState) => {
    dispatch(requestCreateCurrencies());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let currencies = getState().currencies.create.nuevos.map(idCurrency => {
      return denormalizeDato(
        getState().currencies.create.nuevo[idCurrency],
        store
      );
    });

    return currenciesApi
      .saveCreateCurrencies(currencies)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateCurrencies(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateCurrencies(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateCurrencies(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreateCurrencies(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateCurrencies(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorCreateCurrencies(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//DELETE CURRENCY
export const DELETE_CURRENCY = 'DELETE_CURRENCY';
export const REQUEST_DELETE_CURRENCY = 'REQUEST_DELETE_CURRENCY';
export const SUCCESS_DELETE_CURRENCY = 'SUCCESS_DELETE_CURRENCY';
export const ERROR_DELETE_CURRENCY = 'ERROR_DELETE_CURRENCY';
export const RESET_DELETE_CURRENCY = 'RESET_DELETE_CURRENCY';

function requestDeleteCurrency() {
  return {
    type: REQUEST_DELETE_CURRENCY
  };
}

function receiveDeleteCurrency(currency) {
  return {
    type: SUCCESS_DELETE_CURRENCY,
    receivedAt: Date.now(),
    currency: normalizeDato(currency)
  };
}

function errorDeleteCurrency(error) {
  return {
    type: ERROR_DELETE_CURRENCY,
    error: error
  };
}

export function resetDeleteCurrency(error) {
  return {
    type: RESET_DELETE_CURRENCY,
    error: error
  };
}

export function deleteCurrency(currency) {
  return {
    type: DELETE_CURRENCY,
    currency
  };
}

export function saveDeleteCurrency(currency) {
  return dispatch => {
    dispatch(requestDeleteCurrency());
    return currenciesApi
      .saveDelete(currency)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeleteCurrency());
        dispatch(receiveDeleteCurrency(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorDeleteCurrency(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeleteCurrency(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorDeleteCurrency(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorDeleteCurrency(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorDeleteCurrency(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT CURRENCY
export const PRINT_CURRENCY = 'PRINT_CURRENCY';
export const REQUEST_PRINT_CURRENCY = 'REQUEST_PRINT_CURRENCY';
export const SUCCESS_PRINT_CURRENCY = 'SUCCESS_PRINT_CURRENCY';
export const ERROR_PRINT_CURRENCY = 'ERROR_PRINT_CURRENCY';
export const RESET_PRINT_CURRENCY = 'RESET_PRINT_CURRENCY';
export const DELETE_PRINT_CURRENCY = 'DELETE_PRINT_CURRENCY';

function requestPrintCurrency() {
  return {
    type: REQUEST_PRINT_CURRENCY
  };
}

function receivePrintCurrency(turnos) {
  return {
    type: SUCCESS_PRINT_CURRENCY,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintCurrency(error) {
  return {
    type: ERROR_PRINT_CURRENCY,
    error: error
  };
}

export function resetPrintCurrency() {
  return {
    type: RESET_PRINT_CURRENCY
  };
}

export function printCurrency(currency) {
  return {
    type: PRINT_CURRENCY,
    currency
  };
}

export function deletePrintCurrency(currency) {
  return {
    type: DELETE_PRINT_CURRENCY,
    currency
  };
}

export function savePrintCurrency(idCurrency) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintCurrency());
    return currenciesApi
      .printCurrency(idCurrency)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintCurrency(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintCurrency(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintCurrency(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintCurrency(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintCurrency(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT CURRENCIES
export const REQUEST_PRINT_CURRENCIES = 'REQUEST_PRINT_CURRENCIES';
export const SUCCESS_PRINT_CURRENCIES = 'SUCCESS_PRINT_CURRENCIES';
export const ERROR_PRINT_CURRENCIES = 'ERROR_PRINT_CURRENCIES';
export const RESET_PRINT_CURRENCIES = 'RESET_PRINT_CURRENCIES';

function requestPrintCurrencies() {
  return {
    type: REQUEST_PRINT_CURRENCIES
  };
}

function receivePrintCurrencies(currencies) {
  return {
    type: SUCCESS_PRINT_CURRENCIES,
    receivedAt: Date.now(),
    currencies: normalizeDatos(currencies)
  };
}

function errorPrintCurrencies(error) {
  return {
    type: ERROR_PRINT_CURRENCIES,
    error: error
  };
}

export function resetPrintCurrencies() {
  return {
    type: RESET_PRINT_CURRENCIES
  };
}

export function savePrintCurrencies() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintCurrency());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let currencies = getState().currencies.print.printers.map(idCurrency => {
      return denormalizeDato(
        getState().currencies.print.print[idCurrency],
        store
      );
    });
    return currenciesApi
      .printCurrencies(currencies)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintCurrencies(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintCurrencies(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintCurrencies(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintCurrencies(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintCurrencies(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}
