//api
import externalSystemsApi from '../api/externalSystemsApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizeExternalSystems';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//EXTERNALSYSTEMS
export const REQUEST_EXTERNALSYSTEMS = 'REQUEST_EXTERNALSYSTEMS';
export const RECEIVE_EXTERNALSYSTEMS = 'RECEIVE_EXTERNALSYSTEMS';
export const INVALIDATE_EXTERNALSYSTEMS = 'INVALIDATE_EXTERNALSYSTEMS';
export const ERROR_EXTERNALSYSTEMS = 'ERROR_EXTERNALSYSTEMS';
export const RESET_EXTERNALSYSTEMS = 'RESET_EXTERNALSYSTEMS';

export function invalidateExternalSystems() {
  return {
    type: INVALIDATE_EXTERNALSYSTEMS
  };
}

function requestExternalSystems() {
  return {
    type: REQUEST_EXTERNALSYSTEMS
  };
}

function receiveExternalSystems(json) {
  return {
    type: RECEIVE_EXTERNALSYSTEMS,
    externalSystems: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorExternalSystems(error) {
  return {
    type: ERROR_EXTERNALSYSTEMS,
    error: error
  };
}

export function resetExternalSystems() {
  return {
    type: RESET_EXTERNALSYSTEMS
  };
}

export function fetchExternalSystems(filtros) {
  return dispatch => {
    dispatch(requestExternalSystems());
    return externalSystemsApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveExternalSystems(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorExternalSystems(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorExternalSystems(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

function shouldFetchExternalSystems(state) {
  const externalSystems = state.externalSystems.byId;
  if (!externalSystems) {
    return true;
  } else if (externalSystems.isFetching) {
    return false;
  } else {
    return externalSystems.didInvalidate;
  }
}

export function fetchExternalSystemsIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchExternalSystems(getState())) {
      return dispatch(fetchExternalSystems(filtros));
    }
  };
}

//MODEL
export const REQUEST_EXTERNALSYSTEM = 'REQUEST_EXTERNALSYSTEM';
export const RECEIVE_EXTERNALSYSTEM = 'RECEIVE_EXTERNALSYSTEM';
export const INVALIDATE_EXTERNALSYSTEM = 'INVALIDATE_EXTERNALSYSTEM';
export const ERROR_EXTERNALSYSTEM = 'ERROR_EXTERNALSYSTEM';
export const RESET_EXTERNALSYSTEM = 'RESET_EXTERNALSYSTEM';

export function invalidateExternalSystem() {
  return {
    type: INVALIDATE_EXTERNALSYSTEM
  };
}

function requestExternalSystem() {
  return {
    type: REQUEST_EXTERNALSYSTEM
  };
}

export function receiveExternalSystem(json) {
  return {
    type: RECEIVE_EXTERNALSYSTEM,
    externalSystem: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorExternalSystem(error) {
  return {
    type: ERROR_EXTERNALSYSTEM,
    error: error
  };
}

export function fetchExternalSystem(idExternalSystem) {
  return dispatch => {
    dispatch(requestExternalSystem());
    return externalSystemsApi
      .getOne(idExternalSystem)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveExternalSystem(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(errorExternalSystem(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorExternalSystem(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_EXTERNALSYSTEM = 'RECEIVE_FILE_EXTERNALSYSTEM';

function receiveFileExternalSystem(file) {
  return {
    type: RECEIVE_FILE_EXTERNALSYSTEM,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFileExternalSystem(idExternalSystem, filtros) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return externalSystemsApi
      .getFile(idExternalSystem, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFileExternalSystem(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorExternalSystem(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorExternalSystem(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_EXTERNALSYSTEM = 'UPDATE_EXTERNALSYSTEM';
export const REQUEST_UPDATE_EXTERNALSYSTEM = 'REQUEST_UPDATE_EXTERNALSYSTEM';
export const SUCCESS_UPDATE_EXTERNALSYSTEM = 'SUCCESS_UPDATE_EXTERNALSYSTEM';
export const ERROR_UPDATE_EXTERNALSYSTEM = 'ERROR_UPDATE_EXTERNALSYSTEM';
export const RESET_UPDATE_EXTERNALSYSTEM = 'RESET_UPDATE_EXTERNALSYSTEM';
export const DELETE_UPDATE_EXTERNALSYSTEM = 'DELETE_UPDATE_EXTERNALSYSTEM';

function requestUpdateExternalSystem() {
  return {
    type: REQUEST_UPDATE_EXTERNALSYSTEM
  };
}

function receiveUpdateExternalSystem(externalSystem) {
  return {
    type: SUCCESS_UPDATE_EXTERNALSYSTEM,
    receivedAt: Date.now(),
    externalSystem: normalizeDato(externalSystem)
  };
}

function errorUpdateExternalSystem(error) {
  return {
    type: ERROR_UPDATE_EXTERNALSYSTEM,
    error: error
  };
}

export function resetUpdateExternalSystem() {
  return {
    type: RESET_UPDATE_EXTERNALSYSTEM
  };
}

export function updateExternalSystem(externalSystem) {
  return {
    type: UPDATE_EXTERNALSYSTEM,
    externalSystem
  };
}

export function saveUpdateExternalSystem() {
  return (dispatch, getState) => {
    dispatch(requestUpdateExternalSystem());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let externalSystem = denormalizeDato(
      getState().externalSystems.update.activo,
      store
    );

    return externalSystemsApi
      .saveUpdate(externalSystem)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateExternalSystem(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorUpdateExternalSystem(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateExternalSystems(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorUpdateExternalSystems(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorUpdateExternalSystem(JSON.parse(error.message))
                  );
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdateExternalSystem(error.data));
                })
                .catch(() => {
                  dispatch(
                    errorUpdateExternalSystem(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

export function deleteUpdateExternalSystem(externalSystem) {
  return {
    type: DELETE_UPDATE_EXTERNALSYSTEM,
    externalSystem
  };
}

//UPDATE EXTERNALSYSTEMS
export const REQUEST_UPDATE_EXTERNALSYSTEMS = 'REQUEST_UPDATE_EXTERNALSYSTEMS';
export const SUCCESS_UPDATE_EXTERNALSYSTEMS = 'SUCCESS_UPDATE_EXTERNALSYSTEMS';
export const ERROR_UPDATE_EXTERNALSYSTEMS = 'ERROR_UPDATE_EXTERNALSYSTEMS';
export const RESET_UPDATE_EXTERNALSYSTEMS = 'RESET_UPDATE_EXTERNALSYSTEMS';

function requestUpdateExternalSystems() {
  return {
    type: REQUEST_UPDATE_EXTERNALSYSTEMS
  };
}

function receiveUpdateExternalSystems(externalSystems) {
  return {
    type: SUCCESS_UPDATE_EXTERNALSYSTEMS,
    receivedAt: Date.now(),
    externalSystems: normalizeDatos(externalSystems)
  };
}

function errorUpdateExternalSystems(error) {
  return {
    type: ERROR_UPDATE_EXTERNALSYSTEMS,
    error: error
  };
}

export function resetUpdateExternalSystems() {
  return {
    type: RESET_UPDATE_EXTERNALSYSTEMS
  };
}

export function saveUpdateExternalSystems() {
  return (dispatch, getState) => {
    dispatch(requestUpdateExternalSystems());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let externalSystems = getState().externalSystems.update.activos.map(
      idExternalSystem => {
        return denormalizeDato(
          getState().externalSystems.update.activo[idExternalSystem],
          store
        );
      }
    );

    return externalSystemsApi
      .saveUpdateExternalSystems(externalSystems)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateExternalSystems(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorUpdateExternalSystems(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateExternalSystems(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorUpdateExternalSystems(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorUpdateExternalSystems(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorUpdateExternalSystems(errorMessages.GENERAL_ERROR)
                  );
                });

            return;
        }
      });
  };
}

//ALTA EXTERNALSYSTEM
export const CREATE_EXTERNALSYSTEM = 'CREATE_EXTERNALSYSTEM';
export const REQUEST_CREATE_EXTERNALSYSTEM = 'REQUEST_CREATE_EXTERNALSYSTEM';
export const SUCCESS_CREATE_EXTERNALSYSTEM = 'SUCCESS_CREATE_EXTERNALSYSTEM';
export const ERROR_CREATE_EXTERNALSYSTEM = 'ERROR_CREATE_EXTERNALSYSTEM';
export const RESET_CREATE_EXTERNALSYSTEM = 'RESET_CREATE_EXTERNALSYSTEM';
export const DELETE_CREATE_EXTERNALSYSTEM = 'DELETE_CREATE_EXTERNALSYSTEM';

//ALTA EXTERNALSYSTEM
function requestCreateExternalSystem() {
  return {
    type: REQUEST_CREATE_EXTERNALSYSTEM
  };
}

function receiveCreateExternalSystem(externalSystem) {
  return {
    type: SUCCESS_CREATE_EXTERNALSYSTEM,
    receivedAt: Date.now(),
    externalSystem: normalizeDato(externalSystem)
  };
}

export function errorCreateExternalSystem(error) {
  return {
    type: ERROR_CREATE_EXTERNALSYSTEM,
    error: error
  };
}

export function resetCreateExternalSystem() {
  return {
    type: RESET_CREATE_EXTERNALSYSTEM
  };
}

export function createExternalSystem(externalSystem) {
  return {
    type: CREATE_EXTERNALSYSTEM,
    externalSystem
  };
}

export function deleteCreateExternalSystem(externalSystem) {
  return {
    type: DELETE_CREATE_EXTERNALSYSTEM,
    externalSystem
  };
}

export function saveCreateExternalSystem() {
  return (dispatch, getState) => {
    dispatch(requestCreateExternalSystem());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let externalSystem = denormalizeDato(
      getState().externalSystems.create.nuevo,
      store
    );

    return externalSystemsApi
      .saveCreate(externalSystem)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateExternalSystem(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorCreateExternalSystem(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateExternalSystems(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorCreateExternalSystems(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorCreateExternalSystem(JSON.parse(error.message))
                  );
                  if (error.data)
                    dispatch(receiveCreateExternalSystem(error.data));
                })
                .catch(() => {
                  dispatch(
                    errorCreateExternalSystem(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//CREATE EXTERNALSYSTEMS
export const REQUEST_CREATE_EXTERNALSYSTEMS = 'REQUEST_CREATE_EXTERNALSYSTEMS';
export const SUCCESS_CREATE_EXTERNALSYSTEMS = 'SUCCESS_CREATE_EXTERNALSYSTEMS';
export const ERROR_CREATE_EXTERNALSYSTEMS = 'ERROR_CREATE_EXTERNALSYSTEMS';
export const RESET_CREATE_EXTERNALSYSTEMS = 'RESET_CREATE_EXTERNALSYSTEMS';

function requestCreateExternalSystems() {
  return {
    type: REQUEST_CREATE_EXTERNALSYSTEMS
  };
}

function receiveCreateExternalSystems(externalSystems) {
  return {
    type: SUCCESS_CREATE_EXTERNALSYSTEMS,
    receivedAt: Date.now(),
    externalSystems: normalizeDatos(externalSystems)
  };
}

function errorCreateExternalSystems(error) {
  return {
    type: ERROR_CREATE_EXTERNALSYSTEMS,
    error: error
  };
}

export function resetCreateExternalSystems() {
  return {
    type: RESET_CREATE_EXTERNALSYSTEMS
  };
}

export function saveCreateExternalSystems() {
  return (dispatch, getState) => {
    dispatch(requestCreateExternalSystems());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let externalSystems = getState().externalSystems.create.nuevos.map(
      idExternalSystem => {
        return denormalizeDato(
          getState().externalSystems.create.nuevo[idExternalSystem],
          store
        );
      }
    );

    return externalSystemsApi
      .saveCreateExternalSystems(externalSystems)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateExternalSystems(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorCreateExternalSystems(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateExternalSystems(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorCreateExternalSystems(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorCreateExternalSystems(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorCreateExternalSystems(errorMessages.GENERAL_ERROR)
                  );
                });

            return;
        }
      });
  };
}

//DELETE EXTERNALSYSTEM
export const DELETE_EXTERNALSYSTEM = 'DELETE_EXTERNALSYSTEM';
export const REQUEST_DELETE_EXTERNALSYSTEM = 'REQUEST_DELETE_EXTERNALSYSTEM';
export const SUCCESS_DELETE_EXTERNALSYSTEM = 'SUCCESS_DELETE_EXTERNALSYSTEM';
export const ERROR_DELETE_EXTERNALSYSTEM = 'ERROR_DELETE_EXTERNALSYSTEM';
export const RESET_DELETE_EXTERNALSYSTEM = 'RESET_DELETE_EXTERNALSYSTEM';

function requestDeleteExternalSystem() {
  return {
    type: REQUEST_DELETE_EXTERNALSYSTEM
  };
}

function receiveDeleteExternalSystem(externalSystem) {
  return {
    type: SUCCESS_DELETE_EXTERNALSYSTEM,
    receivedAt: Date.now(),
    externalSystem: normalizeDato(externalSystem)
  };
}

function errorDeleteExternalSystem(error) {
  return {
    type: ERROR_DELETE_EXTERNALSYSTEM,
    error: error
  };
}

export function resetDeleteExternalSystem(error) {
  return {
    type: RESET_DELETE_EXTERNALSYSTEM,
    error: error
  };
}

export function deleteExternalSystem(externalSystem) {
  return {
    type: DELETE_EXTERNALSYSTEM,
    externalSystem
  };
}

export function saveDeleteExternalSystem(externalSystem) {
  return dispatch => {
    dispatch(requestDeleteExternalSystem());
    return externalSystemsApi
      .saveDelete(externalSystem)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeleteExternalSystem());
        dispatch(receiveDeleteExternalSystem(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorDeleteExternalSystem(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeleteExternalSystem(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorDeleteExternalSystem(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorDeleteExternalSystem(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorDeleteExternalSystem(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//PRINT EXTERNALSYSTEM
export const PRINT_EXTERNALSYSTEM = 'PRINT_EXTERNALSYSTEM';
export const REQUEST_PRINT_EXTERNALSYSTEM = 'REQUEST_PRINT_EXTERNALSYSTEM';
export const SUCCESS_PRINT_EXTERNALSYSTEM = 'SUCCESS_PRINT_EXTERNALSYSTEM';
export const ERROR_PRINT_EXTERNALSYSTEM = 'ERROR_PRINT_EXTERNALSYSTEM';
export const RESET_PRINT_EXTERNALSYSTEM = 'RESET_PRINT_EXTERNALSYSTEM';
export const DELETE_PRINT_EXTERNALSYSTEM = 'DELETE_PRINT_EXTERNALSYSTEM';

function requestPrintExternalSystem() {
  return {
    type: REQUEST_PRINT_EXTERNALSYSTEM
  };
}

function receivePrintExternalSystem(turnos) {
  return {
    type: SUCCESS_PRINT_EXTERNALSYSTEM,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintExternalSystem(error) {
  return {
    type: ERROR_PRINT_EXTERNALSYSTEM,
    error: error
  };
}

export function resetPrintExternalSystem() {
  return {
    type: RESET_PRINT_EXTERNALSYSTEM
  };
}

export function printExternalSystem(externalSystem) {
  return {
    type: PRINT_EXTERNALSYSTEM,
    externalSystem
  };
}

export function deletePrintExternalSystem(externalSystem) {
  return {
    type: DELETE_PRINT_EXTERNALSYSTEM,
    externalSystem
  };
}

export function savePrintExternalSystem(idExternalSystem) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintExternalSystem());
    return externalSystemsApi
      .printExternalSystem(idExternalSystem)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintExternalSystem(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorPrintExternalSystem(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintExternalSystem(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintExternalSystem(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(
                    errorPrintExternalSystem(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//PRINT EXTERNALSYSTEMS
export const REQUEST_PRINT_EXTERNALSYSTEMS = 'REQUEST_PRINT_EXTERNALSYSTEMS';
export const SUCCESS_PRINT_EXTERNALSYSTEMS = 'SUCCESS_PRINT_EXTERNALSYSTEMS';
export const ERROR_PRINT_EXTERNALSYSTEMS = 'ERROR_PRINT_EXTERNALSYSTEMS';
export const RESET_PRINT_EXTERNALSYSTEMS = 'RESET_PRINT_EXTERNALSYSTEMS';

function requestPrintExternalSystems() {
  return {
    type: REQUEST_PRINT_EXTERNALSYSTEMS
  };
}

function receivePrintExternalSystems(externalSystems) {
  return {
    type: SUCCESS_PRINT_EXTERNALSYSTEMS,
    receivedAt: Date.now(),
    externalSystems: normalizeDatos(externalSystems)
  };
}

function errorPrintExternalSystems(error) {
  return {
    type: ERROR_PRINT_EXTERNALSYSTEMS,
    error: error
  };
}

export function resetPrintExternalSystems() {
  return {
    type: RESET_PRINT_EXTERNALSYSTEMS
  };
}

export function savePrintExternalSystems() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintExternalSystem());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let externalSystems = getState().externalSystems.print.printers.map(
      idExternalSystem => {
        return denormalizeDato(
          getState().externalSystems.print.print[idExternalSystem],
          store
        );
      }
    );
    return externalSystemsApi
      .printExternalSystems(externalSystems)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintExternalSystems(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorPrintExternalSystems(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintExternalSystems(
                  JSON.parse(error.responseJSON.message)
                )
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorPrintExternalSystems(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorPrintExternalSystems(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}
