import { useDispatch, useSelector } from 'react-redux';
import React, { useRef, useState } from 'react';
import clone from 'lodash/clone';
import c from '../../constants/Constants';
import { fetchProperties, updateProperty } from '../../actions/PropertyActions';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { CircularProgress, TextField } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import PropertyItemList from './PropertyItemList';

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: option => option.address
});

const useStyles = makeStyles(theme => ({
  selector: {
    border: 'none',
    minWidth: '160px',
    marginRight: '10px',
    '& .MuiInput-underline:before': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:hover': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none'
    }
  },
  avatarProperty: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1)
  }
}));

export default function PropertyAutocomplete(props) {
  const classes = useStyles();
  const {
    changeModel,
    property_id,
    margin = 'normal',
    attribute_id,
    attribute_rel,
    description,
    disabled = false,
    add_option = false,
    validar,
    validador,
    clearModel
  } = props;
  const dispatch = useDispatch();
  const properties = useSelector(state => state.properties);
  let user =
    property_id && properties.byId.properties[property_id]
      ? properties.byId.properties[property_id]
      : {};
  if (properties.update.activos.indexOf(property_id) !== -1) {
    user = properties.update.activo[property_id];
  }
  if (properties.create.nuevos.indexOf(property_id) !== -1) {
    user = properties.create.nuevo[property_id];
  }

  const [idProperty, setIdProperty] = useState(null);
  const [desProperty, setProperty] = useState('');
  const handleSelectProperty = (e, value, reason) => {
    let idPropertySelect = null;
    let solicitanteSelect = '';
    if (value && value.id) {
      idPropertySelect = value.id;
      solicitanteSelect = value.address;
      if (properties.update.activos.indexOf(idPropertySelect) === -1) {
        let ubicacionUpdate = clone(
          properties.byId.properties[idPropertySelect]
        );
        let cambio = {};
        cambio[idPropertySelect] = ubicacionUpdate;
        dispatch(updateProperty(cambio));
      }
    } else if (value && value.name && value.name.indexOf('Agregar') !== -1) {
      idPropertySelect = -1;
      solicitanteSelect = 'Nueva propiedad';
    } else if (reason === 'clear') {
      idPropertySelect = null;
      solicitanteSelect = '';
    }

    let cambio = {};
    cambio[attribute_id] = idPropertySelect;
    cambio[attribute_rel] = idPropertySelect;
    changeModel(cambio);
    setIdProperty(idPropertySelect);
    setProperty(solicitanteSelect);
    if (idPropertySelect === null && clearModel) {
      clearModel();
    }
  };

  //Property
  const timer = useRef();

  function handleChangePropertyInput(e, value, reason) {
    if (value && reason === 'input' && value.length > 2) {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => triggerChangeProperty(value), 300);
    }
    setProperty(value);
  }

  const triggerChangeProperty = searchPhase => {
    dispatch(
      fetchProperties({
        searchPhase: searchPhase,
        with: ['mainAsset', 'propertyType', 'propertyOperation']
      })
    );
  };

  return (
    <>
      <Autocomplete
        size="small"
        onChange={(event, newValue, reason) => {
          handleSelectProperty(null, newValue, reason);
        }}
        filterOptions={(options, params) => {
          if (params.inputValue !== '') {
            const filtered = filterOptions(options, params);
            // Suggest the creation of a new value

            if (params && params.inputValue !== '' && add_option) {
              filtered.push({
                inputValue: params.inputValue,
                address: `Agregar nueva propiedad "${params.inputValue}"`
              });
            }

            return filtered;
          } else return [];
        }}
        noOptionsText={'Escriba para buscar'}
        loadingText={'Buscando...'}
        selectOnFocus
        // clearOnBlur
        handleHomeEndKeys
        id={attribute_id}
        options={properties.allIds.map(option => {
          let user = properties.byId.properties[option];
          return user ? user : '';
        })}
        getOptionLabel={option => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.address;
        }}
        // renderOption={(option) => option.nombre + ' ' + option.apellido + ' ' + option.email}
        disabled={false}
        renderOption={option => {
          return <PropertyItemList property={option} />;
        }}
        fullWidth={false}
        style={{ width: '100%' }}
        // freeSolo
        loading={properties.byId.isFetching}
        onInputChange={handleChangePropertyInput}
        inputValue={desProperty ? desProperty : ''}
        renderInput={params => (
          <TextField
            disabled={disabled}
            {...params}
            label={description}
            // variant="outlined"
            margin={margin}
            className={classes.selector}
            onBlur={validar ? validar : null}
            error={validador ? !validador.isValidById(attribute_id) : null}
            helperText={
              validador ? validador.getHelperTextById(attribute_id) : null
            }
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {properties.byId.isFetching ? (
                    <CircularProgress color="primary" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
              startAdornment: (
                <Avatar
                  className={classes.avatarProperty}
                  src={user?.path ? `${c.API_HOST}${user.path}` : ''}
                />
              ),
              autoComplete: 'off'
            }}
          />
        )}
      />
    </>
  );
}
