import {
  INVALIDATE_TENANTUSERACCESSES,
  ERROR_TENANTUSERACCESSES,
  RECEIVE_TENANTUSERACCESSES,
  REQUEST_TENANTUSERACCESSES,
  RESET_TENANTUSERACCESSES,
  ERROR_TENANTUSERACCESS,
  RECEIVE_TENANTUSERACCESS,
  REQUEST_TENANTUSERACCESS,
  UPDATE_TENANTUSERACCESS,
  REQUEST_UPDATE_TENANTUSERACCESS,
  SUCCESS_UPDATE_TENANTUSERACCESS,
  ERROR_UPDATE_TENANTUSERACCESS,
  RESET_UPDATE_TENANTUSERACCESS,
  REQUEST_UPDATE_TENANTUSERACCESSES,
  SUCCESS_UPDATE_TENANTUSERACCESSES,
  ERROR_UPDATE_TENANTUSERACCESSES,
  RESET_UPDATE_TENANTUSERACCESSES,
  CREATE_TENANTUSERACCESS,
  ERROR_CREATE_TENANTUSERACCESS,
  REQUEST_CREATE_TENANTUSERACCESS,
  RESET_CREATE_TENANTUSERACCESS,
  SUCCESS_CREATE_TENANTUSERACCESS,
  REQUEST_CREATE_TENANTUSERACCESSES,
  SUCCESS_CREATE_TENANTUSERACCESSES,
  ERROR_CREATE_TENANTUSERACCESSES,
  RESET_CREATE_TENANTUSERACCESSES,
  DELETE_TENANTUSERACCESS,
  DELETE_CREATE_TENANTUSERACCESS,
  DELETE_UPDATE_TENANTUSERACCESS,
  REQUEST_DELETE_TENANTUSERACCESS,
  SUCCESS_DELETE_TENANTUSERACCESS,
  ERROR_DELETE_TENANTUSERACCESS,
  RESET_DELETE_TENANTUSERACCESS,
  REQUEST_PRINT_TENANTUSERACCESS,
  SUCCESS_PRINT_TENANTUSERACCESS,
  ERROR_PRINT_TENANTUSERACCESS,
  RESET_PRINT_TENANTUSERACCESS,
  RECEIVE_FILE_TENANTUSERACCESS,
  REQUEST_PRINT_TENANTUSERACCESSES,
  SUCCESS_PRINT_TENANTUSERACCESSES,
  ERROR_PRINT_TENANTUSERACCESSES,
  RESET_PRINT_TENANTUSERACCESSES,
  RECEIVE_FILE_TENANTUSERACCESSES,
  PRINT_TENANTUSERACCESS,
  DELETE_PRINT_TENANTUSERACCESS
} from '../actions/TenantUserAccessActions';

import {
  RECEIVE_TENANT,
  RECEIVE_TENANTS,
  SUCCESS_DELETE_TENANT,
  SUCCESS_CREATE_TENANT,
  SUCCESS_UPDATE_TENANT,
  SUCCESS_UPDATE_TENANTS
} from '../actions/TenantActions';
import {
  RECEIVE_USER,
  RECEIVE_USERS,
  SUCCESS_DELETE_USER,
  SUCCESS_CREATE_USER,
  SUCCESS_UPDATE_USER,
  SUCCESS_UPDATE_USERS
} from '../actions/UserActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    tenantUserAccesses: {},
    files: {}
  };
}

function tenantUserAccessesById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_TENANTUSERACCESSES:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_TENANTUSERACCESSES:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_TENANTUSERACCESSES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_TENANTUSERACCESSES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        tenantUserAccesses: {}
      });
    case RECEIVE_TENANTUSERACCESSES:
      let dato = action.tenantUserAccesses.entities.tenantUserAccesses;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        tenantUserAccesses: merge({}, state.tenantUserAccesses, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_TENANTUSERACCESS:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_TENANTUSERACCESS:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_TENANTUSERACCESS:
      let datoTenantUserAccess =
        action.tenantUserAccess.entities.tenantUserAccesses;
      return Object.assign({}, state, {
        tenantUserAccesses: merge(
          {},
          state.tenantUserAccesses,
          datoTenantUserAccess
        ),
        isFetching: false
      });
    case RECEIVE_FILE_TENANTUSERACCESS:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_TENANTUSERACCESS:
      let datoTenantUserAccessEliminado =
        action.tenantUserAccess.entities.tenantUserAccesses;
      return Object.assign({}, state, {
        tenantUserAccesses: mergeWith(
          clone(datoTenantUserAccessEliminado),
          state.tenantUserAccesses,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_TENANTUSERACCESS:
      let datoTenantUserAccessCreado =
        action.tenantUserAccess.entities.tenantUserAccesses;
      return Object.assign({}, state, {
        tenantUserAccesses: mergeWith(
          clone(datoTenantUserAccessCreado),
          state.tenantUserAccesses,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_TENANTUSERACCESSES:
      let datosTenantUserAccessCreado =
        action.tenantUserAccesses.entities.tenantUserAccesses;
      return Object.assign({}, state, {
        tenantUserAccesses: mergeWith(
          clone(datosTenantUserAccessCreado),
          state.tenantUserAccesses,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TENANTUSERACCESS:
      let datoTenantUserAccessActualizado =
        action.tenantUserAccess.entities.tenantUserAccesses;
      return Object.assign({}, state, {
        tenantUserAccesses: mergeWith(
          clone(datoTenantUserAccessActualizado),
          state.tenantUserAccesses,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TENANTUSERACCESSES:
      let datosTenantUserAccessActualizado =
        action.tenantUserAccesses.entities.tenantUserAccesses;
      return Object.assign({}, state, {
        tenantUserAccesses: mergeWith(
          clone(datosTenantUserAccessActualizado),
          state.tenantUserAccesses,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    //TENANT
    case RECEIVE_TENANT:
      let tenant =
        action.tenant.entities && action.tenant.entities.tenantUserAccesses
          ? action.tenant.entities.tenantUserAccesses
          : {};
      return Object.assign({}, state, {
        tenantUserAccesses: merge({}, state.tenantUserAccesses, tenant)
      });
    case RECEIVE_TENANTS:
      let tenants =
        action.tenants.entities && action.tenants.entities.tenantUserAccesses
          ? action.tenants.entities.tenantUserAccesses
          : {};
      return Object.assign({}, state, {
        tenantUserAccesses: merge({}, state.tenantUserAccesses, tenants)
      });
    case SUCCESS_DELETE_TENANT:
      let datotenantEliminado =
        action.tenant.entities && action.tenant.entities.tenantUserAccesses
          ? action.tenant.entities.tenantUserAccesses
          : {};
      return Object.assign({}, state, {
        tenantUserAccesses: mergeWith(
          clone(datotenantEliminado),
          pickBy(state.tenantUserAccesses, function (tenantUserAccess) {
            return tenantUserAccess.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_TENANT:
      let datotenantCreado =
        action.tenant.entities && action.tenant.entities.tenantUserAccesses
          ? action.tenant.entities.tenantUserAccesses
          : {};
      return Object.assign({}, state, {
        tenantUserAccesses: mergeWith(
          clone(datotenantCreado),
          pickBy(state.tenantUserAccesses, function (tenantUserAccess) {
            return tenantUserAccess.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TENANT:
      let datotenantActualizado =
        action.tenant.entities && action.tenant.entities.tenantUserAccesses
          ? action.tenant.entities.tenantUserAccesses
          : {};
      return Object.assign({}, state, {
        tenantUserAccesses: mergeWith(
          clone(datotenantActualizado),
          pickBy(state.tenantUserAccesses, function (tenantUserAccess) {
            return tenantUserAccess.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TENANTS:
      let datostenantActualizado =
        action.tenants.entities && action.tenants.entities.tenantUserAccesses
          ? action.tenants.entities.tenantUserAccesses
          : {};
      return Object.assign({}, state, {
        tenantUserAccesses: mergeWith(
          clone(datostenantActualizado),
          state.tenantUserAccesses,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    //USER
    case RECEIVE_USER:
      let user =
        action.user.entities && action.user.entities.tenantUserAccesses
          ? action.user.entities.tenantUserAccesses
          : {};
      return Object.assign({}, state, {
        tenantUserAccesses: merge({}, state.tenantUserAccesses, user)
      });
    case RECEIVE_USERS:
      let users =
        action.users.entities && action.users.entities.tenantUserAccesses
          ? action.users.entities.tenantUserAccesses
          : {};
      return Object.assign({}, state, {
        tenantUserAccesses: merge({}, state.tenantUserAccesses, users)
      });
    case SUCCESS_DELETE_USER:
      let datouserEliminado =
        action.user.entities && action.user.entities.tenantUserAccesses
          ? action.user.entities.tenantUserAccesses
          : {};
      return Object.assign({}, state, {
        tenantUserAccesses: mergeWith(
          clone(datouserEliminado),
          pickBy(state.tenantUserAccesses, function (tenantUserAccess) {
            return tenantUserAccess.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_USER:
      let datouserCreado =
        action.user.entities && action.user.entities.tenantUserAccesses
          ? action.user.entities.tenantUserAccesses
          : {};
      return Object.assign({}, state, {
        tenantUserAccesses: mergeWith(
          clone(datouserCreado),
          pickBy(state.tenantUserAccesses, function (tenantUserAccess) {
            return tenantUserAccess.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_USER:
      let datouserActualizado =
        action.user.entities && action.user.entities.tenantUserAccesses
          ? action.user.entities.tenantUserAccesses
          : {};
      return Object.assign({}, state, {
        tenantUserAccesses: mergeWith(
          clone(datouserActualizado),
          pickBy(state.tenantUserAccesses, function (tenantUserAccess) {
            return tenantUserAccess.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_USERS:
      let datosuserActualizado =
        action.users.entities && action.users.entities.tenantUserAccesses
          ? action.users.entities.tenantUserAccesses
          : {};
      return Object.assign({}, state, {
        tenantUserAccesses: mergeWith(
          clone(datosuserActualizado),
          state.tenantUserAccesses,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        tenantUserAccesses: {}
      });
    default:
      return state;
  }
}

function allTenantUserAccesses(state = [], action) {
  switch (action.type) {
    case RECEIVE_TENANTUSERACCESSES:
      return action.tenantUserAccesses.result &&
        action.tenantUserAccesses.result.tenantUserAccesses
        ? union(action.tenantUserAccesses.result.tenantUserAccesses, state)
        : action.tenantUserAccesses.result
        ? action.tenantUserAccesses.result
        : state;
    case RECEIVE_TENANTUSERACCESS:
      return action.tenantUserAccess.result
        ? union([action.tenantUserAccess.result], state)
        : state;

    case SUCCESS_CREATE_TENANTUSERACCESS:
      let datoTenantUserAccessSCreate =
        action.tenantUserAccess.entities.tenantUserAccesses;
      let idNuevoSCreate = null;
      if (Object.values(datoTenantUserAccessSCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoTenantUserAccessSCreate)[0] &&
          Object.values(datoTenantUserAccessSCreate)[0].id
            ? Object.values(datoTenantUserAccessSCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_TENANTUSERACCESSES:
      let tenantUserAccessesCreate =
        action.tenantUserAccesses.entities &&
        action.tenantUserAccesses.entities.tenantUserAccesses
          ? action.tenantUserAccesses.entities.tenantUserAccesses
          : null;
      return tenantUserAccessesCreate
        ? union(
            state,
            Object.values(tenantUserAccessesCreate).map(tenantUserAccesses => {
              return tenantUserAccesses.id;
            })
          )
        : state;
    case RESET_TENANTUSERACCESSES:
      return [];

    case RECEIVE_TENANT:
      let tenant =
        action.tenant.entities && action.tenant.entities.tenantUserAccesses
          ? action.tenant.entities.tenantUserAccesses
          : null;
      return tenant
        ? union(
            state,
            Object.values(tenant).map(tenant => {
              return tenant.id;
            })
          )
        : state;
    case RECEIVE_TENANTS:
      let tenants =
        action.tenants.entities && action.tenants.entities.tenantUserAccesses
          ? action.tenants.entities.tenantUserAccesses
          : null;
      return tenants
        ? union(
            state,
            Object.values(tenants).map(tenants => {
              return tenants.id;
            })
          )
        : state;

    case SUCCESS_DELETE_TENANT:
      let tenantDelete =
        action.tenant.entities && action.tenant.entities.tenantUserAccesses
          ? action.tenant.entities.tenantUserAccesses
          : null;
      return tenantDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(tenantDelete).map(tenant => {
              return tenant.id;
            })
          )
        : state;
    case SUCCESS_CREATE_TENANT:
      let tenantCreate =
        action.tenant.entities && action.tenant.entities.tenantUserAccesses
          ? action.tenant.entities.tenantUserAccesses
          : null;
      return tenantCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(tenantCreate).map(tenant => {
              return tenant.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_TENANT:
      let tenantUpdate =
        action.tenant.entities && action.tenant.entities.tenantUserAccesses
          ? action.tenant.entities.tenantUserAccesses
          : null;
      return tenantUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(tenantUpdate).map(tenant => {
              return tenant.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_TENANTS:
      let tenantsUpdate =
        action.tenants.entities && action.tenants.entities.tenantUserAccesses
          ? action.tenants.entities.tenantUserAccesses
          : null;
      return tenantsUpdate
        ? union(
            state,
            Object.values(tenantsUpdate).map(tenants => {
              return tenants.id;
            })
          )
        : state;
    case RECEIVE_USER:
      let user =
        action.user.entities && action.user.entities.tenantUserAccesses
          ? action.user.entities.tenantUserAccesses
          : null;
      return user
        ? union(
            state,
            Object.values(user).map(user => {
              return user.id;
            })
          )
        : state;
    case RECEIVE_USERS:
      let users =
        action.users.entities && action.users.entities.tenantUserAccesses
          ? action.users.entities.tenantUserAccesses
          : null;
      return users
        ? union(
            state,
            Object.values(users).map(users => {
              return users.id;
            })
          )
        : state;

    case SUCCESS_DELETE_USER:
      let userDelete =
        action.user.entities && action.user.entities.tenantUserAccesses
          ? action.user.entities.tenantUserAccesses
          : null;
      return userDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(userDelete).map(user => {
              return user.id;
            })
          )
        : state;
    case SUCCESS_CREATE_USER:
      let userCreate =
        action.user.entities && action.user.entities.tenantUserAccesses
          ? action.user.entities.tenantUserAccesses
          : null;
      return userCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(userCreate).map(user => {
              return user.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_USER:
      let userUpdate =
        action.user.entities && action.user.entities.tenantUserAccesses
          ? action.user.entities.tenantUserAccesses
          : null;
      return userUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(userUpdate).map(user => {
              return user.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_USERS:
      let usersUpdate =
        action.users.entities && action.users.entities.tenantUserAccesses
          ? action.users.entities.tenantUserAccesses
          : null;
      return usersUpdate
        ? union(
            state,
            Object.values(usersUpdate).map(users => {
              return users.id;
            })
          )
        : state;

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalTenantUserAccesses(state = null, action) {
  switch (action.type) {
    case RECEIVE_TENANTUSERACCESSES:
      return action.tenantUserAccesses && action.tenantUserAccesses.result.total
        ? action.tenantUserAccesses.result.total
        : 0;
    case RESET_TENANTUSERACCESSES:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_TENANTUSERACCESS:
      let dato = action.tenantUserAccess.entities.tenantUserAccesses;
      let tenantUserAccess =
        dato && Object.keys(dato).length > 0
          ? dato[action.tenantUserAccess.result]
          : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: tenantUserAccess ? tenantUserAccess : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_TENANTUSERACCESS:
      let idsUpdate = [];
      Object.values(action.tenantUserAccess).map(tenantUserAccessUpdate => {
        if (tenantUserAccessUpdate && tenantUserAccessUpdate.id)
          idsUpdate.push(tenantUserAccessUpdate.id);
      });
      return merge({}, state, {
        activo: action.tenantUserAccess,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_TENANTUSERACCESS:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_TENANTUSERACCESS:
      let datoTenantUserAccessActualizado =
        action.tenantUserAccess.entities.tenantUserAccesses;
      let tenantUserAccessNuevo =
        datoTenantUserAccessActualizado &&
        Object.keys(datoTenantUserAccessActualizado).length > 0
          ? datoTenantUserAccessActualizado[action.tenantUserAccess.result]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: tenantUserAccessNuevo
      });
    case ERROR_UPDATE_TENANTUSERACCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_TENANTUSERACCESSES:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_TENANTUSERACCESSES:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_TENANTUSERACCESSES:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_TENANTUSERACCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    //TENANT
    //TODO ver si esta bien
    case SUCCESS_CREATE_TENANT:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_TENANT:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_TENANT:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_TENANTS:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      }); //USER
    //TODO ver si esta bien
    case SUCCESS_CREATE_USER:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_USER:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_USER:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_USERS:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });

    case DELETE_TENANTUSERACCESS:
      let datoTenantUserAccessDelete = action.tenantUserAccess;
      let idsDelete = [];
      Object.values(action.tenantUserAccess).map(tenantUserAccessDelete => {
        if (tenantUserAccessDelete && tenantUserAccessDelete.id)
          idsDelete.push(tenantUserAccessDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoTenantUserAccessDelete)
          ),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_TENANTUSERACCESS:
      let datoTenantUserAccessDeleteUpdate = action.tenantUserAccess;
      let idsDeleteUpdate = [];
      Object.values(action.tenantUserAccess).map(tenantUserAccessDelete => {
        if (tenantUserAccessDelete && tenantUserAccessDelete.id)
          idsDeleteUpdate.push(tenantUserAccessDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoTenantUserAccessDeleteUpdate)
          ),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_TENANTUSERACCESS:
      let datoTenantUserAccessDeleted = {};
      if (
        Object.values(action.tenantUserAccess.entities.tenantUserAccesses)
          .length > 0
      )
        datoTenantUserAccessDeleted = Object.values(
          action.tenantUserAccess.entities.tenantUserAccesses
        )[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoTenantUserAccessDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_TENANTUSERACCESS:
      let idsCreate = [];
      Object.values(action.tenantUserAccess).map(tenantUserAccessCreate => {
        if (tenantUserAccessCreate && tenantUserAccessCreate.id)
          idsCreate.push(tenantUserAccessCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.tenantUserAccess,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_TENANTUSERACCESS:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_TENANTUSERACCESS:
      let datoTenantUserAccessNuevo =
        action.tenantUserAccess.entities.tenantUserAccesses;
      let tenantUserAccessNuevo =
        datoTenantUserAccessNuevo &&
        Object.keys(datoTenantUserAccessNuevo).length > 0
          ? datoTenantUserAccessNuevo[action.tenantUserAccess.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: tenantUserAccessNuevo,
        nuevos: []
      });
    case ERROR_CREATE_TENANTUSERACCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_TENANTUSERACCESSES:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_TENANTUSERACCESSES:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_TENANTUSERACCESSES:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_TENANTUSERACCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    //TENANT
    case SUCCESS_CREATE_TENANT:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_TENANT:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_TENANT:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_TENANTS:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    //USER
    case SUCCESS_CREATE_USER:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_USER:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_USER:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_USERS:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });

    case DELETE_TENANTUSERACCESS:
      let datoTenantUserAccessDelete = action.tenantUserAccess;
      let idsDelete = [];
      Object.values(action.tenantUserAccess).map(tenantUserAccessDelete => {
        if (tenantUserAccessDelete && tenantUserAccessDelete.id)
          idsDelete.push(tenantUserAccessDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoTenantUserAccessDelete)
          ),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_TENANTUSERACCESS:
      let datoTenantUserAccessDeleteCreate = action.tenantUserAccess;
      let idsDeleteCreate = [];
      Object.values(action.tenantUserAccess).map(tenantUserAccessDelete => {
        if (tenantUserAccessDelete && tenantUserAccessDelete.id)
          idsDeleteCreate.push(tenantUserAccessDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoTenantUserAccessDeleteCreate)
          ),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_TENANTUSERACCESS:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.tenantUserAccess,
        error: null
      });
    case REQUEST_DELETE_TENANTUSERACCESS:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_TENANTUSERACCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_TENANTUSERACCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_TENANTUSERACCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    //TENANT
    case SUCCESS_CREATE_TENANT:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_TENANT:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_TENANT:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_TENANTS:
      return Object.assign({}, state, {
        eliminado: {}
      }); //USER
    case SUCCESS_CREATE_USER:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_USER:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_USER:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_USERS:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_TENANTUSERACCESS:
      let idsCreate = [];
      Object.values(action.tenantUserAccess).map(tenantUserAccessCreate => {
        if (tenantUserAccessCreate && tenantUserAccessCreate.id)
          idsCreate.push(tenantUserAccessCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.tenantUserAccess,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_TENANTUSERACCESS:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_TENANTUSERACCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_TENANTUSERACCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_TENANTUSERACCESSES:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_TENANTUSERACCESSES:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_TENANTUSERACCESSES:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_TENANTUSERACCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_TENANTUSERACCESS:
      let datoTenantUserAccessDeleteCreate = action.tenantUserAccess;
      let idsDeleteCreate = [];
      Object.values(action.tenantUserAccess).map(tenantUserAccessDelete => {
        if (tenantUserAccessDelete && tenantUserAccessDelete.id)
          idsDeleteCreate.push(tenantUserAccessDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(
            clone(state.print),
            Object.keys(datoTenantUserAccessDeleteCreate)
          ),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const tenantUserAccesses = combineReducers({
  byId: tenantUserAccessesById,
  allIds: allTenantUserAccesses,
  update: update,
  create: create,
  totalTenantUserAccesses: totalTenantUserAccesses,
  delete: deleter,
  print: print
});

export default tenantUserAccesses;
