//api
import domainsApi from '../api/domainsApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizeDomains';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//DOMAINS
export const REQUEST_DOMAINS = 'REQUEST_DOMAINS';
export const RECEIVE_DOMAINS = 'RECEIVE_DOMAINS';
export const INVALIDATE_DOMAINS = 'INVALIDATE_DOMAINS';
export const ERROR_DOMAINS = 'ERROR_DOMAINS';
export const RESET_DOMAINS = 'RESET_DOMAINS';

export function invalidateDomains() {
  return {
    type: INVALIDATE_DOMAINS
  };
}

function requestDomains() {
  return {
    type: REQUEST_DOMAINS
  };
}

function receiveDomains(json) {
  return {
    type: RECEIVE_DOMAINS,
    domains: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorDomains(error) {
  return {
    type: ERROR_DOMAINS,
    error: error
  };
}

export function resetDomains() {
  return {
    type: RESET_DOMAINS
  };
}

export function fetchDomains(filtros) {
  return dispatch => {
    dispatch(requestDomains());
    return domainsApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveDomains(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorDomains(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorDomains(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

function shouldFetchDomains(state) {
  const domains = state.domains.byId;
  if (!domains) {
    return true;
  } else if (domains.isFetching) {
    return false;
  } else {
    return domains.didInvalidate;
  }
}

export function fetchDomainsIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchDomains(getState())) {
      return dispatch(fetchDomains(filtros));
    }
  };
}

//MODEL
export const REQUEST_DOMAIN = 'REQUEST_DOMAIN';
export const RECEIVE_DOMAIN = 'RECEIVE_DOMAIN';
export const INVALIDATE_DOMAIN = 'INVALIDATE_DOMAIN';
export const ERROR_DOMAIN = 'ERROR_DOMAIN';
export const RESET_DOMAIN = 'RESET_DOMAIN';

export function invalidateDomain() {
  return {
    type: INVALIDATE_DOMAIN
  };
}

function requestDomain() {
  return {
    type: REQUEST_DOMAIN
  };
}

export function receiveDomain(json) {
  return {
    type: RECEIVE_DOMAIN,
    domain: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorDomain(error) {
  return {
    type: ERROR_DOMAIN,
    error: error
  };
}

export function fetchDomain(idDomain) {
  return dispatch => {
    dispatch(requestDomain());
    return domainsApi
      .getOne(idDomain)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveDomain(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(errorDomain(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorDomain(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_DOMAIN = 'RECEIVE_FILE_DOMAIN';

function receiveFileDomain(file) {
  return {
    type: RECEIVE_FILE_DOMAIN,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFileDomain(idDomain, filtros) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return domainsApi
      .getFile(idDomain, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFileDomain(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorDomain(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorDomain(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_DOMAIN = 'UPDATE_DOMAIN';
export const REQUEST_UPDATE_DOMAIN = 'REQUEST_UPDATE_DOMAIN';
export const SUCCESS_UPDATE_DOMAIN = 'SUCCESS_UPDATE_DOMAIN';
export const ERROR_UPDATE_DOMAIN = 'ERROR_UPDATE_DOMAIN';
export const RESET_UPDATE_DOMAIN = 'RESET_UPDATE_DOMAIN';
export const DELETE_UPDATE_DOMAIN = 'DELETE_UPDATE_DOMAIN';

function requestUpdateDomain() {
  return {
    type: REQUEST_UPDATE_DOMAIN
  };
}

function receiveUpdateDomain(domain) {
  return {
    type: SUCCESS_UPDATE_DOMAIN,
    receivedAt: Date.now(),
    domain: normalizeDato(domain)
  };
}

function errorUpdateDomain(error) {
  return {
    type: ERROR_UPDATE_DOMAIN,
    error: error
  };
}

export function resetUpdateDomain() {
  return {
    type: RESET_UPDATE_DOMAIN
  };
}

export function updateDomain(domain) {
  return {
    type: UPDATE_DOMAIN,
    domain
  };
}

export function saveUpdateDomain() {
  return (dispatch, getState) => {
    dispatch(requestUpdateDomain());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let domain = denormalizeDato(getState().domains.update.activo, store);

    return domainsApi
      .saveUpdate(domain)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateDomain(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateDomain(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateDomains(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdateDomains(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateDomain(JSON.parse(error.message)));
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdateDomain(error.data));
                })
                .catch(() => {
                  dispatch(errorUpdateDomain(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

export function deleteUpdateDomain(domain) {
  return {
    type: DELETE_UPDATE_DOMAIN,
    domain
  };
}

//UPDATE DOMAINS
export const REQUEST_UPDATE_DOMAINS = 'REQUEST_UPDATE_DOMAINS';
export const SUCCESS_UPDATE_DOMAINS = 'SUCCESS_UPDATE_DOMAINS';
export const ERROR_UPDATE_DOMAINS = 'ERROR_UPDATE_DOMAINS';
export const RESET_UPDATE_DOMAINS = 'RESET_UPDATE_DOMAINS';

function requestUpdateDomains() {
  return {
    type: REQUEST_UPDATE_DOMAINS
  };
}

function receiveUpdateDomains(domains) {
  return {
    type: SUCCESS_UPDATE_DOMAINS,
    receivedAt: Date.now(),
    domains: normalizeDatos(domains)
  };
}

function errorUpdateDomains(error) {
  return {
    type: ERROR_UPDATE_DOMAINS,
    error: error
  };
}

export function resetUpdateDomains() {
  return {
    type: RESET_UPDATE_DOMAINS
  };
}

export function saveUpdateDomains() {
  return (dispatch, getState) => {
    dispatch(requestUpdateDomains());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let domains = getState().domains.update.activos.map(idDomain => {
      return denormalizeDato(getState().domains.update.activo[idDomain], store);
    });

    return domainsApi
      .saveUpdateDomains(domains)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateDomains(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateDomains(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateDomains(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdateDomains(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateDomains(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorUpdateDomains(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//ALTA DOMAIN
export const CREATE_DOMAIN = 'CREATE_DOMAIN';
export const REQUEST_CREATE_DOMAIN = 'REQUEST_CREATE_DOMAIN';
export const SUCCESS_CREATE_DOMAIN = 'SUCCESS_CREATE_DOMAIN';
export const ERROR_CREATE_DOMAIN = 'ERROR_CREATE_DOMAIN';
export const RESET_CREATE_DOMAIN = 'RESET_CREATE_DOMAIN';
export const DELETE_CREATE_DOMAIN = 'DELETE_CREATE_DOMAIN';

//ALTA DOMAIN
function requestCreateDomain() {
  return {
    type: REQUEST_CREATE_DOMAIN
  };
}

function receiveCreateDomain(domain) {
  return {
    type: SUCCESS_CREATE_DOMAIN,
    receivedAt: Date.now(),
    domain: normalizeDato(domain)
  };
}

export function errorCreateDomain(error) {
  return {
    type: ERROR_CREATE_DOMAIN,
    error: error
  };
}

export function resetCreateDomain() {
  return {
    type: RESET_CREATE_DOMAIN
  };
}

export function createDomain(domain) {
  return {
    type: CREATE_DOMAIN,
    domain
  };
}

export function deleteCreateDomain(domain) {
  return {
    type: DELETE_CREATE_DOMAIN,
    domain
  };
}

export function saveCreateDomain() {
  return (dispatch, getState) => {
    dispatch(requestCreateDomain());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let domain = denormalizeDato(getState().domains.create.nuevo, store);

    return domainsApi
      .saveCreate(domain)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateDomain(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateDomain(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateDomains(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreateDomains(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateDomain(JSON.parse(error.message)));
                  if (error.data) dispatch(receiveCreateDomain(error.data));
                })
                .catch(() => {
                  dispatch(errorCreateDomain(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//CREATE DOMAINS
export const REQUEST_CREATE_DOMAINS = 'REQUEST_CREATE_DOMAINS';
export const SUCCESS_CREATE_DOMAINS = 'SUCCESS_CREATE_DOMAINS';
export const ERROR_CREATE_DOMAINS = 'ERROR_CREATE_DOMAINS';
export const RESET_CREATE_DOMAINS = 'RESET_CREATE_DOMAINS';

function requestCreateDomains() {
  return {
    type: REQUEST_CREATE_DOMAINS
  };
}

function receiveCreateDomains(domains) {
  return {
    type: SUCCESS_CREATE_DOMAINS,
    receivedAt: Date.now(),
    domains: normalizeDatos(domains)
  };
}

function errorCreateDomains(error) {
  return {
    type: ERROR_CREATE_DOMAINS,
    error: error
  };
}

export function resetCreateDomains() {
  return {
    type: RESET_CREATE_DOMAINS
  };
}

export function saveCreateDomains() {
  return (dispatch, getState) => {
    dispatch(requestCreateDomains());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let domains = getState().domains.create.nuevos.map(idDomain => {
      return denormalizeDato(getState().domains.create.nuevo[idDomain], store);
    });

    return domainsApi
      .saveCreateDomains(domains)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateDomains(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateDomains(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateDomains(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreateDomains(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateDomains(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorCreateDomains(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//DELETE DOMAIN
export const DELETE_DOMAIN = 'DELETE_DOMAIN';
export const REQUEST_DELETE_DOMAIN = 'REQUEST_DELETE_DOMAIN';
export const SUCCESS_DELETE_DOMAIN = 'SUCCESS_DELETE_DOMAIN';
export const ERROR_DELETE_DOMAIN = 'ERROR_DELETE_DOMAIN';
export const RESET_DELETE_DOMAIN = 'RESET_DELETE_DOMAIN';

function requestDeleteDomain() {
  return {
    type: REQUEST_DELETE_DOMAIN
  };
}

function receiveDeleteDomain(domain) {
  return {
    type: SUCCESS_DELETE_DOMAIN,
    receivedAt: Date.now(),
    domain: normalizeDato(domain)
  };
}

function errorDeleteDomain(error) {
  return {
    type: ERROR_DELETE_DOMAIN,
    error: error
  };
}

export function resetDeleteDomain(error) {
  return {
    type: RESET_DELETE_DOMAIN,
    error: error
  };
}

export function deleteDomain(domain) {
  return {
    type: DELETE_DOMAIN,
    domain
  };
}

export function saveDeleteDomain(domain) {
  return dispatch => {
    dispatch(requestDeleteDomain());
    return domainsApi
      .saveDelete(domain)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeleteDomain());
        dispatch(receiveDeleteDomain(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorDeleteDomain(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeleteDomain(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorDeleteDomain(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorDeleteDomain(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorDeleteDomain(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT DOMAIN
export const PRINT_DOMAIN = 'PRINT_DOMAIN';
export const REQUEST_PRINT_DOMAIN = 'REQUEST_PRINT_DOMAIN';
export const SUCCESS_PRINT_DOMAIN = 'SUCCESS_PRINT_DOMAIN';
export const ERROR_PRINT_DOMAIN = 'ERROR_PRINT_DOMAIN';
export const RESET_PRINT_DOMAIN = 'RESET_PRINT_DOMAIN';
export const DELETE_PRINT_DOMAIN = 'DELETE_PRINT_DOMAIN';

function requestPrintDomain() {
  return {
    type: REQUEST_PRINT_DOMAIN
  };
}

function receivePrintDomain(turnos) {
  return {
    type: SUCCESS_PRINT_DOMAIN,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintDomain(error) {
  return {
    type: ERROR_PRINT_DOMAIN,
    error: error
  };
}

export function resetPrintDomain() {
  return {
    type: RESET_PRINT_DOMAIN
  };
}

export function printDomain(domain) {
  return {
    type: PRINT_DOMAIN,
    domain
  };
}

export function deletePrintDomain(domain) {
  return {
    type: DELETE_PRINT_DOMAIN,
    domain
  };
}

export function savePrintDomain(idDomain) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintDomain());
    return domainsApi
      .printDomain(idDomain)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintDomain(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintDomain(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintDomain(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintDomain(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintDomain(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT DOMAINS
export const REQUEST_PRINT_DOMAINS = 'REQUEST_PRINT_DOMAINS';
export const SUCCESS_PRINT_DOMAINS = 'SUCCESS_PRINT_DOMAINS';
export const ERROR_PRINT_DOMAINS = 'ERROR_PRINT_DOMAINS';
export const RESET_PRINT_DOMAINS = 'RESET_PRINT_DOMAINS';

function requestPrintDomains() {
  return {
    type: REQUEST_PRINT_DOMAINS
  };
}

function receivePrintDomains(domains) {
  return {
    type: SUCCESS_PRINT_DOMAINS,
    receivedAt: Date.now(),
    domains: normalizeDatos(domains)
  };
}

function errorPrintDomains(error) {
  return {
    type: ERROR_PRINT_DOMAINS,
    error: error
  };
}

export function resetPrintDomains() {
  return {
    type: RESET_PRINT_DOMAINS
  };
}

export function savePrintDomains() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintDomain());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let domains = getState().domains.print.printers.map(idDomain => {
      return denormalizeDato(getState().domains.print.print[idDomain], store);
    });
    return domainsApi
      .printDomains(domains)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintDomains(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintDomains(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintDomains(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintDomains(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintDomains(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}
