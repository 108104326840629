import {
  INVALIDATE_OPERATIONTYPES,
  ERROR_OPERATIONTYPES,
  RECEIVE_OPERATIONTYPES,
  REQUEST_OPERATIONTYPES,
  RESET_OPERATIONTYPES,
  ERROR_OPERATIONTYPE,
  RECEIVE_OPERATIONTYPE,
  REQUEST_OPERATIONTYPE,
  UPDATE_OPERATIONTYPE,
  REQUEST_UPDATE_OPERATIONTYPE,
  SUCCESS_UPDATE_OPERATIONTYPE,
  ERROR_UPDATE_OPERATIONTYPE,
  RESET_UPDATE_OPERATIONTYPE,
  REQUEST_UPDATE_OPERATIONTYPES,
  SUCCESS_UPDATE_OPERATIONTYPES,
  ERROR_UPDATE_OPERATIONTYPES,
  RESET_UPDATE_OPERATIONTYPES,
  CREATE_OPERATIONTYPE,
  ERROR_CREATE_OPERATIONTYPE,
  REQUEST_CREATE_OPERATIONTYPE,
  RESET_CREATE_OPERATIONTYPE,
  SUCCESS_CREATE_OPERATIONTYPE,
  REQUEST_CREATE_OPERATIONTYPES,
  SUCCESS_CREATE_OPERATIONTYPES,
  ERROR_CREATE_OPERATIONTYPES,
  RESET_CREATE_OPERATIONTYPES,
  DELETE_OPERATIONTYPE,
  DELETE_CREATE_OPERATIONTYPE,
  DELETE_UPDATE_OPERATIONTYPE,
  REQUEST_DELETE_OPERATIONTYPE,
  SUCCESS_DELETE_OPERATIONTYPE,
  ERROR_DELETE_OPERATIONTYPE,
  RESET_DELETE_OPERATIONTYPE,
  REQUEST_PRINT_OPERATIONTYPE,
  SUCCESS_PRINT_OPERATIONTYPE,
  ERROR_PRINT_OPERATIONTYPE,
  RESET_PRINT_OPERATIONTYPE,
  RECEIVE_FILE_OPERATIONTYPE,
  REQUEST_PRINT_OPERATIONTYPES,
  SUCCESS_PRINT_OPERATIONTYPES,
  ERROR_PRINT_OPERATIONTYPES,
  RESET_PRINT_OPERATIONTYPES,
  RECEIVE_FILE_OPERATIONTYPES,
  PRINT_OPERATIONTYPE,
  DELETE_PRINT_OPERATIONTYPE
} from '../actions/OperationTypeActions';

import {
  RECEIVE_PROPERTY,
  RECEIVE_PROPERTIES,
  SUCCESS_DELETE_PROPERTY,
  SUCCESS_CREATE_PROPERTY,
  SUCCESS_UPDATE_PROPERTY,
  SUCCESS_UPDATE_PROPERTIES
} from '../actions/PropertyActions';
import {
  RECEIVE_PROPERTYCLOSEDOPERATION,
  RECEIVE_PROPERTYCLOSEDOPERATIONS,
  SUCCESS_DELETE_PROPERTYCLOSEDOPERATION,
  SUCCESS_CREATE_PROPERTYCLOSEDOPERATION,
  SUCCESS_UPDATE_PROPERTYCLOSEDOPERATION,
  SUCCESS_UPDATE_PROPERTYCLOSEDOPERATIONS
} from '../actions/PropertyClosedOperationActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    operationTypes: {},
    files: {}
  };
}

function operationTypesById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_OPERATIONTYPES:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_OPERATIONTYPES:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_OPERATIONTYPES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_OPERATIONTYPES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        operationTypes: {}
      });
    case RECEIVE_OPERATIONTYPES:
      let dato = action.operationTypes.entities.operationTypes;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        operationTypes: merge({}, state.operationTypes, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_OPERATIONTYPE:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_OPERATIONTYPE:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_OPERATIONTYPE:
      let datoOperationType = action.operationType.entities.operationTypes;
      return Object.assign({}, state, {
        operationTypes: merge({}, state.operationTypes, datoOperationType),
        isFetching: false
      });
    case RECEIVE_FILE_OPERATIONTYPE:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_OPERATIONTYPE:
      let datoOperationTypeEliminado =
        action.operationType.entities.operationTypes;
      return Object.assign({}, state, {
        operationTypes: mergeWith(
          clone(datoOperationTypeEliminado),
          state.operationTypes,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_OPERATIONTYPE:
      let datoOperationTypeCreado =
        action.operationType.entities.operationTypes;
      return Object.assign({}, state, {
        operationTypes: mergeWith(
          clone(datoOperationTypeCreado),
          state.operationTypes,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_OPERATIONTYPES:
      let datosOperationTypeCreado =
        action.operationTypes.entities.operationTypes;
      return Object.assign({}, state, {
        operationTypes: mergeWith(
          clone(datosOperationTypeCreado),
          state.operationTypes,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_OPERATIONTYPE:
      let datoOperationTypeActualizado =
        action.operationType.entities.operationTypes;
      return Object.assign({}, state, {
        operationTypes: mergeWith(
          clone(datoOperationTypeActualizado),
          state.operationTypes,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_OPERATIONTYPES:
      let datosOperationTypeActualizado =
        action.operationTypes.entities.operationTypes;
      return Object.assign({}, state, {
        operationTypes: mergeWith(
          clone(datosOperationTypeActualizado),
          state.operationTypes,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    //PROPERTY
    case RECEIVE_PROPERTY:
      let property =
        action.property.entities && action.property.entities.operationTypes
          ? action.property.entities.operationTypes
          : {};
      return Object.assign({}, state, {
        operationTypes: merge({}, state.operationTypes, property)
      });
    case RECEIVE_PROPERTIES:
      let properties =
        action.properties.entities && action.properties.entities.operationTypes
          ? action.properties.entities.operationTypes
          : {};
      return Object.assign({}, state, {
        operationTypes: merge({}, state.operationTypes, properties)
      });
    case SUCCESS_DELETE_PROPERTY:
      let datopropertyEliminado =
        action.property.entities && action.property.entities.operationTypes
          ? action.property.entities.operationTypes
          : {};
      return Object.assign({}, state, {
        operationTypes: mergeWith(
          clone(datopropertyEliminado),
          pickBy(state.operationTypes, function (operationType) {
            return operationType.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_PROPERTY:
      let datopropertyCreado =
        action.property.entities && action.property.entities.operationTypes
          ? action.property.entities.operationTypes
          : {};
      return Object.assign({}, state, {
        operationTypes: mergeWith(
          clone(datopropertyCreado),
          pickBy(state.operationTypes, function (operationType) {
            return operationType.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTY:
      let datopropertyActualizado =
        action.property.entities && action.property.entities.operationTypes
          ? action.property.entities.operationTypes
          : {};
      return Object.assign({}, state, {
        operationTypes: mergeWith(
          clone(datopropertyActualizado),
          pickBy(state.operationTypes, function (operationType) {
            return operationType.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTIES:
      let datospropertyActualizado =
        action.properties.entities && action.properties.entities.operationTypes
          ? action.properties.entities.operationTypes
          : {};
      return Object.assign({}, state, {
        operationTypes: mergeWith(
          clone(datospropertyActualizado),
          state.operationTypes,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    //PROPERTYCLOSEDOPERATION
    case RECEIVE_PROPERTYCLOSEDOPERATION:
      let propertyClosedOperation =
        action.propertyClosedOperation.entities &&
        action.propertyClosedOperation.entities.operationTypes
          ? action.propertyClosedOperation.entities.operationTypes
          : {};
      return Object.assign({}, state, {
        operationTypes: merge({}, state.operationTypes, propertyClosedOperation)
      });
    case RECEIVE_PROPERTYCLOSEDOPERATIONS:
      let propertyClosedOperations =
        action.propertyClosedOperations.entities &&
        action.propertyClosedOperations.entities.operationTypes
          ? action.propertyClosedOperations.entities.operationTypes
          : {};
      return Object.assign({}, state, {
        operationTypes: merge(
          {},
          state.operationTypes,
          propertyClosedOperations
        )
      });
    case SUCCESS_DELETE_PROPERTYCLOSEDOPERATION:
      let datopropertyClosedOperationEliminado =
        action.propertyClosedOperation.entities &&
        action.propertyClosedOperation.entities.operationTypes
          ? action.propertyClosedOperation.entities.operationTypes
          : {};
      return Object.assign({}, state, {
        operationTypes: mergeWith(
          clone(datopropertyClosedOperationEliminado),
          pickBy(state.operationTypes, function (operationType) {
            return operationType.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_PROPERTYCLOSEDOPERATION:
      let datopropertyClosedOperationCreado =
        action.propertyClosedOperation.entities &&
        action.propertyClosedOperation.entities.operationTypes
          ? action.propertyClosedOperation.entities.operationTypes
          : {};
      return Object.assign({}, state, {
        operationTypes: mergeWith(
          clone(datopropertyClosedOperationCreado),
          pickBy(state.operationTypes, function (operationType) {
            return operationType.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTYCLOSEDOPERATION:
      let datopropertyClosedOperationActualizado =
        action.propertyClosedOperation.entities &&
        action.propertyClosedOperation.entities.operationTypes
          ? action.propertyClosedOperation.entities.operationTypes
          : {};
      return Object.assign({}, state, {
        operationTypes: mergeWith(
          clone(datopropertyClosedOperationActualizado),
          pickBy(state.operationTypes, function (operationType) {
            return operationType.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTYCLOSEDOPERATIONS:
      let datospropertyClosedOperationActualizado =
        action.propertyClosedOperations.entities &&
        action.propertyClosedOperations.entities.operationTypes
          ? action.propertyClosedOperations.entities.operationTypes
          : {};
      return Object.assign({}, state, {
        operationTypes: mergeWith(
          clone(datospropertyClosedOperationActualizado),
          state.operationTypes,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        operationTypes: {}
      });
    default:
      return state;
  }
}

function allOperationTypes(state = [], action) {
  switch (action.type) {
    case RECEIVE_OPERATIONTYPES:
      return action.operationTypes.result &&
        action.operationTypes.result.operationTypes
        ? union(action.operationTypes.result.operationTypes, state)
        : action.operationTypes.result
        ? action.operationTypes.result
        : state;
    case RECEIVE_OPERATIONTYPE:
      return action.operationType.result
        ? union([action.operationType.result], state)
        : state;

    case SUCCESS_CREATE_OPERATIONTYPE:
      let datoOperationTypeSCreate =
        action.operationType.entities.operationTypes;
      let idNuevoSCreate = null;
      if (Object.values(datoOperationTypeSCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoOperationTypeSCreate)[0] &&
          Object.values(datoOperationTypeSCreate)[0].id
            ? Object.values(datoOperationTypeSCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_OPERATIONTYPES:
      let operationTypesCreate =
        action.operationTypes.entities &&
        action.operationTypes.entities.operationTypes
          ? action.operationTypes.entities.operationTypes
          : null;
      return operationTypesCreate
        ? union(
            state,
            Object.values(operationTypesCreate).map(operationTypes => {
              return operationTypes.id;
            })
          )
        : state;
    case RESET_OPERATIONTYPES:
      return [];

    case RECEIVE_PROPERTY:
      let property =
        action.property.entities && action.property.entities.operationTypes
          ? action.property.entities.operationTypes
          : null;
      return property
        ? union(
            state,
            Object.values(property).map(property => {
              return property.id;
            })
          )
        : state;
    case RECEIVE_PROPERTIES:
      let properties =
        action.properties.entities && action.properties.entities.operationTypes
          ? action.properties.entities.operationTypes
          : null;
      return properties
        ? union(
            state,
            Object.values(properties).map(properties => {
              return properties.id;
            })
          )
        : state;

    case SUCCESS_DELETE_PROPERTY:
      let propertyDelete =
        action.property.entities && action.property.entities.operationTypes
          ? action.property.entities.operationTypes
          : null;
      return propertyDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyDelete).map(property => {
              return property.id;
            })
          )
        : state;
    case SUCCESS_CREATE_PROPERTY:
      let propertyCreate =
        action.property.entities && action.property.entities.operationTypes
          ? action.property.entities.operationTypes
          : null;
      return propertyCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyCreate).map(property => {
              return property.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_PROPERTY:
      let propertyUpdate =
        action.property.entities && action.property.entities.operationTypes
          ? action.property.entities.operationTypes
          : null;
      return propertyUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyUpdate).map(property => {
              return property.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_PROPERTIES:
      let propertiesUpdate =
        action.properties.entities && action.properties.entities.operationTypes
          ? action.properties.entities.operationTypes
          : null;
      return propertiesUpdate
        ? union(
            state,
            Object.values(propertiesUpdate).map(properties => {
              return properties.id;
            })
          )
        : state;
    case RECEIVE_PROPERTYCLOSEDOPERATION:
      let propertyClosedOperation =
        action.propertyClosedOperation.entities &&
        action.propertyClosedOperation.entities.operationTypes
          ? action.propertyClosedOperation.entities.operationTypes
          : null;
      return propertyClosedOperation
        ? union(
            state,
            Object.values(propertyClosedOperation).map(
              propertyClosedOperation => {
                return propertyClosedOperation.id;
              }
            )
          )
        : state;
    case RECEIVE_PROPERTYCLOSEDOPERATIONS:
      let propertyClosedOperations =
        action.propertyClosedOperations.entities &&
        action.propertyClosedOperations.entities.operationTypes
          ? action.propertyClosedOperations.entities.operationTypes
          : null;
      return propertyClosedOperations
        ? union(
            state,
            Object.values(propertyClosedOperations).map(
              propertyClosedOperations => {
                return propertyClosedOperations.id;
              }
            )
          )
        : state;

    case SUCCESS_DELETE_PROPERTYCLOSEDOPERATION:
      let propertyClosedOperationDelete =
        action.propertyClosedOperation.entities &&
        action.propertyClosedOperation.entities.operationTypes
          ? action.propertyClosedOperation.entities.operationTypes
          : null;
      return propertyClosedOperationDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyClosedOperationDelete).map(
              propertyClosedOperation => {
                return propertyClosedOperation.id;
              }
            )
          )
        : state;
    case SUCCESS_CREATE_PROPERTYCLOSEDOPERATION:
      let propertyClosedOperationCreate =
        action.propertyClosedOperation.entities &&
        action.propertyClosedOperation.entities.operationTypes
          ? action.propertyClosedOperation.entities.operationTypes
          : null;
      return propertyClosedOperationCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyClosedOperationCreate).map(
              propertyClosedOperation => {
                return propertyClosedOperation.id;
              }
            )
          )
        : state;
    case SUCCESS_UPDATE_PROPERTYCLOSEDOPERATION:
      let propertyClosedOperationUpdate =
        action.propertyClosedOperation.entities &&
        action.propertyClosedOperation.entities.operationTypes
          ? action.propertyClosedOperation.entities.operationTypes
          : null;
      return propertyClosedOperationUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyClosedOperationUpdate).map(
              propertyClosedOperation => {
                return propertyClosedOperation.id;
              }
            )
          )
        : state;
    case SUCCESS_UPDATE_PROPERTYCLOSEDOPERATIONS:
      let propertyClosedOperationsUpdate =
        action.propertyClosedOperations.entities &&
        action.propertyClosedOperations.entities.operationTypes
          ? action.propertyClosedOperations.entities.operationTypes
          : null;
      return propertyClosedOperationsUpdate
        ? union(
            state,
            Object.values(propertyClosedOperationsUpdate).map(
              propertyClosedOperations => {
                return propertyClosedOperations.id;
              }
            )
          )
        : state;

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalOperationTypes(state = null, action) {
  switch (action.type) {
    case RECEIVE_OPERATIONTYPES:
      return action.operationTypes && action.operationTypes.result.total
        ? action.operationTypes.result.total
        : 0;
    case RESET_OPERATIONTYPES:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_OPERATIONTYPE:
      let dato = action.operationType.entities.operationTypes;
      let operationType =
        dato && Object.keys(dato).length > 0
          ? dato[action.operationType.result]
          : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: operationType ? operationType : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_OPERATIONTYPE:
      let idsUpdate = [];
      Object.values(action.operationType).map(operationTypeUpdate => {
        if (operationTypeUpdate && operationTypeUpdate.id)
          idsUpdate.push(operationTypeUpdate.id);
      });
      return merge({}, state, {
        activo: action.operationType,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_OPERATIONTYPE:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_OPERATIONTYPE:
      let datoOperationTypeActualizado =
        action.operationType.entities.operationTypes;
      let operationTypeNuevo =
        datoOperationTypeActualizado &&
        Object.keys(datoOperationTypeActualizado).length > 0
          ? datoOperationTypeActualizado[action.operationType.result]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: operationTypeNuevo
      });
    case ERROR_UPDATE_OPERATIONTYPE:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_OPERATIONTYPES:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_OPERATIONTYPES:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_OPERATIONTYPES:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_OPERATIONTYPE:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    //PROPERTY
    //TODO ver si esta bien
    case SUCCESS_CREATE_PROPERTY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_PROPERTY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      }); //PROPERTYCLOSEDOPERATION
    //TODO ver si esta bien
    case SUCCESS_CREATE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_PROPERTYCLOSEDOPERATIONS:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });

    case DELETE_OPERATIONTYPE:
      let datoOperationTypeDelete = action.operationType;
      let idsDelete = [];
      Object.values(action.operationType).map(operationTypeDelete => {
        if (operationTypeDelete && operationTypeDelete.id)
          idsDelete.push(operationTypeDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoOperationTypeDelete)
          ),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_OPERATIONTYPE:
      let datoOperationTypeDeleteUpdate = action.operationType;
      let idsDeleteUpdate = [];
      Object.values(action.operationType).map(operationTypeDelete => {
        if (operationTypeDelete && operationTypeDelete.id)
          idsDeleteUpdate.push(operationTypeDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoOperationTypeDeleteUpdate)
          ),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_OPERATIONTYPE:
      let datoOperationTypeDeleted = {};
      if (
        Object.values(action.operationType.entities.operationTypes).length > 0
      )
        datoOperationTypeDeleted = Object.values(
          action.operationType.entities.operationTypes
        )[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoOperationTypeDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_OPERATIONTYPE:
      let idsCreate = [];
      Object.values(action.operationType).map(operationTypeCreate => {
        if (operationTypeCreate && operationTypeCreate.id)
          idsCreate.push(operationTypeCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.operationType,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_OPERATIONTYPE:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_OPERATIONTYPE:
      let datoOperationTypeNuevo = action.operationType.entities.operationTypes;
      let operationTypeNuevo =
        datoOperationTypeNuevo && Object.keys(datoOperationTypeNuevo).length > 0
          ? datoOperationTypeNuevo[action.operationType.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: operationTypeNuevo,
        nuevos: []
      });
    case ERROR_CREATE_OPERATIONTYPE:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_OPERATIONTYPES:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_OPERATIONTYPES:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_OPERATIONTYPES:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_OPERATIONTYPE:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    //PROPERTY
    case SUCCESS_CREATE_PROPERTY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_PROPERTY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    //PROPERTYCLOSEDOPERATION
    case SUCCESS_CREATE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_PROPERTYCLOSEDOPERATIONS:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });

    case DELETE_OPERATIONTYPE:
      let datoOperationTypeDelete = action.operationType;
      let idsDelete = [];
      Object.values(action.operationType).map(operationTypeDelete => {
        if (operationTypeDelete && operationTypeDelete.id)
          idsDelete.push(operationTypeDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoOperationTypeDelete)),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_OPERATIONTYPE:
      let datoOperationTypeDeleteCreate = action.operationType;
      let idsDeleteCreate = [];
      Object.values(action.operationType).map(operationTypeDelete => {
        if (operationTypeDelete && operationTypeDelete.id)
          idsDeleteCreate.push(operationTypeDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoOperationTypeDeleteCreate)
          ),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_OPERATIONTYPE:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.operationType,
        error: null
      });
    case REQUEST_DELETE_OPERATIONTYPE:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_OPERATIONTYPE:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_OPERATIONTYPE:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_OPERATIONTYPE:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    //PROPERTY
    case SUCCESS_CREATE_PROPERTY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_PROPERTY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        eliminado: {}
      }); //PROPERTYCLOSEDOPERATION
    case SUCCESS_CREATE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_PROPERTYCLOSEDOPERATIONS:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_OPERATIONTYPE:
      let idsCreate = [];
      Object.values(action.operationType).map(operationTypeCreate => {
        if (operationTypeCreate && operationTypeCreate.id)
          idsCreate.push(operationTypeCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.operationType,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_OPERATIONTYPE:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_OPERATIONTYPE:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_OPERATIONTYPE:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_OPERATIONTYPES:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_OPERATIONTYPES:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_OPERATIONTYPES:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_OPERATIONTYPE:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_OPERATIONTYPE:
      let datoOperationTypeDeleteCreate = action.operationType;
      let idsDeleteCreate = [];
      Object.values(action.operationType).map(operationTypeDelete => {
        if (operationTypeDelete && operationTypeDelete.id)
          idsDeleteCreate.push(operationTypeDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(
            clone(state.print),
            Object.keys(datoOperationTypeDeleteCreate)
          ),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const operationTypes = combineReducers({
  byId: operationTypesById,
  allIds: allOperationTypes,
  update: update,
  create: create,
  totalOperationTypes: totalOperationTypes,
  delete: deleter,
  print: print
});

export default operationTypes;
