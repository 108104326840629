import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles } from '@material-ui/core/styles';
import ApartmentIcon from '@material-ui/icons/Apartment';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupIcon from '@material-ui/icons/Group';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import SettingsIcon from '@material-ui/icons/Settings';
import NoteIcon from '@material-ui/icons/Note';
import clsx from 'clsx';
import React from 'react';
//IMG
import HomeIcon from '@material-ui/icons/Home';

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { EmojiPeople } from '@material-ui/icons';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    height: '28px'
  },
  // toolbarOpen: {
  //     height: "129px"
  // },
  // toolbarClose: {
  //     height: "28px"
  // },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  flecha: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: '120px',
    width: '24px',
    height: '24px',
    borderRadius: '100%',
    border: '1px solid',
    borderColor: theme.palette.grey[500],
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.paper
  },
  flechaDrawerOpen: {
    left: drawerWidth - 12,
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  flechaDrawerClose: {
    left: theme.spacing(7) - 12,
    [theme.breakpoints.up('sm')]: {
      left: theme.spacing(9) - 12
    },
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  flechaIcon: {
    padding: '0px'
  },
  list: {
    width: 250
  },
  fullList: {
    width: 'auto'
  },
  itemVisitado: {
    backgroundColor: theme.palette.action.selected,
    '& .MuiListItemIcon-root': {
      color: theme.palette.selected[theme.palette.type]
    }
  },
  linkVisitado: {},
  link: {
    color: theme.palette.text[theme.palette.type],
    textDecoration: 'none'
  },
  btnItemMenu: {
    '& a': {
      textDecoration: 'none',
      display: 'flex',
      color: 'inherit',
      alignItems: 'center',
      width: '100%'
    }
  }
}));

export default function NavLeft(props) {
  const classes = useStyles();
  let location = useLocation();
  const auth = useSelector(state => state.auth);

  const ItemsMenu = () => {
    return (
      <List>
        <ListItem
          button
          className={clsx(classes.btnItemMenu, {
            [classes.itemVisitado]: location.pathname === '/'
          })}
        >
          <Link to={'/'}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary={'Dashboard'} />
          </Link>
        </ListItem>
        <ListItem
          button
          className={clsx(classes.btnItemMenu, {
            [classes.itemVisitado]: location.pathname.indexOf('tickets') !== -1
          })}
        >
          <Link to={'/tickets'}>
            <ListItemIcon>
              <EmojiPeople />
            </ListItemIcon>
            <ListItemText primary={'Oportunidades'} />
          </Link>
        </ListItem>
        <ListItem
          button
          className={clsx(classes.btnItemMenu, {
            [classes.itemVisitado]:
              location.pathname.indexOf('properties') !== -1 &&
              location.pathname.indexOf('developments') === -1
          })}
        >
          <Link to={'/properties'}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={'Propiedades'} />
          </Link>
        </ListItem>
        <ListItem
          button
          className={clsx(classes.btnItemMenu, {
            [classes.itemVisitado]:
              location.pathname.indexOf('developments') !== -1
          })}
        >
          <Link to={'/properties/developments'}>
            <ListItemIcon>
              <ApartmentIcon />
            </ListItemIcon>
            <ListItemText primary={'Emprendimientos'} />
          </Link>
        </ListItem>
        <ListItem
          button
          className={clsx(classes.btnItemMenu, {
            [classes.itemVisitado]: location.pathname.indexOf('contacts') !== -1
          })}
        >
          <Link to={'/contacts'}>
            <ListItemIcon>
              <MailOutlineIcon />
            </ListItemIcon>
            <ListItemText primary={'Contactos'} />
          </Link>
        </ListItem>

        {auth.loggedIn && auth.usuarioLogueado.role_id === 1 && (
          <ListItem
            button
            className={clsx(classes.btnItemMenu, {
              [classes.itemVisitado]: location.pathname.indexOf('users') !== -1
            })}
          >
            <Link to={'/users'}>
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary={'Usuarios'} />
            </Link>
          </ListItem>
        )}
        {auth.loggedIn && auth.usuarioLogueado.role_id === 1 && (
          <ListItem
            button
            className={clsx(classes.btnItemMenu, {
              [classes.itemVisitado]: location.pathname.indexOf('agents') !== -1
            })}
          >
            <Link to={'/agents'}>
              <ListItemIcon>
                <ContactPhoneIcon />
              </ListItemIcon>
              <ListItemText primary={'Asesores'} />
            </Link>
          </ListItem>
        )}
        <ListItem
          button
          className={clsx(classes.btnItemMenu, {
            [classes.itemVisitado]: location.pathname.indexOf('posts') !== -1
          })}
        >
          <Link to={'/posts'}>
            <ListItemIcon>
              <NoteIcon />
            </ListItemIcon>
            <ListItemText primary={'Blog'} />
          </Link>
        </ListItem>
        {auth.loggedIn && auth.usuarioLogueado.role_id === 1 && (
          <ListItem
            button
            className={clsx(classes.btnItemMenu, {
              [classes.itemVisitado]:
                location.pathname.indexOf('configuration') !== -1
            })}
          >
            <Link to={'/configuration'}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={'Configuración'} />
            </Link>
          </ListItem>
        )}
      </List>
    );
  };

  const list = anchor => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom'
      })}
      role="presentation"
      onClick={props.handleDrawerOpen}
      onKeyDown={props.handleDrawerOpen}
    >
      {ItemsMenu()}
      <Divider />
    </div>
  );

  return (
    <div>
      <Hidden xsDown implementation="css">
        <div
          className={clsx(classes.flecha, {
            [classes.flechaDrawerOpen]: props.open,
            [classes.flechaDrawerClose]: !props.open
          })}
        >
          <IconButton
            className={classes.flechaIcon}
            onClick={props.handleDrawerOpen}
          >
            {!props.open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: props.open,
            [classes.drawerClose]: !props.open
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: props.open,
              [classes.drawerClose]: !props.open
            })
          }}
        >
          <div className={clsx(classes.toolbar)} />
          <Divider />
          <List>{ItemsMenu()}</List>
          <Divider />
        </Drawer>
      </Hidden>
      <Hidden smUp>
        <SwipeableDrawer
          anchor="left"
          open={props.open}
          onClose={props.handleDrawerOpen}
          onOpen={props.handleDrawerOpen}
        >
          {list('left')}
        </SwipeableDrawer>
      </Hidden>
    </div>
  );
}
