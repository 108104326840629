//api
import countriesApi from '../api/countriesApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizeCountries';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//COUNTRIES
export const REQUEST_COUNTRIES = 'REQUEST_COUNTRIES';
export const RECEIVE_COUNTRIES = 'RECEIVE_COUNTRIES';
export const INVALIDATE_COUNTRIES = 'INVALIDATE_COUNTRIES';
export const ERROR_COUNTRIES = 'ERROR_COUNTRIES';
export const RESET_COUNTRIES = 'RESET_COUNTRIES';

export function invalidateCountries() {
  return {
    type: INVALIDATE_COUNTRIES
  };
}

function requestCountries() {
  return {
    type: REQUEST_COUNTRIES
  };
}

function receiveCountries(json) {
  return {
    type: RECEIVE_COUNTRIES,
    countries: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorCountries(error) {
  return {
    type: ERROR_COUNTRIES,
    error: error
  };
}

export function resetCountries() {
  return {
    type: RESET_COUNTRIES
  };
}

export function fetchCountries(filtros) {
  return dispatch => {
    dispatch(requestCountries());
    return countriesApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveCountries(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorCountries(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorCountries(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

function shouldFetchCountries(state) {
  const countries = state.countries.byId;
  if (!countries) {
    return true;
  } else if (countries.isFetching) {
    return false;
  } else {
    return countries.didInvalidate;
  }
}

export function fetchCountriesIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchCountries(getState())) {
      return dispatch(fetchCountries(filtros));
    }
  };
}

//MODEL
export const REQUEST_COUNTRY = 'REQUEST_COUNTRY';
export const RECEIVE_COUNTRY = 'RECEIVE_COUNTRY';
export const INVALIDATE_COUNTRY = 'INVALIDATE_COUNTRY';
export const ERROR_COUNTRY = 'ERROR_COUNTRY';
export const RESET_COUNTRY = 'RESET_COUNTRY';

export function invalidateCountry() {
  return {
    type: INVALIDATE_COUNTRY
  };
}

function requestCountry() {
  return {
    type: REQUEST_COUNTRY
  };
}

export function receiveCountry(json) {
  return {
    type: RECEIVE_COUNTRY,
    country: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorCountry(error) {
  return {
    type: ERROR_COUNTRY,
    error: error
  };
}

export function fetchCountry(idCountry) {
  return dispatch => {
    dispatch(requestCountry());
    return countriesApi
      .getOne(idCountry)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveCountry(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(errorCountry(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorCountry(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_COUNTRY = 'RECEIVE_FILE_COUNTRY';

function receiveFileCountry(file) {
  return {
    type: RECEIVE_FILE_COUNTRY,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFileCountry(idCountry, filtros) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return countriesApi
      .getFile(idCountry, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFileCountry(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorCountry(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorCountry(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_COUNTRY = 'UPDATE_COUNTRY';
export const REQUEST_UPDATE_COUNTRY = 'REQUEST_UPDATE_COUNTRY';
export const SUCCESS_UPDATE_COUNTRY = 'SUCCESS_UPDATE_COUNTRY';
export const ERROR_UPDATE_COUNTRY = 'ERROR_UPDATE_COUNTRY';
export const RESET_UPDATE_COUNTRY = 'RESET_UPDATE_COUNTRY';
export const DELETE_UPDATE_COUNTRY = 'DELETE_UPDATE_COUNTRY';

function requestUpdateCountry() {
  return {
    type: REQUEST_UPDATE_COUNTRY
  };
}

function receiveUpdateCountry(country) {
  return {
    type: SUCCESS_UPDATE_COUNTRY,
    receivedAt: Date.now(),
    country: normalizeDato(country)
  };
}

function errorUpdateCountry(error) {
  return {
    type: ERROR_UPDATE_COUNTRY,
    error: error
  };
}

export function resetUpdateCountry() {
  return {
    type: RESET_UPDATE_COUNTRY
  };
}

export function updateCountry(country) {
  return {
    type: UPDATE_COUNTRY,
    country
  };
}

export function saveUpdateCountry() {
  return (dispatch, getState) => {
    dispatch(requestUpdateCountry());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let country = denormalizeDato(getState().countries.update.activo, store);

    return countriesApi
      .saveUpdate(country)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateCountry(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateCountry(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateCountries(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdateCountries(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateCountry(JSON.parse(error.message)));
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdateCountry(error.data));
                })
                .catch(() => {
                  dispatch(errorUpdateCountry(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

export function deleteUpdateCountry(country) {
  return {
    type: DELETE_UPDATE_COUNTRY,
    country
  };
}

//UPDATE COUNTRIES
export const REQUEST_UPDATE_COUNTRIES = 'REQUEST_UPDATE_COUNTRIES';
export const SUCCESS_UPDATE_COUNTRIES = 'SUCCESS_UPDATE_COUNTRIES';
export const ERROR_UPDATE_COUNTRIES = 'ERROR_UPDATE_COUNTRIES';
export const RESET_UPDATE_COUNTRIES = 'RESET_UPDATE_COUNTRIES';

function requestUpdateCountries() {
  return {
    type: REQUEST_UPDATE_COUNTRIES
  };
}

function receiveUpdateCountries(countries) {
  return {
    type: SUCCESS_UPDATE_COUNTRIES,
    receivedAt: Date.now(),
    countries: normalizeDatos(countries)
  };
}

function errorUpdateCountries(error) {
  return {
    type: ERROR_UPDATE_COUNTRIES,
    error: error
  };
}

export function resetUpdateCountries() {
  return {
    type: RESET_UPDATE_COUNTRIES
  };
}

export function saveUpdateCountries() {
  return (dispatch, getState) => {
    dispatch(requestUpdateCountries());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let countries = getState().countries.update.activos.map(idCountry => {
      return denormalizeDato(
        getState().countries.update.activo[idCountry],
        store
      );
    });

    return countriesApi
      .saveUpdateCountries(countries)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateCountries(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateCountries(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateCountries(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdateCountries(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateCountries(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorUpdateCountries(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//ALTA COUNTRY
export const CREATE_COUNTRY = 'CREATE_COUNTRY';
export const REQUEST_CREATE_COUNTRY = 'REQUEST_CREATE_COUNTRY';
export const SUCCESS_CREATE_COUNTRY = 'SUCCESS_CREATE_COUNTRY';
export const ERROR_CREATE_COUNTRY = 'ERROR_CREATE_COUNTRY';
export const RESET_CREATE_COUNTRY = 'RESET_CREATE_COUNTRY';
export const DELETE_CREATE_COUNTRY = 'DELETE_CREATE_COUNTRY';

//ALTA COUNTRY
function requestCreateCountry() {
  return {
    type: REQUEST_CREATE_COUNTRY
  };
}

function receiveCreateCountry(country) {
  return {
    type: SUCCESS_CREATE_COUNTRY,
    receivedAt: Date.now(),
    country: normalizeDato(country)
  };
}

export function errorCreateCountry(error) {
  return {
    type: ERROR_CREATE_COUNTRY,
    error: error
  };
}

export function resetCreateCountry() {
  return {
    type: RESET_CREATE_COUNTRY
  };
}

export function createCountry(country) {
  return {
    type: CREATE_COUNTRY,
    country
  };
}

export function deleteCreateCountry(country) {
  return {
    type: DELETE_CREATE_COUNTRY,
    country
  };
}

export function saveCreateCountry() {
  return (dispatch, getState) => {
    dispatch(requestCreateCountry());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let country = denormalizeDato(getState().countries.create.nuevo, store);

    return countriesApi
      .saveCreate(country)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateCountry(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateCountry(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateCountries(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreateCountries(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateCountry(JSON.parse(error.message)));
                  if (error.data) dispatch(receiveCreateCountry(error.data));
                })
                .catch(() => {
                  dispatch(errorCreateCountry(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//CREATE COUNTRIES
export const REQUEST_CREATE_COUNTRIES = 'REQUEST_CREATE_COUNTRIES';
export const SUCCESS_CREATE_COUNTRIES = 'SUCCESS_CREATE_COUNTRIES';
export const ERROR_CREATE_COUNTRIES = 'ERROR_CREATE_COUNTRIES';
export const RESET_CREATE_COUNTRIES = 'RESET_CREATE_COUNTRIES';

function requestCreateCountries() {
  return {
    type: REQUEST_CREATE_COUNTRIES
  };
}

function receiveCreateCountries(countries) {
  return {
    type: SUCCESS_CREATE_COUNTRIES,
    receivedAt: Date.now(),
    countries: normalizeDatos(countries)
  };
}

function errorCreateCountries(error) {
  return {
    type: ERROR_CREATE_COUNTRIES,
    error: error
  };
}

export function resetCreateCountries() {
  return {
    type: RESET_CREATE_COUNTRIES
  };
}

export function saveCreateCountries() {
  return (dispatch, getState) => {
    dispatch(requestCreateCountries());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let countries = getState().countries.create.nuevos.map(idCountry => {
      return denormalizeDato(
        getState().countries.create.nuevo[idCountry],
        store
      );
    });

    return countriesApi
      .saveCreateCountries(countries)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateCountries(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateCountries(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateCountries(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreateCountries(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateCountries(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorCreateCountries(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//DELETE COUNTRY
export const DELETE_COUNTRY = 'DELETE_COUNTRY';
export const REQUEST_DELETE_COUNTRY = 'REQUEST_DELETE_COUNTRY';
export const SUCCESS_DELETE_COUNTRY = 'SUCCESS_DELETE_COUNTRY';
export const ERROR_DELETE_COUNTRY = 'ERROR_DELETE_COUNTRY';
export const RESET_DELETE_COUNTRY = 'RESET_DELETE_COUNTRY';

function requestDeleteCountry() {
  return {
    type: REQUEST_DELETE_COUNTRY
  };
}

function receiveDeleteCountry(country) {
  return {
    type: SUCCESS_DELETE_COUNTRY,
    receivedAt: Date.now(),
    country: normalizeDato(country)
  };
}

function errorDeleteCountry(error) {
  return {
    type: ERROR_DELETE_COUNTRY,
    error: error
  };
}

export function resetDeleteCountry(error) {
  return {
    type: RESET_DELETE_COUNTRY,
    error: error
  };
}

export function deleteCountry(country) {
  return {
    type: DELETE_COUNTRY,
    country
  };
}

export function saveDeleteCountry(country) {
  return dispatch => {
    dispatch(requestDeleteCountry());
    return countriesApi
      .saveDelete(country)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeleteCountry());
        dispatch(receiveDeleteCountry(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorDeleteCountry(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeleteCountry(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorDeleteCountry(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorDeleteCountry(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorDeleteCountry(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT COUNTRY
export const PRINT_COUNTRY = 'PRINT_COUNTRY';
export const REQUEST_PRINT_COUNTRY = 'REQUEST_PRINT_COUNTRY';
export const SUCCESS_PRINT_COUNTRY = 'SUCCESS_PRINT_COUNTRY';
export const ERROR_PRINT_COUNTRY = 'ERROR_PRINT_COUNTRY';
export const RESET_PRINT_COUNTRY = 'RESET_PRINT_COUNTRY';
export const DELETE_PRINT_COUNTRY = 'DELETE_PRINT_COUNTRY';

function requestPrintCountry() {
  return {
    type: REQUEST_PRINT_COUNTRY
  };
}

function receivePrintCountry(turnos) {
  return {
    type: SUCCESS_PRINT_COUNTRY,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintCountry(error) {
  return {
    type: ERROR_PRINT_COUNTRY,
    error: error
  };
}

export function resetPrintCountry() {
  return {
    type: RESET_PRINT_COUNTRY
  };
}

export function printCountry(country) {
  return {
    type: PRINT_COUNTRY,
    country
  };
}

export function deletePrintCountry(country) {
  return {
    type: DELETE_PRINT_COUNTRY,
    country
  };
}

export function savePrintCountry(idCountry) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintCountry());
    return countriesApi
      .printCountry(idCountry)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintCountry(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintCountry(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintCountry(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintCountry(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintCountry(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT COUNTRIES
export const REQUEST_PRINT_COUNTRIES = 'REQUEST_PRINT_COUNTRIES';
export const SUCCESS_PRINT_COUNTRIES = 'SUCCESS_PRINT_COUNTRIES';
export const ERROR_PRINT_COUNTRIES = 'ERROR_PRINT_COUNTRIES';
export const RESET_PRINT_COUNTRIES = 'RESET_PRINT_COUNTRIES';

function requestPrintCountries() {
  return {
    type: REQUEST_PRINT_COUNTRIES
  };
}

function receivePrintCountries(countries) {
  return {
    type: SUCCESS_PRINT_COUNTRIES,
    receivedAt: Date.now(),
    countries: normalizeDatos(countries)
  };
}

function errorPrintCountries(error) {
  return {
    type: ERROR_PRINT_COUNTRIES,
    error: error
  };
}

export function resetPrintCountries() {
  return {
    type: RESET_PRINT_COUNTRIES
  };
}

export function savePrintCountries() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintCountry());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let countries = getState().countries.print.printers.map(idCountry => {
      return denormalizeDato(
        getState().countries.print.print[idCountry],
        store
      );
    });
    return countriesApi
      .printCountries(countries)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintCountries(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintCountries(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintCountries(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintCountries(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintCountries(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}
