import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useParams } from 'react-router-dom';
//Components
import {
  Box,
  Breadcrumbs,
  Chip,
  Container,
  FormControlLabel,
  MenuItem,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import CustomPaper from '../../library/CustomPaper/CustomPaper';
import PaperHeader from '../../library/CustomPaper/PaperHeader';
import ContainerBtn from '../../library/Buttons/ContainerBtn';
import BtnSave from '../../library/Buttons/BtnSave';
import BtnCancel from '../../library/Buttons/BtnCancel';
import Grid from '@material-ui/core/Grid';
import PaperBody from '../../library/CustomPaper/PaperBody';
//Img
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
//Hooks
import { useValidador } from '../../utils/validador';
import { useDispatch, useSelector } from 'react-redux';
//Actions
import {
  createPost,
  fetchPost,
  saveCreatePost,
  saveUpdatePost,
  updatePost
} from '../../actions/PostActions';

//reset
import postReset from '../../reset/postReset';
import ImageFilePost from './ImageFilePost';
import assetReset from '../../reset/assetReset';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import EmbeddedAssetsList from '../Assets/EmbeddedAssetsList';
import { useHistory, useLocation } from 'react-router';
import PostLanguage from './PostLanguage';

export default function CRUDPost(props) {
  const dispatch = useDispatch();
  let { idPost } = useParams();
  const mounted = useRef();

  const history = useHistory();
  let location = useLocation();
  let path = location.pathname.indexOf('jobs') === -1 ? '/posts' + '' : '/jobs';

  //Store
  const posts = useSelector(state => state.posts);
  const post = idPost ? posts.update.activo : posts.create.nuevo;

  //State

  const [files, setFiles] = useState([]);

  const guardandoLoading = idPost
    ? posts.update.isUpdating
    : posts.create.isCreating;
  const disabled =
    (idPost ? posts.update.isUpdating : posts.create.isCreating) ||
    posts.byId.isFetching;
  const error = idPost ? posts.update.error : posts.create.error;

  //Hooks
  let reglas = {};
  reglas = {
    namePost: ['required'],
    type: ['required'],
    dateEvent: ['sometimes|required']
  };
  const [validador, validar] = useValidador(reglas);

  //Effects
  useEffect(() => {
    window.scrollTo(0, 0);
    if (idPost) dispatch(fetchPost(idPost));

    return function cleanup() {
      postReset.resetAll(dispatch);
      assetReset.resetAll(dispatch);
    };
  }, [idPost]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (!posts.create.isCreating && !posts.create.error) {
        if (posts.create.nuevo.id)
          history.push(path + '/' + posts.create.nuevo.id);
        postReset.resetAll(dispatch);
        assetReset.resetAll(dispatch);
        setFiles([]);
        window.scrollTo(0, 0);
      }
    }
  }, [posts.create.isCreating]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (!posts.update.isUpdating && !posts.update.error) {
        setFiles([]);
      }
    }
  }, [posts.update.isUpdating]);

  //Post
  const handleChangePost = e => {
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    changePost(cambio);
  };

  const handleChangePostDate = (date, id) => {
    let cambio = {};
    if (date && date.isValid()) {
      cambio[id] = date ? moment(date).format('YYYY-MM-DD') : null;
      changePost(cambio);
    }
  };

  const handleChangePostTime = (date, id) => {
    let cambio = {};
    if (date && date.isValid()) {
      cambio[id] = date ? moment(date).format('HH:mm') : null;
      changePost(cambio);
    }
  };

  const changePost = cambio => {
    if (idPost) dispatch(updatePost(cambio));
    else dispatch(createPost(cambio));
  };

  const handleChangeStatusPost = () => {
    let cambio = {};
    cambio.status = !post.status;
    changePost(cambio);
  };

  //Submit
  const onSubmit = e => {
    e.preventDefault();
    if (validador.isValid()) {
      if (idPost) dispatch(saveUpdatePost(files));
      else dispatch(saveCreatePost(files));
    }
  };

  return (
    <Container maxWidth={'xg'}>
      <Breadcrumbs aria-label="breadcrumb" separator={'‣'}>
        <Link color="inherit" to="/">
          Home
        </Link>
        <Link color="inherit" to={path}>
          {location.pathname.indexOf('jobs') === -1 ? 'Posteos' : 'Empleos'}
        </Link>
      </Breadcrumbs>
      <Typography component={'h1'} variant={'h1'}>
        {location.pathname.indexOf('jobs') === -1
          ? !idPost
            ? 'Nuevo posteo'
            : 'Modificar posteo'
          : !idPost
          ? 'Nuevo empleo'
          : 'Modificar empleo'}
      </Typography>
      <form onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item md={6} sm={12} xs={12}>
            <CustomPaper>
              <PaperHeader>
                <Typography variant={'overline'} component={'span'}>
                  Datos del posteo
                </Typography>
              </PaperHeader>
              <PaperBody spacing={2}>
                <Grid item md={6} sm={12} xs={12}>
                  <ImageFilePost
                    files={files}
                    setFiles={setFiles}
                    changePost={changePost}
                    file={post.path}
                    disabled={disabled}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  sm={12}
                  xs={12}
                  style={{ marginBottom: '15px' }}
                >
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ marginBottom: '15px' }}
                  >
                    <TextField
                      id="namePost"
                      placeholder="Nombre"
                      variant="outlined"
                      label={'Nombre *'}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        disabled: disabled,
                        type: 'text'
                      }}
                      disabled={disabled}
                      fullWidth
                      value={post && post.namePost ? post.namePost : ''}
                      onChange={handleChangePost}
                      onBlur={validar}
                      error={!validador.isValidById('namePost')}
                      helperText={validador.getHelperTextById('namePost')}
                    />
                  </Grid>
                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ marginBottom: '15px' }}
                  >
                    <TextField
                      id={'type'}
                      select
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                      label={'Tipo posteo *'}
                      placeholder={'Seleccione tipo de posteo'}
                      value={post && post.type ? post.type : ''}
                      onChange={e => handleChangePost(e)}
                      InputProps={{
                        disabled: disabled,
                        id: 'type',
                        name: 'type'
                      }}
                      disabled={disabled}
                      variant="outlined"
                      onBlur={validador.validar}
                      error={!validador.isValidById('type')}
                      helperText={validador.getHelperTextById('type')}
                    >
                      {location.pathname.indexOf('jobs') === -1 && (
                        <MenuItem value={'Novedad'}>Novedad</MenuItem>
                      )}
                      {location.pathname.indexOf('jobs') === -1 && (
                        <MenuItem value={'Evento'}>Evento</MenuItem>
                      )}
                      {location.pathname.indexOf('jobs') === -1 && (
                        <MenuItem value={'Artículo'}>Artículo</MenuItem>
                      )}
                    </TextField>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box display={'flex'} justifyContent={'flex-end'}>
                      {((!post.dateTo &&
                        moment(post.dateFrom).isSameOrBefore(moment())) ||
                        moment().isBetween(
                          moment(post.dateFrom),
                          moment(post.dateTo)
                        )) &&
                        post.status === 1 && (
                          <Chip
                            icon={<DoneIcon />}
                            label="Vigente"
                            color="primary"
                            variant="outlined"
                            style={{ marginBottom: '20px' }}
                          />
                        )}
                      {!(
                        (!post.dateTo &&
                          moment(post.dateFrom).isSameOrBefore(moment())) ||
                        moment().isBetween(
                          moment(post.dateFrom),
                          moment(post.dateTo)
                        )
                      ) && (
                        <Chip
                          icon={<CloseIcon />}
                          label="Fuera de fecha"
                          color="secondary"
                          variant="outlined"
                          style={{ marginBottom: '20px' }}
                        />
                      )}
                      {post.status === 0 && (
                        <Chip
                          icon={<CloseIcon />}
                          label="Desactivado"
                          color="secondary"
                          variant="outlined"
                          style={{ marginBottom: '20px' }}
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    variant="inline"
                    inputVariant="outlined"
                    label="Fecha desde publicación"
                    format="DD/MM/yyyy"
                    disabled={disabled}
                    InputAdornmentProps={{ position: 'end' }}
                    value={post && post.dateFrom ? post.dateFrom : null}
                    onChange={date => handleChangePostDate(date, 'dateFrom')}
                  />
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    variant="inline"
                    inputVariant="outlined"
                    label="Fecha hasta publicación"
                    format="DD/MM/yyyy"
                    disabled={disabled}
                    InputAdornmentProps={{ position: 'end' }}
                    value={post && post.dateTo ? post.dateTo : null}
                    onChange={date => handleChangePostDate(date, 'dateTo')}
                  />
                </Grid>
                {post.type === 'Evento' && (
                  <>
                    <Grid item md={6} sm={12} xs={12}>
                      <KeyboardDatePicker
                        id={'dateEvent'}
                        autoOk
                        fullWidth
                        variant="inline"
                        inputVariant="outlined"
                        label="Fecha evento"
                        format="DD/MM/yyyy"
                        disabled={disabled}
                        InputAdornmentProps={{ position: 'end' }}
                        value={post && post.dateEvent ? post.dateEvent : null}
                        onChange={date =>
                          handleChangePostDate(date, 'dateEvent')
                        }
                        onBlur={validador.validar}
                        error={!validador.isValidById('dateEvent')}
                        helperText={validador.getHelperTextById('dateEvent')}
                      />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <KeyboardTimePicker
                        ampm={false}
                        id={'timeEvent'}
                        autoOk
                        fullWidth
                        variant="inline"
                        inputVariant="outlined"
                        label="Hora evento"
                        format="HH:mm"
                        disabled={disabled}
                        mask="__:__"
                        InputAdornmentProps={{ position: 'end' }}
                        value={
                          post && post.timeEvent
                            ? moment(post.timeEvent, 'HH:mm')
                            : null
                        }
                        onChange={date =>
                          handleChangePostTime(date, 'timeEvent')
                        }
                        onBlur={validador.validar}
                        error={!validador.isValidById('timeEvent')}
                        helperText={validador.getHelperTextById('timeEvent')}
                      />
                    </Grid>
                    <TextField
                      id="location"
                      margin={'normal'}
                      placeholder="Ubicación física o virtual"
                      variant="outlined"
                      label={'Ubicación'}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        disabled: disabled,
                        type: 'text'
                      }}
                      disabled={disabled}
                      fullWidth
                      value={post && post.location ? post.location : ''}
                      onChange={handleChangePost}
                      onBlur={validar}
                      error={!validador.isValidById('location')}
                      helperText={validador.getHelperTextById('location')}
                    />
                  </>
                )}
                {post.type === 'Empleo' && (
                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      id="location"
                      margin={'normal'}
                      placeholder="Ubicación del puesto"
                      variant="outlined"
                      label={'Ubicación'}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        disabled: disabled,
                        type: 'text'
                      }}
                      disabled={disabled}
                      fullWidth
                      value={post && post.location ? post.location : ''}
                      onChange={handleChangePost}
                      onBlur={validar}
                      error={!validador.isValidById('location')}
                      helperText={validador.getHelperTextById('location')}
                    />
                    <TextField
                      id="link"
                      margin={'normal'}
                      placeholder="Link de LinkedIn"
                      variant="outlined"
                      label={'LinkedIn'}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        disabled: disabled,
                        type: 'text'
                      }}
                      disabled={disabled}
                      fullWidth
                      value={post && post.link ? post.link : ''}
                      onChange={handleChangePost}
                      onBlur={validar}
                      error={!validador.isValidById('link')}
                      helperText={validador.getHelperTextById('link')}
                    />
                  </Grid>
                )}

                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  style={{
                    visibility: !idPost ? 'hidden' : 'inherit',
                    marginTop: '14px'
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={post && !!post.status}
                        onChange={() => handleChangeStatusPost()}
                        disabled={disabled}
                        name="estado"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                    }
                    label="Estado"
                  />
                </Grid>
              </PaperBody>
            </CustomPaper>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <CustomPaper>
              <PaperHeader>
                <Typography variant={'overline'} component={'span'}>
                  Autor
                </Typography>
              </PaperHeader>
              <PaperBody>
                <Grid container spacing={2}>
                  <Grid item md={6} sm={12} xs={12}>
                    <TextField
                      id="nameAuthor"
                      margin={'normal'}
                      placeholder="Nombre"
                      variant="outlined"
                      label={'Nombre'}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        disabled: disabled,
                        type: 'text'
                      }}
                      disabled={disabled}
                      fullWidth
                      value={post && post.nameAuthor ? post.nameAuthor : ''}
                      onChange={handleChangePost}
                      onBlur={validar}
                      error={!validador.isValidById('nameAuthor')}
                      helperText={validador.getHelperTextById('nameAuthor')}
                    />
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <TextField
                      id="lastnameAuthor"
                      margin={'normal'}
                      placeholder="Apellido"
                      variant="outlined"
                      label={'Apellido'}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        disabled: disabled,
                        type: 'text'
                      }}
                      disabled={disabled}
                      fullWidth
                      value={
                        post && post.lastnameAuthor ? post.lastnameAuthor : ''
                      }
                      onChange={handleChangePost}
                      onBlur={validar}
                      error={!validador.isValidById('lastnameAuthor')}
                      helperText={validador.getHelperTextById('lastnameAuthor')}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <TextField
                      id="contactAuthor"
                      margin={'normal'}
                      placeholder="Contacto"
                      variant="outlined"
                      label={'Contacto'}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        disabled: disabled,
                        type: 'text'
                      }}
                      disabled={disabled}
                      fullWidth
                      value={
                        post && post.contactAuthor ? post.contactAuthor : ''
                      }
                      onChange={handleChangePost}
                      onBlur={validar}
                      error={!validador.isValidById('contactAuthor')}
                      helperText={validador.getHelperTextById('contactAuthor')}
                    />
                    <TextField
                      id="positionAuthor"
                      margin={'normal'}
                      placeholder="Ocupación/Puesto"
                      variant="outlined"
                      label={'Ocupación/Puesto'}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        disabled: disabled,
                        type: 'text'
                      }}
                      disabled={disabled}
                      fullWidth
                      value={
                        post && post.positionAuthor ? post.positionAuthor : ''
                      }
                      onChange={handleChangePost}
                      onBlur={validar}
                      error={!validador.isValidById('positionAuthor')}
                      helperText={validador.getHelperTextById('positionAuthor')}
                    />
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <PostLanguage
                      post={post}
                      handleChangePost={handleChangePost}
                      changePost={changePost}
                    />
                  </Grid>
                </Grid>
              </PaperBody>
            </CustomPaper>
          </Grid>
        </Grid>
        <ContainerBtn>
          <BtnCancel onClick={() => props.history.push(path)} />
          <BtnSave
            loading={guardandoLoading}
            disabled={disabled}
            error={error}
          />
        </ContainerBtn>
      </form>
      <EmbeddedAssetsList
        post={post}
        handleChangePost={handleChangePost}
        changePost={changePost}
        files={files}
        setFiles={setFiles}
      />
    </Container>
  );
}
