import {
  INVALIDATE_PROPERTIESATTRIBUTESVALUES,
  ERROR_PROPERTIESATTRIBUTESVALUES,
  RECEIVE_PROPERTIESATTRIBUTESVALUES,
  REQUEST_PROPERTIESATTRIBUTESVALUES,
  RESET_PROPERTIESATTRIBUTESVALUES,
  ERROR_PROPERTYATTRIBUTEVALUE,
  RECEIVE_PROPERTYATTRIBUTEVALUE,
  REQUEST_PROPERTYATTRIBUTEVALUE,
  UPDATE_PROPERTYATTRIBUTEVALUE,
  REQUEST_UPDATE_PROPERTYATTRIBUTEVALUE,
  SUCCESS_UPDATE_PROPERTYATTRIBUTEVALUE,
  ERROR_UPDATE_PROPERTYATTRIBUTEVALUE,
  RESET_UPDATE_PROPERTYATTRIBUTEVALUE,
  REQUEST_UPDATE_PROPERTIESATTRIBUTESVALUES,
  SUCCESS_UPDATE_PROPERTIESATTRIBUTESVALUES,
  ERROR_UPDATE_PROPERTIESATTRIBUTESVALUES,
  RESET_UPDATE_PROPERTIESATTRIBUTESVALUES,
  CREATE_PROPERTYATTRIBUTEVALUE,
  ERROR_CREATE_PROPERTYATTRIBUTEVALUE,
  REQUEST_CREATE_PROPERTYATTRIBUTEVALUE,
  RESET_CREATE_PROPERTYATTRIBUTEVALUE,
  SUCCESS_CREATE_PROPERTYATTRIBUTEVALUE,
  REQUEST_CREATE_PROPERTIESATTRIBUTESVALUES,
  SUCCESS_CREATE_PROPERTIESATTRIBUTESVALUES,
  ERROR_CREATE_PROPERTIESATTRIBUTESVALUES,
  RESET_CREATE_PROPERTIESATTRIBUTESVALUES,
  DELETE_PROPERTYATTRIBUTEVALUE,
  DELETE_CREATE_PROPERTYATTRIBUTEVALUE,
  DELETE_UPDATE_PROPERTYATTRIBUTEVALUE,
  REQUEST_DELETE_PROPERTYATTRIBUTEVALUE,
  SUCCESS_DELETE_PROPERTYATTRIBUTEVALUE,
  ERROR_DELETE_PROPERTYATTRIBUTEVALUE,
  RESET_DELETE_PROPERTYATTRIBUTEVALUE,
  REQUEST_PRINT_PROPERTYATTRIBUTEVALUE,
  SUCCESS_PRINT_PROPERTYATTRIBUTEVALUE,
  ERROR_PRINT_PROPERTYATTRIBUTEVALUE,
  RESET_PRINT_PROPERTYATTRIBUTEVALUE,
  RECEIVE_FILE_PROPERTYATTRIBUTEVALUE,
  REQUEST_PRINT_PROPERTIESATTRIBUTESVALUES,
  SUCCESS_PRINT_PROPERTIESATTRIBUTESVALUES,
  ERROR_PRINT_PROPERTIESATTRIBUTESVALUES,
  RESET_PRINT_PROPERTIESATTRIBUTESVALUES,
  RECEIVE_FILE_PROPERTIESATTRIBUTESVALUES,
  PRINT_PROPERTYATTRIBUTEVALUE,
  DELETE_PRINT_PROPERTYATTRIBUTEVALUE
} from '../actions/PropertyAttributeValueActions';

import {
  RECEIVE_PROPERTY,
  RECEIVE_PROPERTIES,
  SUCCESS_DELETE_PROPERTY,
  SUCCESS_CREATE_PROPERTY,
  SUCCESS_UPDATE_PROPERTY,
  SUCCESS_UPDATE_PROPERTIES
} from '../actions/PropertyActions';
import {
  RECEIVE_TICKET,
  RECEIVE_TICKETS,
  SUCCESS_DELETE_TICKET,
  SUCCESS_CREATE_TICKET,
  SUCCESS_UPDATE_TICKET,
  SUCCESS_UPDATE_TICKETS
} from '../actions/TicketActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    propertiesAttributesValues: {},
    files: {}
  };
}

function propertiesAttributesValuesById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_PROPERTIESATTRIBUTESVALUES:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_PROPERTIESATTRIBUTESVALUES:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_PROPERTIESATTRIBUTESVALUES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_PROPERTIESATTRIBUTESVALUES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        propertiesAttributesValues: {}
      });
    case RECEIVE_PROPERTIESATTRIBUTESVALUES:
      let dato =
        action.propertiesAttributesValues.entities.propertiesAttributesValues;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        propertiesAttributesValues: merge(
          {},
          state.propertiesAttributesValues,
          dato
        ),
        lastUpdated: action.receivedAt
      });
    case REQUEST_PROPERTYATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_PROPERTYATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_PROPERTYATTRIBUTEVALUE:
      let datoPropertyAttributeValue =
        action.propertyAttributeValue.entities.propertiesAttributesValues;
      return Object.assign({}, state, {
        propertiesAttributesValues: merge(
          {},
          state.propertiesAttributesValues,
          datoPropertyAttributeValue
        ),
        isFetching: false
      });
    case RECEIVE_FILE_PROPERTYATTRIBUTEVALUE:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_PROPERTYATTRIBUTEVALUE:
      let datoPropertyAttributeValueEliminado =
        action.propertyAttributeValue.entities.propertiesAttributesValues;
      return Object.assign({}, state, {
        propertiesAttributesValues: mergeWith(
          clone(datoPropertyAttributeValueEliminado),
          state.propertiesAttributesValues,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_PROPERTYATTRIBUTEVALUE:
      let datoPropertyAttributeValueCreado =
        action.propertyAttributeValue.entities.propertiesAttributesValues;
      return Object.assign({}, state, {
        propertiesAttributesValues: mergeWith(
          clone(datoPropertyAttributeValueCreado),
          state.propertiesAttributesValues,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_PROPERTIESATTRIBUTESVALUES:
      let datosPropertyAttributeValueCreado =
        action.propertiesAttributesValues.entities.propertiesAttributesValues;
      return Object.assign({}, state, {
        propertiesAttributesValues: mergeWith(
          clone(datosPropertyAttributeValueCreado),
          state.propertiesAttributesValues,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTYATTRIBUTEVALUE:
      let datoPropertyAttributeValueActualizado =
        action.propertyAttributeValue.entities.propertiesAttributesValues;
      return Object.assign({}, state, {
        propertiesAttributesValues: mergeWith(
          clone(datoPropertyAttributeValueActualizado),
          state.propertiesAttributesValues,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTIESATTRIBUTESVALUES:
      let datosPropertyAttributeValueActualizado =
        action.propertiesAttributesValues.entities.propertiesAttributesValues;
      return Object.assign({}, state, {
        propertiesAttributesValues: mergeWith(
          clone(datosPropertyAttributeValueActualizado),
          state.propertiesAttributesValues,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    //PROPERTY
    case RECEIVE_PROPERTY:
      let property =
        action.property.entities &&
        action.property.entities.propertiesAttributesValues
          ? action.property.entities.propertiesAttributesValues
          : {};
      return Object.assign({}, state, {
        propertiesAttributesValues: merge(
          {},
          state.propertiesAttributesValues,
          property
        )
      });
    case RECEIVE_PROPERTIES:
      let properties =
        action.properties.entities &&
        action.properties.entities.propertiesAttributesValues
          ? action.properties.entities.propertiesAttributesValues
          : {};
      return Object.assign({}, state, {
        propertiesAttributesValues: merge(
          {},
          state.propertiesAttributesValues,
          properties
        )
      });
    case SUCCESS_DELETE_PROPERTY:
      let datopropertyEliminado =
        action.property.entities &&
        action.property.entities.propertiesAttributesValues
          ? action.property.entities.propertiesAttributesValues
          : {};
      return Object.assign({}, state, {
        propertiesAttributesValues: mergeWith(
          clone(datopropertyEliminado),
          pickBy(
            state.propertiesAttributesValues,
            function (propertyAttributeValue) {
              return propertyAttributeValue.id.toString().indexOf('-') === -1;
            }
          ),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_PROPERTY:
      let datopropertyCreado =
        action.property.entities &&
        action.property.entities.propertiesAttributesValues
          ? action.property.entities.propertiesAttributesValues
          : {};
      return Object.assign({}, state, {
        propertiesAttributesValues: mergeWith(
          clone(datopropertyCreado),
          pickBy(
            state.propertiesAttributesValues,
            function (propertyAttributeValue) {
              return propertyAttributeValue.id.toString().indexOf('-') === -1;
            }
          ),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTY:
      let datopropertyActualizado =
        action.property.entities &&
        action.property.entities.propertiesAttributesValues
          ? action.property.entities.propertiesAttributesValues
          : {};
      return Object.assign({}, state, {
        propertiesAttributesValues: mergeWith(
          clone(datopropertyActualizado),
          pickBy(
            state.propertiesAttributesValues,
            function (propertyAttributeValue) {
              return propertyAttributeValue.id.toString().indexOf('-') === -1;
            }
          ),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTIES:
      let datospropertyActualizado =
        action.properties.entities &&
        action.properties.entities.propertiesAttributesValues
          ? action.properties.entities.propertiesAttributesValues
          : {};
      return Object.assign({}, state, {
        propertiesAttributesValues: mergeWith(
          clone(datospropertyActualizado),
          state.propertiesAttributesValues,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    //TICKET
    case RECEIVE_TICKET:
      let ticket =
        action.ticket.entities &&
        action.ticket.entities.propertiesAttributesValues
          ? action.ticket.entities.propertiesAttributesValues
          : {};
      return Object.assign({}, state, {
        propertiesAttributesValues: merge(
          {},
          state.propertiesAttributesValues,
          ticket
        )
      });
    case RECEIVE_TICKETS:
      let tickets =
        action.tickets.entities &&
        action.tickets.entities.propertiesAttributesValues
          ? action.tickets.entities.propertiesAttributesValues
          : {};
      return Object.assign({}, state, {
        propertiesAttributesValues: merge(
          {},
          state.propertiesAttributesValues,
          tickets
        )
      });
    case SUCCESS_DELETE_TICKET:
      let datoticketEliminado =
        action.ticket.entities &&
        action.ticket.entities.propertiesAttributesValues
          ? action.ticket.entities.propertiesAttributesValues
          : {};
      return Object.assign({}, state, {
        propertiesAttributesValues: mergeWith(
          clone(datoticketEliminado),
          pickBy(
            state.propertiesAttributesValues,
            function (propertyAttributeValue) {
              return propertyAttributeValue.id.toString().indexOf('-') === -1;
            }
          ),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_TICKET:
      let datoticketCreado =
        action.ticket.entities &&
        action.ticket.entities.propertiesAttributesValues
          ? action.ticket.entities.propertiesAttributesValues
          : {};
      return Object.assign({}, state, {
        propertiesAttributesValues: mergeWith(
          clone(datoticketCreado),
          pickBy(
            state.propertiesAttributesValues,
            function (propertyAttributeValue) {
              return propertyAttributeValue.id.toString().indexOf('-') === -1;
            }
          ),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TICKET:
      let datoticketActualizado =
        action.ticket.entities &&
        action.ticket.entities.propertiesAttributesValues
          ? action.ticket.entities.propertiesAttributesValues
          : {};
      return Object.assign({}, state, {
        propertiesAttributesValues: mergeWith(
          clone(datoticketActualizado),
          pickBy(
            state.propertiesAttributesValues,
            function (propertyAttributeValue) {
              return propertyAttributeValue.id.toString().indexOf('-') === -1;
            }
          ),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TICKETS:
      let datosticketActualizado =
        action.tickets.entities &&
        action.tickets.entities.propertiesAttributesValues
          ? action.tickets.entities.propertiesAttributesValues
          : {};
      return Object.assign({}, state, {
        propertiesAttributesValues: mergeWith(
          clone(datosticketActualizado),
          state.propertiesAttributesValues,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        propertiesAttributesValues: {}
      });
    default:
      return state;
  }
}

function allPropertiesAttributesValues(state = [], action) {
  switch (action.type) {
    case RECEIVE_PROPERTIESATTRIBUTESVALUES:
      return action.propertiesAttributesValues.result &&
        action.propertiesAttributesValues.result.propertiesAttributesValues
        ? union(
            action.propertiesAttributesValues.result.propertiesAttributesValues,
            state
          )
        : action.propertiesAttributesValues.result
        ? action.propertiesAttributesValues.result
        : state;
    case RECEIVE_PROPERTYATTRIBUTEVALUE:
      return action.propertyAttributeValue.result
        ? union([action.propertyAttributeValue.result], state)
        : state;

    case SUCCESS_CREATE_PROPERTYATTRIBUTEVALUE:
      let datoPropertyAttributeValueSCreate =
        action.propertyAttributeValue.entities.propertiesAttributesValues;
      let idNuevoSCreate = null;
      if (Object.values(datoPropertyAttributeValueSCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoPropertyAttributeValueSCreate)[0] &&
          Object.values(datoPropertyAttributeValueSCreate)[0].id
            ? Object.values(datoPropertyAttributeValueSCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_PROPERTIESATTRIBUTESVALUES:
      let propertiesAttributesValuesCreate =
        action.propertiesAttributesValues.entities &&
        action.propertiesAttributesValues.entities.propertiesAttributesValues
          ? action.propertiesAttributesValues.entities
              .propertiesAttributesValues
          : null;
      return propertiesAttributesValuesCreate
        ? union(
            state,
            Object.values(propertiesAttributesValuesCreate).map(
              propertiesAttributesValues => {
                return propertiesAttributesValues.id;
              }
            )
          )
        : state;
    case RESET_PROPERTIESATTRIBUTESVALUES:
      return [];

    case RECEIVE_PROPERTY:
      let property =
        action.property.entities &&
        action.property.entities.propertiesAttributesValues
          ? action.property.entities.propertiesAttributesValues
          : null;
      return property
        ? union(
            state,
            Object.values(property).map(property => {
              return property.id;
            })
          )
        : state;
    case RECEIVE_PROPERTIES:
      let properties =
        action.properties.entities &&
        action.properties.entities.propertiesAttributesValues
          ? action.properties.entities.propertiesAttributesValues
          : null;
      return properties
        ? union(
            state,
            Object.values(properties).map(properties => {
              return properties.id;
            })
          )
        : state;

    case SUCCESS_DELETE_PROPERTY:
      let propertyDelete =
        action.property.entities &&
        action.property.entities.propertiesAttributesValues
          ? action.property.entities.propertiesAttributesValues
          : null;
      return propertyDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyDelete).map(property => {
              return property.id;
            })
          )
        : state;
    case SUCCESS_CREATE_PROPERTY:
      let propertyCreate =
        action.property.entities &&
        action.property.entities.propertiesAttributesValues
          ? action.property.entities.propertiesAttributesValues
          : null;
      return propertyCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyCreate).map(property => {
              return property.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_PROPERTY:
      let propertyUpdate =
        action.property.entities &&
        action.property.entities.propertiesAttributesValues
          ? action.property.entities.propertiesAttributesValues
          : null;
      return propertyUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyUpdate).map(property => {
              return property.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_PROPERTIES:
      let propertiesUpdate =
        action.properties.entities &&
        action.properties.entities.propertiesAttributesValues
          ? action.properties.entities.propertiesAttributesValues
          : null;
      return propertiesUpdate
        ? union(
            state,
            Object.values(propertiesUpdate).map(properties => {
              return properties.id;
            })
          )
        : state;
    case RECEIVE_TICKET:
      let ticket =
        action.ticket.entities &&
        action.ticket.entities.propertiesAttributesValues
          ? action.ticket.entities.propertiesAttributesValues
          : null;
      return ticket
        ? union(
            state,
            Object.values(ticket).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case RECEIVE_TICKETS:
      let tickets =
        action.tickets.entities &&
        action.tickets.entities.propertiesAttributesValues
          ? action.tickets.entities.propertiesAttributesValues
          : null;
      return tickets
        ? union(
            state,
            Object.values(tickets).map(tickets => {
              return tickets.id;
            })
          )
        : state;

    case SUCCESS_DELETE_TICKET:
      let ticketDelete =
        action.ticket.entities &&
        action.ticket.entities.propertiesAttributesValues
          ? action.ticket.entities.propertiesAttributesValues
          : null;
      return ticketDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(ticketDelete).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case SUCCESS_CREATE_TICKET:
      let ticketCreate =
        action.ticket.entities &&
        action.ticket.entities.propertiesAttributesValues
          ? action.ticket.entities.propertiesAttributesValues
          : null;
      return ticketCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(ticketCreate).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_TICKET:
      let ticketUpdate =
        action.ticket.entities &&
        action.ticket.entities.propertiesAttributesValues
          ? action.ticket.entities.propertiesAttributesValues
          : null;
      return ticketUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(ticketUpdate).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_TICKETS:
      let ticketsUpdate =
        action.tickets.entities &&
        action.tickets.entities.propertiesAttributesValues
          ? action.tickets.entities.propertiesAttributesValues
          : null;
      return ticketsUpdate
        ? union(
            state,
            Object.values(ticketsUpdate).map(tickets => {
              return tickets.id;
            })
          )
        : state;

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalPropertiesAttributesValues(state = null, action) {
  switch (action.type) {
    case RECEIVE_PROPERTIESATTRIBUTESVALUES:
      return action.propertiesAttributesValues &&
        action.propertiesAttributesValues.result.total
        ? action.propertiesAttributesValues.result.total
        : 0;
    case RESET_PROPERTIESATTRIBUTESVALUES:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_PROPERTYATTRIBUTEVALUE:
      let dato =
        action.propertyAttributeValue.entities.propertiesAttributesValues;
      let propertyAttributeValue =
        dato && Object.keys(dato).length > 0
          ? dato[action.propertyAttributeValue.result]
          : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: propertyAttributeValue ? propertyAttributeValue : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_PROPERTYATTRIBUTEVALUE:
      let idsUpdate = [];
      Object.values(action.propertyAttributeValue).map(
        propertyAttributeValueUpdate => {
          if (propertyAttributeValueUpdate && propertyAttributeValueUpdate.id)
            idsUpdate.push(propertyAttributeValueUpdate.id);
        }
      );
      return merge({}, state, {
        activo: action.propertyAttributeValue,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_PROPERTYATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_PROPERTYATTRIBUTEVALUE:
      let datoPropertyAttributeValueActualizado =
        action.propertyAttributeValue.entities.propertiesAttributesValues;
      let propertyAttributeValueNuevo =
        datoPropertyAttributeValueActualizado &&
        Object.keys(datoPropertyAttributeValueActualizado).length > 0
          ? datoPropertyAttributeValueActualizado[
              action.propertyAttributeValue.result
            ]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: propertyAttributeValueNuevo
      });
    case ERROR_UPDATE_PROPERTYATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_PROPERTIESATTRIBUTESVALUES:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_PROPERTIESATTRIBUTESVALUES:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_PROPERTIESATTRIBUTESVALUES:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_PROPERTYATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    //PROPERTY
    //TODO ver si esta bien
    case SUCCESS_CREATE_PROPERTY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_PROPERTY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      }); //TICKET
    //TODO ver si esta bien
    case SUCCESS_CREATE_TICKET:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_TICKET:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_TICKET:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_TICKETS:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });

    case DELETE_PROPERTYATTRIBUTEVALUE:
      let datoPropertyAttributeValueDelete = action.propertyAttributeValue;
      let idsDelete = [];
      Object.values(action.propertyAttributeValue).map(
        propertyAttributeValueDelete => {
          if (propertyAttributeValueDelete && propertyAttributeValueDelete.id)
            idsDelete.push(propertyAttributeValueDelete.id);
        }
      );
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoPropertyAttributeValueDelete)
          ),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_PROPERTYATTRIBUTEVALUE:
      let datoPropertyAttributeValueDeleteUpdate =
        action.propertyAttributeValue;
      let idsDeleteUpdate = [];
      Object.values(action.propertyAttributeValue).map(
        propertyAttributeValueDelete => {
          if (propertyAttributeValueDelete && propertyAttributeValueDelete.id)
            idsDeleteUpdate.push(propertyAttributeValueDelete.id);
        }
      );
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoPropertyAttributeValueDeleteUpdate)
          ),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_PROPERTYATTRIBUTEVALUE:
      let datoPropertyAttributeValueDeleted = {};
      if (
        Object.values(
          action.propertyAttributeValue.entities.propertiesAttributesValues
        ).length > 0
      )
        datoPropertyAttributeValueDeleted = Object.values(
          action.propertyAttributeValue.entities.propertiesAttributesValues
        )[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoPropertyAttributeValueDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_PROPERTYATTRIBUTEVALUE:
      let idsCreate = [];
      Object.values(action.propertyAttributeValue).map(
        propertyAttributeValueCreate => {
          if (propertyAttributeValueCreate && propertyAttributeValueCreate.id)
            idsCreate.push(propertyAttributeValueCreate.id);
        }
      );
      return merge({}, state, {
        isCreating: false,
        nuevo: action.propertyAttributeValue,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_PROPERTYATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_PROPERTYATTRIBUTEVALUE:
      let datoPropertyAttributeValueNuevo =
        action.propertyAttributeValue.entities.propertiesAttributesValues;
      let propertyAttributeValueNuevo =
        datoPropertyAttributeValueNuevo &&
        Object.keys(datoPropertyAttributeValueNuevo).length > 0
          ? datoPropertyAttributeValueNuevo[
              action.propertyAttributeValue.result
            ]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: propertyAttributeValueNuevo,
        nuevos: []
      });
    case ERROR_CREATE_PROPERTYATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_PROPERTIESATTRIBUTESVALUES:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_PROPERTIESATTRIBUTESVALUES:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_PROPERTIESATTRIBUTESVALUES:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_PROPERTYATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    //PROPERTY
    case SUCCESS_CREATE_PROPERTY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_PROPERTY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    //TICKET
    case SUCCESS_CREATE_TICKET:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_TICKET:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_TICKET:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_TICKETS:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });

    case DELETE_PROPERTYATTRIBUTEVALUE:
      let datoPropertyAttributeValueDelete = action.propertyAttributeValue;
      let idsDelete = [];
      Object.values(action.propertyAttributeValue).map(
        propertyAttributeValueDelete => {
          if (propertyAttributeValueDelete && propertyAttributeValueDelete.id)
            idsDelete.push(propertyAttributeValueDelete.id);
        }
      );
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoPropertyAttributeValueDelete)
          ),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_PROPERTYATTRIBUTEVALUE:
      let datoPropertyAttributeValueDeleteCreate =
        action.propertyAttributeValue;
      let idsDeleteCreate = [];
      Object.values(action.propertyAttributeValue).map(
        propertyAttributeValueDelete => {
          if (propertyAttributeValueDelete && propertyAttributeValueDelete.id)
            idsDeleteCreate.push(propertyAttributeValueDelete.id);
        }
      );
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoPropertyAttributeValueDeleteCreate)
          ),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_PROPERTYATTRIBUTEVALUE:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.propertyAttributeValue,
        error: null
      });
    case REQUEST_DELETE_PROPERTYATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_PROPERTYATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_PROPERTYATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_PROPERTYATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    //PROPERTY
    case SUCCESS_CREATE_PROPERTY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_PROPERTY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        eliminado: {}
      }); //TICKET
    case SUCCESS_CREATE_TICKET:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_TICKET:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_TICKET:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_TICKETS:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_PROPERTYATTRIBUTEVALUE:
      let idsCreate = [];
      Object.values(action.propertyAttributeValue).map(
        propertyAttributeValueCreate => {
          if (propertyAttributeValueCreate && propertyAttributeValueCreate.id)
            idsCreate.push(propertyAttributeValueCreate.id);
        }
      );
      return merge({}, state, {
        isCreating: false,
        print: action.propertyAttributeValue,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_PROPERTYATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_PROPERTYATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_PROPERTYATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_PROPERTIESATTRIBUTESVALUES:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_PROPERTIESATTRIBUTESVALUES:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_PROPERTIESATTRIBUTESVALUES:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_PROPERTYATTRIBUTEVALUE:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_PROPERTYATTRIBUTEVALUE:
      let datoPropertyAttributeValueDeleteCreate =
        action.propertyAttributeValue;
      let idsDeleteCreate = [];
      Object.values(action.propertyAttributeValue).map(
        propertyAttributeValueDelete => {
          if (propertyAttributeValueDelete && propertyAttributeValueDelete.id)
            idsDeleteCreate.push(propertyAttributeValueDelete.id);
        }
      );
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(
            clone(state.print),
            Object.keys(datoPropertyAttributeValueDeleteCreate)
          ),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const propertiesAttributesValues = combineReducers({
  byId: propertiesAttributesValuesById,
  allIds: allPropertiesAttributesValues,
  update: update,
  create: create,
  totalPropertiesAttributesValues: totalPropertiesAttributesValues,
  delete: deleter,
  print: print
});

export default propertiesAttributesValues;
