import IconButton from '@material-ui/core/IconButton';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import GetAppIcon from '@material-ui/icons/GetApp';
import React from 'react';
import SharePropertiesModal from './SharePropertiesModal';

export default function SharePropertiesBar() {
  const [open, setOpen] = React.useState(null);
  const [type, setType] = React.useState(null);
  const handleSendWhatsApp = () => {
    setType('whatsapp');
    setOpen(true);
  };

  const handleDownload = () => {
    setType('download');
    setOpen(true);
  };
  return (
    <>
      <IconButton size="large" onClick={handleSendWhatsApp}>
        <WhatsAppIcon />
      </IconButton>
      <IconButton size="large" onClick={handleDownload}>
        <GetAppIcon />
      </IconButton>
      <SharePropertiesModal setOpen={setOpen} open={open} type={type} />
    </>
  );
}
