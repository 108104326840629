import { normalize, schema, denormalize } from 'normalizr';
import location from './normalizeLocations';

const state = new schema.Entity(
  'states',
  { location: [location] },
  { idAttribute: 'id' }
);

export function normalizeDatos(myData) {
  const mySchema = { states: [state] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = state;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = state;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default state;
