//api
import contactsApi from '../api/contactsApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizeContacts';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//CONTACTS
export const REQUEST_CONTACTS = 'REQUEST_CONTACTS';
export const RECEIVE_CONTACTS = 'RECEIVE_CONTACTS';
export const INVALIDATE_CONTACTS = 'INVALIDATE_CONTACTS';
export const ERROR_CONTACTS = 'ERROR_CONTACTS';
export const RESET_CONTACTS = 'RESET_CONTACTS';

export function invalidateContacts() {
  return {
    type: INVALIDATE_CONTACTS
  };
}

function requestContacts() {
  return {
    type: REQUEST_CONTACTS
  };
}

function receiveContacts(json) {
  return {
    type: RECEIVE_CONTACTS,
    contacts: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorContacts(error) {
  return {
    type: ERROR_CONTACTS,
    error: error
  };
}

export function resetContacts() {
  return {
    type: RESET_CONTACTS
  };
}

export function fetchContacts(filtros) {
  return dispatch => {
    dispatch(requestContacts());
    return contactsApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveContacts(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorContacts(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorContacts(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

function shouldFetchContacts(state) {
  const contacts = state.contacts.byId;
  if (!contacts) {
    return true;
  } else if (contacts.isFetching) {
    return false;
  } else {
    return contacts.didInvalidate;
  }
}

export function fetchContactsIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchContacts(getState())) {
      return dispatch(fetchContacts(filtros));
    }
  };
}

//MODEL
export const REQUEST_CONTACT = 'REQUEST_CONTACT';
export const RECEIVE_CONTACT = 'RECEIVE_CONTACT';
export const INVALIDATE_CONTACT = 'INVALIDATE_CONTACT';
export const ERROR_CONTACT = 'ERROR_CONTACT';
export const RESET_CONTACT = 'RESET_CONTACT';

export function invalidateContact() {
  return {
    type: INVALIDATE_CONTACT
  };
}

function requestContact() {
  return {
    type: REQUEST_CONTACT
  };
}

export function receiveContact(json) {
  return {
    type: RECEIVE_CONTACT,
    contact: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorContact(error) {
  return {
    type: ERROR_CONTACT,
    error: error
  };
}

export function fetchContact(idContact) {
  return dispatch => {
    dispatch(requestContact());
    return contactsApi
      .getOne(idContact)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveContact(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(errorContact(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorContact(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_CONTACT = 'RECEIVE_FILE_CONTACT';

function receiveFileContact(file) {
  return {
    type: RECEIVE_FILE_CONTACT,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFileContact(idContact, filtros) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return contactsApi
      .getFile(idContact, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFileContact(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorContact(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorContact(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const REQUEST_UPDATE_CONTACT = 'REQUEST_UPDATE_CONTACT';
export const SUCCESS_UPDATE_CONTACT = 'SUCCESS_UPDATE_CONTACT';
export const ERROR_UPDATE_CONTACT = 'ERROR_UPDATE_CONTACT';
export const RESET_UPDATE_CONTACT = 'RESET_UPDATE_CONTACT';
export const DELETE_UPDATE_CONTACT = 'DELETE_UPDATE_CONTACT';

function requestUpdateContact() {
  return {
    type: REQUEST_UPDATE_CONTACT
  };
}

function receiveUpdateContact(contact) {
  return {
    type: SUCCESS_UPDATE_CONTACT,
    receivedAt: Date.now(),
    contact: normalizeDato(contact)
  };
}

function errorUpdateContact(error) {
  return {
    type: ERROR_UPDATE_CONTACT,
    error: error
  };
}

export function resetUpdateContact() {
  return {
    type: RESET_UPDATE_CONTACT
  };
}

export function updateContact(contact) {
  return {
    type: UPDATE_CONTACT,
    contact
  };
}

export function saveUpdateContact() {
  return (dispatch, getState) => {
    dispatch(requestUpdateContact());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let contact = denormalizeDato(getState().contacts.update.activo, store);

    return contactsApi
      .saveUpdate(contact)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateContact(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateContact(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateContacts(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdateContacts(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateContact(JSON.parse(error.message)));
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdateContact(error.data));
                })
                .catch(() => {
                  dispatch(errorUpdateContact(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

export function deleteUpdateContact(contact) {
  return {
    type: DELETE_UPDATE_CONTACT,
    contact
  };
}

//UPDATE CONTACTS
export const REQUEST_UPDATE_CONTACTS = 'REQUEST_UPDATE_CONTACTS';
export const SUCCESS_UPDATE_CONTACTS = 'SUCCESS_UPDATE_CONTACTS';
export const ERROR_UPDATE_CONTACTS = 'ERROR_UPDATE_CONTACTS';
export const RESET_UPDATE_CONTACTS = 'RESET_UPDATE_CONTACTS';

function requestUpdateContacts() {
  return {
    type: REQUEST_UPDATE_CONTACTS
  };
}

function receiveUpdateContacts(contacts) {
  return {
    type: SUCCESS_UPDATE_CONTACTS,
    receivedAt: Date.now(),
    contacts: normalizeDatos(contacts)
  };
}

function errorUpdateContacts(error) {
  return {
    type: ERROR_UPDATE_CONTACTS,
    error: error
  };
}

export function resetUpdateContacts() {
  return {
    type: RESET_UPDATE_CONTACTS
  };
}

export function saveUpdateContacts() {
  return (dispatch, getState) => {
    dispatch(requestUpdateContacts());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let contacts = getState().contacts.update.activos.map(idContact => {
      return denormalizeDato(
        getState().contacts.update.activo[idContact],
        store
      );
    });

    return contactsApi
      .saveUpdateContacts(contacts)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateContacts(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateContacts(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateContacts(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdateContacts(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateContacts(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorUpdateContacts(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//ALTA CONTACT
export const CREATE_CONTACT = 'CREATE_CONTACT';
export const REQUEST_CREATE_CONTACT = 'REQUEST_CREATE_CONTACT';
export const SUCCESS_CREATE_CONTACT = 'SUCCESS_CREATE_CONTACT';
export const ERROR_CREATE_CONTACT = 'ERROR_CREATE_CONTACT';
export const RESET_CREATE_CONTACT = 'RESET_CREATE_CONTACT';
export const DELETE_CREATE_CONTACT = 'DELETE_CREATE_CONTACT';

//ALTA CONTACT
function requestCreateContact() {
  return {
    type: REQUEST_CREATE_CONTACT
  };
}

function receiveCreateContact(contact) {
  return {
    type: SUCCESS_CREATE_CONTACT,
    receivedAt: Date.now(),
    contact: normalizeDato(contact)
  };
}

export function errorCreateContact(error) {
  return {
    type: ERROR_CREATE_CONTACT,
    error: error
  };
}

export function resetCreateContact() {
  return {
    type: RESET_CREATE_CONTACT
  };
}

export function createContact(contact) {
  return {
    type: CREATE_CONTACT,
    contact
  };
}

export function deleteCreateContact(contact) {
  return {
    type: DELETE_CREATE_CONTACT,
    contact
  };
}

export function saveCreateContact() {
  return (dispatch, getState) => {
    dispatch(requestCreateContact());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let contact = denormalizeDato(getState().contacts.create.nuevo, store);

    return contactsApi
      .saveCreate(contact)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateContact(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateContact(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateContacts(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreateContacts(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateContact(JSON.parse(error.message)));
                  if (error.data) dispatch(receiveCreateContact(error.data));
                })
                .catch(() => {
                  dispatch(errorCreateContact(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//CREATE CONTACTS
export const REQUEST_CREATE_CONTACTS = 'REQUEST_CREATE_CONTACTS';
export const SUCCESS_CREATE_CONTACTS = 'SUCCESS_CREATE_CONTACTS';
export const ERROR_CREATE_CONTACTS = 'ERROR_CREATE_CONTACTS';
export const RESET_CREATE_CONTACTS = 'RESET_CREATE_CONTACTS';

function requestCreateContacts() {
  return {
    type: REQUEST_CREATE_CONTACTS
  };
}

function receiveCreateContacts(contacts) {
  return {
    type: SUCCESS_CREATE_CONTACTS,
    receivedAt: Date.now(),
    contacts: normalizeDatos(contacts)
  };
}

function errorCreateContacts(error) {
  return {
    type: ERROR_CREATE_CONTACTS,
    error: error
  };
}

export function resetCreateContacts() {
  return {
    type: RESET_CREATE_CONTACTS
  };
}

export function saveCreateContacts() {
  return (dispatch, getState) => {
    dispatch(requestCreateContacts());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let contacts = getState().contacts.create.nuevos.map(idContact => {
      return denormalizeDato(
        getState().contacts.create.nuevo[idContact],
        store
      );
    });

    return contactsApi
      .saveCreateContacts(contacts)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateContacts(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateContacts(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateContacts(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreateContacts(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateContacts(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorCreateContacts(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//DELETE CONTACT
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const REQUEST_DELETE_CONTACT = 'REQUEST_DELETE_CONTACT';
export const SUCCESS_DELETE_CONTACT = 'SUCCESS_DELETE_CONTACT';
export const ERROR_DELETE_CONTACT = 'ERROR_DELETE_CONTACT';
export const RESET_DELETE_CONTACT = 'RESET_DELETE_CONTACT';

function requestDeleteContact() {
  return {
    type: REQUEST_DELETE_CONTACT
  };
}

function receiveDeleteContact(contact) {
  return {
    type: SUCCESS_DELETE_CONTACT,
    receivedAt: Date.now(),
    contact: normalizeDato(contact)
  };
}

function errorDeleteContact(error) {
  return {
    type: ERROR_DELETE_CONTACT,
    error: error
  };
}

export function resetDeleteContact(error) {
  return {
    type: RESET_DELETE_CONTACT,
    error: error
  };
}

export function deleteContact(contact) {
  return {
    type: DELETE_CONTACT,
    contact
  };
}

export function saveDeleteContact(contact) {
  return dispatch => {
    dispatch(requestDeleteContact());
    return contactsApi
      .saveDelete(contact)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeleteContact());
        dispatch(receiveDeleteContact(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorDeleteContact(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeleteContact(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorDeleteContact(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorDeleteContact(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorDeleteContact(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT CONTACT
export const PRINT_CONTACT = 'PRINT_CONTACT';
export const REQUEST_PRINT_CONTACT = 'REQUEST_PRINT_CONTACT';
export const SUCCESS_PRINT_CONTACT = 'SUCCESS_PRINT_CONTACT';
export const ERROR_PRINT_CONTACT = 'ERROR_PRINT_CONTACT';
export const RESET_PRINT_CONTACT = 'RESET_PRINT_CONTACT';
export const DELETE_PRINT_CONTACT = 'DELETE_PRINT_CONTACT';

function requestPrintContact() {
  return {
    type: REQUEST_PRINT_CONTACT
  };
}

function receivePrintContact(turnos) {
  return {
    type: SUCCESS_PRINT_CONTACT,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintContact(error) {
  return {
    type: ERROR_PRINT_CONTACT,
    error: error
  };
}

export function resetPrintContact() {
  return {
    type: RESET_PRINT_CONTACT
  };
}

export function printContact(contact) {
  return {
    type: PRINT_CONTACT,
    contact
  };
}

export function deletePrintContact(contact) {
  return {
    type: DELETE_PRINT_CONTACT,
    contact
  };
}

export function savePrintContact(idContact) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintContact());
    return contactsApi
      .printContact(idContact)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintContact(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintContact(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintContact(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintContact(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintContact(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT CONTACTS
export const REQUEST_PRINT_CONTACTS = 'REQUEST_PRINT_CONTACTS';
export const SUCCESS_PRINT_CONTACTS = 'SUCCESS_PRINT_CONTACTS';
export const ERROR_PRINT_CONTACTS = 'ERROR_PRINT_CONTACTS';
export const RESET_PRINT_CONTACTS = 'RESET_PRINT_CONTACTS';

function requestPrintContacts() {
  return {
    type: REQUEST_PRINT_CONTACTS
  };
}

function receivePrintContacts(contacts) {
  return {
    type: SUCCESS_PRINT_CONTACTS,
    receivedAt: Date.now(),
    contacts: normalizeDatos(contacts)
  };
}

function errorPrintContacts(error) {
  return {
    type: ERROR_PRINT_CONTACTS,
    error: error
  };
}

export function resetPrintContacts() {
  return {
    type: RESET_PRINT_CONTACTS
  };
}

export function savePrintContacts() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintContact());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let contacts = getState().contacts.print.printers.map(idContact => {
      return denormalizeDato(getState().contacts.print.print[idContact], store);
    });
    return contactsApi
      .printContacts(contacts)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintContacts(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintContacts(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintContacts(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintContacts(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintContacts(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}
