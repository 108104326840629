import React, { useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

export default function SVGimg(props) {
  const SVG_URL = props.src;
  // const SVG_URL = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/106114/tiger.svg';

  const useStyles = makeStyles(theme => ({
    root: {
      '& svg': {
        width: props.width ? props.width : 'inherit',
        height: props.height ? props.height : 'inherit',
        fill: props.fill ? props.fill : 'inherit'
      }
    }
  }));
  const classes = useStyles();
  const [svg, setSvg] = useState(null);

  useEffect(() => {
    fetch(SVG_URL)
      .then(res => res.text())
      .catch(res => console.log(res))
      .then(text => setSvg(text));
  }, [SVG_URL]);

  if (svg && props.src !== '')
    return (
      <div
        id={props.id}
        style={{ ...props.style }}
        className={classes.root}
        dangerouslySetInnerHTML={{
          __html: svg
        }}
      ></div>
    );
  else if (props.loader !== false) return <></>;
  else return <></>;
}

SVGimg.propTypes = {
  src: PropTypes.string.isRequired,
  style: PropTypes.object,
  className: PropTypes.object
};
