import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import { createTheme, CssBaseline } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
//Components
import '../../assets/css/IndexPanel.css';
import Property from '../Property/Property';
import Users from '../Users/Users';
import MensajeCorrecto from './Messages/MensajeCorrecto';
import MensajeError from './Messages/MensajeError';
import NavLeft from './NavLeft/NavLeft';
import NavSup from './NavSup/NavSup';

import Agents from '../Agents/Agents';
import Configuration from '../Configuration/Configuration';
import Contacts from '../Contacts/Contacts';
import Home from '../Home/Home';
import Tickets from '../Tickets/Tickets';
import { fetchTenant } from '../../actions/TenantActions';
import tenantUtil from '../../utils/tenantUtil';
import { useDispatch } from 'react-redux';
import { fetchOperationTypesIfNeeded } from '../../actions/OperationTypeActions';
import { fetchPropertyTypesIfNeeded } from '../../actions/PropertyTypeActions';
import { fetchCurrenciesIfNeeded } from '../../actions/CurrencyActions';
import { fetchPeriodsIfNeeded } from '../../actions/PeriodActions';
import { fetchAttributesGroupsIfNeeded } from '../../actions/AttributeGroupActions';
import { fetchCountries } from '../../actions/CountryActions';
import { isMobile } from 'react-device-detect';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import Posts from '../Posts/Posts';
//Actions

const useStyles = makeStyles(theme => ({
  offset: theme.mixins.toolbar,
  root: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex'
    },
    paddingTop: theme.spacing(9)
  },
  principal: {
    flexGrow: 1,
    padding: theme.spacing(0),
    marginTop: '24px',
    marginBottom: '90px',
    width: 'calc(100% - 90px)',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
      width: '100%'
    }
  }
}));
export default function Layout() {
  const [darked, setDarked] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTenant(tenantUtil.getTenant()));
    dispatch(fetchOperationTypesIfNeeded());
    dispatch(
      fetchPropertyTypesIfNeeded({ with: ['childrenType'], hierarchy: 0 })
    );
    dispatch(fetchCurrenciesIfNeeded());
    dispatch(fetchPeriodsIfNeeded());
    dispatch(
      fetchAttributesGroupsIfNeeded({ with: ['attribute.attributeValue'] })
    );
    dispatch(
      fetchCountries({ with: ['state.location.location'], limit: '-1' })
    );
  }, []);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const toggleTypeTheme = () => {
    setDarked(prev => !prev);
  };

  const theme = createTheme({
    palette: {
      primary: {
        light: '#b4a2ec',
        main: '#865DFF',
        dark: '#4812e7',
        contrastText: '#fff'
      },
      secondary: {
        light: 'rgba(227,132,255,0.21)',
        main: '#E384FF',
        dark: '#E384FF',
        contrastText: '#000'
      },
      success: {
        light: '#aed581',
        main: '#4caf50',
        dark: '#80936a'
      },
      error: {
        main: '#f44336',
        light: '#ffcdd2',
        dark: '#8b1a12'
      },
      disabled: {
        light: '#fafafa',
        main: '#f5f5f5'
      },
      default: {
        main: '#2196f3',
        light: '#64b5f6',
        dark: '#1f8de5'
      },
      attention: {
        main: '#FFF42B',
        light: '#fff5a4',
        dark: '#a59c10'
      },
      selected: {
        light: '#865DFF',
        dark: 'rgba(255,167,51,0.74)'
      },
      text: {
        light: 'black',
        dark: 'white'
      },
      type: darked ? 'dark' : 'light'
    },
    typography: {
      h1: {
        fontSize: '2rem',
        fontWeight: '900'
        // paddingBottom: "14px",
        // paddingLeft: "3px",
      },
      h2: {
        fontSize: '1.4rem',
        fontWeight: '600'
      },
      subtitle1: {
        fontSize: '1.3rem',
        fontWeight: '600'
      },
      overline: {
        fontWeight: 700,
        lineHeight: '1',
        fontSize: '0.75rem',
        letterSpacing: '1.1px',
        textTransform: 'uppercase',
        fontFamily: 'Public Sans, sans-serif',
        color: 'rgb(99, 115, 129)'
      },
      caption: {
        color: 'rgb(99, 115, 129)'
      },
      fontFamily: ['SkModernist', 'sans-serif'].join(',')
    },
    iconOutlined: {}
  });
  const classes = useStyles();

  theme.shadows.push(
    'rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px'
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <NavSup
        toggleTypeTheme={toggleTypeTheme}
        darked={darked}
        open={open}
        handleDrawerOpen={handleDrawerOpen}
      />
      <div className={`${classes.root} panel-theme`}>
        <NavLeft open={open} handleDrawerOpen={handleDrawerOpen} />
        <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
          <main className={classes.principal}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="es">
              <Switch>
                <Route path="/agents" component={Agents} />
                <Route path="/tickets" component={Tickets} />
                <Route path="/properties" component={Property} />
                <Route path="/posts" component={Posts} />
                <Route path="/contacts" component={Contacts} />
                <Route path="/users" component={Users} />
                <Route path="/configuration" component={Configuration} />
                <Route path="/" component={Home} />
              </Switch>
            </MuiPickersUtilsProvider>
          </main>
        </DndProvider>
      </div>
      <MensajeCorrecto />
      <MensajeError />
    </ThemeProvider>
  );
}
