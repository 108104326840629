import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles(theme => ({
  danger: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.paper
  }
}));
export default function Estado(props) {
  const classes = useStyles();
  switch (props.estado) {
    case 1:
      return <Chip className="default" label={'Activo'} />;
    case 0:
      return <Chip className={classes.danger} label={'Vencido'} />;
    default:
      return <Chip className="default" label={'Activo'} />;
  }
}
