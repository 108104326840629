import React, { forwardRef, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

//Components
import { Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
//Actions
import {
  createDependency,
  updateDependency
} from '../../actions/DependencyActions';
//Hooks
import CustomPaper from '../../library/CustomPaper/CustomPaper';
import PaperBody from '../../library/CustomPaper/PaperBody';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PhoneIcon from '@material-ui/icons/Phone';
import { EmailOutlined } from '@material-ui/icons';
import tenantUtil from '../../utils/tenantUtil';
import PhoneInput from 'react-phone-number-input';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles(theme => ({
  root: {
    '& svg': {
      fontSize: '1em'
    }
  },
  mensaje: {
    display: 'none',
    color: theme.palette.error.main,
    '&.visible': {
      display: 'inherit'
    }
  }
}));

const RenderHeader = forwardRef((props, ref) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      id="phoneNumber1"
      label="Teléfono"
      placeholder="Teléfono"
      fullWidth={true}
      margin="dense"
      InputLabelProps={{
        shrink: true
      }}
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <PhoneIcon />
          </InputAdornment>
        )
      }}
    />
  );
});

const RenderHeader2 = forwardRef((props, ref) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      id="phoneNumber2"
      label="WhatsApp"
      placeholder="WhatsApp"
      fullWidth={true}
      margin="dense"
      InputLabelProps={{
        shrink: true
      }}
      variant="outlined"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <WhatsAppIcon />
          </InputAdornment>
        )
      }}
    />
  );
});

export default function CRUDDependencyContacts(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { validador, validar, disabled, msjPhoneNumber } = props;
  const ref = useRef();
  const ref2 = useRef();

  //Store
  const tenants = useSelector(state => state.tenants);
  const tenant = tenantUtil.getTenant()
    ? tenants.update.activo
    : tenants.create.nuevo;
  let idDependency = tenant && tenant.dependency ? tenant.dependency[0] : null;
  const dependencies = useSelector(state => state.dependencies);
  let dependency = idDependency
    ? dependencies.byId.dependencies[idDependency]
    : {};
  if (dependencies.update.activos.indexOf(idDependency) !== -1)
    dependency = dependencies.update.activo[idDependency];
  if (dependencies.create.nuevos.indexOf(idDependency) !== -1)
    dependency = dependencies.create.nuevo[idDependency];

  //Persona
  const handleChangeDependency = e => {
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    changeDependency(cambio);
    validar(e);
  };

  const changeDependency = cambio => {
    let id = idDependency;
    if (!id) {
      id = Date.now() + Math.random() + '-';
    }
    cambio.id = id;
    let cambioDependency = {};
    cambioDependency[id] = cambio;
    if (id.toString().indexOf('-') === -1) {
      if (dependencies.update.activos.indexOf(id) === -1) {
        cambio = { ...dependencies.byId.dependencies[id], ...cambio };
        cambioDependency[id] = cambio;
      }
      dispatch(updateDependency(cambioDependency));
    } else dispatch(createDependency(cambioDependency));
  };

  const handleChangeCelularUser = value => {
    msjPhoneNumber.current.classList.remove('visible');
    if (value) {
      let cambio = {};
      cambio.phoneNumber1 = value;
      changeDependency(cambio);
    }
  };

  const handleChangeCelular2User = value => {
    msjPhoneNumber.current.classList.remove('visible');
    if (value) {
      let cambio = {};
      cambio.phoneNumber2 = value;
      changeDependency(cambio);
    }
  };

  return (
    <Grid
      container
      spacing={1}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.root}
    >
      <Grid item md={12} sm={12} xs={12} spacing={1}>
        <CustomPaper style={{ marginTop: '10px' }}>
          <PaperBody>
            <Grid
              container
              spacing={1}
              style={{ marginTop: '10px', marginBottom: '10px' }}
            >
              <Grid item md={12} sm={12} xs={12}>
                <Typography variant={'overline'} component={'span'}>
                  ¿Cómo se contactarán con tu empresa?
                </Typography>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  id="email1"
                  label="Email"
                  placeholder="Email"
                  fullWidth={true}
                  margin="dense"
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailOutlined />
                      </InputAdornment>
                    ),
                    type: 'email'
                  }}
                  value={
                    dependency && dependency.email1 ? dependency.email1 : ''
                  }
                  onChange={handleChangeDependency}
                  onBlur={validar}
                  error={!validador.isValidById('email1')}
                  helperText={validador.getHelperTextById('email1')}
                  disabled={disabled}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <PhoneInput
                  id="phoneNumber1"
                  label="Celular"
                  placeholder="Celular"
                  defaultCountry={'AR'}
                  international={true}
                  countryCallingCodeEditable={false}
                  value={
                    dependency?.phoneNumber1 ? dependency.phoneNumber1 : ''
                  }
                  onChange={handleChangeCelularUser}
                  inputComponentRef={ref}
                  inputComponent={RenderHeader}
                  onBlur={validar}
                  error={!validador.isValidById('phoneNumber1')}
                  helperText={validador.getHelperTextById('phoneNumber1')}
                  disabled={disabled}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <PhoneInput
                  id="phoneNumber2"
                  label="Celular"
                  placeholder="Celular"
                  defaultCountry={'AR'}
                  international={true}
                  countryCallingCodeEditable={false}
                  value={
                    dependency?.phoneNumber2 ? dependency.phoneNumber2 : ''
                  }
                  onChange={handleChangeCelular2User}
                  inputComponentRef={ref2}
                  inputComponent={RenderHeader2}
                  onBlur={validar}
                  error={!validador.isValidById('phoneNumber2')}
                  helperText={validador.getHelperTextById('phoneNumber2')}
                  disabled={disabled}
                />
                <FormHelperText
                  ref={msjPhoneNumber}
                  className={classes.mensaje}
                >
                  El celular o teléfono no es válido
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography
                  variant={'overline'}
                  component={'span'}
                  style={{ marginTop: '15px' }}
                >
                  Redes sociales
                </Typography>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  id="linkFacebook"
                  label="Facebook"
                  placeholder="Facebook"
                  fullWidth={true}
                  margin="dense"
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FacebookIcon />
                      </InputAdornment>
                    )
                  }}
                  value={
                    dependency && dependency.linkFacebook
                      ? dependency.linkFacebook
                      : ''
                  }
                  onChange={handleChangeDependency}
                  onBlur={validar}
                  error={!validador.isValidById('linkFacebook')}
                  helperText={validador.getHelperTextById('linkFacebook')}
                  disabled={disabled}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  id="linkInstagram"
                  label="Instagram"
                  placeholder="Instagram"
                  fullWidth={true}
                  margin="dense"
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <InstagramIcon />
                      </InputAdornment>
                    )
                  }}
                  value={
                    dependency && dependency.linkInstagram
                      ? dependency.linkInstagram
                      : ''
                  }
                  onChange={handleChangeDependency}
                  onBlur={validar}
                  error={!validador.isValidById('linkInstagram')}
                  helperText={validador.getHelperTextById('linkInstagram')}
                  disabled={disabled}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  id="linkLinkedIn"
                  label="LinkedIn"
                  placeholder="LinkedIn"
                  fullWidth={true}
                  margin="dense"
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkedInIcon />
                      </InputAdornment>
                    )
                  }}
                  value={
                    dependency && dependency.linkLinkedIn
                      ? dependency.linkLinkedIn
                      : ''
                  }
                  onChange={handleChangeDependency}
                  onBlur={validar}
                  error={!validador.isValidById('linkLinkedIn')}
                  helperText={validador.getHelperTextById('linkLinkedIn')}
                  disabled={disabled}
                />
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <TextField
                  id="linkTwitter"
                  label="Twitter"
                  placeholder="Twitter"
                  fullWidth={true}
                  margin="dense"
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TwitterIcon />
                      </InputAdornment>
                    )
                  }}
                  value={
                    dependency && dependency.linkTwitter
                      ? dependency.linkTwitter
                      : ''
                  }
                  onChange={handleChangeDependency}
                  onBlur={validar}
                  error={!validador.isValidById('linkTwitter')}
                  helperText={validador.getHelperTextById('linkTwitter')}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </PaperBody>
        </CustomPaper>
      </Grid>
    </Grid>
  );
}
