import {
  INVALIDATE_TICKETACTIVITIES,
  ERROR_TICKETACTIVITIES,
  RECEIVE_TICKETACTIVITIES,
  REQUEST_TICKETACTIVITIES,
  RESET_TICKETACTIVITIES,
  ERROR_TICKETACTIVITY,
  RECEIVE_TICKETACTIVITY,
  REQUEST_TICKETACTIVITY,
  UPDATE_TICKETACTIVITY,
  REQUEST_UPDATE_TICKETACTIVITY,
  SUCCESS_UPDATE_TICKETACTIVITY,
  ERROR_UPDATE_TICKETACTIVITY,
  RESET_UPDATE_TICKETACTIVITY,
  REQUEST_UPDATE_TICKETACTIVITIES,
  SUCCESS_UPDATE_TICKETACTIVITIES,
  ERROR_UPDATE_TICKETACTIVITIES,
  RESET_UPDATE_TICKETACTIVITIES,
  CREATE_TICKETACTIVITY,
  ERROR_CREATE_TICKETACTIVITY,
  REQUEST_CREATE_TICKETACTIVITY,
  RESET_CREATE_TICKETACTIVITY,
  SUCCESS_CREATE_TICKETACTIVITY,
  REQUEST_CREATE_TICKETACTIVITIES,
  SUCCESS_CREATE_TICKETACTIVITIES,
  ERROR_CREATE_TICKETACTIVITIES,
  RESET_CREATE_TICKETACTIVITIES,
  DELETE_TICKETACTIVITY,
  DELETE_CREATE_TICKETACTIVITY,
  DELETE_UPDATE_TICKETACTIVITY,
  REQUEST_DELETE_TICKETACTIVITY,
  SUCCESS_DELETE_TICKETACTIVITY,
  ERROR_DELETE_TICKETACTIVITY,
  RESET_DELETE_TICKETACTIVITY,
  REQUEST_PRINT_TICKETACTIVITY,
  SUCCESS_PRINT_TICKETACTIVITY,
  ERROR_PRINT_TICKETACTIVITY,
  RESET_PRINT_TICKETACTIVITY,
  RECEIVE_FILE_TICKETACTIVITY,
  REQUEST_PRINT_TICKETACTIVITIES,
  SUCCESS_PRINT_TICKETACTIVITIES,
  ERROR_PRINT_TICKETACTIVITIES,
  RESET_PRINT_TICKETACTIVITIES,
  RECEIVE_FILE_TICKETACTIVITIES,
  PRINT_TICKETACTIVITY,
  DELETE_PRINT_TICKETACTIVITY
} from '../actions/TicketActivityActions';

import {
  RECEIVE_TICKET,
  RECEIVE_TICKETS,
  SUCCESS_DELETE_TICKET,
  SUCCESS_CREATE_TICKET,
  SUCCESS_UPDATE_TICKET,
  SUCCESS_UPDATE_TICKETS
} from '../actions/TicketActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    ticketActivities: {},
    files: {}
  };
}

function ticketActivitiesById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_TICKETACTIVITIES:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_TICKETACTIVITIES:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_TICKETACTIVITIES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_TICKETACTIVITIES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        ticketActivities: {}
      });
    case RECEIVE_TICKETACTIVITIES:
      let dato = action.ticketActivities.entities.ticketActivities;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        ticketActivities: merge({}, state.ticketActivities, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_TICKETACTIVITY:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_TICKETACTIVITY:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_TICKETACTIVITY:
      let datoTicketActivity = action.ticketActivity.entities.ticketActivities;
      return Object.assign({}, state, {
        ticketActivities: merge({}, state.ticketActivities, datoTicketActivity),
        isFetching: false
      });
    case RECEIVE_FILE_TICKETACTIVITY:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_TICKETACTIVITY:
      let datoTicketActivityEliminado =
        action.ticketActivity.entities.ticketActivities;
      return Object.assign({}, state, {
        ticketActivities: mergeWith(
          clone(datoTicketActivityEliminado),
          state.ticketActivities,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_TICKETACTIVITY:
      let datoTicketActivityCreado =
        action.ticketActivity.entities.ticketActivities;
      return Object.assign({}, state, {
        ticketActivities: mergeWith(
          clone(datoTicketActivityCreado),
          state.ticketActivities,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_TICKETACTIVITIES:
      let datosTicketActivityCreado =
        action.ticketActivities.entities.ticketActivities;
      return Object.assign({}, state, {
        ticketActivities: mergeWith(
          clone(datosTicketActivityCreado),
          state.ticketActivities,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TICKETACTIVITY:
      let datoTicketActivityActualizado =
        action.ticketActivity.entities.ticketActivities;
      return Object.assign({}, state, {
        ticketActivities: mergeWith(
          clone(datoTicketActivityActualizado),
          state.ticketActivities,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TICKETACTIVITIES:
      let datosTicketActivityActualizado =
        action.ticketActivities.entities.ticketActivities;
      return Object.assign({}, state, {
        ticketActivities: mergeWith(
          clone(datosTicketActivityActualizado),
          state.ticketActivities,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    //TICKET
    case RECEIVE_TICKET:
      let ticket =
        action.ticket.entities && action.ticket.entities.ticketActivities
          ? action.ticket.entities.ticketActivities
          : {};
      return Object.assign({}, state, {
        ticketActivities: merge({}, state.ticketActivities, ticket)
      });
    case RECEIVE_TICKETS:
      let tickets =
        action.tickets.entities && action.tickets.entities.ticketActivities
          ? action.tickets.entities.ticketActivities
          : {};
      return Object.assign({}, state, {
        ticketActivities: merge({}, state.ticketActivities, tickets)
      });
    case SUCCESS_DELETE_TICKET:
      let datoticketEliminado =
        action.ticket.entities && action.ticket.entities.ticketActivities
          ? action.ticket.entities.ticketActivities
          : {};
      return Object.assign({}, state, {
        ticketActivities: mergeWith(
          clone(datoticketEliminado),
          pickBy(state.ticketActivities, function (ticketActivity) {
            return ticketActivity.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_TICKET:
      let datoticketCreado =
        action.ticket.entities && action.ticket.entities.ticketActivities
          ? action.ticket.entities.ticketActivities
          : {};
      return Object.assign({}, state, {
        ticketActivities: mergeWith(
          clone(datoticketCreado),
          pickBy(state.ticketActivities, function (ticketActivity) {
            return ticketActivity.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TICKET:
      let datoticketActualizado =
        action.ticket.entities && action.ticket.entities.ticketActivities
          ? action.ticket.entities.ticketActivities
          : {};
      return Object.assign({}, state, {
        ticketActivities: mergeWith(
          clone(datoticketActualizado),
          pickBy(state.ticketActivities, function (ticketActivity) {
            return ticketActivity.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TICKETS:
      let datosticketActualizado =
        action.tickets.entities && action.tickets.entities.ticketActivities
          ? action.tickets.entities.ticketActivities
          : {};
      return Object.assign({}, state, {
        ticketActivities: mergeWith(
          clone(datosticketActualizado),
          state.ticketActivities,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        ticketActivities: {}
      });
    default:
      return state;
  }
}

function allTicketActivities(state = [], action) {
  switch (action.type) {
    case RECEIVE_TICKETACTIVITIES:
      return action.ticketActivities.result &&
        action.ticketActivities.result.ticketActivities
        ? union(action.ticketActivities.result.ticketActivities, state)
        : action.ticketActivities.result
        ? action.ticketActivities.result
        : state;
    case RECEIVE_TICKETACTIVITY:
      return action.ticketActivity.result
        ? union([action.ticketActivity.result], state)
        : state;

    case SUCCESS_CREATE_TICKETACTIVITY:
      let datoTicketActivitySCreate =
        action.ticketActivity.entities.ticketActivities;
      let idNuevoSCreate = null;
      if (Object.values(datoTicketActivitySCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoTicketActivitySCreate)[0] &&
          Object.values(datoTicketActivitySCreate)[0].id
            ? Object.values(datoTicketActivitySCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_TICKETACTIVITIES:
      let ticketActivitiesCreate =
        action.ticketActivities.entities &&
        action.ticketActivities.entities.ticketActivities
          ? action.ticketActivities.entities.ticketActivities
          : null;
      return ticketActivitiesCreate
        ? union(
            state,
            Object.values(ticketActivitiesCreate).map(ticketActivities => {
              return ticketActivities.id;
            })
          )
        : state;
    case RESET_TICKETACTIVITIES:
      return [];

    case RECEIVE_TICKET:
      let ticket =
        action.ticket.entities && action.ticket.entities.ticketActivities
          ? action.ticket.entities.ticketActivities
          : null;
      return ticket
        ? union(
            state,
            Object.values(ticket).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case RECEIVE_TICKETS:
      let tickets =
        action.tickets.entities && action.tickets.entities.ticketActivities
          ? action.tickets.entities.ticketActivities
          : null;
      return tickets
        ? union(
            state,
            Object.values(tickets).map(tickets => {
              return tickets.id;
            })
          )
        : state;

    case SUCCESS_DELETE_TICKET:
      let ticketDelete =
        action.ticket.entities && action.ticket.entities.ticketActivities
          ? action.ticket.entities.ticketActivities
          : null;
      return ticketDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(ticketDelete).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case SUCCESS_CREATE_TICKET:
      let ticketCreate =
        action.ticket.entities && action.ticket.entities.ticketActivities
          ? action.ticket.entities.ticketActivities
          : null;
      return ticketCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(ticketCreate).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_TICKET:
      let ticketUpdate =
        action.ticket.entities && action.ticket.entities.ticketActivities
          ? action.ticket.entities.ticketActivities
          : null;
      return ticketUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(ticketUpdate).map(ticket => {
              return ticket.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_TICKETS:
      let ticketsUpdate =
        action.tickets.entities && action.tickets.entities.ticketActivities
          ? action.tickets.entities.ticketActivities
          : null;
      return ticketsUpdate
        ? union(
            state,
            Object.values(ticketsUpdate).map(tickets => {
              return tickets.id;
            })
          )
        : state;

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalTicketActivities(state = null, action) {
  switch (action.type) {
    case RECEIVE_TICKETACTIVITIES:
      return action.ticketActivities && action.ticketActivities.result.total
        ? action.ticketActivities.result.total
        : 0;
    case RESET_TICKETACTIVITIES:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_TICKETACTIVITY:
      let dato = action.ticketActivity.entities.ticketActivities;
      let ticketActivity =
        dato && Object.keys(dato).length > 0
          ? dato[action.ticketActivity.result]
          : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: ticketActivity ? ticketActivity : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_TICKETACTIVITY:
      let idsUpdate = [];
      Object.values(action.ticketActivity).map(ticketActivityUpdate => {
        if (ticketActivityUpdate && ticketActivityUpdate.id)
          idsUpdate.push(ticketActivityUpdate.id);
      });
      return merge({}, state, {
        activo: action.ticketActivity,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_TICKETACTIVITY:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_TICKETACTIVITY:
      let datoTicketActivityActualizado =
        action.ticketActivity.entities.ticketActivities;
      let ticketActivityNuevo =
        datoTicketActivityActualizado &&
        Object.keys(datoTicketActivityActualizado).length > 0
          ? datoTicketActivityActualizado[action.ticketActivity.result]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: ticketActivityNuevo
      });
    case ERROR_UPDATE_TICKETACTIVITY:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_TICKETACTIVITIES:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_TICKETACTIVITIES:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_TICKETACTIVITIES:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_TICKETACTIVITY:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    //TICKET
    //TODO ver si esta bien
    case SUCCESS_CREATE_TICKET:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_TICKET:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_TICKET:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_TICKETS:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });

    case DELETE_TICKETACTIVITY:
      let datoTicketActivityDelete = action.ticketActivity;
      let idsDelete = [];
      Object.values(action.ticketActivity).map(ticketActivityDelete => {
        if (ticketActivityDelete && ticketActivityDelete.id)
          idsDelete.push(ticketActivityDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoTicketActivityDelete)
          ),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_TICKETACTIVITY:
      let datoTicketActivityDeleteUpdate = action.ticketActivity;
      let idsDeleteUpdate = [];
      Object.values(action.ticketActivity).map(ticketActivityDelete => {
        if (ticketActivityDelete && ticketActivityDelete.id)
          idsDeleteUpdate.push(ticketActivityDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoTicketActivityDeleteUpdate)
          ),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_TICKETACTIVITY:
      let datoTicketActivityDeleted = {};
      if (
        Object.values(action.ticketActivity.entities.ticketActivities).length >
        0
      )
        datoTicketActivityDeleted = Object.values(
          action.ticketActivity.entities.ticketActivities
        )[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoTicketActivityDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_TICKETACTIVITY:
      let idsCreate = [];
      Object.values(action.ticketActivity).map(ticketActivityCreate => {
        if (ticketActivityCreate && ticketActivityCreate.id)
          idsCreate.push(ticketActivityCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.ticketActivity,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_TICKETACTIVITY:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_TICKETACTIVITY:
      let datoTicketActivityNuevo =
        action.ticketActivity.entities.ticketActivities;
      let ticketActivityNuevo =
        datoTicketActivityNuevo &&
        Object.keys(datoTicketActivityNuevo).length > 0
          ? datoTicketActivityNuevo[action.ticketActivity.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: ticketActivityNuevo,
        nuevos: []
      });
    case ERROR_CREATE_TICKETACTIVITY:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_TICKETACTIVITIES:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_TICKETACTIVITIES:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_TICKETACTIVITIES:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_TICKETACTIVITY:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    //TICKET
    case SUCCESS_CREATE_TICKET:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_TICKET:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_TICKET:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_TICKETS:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });

    case DELETE_TICKETACTIVITY:
      let datoTicketActivityDelete = action.ticketActivity;
      let idsDelete = [];
      Object.values(action.ticketActivity).map(ticketActivityDelete => {
        if (ticketActivityDelete && ticketActivityDelete.id)
          idsDelete.push(ticketActivityDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoTicketActivityDelete)
          ),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_TICKETACTIVITY:
      let datoTicketActivityDeleteCreate = action.ticketActivity;
      let idsDeleteCreate = [];
      Object.values(action.ticketActivity).map(ticketActivityDelete => {
        if (ticketActivityDelete && ticketActivityDelete.id)
          idsDeleteCreate.push(ticketActivityDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoTicketActivityDeleteCreate)
          ),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_TICKETACTIVITY:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.ticketActivity,
        error: null
      });
    case REQUEST_DELETE_TICKETACTIVITY:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_TICKETACTIVITY:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_TICKETACTIVITY:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_TICKETACTIVITY:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    //TICKET
    case SUCCESS_CREATE_TICKET:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_TICKET:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_TICKET:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_TICKETS:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_TICKETACTIVITY:
      let idsCreate = [];
      Object.values(action.ticketActivity).map(ticketActivityCreate => {
        if (ticketActivityCreate && ticketActivityCreate.id)
          idsCreate.push(ticketActivityCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.ticketActivity,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_TICKETACTIVITY:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_TICKETACTIVITY:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_TICKETACTIVITY:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_TICKETACTIVITIES:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_TICKETACTIVITIES:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_TICKETACTIVITIES:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_TICKETACTIVITY:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_TICKETACTIVITY:
      let datoTicketActivityDeleteCreate = action.ticketActivity;
      let idsDeleteCreate = [];
      Object.values(action.ticketActivity).map(ticketActivityDelete => {
        if (ticketActivityDelete && ticketActivityDelete.id)
          idsDeleteCreate.push(ticketActivityDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(
            clone(state.print),
            Object.keys(datoTicketActivityDeleteCreate)
          ),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const ticketActivities = combineReducers({
  byId: ticketActivitiesById,
  allIds: allTicketActivities,
  update: update,
  create: create,
  totalTicketActivities: totalTicketActivities,
  delete: deleter,
  print: print
});

export default ticketActivities;
