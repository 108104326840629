import {
  INVALIDATE_ATTRIBUTESGROUPS,
  ERROR_ATTRIBUTESGROUPS,
  RECEIVE_ATTRIBUTESGROUPS,
  REQUEST_ATTRIBUTESGROUPS,
  RESET_ATTRIBUTESGROUPS,
  ERROR_ATTRIBUTEGROUP,
  RECEIVE_ATTRIBUTEGROUP,
  REQUEST_ATTRIBUTEGROUP,
  UPDATE_ATTRIBUTEGROUP,
  REQUEST_UPDATE_ATTRIBUTEGROUP,
  SUCCESS_UPDATE_ATTRIBUTEGROUP,
  ERROR_UPDATE_ATTRIBUTEGROUP,
  RESET_UPDATE_ATTRIBUTEGROUP,
  REQUEST_UPDATE_ATTRIBUTESGROUPS,
  SUCCESS_UPDATE_ATTRIBUTESGROUPS,
  ERROR_UPDATE_ATTRIBUTESGROUPS,
  RESET_UPDATE_ATTRIBUTESGROUPS,
  CREATE_ATTRIBUTEGROUP,
  ERROR_CREATE_ATTRIBUTEGROUP,
  REQUEST_CREATE_ATTRIBUTEGROUP,
  RESET_CREATE_ATTRIBUTEGROUP,
  SUCCESS_CREATE_ATTRIBUTEGROUP,
  REQUEST_CREATE_ATTRIBUTESGROUPS,
  SUCCESS_CREATE_ATTRIBUTESGROUPS,
  ERROR_CREATE_ATTRIBUTESGROUPS,
  RESET_CREATE_ATTRIBUTESGROUPS,
  DELETE_ATTRIBUTEGROUP,
  DELETE_CREATE_ATTRIBUTEGROUP,
  DELETE_UPDATE_ATTRIBUTEGROUP,
  REQUEST_DELETE_ATTRIBUTEGROUP,
  SUCCESS_DELETE_ATTRIBUTEGROUP,
  ERROR_DELETE_ATTRIBUTEGROUP,
  RESET_DELETE_ATTRIBUTEGROUP,
  REQUEST_PRINT_ATTRIBUTEGROUP,
  SUCCESS_PRINT_ATTRIBUTEGROUP,
  ERROR_PRINT_ATTRIBUTEGROUP,
  RESET_PRINT_ATTRIBUTEGROUP,
  RECEIVE_FILE_ATTRIBUTEGROUP,
  REQUEST_PRINT_ATTRIBUTESGROUPS,
  SUCCESS_PRINT_ATTRIBUTESGROUPS,
  ERROR_PRINT_ATTRIBUTESGROUPS,
  RESET_PRINT_ATTRIBUTESGROUPS,
  RECEIVE_FILE_ATTRIBUTESGROUPS,
  PRINT_ATTRIBUTEGROUP,
  DELETE_PRINT_ATTRIBUTEGROUP
} from '../actions/AttributeGroupActions';

import {
  RECEIVE_PROPERTY,
  RECEIVE_PROPERTIES,
  SUCCESS_DELETE_PROPERTY,
  SUCCESS_CREATE_PROPERTY,
  SUCCESS_UPDATE_PROPERTY,
  SUCCESS_UPDATE_PROPERTIES
} from '../actions/PropertyActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    attributesGroups: {},
    files: {}
  };
}

function attributesGroupsById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_ATTRIBUTESGROUPS:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_ATTRIBUTESGROUPS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_ATTRIBUTESGROUPS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_ATTRIBUTESGROUPS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        attributesGroups: {}
      });
    case RECEIVE_ATTRIBUTESGROUPS:
      let dato = action.attributesGroups.entities.attributesGroups;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        attributesGroups: merge({}, state.attributesGroups, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_ATTRIBUTEGROUP:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_ATTRIBUTEGROUP:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_ATTRIBUTEGROUP:
      let datoAttributeGroup = action.attributeGroup.entities.attributesGroups;
      return Object.assign({}, state, {
        attributesGroups: merge({}, state.attributesGroups, datoAttributeGroup),
        isFetching: false
      });
    case RECEIVE_FILE_ATTRIBUTEGROUP:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_ATTRIBUTEGROUP:
      let datoAttributeGroupEliminado =
        action.attributeGroup.entities.attributesGroups;
      return Object.assign({}, state, {
        attributesGroups: mergeWith(
          clone(datoAttributeGroupEliminado),
          state.attributesGroups,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_ATTRIBUTEGROUP:
      let datoAttributeGroupCreado =
        action.attributeGroup.entities.attributesGroups;
      return Object.assign({}, state, {
        attributesGroups: mergeWith(
          clone(datoAttributeGroupCreado),
          state.attributesGroups,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_ATTRIBUTESGROUPS:
      let datosAttributeGroupCreado =
        action.attributesGroups.entities.attributesGroups;
      return Object.assign({}, state, {
        attributesGroups: mergeWith(
          clone(datosAttributeGroupCreado),
          state.attributesGroups,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_ATTRIBUTEGROUP:
      let datoAttributeGroupActualizado =
        action.attributeGroup.entities.attributesGroups;
      return Object.assign({}, state, {
        attributesGroups: mergeWith(
          clone(datoAttributeGroupActualizado),
          state.attributesGroups,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_ATTRIBUTESGROUPS:
      let datosAttributeGroupActualizado =
        action.attributesGroups.entities.attributesGroups;
      return Object.assign({}, state, {
        attributesGroups: mergeWith(
          clone(datosAttributeGroupActualizado),
          state.attributesGroups,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    //PROPERTY
    case RECEIVE_PROPERTY:
      let property =
        action.property.entities && action.property.entities.attributesGroups
          ? action.property.entities.attributesGroups
          : {};
      return Object.assign({}, state, {
        attributesGroups: merge({}, state.attributesGroups, property)
      });
    case RECEIVE_PROPERTIES:
      let properties =
        action.properties.entities &&
        action.properties.entities.attributesGroups
          ? action.properties.entities.attributesGroups
          : {};
      return Object.assign({}, state, {
        attributesGroups: merge({}, state.attributesGroups, properties)
      });
    case SUCCESS_DELETE_PROPERTY:
      let datopropertyEliminado =
        action.property.entities && action.property.entities.attributesGroups
          ? action.property.entities.attributesGroups
          : {};
      return Object.assign({}, state, {
        attributesGroups: mergeWith(
          clone(datopropertyEliminado),
          pickBy(state.attributesGroups, function (attributeGroup) {
            return attributeGroup.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_PROPERTY:
      let datopropertyCreado =
        action.property.entities && action.property.entities.attributesGroups
          ? action.property.entities.attributesGroups
          : {};
      return Object.assign({}, state, {
        attributesGroups: mergeWith(
          clone(datopropertyCreado),
          pickBy(state.attributesGroups, function (attributeGroup) {
            return attributeGroup.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTY:
      let datopropertyActualizado =
        action.property.entities && action.property.entities.attributesGroups
          ? action.property.entities.attributesGroups
          : {};
      return Object.assign({}, state, {
        attributesGroups: mergeWith(
          clone(datopropertyActualizado),
          pickBy(state.attributesGroups, function (attributeGroup) {
            return attributeGroup.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTIES:
      let datospropertyActualizado =
        action.properties.entities &&
        action.properties.entities.attributesGroups
          ? action.properties.entities.attributesGroups
          : {};
      return Object.assign({}, state, {
        attributesGroups: mergeWith(
          clone(datospropertyActualizado),
          state.attributesGroups,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        attributesGroups: {}
      });
    default:
      return state;
  }
}

function allAttributesGroups(state = [], action) {
  switch (action.type) {
    case RECEIVE_ATTRIBUTESGROUPS:
      return action.attributesGroups.result &&
        action.attributesGroups.result.attributesGroups
        ? union(action.attributesGroups.result.attributesGroups, state)
        : action.attributesGroups.result
        ? action.attributesGroups.result
        : state;
    case RECEIVE_ATTRIBUTEGROUP:
      return action.attributeGroup.result
        ? union([action.attributeGroup.result], state)
        : state;

    case SUCCESS_CREATE_ATTRIBUTEGROUP:
      let datoAttributeGroupSCreate =
        action.attributeGroup.entities.attributesGroups;
      let idNuevoSCreate = null;
      if (Object.values(datoAttributeGroupSCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoAttributeGroupSCreate)[0] &&
          Object.values(datoAttributeGroupSCreate)[0].id
            ? Object.values(datoAttributeGroupSCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_ATTRIBUTESGROUPS:
      let attributesGroupsCreate =
        action.attributesGroups.entities &&
        action.attributesGroups.entities.attributesGroups
          ? action.attributesGroups.entities.attributesGroups
          : null;
      return attributesGroupsCreate
        ? union(
            state,
            Object.values(attributesGroupsCreate).map(attributesGroups => {
              return attributesGroups.id;
            })
          )
        : state;
    case RESET_ATTRIBUTESGROUPS:
      return [];

    case RECEIVE_PROPERTY:
      let property =
        action.property.entities && action.property.entities.attributesGroups
          ? action.property.entities.attributesGroups
          : null;
      return property
        ? union(
            state,
            Object.values(property).map(property => {
              return property.id;
            })
          )
        : state;
    case RECEIVE_PROPERTIES:
      let properties =
        action.properties.entities &&
        action.properties.entities.attributesGroups
          ? action.properties.entities.attributesGroups
          : null;
      return properties
        ? union(
            state,
            Object.values(properties).map(properties => {
              return properties.id;
            })
          )
        : state;

    case SUCCESS_DELETE_PROPERTY:
      let propertyDelete =
        action.property.entities && action.property.entities.attributesGroups
          ? action.property.entities.attributesGroups
          : null;
      return propertyDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyDelete).map(property => {
              return property.id;
            })
          )
        : state;
    case SUCCESS_CREATE_PROPERTY:
      let propertyCreate =
        action.property.entities && action.property.entities.attributesGroups
          ? action.property.entities.attributesGroups
          : null;
      return propertyCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyCreate).map(property => {
              return property.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_PROPERTY:
      let propertyUpdate =
        action.property.entities && action.property.entities.attributesGroups
          ? action.property.entities.attributesGroups
          : null;
      return propertyUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(propertyUpdate).map(property => {
              return property.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_PROPERTIES:
      let propertiesUpdate =
        action.properties.entities &&
        action.properties.entities.attributesGroups
          ? action.properties.entities.attributesGroups
          : null;
      return propertiesUpdate
        ? union(
            state,
            Object.values(propertiesUpdate).map(properties => {
              return properties.id;
            })
          )
        : state;

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalAttributesGroups(state = null, action) {
  switch (action.type) {
    case RECEIVE_ATTRIBUTESGROUPS:
      return action.attributesGroups && action.attributesGroups.result.total
        ? action.attributesGroups.result.total
        : 0;
    case RESET_ATTRIBUTESGROUPS:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_ATTRIBUTEGROUP:
      let dato = action.attributeGroup.entities.attributesGroups;
      let attributeGroup =
        dato && Object.keys(dato).length > 0
          ? dato[action.attributeGroup.result]
          : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: attributeGroup ? attributeGroup : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_ATTRIBUTEGROUP:
      let idsUpdate = [];
      Object.values(action.attributeGroup).map(attributeGroupUpdate => {
        if (attributeGroupUpdate && attributeGroupUpdate.id)
          idsUpdate.push(attributeGroupUpdate.id);
      });
      return merge({}, state, {
        activo: action.attributeGroup,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_ATTRIBUTEGROUP:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_ATTRIBUTEGROUP:
      let datoAttributeGroupActualizado =
        action.attributeGroup.entities.attributesGroups;
      let attributeGroupNuevo =
        datoAttributeGroupActualizado &&
        Object.keys(datoAttributeGroupActualizado).length > 0
          ? datoAttributeGroupActualizado[action.attributeGroup.result]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: attributeGroupNuevo
      });
    case ERROR_UPDATE_ATTRIBUTEGROUP:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_ATTRIBUTESGROUPS:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_ATTRIBUTESGROUPS:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_ATTRIBUTESGROUPS:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_ATTRIBUTEGROUP:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    //PROPERTY
    //TODO ver si esta bien
    case SUCCESS_CREATE_PROPERTY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_PROPERTY:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });

    case DELETE_ATTRIBUTEGROUP:
      let datoAttributeGroupDelete = action.attributeGroup;
      let idsDelete = [];
      Object.values(action.attributeGroup).map(attributeGroupDelete => {
        if (attributeGroupDelete && attributeGroupDelete.id)
          idsDelete.push(attributeGroupDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoAttributeGroupDelete)
          ),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_ATTRIBUTEGROUP:
      let datoAttributeGroupDeleteUpdate = action.attributeGroup;
      let idsDeleteUpdate = [];
      Object.values(action.attributeGroup).map(attributeGroupDelete => {
        if (attributeGroupDelete && attributeGroupDelete.id)
          idsDeleteUpdate.push(attributeGroupDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoAttributeGroupDeleteUpdate)
          ),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_ATTRIBUTEGROUP:
      let datoAttributeGroupDeleted = {};
      if (
        Object.values(action.attributeGroup.entities.attributesGroups).length >
        0
      )
        datoAttributeGroupDeleted = Object.values(
          action.attributeGroup.entities.attributesGroups
        )[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoAttributeGroupDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_ATTRIBUTEGROUP:
      let idsCreate = [];
      Object.values(action.attributeGroup).map(attributeGroupCreate => {
        if (attributeGroupCreate && attributeGroupCreate.id)
          idsCreate.push(attributeGroupCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.attributeGroup,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_ATTRIBUTEGROUP:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_ATTRIBUTEGROUP:
      let datoAttributeGroupNuevo =
        action.attributeGroup.entities.attributesGroups;
      let attributeGroupNuevo =
        datoAttributeGroupNuevo &&
        Object.keys(datoAttributeGroupNuevo).length > 0
          ? datoAttributeGroupNuevo[action.attributeGroup.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: attributeGroupNuevo,
        nuevos: []
      });
    case ERROR_CREATE_ATTRIBUTEGROUP:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_ATTRIBUTESGROUPS:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_ATTRIBUTESGROUPS:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_ATTRIBUTESGROUPS:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_ATTRIBUTEGROUP:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    //PROPERTY
    case SUCCESS_CREATE_PROPERTY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_PROPERTY:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });

    case DELETE_ATTRIBUTEGROUP:
      let datoAttributeGroupDelete = action.attributeGroup;
      let idsDelete = [];
      Object.values(action.attributeGroup).map(attributeGroupDelete => {
        if (attributeGroupDelete && attributeGroupDelete.id)
          idsDelete.push(attributeGroupDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoAttributeGroupDelete)
          ),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_ATTRIBUTEGROUP:
      let datoAttributeGroupDeleteCreate = action.attributeGroup;
      let idsDeleteCreate = [];
      Object.values(action.attributeGroup).map(attributeGroupDelete => {
        if (attributeGroupDelete && attributeGroupDelete.id)
          idsDeleteCreate.push(attributeGroupDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoAttributeGroupDeleteCreate)
          ),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_ATTRIBUTEGROUP:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.attributeGroup,
        error: null
      });
    case REQUEST_DELETE_ATTRIBUTEGROUP:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_ATTRIBUTEGROUP:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_ATTRIBUTEGROUP:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_ATTRIBUTEGROUP:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    //PROPERTY
    case SUCCESS_CREATE_PROPERTY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_PROPERTY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_PROPERTY:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_PROPERTIES:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_ATTRIBUTEGROUP:
      let idsCreate = [];
      Object.values(action.attributeGroup).map(attributeGroupCreate => {
        if (attributeGroupCreate && attributeGroupCreate.id)
          idsCreate.push(attributeGroupCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.attributeGroup,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_ATTRIBUTEGROUP:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_ATTRIBUTEGROUP:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_ATTRIBUTEGROUP:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_ATTRIBUTESGROUPS:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_ATTRIBUTESGROUPS:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_ATTRIBUTESGROUPS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_ATTRIBUTEGROUP:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_ATTRIBUTEGROUP:
      let datoAttributeGroupDeleteCreate = action.attributeGroup;
      let idsDeleteCreate = [];
      Object.values(action.attributeGroup).map(attributeGroupDelete => {
        if (attributeGroupDelete && attributeGroupDelete.id)
          idsDeleteCreate.push(attributeGroupDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(
            clone(state.print),
            Object.keys(datoAttributeGroupDeleteCreate)
          ),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const attributesGroups = combineReducers({
  byId: attributesGroupsById,
  allIds: allAttributesGroups,
  update: update,
  create: create,
  totalAttributesGroups: totalAttributesGroups,
  delete: deleter,
  print: print
});

export default attributesGroups;
