import {
  INVALIDATE_TICKETS,
  ERROR_TICKETS,
  RECEIVE_TICKETS,
  REQUEST_TICKETS,
  RESET_TICKETS,
  ERROR_TICKET,
  RECEIVE_TICKET,
  REQUEST_TICKET,
  UPDATE_TICKET,
  REQUEST_UPDATE_TICKET,
  SUCCESS_UPDATE_TICKET,
  ERROR_UPDATE_TICKET,
  RESET_UPDATE_TICKET,
  REQUEST_UPDATE_TICKETS,
  SUCCESS_UPDATE_TICKETS,
  ERROR_UPDATE_TICKETS,
  RESET_UPDATE_TICKETS,
  CREATE_TICKET,
  ERROR_CREATE_TICKET,
  REQUEST_CREATE_TICKET,
  RESET_CREATE_TICKET,
  SUCCESS_CREATE_TICKET,
  REQUEST_CREATE_TICKETS,
  SUCCESS_CREATE_TICKETS,
  ERROR_CREATE_TICKETS,
  RESET_CREATE_TICKETS,
  DELETE_TICKET,
  DELETE_CREATE_TICKET,
  DELETE_UPDATE_TICKET,
  REQUEST_DELETE_TICKET,
  SUCCESS_DELETE_TICKET,
  ERROR_DELETE_TICKET,
  RESET_DELETE_TICKET,
  REQUEST_PRINT_TICKET,
  SUCCESS_PRINT_TICKET,
  ERROR_PRINT_TICKET,
  RESET_PRINT_TICKET,
  RECEIVE_FILE_TICKET,
  REQUEST_PRINT_TICKETS,
  SUCCESS_PRINT_TICKETS,
  ERROR_PRINT_TICKETS,
  RESET_PRINT_TICKETS,
  RECEIVE_FILE_TICKETS,
  PRINT_TICKET,
  DELETE_PRINT_TICKET
} from '../actions/TicketActions';
import {
  CREATE_TICKETACTIVITY,
  UPDATE_TICKETACTIVITY,
  DELETE_TICKETACTIVITY
} from '../actions/TicketActivityActions';
import {
  CREATE_TICKETPROPERTY,
  UPDATE_TICKETPROPERTY,
  DELETE_TICKETPROPERTY
} from '../actions/TicketPropertyActions';
import {
  CREATE_PROPERTYCLOSEDOPERATION,
  UPDATE_PROPERTYCLOSEDOPERATION,
  DELETE_PROPERTYCLOSEDOPERATION
} from '../actions/PropertyClosedOperationActions';

import {
  RECEIVE_USER,
  RECEIVE_USERS,
  SUCCESS_DELETE_USER,
  SUCCESS_CREATE_USER,
  SUCCESS_UPDATE_USER,
  SUCCESS_UPDATE_USERS
} from '../actions/UserActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    tickets: {},
    files: {}
  };
}

function ticketsById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_TICKETS:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_TICKETS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_TICKETS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_TICKETS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        tickets: {}
      });
    case RECEIVE_TICKETS:
      let dato = action.tickets.entities.tickets;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        tickets: merge({}, state.tickets, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_TICKET:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_TICKET:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_TICKET:
      let datoTicket = action.ticket.entities.tickets;
      return Object.assign({}, state, {
        tickets: merge({}, state.tickets, datoTicket),
        isFetching: false
      });
    case RECEIVE_FILE_TICKET:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_TICKET:
      let datoTicketEliminado = action.ticket.entities.tickets;
      return Object.assign({}, state, {
        tickets: mergeWith(
          clone(datoTicketEliminado),
          state.tickets,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_TICKET:
      let datoTicketCreado = action.ticket.entities.tickets;
      return Object.assign({}, state, {
        tickets: mergeWith(
          clone(datoTicketCreado),
          state.tickets,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_TICKETS:
      let datosTicketCreado = action.tickets.entities.tickets;
      return Object.assign({}, state, {
        tickets: mergeWith(
          clone(datosTicketCreado),
          state.tickets,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TICKET:
      let datoTicketActualizado = action.ticket.entities.tickets;
      return Object.assign({}, state, {
        tickets: mergeWith(
          clone(datoTicketActualizado),
          state.tickets,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TICKETS:
      let datosTicketActualizado = action.tickets.entities.tickets;
      return Object.assign({}, state, {
        tickets: mergeWith(
          clone(datosTicketActualizado),
          state.tickets,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    //USER
    case RECEIVE_USER:
      let user =
        action.user.entities && action.user.entities.tickets
          ? action.user.entities.tickets
          : {};
      return Object.assign({}, state, {
        tickets: merge({}, state.tickets, user)
      });
    case RECEIVE_USERS:
      let users =
        action.users.entities && action.users.entities.tickets
          ? action.users.entities.tickets
          : {};
      return Object.assign({}, state, {
        tickets: merge({}, state.tickets, users)
      });
    case SUCCESS_DELETE_USER:
      let datouserEliminado =
        action.user.entities && action.user.entities.tickets
          ? action.user.entities.tickets
          : {};
      return Object.assign({}, state, {
        tickets: mergeWith(
          clone(datouserEliminado),
          pickBy(state.tickets, function (ticket) {
            return ticket.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_USER:
      let datouserCreado =
        action.user.entities && action.user.entities.tickets
          ? action.user.entities.tickets
          : {};
      return Object.assign({}, state, {
        tickets: mergeWith(
          clone(datouserCreado),
          pickBy(state.tickets, function (ticket) {
            return ticket.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_USER:
      let datouserActualizado =
        action.user.entities && action.user.entities.tickets
          ? action.user.entities.tickets
          : {};
      return Object.assign({}, state, {
        tickets: mergeWith(
          clone(datouserActualizado),
          pickBy(state.tickets, function (ticket) {
            return ticket.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_USERS:
      let datosuserActualizado =
        action.users.entities && action.users.entities.tickets
          ? action.users.entities.tickets
          : {};
      return Object.assign({}, state, {
        tickets: mergeWith(
          clone(datosuserActualizado),
          state.tickets,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        tickets: {}
      });
    default:
      return state;
  }
}

function allTickets(state = [], action) {
  switch (action.type) {
    case RECEIVE_TICKETS:
      return action.tickets.result && action.tickets.result.tickets
        ? union(action.tickets.result.tickets, state)
        : action.tickets.result
        ? action.tickets.result
        : state;
    case RECEIVE_TICKET:
      return action.ticket.result
        ? union([action.ticket.result], state)
        : state;

    case SUCCESS_CREATE_TICKET:
      let datoTicketSCreate = action.ticket.entities.tickets;
      let idNuevoSCreate = null;
      if (Object.values(datoTicketSCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoTicketSCreate)[0] &&
          Object.values(datoTicketSCreate)[0].id
            ? Object.values(datoTicketSCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_TICKETS:
      let ticketsCreate =
        action.tickets.entities && action.tickets.entities.tickets
          ? action.tickets.entities.tickets
          : null;
      return ticketsCreate
        ? union(
            state,
            Object.values(ticketsCreate).map(tickets => {
              return tickets.id;
            })
          )
        : state;
    case RESET_TICKETS:
      return [];

    case RECEIVE_USER:
      let user =
        action.user.entities && action.user.entities.tickets
          ? action.user.entities.tickets
          : null;
      return user
        ? union(
            state,
            Object.values(user).map(user => {
              return user.id;
            })
          )
        : state;
    case RECEIVE_USERS:
      let users =
        action.users.entities && action.users.entities.tickets
          ? action.users.entities.tickets
          : null;
      return users
        ? union(
            state,
            Object.values(users).map(users => {
              return users.id;
            })
          )
        : state;

    case SUCCESS_DELETE_USER:
      let userDelete =
        action.user.entities && action.user.entities.tickets
          ? action.user.entities.tickets
          : null;
      return userDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(userDelete).map(user => {
              return user.id;
            })
          )
        : state;
    case SUCCESS_CREATE_USER:
      let userCreate =
        action.user.entities && action.user.entities.tickets
          ? action.user.entities.tickets
          : null;
      return userCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(userCreate).map(user => {
              return user.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_USER:
      let userUpdate =
        action.user.entities && action.user.entities.tickets
          ? action.user.entities.tickets
          : null;
      return userUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(userUpdate).map(user => {
              return user.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_USERS:
      let usersUpdate =
        action.users.entities && action.users.entities.tickets
          ? action.users.entities.tickets
          : null;
      return usersUpdate
        ? union(
            state,
            Object.values(usersUpdate).map(users => {
              return users.id;
            })
          )
        : state;

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalTickets(state = null, action) {
  switch (action.type) {
    case RECEIVE_TICKETS:
      return action.tickets && action.tickets.result.total
        ? action.tickets.result.total
        : 0;
    case RESET_TICKETS:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_TICKET:
      let dato = action.ticket.entities.tickets;
      let ticket =
        dato && Object.keys(dato).length > 0 ? dato[action.ticket.result] : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: ticket ? ticket : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_TICKET:
      let idsUpdate = [];
      Object.values(action.ticket).map(ticketUpdate => {
        if (ticketUpdate && ticketUpdate.id) idsUpdate.push(ticketUpdate.id);
      });
      return merge({}, state, {
        activo: action.ticket,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_TICKET:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_TICKET:
      let datoTicketActualizado = action.ticket.entities.tickets;
      let ticketNuevo =
        datoTicketActualizado && Object.keys(datoTicketActualizado).length > 0
          ? datoTicketActualizado[action.ticket.result]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: ticketNuevo
      });
    case ERROR_UPDATE_TICKET:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_TICKETS:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_TICKETS:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_TICKETS:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_TICKET:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    case CREATE_TICKETACTIVITY:
      let ticketticketActivityCreateActivo = clone(state.activo);
      let ticketticketActivityCreateActivos = clone(state.activos);
      Object.values(action.ticketActivity).map(ticketActivityCreate => {
        if (
          ticketActivityCreate &&
          ticketActivityCreate.ticket_id &&
          ticketticketActivityCreateActivo[ticketActivityCreate.ticket_id]
        ) {
          if (ticketActivityCreate.ticket_id.toString().indexOf('-') === -1)
            ticketticketActivityCreateActivo[
              ticketActivityCreate.ticket_id
            ].ticket_activity = union(
              ticketticketActivityCreateActivo.ticket_activity,
              [ticketActivityCreate.id]
            );
        } else if (ticketActivityCreate) {
          ticketticketActivityCreateActivo.ticket_activity = union(
            ticketticketActivityCreateActivo.ticket_activity
              ? ticketticketActivityCreateActivo.ticket_activity
              : [],
            [ticketActivityCreate.id]
          );
        }
        if (
          ticketActivityCreate &&
          ticketActivityCreate.ticket_id &&
          ticketActivityCreate.ticket_id.toString().indexOf('-') === -1
        )
          ticketticketActivityCreateActivos = union(
            ticketticketActivityCreateActivos,
            [ticketActivityCreate.ticket_id]
          );
      });
      return Object.assign({}, state, {
        activo: ticketticketActivityCreateActivo,
        activos: ticketticketActivityCreateActivos
      });
    case UPDATE_TICKETACTIVITY:
      let ticketticketActivityUpdateActivo = clone(state.activo);
      let ticketticketActivityUpdateActivos = clone(state.activos);
      Object.values(action.ticketActivity).map(ticketActivityUpdate => {
        if (
          ticketActivityUpdate &&
          ticketActivityUpdate.ticket_id &&
          ticketticketActivityUpdateActivo[ticketActivityUpdate.ticket_id]
        ) {
          if (ticketActivityUpdate.ticket_id.toString().indexOf('-') === -1)
            ticketticketActivityUpdateActivo[
              ticketActivityUpdate.ticket_id
            ].ticket_activity = union(
              ticketticketActivityUpdateActivo.ticket_activity,
              [ticketActivityUpdate.id]
            );
        } else if (ticketActivityUpdate) {
          ticketticketActivityUpdateActivo.ticket_activity = union(
            ticketticketActivityUpdateActivo.ticket_activity
              ? ticketticketActivityUpdateActivo.ticket_activity
              : [],
            [ticketActivityUpdate.id]
          );
        }
        if (
          ticketActivityUpdate &&
          ticketActivityUpdate.ticket_id &&
          ticketActivityUpdate.ticket_id.toString().indexOf('-') === -1
        )
          ticketticketActivityUpdateActivos = union(
            ticketticketActivityUpdateActivos,
            [ticketActivityUpdate.ticket_id]
          );
      });
      return Object.assign({}, state, {
        activo: ticketticketActivityUpdateActivo,
        activos: ticketticketActivityUpdateActivos
      });
    case DELETE_TICKETACTIVITY:
      let ticketticketActivityDeleteActivo = clone(state.activo);
      let ticketticketActivityDeleteActivos = clone(state.activos);
      Object.values(action.ticketActivity).map(ticketActivityDelete => {
        if (
          ticketActivityDelete &&
          ticketActivityDelete.ticket_id &&
          ticketticketActivityDeleteActivo[ticketActivityDelete.ticket_id]
        ) {
          if (ticketActivityDelete.ticket_id.toString().indexOf('-') === -1)
            ticketticketActivityDeleteActivo[
              ticketActivityDelete.ticket_id
            ].ticket_activity = difference(
              ticketticketActivityDeleteActivo.ticket_activity,
              [ticketActivityDelete.id]
            );
        } else if (ticketActivityDelete) {
          ticketticketActivityDeleteActivo.ticket_activity = difference(
            ticketticketActivityDeleteActivo.ticket_activity
              ? ticketticketActivityDeleteActivo.ticket_activity
              : [],
            [ticketActivityDelete.id]
          );
        }
        if (
          ticketActivityDelete &&
          ticketActivityDelete.ticket_id &&
          ticketActivityDelete.ticket_id.toString().indexOf('-') === -1
        )
          ticketticketActivityDeleteActivos = union(
            ticketticketActivityDeleteActivos,
            [ticketActivityDelete.ticket_id]
          );
      });
      return Object.assign({}, state, {
        activo: ticketticketActivityDeleteActivo,
        activos: ticketticketActivityDeleteActivos
      });
    case CREATE_TICKETPROPERTY:
      let ticketticketPropertyCreateActivo = clone(state.activo);
      let ticketticketPropertyCreateActivos = clone(state.activos);
      Object.values(action.ticketProperty).map(ticketPropertyCreate => {
        if (
          ticketPropertyCreate &&
          ticketPropertyCreate.ticket_id &&
          ticketticketPropertyCreateActivo[ticketPropertyCreate.ticket_id]
        ) {
          if (ticketPropertyCreate.ticket_id.toString().indexOf('-') === -1)
            ticketticketPropertyCreateActivo[
              ticketPropertyCreate.ticket_id
            ].ticket_property = union(
              ticketticketPropertyCreateActivo.ticket_property,
              [ticketPropertyCreate.id]
            );
        } else if (ticketPropertyCreate) {
          ticketticketPropertyCreateActivo.ticket_property = union(
            ticketticketPropertyCreateActivo.ticket_property
              ? ticketticketPropertyCreateActivo.ticket_property
              : [],
            [ticketPropertyCreate.id]
          );
        }
        if (
          ticketPropertyCreate &&
          ticketPropertyCreate.ticket_id &&
          ticketPropertyCreate.ticket_id.toString().indexOf('-') === -1
        )
          ticketticketPropertyCreateActivos = union(
            ticketticketPropertyCreateActivos,
            [ticketPropertyCreate.ticket_id]
          );
      });
      return Object.assign({}, state, {
        activo: ticketticketPropertyCreateActivo,
        activos: ticketticketPropertyCreateActivos
      });
    case UPDATE_TICKETPROPERTY:
      let ticketticketPropertyUpdateActivo = clone(state.activo);
      let ticketticketPropertyUpdateActivos = clone(state.activos);
      Object.values(action.ticketProperty).map(ticketPropertyUpdate => {
        if (
          ticketPropertyUpdate &&
          ticketPropertyUpdate.ticket_id &&
          ticketticketPropertyUpdateActivo[ticketPropertyUpdate.ticket_id]
        ) {
          if (ticketPropertyUpdate.ticket_id.toString().indexOf('-') === -1)
            ticketticketPropertyUpdateActivo[
              ticketPropertyUpdate.ticket_id
            ].ticket_property = union(
              ticketticketPropertyUpdateActivo.ticket_property,
              [ticketPropertyUpdate.id]
            );
        } else if (ticketPropertyUpdate) {
          ticketticketPropertyUpdateActivo.ticket_property = union(
            ticketticketPropertyUpdateActivo.ticket_property
              ? ticketticketPropertyUpdateActivo.ticket_property
              : [],
            [ticketPropertyUpdate.id]
          );
        }
        if (
          ticketPropertyUpdate &&
          ticketPropertyUpdate.ticket_id &&
          ticketPropertyUpdate.ticket_id.toString().indexOf('-') === -1
        )
          ticketticketPropertyUpdateActivos = union(
            ticketticketPropertyUpdateActivos,
            [ticketPropertyUpdate.ticket_id]
          );
      });
      return Object.assign({}, state, {
        activo: ticketticketPropertyUpdateActivo,
        activos: ticketticketPropertyUpdateActivos
      });
    case DELETE_TICKETPROPERTY:
      let ticketticketPropertyDeleteActivo = clone(state.activo);
      let ticketticketPropertyDeleteActivos = clone(state.activos);
      Object.values(action.ticketProperty).map(ticketPropertyDelete => {
        if (
          ticketPropertyDelete &&
          ticketPropertyDelete.ticket_id &&
          ticketticketPropertyDeleteActivo[ticketPropertyDelete.ticket_id]
        ) {
          if (ticketPropertyDelete.ticket_id.toString().indexOf('-') === -1)
            ticketticketPropertyDeleteActivo[
              ticketPropertyDelete.ticket_id
            ].ticket_property = difference(
              ticketticketPropertyDeleteActivo.ticket_property,
              [ticketPropertyDelete.id]
            );
        } else if (ticketPropertyDelete) {
          ticketticketPropertyDeleteActivo.ticket_property = difference(
            ticketticketPropertyDeleteActivo.ticket_property
              ? ticketticketPropertyDeleteActivo.ticket_property
              : [],
            [ticketPropertyDelete.id]
          );
        }
        if (
          ticketPropertyDelete &&
          ticketPropertyDelete.ticket_id &&
          ticketPropertyDelete.ticket_id.toString().indexOf('-') === -1
        )
          ticketticketPropertyDeleteActivos = union(
            ticketticketPropertyDeleteActivos,
            [ticketPropertyDelete.ticket_id]
          );
      });
      return Object.assign({}, state, {
        activo: ticketticketPropertyDeleteActivo,
        activos: ticketticketPropertyDeleteActivos
      });
    case CREATE_PROPERTYCLOSEDOPERATION:
      let ticketpropertyClosedOperationCreateActivo = clone(state.activo);
      let ticketpropertyClosedOperationCreateActivos = clone(state.activos);
      Object.values(action.propertyClosedOperation).map(
        propertyClosedOperationCreate => {
          if (
            propertyClosedOperationCreate &&
            propertyClosedOperationCreate.ticket_id &&
            ticketpropertyClosedOperationCreateActivo[
              propertyClosedOperationCreate.ticket_id
            ]
          ) {
            if (
              propertyClosedOperationCreate.ticket_id
                .toString()
                .indexOf('-') === -1
            )
              ticketpropertyClosedOperationCreateActivo[
                propertyClosedOperationCreate.ticket_id
              ].property_closed_operation = union(
                ticketpropertyClosedOperationCreateActivo.property_closed_operation,
                [propertyClosedOperationCreate.id]
              );
          } else if (propertyClosedOperationCreate) {
            ticketpropertyClosedOperationCreateActivo.property_closed_operation =
              union(
                ticketpropertyClosedOperationCreateActivo.property_closed_operation
                  ? ticketpropertyClosedOperationCreateActivo.property_closed_operation
                  : [],
                [propertyClosedOperationCreate.id]
              );
          }
          if (
            propertyClosedOperationCreate &&
            propertyClosedOperationCreate.ticket_id &&
            propertyClosedOperationCreate.ticket_id.toString().indexOf('-') ===
              -1
          )
            ticketpropertyClosedOperationCreateActivos = union(
              ticketpropertyClosedOperationCreateActivos,
              [propertyClosedOperationCreate.ticket_id]
            );
        }
      );
      return Object.assign({}, state, {
        activo: ticketpropertyClosedOperationCreateActivo,
        activos: ticketpropertyClosedOperationCreateActivos
      });
    case UPDATE_PROPERTYCLOSEDOPERATION:
      let ticketpropertyClosedOperationUpdateActivo = clone(state.activo);
      let ticketpropertyClosedOperationUpdateActivos = clone(state.activos);
      Object.values(action.propertyClosedOperation).map(
        propertyClosedOperationUpdate => {
          if (
            propertyClosedOperationUpdate &&
            propertyClosedOperationUpdate.ticket_id &&
            ticketpropertyClosedOperationUpdateActivo[
              propertyClosedOperationUpdate.ticket_id
            ]
          ) {
            if (
              propertyClosedOperationUpdate.ticket_id
                .toString()
                .indexOf('-') === -1
            )
              ticketpropertyClosedOperationUpdateActivo[
                propertyClosedOperationUpdate.ticket_id
              ].property_closed_operation = union(
                ticketpropertyClosedOperationUpdateActivo.property_closed_operation,
                [propertyClosedOperationUpdate.id]
              );
          } else if (propertyClosedOperationUpdate) {
            ticketpropertyClosedOperationUpdateActivo.property_closed_operation =
              union(
                ticketpropertyClosedOperationUpdateActivo.property_closed_operation
                  ? ticketpropertyClosedOperationUpdateActivo.property_closed_operation
                  : [],
                [propertyClosedOperationUpdate.id]
              );
          }
          if (
            propertyClosedOperationUpdate &&
            propertyClosedOperationUpdate.ticket_id &&
            propertyClosedOperationUpdate.ticket_id.toString().indexOf('-') ===
              -1
          )
            ticketpropertyClosedOperationUpdateActivos = union(
              ticketpropertyClosedOperationUpdateActivos,
              [propertyClosedOperationUpdate.ticket_id]
            );
        }
      );
      return Object.assign({}, state, {
        activo: ticketpropertyClosedOperationUpdateActivo,
        activos: ticketpropertyClosedOperationUpdateActivos
      });
    case DELETE_PROPERTYCLOSEDOPERATION:
      let ticketpropertyClosedOperationDeleteActivo = clone(state.activo);
      let ticketpropertyClosedOperationDeleteActivos = clone(state.activos);
      Object.values(action.propertyClosedOperation).map(
        propertyClosedOperationDelete => {
          if (
            propertyClosedOperationDelete &&
            propertyClosedOperationDelete.ticket_id &&
            ticketpropertyClosedOperationDeleteActivo[
              propertyClosedOperationDelete.ticket_id
            ]
          ) {
            if (
              propertyClosedOperationDelete.ticket_id
                .toString()
                .indexOf('-') === -1
            )
              ticketpropertyClosedOperationDeleteActivo[
                propertyClosedOperationDelete.ticket_id
              ].property_closed_operation = difference(
                ticketpropertyClosedOperationDeleteActivo.property_closed_operation,
                [propertyClosedOperationDelete.id]
              );
          } else if (propertyClosedOperationDelete) {
            ticketpropertyClosedOperationDeleteActivo.property_closed_operation =
              difference(
                ticketpropertyClosedOperationDeleteActivo.property_closed_operation
                  ? ticketpropertyClosedOperationDeleteActivo.property_closed_operation
                  : [],
                [propertyClosedOperationDelete.id]
              );
          }
          if (
            propertyClosedOperationDelete &&
            propertyClosedOperationDelete.ticket_id &&
            propertyClosedOperationDelete.ticket_id.toString().indexOf('-') ===
              -1
          )
            ticketpropertyClosedOperationDeleteActivos = union(
              ticketpropertyClosedOperationDeleteActivos,
              [propertyClosedOperationDelete.ticket_id]
            );
        }
      );
      return Object.assign({}, state, {
        activo: ticketpropertyClosedOperationDeleteActivo,
        activos: ticketpropertyClosedOperationDeleteActivos
      });

    //USER
    //TODO ver si esta bien
    case SUCCESS_CREATE_USER:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_USER:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_USER:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_USERS:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });

    case DELETE_TICKET:
      let datoTicketDelete = action.ticket;
      let idsDelete = [];
      Object.values(action.ticket).map(ticketDelete => {
        if (ticketDelete && ticketDelete.id) idsDelete.push(ticketDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(clone(state.activo), Object.keys(datoTicketDelete)),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_TICKET:
      let datoTicketDeleteUpdate = action.ticket;
      let idsDeleteUpdate = [];
      Object.values(action.ticket).map(ticketDelete => {
        if (ticketDelete && ticketDelete.id)
          idsDeleteUpdate.push(ticketDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoTicketDeleteUpdate)
          ),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_TICKET:
      let datoTicketDeleted = {};
      if (Object.values(action.ticket.entities.tickets).length > 0)
        datoTicketDeleted = Object.values(action.ticket.entities.tickets)[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoTicketDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_TICKET:
      let idsCreate = [];
      Object.values(action.ticket).map(ticketCreate => {
        if (ticketCreate && ticketCreate.id) idsCreate.push(ticketCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.ticket,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_TICKET:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_TICKET:
      let datoTicketNuevo = action.ticket.entities.tickets;
      let ticketNuevo =
        datoTicketNuevo && Object.keys(datoTicketNuevo).length > 0
          ? datoTicketNuevo[action.ticket.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: ticketNuevo,
        nuevos: []
      });
    case ERROR_CREATE_TICKET:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_TICKETS:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_TICKETS:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_TICKETS:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_TICKET:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    //CREATE TICKETACTIVITY
    case CREATE_TICKETACTIVITY:
      let ticketticketActivityCreateActivo = clone(state.nuevo);
      Object.values(action.ticketActivity).map(ticketActivityCreate => {
        if (
          ticketActivityCreate &&
          ticketActivityCreate.ticket_id &&
          ticketticketActivityCreateActivo[ticketActivityCreate.ticket_id]
        ) {
          if (ticketActivityCreate.ticket_id.toString().indexOf('-') !== -1)
            ticketticketActivityCreateActivo[
              ticketActivityCreate.ticket_id
            ].ticket_activity = union(
              ticketticketActivityCreateActivo.ticket_activity,
              [ticketActivityCreate.id]
            );
        } else if (ticketActivityCreate) {
          ticketticketActivityCreateActivo.ticket_activity = union(
            ticketticketActivityCreateActivo.ticket_activity
              ? ticketticketActivityCreateActivo.ticket_activity
              : [],
            [ticketActivityCreate.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: ticketticketActivityCreateActivo
        //nuevos: ticketActivityCreate && ticketActivityCreate.ticket_id ? union(state.nuevos, [ticketActivityCreate.ticket_id]) : state.nuevos,
      });
    case UPDATE_TICKETACTIVITY:
      let ticketticketActivityUpdateActivo = clone(state.nuevo);
      Object.values(action.ticketActivity).map(ticketActivityUpdate => {
        if (
          ticketActivityUpdate &&
          ticketActivityUpdate.ticket_id &&
          ticketticketActivityUpdateActivo[ticketActivityUpdate.ticket_id]
        ) {
          if (ticketActivityUpdate.ticket_id.toString().indexOf('-') !== -1)
            ticketticketActivityUpdateActivo[
              ticketActivityUpdate.ticket_id
            ].ticket_activity = union(
              ticketticketActivityUpdateActivo.ticket_activity,
              [ticketActivityUpdate.id]
            );
        } else if (ticketActivityUpdate) {
          ticketticketActivityUpdateActivo.ticket_activity = union(
            ticketticketActivityUpdateActivo.ticket_activity
              ? ticketticketActivityUpdateActivo.ticket_activity
              : [],
            [ticketActivityUpdate.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: ticketticketActivityUpdateActivo
        //nuevos: ticketActivityUpdate && ticketActivityUpdate.ticket_id ? union(state.nuevos, [ticketActivityUpdate.ticket_id]) : state.nuevos,
      });
    case DELETE_TICKETACTIVITY:
      let ticketticketActivityDeleteActivo = clone(state.nuevo);
      Object.values(action.ticketActivity).map(ticketActivityDelete => {
        if (
          ticketActivityDelete &&
          ticketActivityDelete.ticket_id &&
          ticketActivityDelete.ticket_id &&
          ticketticketActivityDeleteActivo[ticketActivityDelete.ticket_id]
        ) {
          if (ticketActivityDelete.ticket_id.toString().indexOf('-') !== -1)
            ticketticketActivityDeleteActivo[
              ticketActivityDelete.ticket_id
            ].ticket_activity = difference(
              ticketticketActivityDeleteActivo.ticket_activity,
              [ticketActivityDelete.id]
            );
        } else if (ticketActivityDelete) {
          ticketticketActivityDeleteActivo.ticket_activity = difference(
            ticketticketActivityDeleteActivo.ticket_activity
              ? ticketticketActivityDeleteActivo.ticket_activity
              : [],
            [ticketActivityDelete.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: ticketticketActivityDeleteActivo
        //nuevos: ticketActivityDelete && ticketActivityDelete.ticket_id ? union(state.nuevos, [ticketActivityDelete.ticket_id]) : state.nuevos,
      }); //CREATE TICKETPROPERTY
    case CREATE_TICKETPROPERTY:
      let ticketticketPropertyCreateActivo = clone(state.nuevo);
      Object.values(action.ticketProperty).map(ticketPropertyCreate => {
        if (
          ticketPropertyCreate &&
          ticketPropertyCreate.ticket_id &&
          ticketticketPropertyCreateActivo[ticketPropertyCreate.ticket_id]
        ) {
          if (ticketPropertyCreate.ticket_id.toString().indexOf('-') !== -1)
            ticketticketPropertyCreateActivo[
              ticketPropertyCreate.ticket_id
            ].ticket_property = union(
              ticketticketPropertyCreateActivo.ticket_property,
              [ticketPropertyCreate.id]
            );
        } else if (ticketPropertyCreate) {
          ticketticketPropertyCreateActivo.ticket_property = union(
            ticketticketPropertyCreateActivo.ticket_property
              ? ticketticketPropertyCreateActivo.ticket_property
              : [],
            [ticketPropertyCreate.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: ticketticketPropertyCreateActivo
        //nuevos: ticketPropertyCreate && ticketPropertyCreate.ticket_id ? union(state.nuevos, [ticketPropertyCreate.ticket_id]) : state.nuevos,
      });
    case UPDATE_TICKETPROPERTY:
      let ticketticketPropertyUpdateActivo = clone(state.nuevo);
      Object.values(action.ticketProperty).map(ticketPropertyUpdate => {
        if (
          ticketPropertyUpdate &&
          ticketPropertyUpdate.ticket_id &&
          ticketticketPropertyUpdateActivo[ticketPropertyUpdate.ticket_id]
        ) {
          if (ticketPropertyUpdate.ticket_id.toString().indexOf('-') !== -1)
            ticketticketPropertyUpdateActivo[
              ticketPropertyUpdate.ticket_id
            ].ticket_property = union(
              ticketticketPropertyUpdateActivo.ticket_property,
              [ticketPropertyUpdate.id]
            );
        } else if (ticketPropertyUpdate) {
          ticketticketPropertyUpdateActivo.ticket_property = union(
            ticketticketPropertyUpdateActivo.ticket_property
              ? ticketticketPropertyUpdateActivo.ticket_property
              : [],
            [ticketPropertyUpdate.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: ticketticketPropertyUpdateActivo
        //nuevos: ticketPropertyUpdate && ticketPropertyUpdate.ticket_id ? union(state.nuevos, [ticketPropertyUpdate.ticket_id]) : state.nuevos,
      });
    case DELETE_TICKETPROPERTY:
      let ticketticketPropertyDeleteActivo = clone(state.nuevo);
      Object.values(action.ticketProperty).map(ticketPropertyDelete => {
        if (
          ticketPropertyDelete &&
          ticketPropertyDelete.ticket_id &&
          ticketPropertyDelete.ticket_id &&
          ticketticketPropertyDeleteActivo[ticketPropertyDelete.ticket_id]
        ) {
          if (ticketPropertyDelete.ticket_id.toString().indexOf('-') !== -1)
            ticketticketPropertyDeleteActivo[
              ticketPropertyDelete.ticket_id
            ].ticket_property = difference(
              ticketticketPropertyDeleteActivo.ticket_property,
              [ticketPropertyDelete.id]
            );
        } else if (ticketPropertyDelete) {
          ticketticketPropertyDeleteActivo.ticket_property = difference(
            ticketticketPropertyDeleteActivo.ticket_property
              ? ticketticketPropertyDeleteActivo.ticket_property
              : [],
            [ticketPropertyDelete.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: ticketticketPropertyDeleteActivo
        //nuevos: ticketPropertyDelete && ticketPropertyDelete.ticket_id ? union(state.nuevos, [ticketPropertyDelete.ticket_id]) : state.nuevos,
      }); //CREATE PROPERTYCLOSEDOPERATION
    case CREATE_PROPERTYCLOSEDOPERATION:
      let ticketpropertyClosedOperationCreateActivo = clone(state.nuevo);
      Object.values(action.propertyClosedOperation).map(
        propertyClosedOperationCreate => {
          if (
            propertyClosedOperationCreate &&
            propertyClosedOperationCreate.ticket_id &&
            ticketpropertyClosedOperationCreateActivo[
              propertyClosedOperationCreate.ticket_id
            ]
          ) {
            if (
              propertyClosedOperationCreate.ticket_id
                .toString()
                .indexOf('-') !== -1
            )
              ticketpropertyClosedOperationCreateActivo[
                propertyClosedOperationCreate.ticket_id
              ].property_closed_operation = union(
                ticketpropertyClosedOperationCreateActivo.property_closed_operation,
                [propertyClosedOperationCreate.id]
              );
          } else if (propertyClosedOperationCreate) {
            ticketpropertyClosedOperationCreateActivo.property_closed_operation =
              union(
                ticketpropertyClosedOperationCreateActivo.property_closed_operation
                  ? ticketpropertyClosedOperationCreateActivo.property_closed_operation
                  : [],
                [propertyClosedOperationCreate.id]
              );
          }
        }
      );
      return Object.assign({}, state, {
        nuevo: ticketpropertyClosedOperationCreateActivo
        //nuevos: propertyClosedOperationCreate && propertyClosedOperationCreate.ticket_id ? union(state.nuevos, [propertyClosedOperationCreate.ticket_id]) : state.nuevos,
      });
    case UPDATE_PROPERTYCLOSEDOPERATION:
      let ticketpropertyClosedOperationUpdateActivo = clone(state.nuevo);
      Object.values(action.propertyClosedOperation).map(
        propertyClosedOperationUpdate => {
          if (
            propertyClosedOperationUpdate &&
            propertyClosedOperationUpdate.ticket_id &&
            ticketpropertyClosedOperationUpdateActivo[
              propertyClosedOperationUpdate.ticket_id
            ]
          ) {
            if (
              propertyClosedOperationUpdate.ticket_id
                .toString()
                .indexOf('-') !== -1
            )
              ticketpropertyClosedOperationUpdateActivo[
                propertyClosedOperationUpdate.ticket_id
              ].property_closed_operation = union(
                ticketpropertyClosedOperationUpdateActivo.property_closed_operation,
                [propertyClosedOperationUpdate.id]
              );
          } else if (propertyClosedOperationUpdate) {
            ticketpropertyClosedOperationUpdateActivo.property_closed_operation =
              union(
                ticketpropertyClosedOperationUpdateActivo.property_closed_operation
                  ? ticketpropertyClosedOperationUpdateActivo.property_closed_operation
                  : [],
                [propertyClosedOperationUpdate.id]
              );
          }
        }
      );
      return Object.assign({}, state, {
        nuevo: ticketpropertyClosedOperationUpdateActivo
        //nuevos: propertyClosedOperationUpdate && propertyClosedOperationUpdate.ticket_id ? union(state.nuevos, [propertyClosedOperationUpdate.ticket_id]) : state.nuevos,
      });
    case DELETE_PROPERTYCLOSEDOPERATION:
      let ticketpropertyClosedOperationDeleteActivo = clone(state.nuevo);
      Object.values(action.propertyClosedOperation).map(
        propertyClosedOperationDelete => {
          if (
            propertyClosedOperationDelete &&
            propertyClosedOperationDelete.ticket_id &&
            propertyClosedOperationDelete.ticket_id &&
            ticketpropertyClosedOperationDeleteActivo[
              propertyClosedOperationDelete.ticket_id
            ]
          ) {
            if (
              propertyClosedOperationDelete.ticket_id
                .toString()
                .indexOf('-') !== -1
            )
              ticketpropertyClosedOperationDeleteActivo[
                propertyClosedOperationDelete.ticket_id
              ].property_closed_operation = difference(
                ticketpropertyClosedOperationDeleteActivo.property_closed_operation,
                [propertyClosedOperationDelete.id]
              );
          } else if (propertyClosedOperationDelete) {
            ticketpropertyClosedOperationDeleteActivo.property_closed_operation =
              difference(
                ticketpropertyClosedOperationDeleteActivo.property_closed_operation
                  ? ticketpropertyClosedOperationDeleteActivo.property_closed_operation
                  : [],
                [propertyClosedOperationDelete.id]
              );
          }
        }
      );
      return Object.assign({}, state, {
        nuevo: ticketpropertyClosedOperationDeleteActivo
        //nuevos: propertyClosedOperationDelete && propertyClosedOperationDelete.ticket_id ? union(state.nuevos, [propertyClosedOperationDelete.ticket_id]) : state.nuevos,
      });

    //USER
    case SUCCESS_CREATE_USER:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_USER:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_USER:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_USERS:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });

    case DELETE_TICKET:
      let datoTicketDelete = action.ticket;
      let idsDelete = [];
      Object.values(action.ticket).map(ticketDelete => {
        if (ticketDelete && ticketDelete.id) idsDelete.push(ticketDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoTicketDelete)),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_TICKET:
      let datoTicketDeleteCreate = action.ticket;
      let idsDeleteCreate = [];
      Object.values(action.ticket).map(ticketDelete => {
        if (ticketDelete && ticketDelete.id)
          idsDeleteCreate.push(ticketDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoTicketDeleteCreate)),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_TICKET:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.ticket,
        error: null
      });
    case REQUEST_DELETE_TICKET:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_TICKET:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_TICKET:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_TICKET:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    //USER
    case SUCCESS_CREATE_USER:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_USER:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_USER:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_USERS:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_TICKET:
      let idsCreate = [];
      Object.values(action.ticket).map(ticketCreate => {
        if (ticketCreate && ticketCreate.id) idsCreate.push(ticketCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.ticket,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_TICKET:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_TICKET:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_TICKET:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_TICKETS:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_TICKETS:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_TICKETS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_TICKET:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_TICKET:
      let datoTicketDeleteCreate = action.ticket;
      let idsDeleteCreate = [];
      Object.values(action.ticket).map(ticketDelete => {
        if (ticketDelete && ticketDelete.id)
          idsDeleteCreate.push(ticketDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(clone(state.print), Object.keys(datoTicketDeleteCreate)),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const tickets = combineReducers({
  byId: ticketsById,
  allIds: allTickets,
  update: update,
  create: create,
  totalTickets: totalTickets,
  delete: deleter,
  print: print
});

export default tickets;
