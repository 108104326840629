import { Avatar, Box, Button, Typography, useTheme } from '@material-ui/core';
import { useRef } from 'react';
import { useDrag } from 'react-dnd';
import PinDropIcon from '@material-ui/icons/PinDrop';
import moment from 'moment';
import { Link } from 'react-router-dom';

function TicketItem({ id, lead, user, property }) {
  const theme = useTheme();
  const ref = useRef(null);
  const [{ isDragging }, drag] = useDrag({
    type: 'card',
    item: () => ({ id }),
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;
  drag(ref);
  return (
    <Box
      ref={ref}
      style={{
        opacity,
        ...{
          transition: 'all 0.3s ease-in-out',
          padding: '8px 12px',
          margin: '4px 0',
          fontSize: '0.8em',
          backgroundColor: '#f4f4f4',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
          width: '100%'
        }
      }}
    >
      <Typography
        style={{
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          fontWeight: 400,
          textAlign: 'right'
        }}
        component="p"
        variant="caption"
      >
        {moment(lead?.created_at).fromNow()}
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        style={{
          position: 'relative',
          top: '-10px'
        }}
      >
        <Avatar
          style={{
            width: '33px',
            height: '33px',
            backgroundColor: theme.palette.primary.main
          }}
          alt={user?.name}
        >
          <Typography variant="body1" style={{ fontSize: '13px' }}>
            {user?.name?.[0].toUpperCase()}
            {user?.lastname?.[0].toUpperCase()}
          </Typography>
        </Avatar>
        <Typography
          variant="body1"
          style={{
            marginLeft: '4px',
            fontWeight: 600,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}
        >
          {`${user?.name ?? ''} ${user?.lastname ?? ''}`}
        </Typography>
      </Box>
      <Typography
        style={{
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 3,
          textOverflow: 'ellipsis'
        }}
        component="p"
        variant="body2"
      >
        {lead.origin}
      </Typography>
      <Box display="flex" alignItems="center" style={{ marginTop: '10px' }}>
        <PinDropIcon />
        <Typography
          style={{
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontWeight: 600
          }}
          component="p"
          variant="body2"
        >
          {property?.address}
        </Typography>
      </Box>
      <Link to={'/tickets/' + lead.id}>
        <Button
          color="primary"
          style={{
            alignSelf: 'flex-end',
            fontSize: '12px',
            padding: '0'
          }}
        >
          Ver más
        </Button>
      </Link>
    </Box>
  );
}

TicketItem.propTypes = {};
export default TicketItem;
