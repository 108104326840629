import {
  INVALIDATE_CONTACTS,
  ERROR_CONTACTS,
  RECEIVE_CONTACTS,
  REQUEST_CONTACTS,
  RESET_CONTACTS,
  ERROR_CONTACT,
  RECEIVE_CONTACT,
  REQUEST_CONTACT,
  UPDATE_CONTACT,
  REQUEST_UPDATE_CONTACT,
  SUCCESS_UPDATE_CONTACT,
  ERROR_UPDATE_CONTACT,
  RESET_UPDATE_CONTACT,
  REQUEST_UPDATE_CONTACTS,
  SUCCESS_UPDATE_CONTACTS,
  ERROR_UPDATE_CONTACTS,
  RESET_UPDATE_CONTACTS,
  CREATE_CONTACT,
  ERROR_CREATE_CONTACT,
  REQUEST_CREATE_CONTACT,
  RESET_CREATE_CONTACT,
  SUCCESS_CREATE_CONTACT,
  REQUEST_CREATE_CONTACTS,
  SUCCESS_CREATE_CONTACTS,
  ERROR_CREATE_CONTACTS,
  RESET_CREATE_CONTACTS,
  DELETE_CONTACT,
  DELETE_CREATE_CONTACT,
  DELETE_UPDATE_CONTACT,
  REQUEST_DELETE_CONTACT,
  SUCCESS_DELETE_CONTACT,
  ERROR_DELETE_CONTACT,
  RESET_DELETE_CONTACT,
  REQUEST_PRINT_CONTACT,
  SUCCESS_PRINT_CONTACT,
  ERROR_PRINT_CONTACT,
  RESET_PRINT_CONTACT,
  RECEIVE_FILE_CONTACT,
  REQUEST_PRINT_CONTACTS,
  SUCCESS_PRINT_CONTACTS,
  ERROR_PRINT_CONTACTS,
  RESET_PRINT_CONTACTS,
  RECEIVE_FILE_CONTACTS,
  PRINT_CONTACT,
  DELETE_PRINT_CONTACT
} from '../actions/ContactActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    contacts: {},
    files: {}
  };
}

function contactsById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_CONTACTS:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_CONTACTS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_CONTACTS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_CONTACTS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        contacts: {}
      });
    case RECEIVE_CONTACTS:
      let dato = action.contacts.entities.contacts;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        contacts: merge({}, state.contacts, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_CONTACT:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_CONTACT:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_CONTACT:
      let datoContact = action.contact.entities.contacts;
      return Object.assign({}, state, {
        contacts: merge({}, state.contacts, datoContact),
        isFetching: false
      });
    case RECEIVE_FILE_CONTACT:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_CONTACT:
      let datoContactEliminado = action.contact.entities.contacts;
      return Object.assign({}, state, {
        contacts: mergeWith(
          clone(datoContactEliminado),
          state.contacts,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_CONTACT:
      let datoContactCreado = action.contact.entities.contacts;
      return Object.assign({}, state, {
        contacts: mergeWith(
          clone(datoContactCreado),
          state.contacts,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_CONTACTS:
      let datosContactCreado = action.contacts.entities.contacts;
      return Object.assign({}, state, {
        contacts: mergeWith(
          clone(datosContactCreado),
          state.contacts,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_CONTACT:
      let datoContactActualizado = action.contact.entities.contacts;
      return Object.assign({}, state, {
        contacts: mergeWith(
          clone(datoContactActualizado),
          state.contacts,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_CONTACTS:
      let datosContactActualizado = action.contacts.entities.contacts;
      return Object.assign({}, state, {
        contacts: mergeWith(
          clone(datosContactActualizado),
          state.contacts,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        contacts: {}
      });
    default:
      return state;
  }
}

function allContacts(state = [], action) {
  switch (action.type) {
    case RECEIVE_CONTACTS:
      return action.contacts.result && action.contacts.result.contacts
        ? union(action.contacts.result.contacts, state)
        : action.contacts.result
        ? action.contacts.result
        : state;
    case RECEIVE_CONTACT:
      return action.contact.result
        ? union([action.contact.result], state)
        : state;

    case SUCCESS_CREATE_CONTACT:
      let datoContactSCreate = action.contact.entities.contacts;
      let idNuevoSCreate = null;
      if (Object.values(datoContactSCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoContactSCreate)[0] &&
          Object.values(datoContactSCreate)[0].id
            ? Object.values(datoContactSCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_CONTACTS:
      let contactsCreate =
        action.contacts.entities && action.contacts.entities.contacts
          ? action.contacts.entities.contacts
          : null;
      return contactsCreate
        ? union(
            state,
            Object.values(contactsCreate).map(contacts => {
              return contacts.id;
            })
          )
        : state;
    case RESET_CONTACTS:
      return [];

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalContacts(state = null, action) {
  switch (action.type) {
    case RECEIVE_CONTACTS:
      return action.contacts && action.contacts.result.total
        ? action.contacts.result.total
        : 0;
    case RESET_CONTACTS:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_CONTACT:
      let dato = action.contact.entities.contacts;
      let contact =
        dato && Object.keys(dato).length > 0 ? dato[action.contact.result] : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: contact ? contact : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_CONTACT:
      let idsUpdate = [];
      Object.values(action.contact).map(contactUpdate => {
        if (contactUpdate && contactUpdate.id) idsUpdate.push(contactUpdate.id);
      });
      return merge({}, state, {
        activo: action.contact,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_CONTACT:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_CONTACT:
      let datoContactActualizado = action.contact.entities.contacts;
      let contactNuevo =
        datoContactActualizado && Object.keys(datoContactActualizado).length > 0
          ? datoContactActualizado[action.contact.result]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: contactNuevo
      });
    case ERROR_UPDATE_CONTACT:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_CONTACTS:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_CONTACTS:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_CONTACTS:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_CONTACT:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    case DELETE_CONTACT:
      let datoContactDelete = action.contact;
      let idsDelete = [];
      Object.values(action.contact).map(contactDelete => {
        if (contactDelete && contactDelete.id) idsDelete.push(contactDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(clone(state.activo), Object.keys(datoContactDelete)),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_CONTACT:
      let datoContactDeleteUpdate = action.contact;
      let idsDeleteUpdate = [];
      Object.values(action.contact).map(contactDelete => {
        if (contactDelete && contactDelete.id)
          idsDeleteUpdate.push(contactDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoContactDeleteUpdate)
          ),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_CONTACT:
      let datoContactDeleted = {};
      if (Object.values(action.contact.entities.contacts).length > 0)
        datoContactDeleted = Object.values(action.contact.entities.contacts)[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoContactDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_CONTACT:
      let idsCreate = [];
      Object.values(action.contact).map(contactCreate => {
        if (contactCreate && contactCreate.id) idsCreate.push(contactCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.contact,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_CONTACT:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_CONTACT:
      let datoContactNuevo = action.contact.entities.contacts;
      let contactNuevo =
        datoContactNuevo && Object.keys(datoContactNuevo).length > 0
          ? datoContactNuevo[action.contact.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: contactNuevo,
        nuevos: []
      });
    case ERROR_CREATE_CONTACT:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_CONTACTS:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_CONTACTS:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_CONTACTS:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_CONTACT:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    case DELETE_CONTACT:
      let datoContactDelete = action.contact;
      let idsDelete = [];
      Object.values(action.contact).map(contactDelete => {
        if (contactDelete && contactDelete.id) idsDelete.push(contactDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoContactDelete)),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_CONTACT:
      let datoContactDeleteCreate = action.contact;
      let idsDeleteCreate = [];
      Object.values(action.contact).map(contactDelete => {
        if (contactDelete && contactDelete.id)
          idsDeleteCreate.push(contactDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoContactDeleteCreate)),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_CONTACT:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.contact,
        error: null
      });
    case REQUEST_DELETE_CONTACT:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_CONTACT:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_CONTACT:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_CONTACT:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_CONTACT:
      let idsCreate = [];
      Object.values(action.contact).map(contactCreate => {
        if (contactCreate && contactCreate.id) idsCreate.push(contactCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.contact,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_CONTACT:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_CONTACT:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_CONTACT:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_CONTACTS:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_CONTACTS:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_CONTACTS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_CONTACT:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_CONTACT:
      let datoContactDeleteCreate = action.contact;
      let idsDeleteCreate = [];
      Object.values(action.contact).map(contactDelete => {
        if (contactDelete && contactDelete.id)
          idsDeleteCreate.push(contactDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(clone(state.print), Object.keys(datoContactDeleteCreate)),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const contacts = combineReducers({
  byId: contactsById,
  allIds: allContacts,
  update: update,
  create: create,
  totalContacts: totalContacts,
  delete: deleter,
  print: print
});

export default contacts;
