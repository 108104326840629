import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//Actions
import {
  login,
  resetLogin,
  restablecer,
  validarToken
} from '../../actions/AuthActions';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useValidador } from '../../utils/validador';
import { useHistory, useParams } from 'react-router';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  //     '@global': {
  //         body: {
  //             backgroundColor: theme.palette.common.white,
  //         },
  //     },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  buttonProgress: {
    color: theme.palette.success.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  }
}));

export default function RestablecerOlvideMiPassword(props) {
  const classes = useStyles();
  const [password, setPassword] = useState('');
  const [repetirPassword, setRepetirPassword] = useState('');
  const [cont, setCont] = useState(5);
  const dispatch = useDispatch();
  let { token } = useParams();
  let msj = useRef();
  let msjError = useRef();
  let mounted = useRef();
  let mounted1 = useRef();
  let form = useRef();
  let history = useHistory();

  //Store
  const auth = useSelector(state => state.auth);

  const loading = auth.isFetching;

  //Hooks
  let reglas = {};
  reglas = {
    password: ['required'],
    repetirPassword: ['required']
  };
  const [validador, validar] = useValidador(reglas);

  useEffect(() => {
    if (token) dispatch(validarToken(token));
    return function cleanup() {
      dispatch(resetLogin());
    };
  }, []);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (!auth.isFetching && !auth.errorMessage) {
        msj.current.style.display = 'inline';
        form.current.style.display = 'none';
        avanzar();
      }
    }
  }, [auth.isFetching]);

  useEffect(() => {
    if (!mounted1.current) {
      mounted1.current = true;
    } else {
      history.push('/' + login + '/');
    }
  }, [cont === 0]);

  //State
  const avanzar = async () => {
    for (let i = 5; i >= 0; i--) {
      await sleep(1000);
      setCont(i);
    }
  };

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function submitForm(e) {
    e.preventDefault();
    msj.current.style.display = 'none';
    await dispatch(
      restablecer({
        password,
        password_confirmation: repetirPassword,
        reset_token: token
      })
    );
  }

  let Errores = '';
  let error = auth.errorMessage;
  if (
    error != null &&
    Object.keys(error).length > 0 &&
    typeof error == 'object'
  ) {
    Errores = Object.keys(error).map((nombre, index) => {
      let mensajeCompleto = '';
      if (error[nombre])
        error[nombre].forEach(mensaje => {
          mensajeCompleto += mensaje;
        });
      return (
        <p key={nombre} style={{ margin: '0px', color: 'red' }}>
          {mensajeCompleto}
        </p>
      );
    });
  } else
    Errores = (
      <p key={0} style={{ margin: '0px', color: 'red' }}>
        {error}
      </p>
    );

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Recuperar contraseña
        </Typography>
        <br />
        {Errores}
        <form
          ref={form}
          className={classes.form}
          noValidate
          onSubmit={e => submitForm(e)}
        >
          <TextField
            id="password"
            label="Contraseña"
            placeholder="Contraseña"
            fullWidth
            margin="dense"
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
            InputProps={{ type: 'password' }}
            value={password ? password : ''}
            onChange={e => setPassword(e.target.value)}
            onBlur={validar}
            error={!validador.isValidById('password')}
            helperText={validador.getHelperTextById('password')}
          />
          <TextField
            id="repetirPassword"
            label="Repetir contraseña"
            placeholder="Contraseña"
            fullWidth
            margin="dense"
            InputLabelProps={{
              shrink: true
            }}
            variant="outlined"
            InputProps={{ type: 'password' }}
            value={repetirPassword ? repetirPassword : ''}
            onChange={e => setRepetirPassword(e.target.value)}
            onBlur={validar}
            error={!validador.isValidById('repetirPassword')}
            helperText={validador.getHelperTextById('repetirPassword')}
          />
          <FormHelperText
            ref={msjError}
            style={{ display: 'none', color: 'red' }}
          >
            Las contraseñas no coinciden
          </FormHelperText>
          <div className={classes.wrapper}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Cambiar contraseña
            </Button>
            {loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </div>
        </form>
        <FormHelperText ref={msj} style={{ display: 'none', color: 'green' }}>
          {'Su contraseña fue restablecida! ' + cont}
        </FormHelperText>
      </div>
    </Container>
  );
}
