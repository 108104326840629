import React from 'react';
import SellersListPanel from '../Home/panels/SellersListPanel';
import { Breadcrumbs, Container, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import DataRangeButton from '../Home/components/DataRangeButton';

function AgentsList() {
  const [dateRange, setDateRange] = React.useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    endDate: new Date()
  });
  return (
    <Container maxWidth={'xg'}>
      <Breadcrumbs aria-label="breadcrumb" separator={'‣'}>
        <Link color="inherit" to="/">
          Home
        </Link>
      </Breadcrumbs>
      <Typography component={'h1'} variant={'h1'}>
        Agentes
      </Typography>
      <DataRangeButton
        setDateRange={setDateRange}
        dateRange={dateRange}
        style={{ marginTop: '-30px' }}
      />
      <SellersListPanel dateRange={dateRange} />
    </Container>
  );
}

export default AgentsList;
