import React from 'react';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import moment from 'moment/moment';
import { DateRangePicker } from 'materialui-daterange-picker';
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfWeek,
  startOfYear
} from 'date-fns';

const getDefaultRanges = date => [
  {
    label: 'Hoy',
    startDate: date,
    endDate: date
  },
  {
    label: 'Ayer',
    startDate: addDays(date, -1),
    endDate: addDays(date, -1)
  },
  {
    label: 'Esta semana',
    startDate: startOfWeek(date),
    endDate: endOfWeek(date)
  },
  {
    label: 'Semana pasada',
    startDate: startOfWeek(addWeeks(date, -1)),
    endDate: endOfWeek(addWeeks(date, -1))
  },
  {
    label: 'Últimos 7 días',
    startDate: addWeeks(date, -1),
    endDate: date
  },
  {
    label: 'Este mes',
    startDate: startOfMonth(date),
    endDate: endOfMonth(date)
  },
  {
    label: 'Mes pasado',
    startDate: startOfMonth(addMonths(date, -1)),
    endDate: endOfMonth(addMonths(date, -1))
  },
  {
    label: 'Año pasado',
    startDate: startOfYear(addYears(date, -1)),
    endDate: endOfYear(addYears(date, -1))
  },
  {
    label: 'Este año',
    startDate: startOfYear(date),
    endDate: endOfYear(date)
  }
];

function DataRangeButton(props) {
  const { setDateRange, dateRange } = props;

  const [open, setOpen] = React.useState(false);

  const onChange = range => {
    setDateRange(range);
    setOpen(false);
  };

  const toggle = () => setOpen(!open);

  return (
    <Box
      width={'100%'}
      display={'flex'}
      justifyContent={'flex-end'}
      flexDirection={'column'}
      position={'relative'}
      marginTop={'10px'}
      marginBottom={'10px'}
      style={props.style}
    >
      <Box display={'flex'} justifyContent={'flex-end'}>
        <Button variant={'outlined'} onClick={toggle}>
          {moment(dateRange.startDate).format('DD MMM') +
            '-' +
            moment(dateRange.endDate).format('DD MMM')}
        </Button>
      </Box>
      <Box position={'absolute'} top={'35px'} right={'0px'}>
        <DateRangePicker
          open={open}
          toggle={toggle}
          onChange={onChange}
          initialDateRange={{
            startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
            endDate: new Date()
          }}
          definedRanges={getDefaultRanges(new Date())}
        />
      </Box>
    </Box>
  );
}

export default DataRangeButton;
