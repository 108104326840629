import c from '../constants/Constants';

var users = {
  getAll(filtros) {
    var esc = encodeURIComponent;
    var query = '';
    if (filtros)
      query = Object.keys(filtros)
        .map(k => esc(k) + '=' + esc(filtros[k]))
        .join('&');

    let defaultOptions = {
      url: '',
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json'
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(c.BASE_URL + '/users/?' + query, defaultOptions);
  },
  getOne(idUser) {
    let defaultOptions = {
      url: '',
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json'
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(c.BASE_URL + '/users/' + idUser, defaultOptions);
  },
  getFile(idUser, filtros) {
    var esc = encodeURIComponent;
    var query = '';
    if (filtros)
      query = Object.keys(filtros)
        .map(k => esc(k) + '=' + esc(filtros[k]))
        .join('&');
    let defaultOptions = {
      url: '',
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        pragma: 'no-cache',
        'cache-control': 'no-cache',
        'X-Tenant': localStorage.tenant_id
      }
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(
      c.BASE_URL + '/users/' + idUser + '/file/?' + query,
      defaultOptions
    );
  },
  saveCreate(nuevo, files) {
    var formData = new FormData();
    if (files) {
      files.forEach(file => {
        if (file) formData.append(file.name, file);
      });
    }
    formData.append('user', JSON.stringify(nuevo));
    let defaultOptions = {
      url: '',
      method: 'POST',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json',
      body: formData
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }

    return fetch(c.BASE_URL + '/users/', defaultOptions);
  },
  saveUpdate(activo, files) {
    var formData = new FormData();
    if (files) {
      files.forEach(file => {
        if (file) formData.append(file.name, file);
      });
    }

    formData.append('user', JSON.stringify(activo));
    let defaultOptions = {
      url: '',
      method: 'POST',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json',
      body: formData
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }

    return fetch(c.BASE_URL + '/users/' + activo.id, defaultOptions);
  },
  saveUpdateUsers(activos) {
    let defaultOptions = {
      url: '',
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: 'Bearer ' + localStorage.token,
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json',
      body: JSON.stringify(activos)
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(c.BASE_URL + '/users/all', defaultOptions);
  },
  saveDelete(user) {
    let defaultOptions = {
      url: '',
      method: 'DELETE',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: 'Bearer ' + localStorage.token,
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json'
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(c.BASE_URL + '/users/' + user.id, defaultOptions);
  },
  saveCreateUsers(nuevos) {
    let defaultOptions = {
      url: '',
      method: 'POST',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json;charset=UTF-8',
        Authorization: 'Bearer ' + localStorage.token,
        'X-Tenant': localStorage.tenant_id
      },
      // cache: false,
      dataType: 'json',
      body: JSON.stringify(nuevos)
    };
    if (localStorage.token) {
      defaultOptions.headers['Authorization'] = 'Bearer ' + localStorage.token;
    }
    return fetch(c.BASE_URL + '/users/all', defaultOptions);
  },
  printUser(idUser) {
    let defaultOptions = {
      url: '',
      method: 'GET',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.token,
        'X-Tenant': localStorage.tenant_id
      },
      cache: 'no-cache'
    };
    return fetch(c.BASE_URL + '/users/print/' + idUser, defaultOptions);
  },
  printUsers(print) {
    let defaultOptions = {
      url: '',
      method: 'POST',
      mode: 'cors',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + localStorage.token,
        'X-Tenant': localStorage.tenant_id
      },
      cache: 'no-cache',
      body: JSON.stringify(print)
    };
    return fetch(c.BASE_URL + '/users/' + 'print/all', defaultOptions);
  }
};

export default users;
