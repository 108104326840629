//api
import propertyOperationsApi from '../api/propertyOperationsApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizePropertyOperations';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//PROPERTYOPERATIONS
export const REQUEST_PROPERTYOPERATIONS = 'REQUEST_PROPERTYOPERATIONS';
export const RECEIVE_PROPERTYOPERATIONS = 'RECEIVE_PROPERTYOPERATIONS';
export const INVALIDATE_PROPERTYOPERATIONS = 'INVALIDATE_PROPERTYOPERATIONS';
export const ERROR_PROPERTYOPERATIONS = 'ERROR_PROPERTYOPERATIONS';
export const RESET_PROPERTYOPERATIONS = 'RESET_PROPERTYOPERATIONS';

export function invalidatePropertyOperations() {
  return {
    type: INVALIDATE_PROPERTYOPERATIONS
  };
}

function requestPropertyOperations() {
  return {
    type: REQUEST_PROPERTYOPERATIONS
  };
}

function receivePropertyOperations(json) {
  return {
    type: RECEIVE_PROPERTYOPERATIONS,
    propertyOperations: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorPropertyOperations(error) {
  return {
    type: ERROR_PROPERTYOPERATIONS,
    error: error
  };
}

export function resetPropertyOperations() {
  return {
    type: RESET_PROPERTYOPERATIONS
  };
}

export function fetchPropertyOperations(filtros) {
  return dispatch => {
    dispatch(requestPropertyOperations());
    return propertyOperationsApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receivePropertyOperations(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorPropertyOperations(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorPropertyOperations(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

function shouldFetchPropertyOperations(state) {
  const propertyOperations = state.propertyOperations.byId;
  if (!propertyOperations) {
    return true;
  } else if (propertyOperations.isFetching) {
    return false;
  } else {
    return propertyOperations.didInvalidate;
  }
}

export function fetchPropertyOperationsIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchPropertyOperations(getState())) {
      return dispatch(fetchPropertyOperations(filtros));
    }
  };
}

//MODEL
export const REQUEST_PROPERTYOPERATION = 'REQUEST_PROPERTYOPERATION';
export const RECEIVE_PROPERTYOPERATION = 'RECEIVE_PROPERTYOPERATION';
export const INVALIDATE_PROPERTYOPERATION = 'INVALIDATE_PROPERTYOPERATION';
export const ERROR_PROPERTYOPERATION = 'ERROR_PROPERTYOPERATION';
export const RESET_PROPERTYOPERATION = 'RESET_PROPERTYOPERATION';

export function invalidatePropertyOperation() {
  return {
    type: INVALIDATE_PROPERTYOPERATION
  };
}

function requestPropertyOperation() {
  return {
    type: REQUEST_PROPERTYOPERATION
  };
}

export function receivePropertyOperation(json) {
  return {
    type: RECEIVE_PROPERTYOPERATION,
    propertyOperation: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorPropertyOperation(error) {
  return {
    type: ERROR_PROPERTYOPERATION,
    error: error
  };
}

export function fetchPropertyOperation(idPropertyOperation) {
  return dispatch => {
    dispatch(requestPropertyOperation());
    return propertyOperationsApi
      .getOne(idPropertyOperation)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receivePropertyOperation(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(errorPropertyOperation(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorPropertyOperation(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_PROPERTYOPERATION = 'RECEIVE_FILE_PROPERTYOPERATION';

function receiveFilePropertyOperation(file) {
  return {
    type: RECEIVE_FILE_PROPERTYOPERATION,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFilePropertyOperation(idPropertyOperation, filtros) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return propertyOperationsApi
      .getFile(idPropertyOperation, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFilePropertyOperation(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorPropertyOperation(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorPropertyOperation(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_PROPERTYOPERATION = 'UPDATE_PROPERTYOPERATION';
export const REQUEST_UPDATE_PROPERTYOPERATION =
  'REQUEST_UPDATE_PROPERTYOPERATION';
export const SUCCESS_UPDATE_PROPERTYOPERATION =
  'SUCCESS_UPDATE_PROPERTYOPERATION';
export const ERROR_UPDATE_PROPERTYOPERATION = 'ERROR_UPDATE_PROPERTYOPERATION';
export const RESET_UPDATE_PROPERTYOPERATION = 'RESET_UPDATE_PROPERTYOPERATION';
export const DELETE_UPDATE_PROPERTYOPERATION =
  'DELETE_UPDATE_PROPERTYOPERATION';

function requestUpdatePropertyOperation() {
  return {
    type: REQUEST_UPDATE_PROPERTYOPERATION
  };
}

function receiveUpdatePropertyOperation(propertyOperation) {
  return {
    type: SUCCESS_UPDATE_PROPERTYOPERATION,
    receivedAt: Date.now(),
    propertyOperation: normalizeDato(propertyOperation)
  };
}

function errorUpdatePropertyOperation(error) {
  return {
    type: ERROR_UPDATE_PROPERTYOPERATION,
    error: error
  };
}

export function resetUpdatePropertyOperation() {
  return {
    type: RESET_UPDATE_PROPERTYOPERATION
  };
}

export function updatePropertyOperation(propertyOperation) {
  return {
    type: UPDATE_PROPERTYOPERATION,
    propertyOperation
  };
}

export function saveUpdatePropertyOperation() {
  return (dispatch, getState) => {
    dispatch(requestUpdatePropertyOperation());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let propertyOperation = denormalizeDato(
      getState().propertyOperations.update.activo,
      store
    );

    return propertyOperationsApi
      .saveUpdate(propertyOperation)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdatePropertyOperation(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorUpdatePropertyOperation(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdatePropertyOperations(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorUpdatePropertyOperations(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorUpdatePropertyOperation(JSON.parse(error.message))
                  );
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdatePropertyOperation(error.data));
                })
                .catch(() => {
                  dispatch(
                    errorUpdatePropertyOperation(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

export function deleteUpdatePropertyOperation(propertyOperation) {
  return {
    type: DELETE_UPDATE_PROPERTYOPERATION,
    propertyOperation
  };
}

//UPDATE PROPERTYOPERATIONS
export const REQUEST_UPDATE_PROPERTYOPERATIONS =
  'REQUEST_UPDATE_PROPERTYOPERATIONS';
export const SUCCESS_UPDATE_PROPERTYOPERATIONS =
  'SUCCESS_UPDATE_PROPERTYOPERATIONS';
export const ERROR_UPDATE_PROPERTYOPERATIONS =
  'ERROR_UPDATE_PROPERTYOPERATIONS';
export const RESET_UPDATE_PROPERTYOPERATIONS =
  'RESET_UPDATE_PROPERTYOPERATIONS';

function requestUpdatePropertyOperations() {
  return {
    type: REQUEST_UPDATE_PROPERTYOPERATIONS
  };
}

function receiveUpdatePropertyOperations(propertyOperations) {
  return {
    type: SUCCESS_UPDATE_PROPERTYOPERATIONS,
    receivedAt: Date.now(),
    propertyOperations: normalizeDatos(propertyOperations)
  };
}

function errorUpdatePropertyOperations(error) {
  return {
    type: ERROR_UPDATE_PROPERTYOPERATIONS,
    error: error
  };
}

export function resetUpdatePropertyOperations() {
  return {
    type: RESET_UPDATE_PROPERTYOPERATIONS
  };
}

export function saveUpdatePropertyOperations() {
  return (dispatch, getState) => {
    dispatch(requestUpdatePropertyOperations());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let propertyOperations = getState().propertyOperations.update.activos.map(
      idPropertyOperation => {
        return denormalizeDato(
          getState().propertyOperations.update.activo[idPropertyOperation],
          store
        );
      }
    );

    return propertyOperationsApi
      .saveUpdatePropertyOperations(propertyOperations)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdatePropertyOperations(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorUpdatePropertyOperations(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdatePropertyOperations(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorUpdatePropertyOperations(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorUpdatePropertyOperations(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorUpdatePropertyOperations(errorMessages.GENERAL_ERROR)
                  );
                });

            return;
        }
      });
  };
}

//ALTA PROPERTYOPERATION
export const CREATE_PROPERTYOPERATION = 'CREATE_PROPERTYOPERATION';
export const REQUEST_CREATE_PROPERTYOPERATION =
  'REQUEST_CREATE_PROPERTYOPERATION';
export const SUCCESS_CREATE_PROPERTYOPERATION =
  'SUCCESS_CREATE_PROPERTYOPERATION';
export const ERROR_CREATE_PROPERTYOPERATION = 'ERROR_CREATE_PROPERTYOPERATION';
export const RESET_CREATE_PROPERTYOPERATION = 'RESET_CREATE_PROPERTYOPERATION';
export const DELETE_CREATE_PROPERTYOPERATION =
  'DELETE_CREATE_PROPERTYOPERATION';

//ALTA PROPERTYOPERATION
function requestCreatePropertyOperation() {
  return {
    type: REQUEST_CREATE_PROPERTYOPERATION
  };
}

function receiveCreatePropertyOperation(propertyOperation) {
  return {
    type: SUCCESS_CREATE_PROPERTYOPERATION,
    receivedAt: Date.now(),
    propertyOperation: normalizeDato(propertyOperation)
  };
}

export function errorCreatePropertyOperation(error) {
  return {
    type: ERROR_CREATE_PROPERTYOPERATION,
    error: error
  };
}

export function resetCreatePropertyOperation() {
  return {
    type: RESET_CREATE_PROPERTYOPERATION
  };
}

export function createPropertyOperation(propertyOperation) {
  return {
    type: CREATE_PROPERTYOPERATION,
    propertyOperation
  };
}

export function deleteCreatePropertyOperation(propertyOperation) {
  return {
    type: DELETE_CREATE_PROPERTYOPERATION,
    propertyOperation
  };
}

export function saveCreatePropertyOperation() {
  return (dispatch, getState) => {
    dispatch(requestCreatePropertyOperation());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let propertyOperation = denormalizeDato(
      getState().propertyOperations.create.nuevo,
      store
    );

    return propertyOperationsApi
      .saveCreate(propertyOperation)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreatePropertyOperation(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorCreatePropertyOperation(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreatePropertyOperations(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorCreatePropertyOperations(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorCreatePropertyOperation(JSON.parse(error.message))
                  );
                  if (error.data)
                    dispatch(receiveCreatePropertyOperation(error.data));
                })
                .catch(() => {
                  dispatch(
                    errorCreatePropertyOperation(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//CREATE PROPERTYOPERATIONS
export const REQUEST_CREATE_PROPERTYOPERATIONS =
  'REQUEST_CREATE_PROPERTYOPERATIONS';
export const SUCCESS_CREATE_PROPERTYOPERATIONS =
  'SUCCESS_CREATE_PROPERTYOPERATIONS';
export const ERROR_CREATE_PROPERTYOPERATIONS =
  'ERROR_CREATE_PROPERTYOPERATIONS';
export const RESET_CREATE_PROPERTYOPERATIONS =
  'RESET_CREATE_PROPERTYOPERATIONS';

function requestCreatePropertyOperations() {
  return {
    type: REQUEST_CREATE_PROPERTYOPERATIONS
  };
}

function receiveCreatePropertyOperations(propertyOperations) {
  return {
    type: SUCCESS_CREATE_PROPERTYOPERATIONS,
    receivedAt: Date.now(),
    propertyOperations: normalizeDatos(propertyOperations)
  };
}

function errorCreatePropertyOperations(error) {
  return {
    type: ERROR_CREATE_PROPERTYOPERATIONS,
    error: error
  };
}

export function resetCreatePropertyOperations() {
  return {
    type: RESET_CREATE_PROPERTYOPERATIONS
  };
}

export function saveCreatePropertyOperations() {
  return (dispatch, getState) => {
    dispatch(requestCreatePropertyOperations());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let propertyOperations = getState().propertyOperations.create.nuevos.map(
      idPropertyOperation => {
        return denormalizeDato(
          getState().propertyOperations.create.nuevo[idPropertyOperation],
          store
        );
      }
    );

    return propertyOperationsApi
      .saveCreatePropertyOperations(propertyOperations)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreatePropertyOperations(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorCreatePropertyOperations(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreatePropertyOperations(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorCreatePropertyOperations(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorCreatePropertyOperations(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorCreatePropertyOperations(errorMessages.GENERAL_ERROR)
                  );
                });

            return;
        }
      });
  };
}

//DELETE PROPERTYOPERATION
export const DELETE_PROPERTYOPERATION = 'DELETE_PROPERTYOPERATION';
export const REQUEST_DELETE_PROPERTYOPERATION =
  'REQUEST_DELETE_PROPERTYOPERATION';
export const SUCCESS_DELETE_PROPERTYOPERATION =
  'SUCCESS_DELETE_PROPERTYOPERATION';
export const ERROR_DELETE_PROPERTYOPERATION = 'ERROR_DELETE_PROPERTYOPERATION';
export const RESET_DELETE_PROPERTYOPERATION = 'RESET_DELETE_PROPERTYOPERATION';

function requestDeletePropertyOperation() {
  return {
    type: REQUEST_DELETE_PROPERTYOPERATION
  };
}

function receiveDeletePropertyOperation(propertyOperation) {
  return {
    type: SUCCESS_DELETE_PROPERTYOPERATION,
    receivedAt: Date.now(),
    propertyOperation: normalizeDato(propertyOperation)
  };
}

function errorDeletePropertyOperation(error) {
  return {
    type: ERROR_DELETE_PROPERTYOPERATION,
    error: error
  };
}

export function resetDeletePropertyOperation(error) {
  return {
    type: RESET_DELETE_PROPERTYOPERATION,
    error: error
  };
}

export function deletePropertyOperation(propertyOperation) {
  return {
    type: DELETE_PROPERTYOPERATION,
    propertyOperation
  };
}

export function saveDeletePropertyOperation(propertyOperation) {
  return dispatch => {
    dispatch(requestDeletePropertyOperation());
    return propertyOperationsApi
      .saveDelete(propertyOperation)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeletePropertyOperation());
        dispatch(receiveDeletePropertyOperation(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorDeletePropertyOperation(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeletePropertyOperation(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorDeletePropertyOperation(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorDeletePropertyOperation(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorDeletePropertyOperation(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//PRINT PROPERTYOPERATION
export const PRINT_PROPERTYOPERATION = 'PRINT_PROPERTYOPERATION';
export const REQUEST_PRINT_PROPERTYOPERATION =
  'REQUEST_PRINT_PROPERTYOPERATION';
export const SUCCESS_PRINT_PROPERTYOPERATION =
  'SUCCESS_PRINT_PROPERTYOPERATION';
export const ERROR_PRINT_PROPERTYOPERATION = 'ERROR_PRINT_PROPERTYOPERATION';
export const RESET_PRINT_PROPERTYOPERATION = 'RESET_PRINT_PROPERTYOPERATION';
export const DELETE_PRINT_PROPERTYOPERATION = 'DELETE_PRINT_PROPERTYOPERATION';

function requestPrintPropertyOperation() {
  return {
    type: REQUEST_PRINT_PROPERTYOPERATION
  };
}

function receivePrintPropertyOperation(turnos) {
  return {
    type: SUCCESS_PRINT_PROPERTYOPERATION,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintPropertyOperation(error) {
  return {
    type: ERROR_PRINT_PROPERTYOPERATION,
    error: error
  };
}

export function resetPrintPropertyOperation() {
  return {
    type: RESET_PRINT_PROPERTYOPERATION
  };
}

export function printPropertyOperation(propertyOperation) {
  return {
    type: PRINT_PROPERTYOPERATION,
    propertyOperation
  };
}

export function deletePrintPropertyOperation(propertyOperation) {
  return {
    type: DELETE_PRINT_PROPERTYOPERATION,
    propertyOperation
  };
}

export function savePrintPropertyOperation(idPropertyOperation) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintPropertyOperation());
    return propertyOperationsApi
      .printPropertyOperation(idPropertyOperation)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintPropertyOperation(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorPrintPropertyOperation(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintPropertyOperation(
                  JSON.parse(error.responseJSON.message)
                )
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorPrintPropertyOperation(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorPrintPropertyOperation(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//PRINT PROPERTYOPERATIONS
export const REQUEST_PRINT_PROPERTYOPERATIONS =
  'REQUEST_PRINT_PROPERTYOPERATIONS';
export const SUCCESS_PRINT_PROPERTYOPERATIONS =
  'SUCCESS_PRINT_PROPERTYOPERATIONS';
export const ERROR_PRINT_PROPERTYOPERATIONS = 'ERROR_PRINT_PROPERTYOPERATIONS';
export const RESET_PRINT_PROPERTYOPERATIONS = 'RESET_PRINT_PROPERTYOPERATIONS';

function requestPrintPropertyOperations() {
  return {
    type: REQUEST_PRINT_PROPERTYOPERATIONS
  };
}

function receivePrintPropertyOperations(propertyOperations) {
  return {
    type: SUCCESS_PRINT_PROPERTYOPERATIONS,
    receivedAt: Date.now(),
    propertyOperations: normalizeDatos(propertyOperations)
  };
}

function errorPrintPropertyOperations(error) {
  return {
    type: ERROR_PRINT_PROPERTYOPERATIONS,
    error: error
  };
}

export function resetPrintPropertyOperations() {
  return {
    type: RESET_PRINT_PROPERTYOPERATIONS
  };
}

export function savePrintPropertyOperations() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintPropertyOperation());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let propertyOperations = getState().propertyOperations.print.printers.map(
      idPropertyOperation => {
        return denormalizeDato(
          getState().propertyOperations.print.print[idPropertyOperation],
          store
        );
      }
    );
    return propertyOperationsApi
      .printPropertyOperations(propertyOperations)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintPropertyOperations(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorPrintPropertyOperations(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintPropertyOperations(
                  JSON.parse(error.responseJSON.message)
                )
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorPrintPropertyOperations(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorPrintPropertyOperations(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}
