//api
import propertyTypesApi from '../api/propertyTypesApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizePropertyTypes';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//PROPERTYTYPES
export const REQUEST_PROPERTYTYPES = 'REQUEST_PROPERTYTYPES';
export const RECEIVE_PROPERTYTYPES = 'RECEIVE_PROPERTYTYPES';
export const INVALIDATE_PROPERTYTYPES = 'INVALIDATE_PROPERTYTYPES';
export const ERROR_PROPERTYTYPES = 'ERROR_PROPERTYTYPES';
export const RESET_PROPERTYTYPES = 'RESET_PROPERTYTYPES';

export function invalidatePropertyTypes() {
  return {
    type: INVALIDATE_PROPERTYTYPES
  };
}

function requestPropertyTypes() {
  return {
    type: REQUEST_PROPERTYTYPES
  };
}

function receivePropertyTypes(json) {
  return {
    type: RECEIVE_PROPERTYTYPES,
    propertyTypes: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorPropertyTypes(error) {
  return {
    type: ERROR_PROPERTYTYPES,
    error: error
  };
}

export function resetPropertyTypes() {
  return {
    type: RESET_PROPERTYTYPES
  };
}

export function fetchPropertyTypes(filtros) {
  return dispatch => {
    dispatch(requestPropertyTypes());
    return propertyTypesApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receivePropertyTypes(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorPropertyTypes(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorPropertyTypes(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

function shouldFetchPropertyTypes(state) {
  const propertyTypes = state.propertyTypes.byId;
  if (!propertyTypes) {
    return true;
  } else if (propertyTypes.isFetching) {
    return false;
  } else {
    return propertyTypes.didInvalidate;
  }
}

export function fetchPropertyTypesIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchPropertyTypes(getState())) {
      return dispatch(fetchPropertyTypes(filtros));
    }
  };
}

//MODEL
export const REQUEST_PROPERTYTYPE = 'REQUEST_PROPERTYTYPE';
export const RECEIVE_PROPERTYTYPE = 'RECEIVE_PROPERTYTYPE';
export const INVALIDATE_PROPERTYTYPE = 'INVALIDATE_PROPERTYTYPE';
export const ERROR_PROPERTYTYPE = 'ERROR_PROPERTYTYPE';
export const RESET_PROPERTYTYPE = 'RESET_PROPERTYTYPE';

export function invalidatePropertyType() {
  return {
    type: INVALIDATE_PROPERTYTYPE
  };
}

function requestPropertyType() {
  return {
    type: REQUEST_PROPERTYTYPE
  };
}

export function receivePropertyType(json) {
  return {
    type: RECEIVE_PROPERTYTYPE,
    propertyType: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorPropertyType(error) {
  return {
    type: ERROR_PROPERTYTYPE,
    error: error
  };
}

export function fetchPropertyType(idPropertyType) {
  return dispatch => {
    dispatch(requestPropertyType());
    return propertyTypesApi
      .getOne(idPropertyType)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receivePropertyType(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(errorPropertyType(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorPropertyType(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_PROPERTYTYPE = 'RECEIVE_FILE_PROPERTYTYPE';

function receiveFilePropertyType(file) {
  return {
    type: RECEIVE_FILE_PROPERTYTYPE,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFilePropertyType(idPropertyType, filtros) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return propertyTypesApi
      .getFile(idPropertyType, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFilePropertyType(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorPropertyType(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorPropertyType(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_PROPERTYTYPE = 'UPDATE_PROPERTYTYPE';
export const REQUEST_UPDATE_PROPERTYTYPE = 'REQUEST_UPDATE_PROPERTYTYPE';
export const SUCCESS_UPDATE_PROPERTYTYPE = 'SUCCESS_UPDATE_PROPERTYTYPE';
export const ERROR_UPDATE_PROPERTYTYPE = 'ERROR_UPDATE_PROPERTYTYPE';
export const RESET_UPDATE_PROPERTYTYPE = 'RESET_UPDATE_PROPERTYTYPE';
export const DELETE_UPDATE_PROPERTYTYPE = 'DELETE_UPDATE_PROPERTYTYPE';

function requestUpdatePropertyType() {
  return {
    type: REQUEST_UPDATE_PROPERTYTYPE
  };
}

function receiveUpdatePropertyType(propertyType) {
  return {
    type: SUCCESS_UPDATE_PROPERTYTYPE,
    receivedAt: Date.now(),
    propertyType: normalizeDato(propertyType)
  };
}

function errorUpdatePropertyType(error) {
  return {
    type: ERROR_UPDATE_PROPERTYTYPE,
    error: error
  };
}

export function resetUpdatePropertyType() {
  return {
    type: RESET_UPDATE_PROPERTYTYPE
  };
}

export function updatePropertyType(propertyType) {
  return {
    type: UPDATE_PROPERTYTYPE,
    propertyType
  };
}

export function saveUpdatePropertyType(files) {
  return (dispatch, getState) => {
    dispatch(requestUpdatePropertyType());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let propertyType = denormalizeDato(
      getState().propertyTypes.update.activo,
      store
    );

    return propertyTypesApi
      .saveUpdate(propertyType, files)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdatePropertyType(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdatePropertyType(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdatePropertyTypes(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorUpdatePropertyTypes(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdatePropertyType(JSON.parse(error.message)));
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdatePropertyType(error.data));
                })
                .catch(() => {
                  dispatch(
                    errorUpdatePropertyType(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

export function deleteUpdatePropertyType(propertyType) {
  return {
    type: DELETE_UPDATE_PROPERTYTYPE,
    propertyType
  };
}

//UPDATE PROPERTYTYPES
export const REQUEST_UPDATE_PROPERTYTYPES = 'REQUEST_UPDATE_PROPERTYTYPES';
export const SUCCESS_UPDATE_PROPERTYTYPES = 'SUCCESS_UPDATE_PROPERTYTYPES';
export const ERROR_UPDATE_PROPERTYTYPES = 'ERROR_UPDATE_PROPERTYTYPES';
export const RESET_UPDATE_PROPERTYTYPES = 'RESET_UPDATE_PROPERTYTYPES';

function requestUpdatePropertyTypes() {
  return {
    type: REQUEST_UPDATE_PROPERTYTYPES
  };
}

function receiveUpdatePropertyTypes(propertyTypes) {
  return {
    type: SUCCESS_UPDATE_PROPERTYTYPES,
    receivedAt: Date.now(),
    propertyTypes: normalizeDatos(propertyTypes)
  };
}

function errorUpdatePropertyTypes(error) {
  return {
    type: ERROR_UPDATE_PROPERTYTYPES,
    error: error
  };
}

export function resetUpdatePropertyTypes() {
  return {
    type: RESET_UPDATE_PROPERTYTYPES
  };
}

export function saveUpdatePropertyTypes() {
  return (dispatch, getState) => {
    dispatch(requestUpdatePropertyTypes());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let propertyTypes = getState().propertyTypes.update.activos.map(
      idPropertyType => {
        return denormalizeDato(
          getState().propertyTypes.update.activo[idPropertyType],
          store
        );
      }
    );

    return propertyTypesApi
      .saveUpdatePropertyTypes(propertyTypes)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdatePropertyTypes(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorUpdatePropertyTypes(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdatePropertyTypes(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorUpdatePropertyTypes(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdatePropertyTypes(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(
                    errorUpdatePropertyTypes(errorMessages.GENERAL_ERROR)
                  );
                });

            return;
        }
      });
  };
}

//ALTA PROPERTYTYPE
export const CREATE_PROPERTYTYPE = 'CREATE_PROPERTYTYPE';
export const REQUEST_CREATE_PROPERTYTYPE = 'REQUEST_CREATE_PROPERTYTYPE';
export const SUCCESS_CREATE_PROPERTYTYPE = 'SUCCESS_CREATE_PROPERTYTYPE';
export const ERROR_CREATE_PROPERTYTYPE = 'ERROR_CREATE_PROPERTYTYPE';
export const RESET_CREATE_PROPERTYTYPE = 'RESET_CREATE_PROPERTYTYPE';
export const DELETE_CREATE_PROPERTYTYPE = 'DELETE_CREATE_PROPERTYTYPE';

//ALTA PROPERTYTYPE
function requestCreatePropertyType() {
  return {
    type: REQUEST_CREATE_PROPERTYTYPE
  };
}

function receiveCreatePropertyType(propertyType) {
  return {
    type: SUCCESS_CREATE_PROPERTYTYPE,
    receivedAt: Date.now(),
    propertyType: normalizeDato(propertyType)
  };
}

export function errorCreatePropertyType(error) {
  return {
    type: ERROR_CREATE_PROPERTYTYPE,
    error: error
  };
}

export function resetCreatePropertyType() {
  return {
    type: RESET_CREATE_PROPERTYTYPE
  };
}

export function createPropertyType(propertyType) {
  return {
    type: CREATE_PROPERTYTYPE,
    propertyType
  };
}

export function deleteCreatePropertyType(propertyType) {
  return {
    type: DELETE_CREATE_PROPERTYTYPE,
    propertyType
  };
}

export function saveCreatePropertyType(files) {
  return (dispatch, getState) => {
    dispatch(requestCreatePropertyType());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let propertyType = denormalizeDato(
      getState().propertyTypes.create.nuevo,
      store
    );

    return propertyTypesApi
      .saveCreate(propertyType, files)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreatePropertyType(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreatePropertyType(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreatePropertyTypes(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorCreatePropertyTypes(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreatePropertyType(JSON.parse(error.message)));
                  if (error.data)
                    dispatch(receiveCreatePropertyType(error.data));
                })
                .catch(() => {
                  dispatch(
                    errorCreatePropertyType(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//CREATE PROPERTYTYPES
export const REQUEST_CREATE_PROPERTYTYPES = 'REQUEST_CREATE_PROPERTYTYPES';
export const SUCCESS_CREATE_PROPERTYTYPES = 'SUCCESS_CREATE_PROPERTYTYPES';
export const ERROR_CREATE_PROPERTYTYPES = 'ERROR_CREATE_PROPERTYTYPES';
export const RESET_CREATE_PROPERTYTYPES = 'RESET_CREATE_PROPERTYTYPES';

function requestCreatePropertyTypes() {
  return {
    type: REQUEST_CREATE_PROPERTYTYPES
  };
}

function receiveCreatePropertyTypes(propertyTypes) {
  return {
    type: SUCCESS_CREATE_PROPERTYTYPES,
    receivedAt: Date.now(),
    propertyTypes: normalizeDatos(propertyTypes)
  };
}

function errorCreatePropertyTypes(error) {
  return {
    type: ERROR_CREATE_PROPERTYTYPES,
    error: error
  };
}

export function resetCreatePropertyTypes() {
  return {
    type: RESET_CREATE_PROPERTYTYPES
  };
}

export function saveCreatePropertyTypes() {
  return (dispatch, getState) => {
    dispatch(requestCreatePropertyTypes());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let propertyTypes = getState().propertyTypes.create.nuevos.map(
      idPropertyType => {
        return denormalizeDato(
          getState().propertyTypes.create.nuevo[idPropertyType],
          store
        );
      }
    );

    return propertyTypesApi
      .saveCreatePropertyTypes(propertyTypes)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreatePropertyTypes(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorCreatePropertyTypes(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreatePropertyTypes(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorCreatePropertyTypes(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreatePropertyTypes(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(
                    errorCreatePropertyTypes(errorMessages.GENERAL_ERROR)
                  );
                });

            return;
        }
      });
  };
}

//DELETE PROPERTYTYPE
export const DELETE_PROPERTYTYPE = 'DELETE_PROPERTYTYPE';
export const REQUEST_DELETE_PROPERTYTYPE = 'REQUEST_DELETE_PROPERTYTYPE';
export const SUCCESS_DELETE_PROPERTYTYPE = 'SUCCESS_DELETE_PROPERTYTYPE';
export const ERROR_DELETE_PROPERTYTYPE = 'ERROR_DELETE_PROPERTYTYPE';
export const RESET_DELETE_PROPERTYTYPE = 'RESET_DELETE_PROPERTYTYPE';

function requestDeletePropertyType() {
  return {
    type: REQUEST_DELETE_PROPERTYTYPE
  };
}

function receiveDeletePropertyType(propertyType) {
  return {
    type: SUCCESS_DELETE_PROPERTYTYPE,
    receivedAt: Date.now(),
    propertyType: normalizeDato(propertyType)
  };
}

function errorDeletePropertyType(error) {
  return {
    type: ERROR_DELETE_PROPERTYTYPE,
    error: error
  };
}

export function resetDeletePropertyType(error) {
  return {
    type: RESET_DELETE_PROPERTYTYPE,
    error: error
  };
}

export function deletePropertyType(propertyType) {
  return {
    type: DELETE_PROPERTYTYPE,
    propertyType
  };
}

export function saveDeletePropertyType(propertyType) {
  return dispatch => {
    dispatch(requestDeletePropertyType());
    return propertyTypesApi
      .saveDelete(propertyType)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeletePropertyType());
        dispatch(receiveDeletePropertyType(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorDeletePropertyType(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeletePropertyType(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorDeletePropertyType(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorDeletePropertyType(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(
                    errorDeletePropertyType(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//PRINT PROPERTYTYPE
export const PRINT_PROPERTYTYPE = 'PRINT_PROPERTYTYPE';
export const REQUEST_PRINT_PROPERTYTYPE = 'REQUEST_PRINT_PROPERTYTYPE';
export const SUCCESS_PRINT_PROPERTYTYPE = 'SUCCESS_PRINT_PROPERTYTYPE';
export const ERROR_PRINT_PROPERTYTYPE = 'ERROR_PRINT_PROPERTYTYPE';
export const RESET_PRINT_PROPERTYTYPE = 'RESET_PRINT_PROPERTYTYPE';
export const DELETE_PRINT_PROPERTYTYPE = 'DELETE_PRINT_PROPERTYTYPE';

function requestPrintPropertyType() {
  return {
    type: REQUEST_PRINT_PROPERTYTYPE
  };
}

function receivePrintPropertyType(turnos) {
  return {
    type: SUCCESS_PRINT_PROPERTYTYPE,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintPropertyType(error) {
  return {
    type: ERROR_PRINT_PROPERTYTYPE,
    error: error
  };
}

export function resetPrintPropertyType() {
  return {
    type: RESET_PRINT_PROPERTYTYPE
  };
}

export function printPropertyType(propertyType) {
  return {
    type: PRINT_PROPERTYTYPE,
    propertyType
  };
}

export function deletePrintPropertyType(propertyType) {
  return {
    type: DELETE_PRINT_PROPERTYTYPE,
    propertyType
  };
}

export function savePrintPropertyType(idPropertyType) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintPropertyType());
    return propertyTypesApi
      .printPropertyType(idPropertyType)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintPropertyType(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintPropertyType(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintPropertyType(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintPropertyType(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintPropertyType(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT PROPERTYTYPES
export const REQUEST_PRINT_PROPERTYTYPES = 'REQUEST_PRINT_PROPERTYTYPES';
export const SUCCESS_PRINT_PROPERTYTYPES = 'SUCCESS_PRINT_PROPERTYTYPES';
export const ERROR_PRINT_PROPERTYTYPES = 'ERROR_PRINT_PROPERTYTYPES';
export const RESET_PRINT_PROPERTYTYPES = 'RESET_PRINT_PROPERTYTYPES';

function requestPrintPropertyTypes() {
  return {
    type: REQUEST_PRINT_PROPERTYTYPES
  };
}

function receivePrintPropertyTypes(propertyTypes) {
  return {
    type: SUCCESS_PRINT_PROPERTYTYPES,
    receivedAt: Date.now(),
    propertyTypes: normalizeDatos(propertyTypes)
  };
}

function errorPrintPropertyTypes(error) {
  return {
    type: ERROR_PRINT_PROPERTYTYPES,
    error: error
  };
}

export function resetPrintPropertyTypes() {
  return {
    type: RESET_PRINT_PROPERTYTYPES
  };
}

export function savePrintPropertyTypes() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintPropertyType());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let propertyTypes = getState().propertyTypes.print.printers.map(
      idPropertyType => {
        return denormalizeDato(
          getState().propertyTypes.print.print[idPropertyType],
          store
        );
      }
    );
    return propertyTypesApi
      .printPropertyTypes(propertyTypes)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintPropertyTypes(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintPropertyTypes(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintPropertyTypes(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintPropertyTypes(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(
                    errorPrintPropertyTypes(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}
