import React, { useMemo } from 'react';
import { LinearProgress, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    display: '-webkit-box',
    lineClamp: '1',
    boxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: 'initial',
    width: '350px'
  }
}));

function SearchCard(props) {
  const classes = useStyles();
  const analytics = useSelector(state => state.analytics);
  const totalSessions_properties = analytics.byId.analytics[props.metrics];
  const total = useMemo(() => {
    return totalSessions_properties?.metric.reduce(
      (accumulator, currentValue) => accumulator + currentValue.value,
      0
    );
  }, totalSessions_properties);

  return (
    <Box minHeight="250px" width="23%" overflowY="auto">
      <Box display="flex" flexDirection="row">
        <Typography variant="button" component="h7">
          {props.title}
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
        marginTop="15px"
        borderBottom="1px solid rgba(122,120,120,0.2)"
      >
        <Typography variant="caption" component="h7">
          Búsqueda
        </Typography>
        <Typography variant="caption" component="h7">
          Usuarios
        </Typography>
      </Box>
      <Box
        width="100%"
        maxHeight="450px"
        overflow="auto"
        marginTop="1rem"
        paddingRight="0.5rem"
      >
        {totalSessions_properties?.metric.map(metric => {
          return (
            <Box marginTop="10px" key={metric.title}>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                width="100%"
              >
                <Typography
                  variant="caption"
                  component="h6"
                  className={classes.title}
                >
                  {metric.des}
                </Typography>
                <Typography variant="caption" component="h7">
                  {metric.value}
                </Typography>
              </Box>
              <Box width="100%">
                <LinearProgress
                  variant="determinate"
                  color="primary"
                  value={(metric.value * 100) / total}
                />
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default SearchCard;
