import React, { forwardRef, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

//Components
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
//Icons
//Actions
import {
  createUser,
  saveCreateUser,
  saveCreateUsers,
  updateUser
} from '../../actions/UserActions';
//constants
//Hooks
import { useValidador } from '../../utils/validador';
//Reset
import Button from '@material-ui/core/Button';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: '15px',
    [theme.breakpoints.down('sm')]: {
      padding: '5px 5px'
    },
    '& input': {
      width: '500px'
    }
  },
  tarjeta: {
    padding: '10px 20px 20px 20px',
    marginBottom: '17px'
  },
  mensaje: {
    display: 'none',
    color: theme.palette.error.main,
    '&.visible': {
      display: 'inherit'
    }
  },
  instructions: {
    marginTop: theme.spacing(6),
    position: 'relative'
  },
  buttonProgress: {
    color: theme.palette.secondary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

const RenderHeader = forwardRef((props, ref) => {
  return (
    <TextField
      {...props}
      inputRef={ref}
      fullWidth
      label="Celular"
      variant="outlined"
      name="phone"
      margin="dense"
    />
  );
});

export default function SignUpUser(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleNext } = props;
  let msjError = useRef();
  const msjError2 = useRef();
  const ref = React.createRef();

  //Storell
  const users = useSelector(state => state.users);
  let user = users.create.nuevo;

  const guardandoLoading = users.create.isCreating;
  const error = users.create.error;

  //Hooks
  let reglas = {};
  reglas = {
    name: ['required'],
    lastname: ['required'],
    email: ['required'],
    cellphone: ['required'],
    password: ['required'],
    verifyPassword: ['required']
  };
  const [validador, validar] = useValidador(reglas);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //User
  const handleChangeUser = e => {
    msjError.current.style.display = 'none';
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    changeUser(cambio);
    validar(e);
  };

  const handleChangeCelularUser = value => {
    msjError2.current.classList.remove('visible');
    if (value) {
      let cambio = {};
      cambio.cellphone = value;
      changeUser(cambio);
    }
  };

  const changeUser = cambio => {
    dispatch(createUser(cambio));
  };

  const onSubmit = e => {
    e.preventDefault();
    if (user.password !== user.verifyPassword)
      msjError.current.style.display = 'inline';
    else if (!user.cellphone || !isValidPhoneNumber(user.cellphone)) {
      msjError2.current.classList.add('visible');
      msjError2.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    } else if (validador.isValid()) {
      dispatch(saveCreateUser());
    }
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <form onSubmit={onSubmit}>
        <Grid container spacing={1} direction="row">
          <Grid item lg={12} sm={12} xs={12}>
            <Box
              display={'flex'}
              alignItems={'baseline'}
              justifyContent={'flex-start'}
              marginBottom={'15px'}
            >
              <Typography
                variant="body2"
                component="p"
                style={{ paddingRight: '5px', color: 'grey' }}
              >
                ¿Ya tienes cuenta?
              </Typography>
              <Link href="/login" variant="body2" color={'primary'}>
                {'Iniciar sesión'}
              </Link>
            </Box>
            <Typography variant="h1" component="h2">
              Te damos la bienvenida a Glassprop.
            </Typography>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <TextField
              id="name"
              label="Nombre"
              placeholder="Nombre"
              fullWidth={true}
              margin="dense"
              variant="outlined"
              InputProps={{}}
              value={user && user.name ? user.name : ''}
              onChange={handleChangeUser}
              onBlur={validar}
              error={!validador.isValidById('name')}
              helperText={validador.getHelperTextById('name')}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <TextField
              id="lastname"
              label="Apellido"
              placeholder="Apellido"
              fullWidth
              margin="dense"
              variant="outlined"
              InputProps={{}}
              value={user && user.lastname ? user.lastname : ''}
              onChange={handleChangeUser}
              onBlur={validar}
              error={!validador.isValidById('lastname')}
              helperText={validador.getHelperTextById('lastname')}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <TextField
              id="email"
              label="E-mail"
              placeholder="E-mail"
              fullWidth
              margin="dense"
              variant="outlined"
              InputProps={{ type: 'text' }}
              value={user && user.email ? user.email : ''}
              onChange={handleChangeUser}
              onBlur={validar}
              error={!validador.isValidById('email')}
              helperText={validador.getHelperTextById('email')}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <PhoneInput
              id="cellphone"
              label="Celular"
              placeholder="Celular"
              defaultCountry={'AR'}
              international={true}
              countryCallingCodeEditable={false}
              inputComponentRef={ref}
              value={user?.cellphone ? user.cellphone : ''}
              onChange={handleChangeCelularUser}
              inputComponent={RenderHeader}
            />
            <FormHelperText ref={msjError2} className={classes.mensaje}>
              El celular no es válido
            </FormHelperText>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <TextField
              id="password"
              label="Contraseña"
              placeholder="Contraseña"
              fullWidth
              margin="dense"
              variant="outlined"
              InputProps={{ type: 'password' }}
              value={user && user.password ? user.password : ''}
              onChange={handleChangeUser}
              onBlur={validar}
              error={!validador.isValidById('password')}
              helperText={validador.getHelperTextById('password')}
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <TextField
              id="verifyPassword"
              label="Repetir contraseña"
              placeholder="Contraseña"
              fullWidth
              margin="dense"
              variant="outlined"
              InputProps={{ type: 'password' }}
              value={user && user.verifyPassword ? user.verifyPassword : ''}
              onChange={handleChangeUser}
              onBlur={validar}
              error={!validador.isValidById('verifyPassword')}
              helperText={validador.getHelperTextById('verifyPassword')}
            />
            <FormHelperText
              ref={msjError}
              style={{ display: 'none', color: 'red' }}
            >
              Las contraseñas no coinciden
            </FormHelperText>
          </Grid>
        </Grid>
        <Box
          container
          direction="column"
          justifyContent="center"
          alignItems="flex-end"
          className={classes.instructions}
        >
          <Button
            variant="contained"
            color="primary"
            type={'submit'}
            fullWidth={true}
            disabled={guardandoLoading}
          >
            Registrarme
          </Button>
          {guardandoLoading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </Box>
      </form>
    </Container>
  );
}
