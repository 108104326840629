import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
//Components
import CRUDProperty from './CRUDProperty';
import PropertiesListPage from './PropertiesListPage';
import propertyReset from '../../reset/propertyReset';
import propertyAttributeValueReset from '../../reset/propertyAttributeValueReset';
import attributeReset from '../../reset/attributeReset';
import attributeGroupReset from '../../reset/attributeGroupReset';
import assetReset from '../../reset/assetReset';

export default function Property() {
  const dispatch = useDispatch();
  //Effects
  useEffect(() => {
    return function cleanup() {
      propertyReset.resetAll(dispatch);
      propertyAttributeValueReset.resetAll(dispatch);
      attributeReset.resetAll(dispatch);
      attributeGroupReset.resetAll(dispatch);
      assetReset.resetAll(dispatch);
    };
  }, []);

  return (
    <Switch>
      <Route path="/properties/developments" component={PropertiesListPage} />
      <Route
        path="/properties/new/:idPropertyCopyFrom?"
        component={CRUDProperty}
      />
      <Route path="/properties/:idProperty" component={CRUDProperty} />
      <Route path="/properties" component={PropertiesListPage} />
    </Switch>
  );
}
