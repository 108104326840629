import { normalize, schema, denormalize } from 'normalizr';
import attribute from './normalizeAttributes';

const attributeGroup = new schema.Entity(
  'attributesGroups',
  { attribute: [attribute] },
  { idAttribute: 'id' }
);

export function normalizeDatos(myData) {
  const mySchema = { attributesGroups: [attributeGroup] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = attributeGroup;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = attributeGroup;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default attributeGroup;
