//api
import typesFilesApi from '../api/typesFilesApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizeTypesFiles';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//TYPESFILES
export const REQUEST_TYPESFILES = 'REQUEST_TYPESFILES';
export const RECEIVE_TYPESFILES = 'RECEIVE_TYPESFILES';
export const INVALIDATE_TYPESFILES = 'INVALIDATE_TYPESFILES';
export const ERROR_TYPESFILES = 'ERROR_TYPESFILES';
export const RESET_TYPESFILES = 'RESET_TYPESFILES';

export function invalidateTypesFiles() {
  return {
    type: INVALIDATE_TYPESFILES
  };
}

function requestTypesFiles() {
  return {
    type: REQUEST_TYPESFILES
  };
}

function receiveTypesFiles(json) {
  return {
    type: RECEIVE_TYPESFILES,
    typesFiles: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorTypesFiles(error) {
  return {
    type: ERROR_TYPESFILES,
    error: error
  };
}

export function resetTypesFiles() {
  return {
    type: RESET_TYPESFILES
  };
}

export function fetchTypesFiles(filtros) {
  return dispatch => {
    dispatch(requestTypesFiles());
    return typesFilesApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveTypesFiles(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorTypesFiles(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorTypesFiles(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

function shouldFetchTypesFiles(state) {
  const typesFiles = state.typesFiles.byId;
  if (!typesFiles) {
    return true;
  } else if (typesFiles.isFetching) {
    return false;
  } else {
    return typesFiles.didInvalidate;
  }
}

export function fetchTypesFilesIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchTypesFiles(getState())) {
      return dispatch(fetchTypesFiles(filtros));
    }
  };
}

//MODEL
export const REQUEST_TYPEFILE = 'REQUEST_TYPEFILE';
export const RECEIVE_TYPEFILE = 'RECEIVE_TYPEFILE';
export const INVALIDATE_TYPEFILE = 'INVALIDATE_TYPEFILE';
export const ERROR_TYPEFILE = 'ERROR_TYPEFILE';
export const RESET_TYPEFILE = 'RESET_TYPEFILE';

export function invalidateTypeFile() {
  return {
    type: INVALIDATE_TYPEFILE
  };
}

function requestTypeFile() {
  return {
    type: REQUEST_TYPEFILE
  };
}

export function receiveTypeFile(json) {
  return {
    type: RECEIVE_TYPEFILE,
    typeFile: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorTypeFile(error) {
  return {
    type: ERROR_TYPEFILE,
    error: error
  };
}

export function fetchTypeFile(idTypeFile) {
  return dispatch => {
    dispatch(requestTypeFile());
    return typesFilesApi
      .getOne(idTypeFile)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveTypeFile(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(errorTypeFile(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorTypeFile(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_TYPEFILE = 'RECEIVE_FILE_TYPEFILE';

function receiveFileTypeFile(file) {
  return {
    type: RECEIVE_FILE_TYPEFILE,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFileTypeFile(idTypeFile, filtros) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return typesFilesApi
      .getFile(idTypeFile, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFileTypeFile(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorTypeFile(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorTypeFile(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_TYPEFILE = 'UPDATE_TYPEFILE';
export const REQUEST_UPDATE_TYPEFILE = 'REQUEST_UPDATE_TYPEFILE';
export const SUCCESS_UPDATE_TYPEFILE = 'SUCCESS_UPDATE_TYPEFILE';
export const ERROR_UPDATE_TYPEFILE = 'ERROR_UPDATE_TYPEFILE';
export const RESET_UPDATE_TYPEFILE = 'RESET_UPDATE_TYPEFILE';
export const DELETE_UPDATE_TYPEFILE = 'DELETE_UPDATE_TYPEFILE';

function requestUpdateTypeFile() {
  return {
    type: REQUEST_UPDATE_TYPEFILE
  };
}

function receiveUpdateTypeFile(typeFile) {
  return {
    type: SUCCESS_UPDATE_TYPEFILE,
    receivedAt: Date.now(),
    typeFile: normalizeDato(typeFile)
  };
}

function errorUpdateTypeFile(error) {
  return {
    type: ERROR_UPDATE_TYPEFILE,
    error: error
  };
}

export function resetUpdateTypeFile() {
  return {
    type: RESET_UPDATE_TYPEFILE
  };
}

export function updateTypeFile(typeFile) {
  return {
    type: UPDATE_TYPEFILE,
    typeFile
  };
}

export function saveUpdateTypeFile() {
  return (dispatch, getState) => {
    dispatch(requestUpdateTypeFile());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let typeFile = denormalizeDato(getState().typesFiles.update.activo, store);

    return typesFilesApi
      .saveUpdate(typeFile)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateTypeFile(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateTypeFile(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateTypesFiles(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdateTypesFiles(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateTypeFile(JSON.parse(error.message)));
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdateTypeFile(error.data));
                })
                .catch(() => {
                  dispatch(errorUpdateTypeFile(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

export function deleteUpdateTypeFile(typeFile) {
  return {
    type: DELETE_UPDATE_TYPEFILE,
    typeFile
  };
}

//UPDATE TYPESFILES
export const REQUEST_UPDATE_TYPESFILES = 'REQUEST_UPDATE_TYPESFILES';
export const SUCCESS_UPDATE_TYPESFILES = 'SUCCESS_UPDATE_TYPESFILES';
export const ERROR_UPDATE_TYPESFILES = 'ERROR_UPDATE_TYPESFILES';
export const RESET_UPDATE_TYPESFILES = 'RESET_UPDATE_TYPESFILES';

function requestUpdateTypesFiles() {
  return {
    type: REQUEST_UPDATE_TYPESFILES
  };
}

function receiveUpdateTypesFiles(typesFiles) {
  return {
    type: SUCCESS_UPDATE_TYPESFILES,
    receivedAt: Date.now(),
    typesFiles: normalizeDatos(typesFiles)
  };
}

function errorUpdateTypesFiles(error) {
  return {
    type: ERROR_UPDATE_TYPESFILES,
    error: error
  };
}

export function resetUpdateTypesFiles() {
  return {
    type: RESET_UPDATE_TYPESFILES
  };
}

export function saveUpdateTypesFiles() {
  return (dispatch, getState) => {
    dispatch(requestUpdateTypesFiles());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let typesFiles = getState().typesFiles.update.activos.map(idTypeFile => {
      return denormalizeDato(
        getState().typesFiles.update.activo[idTypeFile],
        store
      );
    });

    return typesFilesApi
      .saveUpdateTypesFiles(typesFiles)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateTypesFiles(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateTypesFiles(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateTypesFiles(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdateTypesFiles(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateTypesFiles(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorUpdateTypesFiles(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//ALTA TYPEFILE
export const CREATE_TYPEFILE = 'CREATE_TYPEFILE';
export const REQUEST_CREATE_TYPEFILE = 'REQUEST_CREATE_TYPEFILE';
export const SUCCESS_CREATE_TYPEFILE = 'SUCCESS_CREATE_TYPEFILE';
export const ERROR_CREATE_TYPEFILE = 'ERROR_CREATE_TYPEFILE';
export const RESET_CREATE_TYPEFILE = 'RESET_CREATE_TYPEFILE';
export const DELETE_CREATE_TYPEFILE = 'DELETE_CREATE_TYPEFILE';

//ALTA TYPEFILE
function requestCreateTypeFile() {
  return {
    type: REQUEST_CREATE_TYPEFILE
  };
}

function receiveCreateTypeFile(typeFile) {
  return {
    type: SUCCESS_CREATE_TYPEFILE,
    receivedAt: Date.now(),
    typeFile: normalizeDato(typeFile)
  };
}

export function errorCreateTypeFile(error) {
  return {
    type: ERROR_CREATE_TYPEFILE,
    error: error
  };
}

export function resetCreateTypeFile() {
  return {
    type: RESET_CREATE_TYPEFILE
  };
}

export function createTypeFile(typeFile) {
  return {
    type: CREATE_TYPEFILE,
    typeFile
  };
}

export function deleteCreateTypeFile(typeFile) {
  return {
    type: DELETE_CREATE_TYPEFILE,
    typeFile
  };
}

export function saveCreateTypeFile() {
  return (dispatch, getState) => {
    dispatch(requestCreateTypeFile());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let typeFile = denormalizeDato(getState().typesFiles.create.nuevo, store);

    return typesFilesApi
      .saveCreate(typeFile)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateTypeFile(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateTypeFile(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateTypesFiles(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreateTypesFiles(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateTypeFile(JSON.parse(error.message)));
                  if (error.data) dispatch(receiveCreateTypeFile(error.data));
                })
                .catch(() => {
                  dispatch(errorCreateTypeFile(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//CREATE TYPESFILES
export const REQUEST_CREATE_TYPESFILES = 'REQUEST_CREATE_TYPESFILES';
export const SUCCESS_CREATE_TYPESFILES = 'SUCCESS_CREATE_TYPESFILES';
export const ERROR_CREATE_TYPESFILES = 'ERROR_CREATE_TYPESFILES';
export const RESET_CREATE_TYPESFILES = 'RESET_CREATE_TYPESFILES';

function requestCreateTypesFiles() {
  return {
    type: REQUEST_CREATE_TYPESFILES
  };
}

function receiveCreateTypesFiles(typesFiles) {
  return {
    type: SUCCESS_CREATE_TYPESFILES,
    receivedAt: Date.now(),
    typesFiles: normalizeDatos(typesFiles)
  };
}

function errorCreateTypesFiles(error) {
  return {
    type: ERROR_CREATE_TYPESFILES,
    error: error
  };
}

export function resetCreateTypesFiles() {
  return {
    type: RESET_CREATE_TYPESFILES
  };
}

export function saveCreateTypesFiles() {
  return (dispatch, getState) => {
    dispatch(requestCreateTypesFiles());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let typesFiles = getState().typesFiles.create.nuevos.map(idTypeFile => {
      return denormalizeDato(
        getState().typesFiles.create.nuevo[idTypeFile],
        store
      );
    });

    return typesFilesApi
      .saveCreateTypesFiles(typesFiles)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateTypesFiles(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateTypesFiles(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateTypesFiles(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreateTypesFiles(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateTypesFiles(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorCreateTypesFiles(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//DELETE TYPEFILE
export const DELETE_TYPEFILE = 'DELETE_TYPEFILE';
export const REQUEST_DELETE_TYPEFILE = 'REQUEST_DELETE_TYPEFILE';
export const SUCCESS_DELETE_TYPEFILE = 'SUCCESS_DELETE_TYPEFILE';
export const ERROR_DELETE_TYPEFILE = 'ERROR_DELETE_TYPEFILE';
export const RESET_DELETE_TYPEFILE = 'RESET_DELETE_TYPEFILE';

function requestDeleteTypeFile() {
  return {
    type: REQUEST_DELETE_TYPEFILE
  };
}

function receiveDeleteTypeFile(typeFile) {
  return {
    type: SUCCESS_DELETE_TYPEFILE,
    receivedAt: Date.now(),
    typeFile: normalizeDato(typeFile)
  };
}

function errorDeleteTypeFile(error) {
  return {
    type: ERROR_DELETE_TYPEFILE,
    error: error
  };
}

export function resetDeleteTypeFile(error) {
  return {
    type: RESET_DELETE_TYPEFILE,
    error: error
  };
}

export function deleteTypeFile(typeFile) {
  return {
    type: DELETE_TYPEFILE,
    typeFile
  };
}

export function saveDeleteTypeFile(typeFile) {
  return dispatch => {
    dispatch(requestDeleteTypeFile());
    return typesFilesApi
      .saveDelete(typeFile)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeleteTypeFile());
        dispatch(receiveDeleteTypeFile(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorDeleteTypeFile(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeleteTypeFile(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorDeleteTypeFile(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorDeleteTypeFile(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorDeleteTypeFile(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT TYPEFILE
export const PRINT_TYPEFILE = 'PRINT_TYPEFILE';
export const REQUEST_PRINT_TYPEFILE = 'REQUEST_PRINT_TYPEFILE';
export const SUCCESS_PRINT_TYPEFILE = 'SUCCESS_PRINT_TYPEFILE';
export const ERROR_PRINT_TYPEFILE = 'ERROR_PRINT_TYPEFILE';
export const RESET_PRINT_TYPEFILE = 'RESET_PRINT_TYPEFILE';
export const DELETE_PRINT_TYPEFILE = 'DELETE_PRINT_TYPEFILE';

function requestPrintTypeFile() {
  return {
    type: REQUEST_PRINT_TYPEFILE
  };
}

function receivePrintTypeFile(turnos) {
  return {
    type: SUCCESS_PRINT_TYPEFILE,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintTypeFile(error) {
  return {
    type: ERROR_PRINT_TYPEFILE,
    error: error
  };
}

export function resetPrintTypeFile() {
  return {
    type: RESET_PRINT_TYPEFILE
  };
}

export function printTypeFile(typeFile) {
  return {
    type: PRINT_TYPEFILE,
    typeFile
  };
}

export function deletePrintTypeFile(typeFile) {
  return {
    type: DELETE_PRINT_TYPEFILE,
    typeFile
  };
}

export function savePrintTypeFile(idTypeFile) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintTypeFile());
    return typesFilesApi
      .printTypeFile(idTypeFile)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintTypeFile(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintTypeFile(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintTypeFile(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintTypeFile(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintTypeFile(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT TYPESFILES
export const REQUEST_PRINT_TYPESFILES = 'REQUEST_PRINT_TYPESFILES';
export const SUCCESS_PRINT_TYPESFILES = 'SUCCESS_PRINT_TYPESFILES';
export const ERROR_PRINT_TYPESFILES = 'ERROR_PRINT_TYPESFILES';
export const RESET_PRINT_TYPESFILES = 'RESET_PRINT_TYPESFILES';

function requestPrintTypesFiles() {
  return {
    type: REQUEST_PRINT_TYPESFILES
  };
}

function receivePrintTypesFiles(typesFiles) {
  return {
    type: SUCCESS_PRINT_TYPESFILES,
    receivedAt: Date.now(),
    typesFiles: normalizeDatos(typesFiles)
  };
}

function errorPrintTypesFiles(error) {
  return {
    type: ERROR_PRINT_TYPESFILES,
    error: error
  };
}

export function resetPrintTypesFiles() {
  return {
    type: RESET_PRINT_TYPESFILES
  };
}

export function savePrintTypesFiles() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintTypeFile());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let typesFiles = getState().typesFiles.print.printers.map(idTypeFile => {
      return denormalizeDato(
        getState().typesFiles.print.print[idTypeFile],
        store
      );
    });
    return typesFilesApi
      .printTypesFiles(typesFiles)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintTypesFiles(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintTypesFiles(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintTypesFiles(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintTypesFiles(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintTypesFiles(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}
