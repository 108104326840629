import {
  INVALIDATE_PROPERTYCLOSEDOPERATIONS,
  ERROR_PROPERTYCLOSEDOPERATIONS,
  RECEIVE_PROPERTYCLOSEDOPERATIONS,
  REQUEST_PROPERTYCLOSEDOPERATIONS,
  RESET_PROPERTYCLOSEDOPERATIONS,
  ERROR_PROPERTYCLOSEDOPERATION,
  RECEIVE_PROPERTYCLOSEDOPERATION,
  REQUEST_PROPERTYCLOSEDOPERATION,
  UPDATE_PROPERTYCLOSEDOPERATION,
  REQUEST_UPDATE_PROPERTYCLOSEDOPERATION,
  SUCCESS_UPDATE_PROPERTYCLOSEDOPERATION,
  ERROR_UPDATE_PROPERTYCLOSEDOPERATION,
  RESET_UPDATE_PROPERTYCLOSEDOPERATION,
  REQUEST_UPDATE_PROPERTYCLOSEDOPERATIONS,
  SUCCESS_UPDATE_PROPERTYCLOSEDOPERATIONS,
  ERROR_UPDATE_PROPERTYCLOSEDOPERATIONS,
  RESET_UPDATE_PROPERTYCLOSEDOPERATIONS,
  CREATE_PROPERTYCLOSEDOPERATION,
  ERROR_CREATE_PROPERTYCLOSEDOPERATION,
  REQUEST_CREATE_PROPERTYCLOSEDOPERATION,
  RESET_CREATE_PROPERTYCLOSEDOPERATION,
  SUCCESS_CREATE_PROPERTYCLOSEDOPERATION,
  REQUEST_CREATE_PROPERTYCLOSEDOPERATIONS,
  SUCCESS_CREATE_PROPERTYCLOSEDOPERATIONS,
  ERROR_CREATE_PROPERTYCLOSEDOPERATIONS,
  RESET_CREATE_PROPERTYCLOSEDOPERATIONS,
  DELETE_PROPERTYCLOSEDOPERATION,
  DELETE_CREATE_PROPERTYCLOSEDOPERATION,
  DELETE_UPDATE_PROPERTYCLOSEDOPERATION,
  REQUEST_DELETE_PROPERTYCLOSEDOPERATION,
  SUCCESS_DELETE_PROPERTYCLOSEDOPERATION,
  ERROR_DELETE_PROPERTYCLOSEDOPERATION,
  RESET_DELETE_PROPERTYCLOSEDOPERATION,
  REQUEST_PRINT_PROPERTYCLOSEDOPERATION,
  SUCCESS_PRINT_PROPERTYCLOSEDOPERATION,
  ERROR_PRINT_PROPERTYCLOSEDOPERATION,
  RESET_PRINT_PROPERTYCLOSEDOPERATION,
  RECEIVE_FILE_PROPERTYCLOSEDOPERATION,
  REQUEST_PRINT_PROPERTYCLOSEDOPERATIONS,
  SUCCESS_PRINT_PROPERTYCLOSEDOPERATIONS,
  ERROR_PRINT_PROPERTYCLOSEDOPERATIONS,
  RESET_PRINT_PROPERTYCLOSEDOPERATIONS,
  RECEIVE_FILE_PROPERTYCLOSEDOPERATIONS,
  PRINT_PROPERTYCLOSEDOPERATION,
  DELETE_PRINT_PROPERTYCLOSEDOPERATION
} from '../actions/PropertyClosedOperationActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    propertyClosedOperations: {},
    files: {}
  };
}

function propertyClosedOperationsById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_PROPERTYCLOSEDOPERATIONS:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_PROPERTYCLOSEDOPERATIONS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_PROPERTYCLOSEDOPERATIONS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_PROPERTYCLOSEDOPERATIONS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        propertyClosedOperations: {}
      });
    case RECEIVE_PROPERTYCLOSEDOPERATIONS:
      let dato =
        action.propertyClosedOperations.entities.propertyClosedOperations;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        propertyClosedOperations: merge(
          {},
          state.propertyClosedOperations,
          dato
        ),
        lastUpdated: action.receivedAt
      });
    case REQUEST_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_PROPERTYCLOSEDOPERATION:
      let datoPropertyClosedOperation =
        action.propertyClosedOperation.entities.propertyClosedOperations;
      return Object.assign({}, state, {
        propertyClosedOperations: merge(
          {},
          state.propertyClosedOperations,
          datoPropertyClosedOperation
        ),
        isFetching: false
      });
    case RECEIVE_FILE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_PROPERTYCLOSEDOPERATION:
      let datoPropertyClosedOperationEliminado =
        action.propertyClosedOperation.entities.propertyClosedOperations;
      return Object.assign({}, state, {
        propertyClosedOperations: mergeWith(
          clone(datoPropertyClosedOperationEliminado),
          state.propertyClosedOperations,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_PROPERTYCLOSEDOPERATION:
      let datoPropertyClosedOperationCreado =
        action.propertyClosedOperation.entities.propertyClosedOperations;
      return Object.assign({}, state, {
        propertyClosedOperations: mergeWith(
          clone(datoPropertyClosedOperationCreado),
          state.propertyClosedOperations,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_PROPERTYCLOSEDOPERATIONS:
      let datosPropertyClosedOperationCreado =
        action.propertyClosedOperations.entities.propertyClosedOperations;
      return Object.assign({}, state, {
        propertyClosedOperations: mergeWith(
          clone(datosPropertyClosedOperationCreado),
          state.propertyClosedOperations,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTYCLOSEDOPERATION:
      let datoPropertyClosedOperationActualizado =
        action.propertyClosedOperation.entities.propertyClosedOperations;
      return Object.assign({}, state, {
        propertyClosedOperations: mergeWith(
          clone(datoPropertyClosedOperationActualizado),
          state.propertyClosedOperations,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_PROPERTYCLOSEDOPERATIONS:
      let datosPropertyClosedOperationActualizado =
        action.propertyClosedOperations.entities.propertyClosedOperations;
      return Object.assign({}, state, {
        propertyClosedOperations: mergeWith(
          clone(datosPropertyClosedOperationActualizado),
          state.propertyClosedOperations,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        propertyClosedOperations: {}
      });
    default:
      return state;
  }
}

function allPropertyClosedOperations(state = [], action) {
  switch (action.type) {
    case RECEIVE_PROPERTYCLOSEDOPERATIONS:
      return action.propertyClosedOperations.result &&
        action.propertyClosedOperations.result.propertyClosedOperations
        ? union(
            action.propertyClosedOperations.result.propertyClosedOperations,
            state
          )
        : action.propertyClosedOperations.result
        ? action.propertyClosedOperations.result
        : state;
    case RECEIVE_PROPERTYCLOSEDOPERATION:
      return action.propertyClosedOperation.result
        ? union([action.propertyClosedOperation.result], state)
        : state;

    case SUCCESS_CREATE_PROPERTYCLOSEDOPERATION:
      let datoPropertyClosedOperationSCreate =
        action.propertyClosedOperation.entities.propertyClosedOperations;
      let idNuevoSCreate = null;
      if (Object.values(datoPropertyClosedOperationSCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoPropertyClosedOperationSCreate)[0] &&
          Object.values(datoPropertyClosedOperationSCreate)[0].id
            ? Object.values(datoPropertyClosedOperationSCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_PROPERTYCLOSEDOPERATIONS:
      let propertyClosedOperationsCreate =
        action.propertyClosedOperations.entities &&
        action.propertyClosedOperations.entities.propertyClosedOperations
          ? action.propertyClosedOperations.entities.propertyClosedOperations
          : null;
      return propertyClosedOperationsCreate
        ? union(
            state,
            Object.values(propertyClosedOperationsCreate).map(
              propertyClosedOperations => {
                return propertyClosedOperations.id;
              }
            )
          )
        : state;
    case RESET_PROPERTYCLOSEDOPERATIONS:
      return [];

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalPropertyClosedOperations(state = null, action) {
  switch (action.type) {
    case RECEIVE_PROPERTYCLOSEDOPERATIONS:
      return action.propertyClosedOperations &&
        action.propertyClosedOperations.result.total
        ? action.propertyClosedOperations.result.total
        : 0;
    case RESET_PROPERTYCLOSEDOPERATIONS:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_PROPERTYCLOSEDOPERATION:
      let dato =
        action.propertyClosedOperation.entities.propertyClosedOperations;
      let propertyClosedOperation =
        dato && Object.keys(dato).length > 0
          ? dato[action.propertyClosedOperation.result]
          : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: propertyClosedOperation ? propertyClosedOperation : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_PROPERTYCLOSEDOPERATION:
      let idsUpdate = [];
      Object.values(action.propertyClosedOperation).map(
        propertyClosedOperationUpdate => {
          if (propertyClosedOperationUpdate && propertyClosedOperationUpdate.id)
            idsUpdate.push(propertyClosedOperationUpdate.id);
        }
      );
      return merge({}, state, {
        activo: action.propertyClosedOperation,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_PROPERTYCLOSEDOPERATION:
      let datoPropertyClosedOperationActualizado =
        action.propertyClosedOperation.entities.propertyClosedOperations;
      let propertyClosedOperationNuevo =
        datoPropertyClosedOperationActualizado &&
        Object.keys(datoPropertyClosedOperationActualizado).length > 0
          ? datoPropertyClosedOperationActualizado[
              action.propertyClosedOperation.result
            ]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: propertyClosedOperationNuevo
      });
    case ERROR_UPDATE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_PROPERTYCLOSEDOPERATIONS:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_PROPERTYCLOSEDOPERATIONS:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_PROPERTYCLOSEDOPERATIONS:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    case DELETE_PROPERTYCLOSEDOPERATION:
      let datoPropertyClosedOperationDelete = action.propertyClosedOperation;
      let idsDelete = [];
      Object.values(action.propertyClosedOperation).map(
        propertyClosedOperationDelete => {
          if (propertyClosedOperationDelete && propertyClosedOperationDelete.id)
            idsDelete.push(propertyClosedOperationDelete.id);
        }
      );
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoPropertyClosedOperationDelete)
          ),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_PROPERTYCLOSEDOPERATION:
      let datoPropertyClosedOperationDeleteUpdate =
        action.propertyClosedOperation;
      let idsDeleteUpdate = [];
      Object.values(action.propertyClosedOperation).map(
        propertyClosedOperationDelete => {
          if (propertyClosedOperationDelete && propertyClosedOperationDelete.id)
            idsDeleteUpdate.push(propertyClosedOperationDelete.id);
        }
      );
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoPropertyClosedOperationDeleteUpdate)
          ),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_PROPERTYCLOSEDOPERATION:
      let datoPropertyClosedOperationDeleted = {};
      if (
        Object.values(
          action.propertyClosedOperation.entities.propertyClosedOperations
        ).length > 0
      )
        datoPropertyClosedOperationDeleted = Object.values(
          action.propertyClosedOperation.entities.propertyClosedOperations
        )[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoPropertyClosedOperationDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_PROPERTYCLOSEDOPERATION:
      let idsCreate = [];
      Object.values(action.propertyClosedOperation).map(
        propertyClosedOperationCreate => {
          if (propertyClosedOperationCreate && propertyClosedOperationCreate.id)
            idsCreate.push(propertyClosedOperationCreate.id);
        }
      );
      return merge({}, state, {
        isCreating: false,
        nuevo: action.propertyClosedOperation,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_PROPERTYCLOSEDOPERATION:
      let datoPropertyClosedOperationNuevo =
        action.propertyClosedOperation.entities.propertyClosedOperations;
      let propertyClosedOperationNuevo =
        datoPropertyClosedOperationNuevo &&
        Object.keys(datoPropertyClosedOperationNuevo).length > 0
          ? datoPropertyClosedOperationNuevo[
              action.propertyClosedOperation.result
            ]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: propertyClosedOperationNuevo,
        nuevos: []
      });
    case ERROR_CREATE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_PROPERTYCLOSEDOPERATIONS:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_PROPERTYCLOSEDOPERATIONS:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_PROPERTYCLOSEDOPERATIONS:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    case DELETE_PROPERTYCLOSEDOPERATION:
      let datoPropertyClosedOperationDelete = action.propertyClosedOperation;
      let idsDelete = [];
      Object.values(action.propertyClosedOperation).map(
        propertyClosedOperationDelete => {
          if (propertyClosedOperationDelete && propertyClosedOperationDelete.id)
            idsDelete.push(propertyClosedOperationDelete.id);
        }
      );
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoPropertyClosedOperationDelete)
          ),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_PROPERTYCLOSEDOPERATION:
      let datoPropertyClosedOperationDeleteCreate =
        action.propertyClosedOperation;
      let idsDeleteCreate = [];
      Object.values(action.propertyClosedOperation).map(
        propertyClosedOperationDelete => {
          if (propertyClosedOperationDelete && propertyClosedOperationDelete.id)
            idsDeleteCreate.push(propertyClosedOperationDelete.id);
        }
      );
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoPropertyClosedOperationDeleteCreate)
          ),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_PROPERTYCLOSEDOPERATION:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.propertyClosedOperation,
        error: null
      });
    case REQUEST_DELETE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_PROPERTYCLOSEDOPERATION:
      let idsCreate = [];
      Object.values(action.propertyClosedOperation).map(
        propertyClosedOperationCreate => {
          if (propertyClosedOperationCreate && propertyClosedOperationCreate.id)
            idsCreate.push(propertyClosedOperationCreate.id);
        }
      );
      return merge({}, state, {
        isCreating: false,
        print: action.propertyClosedOperation,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_PROPERTYCLOSEDOPERATIONS:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_PROPERTYCLOSEDOPERATIONS:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_PROPERTYCLOSEDOPERATIONS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_PROPERTYCLOSEDOPERATION:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_PROPERTYCLOSEDOPERATION:
      let datoPropertyClosedOperationDeleteCreate =
        action.propertyClosedOperation;
      let idsDeleteCreate = [];
      Object.values(action.propertyClosedOperation).map(
        propertyClosedOperationDelete => {
          if (propertyClosedOperationDelete && propertyClosedOperationDelete.id)
            idsDeleteCreate.push(propertyClosedOperationDelete.id);
        }
      );
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(
            clone(state.print),
            Object.keys(datoPropertyClosedOperationDeleteCreate)
          ),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const propertyClosedOperations = combineReducers({
  byId: propertyClosedOperationsById,
  allIds: allPropertyClosedOperations,
  update: update,
  create: create,
  totalPropertyClosedOperations: totalPropertyClosedOperations,
  delete: deleter,
  print: print
});

export default propertyClosedOperations;
