import { normalize, schema, denormalize } from 'normalizr';
import state from './normalizeStates';

const country = new schema.Entity(
  'countries',
  { state: [state] },
  { idAttribute: 'id' }
);

export function normalizeDatos(myData) {
  const mySchema = { countries: [country] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = country;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = country;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default country;
