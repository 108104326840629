import React, { useEffect } from 'react';
import {
  Box,
  Chip,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import List from '@material-ui/core/List';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import clone from 'lodash/clone';
import {
  createPropertyAttributeValue,
  deletePropertyAttributeValue,
  updatePropertyAttributeValue
} from '../../actions/PropertyAttributeValueActions';
import { useDispatch, useSelector } from 'react-redux';
import HomeIcon from '@material-ui/icons/Home';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import SecurityIcon from '@material-ui/icons/Security';
import PoolIcon from '@material-ui/icons/Pool';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import HotTubIcon from '@material-ui/icons/HotTub';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import PropertyBasicAttributes from './PropertyBasicAttributes';

function PropertyAdicionalAttributes(props) {
  const dispatch = useDispatch();
  const { property, disabled } = props;

  const [open, setOpen] = React.useState([]);
  const [attributesSelected, setAttributesSelected] = React.useState([]);

  //Store
  const attributes = useSelector(state => state.attributes);
  const attributesGroups = useSelector(state => state.attributesGroups);
  const propertiesAttributesValues = useSelector(
    state => state.propertiesAttributesValues
  );

  useEffect(() => {
    let _attributes = [];
    attributes.allIds.map(option => {
      let attribute = attributes.byId.attributes[option];
      let attributeGroup =
        attributesGroups.byId.attributesGroups[attribute.idAttributeGroup];
      if (
        attributeGroup.codAttributeGroup !== 'basic' &&
        attributeGroup.codAttributeGroup !== 'surfaces' &&
        attributeGroup.codAttributeGroup !== 'antiquity' &&
        attributeGroup.codAttributeGroup !== 'expenses' &&
        attributeGroup.codAttributeGroup !== 'main'
      )
        _attributes.push(attribute ? attribute : '');
    });
    setAttributesSelected(_attributes);
  }, [attributes.allIds]);

  //Property attribute value

  const getIcon = codAttributeGroup => {
    switch (codAttributeGroup) {
      case 'distribution':
        return <HomeIcon />;
      case 'services':
        return <RoomServiceIcon />;
      case 'security':
        return <SecurityIcon />;
      case 'amenities':
        return <PoolIcon />;
      case 'air_conditioning':
        return <AcUnitIcon />;
      case 'hot_water':
        return <HotTubIcon />;
      default:
        return <AddCircleOutlineIcon />;
    }
  };

  const handleClick = idAttributeGroup => {
    if (open.indexOf(idAttributeGroup) === -1) {
      setOpen([...open, idAttributeGroup]);
    } else {
      setOpen(open.filter(item => item !== idAttributeGroup));
    }
  };

  const handleSelectAttribute = (e, attributes) => {
    attributes.forEach(attribute => {
      if (attribute && attribute.id) {
        let idPropertyAttributeValue = isChecked(attribute.id);
        let cambio = {};
        if (!idPropertyAttributeValue) {
          cambio.value = true;
          let id = idPropertyAttributeValue
            ? idPropertyAttributeValue
            : Date.now() + Math.random() + '-';
          changePropertyAttributeValue(cambio, id, attribute.id);
        }
      }
    });
  };

  const changePropertyAttributeValue = (
    cambio,
    idPropertyAttributeValue,
    idAttribute
  ) => {
    //Verificar si ya esta siendo actualizado
    let propertyAttributeValue =
      idPropertyAttributeValue &&
      propertiesAttributesValues.update.activos.indexOf(
        idPropertyAttributeValue
      ) === -1 &&
      propertiesAttributesValues.create.nuevos.indexOf(
        idPropertyAttributeValue
      ) === -1
        ? propertiesAttributesValues.byId.propertiesAttributesValues[
            idPropertyAttributeValue
          ]
        : null;
    if (propertyAttributeValue) {
      let actualizar = propertyAttributeValue
        ? clone(propertyAttributeValue)
        : null;
      if (actualizar) {
        let cambioPropertyAttributeValue = {};
        cambioPropertyAttributeValue[actualizar.id] = actualizar;
        if (idPropertyAttributeValue.toString().indexOf('-') === -1)
          dispatch(updatePropertyAttributeValue(cambioPropertyAttributeValue));
        else
          dispatch(createPropertyAttributeValue(cambioPropertyAttributeValue));
      }
    }
    cambio.id = idPropertyAttributeValue;
    cambio.idProperty = property.id;
    cambio.idAttribute = idAttribute;
    let cambioPropertyAttributeValue = {};
    cambioPropertyAttributeValue[idPropertyAttributeValue] = cambio;
    if (idPropertyAttributeValue.toString().indexOf('-') === -1)
      dispatch(updatePropertyAttributeValue(cambioPropertyAttributeValue));
    else dispatch(createPropertyAttributeValue(cambioPropertyAttributeValue));
  };

  const handleDeletePropertyAttributeValue = propertyAttributeValue => {
    let cambioUbicacion = {};
    if (propertyAttributeValue) {
      cambioUbicacion[propertyAttributeValue.id] = clone(
        propertyAttributeValue
      );
      dispatch(deletePropertyAttributeValue(cambioUbicacion));
    }
  };

  const isChecked = idAttribute => {
    let isChecked = false;
    if (property.property_attribute_value)
      property.property_attribute_value.some(idAttributeValue => {
        let propertyAttributeValue =
          propertiesAttributesValues.byId.propertiesAttributesValues[
            idAttributeValue
          ];
        if (
          propertiesAttributesValues.update.activos.indexOf(
            idAttributeValue
          ) !== -1
        )
          propertyAttributeValue =
            propertiesAttributesValues.update.activo[idAttributeValue];
        if (
          propertiesAttributesValues.create.nuevos.indexOf(idAttributeValue) !==
          -1
        )
          propertyAttributeValue =
            propertiesAttributesValues.create.nuevo[idAttributeValue];
        if (
          propertyAttributeValue &&
          propertyAttributeValue.idAttribute === idAttribute
        ) {
          isChecked = propertyAttributeValue.id;
          return true;
        } else return false;
      });
    return isChecked;
  };

  return (
    <Grid item md={12} sm={12} xs={12}>
      <Box marginBottom={'30px'}>
        <Typography variant={'h2'} component={'span'}>
          Agregá las comodidades de tu propiedad.
        </Typography>
      </Box>
      <Autocomplete
        multiple
        id="tags-standard"
        options={attributesSelected}
        onChange={handleSelectAttribute}
        value={
          property && property.property_attribute_value
            ? property.property_attribute_value
            : []
        }
        getOptionLabel={option => option.nameAttribute}
        renderTags={(value, getTagProps) =>
          property &&
          property.property_attribute_value &&
          property.property_attribute_value.map(
            (idPropertyAttributeValue, index) => {
              let _propertyAttributeValue =
                propertiesAttributesValues.byId.propertiesAttributesValues[
                  idPropertyAttributeValue
                ];
              if (
                propertiesAttributesValues.update.activos.indexOf(
                  idPropertyAttributeValue
                ) !== -1
              )
                _propertyAttributeValue =
                  propertiesAttributesValues.update.activo[
                    idPropertyAttributeValue
                  ];
              if (
                propertiesAttributesValues.create.nuevos.indexOf(
                  idPropertyAttributeValue
                ) !== -1
              )
                _propertyAttributeValue =
                  propertiesAttributesValues.create.nuevo[
                    idPropertyAttributeValue
                  ];
              let attribute =
                attributes.byId.attributes[_propertyAttributeValue.idAttribute];
              let attributeGroup =
                attributesGroups.byId.attributesGroups[
                  attribute.idAttributeGroup
                ];
              if (
                attributeGroup.codAttributeGroup !== 'basic' &&
                attributeGroup.codAttributeGroup !== 'surfaces' &&
                attributeGroup.codAttributeGroup !== 'antiquity' &&
                attributeGroup.codAttributeGroup !== 'expenses' &&
                attributeGroup.codAttributeGroup !== 'main'
              )
                return (
                  <Chip
                    variant="outlined"
                    label={attribute.nameAttribute}
                    onDelete={() =>
                      handleDeletePropertyAttributeValue(
                        _propertyAttributeValue
                      )
                    }
                  />
                );
            }
          )
        }
        renderInput={params => (
          <TextField
            {...params}
            variant="outlined"
            label="Busca y agregá características"
            placeholder="Ejemplo: Apto mascotas"
          />
        )}
      />
      <Box marginTop={'30px'} marginBottom={'15px'}>
        <Typography variant={'h2'} component={'span'}>
          También podes seleccionarlas de la lista.
        </Typography>
      </Box>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        // className={classes.root}
      >
        {attributesGroups.allIds.map(idAttributeGroup => {
          let attributeGroup =
            attributesGroups.byId.attributesGroups[idAttributeGroup];
          if (
            attributeGroup.codAttributeGroup !== 'basic' &&
            attributeGroup.codAttributeGroup !== 'surfaces' &&
            attributeGroup.codAttributeGroup !== 'antiquity' &&
            attributeGroup.codAttributeGroup !== 'expenses' &&
            attributeGroup.codAttributeGroup !== 'main'
          )
            return (
              <>
                <ListItem button onClick={() => handleClick(idAttributeGroup)}>
                  <ListItemIcon>
                    {getIcon(attributeGroup.codAttributeGroup)}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant={'body1'} component={'p'}>
                        {attributeGroup
                          ? attributeGroup.nameAttributeGroup
                          : ''}
                      </Typography>
                    }
                  />
                  {!open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse
                  in={open.indexOf(idAttributeGroup) !== -1}
                  timeout="auto"
                  unmountOnExit
                >
                  <PropertyBasicAttributes
                    attributeGroup={attributeGroup}
                    disabled={disabled}
                    property={property}
                  />
                </Collapse>
                <Divider />
              </>
            );
        })}
      </List>
    </Grid>
  );
}

export default PropertyAdicionalAttributes;
