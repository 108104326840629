import jwt_decode from 'jwt-decode';
import { receiveLogin } from '../actions/AuthActions';

var authUtil = {
  getUser() {
    return localStorage.token ? jwt_decode(localStorage.token) : '';
  },
  isLoggedIn() {
    return !!localStorage.token;
  },
  addToken(response, dispatch) {
    if (response.headers)
      for (var pair of response.headers.entries()) {
        console.log(pair);
        if (pair[0] === 'authorization' && pair[1].split(' ')[1]) {
          localStorage.token = pair[1].split(' ')[1];
        }
      }
    else if (
      response.getResponseHeader &&
      response.getResponseHeader('authorization') &&
      response.getResponseHeader('authorization').split(' ')[1]
    )
      localStorage.token = response
        .getResponseHeader('authorization')
        .split(' ')[1];
    var datos = jwt_decode(localStorage.token);
    if (dispatch) dispatch(receiveLogin(datos));
  }
};

export default authUtil;
