import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
//Components
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import YoutubePlayer from 'youtube-player';
import Player from '@vimeo/player';
//Icons
import clone from 'lodash/clone';
import union from 'lodash/union';
import { useDispatch, useSelector } from 'react-redux';
import {
  createAsset,
  deleteAsset,
  updateAsset
} from '../../actions/AssetActions';
//Img
import c from '../../constants/Constants';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';

const styles = makeStyles(theme => ({
  form: {
    // padding: theme.spacing(2, 0)
  },
  btnImg: {
    backgroundColor: 'white',
    marginLeft: '10px'
  },
  videoContainer: {
    width: '394px',
    height: '240px',
    marginRight: '20px',
    marginBottom: '20px',
    '& iframe': {
      width: '100%',
      height: '100%'
    }
  },
  playerContainer: {
    width: '100%',
    height: '100%'
  },
  url: {
    '& button': {
      marginLeft: '10px',
      '@media (max-width: 960px)': {
        marginLeft: '0px'
      }
    }
  }
}));

export default function PropertyTour360Assets(props) {
  const classes = styles();
  const dispatch = useDispatch();
  let textFile = React.useRef();
  let inputfileprod = React.createRef();
  let img = React.useRef();
  const mounted = useRef();
  const { property, files, setFiles, type, file } = props;
  //Hooks
  const properties = useSelector(state => state.properties);
  const assets = useSelector(state => state.assets);
  //State
  const [url, setUrl] = React.useState(null);
  const player = useRef({});

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    }
  }, [properties.create.isCreating]);

  useEffect(() => {
    //VIDEO
    if (property && property.asset) {
      property.asset &&
        property.asset.map((idAsset, i) => {
          let asset = assets.byId.assets[idAsset];
          if (assets.update.activos.indexOf(idAsset) !== -1)
            asset = assets.update.activo[idAsset];
          if (assets.create.nuevos.indexOf(idAsset) !== -1)
            asset = assets.create.nuevo[idAsset];
          let link = asset.url;
          if (asset.type === type) {
            if (link && link.indexOf('youtu') !== -1) {
              //Inicializar reproductor
              player.current[asset.id] = YoutubePlayer('player-' + asset.id, {
                playerVars: {
                  autoplay: 0,
                  controls: 1,
                  autohide: 1
                }
              });

              //Ejecutar video
              if (link.indexOf('embed') === -1) {
                let array = link.split('/');
                array.splice(3, 0, 'embed');
                link = array.join('/');
              }
              player.current[asset.id].loadVideoByUrl(link);
            } else if (link && link.indexOf('vimeo') !== -1) {
              if (link.indexOf('player.vimeo.com/video') === -1) {
                let array = link.split('vimeo.com/');
                link = array[array.length - 1];
              }
              new Player('player-' + asset.id, {
                id: link
              });
            }
          }
        });
    }
  }, [property.asset && property.asset.length]);

  const handleChangeAsset = (e, idAsset) => {
    let id = idAsset ? idAsset : Date.now() + Math.random() + '-';
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    changeAsset(cambio, id);
  };

  const changeAsset = (cambio, idAsset) => {
    //Verificar si ya esta siendo actualizado
    let asset =
      idAsset &&
      assets.update.activos.indexOf(idAsset) === -1 &&
      assets.create.nuevos.indexOf(idAsset) === -1
        ? assets.byId.assets[idAsset]
        : null;
    if (asset) {
      let actualizar = asset ? clone(asset) : null;
      if (actualizar) {
        let cambioAsset = {};
        cambioAsset[actualizar.id] = actualizar;
        if (idAsset.toString().indexOf('-') === -1)
          dispatch(updateAsset(cambioAsset));
        else dispatch(createAsset(cambioAsset));
      }
    }
    cambio.id = idAsset;
    cambio.idProperty = property.id;
    cambio.type = type;
    cambio.url = url;
    setUrl(null);
    let cambioAsset = {};
    cambioAsset[idAsset] = cambio;
    if (idAsset.toString().indexOf('-') === -1)
      dispatch(updateAsset(cambioAsset));
    else dispatch(createAsset(cambioAsset));
  };

  const deleteAssetFile = asset => {
    let cambioUbicacion = {};
    cambioUbicacion[asset.id] = clone(asset);
    dispatch(deleteAsset(cambioUbicacion));
  };

  return (
    <Box
      component={'div'}
      className={classes.form}
      display={'flex'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Box component={'div'} width={'100%'}>
        <Box
          component={'div'}
          display={'flex'}
          width={'100%'}
          className={classes.url}
        >
          <TextField
            id="url"
            placeholder={'URL'}
            variant="outlined"
            label={'URL'}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              // disabled: disabled
              type: 'text'
            }}
            // disabled={true}
            fullWidth
            value={url ? url : ''}
            onChange={e => setUrl(e.target.value)}
            // onKeyDown={(e) => handleKeyDown(e)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleChangeAsset}
          >
            Agregar
          </Button>
        </Box>
        <Box
          display={'flex'}
          flexWrap={'wrap'}
          component={'div'}
          width={'100%'}
          justifyContent={'flex-start'}
          marginTop={'20px'}
        >
          {property.asset &&
            property.asset.map((idAsset, i) => {
              let asset = assets.byId.assets[idAsset];
              if (assets.update.activos.indexOf(idAsset) !== -1)
                asset = assets.update.activo[idAsset];
              if (assets.create.nuevos.indexOf(idAsset) !== -1)
                asset = assets.create.nuevo[idAsset];
              // let src = previews.get(asset.filename)
              if (asset.type === type)
                return (
                  <Box
                    component={'div'}
                    position={'relative'}
                    className={classes.videoContainer}
                  >
                    {/*<div className={classes.playerContainer} id={"player-" + idAsset}></div>*/}
                    <iframe
                      width="100%"
                      height="100%"
                      src={asset.url}
                      frameBorder="0"
                    ></iframe>
                    <Box
                      position={'absolute'}
                      right={'10px'}
                      bottom={'10px'}
                      display={'flex'}
                    >
                      <IconButton
                        size="small"
                        aria-label="close"
                        onClick={() => deleteAssetFile(asset)}
                        color="primary"
                        className={classes.btnImg}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </Box>
                );
            })}
        </Box>
      </Box>
    </Box>
  );
}

PropertyTour360Assets.propTypes = {
  legend: PropTypes.string
};
