import {
  resetAssets,
  resetCreateAsset,
  resetUpdateAsset,
  resetDeleteAsset
} from '../actions/AssetActions';
import {
  resetProperties,
  resetCreateProperty,
  resetUpdateProperty,
  resetDeleteProperty
} from '../actions/PropertyActions';
import {
  resetPosts,
  resetCreatePost,
  resetUpdatePost,
  resetDeletePost
} from '../actions/PostActions';
import {
  resetTickets,
  resetCreateTicket,
  resetUpdateTicket,
  resetDeleteTicket
} from '../actions/TicketActions';

var resetAsset = {
  resetAll(dispatch) {
    dispatch(resetAssets());
    dispatch(resetCreateAsset());
    dispatch(resetUpdateAsset());
    dispatch(resetDeleteAsset());
    dispatch(resetProperties());
    dispatch(resetCreateProperty());
    dispatch(resetUpdateProperty());
    dispatch(resetDeleteProperty());
    dispatch(resetPosts());
    dispatch(resetCreatePost());
    dispatch(resetUpdatePost());
    dispatch(resetDeletePost());
    dispatch(resetTickets());
    dispatch(resetCreateTicket());
    dispatch(resetUpdateTicket());
    dispatch(resetDeleteTicket());
  }
};

export default resetAsset;
