//api
import propertyClosedOperationsApi from '../api/propertyClosedOperationsApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizePropertyClosedOperations';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//PROPERTYCLOSEDOPERATIONS
export const REQUEST_PROPERTYCLOSEDOPERATIONS =
  'REQUEST_PROPERTYCLOSEDOPERATIONS';
export const RECEIVE_PROPERTYCLOSEDOPERATIONS =
  'RECEIVE_PROPERTYCLOSEDOPERATIONS';
export const INVALIDATE_PROPERTYCLOSEDOPERATIONS =
  'INVALIDATE_PROPERTYCLOSEDOPERATIONS';
export const ERROR_PROPERTYCLOSEDOPERATIONS = 'ERROR_PROPERTYCLOSEDOPERATIONS';
export const RESET_PROPERTYCLOSEDOPERATIONS = 'RESET_PROPERTYCLOSEDOPERATIONS';

export function invalidatePropertyClosedOperations() {
  return {
    type: INVALIDATE_PROPERTYCLOSEDOPERATIONS
  };
}

function requestPropertyClosedOperations() {
  return {
    type: REQUEST_PROPERTYCLOSEDOPERATIONS
  };
}

function receivePropertyClosedOperations(json) {
  return {
    type: RECEIVE_PROPERTYCLOSEDOPERATIONS,
    propertyClosedOperations: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorPropertyClosedOperations(error) {
  return {
    type: ERROR_PROPERTYCLOSEDOPERATIONS,
    error: error
  };
}

export function resetPropertyClosedOperations() {
  return {
    type: RESET_PROPERTYCLOSEDOPERATIONS
  };
}

export function fetchPropertyClosedOperations(filtros) {
  return dispatch => {
    dispatch(requestPropertyClosedOperations());
    return propertyClosedOperationsApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receivePropertyClosedOperations(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(
              errorPropertyClosedOperations(errorMessages.UNAUTHORIZED_TOKEN)
            );
            dispatch(logout());
            return;
          default:
            dispatch(
              errorPropertyClosedOperations(errorMessages.GENERAL_ERROR)
            );
            return;
        }
      });
  };
}

function shouldFetchPropertyClosedOperations(state) {
  const propertyClosedOperations = state.propertyClosedOperations.byId;
  if (!propertyClosedOperations) {
    return true;
  } else if (propertyClosedOperations.isFetching) {
    return false;
  } else {
    return propertyClosedOperations.didInvalidate;
  }
}

export function fetchPropertyClosedOperationsIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchPropertyClosedOperations(getState())) {
      return dispatch(fetchPropertyClosedOperations(filtros));
    }
  };
}

//MODEL
export const REQUEST_PROPERTYCLOSEDOPERATION =
  'REQUEST_PROPERTYCLOSEDOPERATION';
export const RECEIVE_PROPERTYCLOSEDOPERATION =
  'RECEIVE_PROPERTYCLOSEDOPERATION';
export const INVALIDATE_PROPERTYCLOSEDOPERATION =
  'INVALIDATE_PROPERTYCLOSEDOPERATION';
export const ERROR_PROPERTYCLOSEDOPERATION = 'ERROR_PROPERTYCLOSEDOPERATION';
export const RESET_PROPERTYCLOSEDOPERATION = 'RESET_PROPERTYCLOSEDOPERATION';

export function invalidatePropertyClosedOperation() {
  return {
    type: INVALIDATE_PROPERTYCLOSEDOPERATION
  };
}

function requestPropertyClosedOperation() {
  return {
    type: REQUEST_PROPERTYCLOSEDOPERATION
  };
}

export function receivePropertyClosedOperation(json) {
  return {
    type: RECEIVE_PROPERTYCLOSEDOPERATION,
    propertyClosedOperation: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorPropertyClosedOperation(error) {
  return {
    type: ERROR_PROPERTYCLOSEDOPERATION,
    error: error
  };
}

export function fetchPropertyClosedOperation(idPropertyClosedOperation) {
  return dispatch => {
    dispatch(requestPropertyClosedOperation());
    return propertyClosedOperationsApi
      .getOne(idPropertyClosedOperation)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receivePropertyClosedOperation(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(
              errorPropertyClosedOperation(errorMessages.UNAUTHORIZED_TOKEN)
            );
            dispatch(logout());
            return;
          default:
            dispatch(errorPropertyClosedOperation(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_PROPERTYCLOSEDOPERATION =
  'RECEIVE_FILE_PROPERTYCLOSEDOPERATION';

function receiveFilePropertyClosedOperation(file) {
  return {
    type: RECEIVE_FILE_PROPERTYCLOSEDOPERATION,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFilePropertyClosedOperation(
  idPropertyClosedOperation,
  filtros
) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return propertyClosedOperationsApi
      .getFile(idPropertyClosedOperation, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFilePropertyClosedOperation(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(
              errorPropertyClosedOperation(errorMessages.UNAUTHORIZED_TOKEN)
            );
            dispatch(logout());
            return;
          default:
            dispatch(errorPropertyClosedOperation(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_PROPERTYCLOSEDOPERATION = 'UPDATE_PROPERTYCLOSEDOPERATION';
export const REQUEST_UPDATE_PROPERTYCLOSEDOPERATION =
  'REQUEST_UPDATE_PROPERTYCLOSEDOPERATION';
export const SUCCESS_UPDATE_PROPERTYCLOSEDOPERATION =
  'SUCCESS_UPDATE_PROPERTYCLOSEDOPERATION';
export const ERROR_UPDATE_PROPERTYCLOSEDOPERATION =
  'ERROR_UPDATE_PROPERTYCLOSEDOPERATION';
export const RESET_UPDATE_PROPERTYCLOSEDOPERATION =
  'RESET_UPDATE_PROPERTYCLOSEDOPERATION';
export const DELETE_UPDATE_PROPERTYCLOSEDOPERATION =
  'DELETE_UPDATE_PROPERTYCLOSEDOPERATION';

function requestUpdatePropertyClosedOperation() {
  return {
    type: REQUEST_UPDATE_PROPERTYCLOSEDOPERATION
  };
}

function receiveUpdatePropertyClosedOperation(propertyClosedOperation) {
  return {
    type: SUCCESS_UPDATE_PROPERTYCLOSEDOPERATION,
    receivedAt: Date.now(),
    propertyClosedOperation: normalizeDato(propertyClosedOperation)
  };
}

function errorUpdatePropertyClosedOperation(error) {
  return {
    type: ERROR_UPDATE_PROPERTYCLOSEDOPERATION,
    error: error
  };
}

export function resetUpdatePropertyClosedOperation() {
  return {
    type: RESET_UPDATE_PROPERTYCLOSEDOPERATION
  };
}

export function updatePropertyClosedOperation(propertyClosedOperation) {
  return {
    type: UPDATE_PROPERTYCLOSEDOPERATION,
    propertyClosedOperation
  };
}

export function saveUpdatePropertyClosedOperation() {
  return (dispatch, getState) => {
    dispatch(requestUpdatePropertyClosedOperation());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let propertyClosedOperation = denormalizeDato(
      getState().propertyClosedOperations.update.activo,
      store
    );

    return propertyClosedOperationsApi
      .saveUpdate(propertyClosedOperation)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdatePropertyClosedOperation(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorUpdatePropertyClosedOperation(
                errorMessages.UNAUTHORIZED_TOKEN
              )
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdatePropertyClosedOperations(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorUpdatePropertyClosedOperations(
                    error.responseJSON.message
                  )
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorUpdatePropertyClosedOperation(
                      JSON.parse(error.message)
                    )
                  );
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdatePropertyClosedOperation(error.data));
                })
                .catch(() => {
                  dispatch(
                    errorUpdatePropertyClosedOperation(
                      errorMessages.GENERAL_ERROR
                    )
                  );
                });
            return;
        }
      });
  };
}

export function deleteUpdatePropertyClosedOperation(propertyClosedOperation) {
  return {
    type: DELETE_UPDATE_PROPERTYCLOSEDOPERATION,
    propertyClosedOperation
  };
}

//UPDATE PROPERTYCLOSEDOPERATIONS
export const REQUEST_UPDATE_PROPERTYCLOSEDOPERATIONS =
  'REQUEST_UPDATE_PROPERTYCLOSEDOPERATIONS';
export const SUCCESS_UPDATE_PROPERTYCLOSEDOPERATIONS =
  'SUCCESS_UPDATE_PROPERTYCLOSEDOPERATIONS';
export const ERROR_UPDATE_PROPERTYCLOSEDOPERATIONS =
  'ERROR_UPDATE_PROPERTYCLOSEDOPERATIONS';
export const RESET_UPDATE_PROPERTYCLOSEDOPERATIONS =
  'RESET_UPDATE_PROPERTYCLOSEDOPERATIONS';

function requestUpdatePropertyClosedOperations() {
  return {
    type: REQUEST_UPDATE_PROPERTYCLOSEDOPERATIONS
  };
}

function receiveUpdatePropertyClosedOperations(propertyClosedOperations) {
  return {
    type: SUCCESS_UPDATE_PROPERTYCLOSEDOPERATIONS,
    receivedAt: Date.now(),
    propertyClosedOperations: normalizeDatos(propertyClosedOperations)
  };
}

function errorUpdatePropertyClosedOperations(error) {
  return {
    type: ERROR_UPDATE_PROPERTYCLOSEDOPERATIONS,
    error: error
  };
}

export function resetUpdatePropertyClosedOperations() {
  return {
    type: RESET_UPDATE_PROPERTYCLOSEDOPERATIONS
  };
}

export function saveUpdatePropertyClosedOperations() {
  return (dispatch, getState) => {
    dispatch(requestUpdatePropertyClosedOperations());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let propertyClosedOperations =
      getState().propertyClosedOperations.update.activos.map(
        idPropertyClosedOperation => {
          return denormalizeDato(
            getState().propertyClosedOperations.update.activo[
              idPropertyClosedOperation
            ],
            store
          );
        }
      );

    return propertyClosedOperationsApi
      .saveUpdatePropertyClosedOperations(propertyClosedOperations)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdatePropertyClosedOperations(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorUpdatePropertyClosedOperations(
                errorMessages.UNAUTHORIZED_TOKEN
              )
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdatePropertyClosedOperations(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorUpdatePropertyClosedOperations(
                    error.responseJSON.message
                  )
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorUpdatePropertyClosedOperations(
                      JSON.parse(error.message)
                    )
                  );
                })
                .catch(() => {
                  dispatch(
                    errorUpdatePropertyClosedOperations(
                      errorMessages.GENERAL_ERROR
                    )
                  );
                });

            return;
        }
      });
  };
}

//ALTA PROPERTYCLOSEDOPERATION
export const CREATE_PROPERTYCLOSEDOPERATION = 'CREATE_PROPERTYCLOSEDOPERATION';
export const REQUEST_CREATE_PROPERTYCLOSEDOPERATION =
  'REQUEST_CREATE_PROPERTYCLOSEDOPERATION';
export const SUCCESS_CREATE_PROPERTYCLOSEDOPERATION =
  'SUCCESS_CREATE_PROPERTYCLOSEDOPERATION';
export const ERROR_CREATE_PROPERTYCLOSEDOPERATION =
  'ERROR_CREATE_PROPERTYCLOSEDOPERATION';
export const RESET_CREATE_PROPERTYCLOSEDOPERATION =
  'RESET_CREATE_PROPERTYCLOSEDOPERATION';
export const DELETE_CREATE_PROPERTYCLOSEDOPERATION =
  'DELETE_CREATE_PROPERTYCLOSEDOPERATION';

//ALTA PROPERTYCLOSEDOPERATION
function requestCreatePropertyClosedOperation() {
  return {
    type: REQUEST_CREATE_PROPERTYCLOSEDOPERATION
  };
}

function receiveCreatePropertyClosedOperation(propertyClosedOperation) {
  return {
    type: SUCCESS_CREATE_PROPERTYCLOSEDOPERATION,
    receivedAt: Date.now(),
    propertyClosedOperation: normalizeDato(propertyClosedOperation)
  };
}

export function errorCreatePropertyClosedOperation(error) {
  return {
    type: ERROR_CREATE_PROPERTYCLOSEDOPERATION,
    error: error
  };
}

export function resetCreatePropertyClosedOperation() {
  return {
    type: RESET_CREATE_PROPERTYCLOSEDOPERATION
  };
}

export function createPropertyClosedOperation(propertyClosedOperation) {
  return {
    type: CREATE_PROPERTYCLOSEDOPERATION,
    propertyClosedOperation
  };
}

export function deleteCreatePropertyClosedOperation(propertyClosedOperation) {
  return {
    type: DELETE_CREATE_PROPERTYCLOSEDOPERATION,
    propertyClosedOperation
  };
}

export function saveCreatePropertyClosedOperation() {
  return (dispatch, getState) => {
    dispatch(requestCreatePropertyClosedOperation());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let propertyClosedOperation = denormalizeDato(
      getState().propertyClosedOperations.create.nuevo,
      store
    );

    return propertyClosedOperationsApi
      .saveCreate(propertyClosedOperation)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreatePropertyClosedOperation(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorCreatePropertyClosedOperation(
                errorMessages.UNAUTHORIZED_TOKEN
              )
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreatePropertyClosedOperations(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorCreatePropertyClosedOperations(
                    error.responseJSON.message
                  )
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorCreatePropertyClosedOperation(
                      JSON.parse(error.message)
                    )
                  );
                  if (error.data)
                    dispatch(receiveCreatePropertyClosedOperation(error.data));
                })
                .catch(() => {
                  dispatch(
                    errorCreatePropertyClosedOperation(
                      errorMessages.GENERAL_ERROR
                    )
                  );
                });
            return;
        }
      });
  };
}

//CREATE PROPERTYCLOSEDOPERATIONS
export const REQUEST_CREATE_PROPERTYCLOSEDOPERATIONS =
  'REQUEST_CREATE_PROPERTYCLOSEDOPERATIONS';
export const SUCCESS_CREATE_PROPERTYCLOSEDOPERATIONS =
  'SUCCESS_CREATE_PROPERTYCLOSEDOPERATIONS';
export const ERROR_CREATE_PROPERTYCLOSEDOPERATIONS =
  'ERROR_CREATE_PROPERTYCLOSEDOPERATIONS';
export const RESET_CREATE_PROPERTYCLOSEDOPERATIONS =
  'RESET_CREATE_PROPERTYCLOSEDOPERATIONS';

function requestCreatePropertyClosedOperations() {
  return {
    type: REQUEST_CREATE_PROPERTYCLOSEDOPERATIONS
  };
}

function receiveCreatePropertyClosedOperations(propertyClosedOperations) {
  return {
    type: SUCCESS_CREATE_PROPERTYCLOSEDOPERATIONS,
    receivedAt: Date.now(),
    propertyClosedOperations: normalizeDatos(propertyClosedOperations)
  };
}

function errorCreatePropertyClosedOperations(error) {
  return {
    type: ERROR_CREATE_PROPERTYCLOSEDOPERATIONS,
    error: error
  };
}

export function resetCreatePropertyClosedOperations() {
  return {
    type: RESET_CREATE_PROPERTYCLOSEDOPERATIONS
  };
}

export function saveCreatePropertyClosedOperations() {
  return (dispatch, getState) => {
    dispatch(requestCreatePropertyClosedOperations());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let propertyClosedOperations =
      getState().propertyClosedOperations.create.nuevos.map(
        idPropertyClosedOperation => {
          return denormalizeDato(
            getState().propertyClosedOperations.create.nuevo[
              idPropertyClosedOperation
            ],
            store
          );
        }
      );

    return propertyClosedOperationsApi
      .saveCreatePropertyClosedOperations(propertyClosedOperations)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreatePropertyClosedOperations(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorCreatePropertyClosedOperations(
                errorMessages.UNAUTHORIZED_TOKEN
              )
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreatePropertyClosedOperations(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorCreatePropertyClosedOperations(
                    error.responseJSON.message
                  )
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorCreatePropertyClosedOperations(
                      JSON.parse(error.message)
                    )
                  );
                })
                .catch(() => {
                  dispatch(
                    errorCreatePropertyClosedOperations(
                      errorMessages.GENERAL_ERROR
                    )
                  );
                });

            return;
        }
      });
  };
}

//DELETE PROPERTYCLOSEDOPERATION
export const DELETE_PROPERTYCLOSEDOPERATION = 'DELETE_PROPERTYCLOSEDOPERATION';
export const REQUEST_DELETE_PROPERTYCLOSEDOPERATION =
  'REQUEST_DELETE_PROPERTYCLOSEDOPERATION';
export const SUCCESS_DELETE_PROPERTYCLOSEDOPERATION =
  'SUCCESS_DELETE_PROPERTYCLOSEDOPERATION';
export const ERROR_DELETE_PROPERTYCLOSEDOPERATION =
  'ERROR_DELETE_PROPERTYCLOSEDOPERATION';
export const RESET_DELETE_PROPERTYCLOSEDOPERATION =
  'RESET_DELETE_PROPERTYCLOSEDOPERATION';

function requestDeletePropertyClosedOperation() {
  return {
    type: REQUEST_DELETE_PROPERTYCLOSEDOPERATION
  };
}

function receiveDeletePropertyClosedOperation(propertyClosedOperation) {
  return {
    type: SUCCESS_DELETE_PROPERTYCLOSEDOPERATION,
    receivedAt: Date.now(),
    propertyClosedOperation: normalizeDato(propertyClosedOperation)
  };
}

function errorDeletePropertyClosedOperation(error) {
  return {
    type: ERROR_DELETE_PROPERTYCLOSEDOPERATION,
    error: error
  };
}

export function resetDeletePropertyClosedOperation(error) {
  return {
    type: RESET_DELETE_PROPERTYCLOSEDOPERATION,
    error: error
  };
}

export function deletePropertyClosedOperation(propertyClosedOperation) {
  return {
    type: DELETE_PROPERTYCLOSEDOPERATION,
    propertyClosedOperation
  };
}

export function saveDeletePropertyClosedOperation(propertyClosedOperation) {
  return dispatch => {
    dispatch(requestDeletePropertyClosedOperation());
    return propertyClosedOperationsApi
      .saveDelete(propertyClosedOperation)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeletePropertyClosedOperation());
        dispatch(receiveDeletePropertyClosedOperation(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorDeletePropertyClosedOperation(
                errorMessages.UNAUTHORIZED_TOKEN
              )
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeletePropertyClosedOperation(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorDeletePropertyClosedOperation(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorDeletePropertyClosedOperation(
                      JSON.parse(error.message)
                    )
                  );
                })
                .catch(() => {
                  dispatch(
                    errorDeletePropertyClosedOperation(
                      errorMessages.GENERAL_ERROR
                    )
                  );
                });
            return;
        }
      });
  };
}

//PRINT PROPERTYCLOSEDOPERATION
export const PRINT_PROPERTYCLOSEDOPERATION = 'PRINT_PROPERTYCLOSEDOPERATION';
export const REQUEST_PRINT_PROPERTYCLOSEDOPERATION =
  'REQUEST_PRINT_PROPERTYCLOSEDOPERATION';
export const SUCCESS_PRINT_PROPERTYCLOSEDOPERATION =
  'SUCCESS_PRINT_PROPERTYCLOSEDOPERATION';
export const ERROR_PRINT_PROPERTYCLOSEDOPERATION =
  'ERROR_PRINT_PROPERTYCLOSEDOPERATION';
export const RESET_PRINT_PROPERTYCLOSEDOPERATION =
  'RESET_PRINT_PROPERTYCLOSEDOPERATION';
export const DELETE_PRINT_PROPERTYCLOSEDOPERATION =
  'DELETE_PRINT_PROPERTYCLOSEDOPERATION';

function requestPrintPropertyClosedOperation() {
  return {
    type: REQUEST_PRINT_PROPERTYCLOSEDOPERATION
  };
}

function receivePrintPropertyClosedOperation(turnos) {
  return {
    type: SUCCESS_PRINT_PROPERTYCLOSEDOPERATION,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintPropertyClosedOperation(error) {
  return {
    type: ERROR_PRINT_PROPERTYCLOSEDOPERATION,
    error: error
  };
}

export function resetPrintPropertyClosedOperation() {
  return {
    type: RESET_PRINT_PROPERTYCLOSEDOPERATION
  };
}

export function printPropertyClosedOperation(propertyClosedOperation) {
  return {
    type: PRINT_PROPERTYCLOSEDOPERATION,
    propertyClosedOperation
  };
}

export function deletePrintPropertyClosedOperation(propertyClosedOperation) {
  return {
    type: DELETE_PRINT_PROPERTYCLOSEDOPERATION,
    propertyClosedOperation
  };
}

export function savePrintPropertyClosedOperation(idPropertyClosedOperation) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintPropertyClosedOperation());
    return propertyClosedOperationsApi
      .printPropertyClosedOperation(idPropertyClosedOperation)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintPropertyClosedOperation(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorPrintPropertyClosedOperation(
                errorMessages.UNAUTHORIZED_TOKEN
              )
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintPropertyClosedOperation(
                  JSON.parse(error.responseJSON.message)
                )
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorPrintPropertyClosedOperation(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorPrintPropertyClosedOperation(
                      errorMessages.GENERAL_ERROR
                    )
                  );
                });
            return;
        }
      });
  };
}

//PRINT PROPERTYCLOSEDOPERATIONS
export const REQUEST_PRINT_PROPERTYCLOSEDOPERATIONS =
  'REQUEST_PRINT_PROPERTYCLOSEDOPERATIONS';
export const SUCCESS_PRINT_PROPERTYCLOSEDOPERATIONS =
  'SUCCESS_PRINT_PROPERTYCLOSEDOPERATIONS';
export const ERROR_PRINT_PROPERTYCLOSEDOPERATIONS =
  'ERROR_PRINT_PROPERTYCLOSEDOPERATIONS';
export const RESET_PRINT_PROPERTYCLOSEDOPERATIONS =
  'RESET_PRINT_PROPERTYCLOSEDOPERATIONS';

function requestPrintPropertyClosedOperations() {
  return {
    type: REQUEST_PRINT_PROPERTYCLOSEDOPERATIONS
  };
}

function receivePrintPropertyClosedOperations(propertyClosedOperations) {
  return {
    type: SUCCESS_PRINT_PROPERTYCLOSEDOPERATIONS,
    receivedAt: Date.now(),
    propertyClosedOperations: normalizeDatos(propertyClosedOperations)
  };
}

function errorPrintPropertyClosedOperations(error) {
  return {
    type: ERROR_PRINT_PROPERTYCLOSEDOPERATIONS,
    error: error
  };
}

export function resetPrintPropertyClosedOperations() {
  return {
    type: RESET_PRINT_PROPERTYCLOSEDOPERATIONS
  };
}

export function savePrintPropertyClosedOperations() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintPropertyClosedOperation());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let propertyClosedOperations =
      getState().propertyClosedOperations.print.printers.map(
        idPropertyClosedOperation => {
          return denormalizeDato(
            getState().propertyClosedOperations.print.print[
              idPropertyClosedOperation
            ],
            store
          );
        }
      );
    return propertyClosedOperationsApi
      .printPropertyClosedOperations(propertyClosedOperations)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintPropertyClosedOperations(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorPrintPropertyClosedOperations(
                errorMessages.UNAUTHORIZED_TOKEN
              )
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintPropertyClosedOperations(
                  JSON.parse(error.responseJSON.message)
                )
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorPrintPropertyClosedOperations(
                      JSON.parse(error.message)
                    )
                  );
                })
                .catch(() => {
                  dispatch(
                    errorPrintPropertyClosedOperations(
                      errorMessages.GENERAL_ERROR
                    )
                  );
                });
            return;
        }
      });
  };
}
