import { normalize, schema, denormalize } from 'normalizr';

const dependency = new schema.Entity('dependencies', {}, { idAttribute: 'id' });

export function normalizeDatos(myData) {
  const mySchema = { dependencies: [dependency] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = dependency;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = dependency;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default dependency;
