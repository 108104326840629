//api
import locationsApi from '../api/locationsApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizeLocations';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//LOCATIONS
export const REQUEST_LOCATIONS = 'REQUEST_LOCATIONS';
export const RECEIVE_LOCATIONS = 'RECEIVE_LOCATIONS';
export const INVALIDATE_LOCATIONS = 'INVALIDATE_LOCATIONS';
export const ERROR_LOCATIONS = 'ERROR_LOCATIONS';
export const RESET_LOCATIONS = 'RESET_LOCATIONS';

export function invalidateLocations() {
  return {
    type: INVALIDATE_LOCATIONS
  };
}

function requestLocations() {
  return {
    type: REQUEST_LOCATIONS
  };
}

function receiveLocations(json) {
  return {
    type: RECEIVE_LOCATIONS,
    locations: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorLocations(error) {
  return {
    type: ERROR_LOCATIONS,
    error: error
  };
}

export function resetLocations() {
  return {
    type: RESET_LOCATIONS
  };
}

export function fetchLocations(filtros) {
  return dispatch => {
    dispatch(requestLocations());
    return locationsApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveLocations(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorLocations(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorLocations(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

function shouldFetchLocations(state) {
  const locations = state.locations.byId;
  if (!locations) {
    return true;
  } else if (locations.isFetching) {
    return false;
  } else {
    return locations.didInvalidate;
  }
}

export function fetchLocationsIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchLocations(getState())) {
      return dispatch(fetchLocations(filtros));
    }
  };
}

//MODEL
export const REQUEST_LOCATION = 'REQUEST_LOCATION';
export const RECEIVE_LOCATION = 'RECEIVE_LOCATION';
export const INVALIDATE_LOCATION = 'INVALIDATE_LOCATION';
export const ERROR_LOCATION = 'ERROR_LOCATION';
export const RESET_LOCATION = 'RESET_LOCATION';

export function invalidateLocation() {
  return {
    type: INVALIDATE_LOCATION
  };
}

function requestLocation() {
  return {
    type: REQUEST_LOCATION
  };
}

export function receiveLocation(json) {
  return {
    type: RECEIVE_LOCATION,
    location: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorLocation(error) {
  return {
    type: ERROR_LOCATION,
    error: error
  };
}

export function fetchLocation(idLocation) {
  return dispatch => {
    dispatch(requestLocation());
    return locationsApi
      .getOne(idLocation)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveLocation(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(errorLocation(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorLocation(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_LOCATION = 'RECEIVE_FILE_LOCATION';

function receiveFileLocation(file) {
  return {
    type: RECEIVE_FILE_LOCATION,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFileLocation(idLocation, filtros) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return locationsApi
      .getFile(idLocation, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFileLocation(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorLocation(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorLocation(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_LOCATION = 'UPDATE_LOCATION';
export const REQUEST_UPDATE_LOCATION = 'REQUEST_UPDATE_LOCATION';
export const SUCCESS_UPDATE_LOCATION = 'SUCCESS_UPDATE_LOCATION';
export const ERROR_UPDATE_LOCATION = 'ERROR_UPDATE_LOCATION';
export const RESET_UPDATE_LOCATION = 'RESET_UPDATE_LOCATION';
export const DELETE_UPDATE_LOCATION = 'DELETE_UPDATE_LOCATION';

function requestUpdateLocation() {
  return {
    type: REQUEST_UPDATE_LOCATION
  };
}

function receiveUpdateLocation(location) {
  return {
    type: SUCCESS_UPDATE_LOCATION,
    receivedAt: Date.now(),
    location: normalizeDato(location)
  };
}

function errorUpdateLocation(error) {
  return {
    type: ERROR_UPDATE_LOCATION,
    error: error
  };
}

export function resetUpdateLocation() {
  return {
    type: RESET_UPDATE_LOCATION
  };
}

export function updateLocation(location) {
  return {
    type: UPDATE_LOCATION,
    location
  };
}

export function saveUpdateLocation() {
  return (dispatch, getState) => {
    dispatch(requestUpdateLocation());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let location = denormalizeDato(getState().locations.update.activo, store);

    return locationsApi
      .saveUpdate(location)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateLocation(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateLocation(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateLocations(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdateLocations(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateLocation(JSON.parse(error.message)));
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdateLocation(error.data));
                })
                .catch(() => {
                  dispatch(errorUpdateLocation(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

export function deleteUpdateLocation(location) {
  return {
    type: DELETE_UPDATE_LOCATION,
    location
  };
}

//UPDATE LOCATIONS
export const REQUEST_UPDATE_LOCATIONS = 'REQUEST_UPDATE_LOCATIONS';
export const SUCCESS_UPDATE_LOCATIONS = 'SUCCESS_UPDATE_LOCATIONS';
export const ERROR_UPDATE_LOCATIONS = 'ERROR_UPDATE_LOCATIONS';
export const RESET_UPDATE_LOCATIONS = 'RESET_UPDATE_LOCATIONS';

function requestUpdateLocations() {
  return {
    type: REQUEST_UPDATE_LOCATIONS
  };
}

function receiveUpdateLocations(locations) {
  return {
    type: SUCCESS_UPDATE_LOCATIONS,
    receivedAt: Date.now(),
    locations: normalizeDatos(locations)
  };
}

function errorUpdateLocations(error) {
  return {
    type: ERROR_UPDATE_LOCATIONS,
    error: error
  };
}

export function resetUpdateLocations() {
  return {
    type: RESET_UPDATE_LOCATIONS
  };
}

export function saveUpdateLocations() {
  return (dispatch, getState) => {
    dispatch(requestUpdateLocations());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let locations = getState().locations.update.activos.map(idLocation => {
      return denormalizeDato(
        getState().locations.update.activo[idLocation],
        store
      );
    });

    return locationsApi
      .saveUpdateLocations(locations)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateLocations(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateLocations(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateLocations(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdateLocations(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateLocations(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorUpdateLocations(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//ALTA LOCATION
export const CREATE_LOCATION = 'CREATE_LOCATION';
export const REQUEST_CREATE_LOCATION = 'REQUEST_CREATE_LOCATION';
export const SUCCESS_CREATE_LOCATION = 'SUCCESS_CREATE_LOCATION';
export const ERROR_CREATE_LOCATION = 'ERROR_CREATE_LOCATION';
export const RESET_CREATE_LOCATION = 'RESET_CREATE_LOCATION';
export const DELETE_CREATE_LOCATION = 'DELETE_CREATE_LOCATION';

//ALTA LOCATION
function requestCreateLocation() {
  return {
    type: REQUEST_CREATE_LOCATION
  };
}

function receiveCreateLocation(location) {
  return {
    type: SUCCESS_CREATE_LOCATION,
    receivedAt: Date.now(),
    location: normalizeDato(location)
  };
}

export function errorCreateLocation(error) {
  return {
    type: ERROR_CREATE_LOCATION,
    error: error
  };
}

export function resetCreateLocation() {
  return {
    type: RESET_CREATE_LOCATION
  };
}

export function createLocation(location) {
  return {
    type: CREATE_LOCATION,
    location
  };
}

export function deleteCreateLocation(location) {
  return {
    type: DELETE_CREATE_LOCATION,
    location
  };
}

export function saveCreateLocation() {
  return (dispatch, getState) => {
    dispatch(requestCreateLocation());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let location = denormalizeDato(getState().locations.create.nuevo, store);

    return locationsApi
      .saveCreate(location)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateLocation(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateLocation(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateLocations(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreateLocations(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateLocation(JSON.parse(error.message)));
                  if (error.data) dispatch(receiveCreateLocation(error.data));
                })
                .catch(() => {
                  dispatch(errorCreateLocation(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//CREATE LOCATIONS
export const REQUEST_CREATE_LOCATIONS = 'REQUEST_CREATE_LOCATIONS';
export const SUCCESS_CREATE_LOCATIONS = 'SUCCESS_CREATE_LOCATIONS';
export const ERROR_CREATE_LOCATIONS = 'ERROR_CREATE_LOCATIONS';
export const RESET_CREATE_LOCATIONS = 'RESET_CREATE_LOCATIONS';

function requestCreateLocations() {
  return {
    type: REQUEST_CREATE_LOCATIONS
  };
}

function receiveCreateLocations(locations) {
  return {
    type: SUCCESS_CREATE_LOCATIONS,
    receivedAt: Date.now(),
    locations: normalizeDatos(locations)
  };
}

function errorCreateLocations(error) {
  return {
    type: ERROR_CREATE_LOCATIONS,
    error: error
  };
}

export function resetCreateLocations() {
  return {
    type: RESET_CREATE_LOCATIONS
  };
}

export function saveCreateLocations() {
  return (dispatch, getState) => {
    dispatch(requestCreateLocations());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let locations = getState().locations.create.nuevos.map(idLocation => {
      return denormalizeDato(
        getState().locations.create.nuevo[idLocation],
        store
      );
    });

    return locationsApi
      .saveCreateLocations(locations)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateLocations(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateLocations(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateLocations(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreateLocations(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateLocations(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorCreateLocations(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//DELETE LOCATION
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const REQUEST_DELETE_LOCATION = 'REQUEST_DELETE_LOCATION';
export const SUCCESS_DELETE_LOCATION = 'SUCCESS_DELETE_LOCATION';
export const ERROR_DELETE_LOCATION = 'ERROR_DELETE_LOCATION';
export const RESET_DELETE_LOCATION = 'RESET_DELETE_LOCATION';

function requestDeleteLocation() {
  return {
    type: REQUEST_DELETE_LOCATION
  };
}

function receiveDeleteLocation(location) {
  return {
    type: SUCCESS_DELETE_LOCATION,
    receivedAt: Date.now(),
    location: normalizeDato(location)
  };
}

function errorDeleteLocation(error) {
  return {
    type: ERROR_DELETE_LOCATION,
    error: error
  };
}

export function resetDeleteLocation(error) {
  return {
    type: RESET_DELETE_LOCATION,
    error: error
  };
}

export function deleteLocation(location) {
  return {
    type: DELETE_LOCATION,
    location
  };
}

export function saveDeleteLocation(location) {
  return dispatch => {
    dispatch(requestDeleteLocation());
    return locationsApi
      .saveDelete(location)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeleteLocation());
        dispatch(receiveDeleteLocation(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorDeleteLocation(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeleteLocation(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorDeleteLocation(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorDeleteLocation(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorDeleteLocation(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT LOCATION
export const PRINT_LOCATION = 'PRINT_LOCATION';
export const REQUEST_PRINT_LOCATION = 'REQUEST_PRINT_LOCATION';
export const SUCCESS_PRINT_LOCATION = 'SUCCESS_PRINT_LOCATION';
export const ERROR_PRINT_LOCATION = 'ERROR_PRINT_LOCATION';
export const RESET_PRINT_LOCATION = 'RESET_PRINT_LOCATION';
export const DELETE_PRINT_LOCATION = 'DELETE_PRINT_LOCATION';

function requestPrintLocation() {
  return {
    type: REQUEST_PRINT_LOCATION
  };
}

function receivePrintLocation(turnos) {
  return {
    type: SUCCESS_PRINT_LOCATION,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintLocation(error) {
  return {
    type: ERROR_PRINT_LOCATION,
    error: error
  };
}

export function resetPrintLocation() {
  return {
    type: RESET_PRINT_LOCATION
  };
}

export function printLocation(location) {
  return {
    type: PRINT_LOCATION,
    location
  };
}

export function deletePrintLocation(location) {
  return {
    type: DELETE_PRINT_LOCATION,
    location
  };
}

export function savePrintLocation(idLocation) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintLocation());
    return locationsApi
      .printLocation(idLocation)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintLocation(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintLocation(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintLocation(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintLocation(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintLocation(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT LOCATIONS
export const REQUEST_PRINT_LOCATIONS = 'REQUEST_PRINT_LOCATIONS';
export const SUCCESS_PRINT_LOCATIONS = 'SUCCESS_PRINT_LOCATIONS';
export const ERROR_PRINT_LOCATIONS = 'ERROR_PRINT_LOCATIONS';
export const RESET_PRINT_LOCATIONS = 'RESET_PRINT_LOCATIONS';

function requestPrintLocations() {
  return {
    type: REQUEST_PRINT_LOCATIONS
  };
}

function receivePrintLocations(locations) {
  return {
    type: SUCCESS_PRINT_LOCATIONS,
    receivedAt: Date.now(),
    locations: normalizeDatos(locations)
  };
}

function errorPrintLocations(error) {
  return {
    type: ERROR_PRINT_LOCATIONS,
    error: error
  };
}

export function resetPrintLocations() {
  return {
    type: RESET_PRINT_LOCATIONS
  };
}

export function savePrintLocations() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintLocation());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let locations = getState().locations.print.printers.map(idLocation => {
      return denormalizeDato(
        getState().locations.print.print[idLocation],
        store
      );
    });
    return locationsApi
      .printLocations(locations)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintLocations(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintLocations(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintLocations(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintLocations(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintLocations(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}
