import Avatar from '@material-ui/core/Avatar';
import PersonIcon from '@material-ui/icons/Person';
import { Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React from 'react';
import PropertyItemList from '../Property/PropertyItemList';

const useStyles = makeStyles(theme => ({
  pink: {
    color: 'white',
    backgroundColor: theme.palette.primary.light
  },
  box: {
    border: 'solid 1px rgba(189, 189, 189, 0.4)',
    padding: '10px',
    borderRadius: '15px'
  }
}));

export default function TicketActivityItem(props) {
  const classes = useStyles();
  const { activity, property, position } = props;
  console.log(position);

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection={position === 'right' ? 'row-reverse' : 'row'}
      width={'100%'}
      gridColumnGap={'30px'}
    >
      <Avatar className={classes.pink}>
        <PersonIcon />
      </Avatar>
      <Box
        display="flex"
        alignItems="flex-start"
        flexDirection={'column'}
        className={classes.box}
      >
        <Typography
          variant="body1"
          style={{
            fontWeight: 600,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}
        >
          {activity?.type}
        </Typography>
        <Typography
          variant="caption"
          style={{
            fontWeight: 600,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}
        >
          {(activity?.user_name ? activity?.user_name : '') +
            ' - ' +
            (activity?.created_at
              ? moment(activity?.created_at).fromNow()
              : '')}
        </Typography>
        <Typography
          variant="body2"
          style={{
            fontWeight: 600,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
          }}
        >
          {activity?.text}
        </Typography>
        {activity?.visit_date && (
          <Typography variant="body2" component="p">
            {activity?.type === 'Cita'
              ? 'Fecha de visita:'
              : 'Fecha estimada visita:'}{' '}
            <strong>
              {activity?.visit_date
                ? moment(activity?.visit_date).format('DD-MM-YYYY')
                : ''}
            </strong>
          </Typography>
        )}
        {activity?.visit_time && (
          <Typography variant="body2" component="p">
            Hora:{' '}
            <strong>{`${moment(activity.visit_time, 'HH:mm:ss').format(
              'HH:mm'
            )} - ${
              activity.visit_time_to
                ? moment(activity.visit_time_to, 'HH:mm:ss').format('HH:mm')
                : ''
            }`}</strong>
          </Typography>
        )}
        {activity?.property_id && property && (
          <PropertyItemList
            property={property}
            style={{ maxWidth: '450px' }}
            attributes={false}
          />
        )}
      </Box>
    </Box>
  );
}
