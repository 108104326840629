import React from 'react';
import { Tab, Tabs } from '@material-ui/core';
import ActivityModal from './ActivityModal/ActivityModal';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import CommentIcon from '@material-ui/icons/Comment';
import EventNoteIcon from '@material-ui/icons/EventNote';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import clone from 'lodash/clone';
import { useDispatch, useSelector } from 'react-redux';
import { updateTicketActivity } from '../../actions/TicketActivityActions';

export default function AddTicketActivity(props) {
  const { user, disabled } = props;
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [idTicketActivity, setIdTicketActivity] = React.useState(null);
  const [type, setType] = React.useState(null);

  const ticketActivities = useSelector(state => state.ticketActivities);

  const handleClickOpenModalTicketActivity = (type, idTicketActivity_) => {
    if (!idTicketActivity_) {
      setIdTicketActivity(Date.now() + Math.random() + '-');
      setType(type);
    } else {
      setIdTicketActivity(idTicketActivity_);
      if (ticketActivities.update.activos.indexOf(idTicketActivity_) === -1) {
        let attributeUpdate = clone(
          ticketActivities.byId.ticketActivities[idTicketActivity_]
        );
        let cambio = {};
        cambio[idTicketActivity_] = attributeUpdate;
        dispatch(updateTicketActivity(cambio));
      }
    }
    setOpen(true);
  };

  return (
    <>
      <Tabs
        // value={value}
        // onChange={handleChange}
        variant="fullWidth"
        indicatorColor="secondary"
        textColor="secondary"
        aria-label="icon label tabs example"
        disabled={disabled}
      >
        <Tab
          onClick={() => handleClickOpenModalTicketActivity('Nota', null)}
          icon={<CommentIcon />}
          label="Notas"
        />
        <Tab
          icon={<EventNoteIcon />}
          label="Programar cita"
          onClick={() => handleClickOpenModalTicketActivity('Cita', null)}
        />
        <Tab
          icon={<MailOutlineIcon />}
          label="Correo"
          onClick={() => handleClickOpenModalTicketActivity('Email', null)}
        />
        <Tab
          icon={<WhatsAppIcon />}
          label="WhatsApp"
          onClick={() => handleClickOpenModalTicketActivity('WhatsApp', null)}
        />
      </Tabs>
      <ActivityModal
        open={open}
        setOpen={setOpen}
        idTicketActivity={idTicketActivity}
        type={type}
        user={user}
      />
    </>
  );
}
