import { normalize, schema, denormalize } from 'normalizr';

const analytic = new schema.Entity('analytics', {}, { idAttribute: 'id' });

export function normalizeDatos(myData) {
  const mySchema = { analytics: [analytic] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = analytic;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = analytic;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default analytic;
