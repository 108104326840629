import Grid from '@material-ui/core/Grid';
import { MenuItem, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

export default function PropertyType(props) {
  const { property, handleChangeProperty, disabled, validador } = props;

  //Store
  const propertyTypes = useSelector(state => state.propertyTypes);

  return (
    <Grid container spacing={2}>
      <Grid item md={12} sm={12} xs={12}>
        <Typography gutterBottom variant="body1">
          ¿Cuál es el tipo de propiedad?
        </Typography>
        <TextField
          id={'idPropertyType'}
          select
          fullWidth
          label={'Tipo propiedad'}
          value={
            property && property.idPropertyType ? property.idPropertyType : ''
          }
          onChange={e => handleChangeProperty(e)}
          InputProps={{
            id: 'idPropertyType',
            name: 'idPropertyType'
          }}
          disabled={disabled}
          margin={'normal'}
          variant="outlined"
          onBlur={validador.validar}
          error={!validador.isValidById('idPropertyType')}
          // helperText={validador.getHelperTextById("idTypeFile")}
        >
          {propertyTypes.allIds.map(idPropertyType => {
            let typeFile = propertyTypes.byId.propertyTypes[idPropertyType];
            return (
              <MenuItem key={idPropertyType} value={idPropertyType}>
                {typeFile.nameType}
              </MenuItem>
            );
          })}
        </TextField>

        {property &&
          property.idPropertyType &&
          propertyTypes.byId.propertyTypes[property.idPropertyType] &&
          propertyTypes.byId.propertyTypes[property.idPropertyType]
            .children_type &&
          propertyTypes.byId.propertyTypes[property.idPropertyType]
            .children_type.length > 0 && (
            <TextField
              id={'idPropertySubType'}
              select
              fullWidth
              label={'Subtipo propiedad'}
              value={
                property && property.idPropertySubType
                  ? property.idPropertySubType
                  : ''
              }
              onChange={e => handleChangeProperty(e)}
              InputProps={{
                id: 'idPropertySubType',
                name: 'idPropertySubType'
              }}
              disabled={disabled}
              margin={'normal'}
              variant="outlined"
              onBlur={validador.validar}
              error={!validador.isValidById('idPropertySubType')}
              // helperText={validador.getHelperTextById("idTypeFile")}
            >
              {propertyTypes.byId.propertyTypes[
                property.idPropertyType
              ].children_type.map(idPropertyType => {
                let typeFile = propertyTypes.byId.propertyTypes[idPropertyType];
                return (
                  <MenuItem key={idPropertyType} value={idPropertyType}>
                    {typeFile.nameType}
                  </MenuItem>
                );
              })}
            </TextField>
          )}
      </Grid>
    </Grid>
  );
}
