import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
//Components
import ContactsList from './ContactsList';

export default function Contacts() {
  const dispatch = useDispatch();
  //Effects
  useEffect(() => {
    return function cleanup() {};
  }, []);

  return (
    <Switch>
      <Route path="/contacts" component={ContactsList} />
    </Switch>
  );
}
