import React from 'react';
import { Chip, FormControl, FormGroup, FormLabel } from '@material-ui/core';
import Box from '@material-ui/core/Box';

export default function BedsFilter(props) {
  const { query, setQuery } = props;
  const _beds = query.beds;

  const handleChange = value => {
    let _query = Object.assign({}, query);
    if (!_query.beds) _query.beds = [];
    if (typeof _query.beds === 'string') _query.beds = [_query.beds];
    if (_query.beds.indexOf(value.toString()) === -1)
      _query.beds.push(value.toString());
    else _query.beds.splice(_query.beds.indexOf(value.toString()), 1);
    setQuery(_query);
  };

  return (
    <FormControl component="fieldset" variant="standard">
      <FormLabel component="legend" className={'formLabel'}>
        Dormitorios
      </FormLabel>
      <FormGroup
        style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}
      >
        <Box display={'flex'} gridRowGap={'10px'} gridColumnGap={'10px'}>
          {['Monoambiente', 1, 2, 3, '+4'].map((value, i) => {
            return (
              <Chip
                key={'beds' + i}
                label={value}
                onClick={() => handleChange(i)}
                color={
                  _beds && _beds.indexOf(i.toString()) !== -1
                    ? 'primary'
                    : 'default'
                }
              />
            );
          })}
        </Box>
      </FormGroup>
    </FormControl>
  );
}
