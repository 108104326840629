import {
  resetPropertiesAttributesValues,
  resetCreatePropertyAttributeValue,
  resetUpdatePropertyAttributeValue,
  resetDeletePropertyAttributeValue
} from '../actions/PropertyAttributeValueActions';
import {
  resetProperties,
  resetCreateProperty,
  resetUpdateProperty,
  resetDeleteProperty
} from '../actions/PropertyActions';
import {
  resetTickets,
  resetCreateTicket,
  resetUpdateTicket,
  resetDeleteTicket
} from '../actions/TicketActions';

var resetPropertyAttributeValue = {
  resetAll(dispatch) {
    dispatch(resetPropertiesAttributesValues());
    dispatch(resetCreatePropertyAttributeValue());
    dispatch(resetUpdatePropertyAttributeValue());
    dispatch(resetDeletePropertyAttributeValue());
    dispatch(resetProperties());
    dispatch(resetCreateProperty());
    dispatch(resetUpdateProperty());
    dispatch(resetDeleteProperty());
    dispatch(resetTickets());
    dispatch(resetCreateTicket());
    dispatch(resetUpdateTicket());
    dispatch(resetDeleteTicket());
  }
};

export default resetPropertyAttributeValue;
