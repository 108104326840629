import jwt_decode from 'jwt-decode';

var tenantUtil = {
  setTenant(id) {
    return (localStorage.tenant_id = id);
  },
  getTenant() {
    return localStorage.tenant_id;
  }
};

export default tenantUtil;
