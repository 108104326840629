import React from 'react';
import {
  Dialog,
  DialogTitle,
  ListItem,
  ListItemAvatar
} from '@material-ui/core';
import List from '@material-ui/core/List';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import LinkIcon from '@material-ui/icons/Link';

function ModalTypeAsset(props) {
  const { open, setOpen, handleClick } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">
        ¿Qué tipo de recurso desea agregar?
      </DialogTitle>
      <List>
        <ListItem button onClick={() => handleClick('file')}>
          <ListItemAvatar>
            <Avatar>
              <InsertDriveFileIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={'Archivo'} />
        </ListItem>

        <ListItem autoFocus button onClick={() => handleClick('url')}>
          <ListItemAvatar>
            <Avatar>
              <LinkIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="URL" />
        </ListItem>
      </List>
    </Dialog>
  );
}

ModalTypeAsset.propTypes = {};

export default ModalTypeAsset;
