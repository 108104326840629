import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../../assets/css/PostLanguage.css';
//Components
import {
  AppBar,
  Box,
  Dialog,
  DialogContent,
  Slide,
  TextField,
  Typography,
  useTheme
} from '@material-ui/core';
import HTMLEditor from '../../library/HTMLEditor';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
//Icons
import clsx from 'clsx';
import clone from 'lodash/clone';

import { useDispatch, useSelector } from 'react-redux';
//Img

const styles = makeStyles(theme => ({
  form: {
    padding: theme.spacing(2, 0)
  },
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  btbLang: {
    minWidth: '180px'
  },
  selected: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      color: theme.palette.primary.main
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PostLanguage(props) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = styles();
  const viewport = useRef();
  const { changePost, handleChangePost, post } = props;
  //Hooks
  //State
  const [open, setOpen] = React.useState(false);

  const posts = useSelector(state => state.posts);

  let disabled =
    props.disabled || posts.create.isCreating || posts.update.isUpdating;

  useEffect(() => {
    setTimeout(() => {
      if (viewport.current)
        viewport.current.addEventListener('scroll', handleScroll, false);
    }, 500);

    return function cleanup() {
      if (viewport.current)
        viewport.current.removeEventListener('scroll', handleScroll);
    };
  }, [open]);

  const handleScroll = e => {
    var navbar = document.getElementsByClassName('ql-toolbar ql-snow');
    var sticky = 205;
    if (sticky && navbar[0])
      if (viewport.current.scrollTop >= sticky) {
        navbar[0].classList.add('sticky');
      } else {
        navbar[0].classList.remove('sticky');
      }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //Post language

  const handleChangeTextEditorPostLanguage = (
    content,
    delta,
    source,
    editor,
    idField,
    idPost,
    idLanguage
  ) => {
    if (source === 'user') {
      let id = idPost ? idPost : Date.now() + Math.random() + '-';
      let cambio = {};
      cambio[idField] = content;
      changePost(cambio);
    }
  };

  return (
    <>
      <Box
        display={'flex'}
        alignItems={'center'}
        alignContent={'center'}
        style={{ marginTop: '50px' }}
        justifyContent={'space-between'}
      >
        <Button
          className={clsx(classes.btbLang, {
            [classes.selected]: !!post
          })}
          onClick={handleClickOpen}
          disabled={disabled}
          fullwidth
        >
          Escribir publicación
        </Button>
      </Box>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className={'postLanguage'}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Español
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Aceptar
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent id="dialogCon" ref={viewport}>
          <Grid container style={{ padding: '25px 25px 25px' }}>
            <TextField
              margin={'normal'}
              id="titlePost"
              placeholder="Título"
              variant="outlined"
              label={'Título'}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                disabled: disabled
              }}
              fullWidth
              value={post ? post.titlePost : ''}
              onChange={handleChangePost}
              // onKeyDown={(e) => handleKeyDown(e)}
            />
            <TextField
              margin={'normal'}
              id="subtitlePost"
              placeholder="Subtítulo"
              variant="outlined"
              label={'Subtítulo'}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                disabled: disabled
              }}
              fullWidth
              value={post ? post.subtitlePost : ''}
              onChange={handleChangePost}
              // onKeyDown={(e) => handleKeyDown(e)}
            />
            <HTMLEditor
              textLabel={'Escribe el post'}
              disabled={disabled}
              onChange={(content, delta, source, editor) =>
                handleChangeTextEditorPostLanguage(
                  content,
                  delta,
                  source,
                  editor,
                  'post'
                )
              }
              value={post ? post.post : ''}
              rows={'100%'}
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

PostLanguage.propTypes = {};
