import PaperHeader from '../../library/CustomPaper/PaperHeader';
import { Typography } from '@material-ui/core';
import React, { useRef } from 'react';
import PaperBody from '../../library/CustomPaper/PaperBody';
import UserAutocomplete from '../Users/UserAutocomplete';
import CustomPaper from '../../library/CustomPaper/CustomPaper';

export default function PropertyOwner(props) {
  const { property, disabled, changeProperty } = props;
  const msjErrorCellphone = useRef();

  return (
    <CustomPaper>
      <PaperHeader>
        <Typography variant={'overline'} component={'span'}>
          Datos de propietario
        </Typography>
      </PaperHeader>
      <PaperBody>
        <UserAutocomplete
          user_id={property?.idOwner}
          changeTicket={changeProperty}
          attribute_rel={'owner'}
          attribute_id={'idOwner'}
          description={'Propietario'}
          add_option={true}
          disabled={disabled}
          // validador={validador}
          // validar={validar}
          msjErrorCellphone={msjErrorCellphone}
        />
      </PaperBody>
    </CustomPaper>
  );
}
