import clone from 'lodash/clone';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';

export function useValidador(reglas) {
  class Validador {
    constructor(reglas) {
      this.validaciones = {};
      this.reglas = reglas;
    }

    /**
     * Obtener objeto de validaciones
     * @returns {{}}
     */
    getValidaciones() {
      return this.validaciones;
    }

    /**
     * Verificar que todas las reglas se cumplan
     * Se usa para el submit
     * @returns {boolean}
     */
    isValid() {
      let error = false;
      Object.keys(this.reglas).forEach(id => {
        this.validarById(id);
        if (!this.validaciones[id] || this.validaciones[id] !== true) {
          if (!error) {
            if (document.getElementById(id))
              document.getElementById(id).focus();
          }
          error = true;
        }
      });
      console.log(this.validaciones);
      return !error;
    }

    /**
     * Verificar reglas por id
     * @param id
     * @returns {boolean}
     */
    isValidById(id) {
      return !(this.validaciones[id] && this.validaciones[id] !== true);
    }

    /**
     * Devuelve el error
     * @param id
     * @returns {string|*}
     */
    getErrorById(id) {
      if (this.isValidById(id)) return '';
      else return this.validaciones[id];
    }

    /**
     * Devuelve texto de ayuda
     * @param id
     * @param helperText
     * @returns {string|*}
     */
    getHelperTextById(id, helperText) {
      if (this.isValidById(id)) return helperText ? helperText : '';
      else return this.getErrorById(id);
    }

    /**
     * Calcula validez de un campo por id
     * @param id
     */
    validarById(id) {
      let validacion = reglas[id];
      let error = '';
      let campo = document.querySelector(
        'input#' + id + ', textarea#' + id + ', select#' + id
      );
      console.log(campo);
      if (validacion)
        validacion.forEach(itemValidacion => {
          switch (itemValidacion) {
            case 'required':
              if (!campo || campo.value == '' || campo.value == null)
                error += 'El campo es requerido.';
              break;
            case 'required|notDisabled':
              if (
                campo &&
                (campo.value == '' || campo.value == null) &&
                campo.disabled == false &&
                (campo.getAttribute('aria-disabled') === null ||
                  campo.getAttribute('aria-disabled') == 'false')
              )
                error += 'El campo es requerido.';
              break;
            case 'sometimes|required':
              if (campo && (campo.value == '' || campo.value == null))
                error += 'El campo es requerido.';
              break;
            default:
              break;
          }
        });
      if (isEmpty(error)) error = true;
      let validaciones = clone(validador);
      validaciones.getValidaciones()[id] = error;
      setValidador(validaciones);
    }

    validar(e) {
      let id = e.target.id ? e.target.id : e.target.name;
      let validacion = reglas[id];
      let error = '';
      if (validacion)
        validacion.forEach(itemValidacion => {
          switch (itemValidacion) {
            case 'required':
              if (
                e.target.value === '' ||
                e.target.value === null ||
                !e.target.value
              )
                error += 'El campo es requerido.';
              break;
            case 'required|notDisabled':
              if (
                e.target &&
                (e.target.value == '' || e.target.value == null) &&
                e.target.disabled == false
              )
                error += 'El campo es requerido.';
              break;
            case 'sometimes|required':
              if (
                e.target.value === '' ||
                e.target.value === null ||
                !e.target.value
              )
                error += 'El campo es requerido.';
              break;
            default:
              break;
          }
        });
      if (isEmpty(error)) error = true;
      let validaciones = clone(validador);
      validaciones.getValidaciones()[id] = error;
      setValidador(validaciones);
    }
  }

  //State
  const [validador, setValidador] = useState(new Validador(reglas));

  /**
   * Calcula validez de un campo ante un evento
   * @param e
   */
  const validar = e => {
    let id = e.target.id ? e.target.id : e.target.name;
    let validacion = reglas[id];
    let error = '';
    if (validacion)
      validacion.forEach(itemValidacion => {
        switch (itemValidacion) {
          case 'required':
            if (
              e.target.value === '' ||
              e.target.value === null ||
              !e.target.value
            )
              error += 'El campo es requerido.';
            break;
          case 'sometimes|required':
            if (
              e.target.value === '' ||
              e.target.value === null ||
              !e.target.value
            )
              error += 'El campo es requerido.';
            break;
          case 'required|notDisabled':
            if (
              e.target &&
              (e.target.value == '' || e.target.value == null) &&
              e.target.disabled == false
            )
              error += 'El campo es requerido.';
            break;
          default:
            break;
        }
      });
    if (isEmpty(error)) error = true;
    let validaciones = clone(validador);
    validaciones.getValidaciones()[id] = error;
    setValidador(validaciones);
  };

  return [validador, validar];
}
