import { normalize, schema, denormalize } from 'normalizr';

const typeFile = new schema.Entity('typesFiles', {}, { idAttribute: 'id' });

export function normalizeDatos(myData) {
  const mySchema = { typesFiles: [typeFile] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = typeFile;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = typeFile;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default typeFile;
