import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useValidador } from '../../utils/validador';
import moment from 'moment/moment';
import { DatePicker } from '@material-ui/pickers';
import TicketPropertySelector from '../TicketProperty/TicketPropertySelector';
import { FormControl, FormLabel, MenuItem } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import UserSelector from '../Property/UserSelector';
import Box from '@material-ui/core/Box';

export default function PropertyClosedOperationModalForm(props) {
  const {
    propertyClosedOperation,
    handleClose,
    handleChangePropertyClosedOperation,
    handleDateChangePropertyClosedOperation,
    handlePropertyTypeChangePropertyClosedOperation,
    open
  } = props;
  //Hooks
  let reglas = {};
  reglas = {
    motive: ['sometimes|required'],
    consultant_id: ['sometimes|required']
  };
  const [validador, validar] = useValidador(reglas);
  const currencies = useSelector(state => state.currencies);

  function handleAccept() {
    if (validador.isValid()) {
      props.handleAccept();
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Cambiar estado de propiedad
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Registraremos los datos de la operación exitosa
          </DialogContentText>
          <TextField
            autoFocus
            id="motive"
            select
            label="Motivo"
            fullWidth
            value={
              propertyClosedOperation?.motive
                ? propertyClosedOperation.motive
                : ''
            }
            onChange={handleChangePropertyClosedOperation}
            inputProps={{ id: 'motive', name: 'motive' }}
            margin={'normal'}
            onBlur={validar}
            error={!validador.isValidById('motive')}
            helperText={validador.getHelperTextById('motive')}
          >
            <MenuItem key={'sold'} value={'sold'}>
              Realizamos la operación exitosamente
            </MenuItem>
            <MenuItem key={'sold_with_cl'} value={'sold_with_cl'}>
              Realizamos la operación exitosamente en colaboración con un colega
            </MenuItem>
            <MenuItem key={'retired'} value={'retired'}>
              El cliente ya no quiere realizar la operación
            </MenuItem>
            <MenuItem key={'other_sold'} value={'other_sold'}>
              La operación fue realizada por terceros
            </MenuItem>
            <MenuItem key={'blocked'} value={'blocked'}>
              La propiedad fue bloqueada por el desarrollador
            </MenuItem>
          </TextField>
          {propertyClosedOperation?.motive &&
            (propertyClosedOperation?.motive === 'sold' ||
              propertyClosedOperation?.motive === 'sold_with_cl') && (
              <>
                {/*//TODO ver usuarios*/}
                <UserSelector
                  model={propertyClosedOperation}
                  id={'consultant_id'}
                  description={'Asesor asignado'}
                  handleChangeModel={handleChangePropertyClosedOperation}
                  fullwidth={true}
                  margin={'normal'}
                />
                <DatePicker
                  id={'date'}
                  autoOk
                  clearable
                  label="Fecha de operación"
                  margin={'normal'}
                  variant="outlined"
                  disablePast
                  value={
                    propertyClosedOperation && propertyClosedOperation.date
                      ? moment(propertyClosedOperation.date)
                      : null
                  }
                  fullWidth
                  onChange={e =>
                    handleDateChangePropertyClosedOperation(e, 'date')
                  }
                  inputProps={{ id: 'date', name: 'date' }}
                  format="DD/MM/yyyy"
                  views={['year', 'month', 'date']}
                  onBlur={validar}
                  error={!validador.isValidById('date')}
                  helperText={validador.getHelperTextById('date')}
                  // disabled={moment(asset.dateTo).isBefore(moment())}
                />
                <FormControl
                  component="fieldset"
                  variant="standard"
                  className={'operationType'}
                  style={{ marginTop: '15px', marginBottom: '15px' }}
                >
                  <FormLabel
                    component="legend"
                    className={'formLabel'}
                    style={{ marginBottom: '10px' }}
                  >
                    Tipo de operación
                  </FormLabel>
                  <ToggleButtonGroup
                    color="primary"
                    value={
                      propertyClosedOperation && propertyClosedOperation.codType
                    }
                    exclusive
                    onChange={handlePropertyTypeChangePropertyClosedOperation}
                    variant={'filled'}
                  >
                    <ToggleButton value="V" fullWidth>
                      Venta
                    </ToggleButton>
                    <ToggleButton value="A" fullWidth>
                      Alquiler
                    </ToggleButton>
                    <ToggleButton value="AT" fullWidth>
                      Alquiler temporal
                    </ToggleButton>
                  </ToggleButtonGroup>
                </FormControl>
                <Box display={'flex'} gridColumnGap={'20px'}>
                  <TextField
                    style={{ width: '20%' }}
                    id="codCurrency"
                    select
                    label="Moneda"
                    margin={'normal'}
                    fullWidth
                    value={
                      propertyClosedOperation?.codCurrency
                        ? propertyClosedOperation.codCurrency
                        : ''
                    }
                    onChange={handleChangePropertyClosedOperation}
                    inputProps={{ id: 'codCurrency', name: 'codCurrency' }}
                    // helperText="Please select your currency"
                  >
                    {currencies &&
                      currencies.allIds &&
                      currencies.allIds.map(id => {
                        let currency = currencies.byId.currencies[id];
                        return (
                          <MenuItem key={currency.simbol} value={currency.cod}>
                            {currency.simbol}
                          </MenuItem>
                        );
                      })}
                  </TextField>
                  <TextField
                    style={{ width: '80%' }}
                    id="price"
                    fullWidth
                    label="Precio"
                    margin={'normal'}
                    value={
                      propertyClosedOperation?.price
                        ? propertyClosedOperation.price
                        : ''
                    }
                    onChange={handleChangePropertyClosedOperation}
                    // helperText="Please select your currency"
                  />
                </Box>
                {propertyClosedOperation?.codType !== 'V' && (
                  <Box display={'flex'} gridColumnGap={'20px'}>
                    <DatePicker
                      style={{ width: '50%' }}
                      id={'from'}
                      autoOk
                      clearable
                      label="Fecha inicio alquiler"
                      margin={'normal'}
                      variant="outlined"
                      disablePast
                      value={
                        propertyClosedOperation && propertyClosedOperation.from
                          ? moment(propertyClosedOperation.from)
                          : null
                      }
                      fullWidth
                      onChange={e =>
                        handleDateChangePropertyClosedOperation(e, 'from')
                      }
                      format="DD/MM/yyyy"
                      views={['year', 'month', 'date']}
                      // disabled={moment(asset.dateTo).isBefore(moment())}
                    />
                    <DatePicker
                      id={'to'}
                      style={{ width: '50%' }}
                      autoOk
                      clearable
                      label="Fecha fin alquiler"
                      margin={'normal'}
                      variant="outlined"
                      disablePast
                      value={
                        propertyClosedOperation && propertyClosedOperation.to
                          ? moment(propertyClosedOperation.to)
                          : null
                      }
                      fullWidth
                      onChange={e =>
                        handleDateChangePropertyClosedOperation(e, 'to')
                      }
                      format="DD/MM/yyyy"
                      views={['year', 'month', 'date']}
                      // disabled={moment(asset.dateTo).isBefore(moment())}
                    />
                  </Box>
                )}
              </>
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleAccept} color="primary">
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
