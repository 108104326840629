import { normalize, schema, denormalize } from 'normalizr';

const propertyOperation = new schema.Entity(
  'propertyOperations',
  {},
  { idAttribute: 'id' }
);

export function normalizeDatos(myData) {
  const mySchema = { propertyOperations: [propertyOperation] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = propertyOperation;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = propertyOperation;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default propertyOperation;
