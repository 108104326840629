import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useParams } from 'react-router-dom';
import clone from 'lodash/clone';
//Components
import SwipeableViews from 'react-swipeable-views';
import {
  Box,
  Breadcrumbs,
  Container,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core';
import CustomPaper from '../../library/CustomPaper/CustomPaper';
import PaperHeader from '../../library/CustomPaper/PaperHeader';
import ContainerBtn from '../../library/Buttons/ContainerBtn';
import BtnSave from '../../library/Buttons/BtnSave';
import BtnCancel from '../../library/Buttons/BtnCancel';
import Grid from '@material-ui/core/Grid';
import PaperBody from '../../library/CustomPaper/PaperBody';
import PropertyAddress from './PropertyAddress';
import PropertyBasicAttributes from './PropertyBasicAttributes';
import PropertyAssets from './PropertyAssets';
import PropertyVideoAssets from './PropertyVideoAssets';
import PropertyTour360Assets from './PropertyTour360Assets';
import PropertyOperations from './PropertyOperations';
import Publication from './Publication';
import PropertyAdicionalAttributes from './PropertyAdicionalAttributes';
//Hooks
import { useValidador } from '../../utils/validador';
import { useDispatch, useSelector } from 'react-redux';
//Actions
import {
  createProperty,
  fetchProperties,
  fetchProperty,
  saveCreateProperty,
  saveUpdateProperty,
  updateProperty
} from '../../actions/PropertyActions';
import { fetchOperationTypesIfNeeded } from '../../actions/OperationTypeActions';
import { fetchPropertyTypesIfNeeded } from '../../actions/PropertyTypeActions';
import { fetchCurrenciesIfNeeded } from '../../actions/CurrencyActions';
import { fetchPeriodsIfNeeded } from '../../actions/PeriodActions';
import { fetchAttributesGroupsIfNeeded } from '../../actions/AttributeGroupActions';

//reset
import propertyReset from '../../reset/propertyReset';
import countryReset from '../../reset/countryReset';
import propertyAttributeValueReset from '../../reset/propertyAttributeValueReset';
import assetReset from '../../reset/assetReset';
import attributeReset from '../../reset/attributeReset';
import attributeGroupReset from '../../reset/attributeGroupReset';
import DevelopmentPropertiesListEmbededd from './DevelopmentPropertiesListEmbededd';
import PropertyType from './PropertyType';
import PropertyBelongsToDevelopment from './PropertyBelongsToDevelopment';
import PropertyCard from './PropertyCard';
import { createPropertyAttributeValue } from '../../actions/PropertyAttributeValueActions';
import { createAsset } from '../../actions/AssetActions';
import { useHistory } from 'react-router';
import PropertyCardSkeleton from './PropertyCardSkeleton';
import { Skeleton } from '@material-ui/lab';
import EmbeddedAssetsList from './EmbeddedAssetsList';
import EmbeddedAuthorizationAssetsList from './EmbeddedAuthorizationAssetsList';
import PropertyOwner from './PropertyOwner';

const useStyles = makeStyles(theme => ({
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(2),
    overflowX: 'auto',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-start',
      flexWrap: 'nowrap'
    }
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// TabPanel.propTypes = {
//     children: PropTypes.node,
//     index: PropTypes.any.isRequired,
//     value: PropTypes.any.isRequired,
// };

const tabs = [
  'basic',
  'properties',
  'main',
  'advertise',
  'others',
  'media',
  'private'
];

export default function CRUDProperty(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  let { idProperty, idPropertyCopyFrom } = useParams();
  const mounted = useRef();
  let history = useHistory();

  //Store
  const properties = useSelector(state => state.properties);
  const property = idProperty
    ? properties.update.activo
    : properties.create.nuevo;
  const propertyTypes = useSelector(state => state.propertyTypes);
  const propertyType =
    property && property.idPropertyType
      ? propertyTypes.byId.propertyTypes[property.idPropertyType]
      : null;
  const attributesGroups = useSelector(state => state.attributesGroups);
  const assets = useSelector(state => state.assets);
  const propertiesAttributesValues = useSelector(
    state => state.propertiesAttributesValues
  );
  const isDevelopment = propertyType && propertyType.codType === 'EM';

  //State
  const [files, setFiles] = useState([]);
  const [valueInt, setValueInt] = useState(0);
  const [value, setValue] = useState('basic');

  const guardandoLoading = idProperty
    ? properties.update.isUpdating
    : properties.create.isCreating;
  const disabled =
    (idProperty
      ? properties.update.isUpdating
      : properties.create.isCreating) || properties.byId.isFetching;
  const isFetching = properties.byId.isFetching && !property.id;
  const error = idProperty ? properties.update.error : properties.create.error;

  //Hooks
  let reglas = {
    // state: ["required"],
    // city: ["required"],
    // country: ["required"],
    // address: ["required"],
    // idPropertyType: ["required"],
  };
  const [validador, validar] = useValidador(reglas);

  //Effects
  useEffect(() => {
    window.scrollTo(0, 0);
    if (idProperty) dispatch(fetchProperty(idProperty));
    if (idPropertyCopyFrom)
      dispatch(
        fetchProperties({
          id: idPropertyCopyFrom,
          with: ['mainAsset', 'asset', 'propertyAttributeValue']
        })
      );
    dispatch(fetchOperationTypesIfNeeded());
    dispatch(
      fetchPropertyTypesIfNeeded({ with: ['childrenType'], hierarchy: 0 })
    );
    dispatch(fetchCurrenciesIfNeeded());
    dispatch(fetchPeriodsIfNeeded());
    dispatch(
      fetchAttributesGroupsIfNeeded({ with: ['attribute.attributeValue'] })
    );
    setFiles([]);
    setValue('basic');
    setValueInt(0);

    return function cleanup() {
      propertyReset.resetAll(dispatch);
      countryReset.resetAll(dispatch);
      propertyAttributeValueReset.resetAll(dispatch);
      attributeReset.resetAll(dispatch);
      attributeGroupReset.resetAll(dispatch);
      assetReset.resetAll(dispatch);
    };
  }, [idProperty, idPropertyCopyFrom]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (!properties.create.isCreating && !properties.create.error) {
        let id = property.id;
        propertyReset.resetAll(dispatch);
        propertyAttributeValueReset.resetAll(dispatch);
        attributeReset.resetAll(dispatch);
        attributeGroupReset.resetAll(dispatch);
        assetReset.resetAll(dispatch);
        setFiles([]);
        window.scrollTo(0, 0);
        setValue('basic');
        setValueInt(0);
        history.push('/properties/' + id);
      }
    }
  }, [properties.create.isCreating]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (!properties.update.isUpdating && !properties.update.error) {
        setFiles([]);
        // fetchFiles();
      }
    }
  }, [properties.update.isUpdating]);

  useEffect(() => {
    if (
      idPropertyCopyFrom &&
      !properties.byId.isFetching &&
      !properties.create.nuevo.id
    ) {
      let originalProperty = clone(
        properties.byId.properties[idPropertyCopyFrom]
      );
      if (originalProperty) {
        delete originalProperty.id;
        originalProperty.idDevelopment = idPropertyCopyFrom;
        originalProperty.development = idPropertyCopyFrom;
        originalProperty.idPropertyType = null;
        originalProperty.idPropertySubType = null;
        originalProperty.externalId = null;
        originalProperty.asset = [];
        originalProperty.main_asset = [];
        originalProperty.property_attribute_value = [];
        dispatch(createProperty(originalProperty));
        let cambio = {};
        properties.byId.properties[
          idPropertyCopyFrom
        ].property_attribute_value.forEach(idPropertyAttributeValue => {
          let propertyAttributeValue = clone(
            propertiesAttributesValues.byId.propertiesAttributesValues[
              idPropertyAttributeValue
            ]
          );
          propertyAttributeValue.id = Date.now() + Math.random() + '-';
          propertyAttributeValue.idProperty = null;
          cambio[propertyAttributeValue.id] = propertyAttributeValue;
        });
        dispatch(createPropertyAttributeValue(cambio));

        let cambioAssets = {};
        properties.byId.properties[idPropertyCopyFrom].asset.forEach(
          idAsset => {
            let asset = clone(assets.byId.assets[idAsset]);
            asset.id = Date.now() + Math.random() + '-';
            asset.idProperty = null;
            cambioAssets[asset.id] = asset;
          }
        );
        dispatch(createAsset(cambioAssets));
      }
    }
  }, [properties.byId.isFetching]);

  //Property
  const handleChangeProperty = e => {
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    if (e.target.id === 'address') {
      cambio.country = null;
      cambio.state = null;
      cambio.locality = null;
      cambio.street = null;
      cambio.number = null;
      cambio.apartment = null;
      cambio.floor = null;
      cambio.neighborhood = null;
    }
    changeProperty(cambio);
  };

  const handleChangeCheckboxProperty = e => {
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] =
      !property[e.target.id ? e.target.id : e.target.name];
    changeProperty(cambio);
  };

  const changeProperty = cambio => {
    if (idProperty) dispatch(updateProperty(cambio));
    else dispatch(createProperty(cambio));
  };

  //Submit
  const onSubmit = e => {
    e.preventDefault();
    if (validador.isValid()) {
      if (idProperty) dispatch(saveUpdateProperty(files));
      else dispatch(saveCreateProperty(files));
    }
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    setValueInt(tabs.indexOf(newValue));
  };

  const handleChangeIndexTab = index => {
    setValue(tabs[index]);
    setValueInt(index);
  };

  return (
    <>
      {isFetching && (
        <Container maxWidth={'lg'}>
          <Breadcrumbs aria-label="breadcrumb" separator={'‣'}>
            <Link color="inherit" to="/">
              Home
            </Link>
            <Link color="inherit" to="/properties">
              Propiedades
            </Link>
          </Breadcrumbs>
          <Typography component={'h1'} variant={'h1'}>
            {!idProperty ? 'Nueva propiedad' : 'Modificar propiedad'}
          </Typography>
          <PropertyCardSkeleton />
          <Grid container spacing={2}>
            <Grid item md={6} sm={12} xs={12}>
              <CustomPaper>
                <PaperHeader>
                  <Skeleton
                    animation="wave"
                    height={10}
                    width="40%"
                    style={{ marginBottom: 6 }}
                  />
                </PaperHeader>
                <PaperBody spacing={2}>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={'100%'}
                    height={200}
                  />
                </PaperBody>
              </CustomPaper>
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
              <CustomPaper>
                <PaperHeader>
                  <Skeleton
                    animation="wave"
                    height={10}
                    width="40%"
                    style={{ marginBottom: 6 }}
                  />
                </PaperHeader>
                <PaperBody spacing={2}>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={'100%'}
                    height={200}
                  />
                </PaperBody>
              </CustomPaper>
            </Grid>
          </Grid>
        </Container>
      )}
      <Container
        maxWidth={'lg'}
        style={{ display: isFetching ? 'none' : 'inherit' }}
      >
        <Breadcrumbs aria-label="breadcrumb" separator={'‣'}>
          <Link color="inherit" to="/">
            Home
          </Link>
          <Link color="inherit" to="/properties">
            Propiedades
          </Link>
        </Breadcrumbs>
        <Typography component={'h1'} variant={'h1'}>
          {!idProperty ? 'Nueva propiedad' : 'Modificar propiedad'}
        </Typography>

        <form onSubmit={onSubmit} autoComplete={'daawdsadsf'}>
          <Grid container spacing={2}>
            {!!idProperty && (
              <Grid item md={12} sm={12} xs={12}>
                <CustomPaper>
                  <PaperHeader>
                    <Typography variant={'overline'} component={'span'}>
                      Datos de la propiedad
                    </Typography>
                  </PaperHeader>
                  <PaperBody spacing={2}>
                    <Grid container spacing={3}>
                      <PropertyCard
                        property={property}
                        idProperty={idProperty}
                        changeProperty={changeProperty}
                        disabled={disabled}
                        files={files}
                        setFiles={setFiles}
                        handleChangeProperty={handleChangeProperty}
                      />

                      <Grid item md={12} sm={12} xs={12}>
                        <Box
                          width={'100%'}
                          display={'flex'}
                          justifyContent={'flex-end'}
                        >
                          <Tabs
                            value={value}
                            onChange={handleChangeTab}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                          >
                            <Tab label="Datos básicos" value={'basic'} />
                            {isDevelopment && (
                              <Tab label="Propiedades" value={'properties'} />
                            )}
                            <Tab label="Carácterísticas" value={'main'} />
                            <Tab label="Aviso" value={'advertise'} />
                            <Tab label="Adicionales" value={'others'} />
                            <Tab label="Multimedia" value={'media'} />
                            <Tab label="Datos internos" value={'private'} />
                          </Tabs>
                        </Box>
                      </Grid>
                    </Grid>
                  </PaperBody>
                </CustomPaper>
              </Grid>
            )}
            {!idProperty && (
              <Grid item md={12} sm={12} xs={12} style={{ marginTop: '20px' }}>
                <CustomPaper>
                  <PaperBody spacing={2} style={{ flexGrow: 1 }}>
                    <Tabs
                      value={value}
                      onChange={handleChangeTab}
                      indicatorColor="primary"
                      textColor="primary"
                      centered
                    >
                      <Tab label="Datos básicos" value={'basic'} />
                      <Tab label="Carácterísticas principales" value={'main'} />
                      <Tab label="Aviso" value={'advertise'} />
                      <Tab
                        label="Características adicionales"
                        value={'others'}
                      />
                      <Tab label="Multimedia" value={'media'} />
                      <Tab label="Datos internos" value={'private'} />
                    </Tabs>
                  </PaperBody>
                </CustomPaper>
              </Grid>
            )}
            <Grid item md={12} sm={12} xs={12}>
              <SwipeableViews
                axis={'x'}
                index={valueInt}
                onChangeIndex={handleChangeIndexTab}
              >
                <TabPanel value={valueInt} index={0}>
                  <Grid container spacing={2}>
                    <Grid item md={6} sm={12} xs={12}>
                      <CustomPaper>
                        <PaperHeader>
                          <Typography variant={'overline'} component={'span'}>
                            ¿En dónde está ubicada la propiedad?
                          </Typography>
                        </PaperHeader>

                        <PaperBody spacing={2}>
                          <PropertyAddress
                            handleChangeProperty={handleChangeProperty}
                            changeProperty={changeProperty}
                            property={property}
                            disabled={disabled}
                            handleChangeCheckboxProperty={
                              handleChangeCheckboxProperty
                            }
                          />
                        </PaperBody>
                      </CustomPaper>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                      <Grid item md={12} sm={12} xs={12}>
                        <CustomPaper>
                          <PaperHeader>
                            <Typography variant={'overline'} component={'span'}>
                              Operaciones y tipo de propiedad
                            </Typography>
                          </PaperHeader>
                          <PaperBody spacing={2}>
                            <PropertyType
                              property={property}
                              handleChangeProperty={handleChangeProperty}
                              disabled={disabled}
                              validador={validador}
                            />
                            {!isDevelopment && (
                              <PropertyOperations
                                property={property}
                                handleChangeProperty={handleChangeProperty}
                                disabled={disabled}
                                validador={validador}
                              />
                            )}
                          </PaperBody>
                        </CustomPaper>
                      </Grid>

                      {!isDevelopment && (
                        <Grid item md={12} sm={12} xs={12}>
                          <CustomPaper>
                            <PaperHeader>
                              <Typography
                                variant={'overline'}
                                component={'span'}
                              >
                                ¿Pertenece a un emprendimiento?
                              </Typography>
                            </PaperHeader>

                            <PaperBody spacing={2}>
                              <PropertyBelongsToDevelopment
                                property={property}
                                changeProperty={changeProperty}
                                disabled={disabled}
                                validador={validador}
                              />
                            </PaperBody>
                          </CustomPaper>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </TabPanel>

                {!!isDevelopment && (
                  <TabPanel value={valueInt} index={1}>
                    <DevelopmentPropertiesListEmbededd
                      property={property}
                      disabled={disabled}
                    />
                  </TabPanel>
                )}

                <TabPanel value={valueInt} index={2}>
                  <Grid container spacing={2}>
                    {attributesGroups.allIds.map(idAttributeGroup => {
                      let attributeGroup =
                        attributesGroups.byId.attributesGroups[
                          idAttributeGroup
                        ];
                      if (
                        attributeGroup.codAttributeGroup === 'basic' ||
                        attributeGroup.codAttributeGroup === 'surfaces' ||
                        attributeGroup.codAttributeGroup === 'antiquity' ||
                        attributeGroup.codAttributeGroup === 'expenses' ||
                        attributeGroup.codAttributeGroup === 'main'
                      )
                        return (
                          <Grid
                            key={'at' + idAttributeGroup}
                            item
                            md={6}
                            sm={12}
                            xs={12}
                          >
                            <CustomPaper>
                              <PaperHeader>
                                <Typography
                                  variant={'overline'}
                                  component={'span'}
                                >
                                  {attributeGroup
                                    ? attributeGroup.nameAttributeGroup
                                    : ''}
                                </Typography>
                              </PaperHeader>
                              <PaperBody>
                                <Grid item md={12} xs={12} sm={12}>
                                  <PropertyBasicAttributes
                                    attributeGroup={attributeGroup}
                                    disabled={disabled}
                                    property={property}
                                  />
                                </Grid>
                              </PaperBody>
                            </CustomPaper>
                          </Grid>
                        );
                    })}
                  </Grid>
                </TabPanel>
                <TabPanel value={valueInt} index={3}>
                  <Grid container spacing={2}>
                    <Grid item md={12} sm={12} xs={12}>
                      <CustomPaper>
                        <PaperHeader>
                          <Typography variant={'overline'} component={'span'}>
                            Aviso
                          </Typography>
                        </PaperHeader>
                        <PaperBody>
                          <Publication
                            handleChangeProperty={handleChangeProperty}
                            changeProperty={changeProperty}
                            property={property}
                          />
                        </PaperBody>
                      </CustomPaper>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={valueInt} index={4}>
                  <Grid container spacing={2}>
                    <Grid item md={12} sm={12} xs={12}>
                      <CustomPaper>
                        <PaperHeader>
                          <Typography variant={'overline'} component={'span'}>
                            Otras características
                          </Typography>
                        </PaperHeader>
                        <PaperBody>
                          <PropertyAdicionalAttributes
                            property={property}
                            disabled={disabled}
                          />
                        </PaperBody>
                      </CustomPaper>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={valueInt} index={5}>
                  <Grid container spacing={2}>
                    <Grid item md={12} sm={12} xs={12}>
                      <CustomPaper>
                        <PaperHeader>
                          <Typography variant={'overline'} component={'span'}>
                            Contenido multimedia
                          </Typography>
                        </PaperHeader>
                        <PaperBody>
                          <Grid item md={12} sm={12} xs={12}>
                            <Box marginBottom={'30px'}>
                              <Typography variant={'h2'} component={'h2'}>
                                Fotos
                              </Typography>
                              <Typography variant={'body1'} component={'span'}>
                                Agregá las fotos de la propiedad.
                              </Typography>
                            </Box>
                            <PropertyAssets
                              setFiles={setFiles}
                              files={files}
                              changeProperty={changeProperty}
                              property={property}
                              type={'Photo'}
                              disabled={disabled}
                            />
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Box marginBottom={'30px'} marginTop={'30px'}>
                              <Typography variant={'h2'} component={'h2'}>
                                Videos
                              </Typography>
                              <Typography variant={'body1'} component={'span'}>
                                Agregá los videos de la propiedad.
                              </Typography>
                            </Box>
                            <PropertyVideoAssets
                              setFiles={setFiles}
                              files={files}
                              changeProperty={changeProperty}
                              property={property}
                              type={'Video'}
                            />
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Box marginBottom={'30px'} marginTop={'30px'}>
                              <Typography variant={'h2'} component={'h2'}>
                                Planos
                              </Typography>
                              <Typography variant={'body1'} component={'span'}>
                                Agregá los planos de la propiedad.
                              </Typography>
                            </Box>
                            <PropertyAssets
                              setFiles={setFiles}
                              files={files}
                              changeProperty={changeProperty}
                              property={property}
                              type={'Blueprint'}
                            />
                          </Grid>
                          <Grid item md={12} sm={12} xs={12}>
                            <Box marginBottom={'30px'} marginTop={'30px'}>
                              <Typography variant={'h2'} component={'h2'}>
                                Recorrido 360
                              </Typography>
                              <Typography variant={'body1'} component={'span'}>
                                Agregá los recorridos 360º de la propiedad.
                              </Typography>
                            </Box>
                            <PropertyTour360Assets
                              setFiles={setFiles}
                              files={files}
                              changeProperty={changeProperty}
                              property={property}
                              type={'Tour'}
                            />
                          </Grid>
                        </PaperBody>
                      </CustomPaper>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={valueInt} index={6}>
                  <Grid container spacing={2}>
                    <Grid item md={12} sm={12} xs={12}>
                      <PropertyOwner
                        property={property}
                        changeProperty={changeProperty}
                      />
                      <EmbeddedAuthorizationAssetsList
                        property={property}
                        changeProperty={changeProperty}
                        setFiles={setFiles}
                        files={files}
                      />
                      <EmbeddedAssetsList
                        property={property}
                        changeProperty={changeProperty}
                        setFiles={setFiles}
                        files={files}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>
              </SwipeableViews>
            </Grid>
          </Grid>
          <ContainerBtn>
            <BtnCancel
              onClick={() =>
                props.location.pathname === '/properties/new'
                  ? props.history.push('/properties')
                  : props.history.goBack()
              }
            />
            <BtnSave
              loading={guardandoLoading}
              disabled={disabled}
              error={error}
            />
          </ContainerBtn>
        </form>
      </Container>
    </>
  );
}
