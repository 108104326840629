import { Box, Chip } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import c from '../../constants/Constants';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import 'moment/locale/es';

const useStyles = makeStyles(theme => ({
  iconsContainer: {
    display: 'flex',
    gap: '5px 5px',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  }
}));

export default function PropertyAttributeChips(props) {
  const classes = useStyles();
  const { property, listAttributesGroups, size } = props;
  const attributes = useSelector(state => state.attributes);
  const attributesGroups = useSelector(state => state.attributesGroups);
  const attributesValues = useSelector(state => state.attributesValues);
  const propertiesAttributesValues = useSelector(
    state => state.propertiesAttributesValues
  );
  //State
  const [surface_measurement, setSurface_measurement] = useState('');
  return (
    <Box className={classes.iconsContainer}>
      {property &&
        property.property_attribute_value &&
        property.property_attribute_value.map(idPropertyAttributeValue => {
          let propertyAttributeValue =
            propertiesAttributesValues.byId.propertiesAttributesValues[
              idPropertyAttributeValue
            ];
          if (
            propertiesAttributesValues.update.activos.indexOf(
              idPropertyAttributeValue
            ) !== -1
          )
            propertyAttributeValue =
              propertiesAttributesValues.update.activo[
                idPropertyAttributeValue
              ];
          if (
            propertiesAttributesValues.create.nuevos.indexOf(
              idPropertyAttributeValue
            ) !== -1
          )
            propertyAttributeValue =
              propertiesAttributesValues.create.nuevo[idPropertyAttributeValue];
          let attribute = null;
          let value = null;
          if (propertyAttributeValue) {
            attribute =
              attributes.byId.attributes[propertyAttributeValue.idAttribute];
            value = propertyAttributeValue.value;
            if (attribute && attribute.typeAttribute === 'enum') {
              attribute.attribute_value.some(idAttributeValue => {
                let attributeValue =
                  attributesValues.byId.attributesValues[idAttributeValue];
                if (attributeValue.codAttributeValue === value) {
                  value = attributeValue.nameAttributeValue;
                  return true;
                }
                return false;
              });
            }
          }
          let attributeGroup = null;
          if (attribute)
            attributeGroup =
              attributesGroups.byId.attributesGroups[
                attribute.idAttributeGroup
              ];
          if (
            attribute &&
            attribute.codAttribute === 'surface_measurement' &&
            surface_measurement === ''
          )
            setSurface_measurement(propertyAttributeValue.value);
          if (attributeGroup && attributeGroup.codAttributeGroup === 'surfaces')
            value = value + surface_measurement;

          if (attribute && attribute.typeAttribute === 'date')
            value = propertyAttributeValue.value
              ? moment(propertyAttributeValue.value).format('MMMM YYYY')
              : null;
          if (
            attribute &&
            attribute.codAttribute === 'age' &&
            propertyAttributeValue.value === '0'
          )
            value = 'A estrenar';
          if (
            attribute &&
            attribute.codAttribute === 'age' &&
            propertyAttributeValue.value === '-1'
          )
            value = 'En construcción';
          if (
            attribute &&
            attribute.codAttribute === 'age' &&
            parseInt(propertyAttributeValue.value) > 0
          )
            value = propertyAttributeValue.value + ' años';
          if (
            propertyAttributeValue &&
            propertyAttributeValue.value &&
            attribute &&
            attribute.path &&
            attribute.codAttribute !== 'surface_measurement' &&
            attributeGroup &&
            listAttributesGroups.indexOf(attributeGroup.codAttributeGroup) !==
              -1
          )
            return (
              <Chip
                size={size ? size : 'inherit'}
                key={'pav' + idPropertyAttributeValue}
                avatar={<Avatar src={c.API_HOST + attribute.path} />}
                label={value ? value : null}
                variant={'outlined'}
              />
            );
        })}
    </Box>
  );
}
