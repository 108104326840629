import { MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropertyItemList from '../Property/PropertyItemList';

const useStyles = makeStyles(theme => ({
  selector: {
    border: 'none',
    minWidth: '160px',
    marginRight: '10px',
    '& .MuiInput-underline:before': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:hover': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none'
    }
  },
  avatarUser: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1)
  }
}));
export default function TicketPropertySelector(props) {
  const classes = useStyles();
  const {
    model,
    handleChangeModel,
    disabled,
    id,
    description,
    fullwidth = false,
    margin = 'dense',
    idsTicketProperties
  } = props;
  const dispatch = useDispatch();

  //Store
  const assets = useSelector(state => state.assets);
  const properties = useSelector(state => state.properties);
  const ticketProperties = useSelector(state => state.ticketProperties);
  let property =
    model && model[id] ? properties.byId.properties[model && model[id]] : null;
  if (properties.update.activos.indexOf(model && model[id]) !== -1)
    property = properties.update.activo[model && model[id]];

  return (
    <TextField
      id={id}
      select
      fullWidth={fullwidth}
      label={description}
      value={model && model[id] ? model[id] : ''}
      onChange={e => handleChangeModel(e)}
      InputProps={{
        id: id,
        name: id
        // startAdornment: (
        //   <Avatar
        //     className={classes.avatarUser}
        //     src={
        //       property && property.main_asset && property.main_asset.length > 0
        //         ? c.API_HOST + assets.byId.assets[property.main_asset[0]].path
        //         : null
        //     }
        //   />
        // )
      }}
      disabled={disabled || !handleChangeModel}
      margin={margin}
      className={classes.selector}
    >
      {idsTicketProperties?.map(idTicketProperty => {
        let ticketProperty =
          ticketProperties.byId.ticketProperties[idTicketProperty];
        let property = ticketProperty?.property_id
          ? properties.byId.properties[ticketProperty.property_id]
          : null;
        if (
          properties.update.activos.indexOf(ticketProperty?.property_id) !== -1
        )
          property = properties.update.activo[ticketProperty?.property_id];
        return (
          <MenuItem key={'propselector' + property?.id} value={property?.id}>
            <PropertyItemList property={property} />
          </MenuItem>
        );
      })}
    </TextField>
  );
}
