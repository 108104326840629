import { normalize, schema, denormalize } from 'normalizr';

const tenantUserAccess = new schema.Entity(
  'tenantUserAccesses',
  {},
  { idAttribute: 'id' }
);

export function normalizeDatos(myData) {
  const mySchema = { tenantUserAccesses: [tenantUserAccess] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = tenantUserAccess;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = tenantUserAccess;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default tenantUserAccess;
