import { normalize, schema, denormalize } from 'normalizr';

const externalSystem = new schema.Entity(
  'externalSystems',
  {},
  { idAttribute: 'nameExternalSystem' }
);

export function normalizeDatos(myData) {
  const mySchema = { externalSystems: [externalSystem] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = externalSystem;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = externalSystem;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default externalSystem;
