import React, { useEffect } from 'react';
import { Breadcrumbs, Container, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import FirstStepsCard from './FirstStepsCard';
import SimpleAnalyticsPanel from './panels/SimpleAnalyticsPanel';
import UsersCard from './cards/UsersCard';
import Grid from '@material-ui/core/Grid';
import RealTimeUsersCard from './cards/RealTimeUsersCard';
import { fetchAnalytics, resetAnalytics } from '../../actions/AnalyticActions';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import CountriesCard from './cards/CountriesCard';
import DeviceCategoryCard from './cards/DeviceCategoryCard';
import PropertiesCard from './cards/PropertiesCard';
import SearchPanel from './panels/SearchPanel';
import SellersListPanel from './panels/SellersListPanel';
import DataRangeButton from './components/DataRangeButton';
import PropertiesWithoutAuthorizationCard from './cards/PropertiesWithoutAuthorizationCard';
import PropertiesAuthorizationGraphicCard from './cards/PropertiesAuthorizationGraphicCard';

function ConfigurationPanel() {
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = React.useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    endDate: new Date()
  });

  useEffect(() => {
    dispatch(resetAnalytics());
    dispatch(
      fetchAnalytics({
        metric: 'totalSessions',
        dimension: 'dateTime',
        dateFrom: moment().subtract(30, 'minutes').format('YYYY-MM-DD HH:mm'),
        dateTo: moment(dateRange.endDate).format('YYYY-MM-DD HH:mm')
      })
    );
    dispatch(
      fetchAnalytics({
        metric: 'totalUsers',
        dimension: 'country',
        dateFrom: moment().subtract(30, 'minutes').format('YYYY-MM-DD HH:mm'),
        dateTo: moment(dateRange.endDate).format('YYYY-MM-DD HH:mm')
      })
    );
    dispatch(
      fetchAnalytics({
        metric: 'totalSessions',
        dimension: 'date',
        dateFrom: moment(dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        dateTo: moment(dateRange.endDate).format('YYYY-MM-DD HH:mm')
      })
    );
    dispatch(
      fetchAnalytics({
        metric: 'totalNewUsers',
        dateFrom: moment(dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        dateTo: moment(dateRange.endDate).format('YYYY-MM-DD HH:mm')
      })
    );
    dispatch(
      fetchAnalytics({
        metric: 'totalProperties'
      })
    );
    dispatch(
      fetchAnalytics({
        metric: 'totalNewProperties',
        dateFrom: moment(dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        dateTo: moment(dateRange.endDate).format('YYYY-MM-DD HH:mm')
      })
    );
    dispatch(
      fetchAnalytics({
        metric: 'totalNewContacts',
        dateFrom: moment(dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        dateTo: moment(dateRange.endDate).format('YYYY-MM-DD HH:mm')
      })
    );
    dispatch(
      fetchAnalytics({
        metric: 'totalSellers'
      })
    );
    dispatch(
      fetchAnalytics({
        metric: 'totalClosedOperations',
        dateFrom: moment(dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        dateTo: moment(dateRange.endDate).format('YYYY-MM-DD HH:mm')
      })
    );
    dispatch(
      fetchAnalytics({
        metric: 'totalRetireOperations',
        dateFrom: moment(dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        dateTo: moment(dateRange.endDate).format('YYYY-MM-DD HH:mm')
      })
    );
    dispatch(
      fetchAnalytics({
        metric: 'totalSessions',
        dimension: 'country',
        dateFrom: moment(dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        dateTo: moment(dateRange.endDate).format('YYYY-MM-DD HH:mm')
      })
    );
    dispatch(
      fetchAnalytics({
        metric: 'totalSessions',
        dimension: 'deviceCategory',
        dateFrom: moment(dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        dateTo: moment(dateRange.endDate).format('YYYY-MM-DD HH:mm')
      })
    );
    dispatch(
      fetchAnalytics({
        metric: 'totalSessions',
        dimension: 'property',
        dateFrom: moment(dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        dateTo: moment(dateRange.endDate).format('YYYY-MM-DD HH:mm')
      })
    );
    dispatch(
      fetchAnalytics({
        metric: 'totalSessions',
        dimension: 'search',
        dateFrom: moment(dateRange.startDate).format('YYYY-MM-DD HH:mm'),
        dateTo: moment(dateRange.endDate).format('YYYY-MM-DD HH:mm')
      })
    );
    dispatch(
      fetchAnalytics({
        metric: 'propertiesWithoutAuth'
      })
    );
  }, [dateRange]);

  return (
    <Container maxWidth={'lg'}>
      <Breadcrumbs aria-label="breadcrumb" separator={'‣'}>
        <Link color="inherit" to="/">
          Home
        </Link>
      </Breadcrumbs>
      <Typography component={'h1'} variant={'h1'}>
        {'Estadísticas'}
      </Typography>
      <DataRangeButton
        setDateRange={setDateRange}
        dateRange={dateRange}
        style={{ marginTop: '-30px' }}
      />
      <Grid container style={{ marginTop: '25px' }} spacing={2}>
        <SimpleAnalyticsPanel />
        <FirstStepsCard />
        <UsersCard />
        <RealTimeUsersCard />
        <CountriesCard />
        <DeviceCategoryCard />
        <PropertiesCard key1={'totalSessions_property'} />
        <PropertiesWithoutAuthorizationCard key1={'propertiesWithoutAuth'} />
        <PropertiesAuthorizationGraphicCard />
        <SearchPanel />
        <SellersListPanel dateRange={dateRange} />
      </Grid>
    </Container>
  );
}

export default ConfigurationPanel;
