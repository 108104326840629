import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import DirectionsIcon from '@material-ui/icons/Directions';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { InputAdornment, TextField } from '@material-ui/core';
import clone from 'lodash/clone';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 200
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    '& input': {
      textAlign: 'center'
    },
    '& [type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& ::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& ::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    }
  },
  iconButton: {
    // padding: 10,
  }
}));

export default function CustomizedNumberInput(props) {
  const classes = useStyles();

  const add = () => {
    let newValue = props.value ? parseInt(clone(props.value)) + 1 : 1;
    let e = { target: { value: newValue } };
    props.onChange(e);
  };

  const subtract = () => {
    let newValue = props.value ? parseInt(clone(props.value)) - 1 : 1;
    let e = { target: { value: newValue } };
    props.onChange(e);
  };

  return (
    <Box className={classes.root}>
      {/*<Button variant="contained" className={classes.iconButton} color={"primary"}>*/}
      {/*    <RemoveIcon/>*/}
      {/*</Button>*/}
      <TextField
        className={classes.input}
        {...props}
        InputProps={{
          type: 'number',
          min: 1,
          step: 1,
          startAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={subtract} variant="contained" edge="end">
                <RemoveIcon />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={add} edge="start">
                <AddIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      {/*<IconButton type="submit" className={classes.iconButton} aria-label="search">*/}
      {/*    <AddIcon/>*/}
      {/*</IconButton>*/}
    </Box>
  );
}
