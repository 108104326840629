import { normalize, schema, denormalize } from 'normalizr';
import asset from './normalizeAssets';

const post = new schema.Entity(
  'posts',
  { asset: [asset] },
  { idAttribute: 'id' }
);

export function normalizeDatos(myData) {
  const mySchema = { posts: [post] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = post;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = post;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default post;
