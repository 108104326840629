import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
//Components
import {
  Breadcrumbs,
  Container,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  TextField
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchContacts,
  invalidateContacts,
  resetContacts
} from '../../actions/ContactActions';
import CustomPaper from '../../library/CustomPaper/CustomPaper';
import PaperHeader from '../../library/CustomPaper/PaperHeader';
import CropFreeIcon from '@material-ui/icons/Search';
import Grow from '../../library/Grow';
import PaperFooter from '../../library/CustomPaper/PaperFooter';
import Pagination from '@material-ui/lab/Pagination';
import { Link } from 'react-router-dom';
import BtnAdd from '../../library/Buttons/BtnAdd';
import ContainerBtn from '../../library/Buttons/ContainerBtn';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import moment from 'moment/moment';
import List from '@material-ui/core/List';
import { Skeleton } from '@material-ui/lab';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { isValidPhoneNumber } from 'react-phone-number-input';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper
  }
}));

function ContactsList(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { title } = props;

  //Store
  const contacts = useSelector(state => state.contacts);

  //State
  const [search, setSearch] = useState('');

  const params = new URLSearchParams(props.location.search);
  const order = params.get('order') ? params.get('order') : 'id';
  const direction = params.get('direction') ? params.get('direction') : 'desc';
  const page = params.get('page') ? params.get('page') : 1;
  const searchPhase = params.get('sp') ? params.get('sp') : '';

  const disabled = contacts.byId.isFetching || contacts.delete.isDeleting;

  //Hooks
  const timer = useRef();

  //Effects
  useEffect(() => {
    window.scrollTo(0, 0);

    if (searchPhase != search) {
      setSearch(searchPhase);
    }

    return function cleanup() {};
  }, []);

  useEffect(() => {
    let filtros = {
      searchPhase: search ? search : '',
      order: order,
      direction: direction,
      page: page,
      with: []
    };
    if (props.idConsultant) filtros.idConsultant = props.idConsultant;

    dispatch(resetContacts());
    dispatch(invalidateContacts());
    dispatch(fetchContacts(filtros));
  }, [props.location.search]);

  const WAIT_INTERVAL = 500;

  const changeSearch = e => {
    clearTimeout(timer.current);
    let valor = e.target.value;
    setSearch(e.target.value);

    timer.current = setTimeout(() => triggerChange(valor), WAIT_INTERVAL);
  };

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      clearTimeout(timer.current);
      triggerChange();
    }
  };

  const triggerChange = searchPhase => {
    if ((!searchPhase && !search) || searchPhase === '') {
      params.delete('sp');
      props.history.push(props.location.pathname + '?' + params.toString());
    } else {
      params.delete('page');
      params.set('sp', searchPhase ? searchPhase : search);
      props.history.push(props.location.pathname + '?' + params.toString());
    }
  };

  const handleChangePage = (e, page) => {
    let pagina = page;
    let paramsPagina = new URLSearchParams(props.location.search);
    paramsPagina.set('page', pagina ? pagina : '1');
    props.history.push(props.location.pathname + '?' + paramsPagina.toString());
  };

  let Contacts = [];

  if (contacts.byId.isFetching) {
    Contacts = [...Array(5).keys()].map(i => {
      return <Skeleton style={{ margin: '10px 25px' }} variant="text" />;
    });
  }

  if (!contacts.byId.isFetching)
    Contacts = contacts.allIds.map(idContact => {
      let contact = contacts.byId.contacts[idContact];
      return (
        <>
          <ListItem>
            <ListItemAvatar>
              <Avatar alt={contact.name} />
            </ListItemAvatar>
            <ListItemText
              primary={
                <Box>
                  <Typography variant="body1" component="p">
                    <strong>{contact.contact_topic}</strong>
                  </Typography>
                  <Typography variant="body2" component="p">
                    {contact.message}
                  </Typography>
                  {contact.date && (
                    <Typography variant="body2" component="p">
                      Fecha estimada visita:{' '}
                      <strong>
                        {contact.date
                          ? moment(contact.date).format('DD-MM-YYYY')
                          : ''}
                      </strong>
                    </Typography>
                  )}
                  {contact.timeFrom && (
                    <Typography variant="body2" component="p">
                      Hora:{' '}
                      <strong>{`${moment(contact.timeFrom, 'HH:mm:ss').format(
                        'HH:mm'
                      )} - ${moment(contact.timeTo, 'HH:mm:ss').format(
                        'HH:mm'
                      )}`}</strong>
                    </Typography>
                  )}
                </Box>
              }
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    {contact.name}
                  </Typography>
                  {' — ' +
                    (moment(contact.created_at).isSame(moment(), 'day')
                      ? moment().diff(moment(contact.created_at), 'minutes') <
                        60
                        ? 'hace ' +
                          moment().diff(moment(contact.created_at), 'minutes') +
                          'minutos'
                        : 'hace ' +
                          moment().diff(moment(contact.created_at), 'hours') +
                          'horas'
                      : moment(contact.created_at).format('DD MMM YYYY HH:mm'))}
                  <Typography variant={'caption'} style={{ display: 'block' }}>
                    {contact.cellphone}
                  </Typography>
                </React.Fragment>
              }
            />
            <ListItemSecondaryAction>
              <Tooltip title="Enviar WhatsApp a interesado">
                <IconButton
                  onClick={() =>
                    window.open(
                      `https://api.whatsapp.com/send?phone=${
                        contact?.cellphone &&
                        isValidPhoneNumber(contact?.cellphone)
                          ? contact?.cellphone.replace('+', '')
                          : ''
                      }`
                    )
                  }
                >
                  <WhatsAppIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider variant="inset" component="li" />
        </>
      );
    });

  if (Contacts.length === 0)
    Contacts.push(
      <Box display={'flex'} justifyContent={'center'}>
        <Typography variant={'caption'}>No se encontraron contactos</Typography>
      </Box>
    );

  const totalPaginas = Math.ceil(
    contacts.totalContacts ? contacts.totalContacts / 50 : 0
  );

  return (
    <Container maxWidth={'lg'}>
      {title !== false && (
        <>
          <Breadcrumbs aria-label="breadcrumb" separator={'‣'}>
            <Link color="inherit" to="/">
              Home
            </Link>
          </Breadcrumbs>
          <Typography component={'h1'} variant={'h1'}>
            Contactos
          </Typography>
        </>
      )}
      <CustomPaper>
        <PaperHeader>
          <TextField
            id="search"
            placeholder="Buscar ..."
            variant="outlined"
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <CropFreeIcon />
                  </IconButton>
                </InputAdornment>
              )
              // disabled: disabled
            }}
            value={search ? search : ''}
            onChange={e => changeSearch(e)}
            onKeyDown={e => handleKeyDown(e)}
          />
          <Grow />
        </PaperHeader>
        <List className={classes.root}>{Contacts}</List>
        <PaperFooter style={{ justifyContent: 'center' }}>
          {!!totalPaginas && totalPaginas > 1 && (
            <Pagination
              count={totalPaginas}
              page={page ? parseInt(page) : 1}
              color="primary"
              onChange={handleChangePage}
              size={'small'}
            />
          )}
        </PaperFooter>
      </CustomPaper>
      <ContainerBtn>
        <BtnAdd
          onClick={() => props.history.push('/contacts/new')}
          fetching={disabled}
        />
      </ContainerBtn>
    </Container>
  );
}

ContactsList.propTypes = {};

export default ContactsList;
