import React, { useRef } from 'react';
// import style from '../../styles/navbar/DesktopFilterNavBar.module.css';
import {
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  useMediaQuery
} from '@material-ui/core';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';
import PaperHeader from '../../../library/CustomPaper/PaperHeader';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import { Close } from '@material-ui/icons';
import Grow from '../../../library/Grow';
import Box from '@material-ui/core/Box';
import SharePropertiesBar from './SharePropertiesBar';
import FilterBar from './FilterBar';

export default function DesktopFilterNavBar(props) {
  const isMobile = useMediaQuery('(max-width:990px)');
  const timer = useRef();

  let { setQueryRouter, queryRouter } = props;

  //State
  const [search, setSearch] = React.useState('');

  const handleChangeOrder = e => {
    let query_ = Object.assign({}, queryRouter);
    let value = e.target.value;
    switch (value) {
      case 'priceasc':
        query_.order = 'price';
        query_.direction = 'asc';
        break;
      case 'pricedesc':
        query_.order = 'price';
        query_.direction = 'desc';
        break;
      case 'viewsdesc':
        query_.order = 'views';
        query_.direction = 'desc';
        break;
      case 'iddesc':
        query_.order = 'id';
        query_.direction = 'desc';
        break;
      default:
        query_.order = 'id';
        query_.direction = 'desc';
        break;
    }
    setQueryRouter(query_);
  };

  const changeSearch = e => {
    clearTimeout(timer.current);
    let valor = e.target.value;
    setSearch(e.target.value);

    timer.current = setTimeout(() => triggerChange(valor), 700);
  };

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      clearTimeout(timer.current);
      triggerChange();
    }
  };

  const triggerChange = searchPhase => {
    if ((!searchPhase && !search) || searchPhase === '') {
      let _query = Object.assign({}, queryRouter);
      delete _query['searchPhase'];
      setQueryRouter(_query);
    } else {
      let _query = Object.assign({}, queryRouter, {
        searchPhase: searchPhase ? searchPhase : search
      });
      delete _query['page'];
      setQueryRouter(_query);
    }
  };

  const onClear = () => {
    setSearch(null);
    let _query = Object.assign({}, queryRouter);
    delete _query['searchPhase'];
    setQueryRouter(_query);
  };

  return (
    <>
      <PaperHeader>
        <TextField
          id="search"
          placeholder={'Buscar...'}
          variant="outlined"
          InputLabelProps={{
            shrink: true
          }}
          sx={{
            maxWidth: '320px',
            '& .MuiInputAdornment-root': {
              display: isMobile ? 'none' : 'flex'
            },
            '& .MuiInputBase-root ': {
              borderRadius: '13px',
              // backgroundColor: theme.custom.inputSearch[theme.palette.mode],
              minHeight: 'inherit',
              '& fieldset': {
                // border: "none"
              },
              '& .MuiInputBase-input': {
                height: 'inherit',
                padding: isMobile ? '12px 0' : '12px'
              }
            }
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <IconButton size={'small'} onClick={onClear}>
                <Close />
              </IconButton>
            )
            // disabled: disabled
          }}
          value={search ? search : ''}
          onChange={e => changeSearch(e)}
          onKeyDown={e => handleKeyDown(e)}
        />
        <Grow />
        <SharePropertiesBar />
        {!isMobile && (
          <Select
            margin={'dense'}
            size={'small'}
            variant={'outlined'}
            value={
              queryRouter?.order && queryRouter?.direction
                ? queryRouter?.order + queryRouter?.direction
                : 'iddesc'
            }
            startAdornment={
              <SortByAlphaIcon
                fontSize={'small'}
                style={{ marginRight: '5px' }}
              />
            }
            onChange={handleChangeOrder}
          >
            <MenuItem
              value={'pricedesc'}
              selected={
                queryRouter?.direction === 'desc' &&
                queryRouter?.order === 'price'
              }
            >
              Mayor precio
            </MenuItem>
            <MenuItem
              value={'priceasc'}
              selected={
                queryRouter?.direction === 'asc' &&
                queryRouter?.order === 'price'
              }
            >
              Menor precio
            </MenuItem>
            <MenuItem
              value={'viewsdesc'}
              selected={
                queryRouter?.direction === 'desc' &&
                queryRouter?.order === 'views'
              }
            >
              Más relevante
            </MenuItem>
            <MenuItem
              value={'iddesc'}
              selected={
                (queryRouter?.direction === 'desc' &&
                  queryRouter?.order === 'views') ||
                !queryRouter?.order ||
                !queryRouter?.direction
              }
            >
              Últimos publicados
            </MenuItem>
          </Select>
        )}
      </PaperHeader>
      <PaperHeader>
        <Grow />
        <div>
          <Box display={'flex'} gridColumnGap={'5px'}>
            <FilterBar
              setQueryRouter={setQueryRouter}
              queryRouter={queryRouter}
            />
          </Box>
        </div>
      </PaperHeader>
    </>
  );
}
