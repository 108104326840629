import { normalize, schema, denormalize } from 'normalizr';

const attributeValue = new schema.Entity(
  'attributesValues',
  {},
  { idAttribute: 'id' }
);

export function normalizeDatos(myData) {
  const mySchema = { attributesValues: [attributeValue] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = attributeValue;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = attributeValue;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default attributeValue;
