import Grid from '@material-ui/core/Grid';
import { Breadcrumbs, Chip, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@material-ui/core/Box';
import MainImageProperty from './MainImageProperty';
import PropertyAttributeChips from './PropertyAttributeChips';
import { makeStyles } from '@material-ui/core/styles';
import c from '../../constants/Constants';
//Img
import DoneIcon from '@material-ui/icons/Done';
import UserSelector from './UserSelector';
import PropertyStatus from '../../utils/PropertyStatus';
import {
  deleteProperty,
  saveDeleteProperty
} from '../../actions/PropertyActions';

const useStyles = makeStyles(theme => ({
  desProperty: {
    display: '-webkit-box',
    lineClamp: 2,
    boxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: '20px',
    marginTop: '10px'
  },
  iconsContainer: {
    display: 'flex',
    gap: '5px 5px',
    flexWrap: 'wrap',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  avatarPropertyType: {
    width: theme.spacing(2),
    height: theme.spacing(2)
  }
}));

export default function PropertyCard(props) {
  const {
    property,
    changeProperty,
    disabled,
    files,
    setFiles,
    handleChangeProperty
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  //Store
  const propertyTypes = useSelector(state => state.propertyTypes);
  const operationTypes = useSelector(state => state.operationTypes);
  const propertyOperations = useSelector(state => state.propertyOperations);
  const assets = useSelector(state => state.assets);

  const propertyType =
    property &&
    property.idPropertyType &&
    propertyTypes.byId.propertyTypes[property.idPropertyType]
      ? propertyTypes.byId.propertyTypes[property.idPropertyType]
      : null;
  const propertySubType =
    property &&
    property.idPropertySubType &&
    propertyTypes.byId.propertyTypes[property.idPropertySubType]
      ? propertyTypes.byId.propertyTypes[property.idPropertySubType]
      : null;

  return (
    <>
      <Grid item md={4} sm={12} xs={12}>
        <MainImageProperty
          files={files}
          setFiles={setFiles}
          mainAsset={
            property && property.main_asset && property.main_asset.length > 0
              ? assets.byId.assets[property.main_asset[0]]
              : null
          }
          disabled={disabled}
          property={property}
          changeProperty={changeProperty}
        />
      </Grid>
      <Grid item md={8} sm={12} xs={12}>
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'baseline'}
          gridColumnGap={'5px'}
        >
          <img
            alt={'icono tipo propiedad'}
            src={
              propertyType?.filename
                ? `${c.API_HOST}/storage/propertyTypeIcons/${propertyType.filename}`
                : ''
            }
            className={classes.avatarPropertyType}
          />
          <Typography variant={'p'} component={'caption'} display={'inline'}>
            {propertyType?.nameType ? propertyType.nameType : ''}
            {propertySubType?.nameType ? ' - ' + propertySubType.nameType : ''}
          </Typography>
        </Box>
        <Box display={'flex'} justifyContent={'space-between'}>
          <Typography variant={'h1'} component={'span'}>
            {`${property?.address}${
              property?.floor ? ' ' + property?.floor : ''
            }${property?.apartment ? ' ' + property?.apartment : ''}`}
          </Typography>
          <PropertyStatus
            status={property?.status}
            property_id={property.id}
            disabled={disabled}
          />
        </Box>
        <Breadcrumbs aria-label="breadcrumb" separator={'‣'}>
          <Typography variant={'p'} component={'body2'}>
            {property?.neighborhood ? property?.neighborhood : ''}
          </Typography>
          <Typography variant={'p'} component={'body2'}>
            {property?.city ? property?.city : ''}
          </Typography>
          <Typography variant={'p'} component={'body2'}>
            {property?.state ? property?.state : ''}
          </Typography>
          <Typography variant={'p'} component={'body2'}>
            {property?.country ? property?.country : ''}
          </Typography>
        </Breadcrumbs>
        <Typography
          variant={'p'}
          component={'body2'}
          className={classes.desProperty}
          dangerouslySetInnerHTML={{
            __html: property ? property.desProperty : ''
          }}
        ></Typography>
        <PropertyAttributeChips
          property={property}
          listAttributesGroups={[
            'basic',
            'surfaces',
            'antiquity',
            'expenses',
            'main'
          ]}
        />

        <Box
          style={{
            visibility: !property || !property.id ? 'hidden' : 'inherit',
            marginTop: '14px'
          }}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'flex-start'}
        >
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'flex-end'}
          >
            <UserSelector
              handleChangeModel={handleChangeProperty}
              model={property}
              description={'Asesor'}
              id={'idConsultant'}
            />
            <UserSelector
              handleChangeModel={handleChangeProperty}
              model={property}
              description={'Corredor inmobiliario'}
              id={'idAgent'}
            />
          </Box>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'flex-end'}
            gridColumnGap={'15px'}
            gridRowGap={'10px'}
            flexWrap={'wrap'}
          >
            {property &&
              property.property_operation &&
              property.property_operation.map(idPropertyOperation => {
                let propertyOperation =
                  propertyOperations.byId.propertyOperations[
                    idPropertyOperation
                  ];
                if (
                  propertyOperations.update.activos.indexOf(
                    idPropertyOperation
                  ) !== -1
                )
                  propertyOperation =
                    propertyOperations.update.activo[idPropertyOperation];
                if (
                  propertyOperations.create.nuevos.indexOf(
                    idPropertyOperation
                  ) !== -1
                )
                  propertyOperation =
                    propertyOperations.create.nuevo[idPropertyOperation];
                let operationType = null;
                if (propertyOperation)
                  operationType =
                    operationTypes.byId.operationTypes[
                      propertyOperation.idOperationType
                    ];
                if (
                  propertyOperation &&
                  operationType &&
                  propertyOperation.codCurrency &&
                  propertyOperation.price
                )
                  return (
                    <Box
                      key={'pop' + idPropertyOperation}
                      display={'flex'}
                      flexDirection={'column'}
                      justifyContent={'flex-end'}
                      alignItems={'self-end'}
                    >
                      <Typography variant={'caption'} component={'span'}>
                        {operationType.nameType}
                      </Typography>
                      <Typography variant={'h2'} component={'span'}>
                        {propertyOperation.codCurrency +
                          ' ' +
                          propertyOperation.price}
                      </Typography>
                    </Box>
                  );
              })}
          </Box>
        </Box>
      </Grid>
    </>
  );
}
