import {
  INVALIDATE_POSTS,
  ERROR_POSTS,
  RECEIVE_POSTS,
  REQUEST_POSTS,
  RESET_POSTS,
  ERROR_POST,
  RECEIVE_POST,
  REQUEST_POST,
  UPDATE_POST,
  REQUEST_UPDATE_POST,
  SUCCESS_UPDATE_POST,
  ERROR_UPDATE_POST,
  RESET_UPDATE_POST,
  REQUEST_UPDATE_POSTS,
  SUCCESS_UPDATE_POSTS,
  ERROR_UPDATE_POSTS,
  RESET_UPDATE_POSTS,
  CREATE_POST,
  ERROR_CREATE_POST,
  REQUEST_CREATE_POST,
  RESET_CREATE_POST,
  SUCCESS_CREATE_POST,
  REQUEST_CREATE_POSTS,
  SUCCESS_CREATE_POSTS,
  ERROR_CREATE_POSTS,
  RESET_CREATE_POSTS,
  DELETE_POST,
  DELETE_CREATE_POST,
  DELETE_UPDATE_POST,
  REQUEST_DELETE_POST,
  SUCCESS_DELETE_POST,
  ERROR_DELETE_POST,
  RESET_DELETE_POST,
  REQUEST_PRINT_POST,
  SUCCESS_PRINT_POST,
  ERROR_PRINT_POST,
  RESET_PRINT_POST,
  RECEIVE_FILE_POST,
  REQUEST_PRINT_POSTS,
  SUCCESS_PRINT_POSTS,
  ERROR_PRINT_POSTS,
  RESET_PRINT_POSTS,
  RECEIVE_FILE_POSTS,
  PRINT_POST,
  DELETE_PRINT_POST
} from '../actions/PostActions';
import {
  CREATE_ASSET,
  UPDATE_ASSET,
  DELETE_ASSET
} from '../actions/AssetActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    posts: {},
    files: {}
  };
}

function postsById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_POSTS:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_POSTS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_POSTS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_POSTS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        posts: {}
      });
    case RECEIVE_POSTS:
      let dato = action.posts.entities.posts;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        posts: merge({}, state.posts, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_POST:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_POST:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_POST:
      let datoPost = action.post.entities.posts;
      return Object.assign({}, state, {
        posts: merge({}, state.posts, datoPost),
        isFetching: false
      });
    case RECEIVE_FILE_POST:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_POST:
      let datoPostEliminado = action.post.entities.posts;
      return Object.assign({}, state, {
        posts: mergeWith(
          clone(datoPostEliminado),
          state.posts,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_POST:
      let datoPostCreado = action.post.entities.posts;
      return Object.assign({}, state, {
        posts: mergeWith(
          clone(datoPostCreado),
          state.posts,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_POSTS:
      let datosPostCreado = action.posts.entities.posts;
      return Object.assign({}, state, {
        posts: mergeWith(
          clone(datosPostCreado),
          state.posts,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_POST:
      let datoPostActualizado = action.post.entities.posts;
      return Object.assign({}, state, {
        posts: mergeWith(
          clone(datoPostActualizado),
          state.posts,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_POSTS:
      let datosPostActualizado = action.posts.entities.posts;
      return Object.assign({}, state, {
        posts: mergeWith(
          clone(datosPostActualizado),
          state.posts,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        posts: {}
      });
    default:
      return state;
  }
}

function allPosts(state = [], action) {
  switch (action.type) {
    case RECEIVE_POSTS:
      return action.posts.result && action.posts.result.posts
        ? union(action.posts.result.posts, state)
        : action.posts.result
        ? action.posts.result
        : state;
    case RECEIVE_POST:
      return action.post.result ? union([action.post.result], state) : state;

    case SUCCESS_CREATE_POST:
      let datoPostSCreate = action.post.entities.posts;
      let idNuevoSCreate = null;
      if (Object.values(datoPostSCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoPostSCreate)[0] &&
          Object.values(datoPostSCreate)[0].id
            ? Object.values(datoPostSCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_POSTS:
      let postsCreate =
        action.posts.entities && action.posts.entities.posts
          ? action.posts.entities.posts
          : null;
      return postsCreate
        ? union(
            state,
            Object.values(postsCreate).map(posts => {
              return posts.id;
            })
          )
        : state;
    case RESET_POSTS:
      return [];

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalPosts(state = null, action) {
  switch (action.type) {
    case RECEIVE_POSTS:
      return action.posts && action.posts.result.total
        ? action.posts.result.total
        : 0;
    case RESET_POSTS:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_POST:
      let dato = action.post.entities.posts;
      let post =
        dato && Object.keys(dato).length > 0 ? dato[action.post.result] : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: post ? post : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_POST:
      let idsUpdate = [];
      Object.values(action.post).map(postUpdate => {
        if (postUpdate && postUpdate.id) idsUpdate.push(postUpdate.id);
      });
      return merge({}, state, {
        activo: action.post,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_POST:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_POST:
      let datoPostActualizado = action.post.entities.posts;
      let postNuevo =
        datoPostActualizado && Object.keys(datoPostActualizado).length > 0
          ? datoPostActualizado[action.post.result]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: postNuevo
      });
    case ERROR_UPDATE_POST:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_POSTS:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_POSTS:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_POSTS:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_POST:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    case CREATE_ASSET:
      let postassetCreateActivo = clone(state.activo);
      let postassetCreateActivos = clone(state.activos);
      Object.values(action.asset).map(assetCreate => {
        if (
          assetCreate &&
          assetCreate.idPost &&
          postassetCreateActivo[assetCreate.idPost]
        ) {
          if (assetCreate.idPost.toString().indexOf('-') === -1)
            postassetCreateActivo[assetCreate.idPost].asset = union(
              postassetCreateActivo.asset,
              [assetCreate.id]
            );
        } else if (assetCreate) {
          postassetCreateActivo.asset = union(
            postassetCreateActivo.asset ? postassetCreateActivo.asset : [],
            [assetCreate.id]
          );
        }
        if (
          assetCreate &&
          assetCreate.idPost &&
          assetCreate.idPost.toString().indexOf('-') === -1
        )
          postassetCreateActivos = union(postassetCreateActivos, [
            assetCreate.idPost
          ]);
      });
      return Object.assign({}, state, {
        activo: postassetCreateActivo,
        activos: postassetCreateActivos
      });
    case UPDATE_ASSET:
      let postassetUpdateActivo = clone(state.activo);
      let postassetUpdateActivos = clone(state.activos);
      Object.values(action.asset).map(assetUpdate => {
        if (
          assetUpdate &&
          assetUpdate.idPost &&
          postassetUpdateActivo[assetUpdate.idPost]
        ) {
          if (assetUpdate.idPost.toString().indexOf('-') === -1)
            postassetUpdateActivo[assetUpdate.idPost].asset = union(
              postassetUpdateActivo.asset,
              [assetUpdate.id]
            );
        } else if (assetUpdate) {
          postassetUpdateActivo.asset = union(
            postassetUpdateActivo.asset ? postassetUpdateActivo.asset : [],
            [assetUpdate.id]
          );
        }
        if (
          assetUpdate &&
          assetUpdate.idPost &&
          assetUpdate.idPost.toString().indexOf('-') === -1
        )
          postassetUpdateActivos = union(postassetUpdateActivos, [
            assetUpdate.idPost
          ]);
      });
      return Object.assign({}, state, {
        activo: postassetUpdateActivo,
        activos: postassetUpdateActivos
      });
    case DELETE_ASSET:
      let postassetDeleteActivo = clone(state.activo);
      let postassetDeleteActivos = clone(state.activos);
      Object.values(action.asset).map(assetDelete => {
        if (
          assetDelete &&
          assetDelete.idPost &&
          postassetDeleteActivo[assetDelete.idPost]
        ) {
          if (assetDelete.idPost.toString().indexOf('-') === -1)
            postassetDeleteActivo[assetDelete.idPost].asset = difference(
              postassetDeleteActivo.asset,
              [assetDelete.id]
            );
        } else if (assetDelete) {
          postassetDeleteActivo.asset = difference(
            postassetDeleteActivo.asset ? postassetDeleteActivo.asset : [],
            [assetDelete.id]
          );
        }
        if (
          assetDelete &&
          assetDelete.idPost &&
          assetDelete.idPost.toString().indexOf('-') === -1
        )
          postassetDeleteActivos = union(postassetDeleteActivos, [
            assetDelete.idPost
          ]);
      });
      return Object.assign({}, state, {
        activo: postassetDeleteActivo,
        activos: postassetDeleteActivos
      });

    case DELETE_POST:
      let datoPostDelete = action.post;
      let idsDelete = [];
      Object.values(action.post).map(postDelete => {
        if (postDelete && postDelete.id) idsDelete.push(postDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(clone(state.activo), Object.keys(datoPostDelete)),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_POST:
      let datoPostDeleteUpdate = action.post;
      let idsDeleteUpdate = [];
      Object.values(action.post).map(postDelete => {
        if (postDelete && postDelete.id) idsDeleteUpdate.push(postDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(clone(state.activo), Object.keys(datoPostDeleteUpdate)),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_POST:
      let datoPostDeleted = {};
      if (Object.values(action.post.entities.posts).length > 0)
        datoPostDeleted = Object.values(action.post.entities.posts)[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoPostDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_POST:
      let idsCreate = [];
      Object.values(action.post).map(postCreate => {
        if (postCreate && postCreate.id) idsCreate.push(postCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.post,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_POST:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_POST:
      let datoPostNuevo = action.post.entities.posts;
      let postNuevo =
        datoPostNuevo && Object.keys(datoPostNuevo).length > 0
          ? datoPostNuevo[action.post.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: postNuevo,
        nuevos: []
      });
    case ERROR_CREATE_POST:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_POSTS:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_POSTS:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_POSTS:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_POST:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    //CREATE ASSET
    case CREATE_ASSET:
      let postassetCreateActivo = clone(state.nuevo);
      Object.values(action.asset).map(assetCreate => {
        if (
          assetCreate &&
          assetCreate.idPost &&
          postassetCreateActivo[assetCreate.idPost]
        ) {
          if (assetCreate.idPost.toString().indexOf('-') !== -1)
            postassetCreateActivo[assetCreate.idPost].asset = union(
              postassetCreateActivo.asset,
              [assetCreate.id]
            );
        } else if (assetCreate) {
          postassetCreateActivo.asset = union(
            postassetCreateActivo.asset ? postassetCreateActivo.asset : [],
            [assetCreate.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: postassetCreateActivo
        //nuevos: assetCreate && assetCreate.idPost ? union(state.nuevos, [assetCreate.idPost]) : state.nuevos,
      });
    case UPDATE_ASSET:
      let postassetUpdateActivo = clone(state.nuevo);
      Object.values(action.asset).map(assetUpdate => {
        if (
          assetUpdate &&
          assetUpdate.idPost &&
          postassetUpdateActivo[assetUpdate.idPost]
        ) {
          if (assetUpdate.idPost.toString().indexOf('-') !== -1)
            postassetUpdateActivo[assetUpdate.idPost].asset = union(
              postassetUpdateActivo.asset,
              [assetUpdate.id]
            );
        } else if (assetUpdate) {
          postassetUpdateActivo.asset = union(
            postassetUpdateActivo.asset ? postassetUpdateActivo.asset : [],
            [assetUpdate.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: postassetUpdateActivo
        //nuevos: assetUpdate && assetUpdate.idPost ? union(state.nuevos, [assetUpdate.idPost]) : state.nuevos,
      });
    case DELETE_ASSET:
      let postassetDeleteActivo = clone(state.nuevo);
      Object.values(action.asset).map(assetDelete => {
        if (
          assetDelete &&
          assetDelete.idPost &&
          assetDelete.idPost &&
          postassetDeleteActivo[assetDelete.idPost]
        ) {
          if (assetDelete.idPost.toString().indexOf('-') !== -1)
            postassetDeleteActivo[assetDelete.idPost].asset = difference(
              postassetDeleteActivo.asset,
              [assetDelete.id]
            );
        } else if (assetDelete) {
          postassetDeleteActivo.asset = difference(
            postassetDeleteActivo.asset ? postassetDeleteActivo.asset : [],
            [assetDelete.id]
          );
        }
      });
      return Object.assign({}, state, {
        nuevo: postassetDeleteActivo
        //nuevos: assetDelete && assetDelete.idPost ? union(state.nuevos, [assetDelete.idPost]) : state.nuevos,
      });

    case DELETE_POST:
      let datoPostDelete = action.post;
      let idsDelete = [];
      Object.values(action.post).map(postDelete => {
        if (postDelete && postDelete.id) idsDelete.push(postDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoPostDelete)),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_POST:
      let datoPostDeleteCreate = action.post;
      let idsDeleteCreate = [];
      Object.values(action.post).map(postDelete => {
        if (postDelete && postDelete.id) idsDeleteCreate.push(postDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoPostDeleteCreate)),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_POST:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.post,
        error: null
      });
    case REQUEST_DELETE_POST:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_POST:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_POST:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_POST:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_POST:
      let idsCreate = [];
      Object.values(action.post).map(postCreate => {
        if (postCreate && postCreate.id) idsCreate.push(postCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.post,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_POST:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_POST:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_POST:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_POSTS:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_POSTS:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_POSTS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_POST:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_POST:
      let datoPostDeleteCreate = action.post;
      let idsDeleteCreate = [];
      Object.values(action.post).map(postDelete => {
        if (postDelete && postDelete.id) idsDeleteCreate.push(postDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(clone(state.print), Object.keys(datoPostDeleteCreate)),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const posts = combineReducers({
  byId: postsById,
  allIds: allPosts,
  update: update,
  create: create,
  totalPosts: totalPosts,
  delete: deleter,
  print: print
});

export default posts;
