import { MenuItem, TextField } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUsersIfNeeded } from '../../actions/UserActions';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import c from '../../constants/Constants';

const useStyles = makeStyles(theme => ({
  selector: {
    border: 'none',
    minWidth: '160px',
    marginRight: '10px',
    '& .MuiInput-underline:before': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:hover': {
      borderBottom: 'none'
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none'
    }
  },
  avatarUser: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1)
  }
}));
export default function UserSelector(props) {
  const classes = useStyles();
  const {
    model,
    handleChangeModel,
    disabled,
    id,
    description,
    fullwidth = false,
    margin = 'dense'
  } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsersIfNeeded({ status: 1, idsRoles: [1, 2] }));
  });

  //Store
  const users = useSelector(state => state.users);
  const user = model && model[id] ? users.byId.users[model[id]] : null;

  return (
    <TextField
      id={id}
      select
      fullWidth={fullwidth}
      label={description}
      value={model && model[id] ? model[id] : ''}
      onChange={e => handleChangeModel(e)}
      InputProps={{
        id: id,
        name: id,
        startAdornment: (
          <Avatar
            className={classes.avatarUser}
            src={user?.path ? `${c.API_HOST}${user.path}` : ''}
          />
        )
      }}
      disabled={disabled || !handleChangeModel}
      margin={margin}
      className={classes.selector}
      // variant="outlined"
      // onBlur={validador?.validar}
      // error={!validador?.isValidById(id)}
      // helperText={validador.getHelperTextById("idTypeFile")}
    >
      {users.allIds.map(idProducer => {
        let user = users.byId.users[idProducer];
        return (
          <MenuItem key={idProducer} value={idProducer}>
            {user.name + ' ' + user.lastname}
          </MenuItem>
        );
      })}
    </TextField>
  );
}
