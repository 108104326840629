import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
//Components
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import clsx from 'clsx';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
//Icons
import clone from 'lodash/clone';
import union from 'lodash/union';
import c from '../../constants/Constants';
import { createAsset, updateAsset } from '../../actions/AssetActions';
import { useDispatch, useSelector } from 'react-redux';
//Img

const styles = makeStyles(theme => ({
  form: {
    // padding: theme.spacing(2, 0)
  },
  fileInput: {
    marginBottom: '12px',
    '& label': {
      width: '100%',
      height: '100%',
      display: 'flex',
      padding: '0px 8px',
      paddingBottom: '11px',
      paddingTop: '12px',
      borderRadius: '8px',
      backgroundColor: 'rgba(241, 10, 77, 0.1)',
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      '& *': {
        pointerEvents: 'none'
      }
    },
    '& input[type=file]': {
      width: '0.1px',
      height: '0.1px',
      opacity: 0,
      overflow: 'hidden',
      top: '50%',
      left: '50%',
      position: 'absolute',
      zIndex: '-1'
    }
  },
  mobile: {
    width: '100%',
    maxWidth: '120px',
    height: '120px',
    paddingRight: '6px'
  },
  desktop: {
    width: '100%',
    height: '100%',
    '& img': {
      objectFit: 'cover',
      height: '100%'
    }
  },
  buttonUpload: {
    padding: 0,
    borderRadius: '8px',
    textTransform: 'none',
    width: '100%',
    height: '100%',
    '& .MuiButton-label': {
      width: '100%',
      height: '100%',
      border: '1px dashed #7978786e',
      borderRadius: '8px'
      // padding: "3px"
    }
  },
  labelButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
    minHeight: '230px',
    '& img': {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      backgroundSize: 'cover'
    }
  },
  progress: {
    width: '80px !important',
    height: '80px !important',
    position: 'absolute',
    '& svg circle': {
      strokeWidth: '1.2'
    }
  }
}));

export default function MainImageProperty(props) {
  const classes = styles();
  let inputfileprod = React.createRef();
  let img = React.useRef();
  const dispatch = useDispatch();
  const { disabled, files, setFiles, mainAsset, property, changeProperty } =
    props;
  //State
  const [loading, setLoading] = React.useState(false);
  const assets = useSelector(state => state.assets);

  const handleChangeFileAsset = e => {
    e.preventDefault();
    //Preview
    let fotos = [];
    setLoading(true);
    for (let i = 0; i < inputfileprod.current.files.length; i++) {
      let filename = inputfileprod.current.files[i].name.replace(
        /[^a-zA-Z0-9]/g,
        '_'
      );
      Object.defineProperty(inputfileprod.current.files[i], 'name', {
        writable: true,
        value: filename
      });
      fotos.push(inputfileprod.current.files[i]);
    }
    let fotosClone = clone(files);
    setFiles(union(fotosClone, fotos));

    //Cargar foto
    for (let i = 0; i < inputfileprod.current.files.length; i++) {
      let fileSubido = inputfileprod.current.files[i];
      let filename = fileSubido.name.replace(/[^a-zA-Z0-9]/g, '_');
      let reader = new FileReader();
      reader.onloadend = e => {
        setLoading(false);
        img.current.src = reader.result;
      };

      if (fileSubido) {
        reader.readAsDataURL(fileSubido);
      }
      let id = Date.now() + Math.random() + '-';
      let cambio = {};
      cambio.filename = filename;
      cambio.type = 'Photo';
      cambio.mainImage = 1;
      changeAsset(cambio, id);

      let _property = {};
      _property.main_product_asset = [id];
      changeProperty(_property);
      handleChangeMainImageProductAsset(cambio);
    }
  };

  const changeAsset = (cambio, idAsset) => {
    //Verificar si ya esta siendo actualizado
    let asset =
      idAsset &&
      assets.update.activos.indexOf(idAsset) === -1 &&
      assets.create.nuevos.indexOf(idAsset) === -1
        ? assets.byId.assets[idAsset]
        : null;
    if (asset) {
      let actualizar = asset ? clone(asset) : null;
      if (actualizar) {
        let cambioAsset = {};
        cambioAsset[actualizar.id] = actualizar;
        if (idAsset.toString().indexOf('-') === -1)
          dispatch(updateAsset(cambioAsset));
        else dispatch(createAsset(cambioAsset));
      }
    }
    cambio.id = idAsset;
    cambio.idProperty = property.id;
    let cambioAsset = {};
    cambioAsset[idAsset] = cambio;
    if (idAsset.toString().indexOf('-') === -1)
      dispatch(updateAsset(cambioAsset));
    else {
      cambio.type = 'Photo';
      dispatch(createAsset(cambioAsset));
    }
  };

  const handleChangeMainImageProductAsset = asset => {
    property.asset.forEach(idAsset => {
      if (idAsset !== asset.id) {
        let _asset = assets.byId.assets[idAsset];
        if (assets.update.activos.indexOf(idAsset) !== -1)
          _asset = assets.update.activo[idAsset];
        if (assets.create.nuevos.indexOf(idAsset) !== -1)
          _asset = assets.create.nuevo[idAsset];
        if (_asset.mainImage === 1) {
          let cambioProductAsset = clone(_asset);
          cambioProductAsset.mainImage = 0;
          changeAsset(cambioProductAsset, idAsset);
        }
      }
    });
  };

  return (
    <Box
      component={'div'}
      className={classes.form}
      width={'100%'}
      height={'100%'}
    >
      <Box
        component={'div'}
        className={clsx([classes.fileInput, classes.desktop])}
      >
        <Button className={classes.buttonUpload} disabled={disabled}>
          <label htmlFor={'mainFilePost'} className={classes.labelButton}>
            <AddPhotoAlternateIcon color={'primary'} />
            <Typography variant={'caption'} color={'primary'}>
              Subir imagen portada
            </Typography>
            <img
              ref={img}
              src={mainAsset ? c.API_HOST + mainAsset.path : ''}
              alt=""
            />
            <CircularProgress
              className={classes.progress}
              style={{ display: !loading ? 'none' : 'inherit' }}
            />
          </label>
          <input
            disabled={disabled}
            ref={inputfileprod}
            type="file"
            name="mainFilePost"
            id={'mainFilePost'}
            className="inputfile"
            accept="image/*"
            onChange={e => handleChangeFileAsset(e)}
          />
        </Button>
      </Box>
    </Box>
  );
}

MainImageProperty.propTypes = {
  legend: PropTypes.string
};
