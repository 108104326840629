import { normalize, schema, denormalize } from 'normalizr';
import tenantUserAccess from './normalizeTenantUserAccesses';

const user = new schema.Entity(
  'users',
  { tenant_user_access: [tenantUserAccess] },
  { idAttribute: 'id' }
);

export function normalizeDatos(myData) {
  const mySchema = { users: [user] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = user;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = user;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default user;
