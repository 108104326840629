import { normalize, schema, denormalize } from 'normalizr';
import property from './normalizeProperties';

const ticketProperty = new schema.Entity(
  'ticketProperties',
  { property: property },
  { idAttribute: 'id' }
);

export function normalizeDatos(myData) {
  const mySchema = { ticketProperties: [ticketProperty] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = ticketProperty;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = ticketProperty;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default ticketProperty;
