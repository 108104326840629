//api
import neighborhoodsApi from '../api/neighborhoodsApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizeNeighborhoods';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//NEIGHBORHOODS
export const REQUEST_NEIGHBORHOODS = 'REQUEST_NEIGHBORHOODS';
export const RECEIVE_NEIGHBORHOODS = 'RECEIVE_NEIGHBORHOODS';
export const INVALIDATE_NEIGHBORHOODS = 'INVALIDATE_NEIGHBORHOODS';
export const ERROR_NEIGHBORHOODS = 'ERROR_NEIGHBORHOODS';
export const RESET_NEIGHBORHOODS = 'RESET_NEIGHBORHOODS';

export function invalidateNeighborhoods() {
  return {
    type: INVALIDATE_NEIGHBORHOODS
  };
}

function requestNeighborhoods() {
  return {
    type: REQUEST_NEIGHBORHOODS
  };
}

function receiveNeighborhoods(json) {
  return {
    type: RECEIVE_NEIGHBORHOODS,
    neighborhoods: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorNeighborhoods(error) {
  return {
    type: ERROR_NEIGHBORHOODS,
    error: error
  };
}

export function resetNeighborhoods() {
  return {
    type: RESET_NEIGHBORHOODS
  };
}

export function fetchNeighborhoods(filtros) {
  return dispatch => {
    dispatch(requestNeighborhoods());
    return neighborhoodsApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveNeighborhoods(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorNeighborhoods(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorNeighborhoods(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

function shouldFetchNeighborhoods(state) {
  const neighborhoods = state.neighborhoods.byId;
  if (!neighborhoods) {
    return true;
  } else if (neighborhoods.isFetching) {
    return false;
  } else {
    return neighborhoods.didInvalidate;
  }
}

export function fetchNeighborhoodsIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchNeighborhoods(getState())) {
      return dispatch(fetchNeighborhoods(filtros));
    }
  };
}

//MODEL
export const REQUEST_NEIGHBORHOOD = 'REQUEST_NEIGHBORHOOD';
export const RECEIVE_NEIGHBORHOOD = 'RECEIVE_NEIGHBORHOOD';
export const INVALIDATE_NEIGHBORHOOD = 'INVALIDATE_NEIGHBORHOOD';
export const ERROR_NEIGHBORHOOD = 'ERROR_NEIGHBORHOOD';
export const RESET_NEIGHBORHOOD = 'RESET_NEIGHBORHOOD';

export function invalidateNeighborhood() {
  return {
    type: INVALIDATE_NEIGHBORHOOD
  };
}

function requestNeighborhood() {
  return {
    type: REQUEST_NEIGHBORHOOD
  };
}

export function receiveNeighborhood(json) {
  return {
    type: RECEIVE_NEIGHBORHOOD,
    neighborhood: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorNeighborhood(error) {
  return {
    type: ERROR_NEIGHBORHOOD,
    error: error
  };
}

export function fetchNeighborhood(idNeighborhood) {
  return dispatch => {
    dispatch(requestNeighborhood());
    return neighborhoodsApi
      .getOne(idNeighborhood)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveNeighborhood(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(errorNeighborhood(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorNeighborhood(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_NEIGHBORHOOD = 'RECEIVE_FILE_NEIGHBORHOOD';

function receiveFileNeighborhood(file) {
  return {
    type: RECEIVE_FILE_NEIGHBORHOOD,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFileNeighborhood(idNeighborhood, filtros) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return neighborhoodsApi
      .getFile(idNeighborhood, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFileNeighborhood(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorNeighborhood(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorNeighborhood(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_NEIGHBORHOOD = 'UPDATE_NEIGHBORHOOD';
export const REQUEST_UPDATE_NEIGHBORHOOD = 'REQUEST_UPDATE_NEIGHBORHOOD';
export const SUCCESS_UPDATE_NEIGHBORHOOD = 'SUCCESS_UPDATE_NEIGHBORHOOD';
export const ERROR_UPDATE_NEIGHBORHOOD = 'ERROR_UPDATE_NEIGHBORHOOD';
export const RESET_UPDATE_NEIGHBORHOOD = 'RESET_UPDATE_NEIGHBORHOOD';
export const DELETE_UPDATE_NEIGHBORHOOD = 'DELETE_UPDATE_NEIGHBORHOOD';

function requestUpdateNeighborhood() {
  return {
    type: REQUEST_UPDATE_NEIGHBORHOOD
  };
}

function receiveUpdateNeighborhood(neighborhood) {
  return {
    type: SUCCESS_UPDATE_NEIGHBORHOOD,
    receivedAt: Date.now(),
    neighborhood: normalizeDato(neighborhood)
  };
}

function errorUpdateNeighborhood(error) {
  return {
    type: ERROR_UPDATE_NEIGHBORHOOD,
    error: error
  };
}

export function resetUpdateNeighborhood() {
  return {
    type: RESET_UPDATE_NEIGHBORHOOD
  };
}

export function updateNeighborhood(neighborhood) {
  return {
    type: UPDATE_NEIGHBORHOOD,
    neighborhood
  };
}

export function saveUpdateNeighborhood() {
  return (dispatch, getState) => {
    dispatch(requestUpdateNeighborhood());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let neighborhood = denormalizeDato(
      getState().neighborhoods.update.activo,
      store
    );

    return neighborhoodsApi
      .saveUpdate(neighborhood)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateNeighborhood(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateNeighborhood(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateNeighborhoods(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorUpdateNeighborhoods(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateNeighborhood(JSON.parse(error.message)));
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdateNeighborhood(error.data));
                })
                .catch(() => {
                  dispatch(
                    errorUpdateNeighborhood(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

export function deleteUpdateNeighborhood(neighborhood) {
  return {
    type: DELETE_UPDATE_NEIGHBORHOOD,
    neighborhood
  };
}

//UPDATE NEIGHBORHOODS
export const REQUEST_UPDATE_NEIGHBORHOODS = 'REQUEST_UPDATE_NEIGHBORHOODS';
export const SUCCESS_UPDATE_NEIGHBORHOODS = 'SUCCESS_UPDATE_NEIGHBORHOODS';
export const ERROR_UPDATE_NEIGHBORHOODS = 'ERROR_UPDATE_NEIGHBORHOODS';
export const RESET_UPDATE_NEIGHBORHOODS = 'RESET_UPDATE_NEIGHBORHOODS';

function requestUpdateNeighborhoods() {
  return {
    type: REQUEST_UPDATE_NEIGHBORHOODS
  };
}

function receiveUpdateNeighborhoods(neighborhoods) {
  return {
    type: SUCCESS_UPDATE_NEIGHBORHOODS,
    receivedAt: Date.now(),
    neighborhoods: normalizeDatos(neighborhoods)
  };
}

function errorUpdateNeighborhoods(error) {
  return {
    type: ERROR_UPDATE_NEIGHBORHOODS,
    error: error
  };
}

export function resetUpdateNeighborhoods() {
  return {
    type: RESET_UPDATE_NEIGHBORHOODS
  };
}

export function saveUpdateNeighborhoods() {
  return (dispatch, getState) => {
    dispatch(requestUpdateNeighborhoods());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let neighborhoods = getState().neighborhoods.update.activos.map(
      idNeighborhood => {
        return denormalizeDato(
          getState().neighborhoods.update.activo[idNeighborhood],
          store
        );
      }
    );

    return neighborhoodsApi
      .saveUpdateNeighborhoods(neighborhoods)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateNeighborhoods(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorUpdateNeighborhoods(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateNeighborhoods(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorUpdateNeighborhoods(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateNeighborhoods(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(
                    errorUpdateNeighborhoods(errorMessages.GENERAL_ERROR)
                  );
                });

            return;
        }
      });
  };
}

//ALTA NEIGHBORHOOD
export const CREATE_NEIGHBORHOOD = 'CREATE_NEIGHBORHOOD';
export const REQUEST_CREATE_NEIGHBORHOOD = 'REQUEST_CREATE_NEIGHBORHOOD';
export const SUCCESS_CREATE_NEIGHBORHOOD = 'SUCCESS_CREATE_NEIGHBORHOOD';
export const ERROR_CREATE_NEIGHBORHOOD = 'ERROR_CREATE_NEIGHBORHOOD';
export const RESET_CREATE_NEIGHBORHOOD = 'RESET_CREATE_NEIGHBORHOOD';
export const DELETE_CREATE_NEIGHBORHOOD = 'DELETE_CREATE_NEIGHBORHOOD';

//ALTA NEIGHBORHOOD
function requestCreateNeighborhood() {
  return {
    type: REQUEST_CREATE_NEIGHBORHOOD
  };
}

function receiveCreateNeighborhood(neighborhood) {
  return {
    type: SUCCESS_CREATE_NEIGHBORHOOD,
    receivedAt: Date.now(),
    neighborhood: normalizeDato(neighborhood)
  };
}

export function errorCreateNeighborhood(error) {
  return {
    type: ERROR_CREATE_NEIGHBORHOOD,
    error: error
  };
}

export function resetCreateNeighborhood() {
  return {
    type: RESET_CREATE_NEIGHBORHOOD
  };
}

export function createNeighborhood(neighborhood) {
  return {
    type: CREATE_NEIGHBORHOOD,
    neighborhood
  };
}

export function deleteCreateNeighborhood(neighborhood) {
  return {
    type: DELETE_CREATE_NEIGHBORHOOD,
    neighborhood
  };
}

export function saveCreateNeighborhood() {
  return (dispatch, getState) => {
    dispatch(requestCreateNeighborhood());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let neighborhood = denormalizeDato(
      getState().neighborhoods.create.nuevo,
      store
    );

    return neighborhoodsApi
      .saveCreate(neighborhood)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateNeighborhood(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateNeighborhood(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateNeighborhoods(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorCreateNeighborhoods(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateNeighborhood(JSON.parse(error.message)));
                  if (error.data)
                    dispatch(receiveCreateNeighborhood(error.data));
                })
                .catch(() => {
                  dispatch(
                    errorCreateNeighborhood(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//CREATE NEIGHBORHOODS
export const REQUEST_CREATE_NEIGHBORHOODS = 'REQUEST_CREATE_NEIGHBORHOODS';
export const SUCCESS_CREATE_NEIGHBORHOODS = 'SUCCESS_CREATE_NEIGHBORHOODS';
export const ERROR_CREATE_NEIGHBORHOODS = 'ERROR_CREATE_NEIGHBORHOODS';
export const RESET_CREATE_NEIGHBORHOODS = 'RESET_CREATE_NEIGHBORHOODS';

function requestCreateNeighborhoods() {
  return {
    type: REQUEST_CREATE_NEIGHBORHOODS
  };
}

function receiveCreateNeighborhoods(neighborhoods) {
  return {
    type: SUCCESS_CREATE_NEIGHBORHOODS,
    receivedAt: Date.now(),
    neighborhoods: normalizeDatos(neighborhoods)
  };
}

function errorCreateNeighborhoods(error) {
  return {
    type: ERROR_CREATE_NEIGHBORHOODS,
    error: error
  };
}

export function resetCreateNeighborhoods() {
  return {
    type: RESET_CREATE_NEIGHBORHOODS
  };
}

export function saveCreateNeighborhoods() {
  return (dispatch, getState) => {
    dispatch(requestCreateNeighborhoods());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let neighborhoods = getState().neighborhoods.create.nuevos.map(
      idNeighborhood => {
        return denormalizeDato(
          getState().neighborhoods.create.nuevo[idNeighborhood],
          store
        );
      }
    );

    return neighborhoodsApi
      .saveCreateNeighborhoods(neighborhoods)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateNeighborhoods(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorCreateNeighborhoods(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateNeighborhoods(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorCreateNeighborhoods(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateNeighborhoods(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(
                    errorCreateNeighborhoods(errorMessages.GENERAL_ERROR)
                  );
                });

            return;
        }
      });
  };
}

//DELETE NEIGHBORHOOD
export const DELETE_NEIGHBORHOOD = 'DELETE_NEIGHBORHOOD';
export const REQUEST_DELETE_NEIGHBORHOOD = 'REQUEST_DELETE_NEIGHBORHOOD';
export const SUCCESS_DELETE_NEIGHBORHOOD = 'SUCCESS_DELETE_NEIGHBORHOOD';
export const ERROR_DELETE_NEIGHBORHOOD = 'ERROR_DELETE_NEIGHBORHOOD';
export const RESET_DELETE_NEIGHBORHOOD = 'RESET_DELETE_NEIGHBORHOOD';

function requestDeleteNeighborhood() {
  return {
    type: REQUEST_DELETE_NEIGHBORHOOD
  };
}

function receiveDeleteNeighborhood(neighborhood) {
  return {
    type: SUCCESS_DELETE_NEIGHBORHOOD,
    receivedAt: Date.now(),
    neighborhood: normalizeDato(neighborhood)
  };
}

function errorDeleteNeighborhood(error) {
  return {
    type: ERROR_DELETE_NEIGHBORHOOD,
    error: error
  };
}

export function resetDeleteNeighborhood(error) {
  return {
    type: RESET_DELETE_NEIGHBORHOOD,
    error: error
  };
}

export function deleteNeighborhood(neighborhood) {
  return {
    type: DELETE_NEIGHBORHOOD,
    neighborhood
  };
}

export function saveDeleteNeighborhood(neighborhood) {
  return dispatch => {
    dispatch(requestDeleteNeighborhood());
    return neighborhoodsApi
      .saveDelete(neighborhood)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeleteNeighborhood());
        dispatch(receiveDeleteNeighborhood(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorDeleteNeighborhood(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeleteNeighborhood(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorDeleteNeighborhood(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorDeleteNeighborhood(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(
                    errorDeleteNeighborhood(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//PRINT NEIGHBORHOOD
export const PRINT_NEIGHBORHOOD = 'PRINT_NEIGHBORHOOD';
export const REQUEST_PRINT_NEIGHBORHOOD = 'REQUEST_PRINT_NEIGHBORHOOD';
export const SUCCESS_PRINT_NEIGHBORHOOD = 'SUCCESS_PRINT_NEIGHBORHOOD';
export const ERROR_PRINT_NEIGHBORHOOD = 'ERROR_PRINT_NEIGHBORHOOD';
export const RESET_PRINT_NEIGHBORHOOD = 'RESET_PRINT_NEIGHBORHOOD';
export const DELETE_PRINT_NEIGHBORHOOD = 'DELETE_PRINT_NEIGHBORHOOD';

function requestPrintNeighborhood() {
  return {
    type: REQUEST_PRINT_NEIGHBORHOOD
  };
}

function receivePrintNeighborhood(turnos) {
  return {
    type: SUCCESS_PRINT_NEIGHBORHOOD,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintNeighborhood(error) {
  return {
    type: ERROR_PRINT_NEIGHBORHOOD,
    error: error
  };
}

export function resetPrintNeighborhood() {
  return {
    type: RESET_PRINT_NEIGHBORHOOD
  };
}

export function printNeighborhood(neighborhood) {
  return {
    type: PRINT_NEIGHBORHOOD,
    neighborhood
  };
}

export function deletePrintNeighborhood(neighborhood) {
  return {
    type: DELETE_PRINT_NEIGHBORHOOD,
    neighborhood
  };
}

export function savePrintNeighborhood(idNeighborhood) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintNeighborhood());
    return neighborhoodsApi
      .printNeighborhood(idNeighborhood)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintNeighborhood(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintNeighborhood(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintNeighborhood(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintNeighborhood(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintNeighborhood(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT NEIGHBORHOODS
export const REQUEST_PRINT_NEIGHBORHOODS = 'REQUEST_PRINT_NEIGHBORHOODS';
export const SUCCESS_PRINT_NEIGHBORHOODS = 'SUCCESS_PRINT_NEIGHBORHOODS';
export const ERROR_PRINT_NEIGHBORHOODS = 'ERROR_PRINT_NEIGHBORHOODS';
export const RESET_PRINT_NEIGHBORHOODS = 'RESET_PRINT_NEIGHBORHOODS';

function requestPrintNeighborhoods() {
  return {
    type: REQUEST_PRINT_NEIGHBORHOODS
  };
}

function receivePrintNeighborhoods(neighborhoods) {
  return {
    type: SUCCESS_PRINT_NEIGHBORHOODS,
    receivedAt: Date.now(),
    neighborhoods: normalizeDatos(neighborhoods)
  };
}

function errorPrintNeighborhoods(error) {
  return {
    type: ERROR_PRINT_NEIGHBORHOODS,
    error: error
  };
}

export function resetPrintNeighborhoods() {
  return {
    type: RESET_PRINT_NEIGHBORHOODS
  };
}

export function savePrintNeighborhoods() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintNeighborhood());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let neighborhoods = getState().neighborhoods.print.printers.map(
      idNeighborhood => {
        return denormalizeDato(
          getState().neighborhoods.print.print[idNeighborhood],
          store
        );
      }
    );
    return neighborhoodsApi
      .printNeighborhoods(neighborhoods)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintNeighborhoods(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintNeighborhoods(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintNeighborhoods(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintNeighborhoods(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(
                    errorPrintNeighborhoods(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}
