import { useSelector } from 'react-redux';
import React from 'react';
import { Box, Chip } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import HomeIcon from '@material-ui/icons/Home';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import BedOutlinedIcon from '@material-ui/icons/KingBed';
import PoolOutlinedIcon from '@material-ui/icons/PoolOutlined';

const menus = [
  {
    name: 'Ubicación',
    query: ['city', 'state', 'country', 'neighborhood'],
    icon: <LocationOnIcon />
  },
  {
    name: 'Operación',
    query: ['codOperationType'],
    icon: <LocalOfferIcon />
  },
  { name: 'Tipo', query: ['idsPropertyType'], icon: <HomeIcon /> },
  {
    name: 'Precio',
    query: ['codCurrency', 'priceFrom', 'priceTo'],
    icon: <MonetizationOnIcon />
  },
  { name: 'Dormitorios', query: ['beds'], icon: <BedOutlinedIcon /> },
  { name: 'Amenities', query: ['amenities'], icon: <PoolOutlinedIcon /> }
];

export default function Interests(props) {
  const { ticket } = props;
  const countries = useSelector(state => state.countries);
  const neighborhoods = useSelector(state => state.neighborhoods);
  const states = useSelector(state => state.states);
  const locations = useSelector(state => state.locations);
  const propertyTypes = useSelector(state => state.propertyTypes);

  const getElement = (key, value) => {
    switch (key) {
      case 'neighborhood':
        return (
          <Chip
            label={`Barrio: ${value.reduce((accumulator, currentValue) => {
              let neighborhood = neighborhoods.byId.neighborhoods[currentValue];
              return accumulator + neighborhood?.desLocation + ' ';
            }, '')}`}
            size={'small'}
            icon={<LocationOnIcon />}
            variant={'outlined'}
          />
        );
      case 'city':
        return (
          <Chip
            label={`Ciudad: ${value.reduce((accumulator, currentValue) => {
              let city = locations.byId.locations[currentValue];
              return accumulator + city?.desLocation + ' ';
            }, '')}`}
            size={'small'}
            icon={<LocationOnIcon />}
            variant={'outlined'}
          />
        );
      case 'state':
        return (
          <Chip
            label={`Estado/Provincia: ${value.reduce(
              (accumulator, currentValue) => {
                let state = states.byId.states[currentValue];
                return accumulator + state?.desState + ' ';
              },
              ''
            )}`}
            size={'small'}
            icon={<LocationOnIcon />}
            variant={'outlined'}
          />
        );
      case 'country':
        return (
          <Chip
            label={`País: ${value.reduce((accumulator, currentValue) => {
              let country = countries.byId.countries[currentValue];
              return accumulator + country?.desCountry + ' ';
            }, '')}`}
            size={'small'}
            icon={<LocationOnIcon />}
            variant={'outlined'}
          />
        );
      case 'beds':
        return (
          <Chip
            label={`Dormitorios: ${value}`}
            size={'small'}
            icon={<BedOutlinedIcon />}
            variant={'outlined'}
          />
        );
      case 'codCurrency':
        return (
          <Chip
            label={`Moneda: ${value}`}
            size={'small'}
            icon={<MonetizationOnIcon />}
            variant={'outlined'}
          />
        );
      case 'priceTo':
        return (
          <Chip
            label={`Hasta: ${value}`}
            size={'small'}
            icon={<MonetizationOnIcon />}
            variant={'outlined'}
          />
        );
      case 'priceFrom':
        return (
          <Chip
            label={`Desde: ${value}`}
            size={'small'}
            icon={<MonetizationOnIcon />}
            variant={'outlined'}
          />
        );
      case 'idsPropertyType':
        return (
          <Chip
            label={`Tipo: ${value.reduce(
              (accumulator, currentValue) =>
                accumulator +
                propertyTypes.byId.propertyTypes[currentValue]?.nameType +
                ' ',
              ''
            )}`}
            size={'small'}
            icon={<LocalOfferIcon />}
            variant={'outlined'}
          />
        );
      case 'codOperationType':
        return (
          <Chip
            label={`Operación: ${value === 'V' ? 'Venta' : 'Alquiler'}`}
            size={'small'}
            icon={<MonetizationOnIcon />}
            variant={'outlined'}
          />
        );
    }
  };
  return (
    <Box
      width={'100%'}
      marginTop={'15px'}
      justifyContent={'flex-end'}
      display={'flex'}
    >
      <Box
        width={'48%'}
        display={'flex'}
        justifyContent={'start'}
        flexWrap={'wrap'}
        gridRowGap={'5px'}
        gridColumnGap={'5px'}
      >
        {ticket?.query &&
          Object.keys(ticket?.query).map(key =>
            getElement(key, ticket?.query[key])
          )}
      </Box>
    </Box>
  );
}
