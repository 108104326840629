import { normalize, schema, denormalize } from 'normalizr';

const currency = new schema.Entity('currencies', {}, { idAttribute: 'cod' });

export function normalizeDatos(myData) {
  const mySchema = { currencies: [currency] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = currency;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = currency;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default currency;
