//api
import assetsApi from '../api/assetsApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizeAssets';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//ASSETS
export const REQUEST_ASSETS = 'REQUEST_ASSETS';
export const RECEIVE_ASSETS = 'RECEIVE_ASSETS';
export const INVALIDATE_ASSETS = 'INVALIDATE_ASSETS';
export const ERROR_ASSETS = 'ERROR_ASSETS';
export const RESET_ASSETS = 'RESET_ASSETS';

export function invalidateAssets() {
  return {
    type: INVALIDATE_ASSETS
  };
}

function requestAssets() {
  return {
    type: REQUEST_ASSETS
  };
}

function receiveAssets(json) {
  return {
    type: RECEIVE_ASSETS,
    assets: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorAssets(error) {
  return {
    type: ERROR_ASSETS,
    error: error
  };
}

export function resetAssets() {
  return {
    type: RESET_ASSETS
  };
}

export function fetchAssets(filtros) {
  return dispatch => {
    dispatch(requestAssets());
    return assetsApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveAssets(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorAssets(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorAssets(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

function shouldFetchAssets(state) {
  const assets = state.assets.byId;
  if (!assets) {
    return true;
  } else if (assets.isFetching) {
    return false;
  } else {
    return assets.didInvalidate;
  }
}

export function fetchAssetsIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchAssets(getState())) {
      return dispatch(fetchAssets(filtros));
    }
  };
}

//MODEL
export const REQUEST_ASSET = 'REQUEST_ASSET';
export const RECEIVE_ASSET = 'RECEIVE_ASSET';
export const INVALIDATE_ASSET = 'INVALIDATE_ASSET';
export const ERROR_ASSET = 'ERROR_ASSET';
export const RESET_ASSET = 'RESET_ASSET';

export function invalidateAsset() {
  return {
    type: INVALIDATE_ASSET
  };
}

function requestAsset() {
  return {
    type: REQUEST_ASSET
  };
}

export function receiveAsset(json) {
  return {
    type: RECEIVE_ASSET,
    asset: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorAsset(error) {
  return {
    type: ERROR_ASSET,
    error: error
  };
}

export function fetchAsset(idAsset) {
  return dispatch => {
    dispatch(requestAsset());
    return assetsApi
      .getOne(idAsset)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveAsset(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(errorAsset(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorAsset(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_ASSET = 'RECEIVE_FILE_ASSET';

function receiveFileAsset(file) {
  return {
    type: RECEIVE_FILE_ASSET,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFileAsset(idAsset, filtros) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return assetsApi
      .getFile(idAsset, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFileAsset(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorAsset(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorAsset(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_ASSET = 'UPDATE_ASSET';
export const REQUEST_UPDATE_ASSET = 'REQUEST_UPDATE_ASSET';
export const SUCCESS_UPDATE_ASSET = 'SUCCESS_UPDATE_ASSET';
export const ERROR_UPDATE_ASSET = 'ERROR_UPDATE_ASSET';
export const RESET_UPDATE_ASSET = 'RESET_UPDATE_ASSET';
export const DELETE_UPDATE_ASSET = 'DELETE_UPDATE_ASSET';

function requestUpdateAsset() {
  return {
    type: REQUEST_UPDATE_ASSET
  };
}

function receiveUpdateAsset(asset) {
  return {
    type: SUCCESS_UPDATE_ASSET,
    receivedAt: Date.now(),
    asset: normalizeDato(asset)
  };
}

function errorUpdateAsset(error) {
  return {
    type: ERROR_UPDATE_ASSET,
    error: error
  };
}

export function resetUpdateAsset() {
  return {
    type: RESET_UPDATE_ASSET
  };
}

export function updateAsset(asset) {
  return {
    type: UPDATE_ASSET,
    asset
  };
}

export function saveUpdateAsset(files) {
  return (dispatch, getState) => {
    dispatch(requestUpdateAsset());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let asset = denormalizeDato(getState().assets.update.activo, store);

    return assetsApi
      .saveUpdate(asset, files)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateAsset(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateAsset(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateAssets(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdateAssets(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateAsset(JSON.parse(error.message)));
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdateAsset(error.data));
                })
                .catch(() => {
                  dispatch(errorUpdateAsset(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

export function deleteUpdateAsset(asset) {
  return {
    type: DELETE_UPDATE_ASSET,
    asset
  };
}

//UPDATE ASSETS
export const REQUEST_UPDATE_ASSETS = 'REQUEST_UPDATE_ASSETS';
export const SUCCESS_UPDATE_ASSETS = 'SUCCESS_UPDATE_ASSETS';
export const ERROR_UPDATE_ASSETS = 'ERROR_UPDATE_ASSETS';
export const RESET_UPDATE_ASSETS = 'RESET_UPDATE_ASSETS';

function requestUpdateAssets() {
  return {
    type: REQUEST_UPDATE_ASSETS
  };
}

function receiveUpdateAssets(assets) {
  return {
    type: SUCCESS_UPDATE_ASSETS,
    receivedAt: Date.now(),
    assets: normalizeDatos(assets)
  };
}

function errorUpdateAssets(error) {
  return {
    type: ERROR_UPDATE_ASSETS,
    error: error
  };
}

export function resetUpdateAssets() {
  return {
    type: RESET_UPDATE_ASSETS
  };
}

export function saveUpdateAssets() {
  return (dispatch, getState) => {
    dispatch(requestUpdateAssets());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let assets = getState().assets.update.activos.map(idAsset => {
      return denormalizeDato(getState().assets.update.activo[idAsset], store);
    });

    return assetsApi
      .saveUpdateAssets(assets)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateAssets(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateAssets(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateAssets(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdateAssets(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateAssets(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorUpdateAssets(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//ALTA ASSET
export const CREATE_ASSET = 'CREATE_ASSET';
export const REQUEST_CREATE_ASSET = 'REQUEST_CREATE_ASSET';
export const SUCCESS_CREATE_ASSET = 'SUCCESS_CREATE_ASSET';
export const ERROR_CREATE_ASSET = 'ERROR_CREATE_ASSET';
export const RESET_CREATE_ASSET = 'RESET_CREATE_ASSET';
export const DELETE_CREATE_ASSET = 'DELETE_CREATE_ASSET';

//ALTA ASSET
function requestCreateAsset() {
  return {
    type: REQUEST_CREATE_ASSET
  };
}

function receiveCreateAsset(asset) {
  return {
    type: SUCCESS_CREATE_ASSET,
    receivedAt: Date.now(),
    asset: normalizeDato(asset)
  };
}

export function errorCreateAsset(error) {
  return {
    type: ERROR_CREATE_ASSET,
    error: error
  };
}

export function resetCreateAsset() {
  return {
    type: RESET_CREATE_ASSET
  };
}

export function createAsset(asset) {
  return {
    type: CREATE_ASSET,
    asset
  };
}

export function deleteCreateAsset(asset) {
  return {
    type: DELETE_CREATE_ASSET,
    asset
  };
}

export function saveCreateAsset(files) {
  return (dispatch, getState) => {
    dispatch(requestCreateAsset());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let asset = denormalizeDato(getState().assets.create.nuevo, store);

    return assetsApi
      .saveCreate(asset, files)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateAsset(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateAsset(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateAssets(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreateAssets(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateAsset(JSON.parse(error.message)));
                  if (error.data) dispatch(receiveCreateAsset(error.data));
                })
                .catch(() => {
                  dispatch(errorCreateAsset(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//CREATE ASSETS
export const REQUEST_CREATE_ASSETS = 'REQUEST_CREATE_ASSETS';
export const SUCCESS_CREATE_ASSETS = 'SUCCESS_CREATE_ASSETS';
export const ERROR_CREATE_ASSETS = 'ERROR_CREATE_ASSETS';
export const RESET_CREATE_ASSETS = 'RESET_CREATE_ASSETS';

function requestCreateAssets() {
  return {
    type: REQUEST_CREATE_ASSETS
  };
}

function receiveCreateAssets(assets) {
  return {
    type: SUCCESS_CREATE_ASSETS,
    receivedAt: Date.now(),
    assets: normalizeDatos(assets)
  };
}

function errorCreateAssets(error) {
  return {
    type: ERROR_CREATE_ASSETS,
    error: error
  };
}

export function resetCreateAssets() {
  return {
    type: RESET_CREATE_ASSETS
  };
}

export function saveCreateAssets() {
  return (dispatch, getState) => {
    dispatch(requestCreateAssets());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let assets = getState().assets.create.nuevos.map(idAsset => {
      return denormalizeDato(getState().assets.create.nuevo[idAsset], store);
    });

    return assetsApi
      .saveCreateAssets(assets)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateAssets(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateAssets(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateAssets(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreateAssets(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateAssets(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorCreateAssets(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//DELETE ASSET
export const DELETE_ASSET = 'DELETE_ASSET';
export const REQUEST_DELETE_ASSET = 'REQUEST_DELETE_ASSET';
export const SUCCESS_DELETE_ASSET = 'SUCCESS_DELETE_ASSET';
export const ERROR_DELETE_ASSET = 'ERROR_DELETE_ASSET';
export const RESET_DELETE_ASSET = 'RESET_DELETE_ASSET';

function requestDeleteAsset() {
  return {
    type: REQUEST_DELETE_ASSET
  };
}

function receiveDeleteAsset(asset) {
  return {
    type: SUCCESS_DELETE_ASSET,
    receivedAt: Date.now(),
    asset: normalizeDato(asset)
  };
}

function errorDeleteAsset(error) {
  return {
    type: ERROR_DELETE_ASSET,
    error: error
  };
}

export function resetDeleteAsset(error) {
  return {
    type: RESET_DELETE_ASSET,
    error: error
  };
}

export function deleteAsset(asset) {
  return {
    type: DELETE_ASSET,
    asset
  };
}

export function saveDeleteAsset(asset) {
  return dispatch => {
    dispatch(requestDeleteAsset());
    return assetsApi
      .saveDelete(asset)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeleteAsset());
        dispatch(receiveDeleteAsset(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorDeleteAsset(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeleteAsset(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorDeleteAsset(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorDeleteAsset(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorDeleteAsset(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT ASSET
export const PRINT_ASSET = 'PRINT_ASSET';
export const REQUEST_PRINT_ASSET = 'REQUEST_PRINT_ASSET';
export const SUCCESS_PRINT_ASSET = 'SUCCESS_PRINT_ASSET';
export const ERROR_PRINT_ASSET = 'ERROR_PRINT_ASSET';
export const RESET_PRINT_ASSET = 'RESET_PRINT_ASSET';
export const DELETE_PRINT_ASSET = 'DELETE_PRINT_ASSET';

function requestPrintAsset() {
  return {
    type: REQUEST_PRINT_ASSET
  };
}

function receivePrintAsset(turnos) {
  return {
    type: SUCCESS_PRINT_ASSET,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintAsset(error) {
  return {
    type: ERROR_PRINT_ASSET,
    error: error
  };
}

export function resetPrintAsset() {
  return {
    type: RESET_PRINT_ASSET
  };
}

export function printAsset(asset) {
  return {
    type: PRINT_ASSET,
    asset
  };
}

export function deletePrintAsset(asset) {
  return {
    type: DELETE_PRINT_ASSET,
    asset
  };
}

export function savePrintAsset(idAsset) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintAsset());
    return assetsApi
      .printAsset(idAsset)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintAsset(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintAsset(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(errorPrintAsset(JSON.parse(error.responseJSON.message)));
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintAsset(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintAsset(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT ASSETS
export const REQUEST_PRINT_ASSETS = 'REQUEST_PRINT_ASSETS';
export const SUCCESS_PRINT_ASSETS = 'SUCCESS_PRINT_ASSETS';
export const ERROR_PRINT_ASSETS = 'ERROR_PRINT_ASSETS';
export const RESET_PRINT_ASSETS = 'RESET_PRINT_ASSETS';

function requestPrintAssets() {
  return {
    type: REQUEST_PRINT_ASSETS
  };
}

function receivePrintAssets(assets) {
  return {
    type: SUCCESS_PRINT_ASSETS,
    receivedAt: Date.now(),
    assets: normalizeDatos(assets)
  };
}

function errorPrintAssets(error) {
  return {
    type: ERROR_PRINT_ASSETS,
    error: error
  };
}

export function resetPrintAssets() {
  return {
    type: RESET_PRINT_ASSETS
  };
}

export function savePrintAssets() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintAsset());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let assets = getState().assets.print.printers.map(idAsset => {
      return denormalizeDato(getState().assets.print.print[idAsset], store);
    });
    return assetsApi
      .printAssets(assets)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintAssets(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintAssets(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintAssets(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintAssets(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintAssets(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}
