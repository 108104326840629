import {
  resetAttributesGroups,
  resetCreateAttributeGroup,
  resetUpdateAttributeGroup,
  resetDeleteAttributeGroup
} from '../actions/AttributeGroupActions';
import {
  resetProperties,
  resetCreateProperty,
  resetUpdateProperty,
  resetDeleteProperty
} from '../actions/PropertyActions';

var resetAttributeGroup = {
  resetAll(dispatch) {
    dispatch(resetAttributesGroups());
    dispatch(resetCreateAttributeGroup());
    dispatch(resetUpdateAttributeGroup());
    dispatch(resetDeleteAttributeGroup());
    dispatch(resetProperties());
    dispatch(resetCreateProperty());
    dispatch(resetUpdateProperty());
    dispatch(resetDeleteProperty());
  }
};

export default resetAttributeGroup;
