import {
  INVALIDATE_TICKETSTATUSES,
  ERROR_TICKETSTATUSES,
  RECEIVE_TICKETSTATUSES,
  REQUEST_TICKETSTATUSES,
  RESET_TICKETSTATUSES,
  ERROR_TICKETSTATUS,
  RECEIVE_TICKETSTATUS,
  REQUEST_TICKETSTATUS,
  UPDATE_TICKETSTATUS,
  REQUEST_UPDATE_TICKETSTATUS,
  SUCCESS_UPDATE_TICKETSTATUS,
  ERROR_UPDATE_TICKETSTATUS,
  RESET_UPDATE_TICKETSTATUS,
  REQUEST_UPDATE_TICKETSTATUSES,
  SUCCESS_UPDATE_TICKETSTATUSES,
  ERROR_UPDATE_TICKETSTATUSES,
  RESET_UPDATE_TICKETSTATUSES,
  CREATE_TICKETSTATUS,
  ERROR_CREATE_TICKETSTATUS,
  REQUEST_CREATE_TICKETSTATUS,
  RESET_CREATE_TICKETSTATUS,
  SUCCESS_CREATE_TICKETSTATUS,
  REQUEST_CREATE_TICKETSTATUSES,
  SUCCESS_CREATE_TICKETSTATUSES,
  ERROR_CREATE_TICKETSTATUSES,
  RESET_CREATE_TICKETSTATUSES,
  DELETE_TICKETSTATUS,
  DELETE_CREATE_TICKETSTATUS,
  DELETE_UPDATE_TICKETSTATUS,
  REQUEST_DELETE_TICKETSTATUS,
  SUCCESS_DELETE_TICKETSTATUS,
  ERROR_DELETE_TICKETSTATUS,
  RESET_DELETE_TICKETSTATUS,
  REQUEST_PRINT_TICKETSTATUS,
  SUCCESS_PRINT_TICKETSTATUS,
  ERROR_PRINT_TICKETSTATUS,
  RESET_PRINT_TICKETSTATUS,
  RECEIVE_FILE_TICKETSTATUS,
  REQUEST_PRINT_TICKETSTATUSES,
  SUCCESS_PRINT_TICKETSTATUSES,
  ERROR_PRINT_TICKETSTATUSES,
  RESET_PRINT_TICKETSTATUSES,
  RECEIVE_FILE_TICKETSTATUSES,
  PRINT_TICKETSTATUS,
  DELETE_PRINT_TICKETSTATUS
} from '../actions/TicketStatusActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    ticketStatuses: {},
    files: {}
  };
}

function ticketStatusesById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_TICKETSTATUSES:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_TICKETSTATUSES:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_TICKETSTATUSES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_TICKETSTATUSES:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        ticketStatuses: {}
      });
    case RECEIVE_TICKETSTATUSES:
      let dato = action.ticketStatuses.entities.ticketStatuses;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        ticketStatuses: merge({}, state.ticketStatuses, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_TICKETSTATUS:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_TICKETSTATUS:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_TICKETSTATUS:
      let datoTicketStatus = action.ticketStatus.entities.ticketStatuses;
      return Object.assign({}, state, {
        ticketStatuses: merge({}, state.ticketStatuses, datoTicketStatus),
        isFetching: false
      });
    case RECEIVE_FILE_TICKETSTATUS:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_TICKETSTATUS:
      let datoTicketStatusEliminado =
        action.ticketStatus.entities.ticketStatuses;
      return Object.assign({}, state, {
        ticketStatuses: mergeWith(
          clone(datoTicketStatusEliminado),
          state.ticketStatuses,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_TICKETSTATUS:
      let datoTicketStatusCreado = action.ticketStatus.entities.ticketStatuses;
      return Object.assign({}, state, {
        ticketStatuses: mergeWith(
          clone(datoTicketStatusCreado),
          state.ticketStatuses,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_TICKETSTATUSES:
      let datosTicketStatusCreado =
        action.ticketStatuses.entities.ticketStatuses;
      return Object.assign({}, state, {
        ticketStatuses: mergeWith(
          clone(datosTicketStatusCreado),
          state.ticketStatuses,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TICKETSTATUS:
      let datoTicketStatusActualizado =
        action.ticketStatus.entities.ticketStatuses;
      return Object.assign({}, state, {
        ticketStatuses: mergeWith(
          clone(datoTicketStatusActualizado),
          state.ticketStatuses,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TICKETSTATUSES:
      let datosTicketStatusActualizado =
        action.ticketStatuses.entities.ticketStatuses;
      return Object.assign({}, state, {
        ticketStatuses: mergeWith(
          clone(datosTicketStatusActualizado),
          state.ticketStatuses,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        ticketStatuses: {}
      });
    default:
      return state;
  }
}

function allTicketStatuses(state = [], action) {
  switch (action.type) {
    case RECEIVE_TICKETSTATUSES:
      return action.ticketStatuses.result &&
        action.ticketStatuses.result.ticketStatuses
        ? union(action.ticketStatuses.result.ticketStatuses, state)
        : action.ticketStatuses.result
        ? action.ticketStatuses.result
        : state;
    case RECEIVE_TICKETSTATUS:
      return action.ticketStatus.result
        ? union([action.ticketStatus.result], state)
        : state;

    case SUCCESS_CREATE_TICKETSTATUS:
      let datoTicketStatusSCreate = action.ticketStatus.entities.ticketStatuses;
      let idNuevoSCreate = null;
      if (Object.values(datoTicketStatusSCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoTicketStatusSCreate)[0] &&
          Object.values(datoTicketStatusSCreate)[0].id
            ? Object.values(datoTicketStatusSCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_TICKETSTATUSES:
      let ticketStatusesCreate =
        action.ticketStatuses.entities &&
        action.ticketStatuses.entities.ticketStatuses
          ? action.ticketStatuses.entities.ticketStatuses
          : null;
      return ticketStatusesCreate
        ? union(
            state,
            Object.values(ticketStatusesCreate).map(ticketStatuses => {
              return ticketStatuses.id;
            })
          )
        : state;
    case RESET_TICKETSTATUSES:
      return [];

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalTicketStatuses(state = null, action) {
  switch (action.type) {
    case RECEIVE_TICKETSTATUSES:
      return action.ticketStatuses && action.ticketStatuses.result.total
        ? action.ticketStatuses.result.total
        : 0;
    case RESET_TICKETSTATUSES:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_TICKETSTATUS:
      let dato = action.ticketStatus.entities.ticketStatuses;
      let ticketStatus =
        dato && Object.keys(dato).length > 0
          ? dato[action.ticketStatus.result]
          : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: ticketStatus ? ticketStatus : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_TICKETSTATUS:
      let idsUpdate = [];
      Object.values(action.ticketStatus).map(ticketStatusUpdate => {
        if (ticketStatusUpdate && ticketStatusUpdate.id)
          idsUpdate.push(ticketStatusUpdate.id);
      });
      return merge({}, state, {
        activo: action.ticketStatus,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_TICKETSTATUS:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_TICKETSTATUS:
      let datoTicketStatusActualizado =
        action.ticketStatus.entities.ticketStatuses;
      let ticketStatusNuevo =
        datoTicketStatusActualizado &&
        Object.keys(datoTicketStatusActualizado).length > 0
          ? datoTicketStatusActualizado[action.ticketStatus.result]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: ticketStatusNuevo
      });
    case ERROR_UPDATE_TICKETSTATUS:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_TICKETSTATUSES:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_TICKETSTATUSES:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_TICKETSTATUSES:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_TICKETSTATUS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    case DELETE_TICKETSTATUS:
      let datoTicketStatusDelete = action.ticketStatus;
      let idsDelete = [];
      Object.values(action.ticketStatus).map(ticketStatusDelete => {
        if (ticketStatusDelete && ticketStatusDelete.id)
          idsDelete.push(ticketStatusDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoTicketStatusDelete)
          ),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_TICKETSTATUS:
      let datoTicketStatusDeleteUpdate = action.ticketStatus;
      let idsDeleteUpdate = [];
      Object.values(action.ticketStatus).map(ticketStatusDelete => {
        if (ticketStatusDelete && ticketStatusDelete.id)
          idsDeleteUpdate.push(ticketStatusDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoTicketStatusDeleteUpdate)
          ),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_TICKETSTATUS:
      let datoTicketStatusDeleted = {};
      if (Object.values(action.ticketStatus.entities.ticketStatuses).length > 0)
        datoTicketStatusDeleted = Object.values(
          action.ticketStatus.entities.ticketStatuses
        )[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoTicketStatusDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_TICKETSTATUS:
      let idsCreate = [];
      Object.values(action.ticketStatus).map(ticketStatusCreate => {
        if (ticketStatusCreate && ticketStatusCreate.id)
          idsCreate.push(ticketStatusCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.ticketStatus,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_TICKETSTATUS:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_TICKETSTATUS:
      let datoTicketStatusNuevo = action.ticketStatus.entities.ticketStatuses;
      let ticketStatusNuevo =
        datoTicketStatusNuevo && Object.keys(datoTicketStatusNuevo).length > 0
          ? datoTicketStatusNuevo[action.ticketStatus.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: ticketStatusNuevo,
        nuevos: []
      });
    case ERROR_CREATE_TICKETSTATUS:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_TICKETSTATUSES:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_TICKETSTATUSES:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_TICKETSTATUSES:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_TICKETSTATUS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    case DELETE_TICKETSTATUS:
      let datoTicketStatusDelete = action.ticketStatus;
      let idsDelete = [];
      Object.values(action.ticketStatus).map(ticketStatusDelete => {
        if (ticketStatusDelete && ticketStatusDelete.id)
          idsDelete.push(ticketStatusDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(clone(state.nuevo), Object.keys(datoTicketStatusDelete)),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_TICKETSTATUS:
      let datoTicketStatusDeleteCreate = action.ticketStatus;
      let idsDeleteCreate = [];
      Object.values(action.ticketStatus).map(ticketStatusDelete => {
        if (ticketStatusDelete && ticketStatusDelete.id)
          idsDeleteCreate.push(ticketStatusDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoTicketStatusDeleteCreate)
          ),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_TICKETSTATUS:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.ticketStatus,
        error: null
      });
    case REQUEST_DELETE_TICKETSTATUS:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_TICKETSTATUS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_TICKETSTATUS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_TICKETSTATUS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_TICKETSTATUS:
      let idsCreate = [];
      Object.values(action.ticketStatus).map(ticketStatusCreate => {
        if (ticketStatusCreate && ticketStatusCreate.id)
          idsCreate.push(ticketStatusCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.ticketStatus,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_TICKETSTATUS:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_TICKETSTATUS:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_TICKETSTATUS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_TICKETSTATUSES:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_TICKETSTATUSES:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_TICKETSTATUSES:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_TICKETSTATUS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_TICKETSTATUS:
      let datoTicketStatusDeleteCreate = action.ticketStatus;
      let idsDeleteCreate = [];
      Object.values(action.ticketStatus).map(ticketStatusDelete => {
        if (ticketStatusDelete && ticketStatusDelete.id)
          idsDeleteCreate.push(ticketStatusDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(
            clone(state.print),
            Object.keys(datoTicketStatusDeleteCreate)
          ),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const ticketStatuses = combineReducers({
  byId: ticketStatusesById,
  allIds: allTicketStatuses,
  update: update,
  create: create,
  totalTicketStatuses: totalTicketStatuses,
  delete: deleter,
  print: print
});

export default ticketStatuses;
