import Pagination from '@material-ui/lab/Pagination';
import PaperFooter from '../../library/CustomPaper/PaperFooter';
import React from 'react';

export default function PropertiesPaginationList(props) {
  const { totalProperties, handleChangePage, page } = props;
  const totalPaginas = Math.ceil(totalProperties ? totalProperties / 50 : 0);
  return (
    <PaperFooter style={{ justifyContent: 'center' }}>
      {!!totalPaginas && totalPaginas > 1 && (
        <Pagination
          count={totalPaginas}
          page={page ? parseInt(page) : 1}
          color="primary"
          onChange={handleChangePage}
          size={'small'}
        />
      )}
    </PaperFooter>
  );
}
