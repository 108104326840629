//api
import ticketPropertiesApi from '../api/ticketPropertiesApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizeTicketProperties';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//TICKETPROPERTIES
export const REQUEST_TICKETPROPERTIES = 'REQUEST_TICKETPROPERTIES';
export const RECEIVE_TICKETPROPERTIES = 'RECEIVE_TICKETPROPERTIES';
export const INVALIDATE_TICKETPROPERTIES = 'INVALIDATE_TICKETPROPERTIES';
export const ERROR_TICKETPROPERTIES = 'ERROR_TICKETPROPERTIES';
export const RESET_TICKETPROPERTIES = 'RESET_TICKETPROPERTIES';

export function invalidateTicketProperties() {
  return {
    type: INVALIDATE_TICKETPROPERTIES
  };
}

function requestTicketProperties() {
  return {
    type: REQUEST_TICKETPROPERTIES
  };
}

function receiveTicketProperties(json) {
  return {
    type: RECEIVE_TICKETPROPERTIES,
    ticketProperties: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorTicketProperties(error) {
  return {
    type: ERROR_TICKETPROPERTIES,
    error: error
  };
}

export function resetTicketProperties() {
  return {
    type: RESET_TICKETPROPERTIES
  };
}

export function fetchTicketProperties(filtros) {
  return dispatch => {
    dispatch(requestTicketProperties());
    return ticketPropertiesApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveTicketProperties(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorTicketProperties(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorTicketProperties(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

function shouldFetchTicketProperties(state) {
  const ticketProperties = state.ticketProperties.byId;
  if (!ticketProperties) {
    return true;
  } else if (ticketProperties.isFetching) {
    return false;
  } else {
    return ticketProperties.didInvalidate;
  }
}

export function fetchTicketPropertiesIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchTicketProperties(getState())) {
      return dispatch(fetchTicketProperties(filtros));
    }
  };
}

//MODEL
export const REQUEST_TICKETPROPERTY = 'REQUEST_TICKETPROPERTY';
export const RECEIVE_TICKETPROPERTY = 'RECEIVE_TICKETPROPERTY';
export const INVALIDATE_TICKETPROPERTY = 'INVALIDATE_TICKETPROPERTY';
export const ERROR_TICKETPROPERTY = 'ERROR_TICKETPROPERTY';
export const RESET_TICKETPROPERTY = 'RESET_TICKETPROPERTY';

export function invalidateTicketProperty() {
  return {
    type: INVALIDATE_TICKETPROPERTY
  };
}

function requestTicketProperty() {
  return {
    type: REQUEST_TICKETPROPERTY
  };
}

export function receiveTicketProperty(json) {
  return {
    type: RECEIVE_TICKETPROPERTY,
    ticketProperty: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorTicketProperty(error) {
  return {
    type: ERROR_TICKETPROPERTY,
    error: error
  };
}

export function fetchTicketProperty(idTicketProperty) {
  return dispatch => {
    dispatch(requestTicketProperty());
    return ticketPropertiesApi
      .getOne(idTicketProperty)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveTicketProperty(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(errorTicketProperty(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorTicketProperty(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_TICKETPROPERTY = 'RECEIVE_FILE_TICKETPROPERTY';

function receiveFileTicketProperty(file) {
  return {
    type: RECEIVE_FILE_TICKETPROPERTY,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFileTicketProperty(idTicketProperty, filtros) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return ticketPropertiesApi
      .getFile(idTicketProperty, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFileTicketProperty(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorTicketProperty(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorTicketProperty(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_TICKETPROPERTY = 'UPDATE_TICKETPROPERTY';
export const REQUEST_UPDATE_TICKETPROPERTY = 'REQUEST_UPDATE_TICKETPROPERTY';
export const SUCCESS_UPDATE_TICKETPROPERTY = 'SUCCESS_UPDATE_TICKETPROPERTY';
export const ERROR_UPDATE_TICKETPROPERTY = 'ERROR_UPDATE_TICKETPROPERTY';
export const RESET_UPDATE_TICKETPROPERTY = 'RESET_UPDATE_TICKETPROPERTY';
export const DELETE_UPDATE_TICKETPROPERTY = 'DELETE_UPDATE_TICKETPROPERTY';

function requestUpdateTicketProperty() {
  return {
    type: REQUEST_UPDATE_TICKETPROPERTY
  };
}

function receiveUpdateTicketProperty(ticketProperty) {
  return {
    type: SUCCESS_UPDATE_TICKETPROPERTY,
    receivedAt: Date.now(),
    ticketProperty: normalizeDato(ticketProperty)
  };
}

function errorUpdateTicketProperty(error) {
  return {
    type: ERROR_UPDATE_TICKETPROPERTY,
    error: error
  };
}

export function resetUpdateTicketProperty() {
  return {
    type: RESET_UPDATE_TICKETPROPERTY
  };
}

export function updateTicketProperty(ticketProperty) {
  return {
    type: UPDATE_TICKETPROPERTY,
    ticketProperty
  };
}

export function saveUpdateTicketProperty() {
  return (dispatch, getState) => {
    dispatch(requestUpdateTicketProperty());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let ticketProperty = denormalizeDato(
      getState().ticketProperties.update.activo,
      store
    );

    return ticketPropertiesApi
      .saveUpdate(ticketProperty)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateTicketProperty(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorUpdateTicketProperty(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateTicketProperties(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorUpdateTicketProperties(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorUpdateTicketProperty(JSON.parse(error.message))
                  );
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdateTicketProperty(error.data));
                })
                .catch(() => {
                  dispatch(
                    errorUpdateTicketProperty(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

export function deleteUpdateTicketProperty(ticketProperty) {
  return {
    type: DELETE_UPDATE_TICKETPROPERTY,
    ticketProperty
  };
}

//UPDATE TICKETPROPERTIES
export const REQUEST_UPDATE_TICKETPROPERTIES =
  'REQUEST_UPDATE_TICKETPROPERTIES';
export const SUCCESS_UPDATE_TICKETPROPERTIES =
  'SUCCESS_UPDATE_TICKETPROPERTIES';
export const ERROR_UPDATE_TICKETPROPERTIES = 'ERROR_UPDATE_TICKETPROPERTIES';
export const RESET_UPDATE_TICKETPROPERTIES = 'RESET_UPDATE_TICKETPROPERTIES';

function requestUpdateTicketProperties() {
  return {
    type: REQUEST_UPDATE_TICKETPROPERTIES
  };
}

function receiveUpdateTicketProperties(ticketProperties) {
  return {
    type: SUCCESS_UPDATE_TICKETPROPERTIES,
    receivedAt: Date.now(),
    ticketProperties: normalizeDatos(ticketProperties)
  };
}

function errorUpdateTicketProperties(error) {
  return {
    type: ERROR_UPDATE_TICKETPROPERTIES,
    error: error
  };
}

export function resetUpdateTicketProperties() {
  return {
    type: RESET_UPDATE_TICKETPROPERTIES
  };
}

export function saveUpdateTicketProperties() {
  return (dispatch, getState) => {
    dispatch(requestUpdateTicketProperties());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let ticketProperties = getState().ticketProperties.update.activos.map(
      idTicketProperty => {
        return denormalizeDato(
          getState().ticketProperties.update.activo[idTicketProperty],
          store
        );
      }
    );

    return ticketPropertiesApi
      .saveUpdateTicketProperties(ticketProperties)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateTicketProperties(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorUpdateTicketProperties(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateTicketProperties(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorUpdateTicketProperties(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorUpdateTicketProperties(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorUpdateTicketProperties(errorMessages.GENERAL_ERROR)
                  );
                });

            return;
        }
      });
  };
}

//ALTA TICKETPROPERTY
export const CREATE_TICKETPROPERTY = 'CREATE_TICKETPROPERTY';
export const REQUEST_CREATE_TICKETPROPERTY = 'REQUEST_CREATE_TICKETPROPERTY';
export const SUCCESS_CREATE_TICKETPROPERTY = 'SUCCESS_CREATE_TICKETPROPERTY';
export const ERROR_CREATE_TICKETPROPERTY = 'ERROR_CREATE_TICKETPROPERTY';
export const RESET_CREATE_TICKETPROPERTY = 'RESET_CREATE_TICKETPROPERTY';
export const DELETE_CREATE_TICKETPROPERTY = 'DELETE_CREATE_TICKETPROPERTY';

//ALTA TICKETPROPERTY
function requestCreateTicketProperty() {
  return {
    type: REQUEST_CREATE_TICKETPROPERTY
  };
}

function receiveCreateTicketProperty(ticketProperty) {
  return {
    type: SUCCESS_CREATE_TICKETPROPERTY,
    receivedAt: Date.now(),
    ticketProperty: normalizeDato(ticketProperty)
  };
}

export function errorCreateTicketProperty(error) {
  return {
    type: ERROR_CREATE_TICKETPROPERTY,
    error: error
  };
}

export function resetCreateTicketProperty() {
  return {
    type: RESET_CREATE_TICKETPROPERTY
  };
}

export function createTicketProperty(ticketProperty) {
  return {
    type: CREATE_TICKETPROPERTY,
    ticketProperty
  };
}

export function deleteCreateTicketProperty(ticketProperty) {
  return {
    type: DELETE_CREATE_TICKETPROPERTY,
    ticketProperty
  };
}

export function saveCreateTicketProperty() {
  return (dispatch, getState) => {
    dispatch(requestCreateTicketProperty());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let ticketProperty = denormalizeDato(
      getState().ticketProperties.create.nuevo,
      store
    );

    return ticketPropertiesApi
      .saveCreate(ticketProperty)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateTicketProperty(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorCreateTicketProperty(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateTicketProperties(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorCreateTicketProperties(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorCreateTicketProperty(JSON.parse(error.message))
                  );
                  if (error.data)
                    dispatch(receiveCreateTicketProperty(error.data));
                })
                .catch(() => {
                  dispatch(
                    errorCreateTicketProperty(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//CREATE TICKETPROPERTIES
export const REQUEST_CREATE_TICKETPROPERTIES =
  'REQUEST_CREATE_TICKETPROPERTIES';
export const SUCCESS_CREATE_TICKETPROPERTIES =
  'SUCCESS_CREATE_TICKETPROPERTIES';
export const ERROR_CREATE_TICKETPROPERTIES = 'ERROR_CREATE_TICKETPROPERTIES';
export const RESET_CREATE_TICKETPROPERTIES = 'RESET_CREATE_TICKETPROPERTIES';

function requestCreateTicketProperties() {
  return {
    type: REQUEST_CREATE_TICKETPROPERTIES
  };
}

function receiveCreateTicketProperties(ticketProperties) {
  return {
    type: SUCCESS_CREATE_TICKETPROPERTIES,
    receivedAt: Date.now(),
    ticketProperties: normalizeDatos(ticketProperties)
  };
}

function errorCreateTicketProperties(error) {
  return {
    type: ERROR_CREATE_TICKETPROPERTIES,
    error: error
  };
}

export function resetCreateTicketProperties() {
  return {
    type: RESET_CREATE_TICKETPROPERTIES
  };
}

export function saveCreateTicketProperties() {
  return (dispatch, getState) => {
    dispatch(requestCreateTicketProperties());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let ticketProperties = getState().ticketProperties.create.nuevos.map(
      idTicketProperty => {
        return denormalizeDato(
          getState().ticketProperties.create.nuevo[idTicketProperty],
          store
        );
      }
    );

    return ticketPropertiesApi
      .saveCreateTicketProperties(ticketProperties)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateTicketProperties(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorCreateTicketProperties(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateTicketProperties(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(
                  errorCreateTicketProperties(error.responseJSON.message)
                );
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorCreateTicketProperties(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorCreateTicketProperties(errorMessages.GENERAL_ERROR)
                  );
                });

            return;
        }
      });
  };
}

//DELETE TICKETPROPERTY
export const DELETE_TICKETPROPERTY = 'DELETE_TICKETPROPERTY';
export const REQUEST_DELETE_TICKETPROPERTY = 'REQUEST_DELETE_TICKETPROPERTY';
export const SUCCESS_DELETE_TICKETPROPERTY = 'SUCCESS_DELETE_TICKETPROPERTY';
export const ERROR_DELETE_TICKETPROPERTY = 'ERROR_DELETE_TICKETPROPERTY';
export const RESET_DELETE_TICKETPROPERTY = 'RESET_DELETE_TICKETPROPERTY';

function requestDeleteTicketProperty() {
  return {
    type: REQUEST_DELETE_TICKETPROPERTY
  };
}

function receiveDeleteTicketProperty(ticketProperty) {
  return {
    type: SUCCESS_DELETE_TICKETPROPERTY,
    receivedAt: Date.now(),
    ticketProperty: normalizeDato(ticketProperty)
  };
}

function errorDeleteTicketProperty(error) {
  return {
    type: ERROR_DELETE_TICKETPROPERTY,
    error: error
  };
}

export function resetDeleteTicketProperty(error) {
  return {
    type: RESET_DELETE_TICKETPROPERTY,
    error: error
  };
}

export function deleteTicketProperty(ticketProperty) {
  return {
    type: DELETE_TICKETPROPERTY,
    ticketProperty
  };
}

export function saveDeleteTicketProperty(ticketProperty) {
  return dispatch => {
    dispatch(requestDeleteTicketProperty());
    return ticketPropertiesApi
      .saveDelete(ticketProperty)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeleteTicketProperty());
        dispatch(receiveDeleteTicketProperty(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorDeleteTicketProperty(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeleteTicketProperty(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorDeleteTicketProperty(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorDeleteTicketProperty(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorDeleteTicketProperty(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//PRINT TICKETPROPERTY
export const PRINT_TICKETPROPERTY = 'PRINT_TICKETPROPERTY';
export const REQUEST_PRINT_TICKETPROPERTY = 'REQUEST_PRINT_TICKETPROPERTY';
export const SUCCESS_PRINT_TICKETPROPERTY = 'SUCCESS_PRINT_TICKETPROPERTY';
export const ERROR_PRINT_TICKETPROPERTY = 'ERROR_PRINT_TICKETPROPERTY';
export const RESET_PRINT_TICKETPROPERTY = 'RESET_PRINT_TICKETPROPERTY';
export const DELETE_PRINT_TICKETPROPERTY = 'DELETE_PRINT_TICKETPROPERTY';

function requestPrintTicketProperty() {
  return {
    type: REQUEST_PRINT_TICKETPROPERTY
  };
}

function receivePrintTicketProperty(turnos) {
  return {
    type: SUCCESS_PRINT_TICKETPROPERTY,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintTicketProperty(error) {
  return {
    type: ERROR_PRINT_TICKETPROPERTY,
    error: error
  };
}

export function resetPrintTicketProperty() {
  return {
    type: RESET_PRINT_TICKETPROPERTY
  };
}

export function printTicketProperty(ticketProperty) {
  return {
    type: PRINT_TICKETPROPERTY,
    ticketProperty
  };
}

export function deletePrintTicketProperty(ticketProperty) {
  return {
    type: DELETE_PRINT_TICKETPROPERTY,
    ticketProperty
  };
}

export function savePrintTicketProperty(idTicketProperty) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintTicketProperty());
    return ticketPropertiesApi
      .printTicketProperty(idTicketProperty)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintTicketProperty(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorPrintTicketProperty(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintTicketProperty(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintTicketProperty(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(
                    errorPrintTicketProperty(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//PRINT TICKETPROPERTIES
export const REQUEST_PRINT_TICKETPROPERTIES = 'REQUEST_PRINT_TICKETPROPERTIES';
export const SUCCESS_PRINT_TICKETPROPERTIES = 'SUCCESS_PRINT_TICKETPROPERTIES';
export const ERROR_PRINT_TICKETPROPERTIES = 'ERROR_PRINT_TICKETPROPERTIES';
export const RESET_PRINT_TICKETPROPERTIES = 'RESET_PRINT_TICKETPROPERTIES';

function requestPrintTicketProperties() {
  return {
    type: REQUEST_PRINT_TICKETPROPERTIES
  };
}

function receivePrintTicketProperties(ticketProperties) {
  return {
    type: SUCCESS_PRINT_TICKETPROPERTIES,
    receivedAt: Date.now(),
    ticketProperties: normalizeDatos(ticketProperties)
  };
}

function errorPrintTicketProperties(error) {
  return {
    type: ERROR_PRINT_TICKETPROPERTIES,
    error: error
  };
}

export function resetPrintTicketProperties() {
  return {
    type: RESET_PRINT_TICKETPROPERTIES
  };
}

export function savePrintTicketProperties() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintTicketProperty());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let ticketProperties = getState().ticketProperties.print.printers.map(
      idTicketProperty => {
        return denormalizeDato(
          getState().ticketProperties.print.print[idTicketProperty],
          store
        );
      }
    );
    return ticketPropertiesApi
      .printTicketProperties(ticketProperties)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintTicketProperties(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorPrintTicketProperties(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintTicketProperties(
                  JSON.parse(error.responseJSON.message)
                )
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorPrintTicketProperties(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorPrintTicketProperties(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}
