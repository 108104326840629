import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useValidador } from '../../utils/validador';
import moment from 'moment/moment';
import { DatePicker } from '@material-ui/pickers';
import TicketPropertySelector from '../TicketProperty/TicketPropertySelector';
import { FormControl, FormLabel, MenuItem } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import UserSelector from '../Property/UserSelector';
import Box from '@material-ui/core/Box';

export default function SuccessfulTicketClosedOperationModal(props) {
  const {
    ticket,
    propertyClosedOperation,
    handleClose,
    handleChangePropertyClosedOperation,
    handleDateChangePropertyClosedOperation,
    handlePropertyTypeChangePropertyClosedOperation,
    handleChangeTicket,
    open,
    ticket_property,
    disabled
  } = props;
  //Hooks
  let reglas = {};
  reglas = {
    date: ['sometimes|required']
  };
  const [validador, validar] = useValidador(reglas);
  const currencies = useSelector(state => state.currencies);

  function handleAccept() {
    if (validador.isValid()) {
      props.handleAccept();
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Registrar operación exitosa
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Registraremos los datos de la operación exitosa
          </DialogContentText>
          <TextField
            autoFocus
            id="motive"
            select
            label="Motivo"
            fullWidth
            value={ticket?.motive ? ticket.motive : ''}
            onChange={handleChangeTicket}
            inputProps={{ id: 'motive', name: 'motive' }}
            margin={'normal'}
            disabled={disabled}
          >
            <MenuItem key={'sold'} value={'sold'}>
              Realizamos la operación
            </MenuItem>
            <MenuItem key={'sold_with_cl'} value={'sold_with_cl'}>
              Realizamos la operación en colaboración con un colega
            </MenuItem>
            <MenuItem key={'appraisal_success'} value={'appraisal_success'}>
              Tasación exitosa, ingresó la propiedad
            </MenuItem>
          </TextField>
          {ticket?.motive && ticket?.motive !== 'appraisal_success' && (
            <>
              {/*//TODO ver usuarios*/}
              <UserSelector
                model={propertyClosedOperation}
                id={'consultant_id'}
                description={'Asesor asignado'}
                handleChangeModel={handleChangePropertyClosedOperation}
                fullwidth={true}
                margin={'normal'}
                disabled={disabled}
              />
              <DatePicker
                id={'date'}
                autoOk
                clearable
                label="Fecha de operación"
                margin={'normal'}
                variant="outlined"
                disablePast
                value={
                  propertyClosedOperation && propertyClosedOperation.date
                    ? moment(propertyClosedOperation.date)
                    : null
                }
                fullWidth
                onChange={e =>
                  handleDateChangePropertyClosedOperation(e, 'date')
                }
                format="DD/MM/yyyy"
                views={['year', 'month', 'date']}
                disabled={disabled}
              />
              <FormControl
                component="fieldset"
                variant="standard"
                className={'operationType'}
                style={{ marginTop: '15px', marginBottom: '15px' }}
              >
                <FormLabel
                  component="legend"
                  className={'formLabel'}
                  style={{ marginBottom: '10px' }}
                >
                  Tipo de operación
                </FormLabel>
                <ToggleButtonGroup
                  color="primary"
                  value={
                    propertyClosedOperation && propertyClosedOperation.codType
                  }
                  exclusive
                  onChange={handlePropertyTypeChangePropertyClosedOperation}
                  variant={'filled'}
                  disabled={disabled}
                >
                  <ToggleButton value="V" fullWidth>
                    Venta
                  </ToggleButton>
                  <ToggleButton value="A" fullWidth>
                    Alquiler
                  </ToggleButton>
                  <ToggleButton value="AT" fullWidth>
                    Alquiler temporal
                  </ToggleButton>
                </ToggleButtonGroup>
              </FormControl>
              <Box display={'flex'} gridColumnGap={'20px'}>
                <TextField
                  style={{ width: '20%' }}
                  id="codCurrency"
                  select
                  label="Moneda"
                  margin={'normal'}
                  fullWidth
                  value={
                    propertyClosedOperation?.codCurrency
                      ? propertyClosedOperation.codCurrency
                      : ''
                  }
                  onChange={handleChangePropertyClosedOperation}
                  inputProps={{ id: 'codCurrency', name: 'codCurrency' }}
                  disabled={disabled}
                >
                  {currencies &&
                    currencies.allIds &&
                    currencies.allIds.map(id => {
                      let currency = currencies.byId.currencies[id];
                      return (
                        <MenuItem key={currency.simbol} value={currency.cod}>
                          {currency.simbol}
                        </MenuItem>
                      );
                    })}
                </TextField>
                <TextField
                  style={{ width: '80%' }}
                  id="price"
                  fullWidth
                  label="Precio"
                  margin={'normal'}
                  value={
                    propertyClosedOperation?.price
                      ? propertyClosedOperation.price
                      : ''
                  }
                  onChange={handleChangePropertyClosedOperation}
                  disabled={disabled}
                />
              </Box>
              {propertyClosedOperation?.codType !== 'V' && (
                <Box display={'flex'} gridColumnGap={'20px'}>
                  <DatePicker
                    style={{ width: '50%' }}
                    id={'from'}
                    autoOk
                    clearable
                    label="Fecha inicio alquiler"
                    margin={'normal'}
                    variant="outlined"
                    disablePast
                    value={
                      propertyClosedOperation && propertyClosedOperation.from
                        ? moment(propertyClosedOperation.from)
                        : null
                    }
                    fullWidth
                    onChange={e =>
                      handleDateChangePropertyClosedOperation(e, 'from')
                    }
                    format="DD/MM/yyyy"
                    views={['year', 'month', 'date']}
                    disabled={disabled}
                  />
                  <DatePicker
                    id={'to'}
                    style={{ width: '50%' }}
                    autoOk
                    clearable
                    label="Fecha fin alquiler"
                    margin={'normal'}
                    variant="outlined"
                    disablePast
                    value={
                      propertyClosedOperation && propertyClosedOperation.to
                        ? moment(propertyClosedOperation.to)
                        : null
                    }
                    fullWidth
                    onChange={e =>
                      handleDateChangePropertyClosedOperation(e, 'to')
                    }
                    format="DD/MM/yyyy"
                    views={['year', 'month', 'date']}
                    disabled={disabled}
                  />
                </Box>
              )}
            </>
          )}
          <TicketPropertySelector
            model={propertyClosedOperation}
            handleChangeModel={handleChangePropertyClosedOperation}
            disabled={disabled}
            id={'property_id'}
            margin={'normal'}
            fullwidth={true}
            idsTicketProperties={ticket_property}
            description={'Propiedad'}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={disabled}>
            Cancelar
          </Button>
          <Button onClick={handleAccept} color="primary" disabled={disabled}>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
