import authApi from '../api/auth';
import jwt_decode from 'jwt-decode';
import * as errorMessages from '../constants/MessageConstants';
import history from '../history';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const RESTABLECER_REQUEST = 'RESTABLECER_REQUEST';
export const RESTABLECER_SUCCESS = 'RESTABLECER_SUCCESS';
export const ERROR_RESTABLECER = 'ERROR_RESTABLECER';
export const RESET_LOGIN = 'RESET_LOGIN';

//LOGIN

function requestLogin(creds) {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    creds
  };
}

export function resetLogin() {
  return {
    type: RESET_LOGIN
  };
}

export function login(email, password, to) {
  return dispatch => {
    dispatch(requestLogin());
    return authApi
      .login(email, password)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          return data;
        }
      })
      .then(function (data) {
        localStorage.token = data.access_token;
        var datos = jwt_decode(localStorage.token);
        dispatch(receiveLogin(datos));
        // if(to && to.pathname)
        //     forwardTo(to.pathname);
        // else
        //     forwardTo("/");
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(loginError(errorMessages.UNAUTHORIZED));
            // dispatch(logout());
            return;
          default:
            error &&
              error
                .json()
                .then(error => {
                  if (error.length > 0) dispatch(loginError(error));
                  else dispatch(loginError(errorMessages.GENERAL_ERROR));
                })
                .catch(error => {
                  dispatch(loginError(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

export function receiveLogin(user, mensaje) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
    user: user,
    mensaje
  };
}

function loginError(message) {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    message
  };
}

//LOGOUT

function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
    isFetching: true,
    isAuthenticated: true
  };
}

export function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false
  };
}

export function logout() {
  return dispatch => {
    dispatch(requestLogout());
    return authApi
      .logout()
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          return data;
        }
      })
      .then(function (data) {
        localStorage.removeItem('token');
        localStorage.removeItem('tenant_id');
        document.cookie = 'id_token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        forwardTo('/login');
        dispatch(receiveLogout());
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(loginError(errorMessages.UNAUTHORIZED));
            dispatch(logout());
            return;
          default:
            error &&
              error
                .json()
                .then(error => {
                  if (error.length > 0) dispatch(loginError(error));
                  else dispatch(loginError(errorMessages.GENERAL_ERROR));
                })
                .catch(error => {
                  dispatch(loginError(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//OLVIDO CONTRASENA
function requestRestablecer() {
  return {
    type: RESTABLECER_REQUEST,
    isFetching: true
  };
}

export function receiveRestablecer(mensaje) {
  return {
    type: RESTABLECER_SUCCESS,
    isFetching: false,
    mensaje: mensaje
  };
}

function errorRestablecer(error) {
  return {
    type: ERROR_RESTABLECER,
    error: error
  };
}

export function olvideMiPassword(usuario) {
  return dispatch => {
    dispatch(requestLogin());
    // If no username or password was specified, throw a field-missing error
    if (usuario == '') {
      dispatch(
        loginError(
          'Debe ingresar su email para iniciar el proceso de recuperación.'
        )
      );
      return;
    }
    authApi
      .olvideMiPassword(usuario)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          dispatch(
            receiveLogin(
              null,
              'Se le ha enviado un mail con las instrucciones para restablecer la contraseña.'
            )
          );
        }
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(loginError(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            try {
              error
                .json()
                .then(error => {
                  if (error.message != '') dispatch(loginError(error.message));
                  else dispatch(loginError(errorMessages.GENERAL_ERROR));
                })
                .catch(error => {
                  dispatch(loginError(errorMessages.GENERAL_ERROR));
                });
            } catch (e) {
              console.log('error');
            }
            return;
        }
      });
  };
}

export function activarCuenta(token) {
  return dispatch => {
    dispatch(requestLogin());
    authApi
      .activarCuenta(token)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          dispatch(
            receiveLogin(
              null,
              'Su cuenta fue activada correctamente. Ya puede iniciar sesión!'
            )
          );
        }
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(loginError(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            try {
              error
                .json()
                .then(error => {
                  if (error.message != '') dispatch(loginError(error.message));
                  else dispatch(loginError(errorMessages.GENERAL_ERROR));
                })
                .catch(() => {
                  dispatch(loginError(errorMessages.GENERAL_ERROR));
                });
            } catch (e) {
              console.log('error');
            }
            return;
        }
      });
  };
}

export function restablecer(usuario) {
  return dispatch => {
    dispatch(requestRestablecer());
    authApi
      .resetPassword(usuario)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          dispatch(
            receiveRestablecer(
              'Su contraseña ha sido restablecida correctamente. Inicie sesión con su nueva contraseña.'
            )
          );
        }
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorRestablecer(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            try {
              error
                .json()
                .then(error => {
                  if (error.message != '')
                    dispatch(errorRestablecer(error.message));
                  else dispatch(errorRestablecer(errorMessages.GENERAL_ERROR));
                })
                .catch(error => {
                  dispatch(errorRestablecer(errorMessages.GENERAL_ERROR));
                });
            } catch (e) {
              console.log('error');
            }
            return;
        }
      });
  };
}

export function validarToken(token) {
  return dispatch => {
    authApi
      .validarToken(token)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        }
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorRestablecer(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            try {
              error
                .json()
                .then(error => {
                  console.log(error);
                  if (error.message != '')
                    dispatch(errorRestablecer(error.message));
                  else dispatch(errorRestablecer(errorMessages.GENERAL_ERROR));
                })
                .catch(error => {
                  dispatch(errorRestablecer(errorMessages.GENERAL_ERROR));
                });
            } catch (e) {
              console.log('error');
            }
            return;
        }
      });
  };
}

/**
 * Forwards the user
 * @param {string} location The route the user should be forwarded to
 */
function forwardTo(location) {
  history.push(location);
}
