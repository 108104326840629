import React, { useEffect, useRef } from 'react';
import { LoadScript } from '@react-google-maps/api';
//css
//Actions
//img

const LIBRARIES = ['places', 'geocoder'];

export default function MapWrapper(props) {
  return (
    <LoadScript
      googleMapsApiKey={'AIzaSyANSH-395EpmTpki-222stF4V76Rp8KcZY'}
      libraries={LIBRARIES}
    >
      {props.children}
    </LoadScript>
  );
}
