import React from 'react';

export default function Spacing() {
  return (
    <div
      style={{
        marginLeft: '15px',
        marginRight: '15px',
        width: '1px',
        marginTop: '1px',
        marginBottom: '1px',
        backgroundColor: '#c4c4c4ad'
      }}
    />
  );
}
