import Grid from '@material-ui/core/Grid';
import PropertyCard from '../Property/PropertyCard';
import React from 'react';
import { useSelector } from 'react-redux';
import PropertyItemList from '../Property/PropertyItemList';
import List from '@material-ui/core/List';

export default function TicketPropertyList(props) {
  const { ticket } = props;
  const properties = useSelector(state => state.properties);
  const ticketProperties = useSelector(state => state.ticketProperties);

  return (
    <>
      <List style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
        {ticket?.ticket_property?.map((idTicketProperty, i) => {
          let ticketProperty =
            ticketProperties.byId.ticketProperties[idTicketProperty];
          let property = ticketProperty?.property_id
            ? properties.byId.properties[ticketProperty.property_id]
            : null;
          if (
            properties.update.activos.indexOf(ticketProperty?.property_id) !==
            -1
          )
            property = properties.update.activo[ticketProperty?.property_id];
          if (property)
            return (
              <PropertyItemList
                property={property}
                disabled={true}
                style={{
                  width: '50%',
                  boxShadow: 'rgb(234 234 234 / 77%) 0px 5px 16px 0px',
                  borderRadius: '20px'
                }}
              />
            );
        })}
      </List>
    </>
  );
}
