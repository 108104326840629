import { normalize, schema, denormalize } from 'normalizr';
import attributeValue from './normalizeAttributesValues';

const attribute = new schema.Entity(
  'attributes',
  { attribute_value: [attributeValue] },
  { idAttribute: 'id' }
);

export function normalizeDatos(myData) {
  const mySchema = { attributes: [attribute] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = attribute;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = attribute;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default attribute;
