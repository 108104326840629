import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
//Components
import AgentsList from '../Agents/AgentsList';

export default function Agents() {
  const dispatch = useDispatch();
  //Effects
  useEffect(() => {
    return function cleanup() {};
  }, []);

  return (
    <Switch>
      <Route path="/agents" component={AgentsList} />
    </Switch>
  );
}
