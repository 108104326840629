import {
  INVALIDATE_EXTERNALSYSTEMS,
  ERROR_EXTERNALSYSTEMS,
  RECEIVE_EXTERNALSYSTEMS,
  REQUEST_EXTERNALSYSTEMS,
  RESET_EXTERNALSYSTEMS,
  ERROR_EXTERNALSYSTEM,
  RECEIVE_EXTERNALSYSTEM,
  REQUEST_EXTERNALSYSTEM,
  UPDATE_EXTERNALSYSTEM,
  REQUEST_UPDATE_EXTERNALSYSTEM,
  SUCCESS_UPDATE_EXTERNALSYSTEM,
  ERROR_UPDATE_EXTERNALSYSTEM,
  RESET_UPDATE_EXTERNALSYSTEM,
  REQUEST_UPDATE_EXTERNALSYSTEMS,
  SUCCESS_UPDATE_EXTERNALSYSTEMS,
  ERROR_UPDATE_EXTERNALSYSTEMS,
  RESET_UPDATE_EXTERNALSYSTEMS,
  CREATE_EXTERNALSYSTEM,
  ERROR_CREATE_EXTERNALSYSTEM,
  REQUEST_CREATE_EXTERNALSYSTEM,
  RESET_CREATE_EXTERNALSYSTEM,
  SUCCESS_CREATE_EXTERNALSYSTEM,
  REQUEST_CREATE_EXTERNALSYSTEMS,
  SUCCESS_CREATE_EXTERNALSYSTEMS,
  ERROR_CREATE_EXTERNALSYSTEMS,
  RESET_CREATE_EXTERNALSYSTEMS,
  DELETE_EXTERNALSYSTEM,
  DELETE_CREATE_EXTERNALSYSTEM,
  DELETE_UPDATE_EXTERNALSYSTEM,
  REQUEST_DELETE_EXTERNALSYSTEM,
  SUCCESS_DELETE_EXTERNALSYSTEM,
  ERROR_DELETE_EXTERNALSYSTEM,
  RESET_DELETE_EXTERNALSYSTEM,
  REQUEST_PRINT_EXTERNALSYSTEM,
  SUCCESS_PRINT_EXTERNALSYSTEM,
  ERROR_PRINT_EXTERNALSYSTEM,
  RESET_PRINT_EXTERNALSYSTEM,
  RECEIVE_FILE_EXTERNALSYSTEM,
  REQUEST_PRINT_EXTERNALSYSTEMS,
  SUCCESS_PRINT_EXTERNALSYSTEMS,
  ERROR_PRINT_EXTERNALSYSTEMS,
  RESET_PRINT_EXTERNALSYSTEMS,
  RECEIVE_FILE_EXTERNALSYSTEMS,
  PRINT_EXTERNALSYSTEM,
  DELETE_PRINT_EXTERNALSYSTEM
} from '../actions/ExternalSystemActions';

import {
  RECEIVE_TENANT,
  RECEIVE_TENANTS,
  SUCCESS_DELETE_TENANT,
  SUCCESS_CREATE_TENANT,
  SUCCESS_UPDATE_TENANT,
  SUCCESS_UPDATE_TENANTS
} from '../actions/TenantActions';

import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from '../actions/AuthActions';

import merge from 'lodash/merge';
import mergeWith from 'lodash/mergeWith';
import union from 'lodash/union';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import omit from 'lodash/omit';
import pickBy from 'lodash/pickBy';
import filter from 'lodash/filter';

function getInitialStateById() {
  return {
    isFetching: false,
    didInvalidate: true,
    externalSystems: {},
    files: {}
  };
}

function externalSystemsById(state = getInitialStateById(), action) {
  switch (action.type) {
    case INVALIDATE_EXTERNALSYSTEMS:
      return Object.assign({}, state, {
        didInvalidate: true
      });
    case REQUEST_EXTERNALSYSTEMS:
      return Object.assign({}, state, {
        isFetching: true,
        didInvalidate: false
      });
    case ERROR_EXTERNALSYSTEMS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: action.error
      });
    case RESET_EXTERNALSYSTEMS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        lastUpdated: null,
        externalSystems: {}
      });
    case RECEIVE_EXTERNALSYSTEMS:
      let dato = action.externalSystems.entities.externalSystems;
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        externalSystems: merge({}, state.externalSystems, dato),
        lastUpdated: action.receivedAt
      });
    case REQUEST_EXTERNALSYSTEM:
      return Object.assign({}, state, {
        isFetching: true
      });
    case ERROR_EXTERNALSYSTEM:
      return Object.assign({}, state, {
        isFetching: false,
        error: action.error
      });
    case RECEIVE_EXTERNALSYSTEM:
      let datoExternalSystem = action.externalSystem.entities.externalSystems;
      return Object.assign({}, state, {
        externalSystems: merge({}, state.externalSystems, datoExternalSystem),
        isFetching: false
      });
    case RECEIVE_FILE_EXTERNALSYSTEM:
      return Object.assign({}, state, {
        files: merge({}, state.files, action.file)
      });

    case SUCCESS_DELETE_EXTERNALSYSTEM:
      let datoExternalSystemEliminado =
        action.externalSystem.entities.externalSystems;
      return Object.assign({}, state, {
        externalSystems: mergeWith(
          clone(datoExternalSystemEliminado),
          state.externalSystems,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_EXTERNALSYSTEM:
      let datoExternalSystemCreado =
        action.externalSystem.entities.externalSystems;
      return Object.assign({}, state, {
        externalSystems: mergeWith(
          clone(datoExternalSystemCreado),
          state.externalSystems,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_EXTERNALSYSTEMS:
      let datosExternalSystemCreado =
        action.externalSystems.entities.externalSystems;
      return Object.assign({}, state, {
        externalSystems: mergeWith(
          clone(datosExternalSystemCreado),
          state.externalSystems,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_EXTERNALSYSTEM:
      let datoExternalSystemActualizado =
        action.externalSystem.entities.externalSystems;
      return Object.assign({}, state, {
        externalSystems: mergeWith(
          clone(datoExternalSystemActualizado),
          state.externalSystems,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_EXTERNALSYSTEMS:
      let datosExternalSystemActualizado =
        action.externalSystems.entities.externalSystems;
      return Object.assign({}, state, {
        externalSystems: mergeWith(
          clone(datosExternalSystemActualizado),
          state.externalSystems,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    //TENANT
    case RECEIVE_TENANT:
      let tenant =
        action.tenant.entities && action.tenant.entities.externalSystems
          ? action.tenant.entities.externalSystems
          : {};
      return Object.assign({}, state, {
        externalSystems: merge({}, state.externalSystems, tenant)
      });
    case RECEIVE_TENANTS:
      let tenants =
        action.tenants.entities && action.tenants.entities.externalSystems
          ? action.tenants.entities.externalSystems
          : {};
      return Object.assign({}, state, {
        externalSystems: merge({}, state.externalSystems, tenants)
      });
    case SUCCESS_DELETE_TENANT:
      let datotenantEliminado =
        action.tenant.entities && action.tenant.entities.externalSystems
          ? action.tenant.entities.externalSystems
          : {};
      return Object.assign({}, state, {
        externalSystems: mergeWith(
          clone(datotenantEliminado),
          pickBy(state.externalSystems, function (externalSystem) {
            return externalSystem.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_CREATE_TENANT:
      let datotenantCreado =
        action.tenant.entities && action.tenant.entities.externalSystems
          ? action.tenant.entities.externalSystems
          : {};
      return Object.assign({}, state, {
        externalSystems: mergeWith(
          clone(datotenantCreado),
          pickBy(state.externalSystems, function (externalSystem) {
            return externalSystem.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TENANT:
      let datotenantActualizado =
        action.tenant.entities && action.tenant.entities.externalSystems
          ? action.tenant.entities.externalSystems
          : {};
      return Object.assign({}, state, {
        externalSystems: mergeWith(
          clone(datotenantActualizado),
          pickBy(state.externalSystems, function (externalSystem) {
            return externalSystem.id.toString().indexOf('-') === -1;
          }),
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });
    case SUCCESS_UPDATE_TENANTS:
      let datostenantActualizado =
        action.tenants.entities && action.tenants.entities.externalSystems
          ? action.tenants.entities.externalSystems
          : {};
      return Object.assign({}, state, {
        externalSystems: mergeWith(
          clone(datostenantActualizado),
          state.externalSystems,
          (objValue, srcValue) => {
            return objValue;
          }
        )
      });

    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: true,
        error: null,
        externalSystems: {}
      });
    default:
      return state;
  }
}

function allExternalSystems(state = [], action) {
  switch (action.type) {
    case RECEIVE_EXTERNALSYSTEMS:
      return action.externalSystems.result &&
        action.externalSystems.result.externalSystems
        ? union(action.externalSystems.result.externalSystems, state)
        : action.externalSystems.result
        ? action.externalSystems.result
        : state;
    case RECEIVE_EXTERNALSYSTEM:
      return action.externalSystem.result
        ? union([action.externalSystem.result], state)
        : state;

    case SUCCESS_CREATE_EXTERNALSYSTEM:
      let datoExternalSystemSCreate =
        action.externalSystem.entities.externalSystems;
      let idNuevoSCreate = null;
      if (Object.values(datoExternalSystemSCreate).length > 0)
        idNuevoSCreate =
          Object.values(datoExternalSystemSCreate)[0] &&
          Object.values(datoExternalSystemSCreate)[0].id
            ? Object.values(datoExternalSystemSCreate)[0].id
            : null;
      if (idNuevoSCreate) return union(state, [idNuevoSCreate]);
      else return state;
    case SUCCESS_CREATE_EXTERNALSYSTEMS:
      let externalSystemsCreate =
        action.externalSystems.entities &&
        action.externalSystems.entities.externalSystems
          ? action.externalSystems.entities.externalSystems
          : null;
      return externalSystemsCreate
        ? union(
            state,
            Object.values(externalSystemsCreate).map(externalSystems => {
              return externalSystems.id;
            })
          )
        : state;
    case RESET_EXTERNALSYSTEMS:
      return [];

    case RECEIVE_TENANT:
      let tenant =
        action.tenant.entities && action.tenant.entities.externalSystems
          ? action.tenant.entities.externalSystems
          : null;
      return tenant
        ? union(
            state,
            Object.values(tenant).map(tenant => {
              return tenant.id;
            })
          )
        : state;
    case RECEIVE_TENANTS:
      let tenants =
        action.tenants.entities && action.tenants.entities.externalSystems
          ? action.tenants.entities.externalSystems
          : null;
      return tenants
        ? union(
            state,
            Object.values(tenants).map(tenants => {
              return tenants.id;
            })
          )
        : state;

    case SUCCESS_DELETE_TENANT:
      let tenantDelete =
        action.tenant.entities && action.tenant.entities.externalSystems
          ? action.tenant.entities.externalSystems
          : null;
      return tenantDelete
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(tenantDelete).map(tenant => {
              return tenant.id;
            })
          )
        : state;
    case SUCCESS_CREATE_TENANT:
      let tenantCreate =
        action.tenant.entities && action.tenant.entities.externalSystems
          ? action.tenant.entities.externalSystems
          : null;
      return tenantCreate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(tenantCreate).map(tenant => {
              return tenant.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_TENANT:
      let tenantUpdate =
        action.tenant.entities && action.tenant.entities.externalSystems
          ? action.tenant.entities.externalSystems
          : null;
      return tenantUpdate
        ? union(
            filter(state, function (o) {
              return o.toString().indexOf('-') === -1;
            }),
            Object.values(tenantUpdate).map(tenant => {
              return tenant.id;
            })
          )
        : state;
    case SUCCESS_UPDATE_TENANTS:
      let tenantsUpdate =
        action.tenants.entities && action.tenants.entities.externalSystems
          ? action.tenants.entities.externalSystems
          : null;
      return tenantsUpdate
        ? union(
            state,
            Object.values(tenantsUpdate).map(tenants => {
              return tenants.id;
            })
          )
        : state;

    case LOGOUT_SUCCESS:
      return [];
    default:
      return state;
  }
}

function totalExternalSystems(state = null, action) {
  switch (action.type) {
    case RECEIVE_EXTERNALSYSTEMS:
      return action.externalSystems && action.externalSystems.result.total
        ? action.externalSystems.result.total
        : 0;
    case RESET_EXTERNALSYSTEMS:
      return null;
    case LOGOUT_SUCCESS:
      return null;
    default:
      return state;
  }
}

function update(
  state = {
    isUpdating: false,
    activo: {},
    activos: []
  },
  action
) {
  switch (action.type) {
    case RECEIVE_EXTERNALSYSTEM:
      let dato = action.externalSystem.entities.externalSystems;
      let externalSystem =
        dato && Object.keys(dato).length > 0
          ? dato[action.externalSystem.result]
          : {};
      return Object.assign({}, state, {
        isFetching: false,
        didInvalidate: false,
        activo: externalSystem ? externalSystem : [],
        lastUpdated: action.receivedAt
      });
    case UPDATE_EXTERNALSYSTEM:
      let idsUpdate = [];
      Object.values(action.externalSystem).map(externalSystemUpdate => {
        if (externalSystemUpdate && externalSystemUpdate.id)
          idsUpdate.push(externalSystemUpdate.id);
      });
      return merge({}, state, {
        activo: action.externalSystem,
        activos:
          idsUpdate.length > 0
            ? union(state.activos, idsUpdate)
            : state.activos,
        error: ''
      });
    case REQUEST_UPDATE_EXTERNALSYSTEM:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_EXTERNALSYSTEM:
      let datoExternalSystemActualizado =
        action.externalSystem.entities.externalSystems;
      let externalSystemNuevo =
        datoExternalSystemActualizado &&
        Object.keys(datoExternalSystemActualizado).length > 0
          ? datoExternalSystemActualizado[action.externalSystem.result]
          : {};
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: externalSystemNuevo
      });
    case ERROR_UPDATE_EXTERNALSYSTEM:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case REQUEST_UPDATE_EXTERNALSYSTEMS:
      return Object.assign({}, state, {
        isUpdating: true,
        error: null
      });
    case SUCCESS_UPDATE_EXTERNALSYSTEMS:
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: {},
        activos: []
      });
    case ERROR_UPDATE_EXTERNALSYSTEMS:
      return Object.assign({}, state, {
        isUpdating: false,
        error: action.error
      });
    case RESET_UPDATE_EXTERNALSYSTEM:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        activos: [],
        error: ''
      });

    //TENANT
    //TODO ver si esta bien
    case SUCCESS_CREATE_TENANT:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_TENANT:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_DELETE_TENANT:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });
    case SUCCESS_UPDATE_TENANTS:
      return Object.assign({}, state, {
        activo: state.activo,
        activos: state.activos
      });

    case DELETE_EXTERNALSYSTEM:
      let datoExternalSystemDelete = action.externalSystem;
      let idsDelete = [];
      Object.values(action.externalSystem).map(externalSystemDelete => {
        if (externalSystemDelete && externalSystemDelete.id)
          idsDelete.push(externalSystemDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoExternalSystemDelete)
          ),
          activos: difference(clone(state.activos), idsDelete)
        });
      else return state;
    case DELETE_UPDATE_EXTERNALSYSTEM:
      let datoExternalSystemDeleteUpdate = action.externalSystem;
      let idsDeleteUpdate = [];
      Object.values(action.externalSystem).map(externalSystemDelete => {
        if (externalSystemDelete && externalSystemDelete.id)
          idsDeleteUpdate.push(externalSystemDelete.id);
      });
      if (idsDeleteUpdate.length > 0)
        return Object.assign({}, state, {
          activo: omit(
            clone(state.activo),
            Object.keys(datoExternalSystemDeleteUpdate)
          ),
          activos: difference(clone(state.activos), idsDeleteUpdate)
        });
      else return state;
    case SUCCESS_DELETE_EXTERNALSYSTEM:
      let datoExternalSystemDeleted = {};
      if (
        Object.values(action.externalSystem.entities.externalSystems).length > 0
      )
        datoExternalSystemDeleted = Object.values(
          action.externalSystem.entities.externalSystems
        )[0];
      return Object.assign({}, state, {
        isUpdating: false,
        lastUpdated: action.receivedAt,
        error: null,
        activo: datoExternalSystemDeleted
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        activo: {},
        error: ''
      });
    default:
      return state;
  }
}

function create(
  state = {
    isCreating: false,
    nuevo: {},
    nuevos: [],
    error: ''
  },
  action
) {
  switch (action.type) {
    case CREATE_EXTERNALSYSTEM:
      let idsCreate = [];
      Object.values(action.externalSystem).map(externalSystemCreate => {
        if (externalSystemCreate && externalSystemCreate.id)
          idsCreate.push(externalSystemCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        nuevo: action.externalSystem,
        nuevos:
          idsCreate.length > 0 ? union(state.nuevos, idsCreate) : state.nuevos,
        error: null
      });
    case REQUEST_CREATE_EXTERNALSYSTEM:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_EXTERNALSYSTEM:
      let datoExternalSystemNuevo =
        action.externalSystem.entities.externalSystems;
      let externalSystemNuevo =
        datoExternalSystemNuevo &&
        Object.keys(datoExternalSystemNuevo).length > 0
          ? datoExternalSystemNuevo[action.externalSystem.result]
          : {};
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: externalSystemNuevo,
        nuevos: []
      });
    case ERROR_CREATE_EXTERNALSYSTEM:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case REQUEST_CREATE_EXTERNALSYSTEMS:
      return Object.assign({}, state, {
        isCreating: true,
        error: null
      });
    case SUCCESS_CREATE_EXTERNALSYSTEMS:
      return Object.assign({}, state, {
        isCreating: false,
        lastUpdated: action.receivedAt,
        error: null,
        nuevo: {},
        nuevos: []
      });
    case ERROR_CREATE_EXTERNALSYSTEMS:
      return Object.assign({}, state, {
        isCreating: false,
        error: action.error
      });
    case RESET_CREATE_EXTERNALSYSTEM:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {},
        nuevos: []
      });

    //TENANT
    case SUCCESS_CREATE_TENANT:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_TENANT:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_DELETE_TENANT:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });
    case SUCCESS_UPDATE_TENANTS:
      return Object.assign({}, state, {
        nuevo: {},
        nuevos: []
      });

    case DELETE_EXTERNALSYSTEM:
      let datoExternalSystemDelete = action.externalSystem;
      let idsDelete = [];
      Object.values(action.externalSystem).map(externalSystemDelete => {
        if (externalSystemDelete && externalSystemDelete.id)
          idsDelete.push(externalSystemDelete.id);
      });
      if (idsDelete.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoExternalSystemDelete)
          ),
          nuevos: difference(clone(state.nuevos), idsDelete)
        });
      else return state;
    case DELETE_CREATE_EXTERNALSYSTEM:
      let datoExternalSystemDeleteCreate = action.externalSystem;
      let idsDeleteCreate = [];
      Object.values(action.externalSystem).map(externalSystemDelete => {
        if (externalSystemDelete && externalSystemDelete.id)
          idsDeleteCreate.push(externalSystemDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          nuevo: omit(
            clone(state.nuevo),
            Object.keys(datoExternalSystemDeleteCreate)
          ),
          nuevos: difference(clone(state.nuevos), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isCreating: false,
        error: null,
        nuevo: {}
      });
    default:
      return state;
  }
}

function deleter(
  state = {
    isDeleting: false,
    eliminado: {},
    error: ''
  },
  action
) {
  switch (action.type) {
    case DELETE_EXTERNALSYSTEM:
      return merge({}, state, {
        isDeleting: false,
        eliminado: action.externalSystem,
        error: null
      });
    case REQUEST_DELETE_EXTERNALSYSTEM:
      return Object.assign({}, state, {
        isDeleting: true,
        error: null
      });
    case SUCCESS_DELETE_EXTERNALSYSTEM:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null
      });
    case ERROR_DELETE_EXTERNALSYSTEM:
      return Object.assign({}, state, {
        isDeleting: false,
        error: action.error
      });
    case RESET_DELETE_EXTERNALSYSTEM:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    //TENANT
    case SUCCESS_CREATE_TENANT:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_TENANT:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_DELETE_TENANT:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case SUCCESS_UPDATE_TENANTS:
      return Object.assign({}, state, {
        eliminado: {}
      });
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isDeleting: false,
        error: null,
        eliminado: {}
      });
    default:
      return state;
  }
}

function print(
  state = {
    isPrinting: false,
    error: '',
    print: {},
    printers: []
  },
  action
) {
  switch (action.type) {
    case PRINT_EXTERNALSYSTEM:
      let idsCreate = [];
      Object.values(action.externalSystem).map(externalSystemCreate => {
        if (externalSystemCreate && externalSystemCreate.id)
          idsCreate.push(externalSystemCreate.id);
      });
      return merge({}, state, {
        isCreating: false,
        print: action.externalSystem,
        printers:
          idsCreate.length > 0
            ? union(state.printers, idsCreate)
            : state.printers,
        error: null
      });
    case REQUEST_PRINT_EXTERNALSYSTEM:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_EXTERNALSYSTEM:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: null,
        printers: {}
      });
    case ERROR_PRINT_EXTERNALSYSTEM:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });

    case REQUEST_PRINT_EXTERNALSYSTEMS:
      return Object.assign({}, state, {
        isPrinting: true,
        error: null
      });
    case SUCCESS_PRINT_EXTERNALSYSTEMS:
      return Object.assign({}, state, {
        isPrinting: false,
        lastUpdated: action.receivedAt,
        error: null,
        print: {},
        printers: []
      });
    case ERROR_PRINT_EXTERNALSYSTEMS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: action.error
      });
    case RESET_PRINT_EXTERNALSYSTEM:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: {},
        printers: []
      });
    case DELETE_PRINT_EXTERNALSYSTEM:
      let datoExternalSystemDeleteCreate = action.externalSystem;
      let idsDeleteCreate = [];
      Object.values(action.externalSystem).map(externalSystemDelete => {
        if (externalSystemDelete && externalSystemDelete.id)
          idsDeleteCreate.push(externalSystemDelete.id);
      });
      if (idsDeleteCreate.length > 0)
        return Object.assign({}, state, {
          print: omit(
            clone(state.print),
            Object.keys(datoExternalSystemDeleteCreate)
          ),
          printers: difference(clone(state.printers), idsDeleteCreate)
        });
      else return state;
    case LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        isPrinting: false,
        error: null,
        print: null,
        printers: {}
      });
    default:
      return state;
  }
}

const externalSystems = combineReducers({
  byId: externalSystemsById,
  allIds: allExternalSystems,
  update: update,
  create: create,
  totalExternalSystems: totalExternalSystems,
  delete: deleter,
  print: print
});

export default externalSystems;
