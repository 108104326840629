//api
import operationTypesApi from '../api/operationTypesApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizeOperationTypes';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//OPERATIONTYPES
export const REQUEST_OPERATIONTYPES = 'REQUEST_OPERATIONTYPES';
export const RECEIVE_OPERATIONTYPES = 'RECEIVE_OPERATIONTYPES';
export const INVALIDATE_OPERATIONTYPES = 'INVALIDATE_OPERATIONTYPES';
export const ERROR_OPERATIONTYPES = 'ERROR_OPERATIONTYPES';
export const RESET_OPERATIONTYPES = 'RESET_OPERATIONTYPES';

export function invalidateOperationTypes() {
  return {
    type: INVALIDATE_OPERATIONTYPES
  };
}

function requestOperationTypes() {
  return {
    type: REQUEST_OPERATIONTYPES
  };
}

function receiveOperationTypes(json) {
  return {
    type: RECEIVE_OPERATIONTYPES,
    operationTypes: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorOperationTypes(error) {
  return {
    type: ERROR_OPERATIONTYPES,
    error: error
  };
}

export function resetOperationTypes() {
  return {
    type: RESET_OPERATIONTYPES
  };
}

export function fetchOperationTypes(filtros) {
  return dispatch => {
    dispatch(requestOperationTypes());
    return operationTypesApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveOperationTypes(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorOperationTypes(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorOperationTypes(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

function shouldFetchOperationTypes(state) {
  const operationTypes = state.operationTypes.byId;
  if (!operationTypes) {
    return true;
  } else if (operationTypes.isFetching) {
    return false;
  } else {
    return operationTypes.didInvalidate;
  }
}

export function fetchOperationTypesIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchOperationTypes(getState())) {
      return dispatch(fetchOperationTypes(filtros));
    }
  };
}

//MODEL
export const REQUEST_OPERATIONTYPE = 'REQUEST_OPERATIONTYPE';
export const RECEIVE_OPERATIONTYPE = 'RECEIVE_OPERATIONTYPE';
export const INVALIDATE_OPERATIONTYPE = 'INVALIDATE_OPERATIONTYPE';
export const ERROR_OPERATIONTYPE = 'ERROR_OPERATIONTYPE';
export const RESET_OPERATIONTYPE = 'RESET_OPERATIONTYPE';

export function invalidateOperationType() {
  return {
    type: INVALIDATE_OPERATIONTYPE
  };
}

function requestOperationType() {
  return {
    type: REQUEST_OPERATIONTYPE
  };
}

export function receiveOperationType(json) {
  return {
    type: RECEIVE_OPERATIONTYPE,
    operationType: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorOperationType(error) {
  return {
    type: ERROR_OPERATIONTYPE,
    error: error
  };
}

export function fetchOperationType(idOperationType) {
  return dispatch => {
    dispatch(requestOperationType());
    return operationTypesApi
      .getOne(idOperationType)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveOperationType(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(errorOperationType(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorOperationType(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_OPERATIONTYPE = 'RECEIVE_FILE_OPERATIONTYPE';

function receiveFileOperationType(file) {
  return {
    type: RECEIVE_FILE_OPERATIONTYPE,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFileOperationType(idOperationType, filtros) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return operationTypesApi
      .getFile(idOperationType, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFileOperationType(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorOperationType(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorOperationType(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_OPERATIONTYPE = 'UPDATE_OPERATIONTYPE';
export const REQUEST_UPDATE_OPERATIONTYPE = 'REQUEST_UPDATE_OPERATIONTYPE';
export const SUCCESS_UPDATE_OPERATIONTYPE = 'SUCCESS_UPDATE_OPERATIONTYPE';
export const ERROR_UPDATE_OPERATIONTYPE = 'ERROR_UPDATE_OPERATIONTYPE';
export const RESET_UPDATE_OPERATIONTYPE = 'RESET_UPDATE_OPERATIONTYPE';
export const DELETE_UPDATE_OPERATIONTYPE = 'DELETE_UPDATE_OPERATIONTYPE';

function requestUpdateOperationType() {
  return {
    type: REQUEST_UPDATE_OPERATIONTYPE
  };
}

function receiveUpdateOperationType(operationType) {
  return {
    type: SUCCESS_UPDATE_OPERATIONTYPE,
    receivedAt: Date.now(),
    operationType: normalizeDato(operationType)
  };
}

function errorUpdateOperationType(error) {
  return {
    type: ERROR_UPDATE_OPERATIONTYPE,
    error: error
  };
}

export function resetUpdateOperationType() {
  return {
    type: RESET_UPDATE_OPERATIONTYPE
  };
}

export function updateOperationType(operationType) {
  return {
    type: UPDATE_OPERATIONTYPE,
    operationType
  };
}

export function saveUpdateOperationType(files) {
  return (dispatch, getState) => {
    dispatch(requestUpdateOperationType());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let operationType = denormalizeDato(
      getState().operationTypes.update.activo,
      store
    );

    return operationTypesApi
      .saveUpdate(operationType, files)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateOperationType(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorUpdateOperationType(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateOperationTypes(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorUpdateOperationTypes(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateOperationType(JSON.parse(error.message)));
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdateOperationType(error.data));
                })
                .catch(() => {
                  dispatch(
                    errorUpdateOperationType(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

export function deleteUpdateOperationType(operationType) {
  return {
    type: DELETE_UPDATE_OPERATIONTYPE,
    operationType
  };
}

//UPDATE OPERATIONTYPES
export const REQUEST_UPDATE_OPERATIONTYPES = 'REQUEST_UPDATE_OPERATIONTYPES';
export const SUCCESS_UPDATE_OPERATIONTYPES = 'SUCCESS_UPDATE_OPERATIONTYPES';
export const ERROR_UPDATE_OPERATIONTYPES = 'ERROR_UPDATE_OPERATIONTYPES';
export const RESET_UPDATE_OPERATIONTYPES = 'RESET_UPDATE_OPERATIONTYPES';

function requestUpdateOperationTypes() {
  return {
    type: REQUEST_UPDATE_OPERATIONTYPES
  };
}

function receiveUpdateOperationTypes(operationTypes) {
  return {
    type: SUCCESS_UPDATE_OPERATIONTYPES,
    receivedAt: Date.now(),
    operationTypes: normalizeDatos(operationTypes)
  };
}

function errorUpdateOperationTypes(error) {
  return {
    type: ERROR_UPDATE_OPERATIONTYPES,
    error: error
  };
}

export function resetUpdateOperationTypes() {
  return {
    type: RESET_UPDATE_OPERATIONTYPES
  };
}

export function saveUpdateOperationTypes() {
  return (dispatch, getState) => {
    dispatch(requestUpdateOperationTypes());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let operationTypes = getState().operationTypes.update.activos.map(
      idOperationType => {
        return denormalizeDato(
          getState().operationTypes.update.activo[idOperationType],
          store
        );
      }
    );

    return operationTypesApi
      .saveUpdateOperationTypes(operationTypes)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateOperationTypes(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorUpdateOperationTypes(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateOperationTypes(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorUpdateOperationTypes(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorUpdateOperationTypes(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorUpdateOperationTypes(errorMessages.GENERAL_ERROR)
                  );
                });

            return;
        }
      });
  };
}

//ALTA OPERATIONTYPE
export const CREATE_OPERATIONTYPE = 'CREATE_OPERATIONTYPE';
export const REQUEST_CREATE_OPERATIONTYPE = 'REQUEST_CREATE_OPERATIONTYPE';
export const SUCCESS_CREATE_OPERATIONTYPE = 'SUCCESS_CREATE_OPERATIONTYPE';
export const ERROR_CREATE_OPERATIONTYPE = 'ERROR_CREATE_OPERATIONTYPE';
export const RESET_CREATE_OPERATIONTYPE = 'RESET_CREATE_OPERATIONTYPE';
export const DELETE_CREATE_OPERATIONTYPE = 'DELETE_CREATE_OPERATIONTYPE';

//ALTA OPERATIONTYPE
function requestCreateOperationType() {
  return {
    type: REQUEST_CREATE_OPERATIONTYPE
  };
}

function receiveCreateOperationType(operationType) {
  return {
    type: SUCCESS_CREATE_OPERATIONTYPE,
    receivedAt: Date.now(),
    operationType: normalizeDato(operationType)
  };
}

export function errorCreateOperationType(error) {
  return {
    type: ERROR_CREATE_OPERATIONTYPE,
    error: error
  };
}

export function resetCreateOperationType() {
  return {
    type: RESET_CREATE_OPERATIONTYPE
  };
}

export function createOperationType(operationType) {
  return {
    type: CREATE_OPERATIONTYPE,
    operationType
  };
}

export function deleteCreateOperationType(operationType) {
  return {
    type: DELETE_CREATE_OPERATIONTYPE,
    operationType
  };
}

export function saveCreateOperationType(files) {
  return (dispatch, getState) => {
    dispatch(requestCreateOperationType());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let operationType = denormalizeDato(
      getState().operationTypes.create.nuevo,
      store
    );

    return operationTypesApi
      .saveCreate(operationType, files)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateOperationType(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorCreateOperationType(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateOperationTypes(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorCreateOperationTypes(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateOperationType(JSON.parse(error.message)));
                  if (error.data)
                    dispatch(receiveCreateOperationType(error.data));
                })
                .catch(() => {
                  dispatch(
                    errorCreateOperationType(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//CREATE OPERATIONTYPES
export const REQUEST_CREATE_OPERATIONTYPES = 'REQUEST_CREATE_OPERATIONTYPES';
export const SUCCESS_CREATE_OPERATIONTYPES = 'SUCCESS_CREATE_OPERATIONTYPES';
export const ERROR_CREATE_OPERATIONTYPES = 'ERROR_CREATE_OPERATIONTYPES';
export const RESET_CREATE_OPERATIONTYPES = 'RESET_CREATE_OPERATIONTYPES';

function requestCreateOperationTypes() {
  return {
    type: REQUEST_CREATE_OPERATIONTYPES
  };
}

function receiveCreateOperationTypes(operationTypes) {
  return {
    type: SUCCESS_CREATE_OPERATIONTYPES,
    receivedAt: Date.now(),
    operationTypes: normalizeDatos(operationTypes)
  };
}

function errorCreateOperationTypes(error) {
  return {
    type: ERROR_CREATE_OPERATIONTYPES,
    error: error
  };
}

export function resetCreateOperationTypes() {
  return {
    type: RESET_CREATE_OPERATIONTYPES
  };
}

export function saveCreateOperationTypes() {
  return (dispatch, getState) => {
    dispatch(requestCreateOperationTypes());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let operationTypes = getState().operationTypes.create.nuevos.map(
      idOperationType => {
        return denormalizeDato(
          getState().operationTypes.create.nuevo[idOperationType],
          store
        );
      }
    );

    return operationTypesApi
      .saveCreateOperationTypes(operationTypes)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateOperationTypes(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorCreateOperationTypes(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateOperationTypes(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorCreateOperationTypes(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(
                    errorCreateOperationTypes(JSON.parse(error.message))
                  );
                })
                .catch(() => {
                  dispatch(
                    errorCreateOperationTypes(errorMessages.GENERAL_ERROR)
                  );
                });

            return;
        }
      });
  };
}

//DELETE OPERATIONTYPE
export const DELETE_OPERATIONTYPE = 'DELETE_OPERATIONTYPE';
export const REQUEST_DELETE_OPERATIONTYPE = 'REQUEST_DELETE_OPERATIONTYPE';
export const SUCCESS_DELETE_OPERATIONTYPE = 'SUCCESS_DELETE_OPERATIONTYPE';
export const ERROR_DELETE_OPERATIONTYPE = 'ERROR_DELETE_OPERATIONTYPE';
export const RESET_DELETE_OPERATIONTYPE = 'RESET_DELETE_OPERATIONTYPE';

function requestDeleteOperationType() {
  return {
    type: REQUEST_DELETE_OPERATIONTYPE
  };
}

function receiveDeleteOperationType(operationType) {
  return {
    type: SUCCESS_DELETE_OPERATIONTYPE,
    receivedAt: Date.now(),
    operationType: normalizeDato(operationType)
  };
}

function errorDeleteOperationType(error) {
  return {
    type: ERROR_DELETE_OPERATIONTYPE,
    error: error
  };
}

export function resetDeleteOperationType(error) {
  return {
    type: RESET_DELETE_OPERATIONTYPE,
    error: error
  };
}

export function deleteOperationType(operationType) {
  return {
    type: DELETE_OPERATIONTYPE,
    operationType
  };
}

export function saveDeleteOperationType(operationType) {
  return dispatch => {
    dispatch(requestDeleteOperationType());
    return operationTypesApi
      .saveDelete(operationType)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeleteOperationType());
        dispatch(receiveDeleteOperationType(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorDeleteOperationType(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeleteOperationType(
                    JSON.parse(error.responseJSON.message)
                  )
                );
              } catch (e) {
                dispatch(errorDeleteOperationType(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorDeleteOperationType(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(
                    errorDeleteOperationType(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//PRINT OPERATIONTYPE
export const PRINT_OPERATIONTYPE = 'PRINT_OPERATIONTYPE';
export const REQUEST_PRINT_OPERATIONTYPE = 'REQUEST_PRINT_OPERATIONTYPE';
export const SUCCESS_PRINT_OPERATIONTYPE = 'SUCCESS_PRINT_OPERATIONTYPE';
export const ERROR_PRINT_OPERATIONTYPE = 'ERROR_PRINT_OPERATIONTYPE';
export const RESET_PRINT_OPERATIONTYPE = 'RESET_PRINT_OPERATIONTYPE';
export const DELETE_PRINT_OPERATIONTYPE = 'DELETE_PRINT_OPERATIONTYPE';

function requestPrintOperationType() {
  return {
    type: REQUEST_PRINT_OPERATIONTYPE
  };
}

function receivePrintOperationType(turnos) {
  return {
    type: SUCCESS_PRINT_OPERATIONTYPE,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintOperationType(error) {
  return {
    type: ERROR_PRINT_OPERATIONTYPE,
    error: error
  };
}

export function resetPrintOperationType() {
  return {
    type: RESET_PRINT_OPERATIONTYPE
  };
}

export function printOperationType(operationType) {
  return {
    type: PRINT_OPERATIONTYPE,
    operationType
  };
}

export function deletePrintOperationType(operationType) {
  return {
    type: DELETE_PRINT_OPERATIONTYPE,
    operationType
  };
}

export function savePrintOperationType(idOperationType) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintOperationType());
    return operationTypesApi
      .printOperationType(idOperationType)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintOperationType(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintOperationType(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintOperationType(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintOperationType(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(
                    errorPrintOperationType(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}

//PRINT OPERATIONTYPES
export const REQUEST_PRINT_OPERATIONTYPES = 'REQUEST_PRINT_OPERATIONTYPES';
export const SUCCESS_PRINT_OPERATIONTYPES = 'SUCCESS_PRINT_OPERATIONTYPES';
export const ERROR_PRINT_OPERATIONTYPES = 'ERROR_PRINT_OPERATIONTYPES';
export const RESET_PRINT_OPERATIONTYPES = 'RESET_PRINT_OPERATIONTYPES';

function requestPrintOperationTypes() {
  return {
    type: REQUEST_PRINT_OPERATIONTYPES
  };
}

function receivePrintOperationTypes(operationTypes) {
  return {
    type: SUCCESS_PRINT_OPERATIONTYPES,
    receivedAt: Date.now(),
    operationTypes: normalizeDatos(operationTypes)
  };
}

function errorPrintOperationTypes(error) {
  return {
    type: ERROR_PRINT_OPERATIONTYPES,
    error: error
  };
}

export function resetPrintOperationTypes() {
  return {
    type: RESET_PRINT_OPERATIONTYPES
  };
}

export function savePrintOperationTypes() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintOperationType());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let operationTypes = getState().operationTypes.print.printers.map(
      idOperationType => {
        return denormalizeDato(
          getState().operationTypes.print.print[idOperationType],
          store
        );
      }
    );
    return operationTypesApi
      .printOperationTypes(operationTypes)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintOperationTypes(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(
              errorPrintOperationTypes(errorMessages.UNAUTHORIZED_TOKEN)
            );
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintOperationTypes(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintOperationTypes(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(
                    errorPrintOperationTypes(errorMessages.GENERAL_ERROR)
                  );
                });
            return;
        }
      });
  };
}
