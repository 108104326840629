import React, { useEffect, useRef } from 'react';
import clone from 'lodash/clone';
import {
  createPropertyClosedOperation,
  deletePropertyClosedOperation,
  updatePropertyClosedOperation
} from '../../actions/PropertyClosedOperationActions';
import { useDispatch, useSelector } from 'react-redux';
import { saveUpdateTicket, updateTicket } from '../../actions/TicketActions';
import SuccessfulTicketClosedOperationModal from './SuccessfulTicketClosedOperationModal';
import UnsuccessfulTicketClosedOperationModal from './UnsuccessfulTicketClosedOperationModal';

export default function TicketClosedOperationModal(props) {
  const dispatch = useDispatch();
  const { open, setOpen, type = 'sold', idPropertyClosedOperation } = props;
  const tickets = useSelector(state => state.tickets);
  const propertyClosedOperations = useSelector(
    state => state.propertyClosedOperations
  );
  const propertyClosedOperation =
    idPropertyClosedOperation &&
    idPropertyClosedOperation.toString().indexOf('-') === -1
      ? propertyClosedOperations.update.activo[idPropertyClosedOperation]
      : propertyClosedOperations.create.nuevo[idPropertyClosedOperation];
  const mounted = useRef(false);
  const disabled =
    tickets.update.isUpdating ||
    tickets.create.isCreating ||
    tickets.byId.isFetching;

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
    } else {
      if (!tickets.update.isUpdating && !tickets.update.error && open) {
        handleClose();
      }
    }
  }, [tickets.update.isUpdating]);

  const handleChangePropertyClosedOperation = e => {
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    cambio.type = type;
    changePropertyClosedOperation(cambio);
  };

  const handleDateChangePropertyClosedOperation = (e, id) => {
    let cambio = {};
    cambio[id] = e.format('YYYY-MM-DD');
    changePropertyClosedOperation(cambio);
  };

  const handlePropertyTypeChangePropertyClosedOperation = (e, value) => {
    let cambio = {};
    cambio.codType = value;
    changePropertyClosedOperation(cambio);
  };

  const changePropertyClosedOperation = cambio => {
    cambio.id = idPropertyClosedOperation;
    let cambioPropertyClosedOperation = {};
    cambioPropertyClosedOperation[idPropertyClosedOperation] = cambio;
    if (idPropertyClosedOperation.toString().indexOf('-') === -1)
      dispatch(updatePropertyClosedOperation(cambioPropertyClosedOperation));
    else dispatch(createPropertyClosedOperation(cambioPropertyClosedOperation));
  };

  const deletePropertyClosedOperationNueva = () => {
    let cambio = propertyClosedOperations.create.nuevo[
      idPropertyClosedOperation
    ]
      ? clone(propertyClosedOperations.create.nuevo[idPropertyClosedOperation])
      : { id: idPropertyClosedOperation };
    let cambioPropertyClosedOperation = {};
    cambioPropertyClosedOperation[idPropertyClosedOperation] = cambio;
    dispatch(deletePropertyClosedOperation(cambioPropertyClosedOperation));
  };

  const handleAccept = e => {
    let cambio = { status: 0 };
    changeTicket(cambio);
    dispatch(saveUpdateTicket());
  };

  const handleClose = e => {
    if (idPropertyClosedOperation.toString().indexOf('-') === -1) {
      let attribute = clone(
        propertyClosedOperations.byId.propertyClosedOperations[
          idPropertyClosedOperation
        ]
      );
      changePropertyClosedOperation(attribute);
    } else {
      deletePropertyClosedOperationNueva({});
    }
    setOpen(false);
  };

  const handleChangeTicket = e => {
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    changeTicket(cambio);
    //When motive changes delete the operation
    deletePropertyClosedOperationNueva({});
  };

  const changeTicket = cambio => {
    dispatch(updateTicket(cambio));
  };

  return (
    <>
      {type === 'sold' && (
        <SuccessfulTicketClosedOperationModal
          handleClose={handleClose}
          handleAccept={handleAccept}
          handleChangePropertyClosedOperation={
            handleChangePropertyClosedOperation
          }
          handleDateChangePropertyClosedOperation={
            handleDateChangePropertyClosedOperation
          }
          handlePropertyTypeChangePropertyClosedOperation={
            handlePropertyTypeChangePropertyClosedOperation
          }
          handleChangeTicket={handleChangeTicket}
          propertyClosedOperation={propertyClosedOperation}
          open={open}
          ticket_property={tickets.update.activo.ticket_property}
          ticket={tickets.update.activo}
          disabled={disabled}
        />
      )}
      {type === 'not-sold' && (
        <UnsuccessfulTicketClosedOperationModal
          handleClose={handleClose}
          handleAccept={handleAccept}
          handleChangeTicket={handleChangeTicket}
          open={open}
          ticket={tickets.update.activo}
          disabled={disabled}
        />
      )}
    </>
  );
}
