import { normalize, schema, denormalize } from 'normalizr';
import property from './normalizeProperties';

const ticketActivity = new schema.Entity(
  'ticketActivities',
  { property: property },
  { idAttribute: 'id' }
);

export function normalizeDatos(myData) {
  const mySchema = { ticketActivities: [ticketActivity] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = ticketActivity;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = ticketActivity;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default ticketActivity;
