import {
  resetTenants,
  resetCreateTenant,
  resetUpdateTenant,
  resetDeleteTenant
} from '../actions/TenantActions';
import {
  resetTenantUserAccesses,
  resetCreateTenantUserAccess,
  resetUpdateTenantUserAccess,
  resetDeleteTenantUserAccess
} from '../actions/TenantUserAccessActions';
import {
  resetUsers,
  resetCreateUser,
  resetUpdateUser,
  resetDeleteUser
} from '../actions/UserActions';
import {
  resetDependencies,
  resetCreateDependency,
  resetUpdateDependency,
  resetDeleteDependency
} from '../actions/DependencyActions';
import {
  resetDomains,
  resetCreateDomain,
  resetUpdateDomain,
  resetDeleteDomain
} from '../actions/DomainActions';

var resetTenant = {
  resetAll(dispatch) {
    dispatch(resetTenants());
    dispatch(resetCreateTenant());
    dispatch(resetUpdateTenant());
    dispatch(resetDeleteTenant());
    dispatch(resetTenantUserAccesses());
    dispatch(resetCreateTenantUserAccess());
    dispatch(resetUpdateTenantUserAccess());
    dispatch(resetDeleteTenantUserAccess());
    dispatch(resetUsers());
    dispatch(resetCreateUser());
    dispatch(resetUpdateUser());
    dispatch(resetDeleteUser());
    dispatch(resetDependencies());
    dispatch(resetCreateDependency());
    dispatch(resetUpdateDependency());
    dispatch(resetDeleteDependency());
    dispatch(resetDomains());
    dispatch(resetCreateDomain());
    dispatch(resetUpdateDomain());
    dispatch(resetDeleteDomain());
  }
};

export default resetTenant;
