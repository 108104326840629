//api
import tenantsApi from '../api/tenantsApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizeTenants';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//TENANTS
export const REQUEST_TENANTS = 'REQUEST_TENANTS';
export const RECEIVE_TENANTS = 'RECEIVE_TENANTS';
export const INVALIDATE_TENANTS = 'INVALIDATE_TENANTS';
export const ERROR_TENANTS = 'ERROR_TENANTS';
export const RESET_TENANTS = 'RESET_TENANTS';

export function invalidateTenants() {
  return {
    type: INVALIDATE_TENANTS
  };
}

function requestTenants() {
  return {
    type: REQUEST_TENANTS
  };
}

function receiveTenants(json) {
  return {
    type: RECEIVE_TENANTS,
    tenants: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorTenants(error) {
  return {
    type: ERROR_TENANTS,
    error: error
  };
}

export function resetTenants() {
  return {
    type: RESET_TENANTS
  };
}

export function fetchTenants(filtros) {
  return dispatch => {
    dispatch(requestTenants());
    return tenantsApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveTenants(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorTenants(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorTenants(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

function shouldFetchTenants(state) {
  const tenants = state.tenants.byId;
  if (!tenants) {
    return true;
  } else if (tenants.isFetching) {
    return false;
  } else {
    return tenants.didInvalidate;
  }
}

export function fetchTenantsIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchTenants(getState())) {
      return dispatch(fetchTenants(filtros));
    }
  };
}

//MODEL
export const REQUEST_TENANT = 'REQUEST_TENANT';
export const RECEIVE_TENANT = 'RECEIVE_TENANT';
export const INVALIDATE_TENANT = 'INVALIDATE_TENANT';
export const ERROR_TENANT = 'ERROR_TENANT';
export const RESET_TENANT = 'RESET_TENANT';

export function invalidateTenant() {
  return {
    type: INVALIDATE_TENANT
  };
}

function requestTenant() {
  return {
    type: REQUEST_TENANT
  };
}

export function receiveTenant(json) {
  return {
    type: RECEIVE_TENANT,
    tenant: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorTenant(error) {
  return {
    type: ERROR_TENANT,
    error: error
  };
}

export function fetchTenant(idTenant) {
  return dispatch => {
    dispatch(requestTenant());
    return tenantsApi
      .getOne(idTenant)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receiveTenant(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(errorTenant(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorTenant(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_TENANT = 'RECEIVE_FILE_TENANT';

function receiveFileTenant(file) {
  return {
    type: RECEIVE_FILE_TENANT,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFileTenant(idTenant, filtros) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return tenantsApi
      .getFile(idTenant, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFileTenant(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorTenant(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorTenant(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_TENANT = 'UPDATE_TENANT';
export const REQUEST_UPDATE_TENANT = 'REQUEST_UPDATE_TENANT';
export const SUCCESS_UPDATE_TENANT = 'SUCCESS_UPDATE_TENANT';
export const ERROR_UPDATE_TENANT = 'ERROR_UPDATE_TENANT';
export const RESET_UPDATE_TENANT = 'RESET_UPDATE_TENANT';
export const DELETE_UPDATE_TENANT = 'DELETE_UPDATE_TENANT';

function requestUpdateTenant() {
  return {
    type: REQUEST_UPDATE_TENANT
  };
}

function receiveUpdateTenant(tenant) {
  return {
    type: SUCCESS_UPDATE_TENANT,
    receivedAt: Date.now(),
    tenant: normalizeDato(tenant)
  };
}

function errorUpdateTenant(error) {
  return {
    type: ERROR_UPDATE_TENANT,
    error: error
  };
}

export function resetUpdateTenant() {
  return {
    type: RESET_UPDATE_TENANT
  };
}

export function updateTenant(tenant) {
  return {
    type: UPDATE_TENANT,
    tenant
  };
}

export function saveUpdateTenant(files) {
  return (dispatch, getState) => {
    dispatch(requestUpdateTenant());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let tenant = denormalizeDato(getState().tenants.update.activo, store);

    return tenantsApi
      .saveUpdate(tenant, files)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateTenant(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateTenant(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateTenants(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdateTenants(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateTenant(JSON.parse(error.message)));
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdateTenant(error.data));
                })
                .catch(() => {
                  dispatch(errorUpdateTenant(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

export function deleteUpdateTenant(tenant) {
  return {
    type: DELETE_UPDATE_TENANT,
    tenant
  };
}

//UPDATE TENANTS
export const REQUEST_UPDATE_TENANTS = 'REQUEST_UPDATE_TENANTS';
export const SUCCESS_UPDATE_TENANTS = 'SUCCESS_UPDATE_TENANTS';
export const ERROR_UPDATE_TENANTS = 'ERROR_UPDATE_TENANTS';
export const RESET_UPDATE_TENANTS = 'RESET_UPDATE_TENANTS';

function requestUpdateTenants() {
  return {
    type: REQUEST_UPDATE_TENANTS
  };
}

function receiveUpdateTenants(tenants) {
  return {
    type: SUCCESS_UPDATE_TENANTS,
    receivedAt: Date.now(),
    tenants: normalizeDatos(tenants)
  };
}

function errorUpdateTenants(error) {
  return {
    type: ERROR_UPDATE_TENANTS,
    error: error
  };
}

export function resetUpdateTenants() {
  return {
    type: RESET_UPDATE_TENANTS
  };
}

export function saveUpdateTenants() {
  return (dispatch, getState) => {
    dispatch(requestUpdateTenants());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let tenants = getState().tenants.update.activos.map(idTenant => {
      return denormalizeDato(getState().tenants.update.activo[idTenant], store);
    });

    return tenantsApi
      .saveUpdateTenants(tenants)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdateTenants(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdateTenants(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdateTenants(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdateTenants(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdateTenants(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorUpdateTenants(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//ALTA TENANT
export const CREATE_TENANT = 'CREATE_TENANT';
export const REQUEST_CREATE_TENANT = 'REQUEST_CREATE_TENANT';
export const SUCCESS_CREATE_TENANT = 'SUCCESS_CREATE_TENANT';
export const ERROR_CREATE_TENANT = 'ERROR_CREATE_TENANT';
export const RESET_CREATE_TENANT = 'RESET_CREATE_TENANT';
export const DELETE_CREATE_TENANT = 'DELETE_CREATE_TENANT';

//ALTA TENANT
function requestCreateTenant() {
  return {
    type: REQUEST_CREATE_TENANT
  };
}

function receiveCreateTenant(tenant) {
  return {
    type: SUCCESS_CREATE_TENANT,
    receivedAt: Date.now(),
    tenant: normalizeDato(tenant)
  };
}

export function errorCreateTenant(error) {
  return {
    type: ERROR_CREATE_TENANT,
    error: error
  };
}

export function resetCreateTenant() {
  return {
    type: RESET_CREATE_TENANT
  };
}

export function createTenant(tenant) {
  return {
    type: CREATE_TENANT,
    tenant
  };
}

export function deleteCreateTenant(tenant) {
  return {
    type: DELETE_CREATE_TENANT,
    tenant
  };
}

export function saveCreateTenant(files) {
  return (dispatch, getState) => {
    dispatch(requestCreateTenant());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let tenant = denormalizeDato(getState().tenants.create.nuevo, store);

    return tenantsApi
      .saveCreate(tenant, files)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateTenant(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateTenant(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateTenants(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreateTenants(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateTenant(JSON.parse(error.message)));
                  if (error.data) dispatch(receiveCreateTenant(error.data));
                })
                .catch(() => {
                  dispatch(errorCreateTenant(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//CREATE TENANTS
export const REQUEST_CREATE_TENANTS = 'REQUEST_CREATE_TENANTS';
export const SUCCESS_CREATE_TENANTS = 'SUCCESS_CREATE_TENANTS';
export const ERROR_CREATE_TENANTS = 'ERROR_CREATE_TENANTS';
export const RESET_CREATE_TENANTS = 'RESET_CREATE_TENANTS';

function requestCreateTenants() {
  return {
    type: REQUEST_CREATE_TENANTS
  };
}

function receiveCreateTenants(tenants) {
  return {
    type: SUCCESS_CREATE_TENANTS,
    receivedAt: Date.now(),
    tenants: normalizeDatos(tenants)
  };
}

function errorCreateTenants(error) {
  return {
    type: ERROR_CREATE_TENANTS,
    error: error
  };
}

export function resetCreateTenants() {
  return {
    type: RESET_CREATE_TENANTS
  };
}

export function saveCreateTenants() {
  return (dispatch, getState) => {
    dispatch(requestCreateTenants());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let tenants = getState().tenants.create.nuevos.map(idTenant => {
      return denormalizeDato(getState().tenants.create.nuevo[idTenant], store);
    });

    return tenantsApi
      .saveCreateTenants(tenants)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreateTenants(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreateTenants(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreateTenants(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreateTenants(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreateTenants(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorCreateTenants(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//DELETE TENANT
export const DELETE_TENANT = 'DELETE_TENANT';
export const REQUEST_DELETE_TENANT = 'REQUEST_DELETE_TENANT';
export const SUCCESS_DELETE_TENANT = 'SUCCESS_DELETE_TENANT';
export const ERROR_DELETE_TENANT = 'ERROR_DELETE_TENANT';
export const RESET_DELETE_TENANT = 'RESET_DELETE_TENANT';

function requestDeleteTenant() {
  return {
    type: REQUEST_DELETE_TENANT
  };
}

function receiveDeleteTenant(tenant) {
  return {
    type: SUCCESS_DELETE_TENANT,
    receivedAt: Date.now(),
    tenant: normalizeDato(tenant)
  };
}

function errorDeleteTenant(error) {
  return {
    type: ERROR_DELETE_TENANT,
    error: error
  };
}

export function resetDeleteTenant(error) {
  return {
    type: RESET_DELETE_TENANT,
    error: error
  };
}

export function deleteTenant(tenant) {
  return {
    type: DELETE_TENANT,
    tenant
  };
}

export function saveDeleteTenant(tenant) {
  return dispatch => {
    dispatch(requestDeleteTenant());
    return tenantsApi
      .saveDelete(tenant)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeleteTenant());
        dispatch(receiveDeleteTenant(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorDeleteTenant(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeleteTenant(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorDeleteTenant(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorDeleteTenant(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorDeleteTenant(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT TENANT
export const PRINT_TENANT = 'PRINT_TENANT';
export const REQUEST_PRINT_TENANT = 'REQUEST_PRINT_TENANT';
export const SUCCESS_PRINT_TENANT = 'SUCCESS_PRINT_TENANT';
export const ERROR_PRINT_TENANT = 'ERROR_PRINT_TENANT';
export const RESET_PRINT_TENANT = 'RESET_PRINT_TENANT';
export const DELETE_PRINT_TENANT = 'DELETE_PRINT_TENANT';

function requestPrintTenant() {
  return {
    type: REQUEST_PRINT_TENANT
  };
}

function receivePrintTenant(turnos) {
  return {
    type: SUCCESS_PRINT_TENANT,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintTenant(error) {
  return {
    type: ERROR_PRINT_TENANT,
    error: error
  };
}

export function resetPrintTenant() {
  return {
    type: RESET_PRINT_TENANT
  };
}

export function printTenant(tenant) {
  return {
    type: PRINT_TENANT,
    tenant
  };
}

export function deletePrintTenant(tenant) {
  return {
    type: DELETE_PRINT_TENANT,
    tenant
  };
}

export function savePrintTenant(idTenant) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintTenant());
    return tenantsApi
      .printTenant(idTenant)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintTenant(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintTenant(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintTenant(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintTenant(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintTenant(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT TENANTS
export const REQUEST_PRINT_TENANTS = 'REQUEST_PRINT_TENANTS';
export const SUCCESS_PRINT_TENANTS = 'SUCCESS_PRINT_TENANTS';
export const ERROR_PRINT_TENANTS = 'ERROR_PRINT_TENANTS';
export const RESET_PRINT_TENANTS = 'RESET_PRINT_TENANTS';

function requestPrintTenants() {
  return {
    type: REQUEST_PRINT_TENANTS
  };
}

function receivePrintTenants(tenants) {
  return {
    type: SUCCESS_PRINT_TENANTS,
    receivedAt: Date.now(),
    tenants: normalizeDatos(tenants)
  };
}

function errorPrintTenants(error) {
  return {
    type: ERROR_PRINT_TENANTS,
    error: error
  };
}

export function resetPrintTenants() {
  return {
    type: RESET_PRINT_TENANTS
  };
}

export function savePrintTenants() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintTenant());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let tenants = getState().tenants.print.printers.map(idTenant => {
      return denormalizeDato(getState().tenants.print.print[idTenant], store);
    });
    return tenantsApi
      .printTenants(tenants)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintTenants(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintTenants(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintTenants(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintTenants(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintTenants(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}
