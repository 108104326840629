import { normalize, schema, denormalize } from 'normalizr';

const operationType = new schema.Entity(
  'operationTypes',
  {},
  { idAttribute: 'id' }
);

export function normalizeDatos(myData) {
  const mySchema = { operationTypes: [operationType] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = operationType;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = operationType;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default operationType;
