import React, { useEffect, useRef, useState } from 'react';
import { Breadcrumbs, Container, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import CRUDTenant from './CRUDTenant';
import CRUDDependency from './CRUDDependency';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CRUDDependencyContacts from './CRUDDependencyContacts';
import { useValidador } from '../../utils/validador';
import {
  fetchTenant,
  saveCreateTenant,
  saveUpdateTenant
} from '../../actions/TenantActions';
import { useDispatch, useSelector } from 'react-redux';
import BtnCancel from '../../library/Buttons/BtnCancel';
import BtnSave from '../../library/Buttons/BtnSave';
import ContainerBtn from '../../library/Buttons/ContainerBtn';
import { useHistory } from 'react-router';
import tenantUtil from '../../utils/tenantUtil';
import { isValidPhoneNumber } from 'react-phone-number-input';
import dependency from '../../normalizers/normalizeDependencies';

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: '15px',
    [theme.breakpoints.down('sm')]: {
      padding: '5px 5px'
    }
  }
}));

function CustomizationConfiguration() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const msjImagen = useRef();
  const msjPhoneNumber = useRef();
  const history = useHistory();

  //State
  const [files, setFiles] = useState([]);

  //Store
  const tenants = useSelector(state => state.tenants);
  const tenant = tenantUtil.getTenant()
    ? tenants.update.activo
    : tenants.create.nuevo;

  const guardandoLoading = tenantUtil.getTenant()
    ? tenants.update.isUpdating
    : tenants.create.isCreating;
  const error = tenantUtil.getTenant()
    ? tenants.update.error
    : tenants.create.error;
  const disabled =
    tenants.update.isUpdating ||
    tenants.create.isCreating ||
    tenants.byId.isFetching;

  //Hooks
  let reglas = {};
  reglas = {
    name: ['required|notDisabled'],
    nameDependency: ['required'],
    address: ['required'],
    state: ['required'],
    city: ['required'],
    country: ['required']
  };
  const [validador, validar] = useValidador(reglas);

  useEffect(() => {
    if (!tenant || !tenant.id) dispatch(fetchTenant(tenantUtil.getTenant()));
  }, []);

  const onSubmit = e => {
    e.preventDefault();
    if (msjImagen.current) msjImagen.current.style.display = 'none';
    if (
      (!tenant.originalFilename ||
        !tenant.primaryColor ||
        !tenant.secondaryColor) &&
      msjImagen.current
    )
      msjImagen.current.style.display = 'block';
    else if (
      (dependency.phoneNumber1 &&
        !isValidPhoneNumber(dependency.phoneNumber1)) ||
      (dependency.phoneNumber2 && !isValidPhoneNumber(dependency.phoneNumber2))
    ) {
      msjPhoneNumber.current.classList.add('visible');
      msjPhoneNumber.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      });
    } else if (validador.isValid()) {
      if (tenantUtil.getTenant()) dispatch(saveUpdateTenant(files));
      else dispatch(saveCreateTenant(files));
    }
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Breadcrumbs aria-label="breadcrumb" separator={'‣'}>
        <Link color="inherit" to="/">
          Home
        </Link>
        <Link color="inherit" to="/configuration">
          Configuración
        </Link>
        <Link color="inherit" to="/properties">
          Personalización
        </Link>
      </Breadcrumbs>
      <Typography component={'h1'} variant={'h1'}>
        {'Personalización'}
      </Typography>
      <form onSubmit={onSubmit}>
        <Grid
          container
          spacing={1}
          style={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <Grid item md={7} sm={12} xs={12}>
            <CRUDDependency
              validador={validador}
              validar={validar}
              disabled={disabled}
            />
          </Grid>
          <Grid item md={5} sm={12} xs={12}>
            <Grid item md={12} sm={12} xs={12}>
              <CRUDTenant
                setFiles={setFiles}
                validador={validador}
                validar={validar}
                msjImagen={msjImagen}
                disabled={disabled}
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <CRUDDependencyContacts
                disabled={disabled}
                validador={validador}
                validar={validar}
                msjPhoneNumber={msjPhoneNumber}
              />
            </Grid>
          </Grid>
        </Grid>
        <ContainerBtn>
          <BtnCancel onClick={() => history.push('/configuration')} />
          <BtnSave
            loading={guardandoLoading}
            disabled={disabled}
            error={error}
          />
        </ContainerBtn>
      </form>
    </Container>
  );
}

export default CustomizationConfiguration;
