import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//Components
import HomePanel from './HomePanel';
import HomeUser from './HomeUser';

export default function Home() {
  const auth = useSelector(state => state.auth);

  const dispatch = useDispatch();
  //Effects
  useEffect(() => {
    return function cleanup() {};
  }, []);

  const PrivateRoute = (
    { component, ...rest } // eslint-disable-line
  ) => (
    <Route
      {...rest}
      render={props =>
        auth.loggedIn && auth.usuarioLogueado.role_id === 1 ? (
          //true
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: '/dashboard/' + auth.usuarioLogueado.user_id
            }}
          />
        )
      }
    />
  );

  return (
    <Switch>
      <Route path="/dashboard/:idUser?" component={HomeUser} />
      <PrivateRoute path="/" component={HomePanel} />
    </Switch>
  );
}
