import React, { useEffect } from 'react';
import {
  Breadcrumbs,
  Container,
  ListItem,
  ListItemAvatar,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { useValidador } from '../../utils/validador';
import {
  createTenant,
  fetchTenant,
  saveCreateTenant,
  saveUpdateTenant,
  updateTenant
} from '../../actions/TenantActions';
import { useDispatch, useSelector } from 'react-redux';
import BtnCancel from '../../library/Buttons/BtnCancel';
import BtnSave from '../../library/Buttons/BtnSave';
import ContainerBtn from '../../library/Buttons/ContainerBtn';
import { useHistory } from 'react-router';
import tenantUtil from '../../utils/tenantUtil';
import CustomPaper from '../../library/CustomPaper/CustomPaper';
import PaperBody from '../../library/CustomPaper/PaperBody';
import HomeIcon from '../../assets/img/icon/home.svg';
import PadlockIcon from '../../assets/img/icon/padlock.svg';
import KeysIcon from '../../assets/img/icon/keys.svg';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { fetchExternalSystems } from '../../actions/ExternalSystemActions';

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: '15px',
    [theme.breakpoints.down('sm')]: {
      padding: '5px 5px'
    }
  }
}));

const externalSystemsIcons = {
  HomeIcon: HomeIcon,
  PadlockIcon: PadlockIcon,
  KeysIcon: KeysIcon
};

function SynchronizarionConfiguration() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  //Store
  const tenants = useSelector(state => state.tenants);
  const tenant = tenantUtil.getTenant()
    ? tenants.update.activo
    : tenants.create.nuevo;
  const externalSystems = useSelector(state => state.externalSystems);
  const externalSystem =
    externalSystems.byId.externalSystems[tenant.external_system_name];

  const guardandoLoading = tenantUtil.getTenant()
    ? tenants.update.isUpdating
    : tenants.create.isCreating;
  const error = tenantUtil.getTenant()
    ? tenants.update.error
    : tenants.create.error;
  const disabled =
    tenants.update.isUpdating ||
    tenants.create.isCreating ||
    tenants.byId.isFetching;

  //Hooks
  let reglas = {
    external_system_name: ['required'],
    external_system_key: ['required']
  };
  const [validador, validar] = useValidador(reglas);

  useEffect(() => {
    dispatch(fetchTenant(tenantUtil.getTenant()));
    dispatch(fetchExternalSystems({ status: 1 }));
  }, []);

  //Tenant
  const handleChangeTenant = e => {
    let cambio = {};
    cambio[e.target.id ? e.target.id : e.target.name] = e.target.value;
    changeTenant(cambio);
    validar(e);
  };

  const changeTenant = cambio => {
    if (tenantUtil.getTenant()) dispatch(updateTenant(cambio));
    else dispatch(createTenant(cambio));
  };

  const onSubmit = e => {
    e.preventDefault();
    if (validador.isValid()) {
      if (tenantUtil.getTenant()) dispatch(saveUpdateTenant([]));
      else dispatch(saveCreateTenant([]));
    }
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Breadcrumbs aria-label="breadcrumb" separator={'‣'}>
        <Link color="inherit" to="/">
          Home
        </Link>
        <Link color="inherit" to="/configuration">
          Configuración
        </Link>
        <Link color="inherit">Sincronización</Link>
      </Breadcrumbs>
      <Typography component={'h1'} variant={'h1'}>
        {'Sincronización'}
      </Typography>
      <form onSubmit={onSubmit}>
        <Grid
          container
          spacing={1}
          style={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <Grid item md={7} sm={12} xs={12}>
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems="center"
              justifyContent="center"
            >
              <Grid item md={12} sm={12} xs={12} spacing={1}>
                <CustomPaper>
                  <PaperBody>
                    <Grid
                      container
                      spacing={1}
                      style={{ marginTop: '10px', marginBottom: '10px' }}
                    >
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography
                          variant={'overline'}
                          component={'span'}
                          display={'block'}
                        >
                          Datos de sincronización con sistema externo
                        </Typography>
                        <Typography variant={'caption'} component={'span'}>
                          Mantendremos tus propiedades y emprendimientos
                          sincronizados con tu CRM.
                        </Typography>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <TextField
                          id={'external_system_name'}
                          select
                          fullWidth
                          label={'Sistema externo'}
                          value={
                            tenant && tenant.external_system_name
                              ? tenant.external_system_name
                              : ''
                          }
                          onChange={e => handleChangeTenant(e)}
                          InputProps={{
                            id: 'external_system_name',
                            name: 'external_system_name'
                          }}
                          disabled={disabled}
                          margin={'normal'}
                          variant="outlined"
                          onBlur={validador.validar}
                          error={!validador.isValidById('external_system_name')}
                          // helperText={validador.getHelperTextById("idTypeFile")}
                        >
                          {externalSystems.allIds.map(itemKey => {
                            let item =
                              externalSystems.byId.externalSystems[itemKey];
                            return (
                              <MenuItem
                                key={item.nameExternalSystem}
                                value={item.nameExternalSystem}
                              >
                                {item.nameExternalSystem}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <TextField
                          id="external_system_key"
                          label="API Key"
                          placeholder="API Key"
                          fullWidth={true}
                          InputLabelProps={{
                            shrink: true
                          }}
                          variant="outlined"
                          InputProps={{}}
                          value={
                            tenant && tenant.external_system_key
                              ? tenant.external_system_key
                              : ''
                          }
                          onChange={handleChangeTenant}
                          onBlur={validar}
                          error={!validador.isValidById('external_system_key')}
                          helperText={validador.getHelperTextById(
                            'external_system_key'
                          )}
                          disabled={disabled}
                        />
                      </Grid>
                    </Grid>
                  </PaperBody>
                </CustomPaper>
              </Grid>
            </Grid>
          </Grid>
          {tenant.external_system_name && (
            <Grid item md={5} sm={12} xs={12} spacing={1}>
              <CustomPaper>
                <PaperBody>
                  <Grid
                    container
                    spacing={1}
                    style={{ marginTop: '10px', marginBottom: '10px' }}
                  >
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography
                        variant={'overline'}
                        component={'span'}
                        display={'block'}
                      >
                        {`¿Cómo obtengo mi API Key de ${externalSystem?.nameExternalSystem}?`}
                      </Typography>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <List className={classes.root}>
                        {tenant?.external_system_name &&
                          JSON.parse(
                            externalSystems.byId.externalSystems[
                              tenant.external_system_name
                            ]?.steps
                          ).map((step, i) => {
                            return (
                              <ListItem key={'step' + i}>
                                <ListItemAvatar>
                                  <Avatar
                                    src={externalSystemsIcons[step.icon]}
                                  ></Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  primary={'Paso ' + (i + 1)}
                                  secondary={step.description}
                                />
                              </ListItem>
                            );
                          })}
                      </List>
                    </Grid>
                  </Grid>
                </PaperBody>
              </CustomPaper>
            </Grid>
          )}
        </Grid>
        <ContainerBtn>
          <BtnCancel onClick={() => history.push('/configuration')} />
          <BtnSave
            loading={guardandoLoading}
            disabled={disabled}
            error={error}
          />
        </ContainerBtn>
      </form>
    </Container>
  );
}

export default SynchronizarionConfiguration;
