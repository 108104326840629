import {
  resetUsers,
  resetCreateUser,
  resetUpdateUser,
  resetDeleteUser
} from '../actions/UserActions';
import {
  resetTickets,
  resetCreateTicket,
  resetUpdateTicket,
  resetDeleteTicket
} from '../actions/TicketActions';
import {
  resetProperties,
  resetCreateProperty,
  resetUpdateProperty,
  resetDeleteProperty
} from '../actions/PropertyActions';
import {
  resetTenantUserAccesses,
  resetCreateTenantUserAccess,
  resetUpdateTenantUserAccess,
  resetDeleteTenantUserAccess
} from '../actions/TenantUserAccessActions';

var resetUser = {
  resetAll(dispatch) {
    dispatch(resetUsers());
    dispatch(resetCreateUser());
    dispatch(resetUpdateUser());
    dispatch(resetDeleteUser());
    dispatch(resetTickets());
    dispatch(resetCreateTicket());
    dispatch(resetUpdateTicket());
    dispatch(resetDeleteTicket());
    dispatch(resetProperties());
    dispatch(resetCreateProperty());
    dispatch(resetUpdateProperty());
    dispatch(resetDeleteProperty());
    dispatch(resetTenantUserAccesses());
    dispatch(resetCreateTenantUserAccess());
    dispatch(resetUpdateTenantUserAccess());
    dispatch(resetDeleteTenantUserAccess());
  }
};

export default resetUser;
