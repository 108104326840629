//api
import periodsApi from '../api/periodsApi';
//constants
import * as errorMessages from '../constants/MessageConstants';
//actions
import { logout } from '../actions/AuthActions';
//normalizers
import {
  normalizeDatos,
  normalizeDato,
  denormalizeDato
} from '../normalizers/normalizePeriods';
//lodash
import merge from 'lodash/merge';
//utils
import authUtil from '../utils/auth';

//PERIODS
export const REQUEST_PERIODS = 'REQUEST_PERIODS';
export const RECEIVE_PERIODS = 'RECEIVE_PERIODS';
export const INVALIDATE_PERIODS = 'INVALIDATE_PERIODS';
export const ERROR_PERIODS = 'ERROR_PERIODS';
export const RESET_PERIODS = 'RESET_PERIODS';

export function invalidatePeriods() {
  return {
    type: INVALIDATE_PERIODS
  };
}

function requestPeriods() {
  return {
    type: REQUEST_PERIODS
  };
}

function receivePeriods(json) {
  return {
    type: RECEIVE_PERIODS,
    periods: normalizeDatos(json),
    receivedAt: Date.now()
  };
}

function errorPeriods(error) {
  return {
    type: ERROR_PERIODS,
    error: error
  };
}

export function resetPeriods() {
  return {
    type: RESET_PERIODS
  };
}

export function fetchPeriods(filtros) {
  return dispatch => {
    dispatch(requestPeriods());
    return periodsApi
      .getAll(filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receivePeriods(data));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorPeriods(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorPeriods(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

function shouldFetchPeriods(state) {
  const periods = state.periods.byId;
  if (!periods) {
    return true;
  } else if (periods.isFetching) {
    return false;
  } else {
    return periods.didInvalidate;
  }
}

export function fetchPeriodsIfNeeded(filtros) {
  return (dispatch, getState) => {
    if (shouldFetchPeriods(getState())) {
      return dispatch(fetchPeriods(filtros));
    }
  };
}

//MODEL
export const REQUEST_PERIOD = 'REQUEST_PERIOD';
export const RECEIVE_PERIOD = 'RECEIVE_PERIOD';
export const INVALIDATE_PERIOD = 'INVALIDATE_PERIOD';
export const ERROR_PERIOD = 'ERROR_PERIOD';
export const RESET_PERIOD = 'RESET_PERIOD';

export function invalidatePeriod() {
  return {
    type: INVALIDATE_PERIOD
  };
}

function requestPeriod() {
  return {
    type: REQUEST_PERIOD
  };
}

export function receivePeriod(json) {
  return {
    type: RECEIVE_PERIOD,
    period: normalizeDato(json),
    receivedAt: Date.now()
  };
}

function errorPeriod(error) {
  return {
    type: ERROR_PERIOD,
    error: error
  };
}

export function fetchPeriod(idPeriod) {
  return dispatch => {
    dispatch(requestPeriod());
    return periodsApi
      .getOne(idPeriod)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(receivePeriod(data));
      })
      .catch(function (error) {
        switch (error.status) {
          case 401:
            dispatch(errorPeriod(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorPeriod(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//FILE
export const RECEIVE_FILE_PERIOD = 'RECEIVE_FILE_PERIOD';

function receiveFilePeriod(file) {
  return {
    type: RECEIVE_FILE_PERIOD,
    file: file,
    receivedAt: Date.now()
  };
}

export function fetchFilePeriod(idPeriod, filtros) {
  let nombreArchivo = '';
  let tipoArchivo = '';
  return dispatch => {
    return periodsApi
      .getFile(idPeriod, filtros)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let fileObj = {};
        fileObj[nombreArchivo] = file;
        dispatch(receiveFilePeriod(fileObj));
      })
      .catch(function (error) {
        console.log(error);
        switch (error.status) {
          case 401:
            dispatch(errorPeriod(errorMessages.UNAUTHORIZED_TOKEN));
            dispatch(logout());
            return;
          default:
            dispatch(errorPeriod(errorMessages.GENERAL_ERROR));
            return;
        }
      });
  };
}

//UPDATE MODEL
export const UPDATE_PERIOD = 'UPDATE_PERIOD';
export const REQUEST_UPDATE_PERIOD = 'REQUEST_UPDATE_PERIOD';
export const SUCCESS_UPDATE_PERIOD = 'SUCCESS_UPDATE_PERIOD';
export const ERROR_UPDATE_PERIOD = 'ERROR_UPDATE_PERIOD';
export const RESET_UPDATE_PERIOD = 'RESET_UPDATE_PERIOD';
export const DELETE_UPDATE_PERIOD = 'DELETE_UPDATE_PERIOD';

function requestUpdatePeriod() {
  return {
    type: REQUEST_UPDATE_PERIOD
  };
}

function receiveUpdatePeriod(period) {
  return {
    type: SUCCESS_UPDATE_PERIOD,
    receivedAt: Date.now(),
    period: normalizeDato(period)
  };
}

function errorUpdatePeriod(error) {
  return {
    type: ERROR_UPDATE_PERIOD,
    error: error
  };
}

export function resetUpdatePeriod() {
  return {
    type: RESET_UPDATE_PERIOD
  };
}

export function updatePeriod(period) {
  return {
    type: UPDATE_PERIOD,
    period
  };
}

export function saveUpdatePeriod() {
  return (dispatch, getState) => {
    dispatch(requestUpdatePeriod());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let period = denormalizeDato(getState().periods.update.activo, store);

    return periodsApi
      .saveUpdate(period)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdatePeriod(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdatePeriod(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdatePeriods(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdatePeriods(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdatePeriod(JSON.parse(error.message)));
                  if (error.data && error.data.length > 0)
                    dispatch(receiveUpdatePeriod(error.data));
                })
                .catch(() => {
                  dispatch(errorUpdatePeriod(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

export function deleteUpdatePeriod(period) {
  return {
    type: DELETE_UPDATE_PERIOD,
    period
  };
}

//UPDATE PERIODS
export const REQUEST_UPDATE_PERIODS = 'REQUEST_UPDATE_PERIODS';
export const SUCCESS_UPDATE_PERIODS = 'SUCCESS_UPDATE_PERIODS';
export const ERROR_UPDATE_PERIODS = 'ERROR_UPDATE_PERIODS';
export const RESET_UPDATE_PERIODS = 'RESET_UPDATE_PERIODS';

function requestUpdatePeriods() {
  return {
    type: REQUEST_UPDATE_PERIODS
  };
}

function receiveUpdatePeriods(periods) {
  return {
    type: SUCCESS_UPDATE_PERIODS,
    receivedAt: Date.now(),
    periods: normalizeDatos(periods)
  };
}

function errorUpdatePeriods(error) {
  return {
    type: ERROR_UPDATE_PERIODS,
    error: error
  };
}

export function resetUpdatePeriods() {
  return {
    type: RESET_UPDATE_PERIODS
  };
}

export function saveUpdatePeriods() {
  return (dispatch, getState) => {
    dispatch(requestUpdatePeriods());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let periods = getState().periods.update.activos.map(idPeriod => {
      return denormalizeDato(getState().periods.update.activo[idPeriod], store);
    });

    return periodsApi
      .saveUpdatePeriods(periods)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveUpdatePeriods(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorUpdatePeriods(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorUpdatePeriods(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorUpdatePeriods(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorUpdatePeriods(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorUpdatePeriods(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//ALTA PERIOD
export const CREATE_PERIOD = 'CREATE_PERIOD';
export const REQUEST_CREATE_PERIOD = 'REQUEST_CREATE_PERIOD';
export const SUCCESS_CREATE_PERIOD = 'SUCCESS_CREATE_PERIOD';
export const ERROR_CREATE_PERIOD = 'ERROR_CREATE_PERIOD';
export const RESET_CREATE_PERIOD = 'RESET_CREATE_PERIOD';
export const DELETE_CREATE_PERIOD = 'DELETE_CREATE_PERIOD';

//ALTA PERIOD
function requestCreatePeriod() {
  return {
    type: REQUEST_CREATE_PERIOD
  };
}

function receiveCreatePeriod(period) {
  return {
    type: SUCCESS_CREATE_PERIOD,
    receivedAt: Date.now(),
    period: normalizeDato(period)
  };
}

export function errorCreatePeriod(error) {
  return {
    type: ERROR_CREATE_PERIOD,
    error: error
  };
}

export function resetCreatePeriod() {
  return {
    type: RESET_CREATE_PERIOD
  };
}

export function createPeriod(period) {
  return {
    type: CREATE_PERIOD,
    period
  };
}

export function deleteCreatePeriod(period) {
  return {
    type: DELETE_CREATE_PERIOD,
    period
  };
}

export function saveCreatePeriod() {
  return (dispatch, getState) => {
    dispatch(requestCreatePeriod());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let period = denormalizeDato(getState().periods.create.nuevo, store);

    return periodsApi
      .saveCreate(period)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreatePeriod(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreatePeriod(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreatePeriods(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreatePeriods(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreatePeriod(JSON.parse(error.message)));
                  if (error.data) dispatch(receiveCreatePeriod(error.data));
                })
                .catch(() => {
                  dispatch(errorCreatePeriod(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//CREATE PERIODS
export const REQUEST_CREATE_PERIODS = 'REQUEST_CREATE_PERIODS';
export const SUCCESS_CREATE_PERIODS = 'SUCCESS_CREATE_PERIODS';
export const ERROR_CREATE_PERIODS = 'ERROR_CREATE_PERIODS';
export const RESET_CREATE_PERIODS = 'RESET_CREATE_PERIODS';

function requestCreatePeriods() {
  return {
    type: REQUEST_CREATE_PERIODS
  };
}

function receiveCreatePeriods(periods) {
  return {
    type: SUCCESS_CREATE_PERIODS,
    receivedAt: Date.now(),
    periods: normalizeDatos(periods)
  };
}

function errorCreatePeriods(error) {
  return {
    type: ERROR_CREATE_PERIODS,
    error: error
  };
}

export function resetCreatePeriods() {
  return {
    type: RESET_CREATE_PERIODS
  };
}

export function saveCreatePeriods() {
  return (dispatch, getState) => {
    dispatch(requestCreatePeriods());

    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let periods = getState().periods.create.nuevos.map(idPeriod => {
      return denormalizeDato(getState().periods.create.nuevo[idPeriod], store);
    });

    return periodsApi
      .saveCreatePeriods(periods)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          //Refresco token
          authUtil.addToken(response, dispatch);
          var data = response.json();
          return data;
        }
      })
      .then(function (json) {
        dispatch(receiveCreatePeriods(json));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorCreatePeriods(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorCreatePeriods(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorCreatePeriods(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorCreatePeriods(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorCreatePeriods(errorMessages.GENERAL_ERROR));
                });

            return;
        }
      });
  };
}

//DELETE PERIOD
export const DELETE_PERIOD = 'DELETE_PERIOD';
export const REQUEST_DELETE_PERIOD = 'REQUEST_DELETE_PERIOD';
export const SUCCESS_DELETE_PERIOD = 'SUCCESS_DELETE_PERIOD';
export const ERROR_DELETE_PERIOD = 'ERROR_DELETE_PERIOD';
export const RESET_DELETE_PERIOD = 'RESET_DELETE_PERIOD';

function requestDeletePeriod() {
  return {
    type: REQUEST_DELETE_PERIOD
  };
}

function receiveDeletePeriod(period) {
  return {
    type: SUCCESS_DELETE_PERIOD,
    receivedAt: Date.now(),
    period: normalizeDato(period)
  };
}

function errorDeletePeriod(error) {
  return {
    type: ERROR_DELETE_PERIOD,
    error: error
  };
}

export function resetDeletePeriod(error) {
  return {
    type: RESET_DELETE_PERIOD,
    error: error
  };
}

export function deletePeriod(period) {
  return {
    type: DELETE_PERIOD,
    period
  };
}

export function saveDeletePeriod(period) {
  return dispatch => {
    dispatch(requestDeletePeriod());
    return periodsApi
      .saveDelete(period)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          var data = response.json();
          //Refresco token
          //auth.addToken(response.headers);
          return data;
        }
      })
      .then(function (data) {
        dispatch(resetDeletePeriod());
        dispatch(receiveDeletePeriod(data));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorDeletePeriod(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              try {
                dispatch(
                  errorDeletePeriod(JSON.parse(error.responseJSON.message))
                );
              } catch (e) {
                dispatch(errorDeletePeriod(error.responseJSON.message));
              }
            else
              error
                .json()
                .then(error => {
                  dispatch(errorDeletePeriod(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorDeletePeriod(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT PERIOD
export const PRINT_PERIOD = 'PRINT_PERIOD';
export const REQUEST_PRINT_PERIOD = 'REQUEST_PRINT_PERIOD';
export const SUCCESS_PRINT_PERIOD = 'SUCCESS_PRINT_PERIOD';
export const ERROR_PRINT_PERIOD = 'ERROR_PRINT_PERIOD';
export const RESET_PRINT_PERIOD = 'RESET_PRINT_PERIOD';
export const DELETE_PRINT_PERIOD = 'DELETE_PRINT_PERIOD';

function requestPrintPeriod() {
  return {
    type: REQUEST_PRINT_PERIOD
  };
}

function receivePrintPeriod(turnos) {
  return {
    type: SUCCESS_PRINT_PERIOD,
    receivedAt: Date.now(),
    turnos: normalizeDatos(turnos)
  };
}

function errorPrintPeriod(error) {
  return {
    type: ERROR_PRINT_PERIOD,
    error: error
  };
}

export function resetPrintPeriod() {
  return {
    type: RESET_PRINT_PERIOD
  };
}

export function printPeriod(period) {
  return {
    type: PRINT_PERIOD,
    period
  };
}

export function deletePrintPeriod(period) {
  return {
    type: DELETE_PRINT_PERIOD,
    period
  };
}

export function savePrintPeriod(idPeriod) {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintPeriod());
    return periodsApi
      .printPeriod(idPeriod)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintPeriod(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintPeriod(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintPeriod(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintPeriod(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintPeriod(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}

//PRINT PERIODS
export const REQUEST_PRINT_PERIODS = 'REQUEST_PRINT_PERIODS';
export const SUCCESS_PRINT_PERIODS = 'SUCCESS_PRINT_PERIODS';
export const ERROR_PRINT_PERIODS = 'ERROR_PRINT_PERIODS';
export const RESET_PRINT_PERIODS = 'RESET_PRINT_PERIODS';

function requestPrintPeriods() {
  return {
    type: REQUEST_PRINT_PERIODS
  };
}

function receivePrintPeriods(periods) {
  return {
    type: SUCCESS_PRINT_PERIODS,
    receivedAt: Date.now(),
    periods: normalizeDatos(periods)
  };
}

function errorPrintPeriods(error) {
  return {
    type: ERROR_PRINT_PERIODS,
    error: error
  };
}

export function resetPrintPeriods() {
  return {
    type: RESET_PRINT_PERIODS
  };
}

export function savePrintPeriods() {
  return (dispatch, getState) => {
    let nombreArchivo = '';
    let tipoArchivo = '';
    dispatch(requestPrintPeriod());
    let store = {};
    Object.keys(getState()).forEach(key => {
      if (getState()[key].byId)
        store[key] = merge(
          {},
          getState()[key].byId[key],
          getState()[key].update.activo,
          getState()[key].create.nuevo
        );
    });

    let periods = getState().periods.print.printers.map(idPeriod => {
      return denormalizeDato(getState().periods.print.print[idPeriod], store);
    });
    return periodsApi
      .printPeriods(periods)
      .then(function (response) {
        if (response.status >= 400) {
          return Promise.reject(response);
        } else {
          response.headers.forEach(function (val, key) {
            if (key === 'content-disposition') {
              // nombreArchivo = val.replace("attachment; filename=", "");}
              var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
              var matches = filenameRegex.exec(val);
              if (matches != null && matches[1]) {
                nombreArchivo = matches[1].replace(/['"]/g, '');
              }
            }
            if (key === 'content-type') {
              tipoArchivo = val;
            }
          });
          var data = response.blob();
          return data;
        }
      })
      .then(function (data) {
        console.log(nombreArchivo, tipoArchivo);
        let file = new File([data], nombreArchivo, { type: tipoArchivo });
        let reader = new FileReader();
        let a = document.createElement('a');
        document.body.appendChild(a);
        a.style = 'display: none';
        reader.onloadend = function () {
          a.href = reader.result;
          a.download = file.name;
          a.click();
        };
        if (file) {
          reader.readAsDataURL(file);
        }
        dispatch(receivePrintPeriods(file));
      })
      .catch(function (error) {
        console.log(error, error.status);
        switch (error.status) {
          case 401:
            dispatch(errorPrintPeriods(errorMessages.UNAUTHORIZED_TOKEN));
            return;
          default:
            if (error.responseJSON && error.responseJSON.message !== '')
              dispatch(
                errorPrintPeriods(JSON.parse(error.responseJSON.message))
              );
            else
              error
                .json()
                .then(error => {
                  dispatch(errorPrintPeriods(JSON.parse(error.message)));
                })
                .catch(() => {
                  dispatch(errorPrintPeriods(errorMessages.GENERAL_ERROR));
                });
            return;
        }
      });
  };
}
