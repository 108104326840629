import React from 'react';
import { FormControl, FormGroup, FormLabel } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

export default function OperationTypesFilter(props) {
  const { query, setQuery } = props;

  const handleChange = (e, value) => {
    setQuery(prev => Object.assign({}, prev, { codOperationType: value }));
  };

  return (
    <FormControl
      component="fieldset"
      variant="standard"
      className={'operationType'}
    >
      <FormLabel component="legend" className={'formLabel'}>
        Tipo de operación
      </FormLabel>
      <FormGroup>
        <ToggleButtonGroup
          color="primary"
          value={query && query.codOperationType}
          exclusive
          onChange={handleChange}
          variant={'filled'}
        >
          <ToggleButton value="V" fullWidth>
            Venta
          </ToggleButton>
          <ToggleButton value="A" fullWidth>
            Alquiler
          </ToggleButton>
        </ToggleButtonGroup>
      </FormGroup>
    </FormControl>
  );
}
