import React from 'react';
import { Route, Switch } from 'react-router-dom';

//Components
import TicketsBoard from './TicketsBoard';
import CRUDTicket from './CRUDTicket';

export default function Tickets() {
  return (
    <Switch>
      <Route path="/tickets/:idTicket" component={CRUDTicket} />
      <Route path="/tickets" component={TicketsBoard} />
    </Switch>
  );
}
