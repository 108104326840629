import { normalize, schema, denormalize } from 'normalizr';
import neighborhood from './normalizeNeighborhoods';

const location = new schema.Entity('locations', {}, { idAttribute: 'id' });

location.define({
  location: [neighborhood]
});

export function normalizeDatos(myData) {
  const mySchema = { locations: [location] };
  const normalizedData = normalize(myData, mySchema);
  return normalizedData;
}

export function normalizeDato(myData) {
  const mySchema = location;
  const normalizedData = normalize(myData, mySchema);

  return normalizedData;
}

export function denormalizeDato(myData, store) {
  const mySchema = location;

  const normalizedData = denormalize(myData, mySchema, store);

  return normalizedData;
}

export default location;
