import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
//Components
import {
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import userReset from '../../../reset/userReset';
import {
  fetchUsers,
  invalidateUsers,
  resetUsers
} from '../../../actions/UserActions';
import { Skeleton } from '@material-ui/lab';
import CustomPaper from '../../../library/CustomPaper/CustomPaper';
import PaperHeader from '../../../library/CustomPaper/PaperHeader';
import CropFreeIcon from '@material-ui/icons/Search';
import Grow from '../../../library/Grow';
import PaperFooter from '../../../library/CustomPaper/PaperFooter';
import Pagination from '@material-ui/lab/Pagination';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Grid from '@material-ui/core/Grid';
import c from '../../../constants/Constants';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import moment from 'moment/moment';

const useStyles = makeStyles(theme => ({
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  h6: {
    fontSize: '15px',
    fontWeight: '600'
  }
}));

function SellersListPanel(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { dateRange } = props;

  //Store
  const users = useSelector(state => state.users);

  //State
  const [search, setSearch] = useState('');
  const [filter, setFilter] = useState({});

  const params = filter;
  const order = filter.order ? filter.order : 'id';
  const direction = filter.direction ? filter.direction : 'asc';
  const page = filter.page ? filter.page : 1;
  const searchPhase = filter.sp ? filter.sp : '';
  const idCategorySelected = filter.idCategory ? filter.idCategory : '';

  const disabled = users.byId.isFetching || users.delete.isDeleting;

  //Hooks
  const timer = useRef();
  const mounted = useRef();

  //Effects
  useEffect(() => {
    window.scrollTo(0, 0);

    if (searchPhase != search) {
      setSearch(searchPhase);
    }

    return function cleanup() {
      userReset.resetAll(dispatch);
    };
  }, []);

  useEffect(() => {
    let filtros = {
      searchPhase: search ? search : '',
      order: order,
      direction: direction,
      page: page,
      idsRoles: [1, 2],
      dateFrom: moment(dateRange.startDate).format('YYYY-MM-DD HH:mm'),
      dateTo: moment(dateRange.endDate).format('YYYY-MM-DD HH:mm')
    };

    dispatch(resetUsers());
    dispatch(invalidateUsers());
    dispatch(fetchUsers(filtros));
  }, [filter, dateRange]);

  const changeOrder = or => {
    params.set('order', or ? or : '');
    let direction = params.get('direction');
    params.set('direction', direction === 'asc' ? 'desc' : 'asc');
    props.history.push('/users?' + params.toString());
  };
  const WAIT_INTERVAL = 500;

  const changeSearch = e => {
    clearTimeout(timer.current);
    let valor = e.target.value;
    setSearch(e.target.value);

    timer.current = setTimeout(() => triggerChange(valor), WAIT_INTERVAL);
  };

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      clearTimeout(timer.current);
      triggerChange();
    }
  };

  const triggerChange = searchPhase => {
    if ((!searchPhase && !search) || searchPhase === '') {
      params.delete('sp');
      props.history.push('/users?' + params.toString());
    } else {
      params.delete('page');
      params.set('sp', searchPhase ? searchPhase : search);
      props.history.push('/users?' + params.toString());
    }
  };

  const handleChangePage = (e, page) => {
    let pagina = page;
    let paramsPagina = new URLSearchParams(props.location.search);
    paramsPagina.set('page', pagina ? pagina : '1');
    props.history.push('/users?' + paramsPagina.toString());
  };

  let Users = [];

  if (users.byId.isFetching) {
    Users = [...Array(5).keys()].map(i => {
      return (
        <TableRow key={'inv' + i}>
          {[...Array(5).keys()].map(i => (
            <TableCell key={i}>
              <Skeleton variant="text" />
            </TableCell>
          ))}
        </TableRow>
      );
    });
  }

  if (!users.byId.isFetching)
    Users = users.allIds.map(idUser => {
      let user = users.byId.users[idUser];
      return (
        <TableRow key={user.id}>
          <TableCell>
            <Box display={'flex'} flexDirection={'column'}>
              <Box
                display={'flex'}
                justifyContent={'flex-start'}
                alignItems={'center'}
                gridColumnGap={'10px'}
              >
                <Avatar
                  className={classes.small}
                  src={user?.path ? `${c.API_HOST}${user.path}` : ''}
                />
                <Typography
                  variant={'h6'}
                  component={'h6'}
                  className={classes.h6}
                >
                  {user ? user.lastname + ' ' + user.name : ''}
                </Typography>
              </Box>
              <Box display={'flex'}>
                <Typography variant={'caption'}>
                  {user ? user.email : ''}
                </Typography>
              </Box>
            </Box>
          </TableCell>
          <TableCell>{user ? user.qProperties : ''}</TableCell>
          <TableCell>{user ? user.qContacts : ''}</TableCell>
          <TableCell>{user ? user.qClosedOperation : ''}</TableCell>
          <TableCell>{user ? user.qClosedOperationRetired : ''}</TableCell>
          <TableCell>
            <Link to={'/dashboard/' + user.id}>
              <Tooltip title="Ver">
                <IconButton
                  aria-label="editar"
                  size={'small'}
                  disabled={disabled}
                >
                  <VisibilityIcon color="secondary" />
                </IconButton>
              </Tooltip>
            </Link>
          </TableCell>
          <TableCell>
            <Link to={'/users/' + user.id}>
              <Tooltip title="Editar">
                <IconButton
                  aria-label="editar"
                  size={'small'}
                  disabled={disabled}
                >
                  <EditIcon color="secondary" />
                </IconButton>
              </Tooltip>
            </Link>
          </TableCell>
        </TableRow>
      );
    });

  if (Users.length === 0)
    Users.push(
      <TableRow key={0}>
        <TableCell align="center" colSpan={11}>
          No se encontraron usuarios
        </TableCell>
      </TableRow>
    );

  const totalPaginas = Math.ceil(users.totalUsers ? users.totalUsers / 50 : 0);

  return (
    <Grid item xs={12}>
      <CustomPaper>
        <PaperHeader>
          <TextField
            id="search"
            placeholder="Buscar ..."
            variant="outlined"
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton>
                    <CropFreeIcon />
                  </IconButton>
                </InputAdornment>
              )
              // disabled: disabled
            }}
            value={search ? search : ''}
            onChange={e => changeSearch(e)}
            onKeyDown={e => handleKeyDown(e)}
          />
          <Grow />
        </PaperHeader>
        <TableContainer>
          <Table aria-label="Tabla de contenido">
            <TableHead>
              <TableRow>
                <TableCell>
                  <TableSortLabel
                    active={order === 'name'}
                    direction={direction}
                    onClick={() => changeOrder('name')}
                  >
                    <b>Usuario</b>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={order === 'lastname'}
                    direction={direction}
                    onClick={() => changeOrder('lastname')}
                  >
                    <b>Propiedades</b>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={order === 'email'}
                    direction={direction}
                    onClick={() => changeOrder('email')}
                  >
                    <b>Contactos</b>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={order === 'email'}
                    direction={direction}
                    onClick={() => changeOrder('email')}
                  >
                    <b>Operaciones</b>
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={order === 'email'}
                    direction={direction}
                    onClick={() => changeOrder('email')}
                  >
                    <b>Operaciones canceladas</b>
                  </TableSortLabel>
                </TableCell>

                <TableCell width={'10px'} />
                <TableCell width={'10px'} />
              </TableRow>
            </TableHead>
            <TableBody>{Users}</TableBody>
          </Table>
        </TableContainer>
        <PaperFooter style={{ justifyContent: 'center' }}>
          {!!totalPaginas && totalPaginas > 1 && (
            <Pagination
              count={totalPaginas}
              page={page ? parseInt(page) : 1}
              color="primary"
              onChange={handleChangePage}
              size={'small'}
            />
          )}
        </PaperFooter>
      </CustomPaper>
    </Grid>
  );
}

SellersListPanel.propTypes = {};

export default SellersListPanel;
